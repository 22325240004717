
import React from 'react';
import { Link } from 'react-router-dom';
import { MapPinOff } from 'lucide-react';
import LocationDetailLayout from './LocationDetailLayout';

const LocationNotFound: React.FC = () => {
  return (
    <LocationDetailLayout>
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center justify-center py-20 text-center">
          <MapPinOff className="h-16 w-16 text-gray-400 mb-4" />
          <h2 className="text-2xl font-bold mb-4">Location Not Found</h2>
          <p className="text-gray-600 mb-8 max-w-lg">
            The location you're looking for doesn't exist or has been removed.
            Please check the URL or browse our available service areas.
          </p>
          <Link 
            to="/"
            className="bg-[#D00B0E] hover:bg-[#D00B0E]/90 text-white px-6 py-2 rounded-md font-medium transition-colors mr-4"
          >
            Return Home
          </Link>
        </div>
      </div>
    </LocationDetailLayout>
  );
};

export default LocationNotFound;
