import React from 'react';
import { Link } from 'react-router-dom';

interface FooterCopyrightProps {
  currentYear: number;
}

const FooterCopyright: React.FC<FooterCopyrightProps> = ({ currentYear }) => {
  return (
    <div className="py-6 border-t border-gray-700/50 flex flex-col space-y-4">
      <div className="flex flex-col md:flex-row items-center space-y-3 md:space-y-0 md:space-x-6 text-sm">
        {[
          { to: "/privacy-policy", label: "Privacy Policy" },
          { to: "/terms-of-service", label: "Terms of Service" },
          { to: "/SiteMap", label: "Sitemap" },
          { to: "/admin/login", label: "Admin" }
        ].map((link) => (
          <Link 
            key={link.to}
            to={link.to} 
            className="text-gray-400 hover:text-white transition-colors"
          >
            {link.label}
          </Link>
        ))}
      </div>
      <p className="text-gray-400 text-sm text-center md:text-left">
        &copy; {currentYear} Fix My Brick Inc. All rights reserved.
      </p>
    </div>
  );
};

export default FooterCopyright;
