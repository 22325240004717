import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { Skeleton } from '@/components/ui/skeleton';
import SocialMediaImageGrid from '../franchise-images/SocialMediaImageGrid';
import StatusFilter from '../franchise-images/StatusFilter';
import { useImageStatusUpdate } from '@/hooks/franchise-images/useImageStatusUpdate';

interface GallerySlideshowProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  galleryId: string;
  galleryTitle?: string;
  franchiseRegion?: string | null;
  jobNumber?: number | null;
}

const GallerySlideshow = ({
  open,
  onOpenChange,
  galleryId,
  galleryTitle,
  franchiseRegion,
  jobNumber,
}: GallerySlideshowProps) => {
  const [images, setImages] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [showOnlyPending, setShowOnlyPending] = useState(false);
  const [coverImage, setCoverImage] = useState<string | null>(null);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const { approving, rejecting, updateImageStatus } = useImageStatusUpdate();

  useEffect(() => {
    if (open && galleryId) {
      fetchGalleryImages();
      fetchGalleryCoverImage();
    } else {
      setImages([]);
      setLoading(true);
    }
  }, [open, galleryId, showOnlyPending]);

  // Debug function to check image URL validity
  const logImageUrls = (images: any[]) => {
    console.log('Gallery Images Loaded:', images.length);
    images.forEach((img, index) => {
      console.log(`Image ${index + 1}:`, {
        id: img.id,
        url: img.image_url,
        valid: Boolean(img.image_url && img.image_url.startsWith('http')),
      });
    });
  };

  const fetchGalleryImages = async () => {
    try {
      setLoading(true);

      const query = supabase
        .from('gallery_photos')
        .select('*')
        .eq('gallery_id', galleryId)
        .order('sort_order', { ascending: true });

      const { data, error } = await query;

      if (error) throw error;

      // If we have franchise_region and job_number, also get franchise images
      let franchiseImages: any[] = [];
      if (franchiseRegion && jobNumber) {
        let franchiseQuery = supabase
          .from('franchise_image_jobs')
          .select('*')
          .eq('franchise_region', franchiseRegion)
          .eq('job_number', jobNumber);

        if (showOnlyPending) {
          franchiseQuery = franchiseQuery.eq('review_status', 'pending');
        }

        const { data: franchiseData, error: franchiseError } =
          await franchiseQuery;

        if (!franchiseError && franchiseData) {
          // Transform franchise images to match gallery_photos structure
          franchiseImages = franchiseData.map((img) => ({
            id: img.id,
            image_url: img.image_url,
            caption: null,
            gallery_id: galleryId,
            review_status: img.review_status,
          }));
        }
      }

      // Combine gallery photos with franchise images
      const allImages = [...(data || []), ...franchiseImages];

      // Log image URLs for debugging
      logImageUrls(allImages);

      setImages(allImages);
    } catch (error) {
      console.error('Error fetching gallery images:', error);
      toast.error('Failed to load gallery images');
    } finally {
      setLoading(false);
    }
  };

  const fetchGalleryCoverImage = async () => {
    try {
      const { data, error } = await supabase
        .from('galleries')
        .select('cover_image')
        .eq('id', galleryId)
        .single();

      if (error) throw error;

      setCoverImage(data.cover_image);
    } catch (error) {
      console.error('Error fetching gallery cover image:', error);
    }
  };

  const handleAddToGallery = async (imageUrl: string) => {
    try {
      // Check if image already exists in gallery
      const { data: existingPhotos, error: checkError } = await supabase
        .from('gallery_photos')
        .select('id')
        .eq('gallery_id', galleryId)
        .eq('image_url', imageUrl);

      if (checkError) throw checkError;

      if (existingPhotos && existingPhotos.length > 0) {
        toast.info('This image is already in the gallery');
        return;
      }

      // Get the highest sort order
      const { data: sortOrderData, error: sortError } = await supabase
        .from('gallery_photos')
        .select('sort_order')
        .eq('gallery_id', galleryId)
        .order('sort_order', { ascending: false })
        .limit(1);

      if (sortError) throw sortError;

      const nextSortOrder =
        sortOrderData && sortOrderData.length > 0
          ? sortOrderData[0].sort_order + 1
          : 0;

      // Add the image to gallery
      const { error: insertError } = await supabase
        .from('gallery_photos')
        .insert({
          gallery_id: galleryId,
          image_url: imageUrl,
          sort_order: nextSortOrder,
        });

      if (insertError) throw insertError;

      toast.success('Image added to gallery');
      fetchGalleryImages();
    } catch (error) {
      console.error('Error adding image to gallery:', error);
      toast.error('Failed to add image to gallery');
    }
  };

  const handleSetCoverImage = async (imageUrl: string) => {
    try {
      const { error } = await supabase
        .from('galleries')
        .update({ cover_image: imageUrl })
        .eq('id', galleryId);

      if (error) throw error;

      setCoverImage(imageUrl);
      toast.success('Cover image updated');
    } catch (error) {
      console.error('Error setting cover image:', error);
      toast.error('Failed to update cover image');
    }
  };

  const handleApprove = async (imageId: string) => {
    updateImageStatus(imageId, 'approved');
  };

  const handleReject = async (imageId: string) => {
    updateImageStatus(imageId, 'rejected');
  };

  const handlePrevImage = () => {
    setActiveImageIndex((prev) => (prev > 0 ? prev - 1 : images.length - 1));
  };

  const handleNextImage = () => {
    setActiveImageIndex((prev) => (prev < images.length - 1 ? prev + 1 : 0));
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className='max-w-4xl w-[90vw]'>
        <DialogHeader>
          <DialogTitle>
            {galleryTitle || 'Gallery Images'}
            {franchiseRegion &&
              jobNumber &&
              ` - Job #${jobNumber} (${franchiseRegion})`}
          </DialogTitle>
          {franchiseRegion && jobNumber && (
            <div className='mt-2'>
              <StatusFilter
                showOnlyPending={showOnlyPending}
                onShowOnlyPendingChange={setShowOnlyPending}
              />
            </div>
          )}
        </DialogHeader>

        <div className='py-4'>
          {loading ? (
            <div className='grid grid-cols-2 md:grid-cols-3 gap-4'>
              {[...Array(6)].map((_, i) => (
                <div key={i} className='aspect-square'>
                  <Skeleton className='h-full w-full' />
                </div>
              ))}
            </div>
          ) : images.length === 0 ? (
            <div className='text-center py-8 text-muted-foreground'>
              No images found for this gallery.
            </div>
          ) : (
            <SocialMediaImageGrid
              images={images}
              loading={loading}
              coverImage={coverImage || undefined}
              onAddToGallery={
                franchiseRegion && jobNumber ? handleAddToGallery : undefined
              }
              onSetCoverImage={handleSetCoverImage}
              onApprove={handleApprove}
              onReject={handleReject}
              approving={approving}
              rejecting={rejecting}
              activeImageIndex={activeImageIndex}
              setActiveImageIndex={setActiveImageIndex}
              onPrevImage={handlePrevImage}
              onNextImage={handleNextImage}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GallerySlideshow;
