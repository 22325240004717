
import React from 'react';
import { Skeleton } from '@/components/ui/skeleton';

const LoadingState: React.FC = () => {
  return (
    <div className="py-8 space-y-8">
      {/* Blog post list skeleton */}
      <div className="space-y-6">
        {[1, 2, 3].map((item) => (
          <div key={item} className="bg-white rounded-lg shadow overflow-hidden">
            <div className="flex flex-col md:flex-row">
              <div className="md:w-1/3">
                <Skeleton className="h-48 md:h-full w-full bg-gray-200" />
              </div>
              <div className="p-6 md:w-2/3 space-y-4">
                <Skeleton className="h-7 w-3/4 bg-gray-200" />
                <div className="space-y-2">
                  <Skeleton className="h-4 w-full bg-gray-200" />
                  <Skeleton className="h-4 w-5/6 bg-gray-200" />
                  <Skeleton className="h-4 w-4/5 bg-gray-200" />
                </div>
                <div className="pt-4 flex items-center space-x-4">
                  <Skeleton className="h-6 w-24 rounded-md bg-gray-200" />
                  <Skeleton className="h-4 w-32 bg-gray-200" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoadingState;
