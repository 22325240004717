
import React from 'react';
import AdminLayout from '@/components/admin/AdminLayout';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import GalleryTable from '@/components/admin/galleries/GalleryTable';
import GallerySearch from '@/components/admin/galleries/GallerySearch';
import { useGalleriesTable } from '@/hooks/gallery-table';
import { Button } from '@/components/ui/button';
import { PlusCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const AdminGalleries = () => {
  const navigate = useNavigate();
  const {
    filteredGalleries,
    loading,
    deleting,
    confirmDeleteId,
    searchQuery,
    setSearchQuery,
    sortField,
    sortDirection,
    handleSort,
    filterPublic,
    setFilterPublic,
    filterFranchise,
    setFilterFranchise,
    filterReviewStatus,
    setFilterReviewStatus,
    franchiseOptions,
    handleEditGallery,
    handlePreviewGallery,
    handleDeleteGallery,
    handleTogglePublic,
    handleUpdateReviewStatus,
    handleManagePhotos,
    totalCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize
  } = useGalleriesTable();

  return (
    <AdminLayout title="Galleries">
      <div className="flex justify-between items-center mb-6">
        <GallerySearch
          searchQuery={searchQuery}
          onSearchChange={setSearchQuery}
          filterPublic={filterPublic}
          onFilterChange={setFilterPublic}
          filterFranchise={filterFranchise}
          onFranchiseFilterChange={setFilterFranchise}
          franchiseOptions={franchiseOptions}
          filterReviewStatus={filterReviewStatus}
          onReviewStatusFilterChange={setFilterReviewStatus}
        />
        
        <Button 
          onClick={() => navigate('/admin/galleries/edit/new')}
          className="ml-4"
        >
          <PlusCircle className="mr-2 h-4 w-4" />
          New Gallery
        </Button>
      </div>

      <Card>
        <CardHeader>
          <CardTitle>Galleries</CardTitle>
        </CardHeader>
        <CardContent>
          {loading ? (
            <div className="py-10 text-center">Loading galleries...</div>
          ) : (
            <GalleryTable
              galleries={filteredGalleries}
              confirmDeleteId={confirmDeleteId}
              deleting={deleting}
              onEdit={handleEditGallery}
              onPreview={handlePreviewGallery}
              onDelete={handleDeleteGallery}
              onTogglePublic={handleTogglePublic}
              onUpdateReviewStatus={handleUpdateReviewStatus}
              onManagePhotos={handleManagePhotos}
              sortField={sortField}
              sortDirection={sortDirection}
              onSort={handleSort}
              totalCount={totalCount}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
          )}
        </CardContent>
      </Card>
    </AdminLayout>
  );
};

export default AdminGalleries;
