
import React, { useState, useEffect } from 'react';
import { Search, X } from 'lucide-react';
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useFranchiseImagesTable } from '@/hooks/franchise-images';
import { FranchiseImageFilters } from '@/hooks/franchise-images/types';
import { ReviewStatus } from '@/hooks/gallery-table/types';
import { useDebounce } from '@/hooks/useDebounce';

const FranchiseImagesToolbar: React.FC = () => {
  const { handleFilterChange, clearFilters, franchiseOptions, filters } = useFranchiseImagesTable();
  const [franchise, setFranchise] = useState<string>(filters.franchise || 'all');
  const [status, setStatus] = useState<ReviewStatus | 'all'>(filters.status as ReviewStatus || 'all');
  const [searchTerm, setSearchTerm] = useState(filters.searchTerm || '');
  
  // Increase debounce time to prevent excessive API calls
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  
  // Track previous filter values to prevent unnecessary updates
  const prevFiltersRef = React.useRef<FranchiseImageFilters>({});
  
  // Apply filters when debounced search term or dropdown selections change
  useEffect(() => {
    const newFilters: FranchiseImageFilters = {};
    
    if (franchise && franchise !== 'all') newFilters.franchise = franchise;
    if (status && status !== 'all') newFilters.status = status as ReviewStatus;
    if (debouncedSearchTerm) newFilters.searchTerm = debouncedSearchTerm;
    
    // Check if filters actually changed before applying
    const prevFiltersStr = JSON.stringify(prevFiltersRef.current);
    const newFiltersStr = JSON.stringify(newFilters);
    
    if (prevFiltersStr !== newFiltersStr) {
      console.log('Toolbar applying filters:', newFilters);
      prevFiltersRef.current = newFilters;
      handleFilterChange(newFilters);
    }
  }, [franchise, status, debouncedSearchTerm, handleFilterChange]);
  
  // Reset form state when filters are cleared externally
  useEffect(() => {
    if (Object.keys(filters).length === 0 && 
        (franchise !== 'all' || status !== 'all' || searchTerm !== '')) {
      setFranchise('all');
      setStatus('all');
      setSearchTerm('');
    }
  }, [filters, franchise, status, searchTerm]);
  
  const handleClearFilters = () => {
    setFranchise('all');
    setStatus('all');
    setSearchTerm('');
    prevFiltersRef.current = {};
    clearFilters();
  };
  
  // Handle search input with local debouncing to reduce state updates
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  
  return (
    <div className="space-y-4 mb-6">
      <div className="flex flex-col md:flex-row gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search by job number or file name..."
            className="pl-8"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        
        <Select value={franchise} onValueChange={setFranchise}>
          <SelectTrigger className="w-full md:w-[180px]">
            <SelectValue placeholder="Franchise Region" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Regions</SelectItem>
            {franchiseOptions.map((option) => (
              <SelectItem key={option} value={option}>
                {option}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        
        <Select 
          value={status} 
          onValueChange={(value) => setStatus(value as ReviewStatus | 'all')}
        >
          <SelectTrigger className="w-full md:w-[180px]">
            <SelectValue placeholder="Status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Statuses</SelectItem>
            <SelectItem value="pending">Pending</SelectItem>
            <SelectItem value="approved">Approved</SelectItem>
            <SelectItem value="rejected">Rejected</SelectItem>
          </SelectContent>
        </Select>
      </div>
      
      <div className="flex justify-end gap-2">
        <Button 
          variant="outline" 
          onClick={handleClearFilters}
          disabled={franchise === 'all' && status === 'all' && !searchTerm}
        >
          <X className="h-4 w-4 mr-2" />
          Clear Filters
        </Button>
      </div>
    </div>
  );
};

export default FranchiseImagesToolbar;
