
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getTrackingParams, saveTrackingParams } from '@/utils/urlParams';

const ScrollToTop = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo(0, 0);
    
    // Handle tracking parameters
    const urlParams = getTrackingParams();
    if (Object.keys(urlParams).length > 0) {
      saveTrackingParams(urlParams);
    }
  }, [pathname, search]);

  return null;
};

export default ScrollToTop;
