
import { useLocationLoader } from './useLocationLoader';
import { useLocationFormState } from './useLocationFormState';
import { useLocationSave } from './useLocationSave';

export function useLocationForm(id: string | undefined) {
  const { location, parentLocations, loading, isNewLocation } = useLocationLoader(id);
  const { formData, setFormData, handleChange } = useLocationFormState(location, isNewLocation);
  const { saving, saveLocation } = useLocationSave(location);

  const handleSave = async () => {
    await saveLocation(formData, isNewLocation);
  };

  return {
    formData,
    setFormData,
    location,
    parentLocations,
    loading,
    saving,
    isNewLocation,
    handleChange,
    handleSave
  };
}

// Export index file
export * from './useLocationFormState';
export * from './useLocationLoader';
export * from './useLocationSave';
