
// This utility provides a type-safe way to interact with the dataLayer for Google Tag Manager

// Define common event types
export type DataLayerEventName =
  | 'page_view'
  | 'scroll_depth'
  | 'button_click'
  | 'form_view'
  | 'form_submit'
  | 'form_error'
  | 'phone_call'
  | 'cta_click'
  | 'service_view'
  | 'gallery_view'
  | 'location_view'
  | 'custom_event';

// Basic interface for all events
export interface DataLayerEvent {
  event: DataLayerEventName;
  event_category?: string;
  event_action?: string;
  event_label?: string;
  event_value?: number;
  [key: string]: any; // Allow additional custom properties
}

// Specialized event interfaces for specific event types
export interface PageViewEvent extends DataLayerEvent {
  event: 'page_view';
  page_title: string;
  page_path: string;
  page_type?: string;
}

export interface FormEvent extends DataLayerEvent {
  event: 'form_view' | 'form_submit' | 'form_error';
  form_id: string;
  form_name?: string;
  form_type?: string;
  error_message?: string;
}

export interface ClickEvent extends DataLayerEvent {
  event: 'button_click' | 'cta_click';
  element_text?: string;
  element_destination?: string;
  element_type?: string;
  element_location?: string;
}

export interface CallEvent extends DataLayerEvent {
  event: 'phone_call';
  phone_number: string;
  call_source?: string;
}

export interface ContentViewEvent extends DataLayerEvent {
  event: 'service_view' | 'gallery_view' | 'location_view';
  content_id: string;
  content_name?: string;
  content_type: string;
}

export interface ScrollDepthEvent extends DataLayerEvent {
  event: 'scroll_depth';
  scroll_depth: number;
  scroll_unit: 'percent' | 'pixel';
}

// Main DataLayer class for handling GTM interactions
export class DataLayer {
  private static instance: DataLayer;
  private initialized: boolean = false;

  private constructor() {
    // Initialize the dataLayer if it doesn't exist
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      this.initialized = true;
    }
  }

  public static getInstance(): DataLayer {
    if (!DataLayer.instance) {
      DataLayer.instance = new DataLayer();
    }
    return DataLayer.instance;
  }

  // Push an event to the dataLayer
  public push<T extends DataLayerEvent>(data: T): void {
    if (this.initialized && typeof window !== 'undefined') {
      window.dataLayer.push(data);
    } else {
      console.warn('DataLayer not initialized or not in browser environment');
    }
  }

  // Specific method for tracking page views
  public trackPageView(data: Omit<PageViewEvent, 'event'>): void {
    this.push<PageViewEvent>({
      event: 'page_view',
      page_title: '',
      page_path: '',
      ...data,
    });
  }

  // Method for tracking form events
  public trackForm(
    eventType: 'form_view' | 'form_submit' | 'form_error',
    data: Omit<FormEvent, 'event'>
  ): void {
    this.push<FormEvent>({
      event: eventType,
      form_id: '',
      ...data,
    });
  }

  // Method for tracking button and CTA clicks
  public trackClick(
    eventType: 'button_click' | 'cta_click',
    data: Omit<ClickEvent, 'event'>
  ): void {
    this.push<ClickEvent>({
      event: eventType,
      ...data,
    });
  }

  // Method for tracking phone calls
  public trackCall(data: Omit<CallEvent, 'event'>): void {
    this.push<CallEvent>({
      event: 'phone_call',
      phone_number: '',
      ...data,
    });
  }

  // Method for tracking content views (services, galleries, locations)
  public trackContentView(
    eventType: 'service_view' | 'gallery_view' | 'location_view',
    data: Omit<ContentViewEvent, 'event'>
  ): void {
    this.push<ContentViewEvent>({
      event: eventType,
      content_id: '',
      content_type: '',
      ...data,
    });
  }

  // Method for tracking scroll depth
  public trackScrollDepth(data: Omit<ScrollDepthEvent, 'event'>): void {
    this.push<ScrollDepthEvent>({
      event: 'scroll_depth',
      scroll_depth: 0,
      scroll_unit: 'percent',
      ...data,
    });
  }
}

// For use in TypeScript with window.dataLayer
declare global {
  interface Window {
    dataLayer: any[];
  }
}

// Export a singleton instance
export const dataLayer = DataLayer.getInstance();
