import React from 'react';
import ContactInfo from './contact/ContactInfo';
import ServiceAreas from './contact/ServiceAreas';
import { ContactRequestForm } from './shared/contact-form';

const ContactSection = () => {
  return (
    <section className='section-padding' id='contact'>
      <div className='container mx-auto px-4'>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-12'>
          <div>
            <ContactInfo />
            <ServiceAreas />
          </div>
          <div>
            <ContactRequestForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
