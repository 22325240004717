
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Upload, X, Image as ImageIcon, Plus } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { v4 as uuidv4 } from 'uuid';

interface GalleryUploaderProps {
  images: string[];
  onImagesChange: (images: string[]) => void;
}

const GalleryUploader: React.FC<GalleryUploaderProps> = ({ 
  images, 
  onImagesChange 
}) => {
  const [uploading, setUploading] = useState(false);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];
    const fileExt = file.name.split('.').pop();
    const fileName = `${uuidv4()}.${fileExt}`;
    const filePath = `locations/${fileName}`;

    setUploading(true);

    try {
      // Upload file
      const { error: uploadError } = await supabase.storage
        .from('images')
        .upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('images')
        .getPublicUrl(filePath);

      // Add to gallery images
      onImagesChange([...images, publicUrl]);
      toast.success('Image added to gallery');
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Failed to upload image');
    } finally {
      setUploading(false);
      // Reset the input
      e.target.value = '';
    }
  };

  const handleRemoveImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    onImagesChange(newImages);
    toast.success('Image removed from gallery');
  };

  return (
    <div className="space-y-4">
      <Label>Gallery Images</Label>
      
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {images.map((url, index) => (
          <div key={index} className="relative group">
            <img 
              src={url} 
              alt={`Gallery ${index}`} 
              className="h-32 w-full object-cover rounded-md"
            />
            <Button
              variant="destructive"
              size="icon"
              className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={() => handleRemoveImage(index)}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        ))}
        
        <div className="h-32 flex items-center justify-center border-2 border-dashed border-gray-300 rounded-md p-2">
          <Input
            id="gallery-upload"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="hidden"
          />
          <Button
            type="button"
            variant="ghost"
            onClick={() => document.getElementById('gallery-upload')?.click()}
            disabled={uploading}
            className="h-full w-full flex flex-col items-center justify-center"
          >
            {uploading ? (
              <span className="animate-spin mb-2">⏳</span>
            ) : (
              <Plus className="h-8 w-8 mb-2" />
            )}
            <span className="text-xs">
              {uploading ? 'Uploading...' : 'Add Image'}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GalleryUploader;
