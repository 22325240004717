
import React from 'react';
import { useAssetManager } from '@/hooks/useAssetManager';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/components/ui/alert-dialog';

interface AssetDeleteFolderDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  folderId: string | null;
}

export const AssetDeleteFolderDialog: React.FC<AssetDeleteFolderDialogProps> = ({
  open,
  onOpenChange,
  folderId
}) => {
  const { deleteFolder } = useAssetManager();
  
  // Delete a folder
  const handleDeleteFolder = async () => {
    if (!folderId) return;
    
    try {
      await deleteFolder(folderId);
      onOpenChange(false);
    } catch (error) {
      console.error('Error deleting folder:', error);
    }
  };
  
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This will permanently delete this folder. This action cannot be undone.
            Note: You can only delete empty folders.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction 
            onClick={handleDeleteFolder}
            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
