
import React from 'react';
import { Link } from 'react-router-dom';
import { MapPin } from 'lucide-react';
import { Location } from '@/types/location';
import { getLocationAddress } from '@/lib/locationCache';

interface LocationViewProps {
  location: Location;
  parentLocation?: Location | null;
  childLocations?: Location[];
  sublocationSlug?: string;
}

const LocationView: React.FC<LocationViewProps> = ({ 
  location, 
  parentLocation, 
  childLocations = [],
  sublocationSlug 
}) => {
  // Get address from cache or use the one from the location object
  const address = location.address || getLocationAddress(location.slug);
  
  // For a sublocation, we shouldn't show the contact info card in the main content
  // This will be displayed in the sidebar component instead
  const isSubLocation = !!sublocationSlug || !!parentLocation;

  return (
    <div className="mb-12">
      <h1 className="text-3xl font-bold mb-6">{location.name}</h1>
      
      {/* Location description */}
      {location.description && (
        <div className="prose max-w-none mb-8">
          <div dangerouslySetInnerHTML={{ __html: location.description }} />
        </div>
      )}
    </div>
  );
};

export default LocationView;
