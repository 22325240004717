
import { LocationLinks } from '@/components/header/navigationData';

// This cache stores the addresses by location slug
export const locationAddressCache: Record<string, string> = {};

// Initialize the cache with hardcoded values from navigationData
export const initializeLocationCache = () => {
  LocationLinks.forEach(location => {
    if (location.address) {
      locationAddressCache[location.slug] = location.address;
    }
  });
};

// Get location address from cache or fallback
export const getLocationAddress = (locationSlug: string): string => {
  return locationAddressCache[locationSlug] || '';
};
