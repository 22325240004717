
import React from 'react';

export const EditorTips = () => {
  return (
    <div className="text-xs text-muted-foreground">
      <p>
        <span className="font-semibold">Tip:</span> Select text and use the formatting tools above or manually add HTML tags for advanced formatting.
      </p>
      <p className="mt-1">
        For images, use the image button or add <code>&lt;img src="URL" alt="description" /&gt;</code> directly.
      </p>
    </div>
  );
};
