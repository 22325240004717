
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { Location } from '@/types/location';
import { fetchLocationById, fetchParentLocations } from '@/utils/locationUtils';

export function useLocationLoader(id: string | undefined) {
  const navigate = useNavigate();
  const [location, setLocation] = useState<Location | null>(null);
  const [parentLocations, setParentLocations] = useState<Location[]>([]);
  const [loading, setLoading] = useState(true);
  const isNewLocation = id === 'new';

  // Load parent locations
  useEffect(() => {
    const loadParentLocations = async () => {
      const locations = await fetchParentLocations(id, isNewLocation);
      setParentLocations(locations);
    };

    loadParentLocations();
  }, [id, isNewLocation]);

  // Load location data if editing
  useEffect(() => {
    const loadLocation = async () => {
      if (isNewLocation) {
        setLoading(false);
        return;
      }

      try {
        if (!id) {
          throw new Error('Location ID is required');
        }
        
        const locationData = await fetchLocationById(id);
        
        if (locationData) {
          setLocation(locationData);
        } else {
          toast.error('Location not found');
          navigate('/admin/locations');
        }
      } catch (error) {
        console.error('Error loading location:', error);
        toast.error('Failed to load location data');
      } finally {
        setLoading(false);
      }
    };

    loadLocation();
  }, [id, isNewLocation, navigate]);

  return {
    location,
    parentLocations,
    loading,
    isNewLocation
  };
}
