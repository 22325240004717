
import React, { useState } from 'react';
import { ServiceDetail } from '@/types/service';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface ServiceFAQProps {
  service: ServiceDetail;
}

const ServiceFAQ: React.FC<ServiceFAQProps> = ({ service }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(0);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-3xl font-bold mb-10 text-center">Frequently Asked Questions</h2>
        <div className="max-w-3xl mx-auto">
          {service.faq.map((item, index) => (
            <div key={index} className="mb-4">
              <button
                className={`flex justify-between items-center w-full text-left p-5 rounded-lg ${
                  openIndex === index ? 'bg-white shadow-md' : 'bg-white/70 hover:bg-white/90'
                }`}
                onClick={() => toggleFAQ(index)}
              >
                <h3 className="text-lg font-semibold">{item.question}</h3>
                {openIndex === index ? (
                  <ChevronUp className="h-5 w-5 text-brick" />
                ) : (
                  <ChevronDown className="h-5 w-5 text-gray-600" />
                )}
              </button>
              {openIndex === index && (
                <div className="p-5 bg-white rounded-b-lg shadow-md -mt-1 border-t border-gray-100">
                  <p className="text-gray-700 leading-relaxed">{item.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceFAQ;
