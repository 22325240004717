
import { toast } from 'sonner';
import { supabase } from '@/lib/supabase';
import { Location } from '@/types/location';

// Function to generate a URL-friendly slug from text
export const generateSlug = (text: string): string => {
  return text
    .toLowerCase()
    .replace(/[^\w\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-')     // Replace spaces with hyphens
    .replace(/-+/g, '-')      // Replace multiple hyphens with a single hyphen
    .trim();                  // Trim any whitespace
};

// Function to validate a slug format
export const validateSlug = (slug: string): boolean => {
  const slugRegex = /^[a-z0-9-]+$/;
  return !!slug.trim() && slugRegex.test(slug);
};

// Function to fetch parent locations
export const fetchParentLocations = async (locationId?: string, isNewLocation: boolean = false) => {
  try {
    const { data, error } = await supabase
      .from('locations')
      .select('*')
      .eq('is_primary', true);
    
    if (error) {
      throw error;
    }
    
    // Filter out the current location if we're editing
    const filteredLocations = isNewLocation 
      ? data 
      : data.filter(loc => loc.id !== locationId);
    
    return filteredLocations;
  } catch (error) {
    console.error('Error loading parent locations:', error);
    toast.error('Failed to load parent locations');
    return [];
  }
};

// Function to fetch a single location by ID
export const fetchLocationById = async (id: string) => {
  try {
    const { data, error } = await supabase
      .from('locations')
      .select('*')
      .eq('id', id)
      .single();
    
    if (error) {
      throw error;
    }
    
    return data as Location;
  } catch (error) {
    console.error('Error loading location:', error);
    toast.error('Failed to load location data');
    return null;
  }
};
