import React, { useRef, useEffect, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { motion, AnimatePresence, useReducedMotion } from 'framer-motion';
import { X } from 'lucide-react';
import { cn } from '@/lib/utils';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
  className?: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  className = '',
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const prefersReducedMotion = useReducedMotion();
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  // Handle Escape key press
  useEffect(() => {
    const handleEscapeKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isOpen) {
        handleClose();
      }
    };

    window.addEventListener('keydown', handleEscapeKey);
    return () => window.removeEventListener('keydown', handleEscapeKey);
  }, [isOpen, handleClose]);

  // Handle clicking outside the modal
  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        handleClose();
      }
    };

    if (isOpen) {
      // Add a slight delay to prevent immediate closing when opening
      setTimeout(() => {
        document.addEventListener('mousedown', handleOutsideClick);
      }, 100);
    }

    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [isOpen, handleClose]);

  // Lock body scroll when modal is open
  useEffect(() => {
    const scrollY = window.scrollY;

    if (isOpen) {
      // Store current position and lock scroll
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
      document.body.style.overflow = 'hidden';
    } else if (document.body.style.position === 'fixed') {
      // Restore scroll position when closed
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      document.body.style.overflow = '';
      window.scrollTo(0, scrollY);
    }

    return () => {
      // Clean up scroll lock
      if (document.body.style.position === 'fixed') {
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        document.body.style.overflow = '';
        window.scrollTo(0, scrollY);
      }
    };
  }, [isOpen, handleClose]);

  // Move focus into modal when opened
  useEffect(() => {
    if (isOpen && modalRef.current) {
      const focusableElements = modalRef.current.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
      if (focusableElements.length) {
        (focusableElements[0] as HTMLElement).focus();
      }
    }
  }, [isOpen]);

  // Client-side only rendering via Portal
  if (typeof window === 'undefined') {
    return null;
  }

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <div className='fixed inset-0 z-50 flex items-center justify-center overflow-hidden'>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: prefersReducedMotion ? 0.1 : 0.2 }}
            className='absolute inset-0 bg-black/50 backdrop-blur-sm'
            aria-hidden='true'
            onClick={handleClose}
          />

          {/* Modal */}
          <motion.div
            ref={modalRef}
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 10 }}
            transition={{
              duration: prefersReducedMotion ? 0.15 : 0.2,
              type: 'spring',
              stiffness: 300,
              damping: 30,
            }}
            className={cn(
              'relative bg-white text-gray-900 rounded-lg shadow-2xl overflow-hidden max-h-[90vh] max-w-[95vw]',
              className
            )}
            role='dialog'
            aria-modal='true'
            aria-labelledby={title ? 'modal-title' : undefined}>
            {/* Close button */}
            <button
              onClick={handleClose}
              className='absolute top-4 right-4 z-10 p-2 rounded-full text-gray-600 hover:text-gray-900 hover:bg-gray-100 transition-colors'
              aria-label='Close modal'>
              <X className='h-5 w-5' />
            </button>

            {/* Title */}
            {title && (
              <div className='px-6 py-4 border-b border-gray-200'>
                <h2 id='modal-title' className='text-xl font-bold'>
                  {title}
                </h2>
              </div>
            )}

            {/* Content */}
            <div className='overflow-y-auto overscroll-contain max-h-[80vh]'>
              {children}
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default Modal;
