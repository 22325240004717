
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';

export const useFranchiseOptions = () => {
  const fetchFranchiseOptions = async () => {
    try {
      // Check auth state first
      const { data: session } = await supabase.auth.getSession();
      if (!session.session) {
        throw new Error("User not authenticated");
      }
      
      // Use a distinct query which is more efficient
      const { data, error } = await supabase
        .from('franchise_image_jobs')
        .select('franchise_region')
        .not('franchise_region', 'is', null)
        .order('franchise_region');
      
      if (error) {
        throw error;
      }
      
      // Since we're already filtering nulls in the query, just get unique values
      const uniqueFranchises = [...new Set(
        data.map(item => item.franchise_region)
      )];
      
      return uniqueFranchises;
    } catch (error: any) {
      console.error('Error fetching franchise options:', error);
      
      // Only show toast for non-auth errors - auth errors will be handled by the app's auth flow
      if (error.message !== "User not authenticated") {
        toast.error(`Failed to load franchise options: ${error.message}`);
      }
      
      throw new Error(error.message);
    }
  };

  // Use React Query to fetch and cache the franchise options
  const result = useQuery({
    queryKey: ['franchise-options'],
    queryFn: fetchFranchiseOptions,
    staleTime: 10 * 60 * 1000, // 10 minutes
    gcTime: 15 * 60 * 1000, // 15 minutes
    retry: 3,
  });

  return { 
    franchiseOptions: result.data || [],
    loading: result.isLoading,
    error: result.error ? (result.error as Error).message : null,
    refreshOptions: result.refetch
  };
};
