import React, { useState } from 'react';
import { useAssetManager } from '@/hooks/useAssetManager';
import { AssetUploader } from './AssetUploader';
import { AssetGrid } from './AssetGrid';
import { AssetList } from './AssetList';
import { AssetDetailView } from './AssetDetailView';
import { AssetToolbar } from './AssetToolbar';
import { AssetFolderSidebar } from './AssetFolderSidebar';
import { AssetSelectionToolbar } from './AssetSelectionToolbar';
import { AssetFolderDialog } from './dialogs/AssetFolderDialog';
import { AssetMoveDialog } from './dialogs/AssetMoveDialog';
import { AssetFolderSheet } from './dialogs/AssetFolderSheet';
import { AssetDeleteFolderDialog } from './dialogs/AssetDeleteFolderDialog';
import { FileArchive, RefreshCw } from 'lucide-react';
import { Button } from '@/components/ui/button';

export const AssetLibrary: React.FC = () => {
  const {
    assets,
    isLoading,
    selectedAssets,
    setSelectedAssets,
    filters,
    setFilters,
    currentFolder,
    setCurrentFolder,
    refreshAssets,
  } = useAssetManager();

  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [showFolderDialog, setShowFolderDialog] = useState(false);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [showFolderSheet, setShowFolderSheet] = useState(false);
  const [targetFolderId, setTargetFolderId] = useState(null);
  const [showDeleteFolderDialog, setShowDeleteFolderDialog] = useState(false);
  const [deleteFolderId, setDeleteFolderId] = useState(null);
  const [showArchivedAssets, setShowArchivedAssets] = useState(false);

  // Reset the selection when folder changes
  React.useEffect(() => {
    setSelectedAssets([]);
  }, [currentFolder, setSelectedAssets]);

  // Refresh the assets list
  React.useEffect(() => {
    refreshAssets();
  }, [refreshAssets]);

  // Update filters when showing archived assets
  React.useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      archived: showArchivedAssets,
    }));
  }, [showArchivedAssets, setFilters]);

  // Toggle selection of a single asset
  const handleSelectAsset = (id, isSelected) => {
    if (id === 'all') {
      if (isSelected) {
        // Select all visible assets
        setSelectedAssets(assets.map((asset) => asset.id));
      } else {
        // Deselect all
        setSelectedAssets([]);
      }
      return;
    }

    if (isSelected) {
      setSelectedAssets((prev) => [...prev, id]);
    } else {
      setSelectedAssets((prev) => prev.filter((assetId) => assetId !== id));
    }
  };

  return (
    <div className='flex flex-col h-full'>
      {/* Header */}
      <div className='flex justify-between items-center mb-6 pt-2'>
        <div>
          <h1 className='text-2xl font-semibold'>
            {showArchivedAssets ? 'Archived Assets' : 'Asset Library'}
          </h1>
          <div className='text-sm text-muted-foreground mt-1'>
            Manage your images, documents, and media files
          </div>
        </div>

        <div className='flex items-center gap-3'>
          <Button
            variant={showArchivedAssets ? 'default' : 'outline'}
            className='gap-2'
            onClick={() => setShowArchivedAssets(!showArchivedAssets)}
            size='sm'>
            {showArchivedAssets ? (
              <>
                <RefreshCw size={16} />
                Show Active
              </>
            ) : (
              <>
                <FileArchive size={16} />
                Show Archived
              </>
            )}
          </Button>

          <AssetUploader
            onComplete={refreshAssets}
            currentFolderId={currentFolder}
          />
        </div>
      </div>

      <div className='grid grid-cols-12 gap-6 h-full'>
        {/* Folder sidebar */}
        <div className='col-span-3 lg:col-span-2 h-full'>
          <AssetFolderSidebar
            currentFolder={currentFolder}
            onFolderSelect={setCurrentFolder}
            onCreateFolder={() => setShowFolderDialog(true)}
            onManageFolders={() => setShowFolderSheet(true)}
          />
        </div>

        {/* Main content */}
        <div className='col-span-9 lg:col-span-10 flex flex-col h-full'>
          {/* Toolbar */}
          <AssetToolbar
            currentFolder={currentFolder}
            setCurrentFolder={setCurrentFolder}
            filters={filters}
            setFilters={setFilters}
            viewMode={viewMode}
            setViewMode={setViewMode}
          />

          {/* Selection toolbar (when assets are selected) */}
          {selectedAssets.length > 0 && (
            <AssetSelectionToolbar
              selectedAssets={selectedAssets}
              showArchivedAssets={showArchivedAssets}
              onMove={() => setShowMoveDialog(true)}
            />
          )}

          {/* Assets list/grid */}
          <div className='flex-1 overflow-auto border rounded-md bg-white'>
            {isLoading ? (
              <div className='flex items-center justify-center h-full'>
                <div className='text-center'>
                  <div className='animate-spin rounded-full h-8 w-8 border-2 border-primary border-t-transparent mx-auto mb-2'></div>
                  <p className='text-muted-foreground'>Loading assets...</p>
                </div>
              </div>
            ) : viewMode === 'grid' ? (
              <div className='p-6'>
                <AssetGrid
                  assets={assets}
                  selectedAssets={selectedAssets}
                  onSelect={handleSelectAsset}
                  onAssetClick={setSelectedAsset}
                  onUploadClick={() => {
                    // Use a ref instead of querying the DOM
                    const uploadButton = document.querySelector(
                      '.asset-upload-trigger'
                    ) as HTMLButtonElement;
                    if (uploadButton) uploadButton.click();
                  }}
                  onCreateFolderClick={() => setShowFolderDialog(true)}
                />
              </div>
            ) : (
              <AssetList
                assets={assets}
                selectedAssets={selectedAssets}
                onSelect={handleSelectAsset}
                onAssetClick={setSelectedAsset}
              />
            )}
          </div>
        </div>
      </div>

      {/* Modals and detail views */}
      <AssetDetailView
        asset={selectedAsset}
        onClose={() => setSelectedAsset(null)}
      />

      <AssetFolderDialog
        open={showFolderDialog}
        onOpenChange={setShowFolderDialog}
        currentFolder={currentFolder}
      />

      <AssetMoveDialog
        open={showMoveDialog}
        onOpenChange={setShowMoveDialog}
        selectedAssets={selectedAssets}
        onTargetFolderChange={setTargetFolderId}
        targetFolderId={targetFolderId}
      />

      <AssetFolderSheet
        open={showFolderSheet}
        onOpenChange={setShowFolderSheet}
        onCreateFolder={() => {
          setShowFolderSheet(false);
          setShowFolderDialog(true);
        }}
        onDeleteFolder={(folderId) => {
          setDeleteFolderId(folderId);
          setShowDeleteFolderDialog(true);
        }}
      />

      <AssetDeleteFolderDialog
        open={showDeleteFolderDialog}
        onOpenChange={setShowDeleteFolderDialog}
        folderId={deleteFolderId}
      />
    </div>
  );
};
