
import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useServicesQuery } from '@/hooks/useServiceQuery';
import ServiceGrid from './services/ServiceGrid';

const ServicesSection = () => {
  const { data: services, isLoading } = useServicesQuery();
  
  // Filter for featured services when data is available
  const featuredServices = services?.filter(service => service.is_featured) || [];

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Our Services</h2>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
            We specialize in restoring and reinforcing the brickwork that keeps your home looking its best—whether it's a worn chimney, loose bricks, or cracks along your foundation.
          </p>
        </div>

        <ServiceGrid loading={isLoading} featuredServices={featuredServices} />

        <div className="text-center mt-12">
          <Button 
            className="bg-[#D00B0E] hover:bg-[#D00B0E]/90 text-white px-8 py-3 h-14 text-lg shadow-sm"
            asChild
          >
            <Link to="/services" className="inline-flex items-center">
              View All Services <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
