
import React from 'react';
import { Table, TableBody } from "@/components/ui/table";
import { ReviewStatus } from '@/hooks/gallery-table/types';
import GalleryTableHeader from './table/GalleryTableHeader';
import GalleryTableRow from './table/GalleryTableRow';
import GalleryTablePagination from './table/GalleryTablePagination';
import GallerySlideshow from './modals/GallerySlideshow';
import { useGalleryPreview } from '@/hooks/gallery-table/useGalleryPreview';

interface Gallery {
  id: string;
  title: string;
  slug: string;
  is_public: boolean;
  created_on: string;
  franchise_region: string | null;
  review_status: ReviewStatus;
  cover_image?: string | null;
}

interface GalleryTableProps {
  galleries: Gallery[];
  confirmDeleteId: string | null;
  deleting: boolean;
  sortField: string;
  sortDirection: 'asc' | 'desc';
  onSort: (field: any) => void;
  onEdit: (id: string) => void;
  onPreview: (slug: string) => void;
  onDelete: (id: string) => void;
  onTogglePublic: (id: string, currentStatus: boolean) => void;
  onUpdateReviewStatus: (id: string, status: ReviewStatus) => void;
  onManagePhotos: (id: string) => void;
  totalCount: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  pageSize: number;
  onPageSizeChange: (size: number) => void;
}

const GalleryTable: React.FC<GalleryTableProps> = ({
  galleries,
  confirmDeleteId,
  deleting,
  sortField,
  sortDirection,
  onSort,
  onEdit,
  onPreview,
  onDelete,
  onTogglePublic,
  onUpdateReviewStatus,
  onManagePhotos,
  totalCount,
  currentPage,
  onPageChange,
  pageSize,
  onPageSizeChange
}) => {
  const {
    slideshowOpen,
    previewGalleryId,
    galleryTitle,
    franchiseRegion,
    jobNumber,
    openGalleryPreview,
    closeGalleryPreview
  } = useGalleryPreview();

  if (galleries.length === 0) {
    return (
      <div className="text-center py-8 text-muted-foreground">
        No galleries found.
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="relative overflow-x-auto">
        <Table>
          <GalleryTableHeader 
            sortField={sortField}
            sortDirection={sortDirection}
            onSort={onSort}
          />
          <TableBody>
            {galleries.map((gallery) => (
              <GalleryTableRow
                key={gallery.id}
                gallery={gallery}
                confirmDeleteId={confirmDeleteId}
                deleting={deleting}
                onEdit={onEdit}
                onPreview={onPreview}
                onDelete={onDelete}
                onTogglePublic={onTogglePublic}
                onUpdateReviewStatus={onUpdateReviewStatus}
                onManagePhotos={onManagePhotos}
                onViewSlideshow={openGalleryPreview}
              />
            ))}
          </TableBody>
        </Table>
      </div>

      <GalleryTablePagination
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        itemsShown={galleries.length}
      />

      {/* Gallery Slideshow Modal */}
      <GallerySlideshow
        open={slideshowOpen}
        onOpenChange={(open) => {
          if (!open) closeGalleryPreview();
        }}
        galleryId={previewGalleryId || ''}
        galleryTitle={galleryTitle}
        franchiseRegion={franchiseRegion}
        jobNumber={jobNumber}
      />
    </div>
  );
};

export default GalleryTable;
