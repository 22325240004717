import React from 'react';

interface ServiceBackgroundProps {
  service: {
    hero_image?: string;
    image_url?: string;
  };
}

/**
 * ServiceBackground component for consistent rendering of service hero backgrounds
 * Uses the same approach as LocationBackground for consistency
 */
const ServiceBackground: React.FC<ServiceBackgroundProps> = ({ service }) => {
  // Check if we have a hero_image and use it, then fall back to other options
  if (!service.hero_image && !service.image_url) {
    return (
      <div
        className='absolute inset-0 bg-cover bg-center z-[-2]'
        style={{ backgroundImage: "url('/placeholder.svg')" }}
        data-image-source='default'></div>
    );
  }

  // Use CSS backgroundImage instead of an img tag for more reliable rendering
  const backgroundImageUrl =
    service.hero_image || service.image_url || '/placeholder.svg';
  const backgroundImage = `url("${backgroundImageUrl}")`;

  // Enhanced debugging - log complete URL and verify image URLs
  console.log('ServiceBackground rendering with:', {
    hero_image: service.hero_image,
    image_url: service.image_url,
    using: backgroundImageUrl,
    completeImageStyle: backgroundImage,
  });

  // Preload the image to verify it loads correctly
  React.useEffect(() => {
    const img = new Image();
    img.onload = () =>
      console.log(
        `✅ Background image loaded successfully: ${backgroundImageUrl}`
      );
    img.onerror = () =>
      console.error(
        `❌ Background image failed to load: ${backgroundImageUrl}`
      );
    img.src = backgroundImageUrl;
  }, [backgroundImageUrl]);

  return (
    <>
      {/* Background image div - with improved styling */}
      <div
        className='absolute inset-0 bg-cover bg-center z-0 overflow-hidden'
        style={{
          backgroundImage,
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
        data-image-source={
          service.hero_image
            ? 'hero_image'
            : service.image_url
            ? 'image_url'
            : 'default'
        }
        aria-hidden='true'></div>

      {/* Dark gradient overlay - adjusted z-index */}
      <div className='absolute inset-0 bg-gradient-to-b from-gray-900/70 to-gray-900/60 z-[1]'></div>
    </>
  );
};

export default ServiceBackground;
