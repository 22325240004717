
import React from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { PlusCircle, X, CheckCircle } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';

interface BenefitsFormProps {
  benefits: string[];
  addBenefit: () => void;
  removeBenefit: (index: number) => void;
  updateBenefit: (index: number, value: string) => void;
}

const BenefitsForm: React.FC<BenefitsFormProps> = ({
  benefits,
  addBenefit,
  removeBenefit,
  updateBenefit
}) => {
  return (
    <Card>
      <CardHeader className="pb-3">
        <CardTitle className="text-lg font-semibold flex items-center">
          <CheckCircle className="h-5 w-5 mr-2 text-D00B0E" />
          Service Benefits
        </CardTitle>
        <CardDescription>
          List the key benefits customers will receive from this service
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        {benefits && benefits.map((benefit, index) => (
          <div key={index} className="flex items-center gap-2 group">
            <div className="flex-shrink-0 w-6 h-6 rounded-full bg-red-50 text-D00B0E flex items-center justify-center">
              {index + 1}
            </div>
            <Input
              value={benefit}
              onChange={(e) => updateBenefit(index, e.target.value)}
              placeholder="Enter a benefit"
              className="flex-grow"
            />
            <Button
              type="button"
              variant="ghost"
              size="icon"
              onClick={() => removeBenefit(index)}
              className="flex-shrink-0 opacity-0 group-hover:opacity-100 transition-opacity"
            >
              <X className="w-4 h-4" />
            </Button>
          </div>
        ))}

        {(!benefits || benefits.length === 0) && (
          <div className="py-6 text-center text-gray-500">
            <p>No benefits added yet.</p>
            <p className="text-sm">Click the button below to add your first benefit</p>
          </div>
        )}

        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={addBenefit}
          className="flex items-center gap-1 mt-2 w-full justify-center"
        >
          <PlusCircle className="w-4 h-4" />
          Add Benefit
        </Button>
      </CardContent>
    </Card>
  );
};

export default BenefitsForm;
