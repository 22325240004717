
import { useState } from 'react';
import { GalleryFormValues } from '@/components/admin/galleries/form/types';
import { ReviewStatus } from '@/hooks/gallery-table/types';

export const useGalleryFormFields = (
  gallery: GalleryFormValues,
  setGallery: React.Dispatch<React.SetStateAction<GalleryFormValues>>
) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const target = e.target as HTMLInputElement;
    
    if (target.type === 'checkbox') {
      setGallery(prev => ({ ...prev, [name]: target.checked }));
    } else {
      setGallery(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSelectChange = (name: string, value: string) => {
    setGallery(prev => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (checked: boolean) => {
    setGallery(prev => ({ ...prev, is_public: checked }));
  };

  const handleImageUpload = (url: string) => {
    setGallery(prev => ({ ...prev, cover_image: url }));
  };

  const handleReviewStatusChange = (status: ReviewStatus) => {
    setGallery(prev => ({ ...prev, review_status: status }));
  };
  
  const handleServiceChange = (serviceId: string | null) => {
    setGallery(prev => ({ ...prev, service_id: serviceId }));
  };

  return {
    handleChange,
    handleSelectChange,
    handleCheckboxChange,
    handleImageUpload,
    handleReviewStatusChange,
    handleServiceChange
  };
};
