import React from 'react';
import { Search, Grid, List, SlidersHorizontal, X } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Asset, AssetFolder } from '@/hooks/useAssetManager';

interface AssetToolbarProps {
  currentFolder: string | null;
  setCurrentFolder: (folderId: string | null) => void;
  filters: {
    search?: string;
    [key: string]: any;
  };
  setFilters: React.Dispatch<React.SetStateAction<any>>;
  viewMode: 'grid' | 'list';
  setViewMode: (mode: 'grid' | 'list') => void;
}

export const AssetToolbar: React.FC<AssetToolbarProps> = ({
  currentFolder,
  setCurrentFolder,
  filters,
  setFilters,
  viewMode,
  setViewMode,
}) => {
  // Get breadcrumb path
  const getBreadcrumbPath = (): { id: string | null; name: string }[] => {
    // This is a simplified version that will be enhanced with folder data from context/props
    return [{ id: null, name: 'All Assets' }];
  };

  const breadcrumbPath = getBreadcrumbPath();

  return (
    <div className='mb-4 space-y-3 bg-muted/10 p-3 rounded-md border'>
      <div className='flex items-center justify-between'>
        {/* Breadcrumb */}
        <div className='flex items-center text-sm'>
          {breadcrumbPath.map((item, index) => (
            <div key={index} className='inline-flex items-center'>
              {index > 0 && (
                <span className='mx-1 text-muted-foreground'>/</span>
              )}
              <button
                className={`hover:underline transition-colors ${
                  index === breadcrumbPath.length - 1
                    ? 'font-medium'
                    : 'text-muted-foreground hover:text-foreground'
                }`}
                onClick={() => setCurrentFolder(item.id)}>
                {item.name}
              </button>
            </div>
          ))}
        </div>

        {/* View mode toggle */}
        <div className='flex items-center gap-2'>
          <div className='flex items-center border rounded-md overflow-hidden shadow-sm bg-background'>
            <Button
              variant={viewMode === 'grid' ? 'default' : 'ghost'}
              size='icon'
              className='rounded-none h-8 w-8'
              onClick={() => setViewMode('grid')}>
              <Grid size={14} />
            </Button>
            <Separator orientation='vertical' className='h-6' />
            <Button
              variant={viewMode === 'list' ? 'default' : 'ghost'}
              size='icon'
              className='rounded-none h-8 w-8'
              onClick={() => setViewMode('list')}>
              <List size={14} />
            </Button>
          </div>

          <Popover>
            <PopoverTrigger asChild>
              <Button 
                variant='outline' 
                size='icon' 
                className={`h-8 w-8 ${filters.type ? 'bg-primary/10 border-primary/20 text-primary' : ''}`}
              >
                <SlidersHorizontal size={14} />
              </Button>
            </PopoverTrigger>
            <PopoverContent className='w-72'>
              <div className='space-y-4'>
                <h4 className='font-medium'>Filter Assets</h4>

                <div className='space-y-2'>
                  <div className='text-sm font-medium'>File Type</div>
                  <div className='flex flex-wrap gap-2'>
                    <Button
                      variant={filters.type === 'image' ? 'default' : 'outline'}
                      size='sm'
                      className='text-xs h-7'
                      onClick={() =>
                        setFilters({
                          ...filters,
                          type: filters.type === 'image' ? undefined : 'image',
                        })
                      }>
                      Images
                    </Button>
                    <Button
                      variant={
                        filters.type === 'document' ? 'default' : 'outline'
                      }
                      size='sm'
                      className='text-xs h-7'
                      onClick={() =>
                        setFilters({
                          ...filters,
                          type:
                            filters.type === 'document'
                              ? undefined
                              : 'document',
                        })
                      }>
                      Documents
                    </Button>
                    <Button
                      variant={filters.type === 'other' ? 'default' : 'outline'}
                      size='sm'
                      className='text-xs h-7'
                      onClick={() =>
                        setFilters({
                          ...filters,
                          type: filters.type === 'other' ? undefined : 'other',
                        })
                      }>
                      Other
                    </Button>
                  </div>
                </div>

                <div className='pt-2'>
                  <Button
                    variant='ghost'
                    size='sm'
                    className='text-xs'
                    onClick={() => setFilters({ ...filters, type: undefined })}>
                    Clear filters
                  </Button>
                </div>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </div>

      {/* Search bar */}
      <div className='relative'>
        <Search
          size={16}
          className='absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground'
        />
        <Input
          placeholder='Search by filename, description, or tags...'
          className='pl-9 pr-9 bg-background'
          value={filters.search || ''}
          onChange={(e) => setFilters({ ...filters, search: e.target.value })}
        />
        {filters.search && (
          <Button
            variant='ghost'
            size='icon'
            className='absolute right-1 top-1/2 transform -translate-y-1/2 h-7 w-7'
            onClick={() => setFilters({ ...filters, search: '' })}>
            <X size={14} />
          </Button>
        )}
      </div>
      
      {/* Active filters indicator */}
      {(filters.type || filters.search) && (
        <div className="flex items-center text-sm text-muted-foreground">
          <span>Active filters:</span>
          {filters.type && (
            <span className="ml-2 bg-primary/10 text-primary px-2 py-0.5 rounded-full text-xs font-medium">
              {filters.type.charAt(0).toUpperCase() + filters.type.slice(1)}
            </span>
          )}
          {filters.search && (
            <span className="ml-2 bg-muted/50 px-2 py-0.5 rounded-full text-xs">
              Search: "{filters.search}"
            </span>
          )}
          <Button
            variant="ghost"
            className="ml-2 h-6 text-xs"
            onClick={() => setFilters({ archived: filters.archived })}
          >
            Clear all
          </Button>
        </div>
      )}
    </div>
  );
};
