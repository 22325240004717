
import React from 'react';
import { useAssetManager } from '@/hooks/useAssetManager';
import { Button } from '@/components/ui/button';
import { FileArchive, RefreshCw, Trash2 } from 'lucide-react';

interface AssetSelectionToolbarProps {
  selectedAssets: string[];
  showArchivedAssets: boolean;
  onMove: () => void;
}

export const AssetSelectionToolbar: React.FC<AssetSelectionToolbarProps> = ({
  selectedAssets,
  showArchivedAssets,
  onMove
}) => {
  const { archiveAsset, deleteAsset } = useAssetManager();
  
  // Archive selected assets
  const handleArchiveAssets = async () => {
    if (selectedAssets.length === 0) return;
    
    // Archive each selected asset
    for (const id of selectedAssets) {
      await archiveAsset(id, true);
    }
  };
  
  // Delete selected assets
  const handleDeleteAssets = async () => {
    if (selectedAssets.length === 0) return;
    
    // Delete each selected asset
    for (const id of selectedAssets) {
      await deleteAsset(id);
    }
  };
  
  // Restore selected assets from archive
  const handleRestoreAssets = async () => {
    if (selectedAssets.length === 0) return;
    
    // Restore each selected asset
    for (const id of selectedAssets) {
      await archiveAsset(id, false);
    }
  };
  
  return (
    <div className="mb-4 p-2 border rounded-md bg-muted/30 flex items-center justify-between">
      <div className="text-sm font-medium">
        {selectedAssets.length} {selectedAssets.length === 1 ? 'asset' : 'assets'} selected
      </div>
      
      <div className="flex items-center gap-2">
        <Button 
          variant="outline" 
          size="sm"
          onClick={onMove}
        >
          Move to folder
        </Button>
        
        {showArchivedAssets ? (
          <Button 
            variant="outline" 
            size="sm"
            onClick={handleRestoreAssets}
            className="gap-1"
          >
            <RefreshCw size={14} />
            Restore
          </Button>
        ) : (
          <Button 
            variant="outline" 
            size="sm"
            onClick={handleArchiveAssets}
            className="gap-1"
          >
            <FileArchive size={14} />
            Archive
          </Button>
        )}
        
        <Button 
          variant="destructive" 
          size="sm"
          onClick={handleDeleteAssets}
          className="gap-1"
        >
          <Trash2 size={14} />
          Delete
        </Button>
      </div>
    </div>
  );
};
