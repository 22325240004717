import React from 'react';
import { Button } from '@/components/ui/button';
import { Edit, Eye, CheckCircle, Clock, AlertTriangle } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Card, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Location } from '@/types/location';
import { getStatusIcon, updateLocationStatus } from './utils/locationStatus';

interface LocationMobileCardProps {
  location: Location;
  handleEdit: (id: string | number) => void;
  handleView: (slug: string) => void;
  locations: Location[];
  setLocations: React.Dispatch<React.SetStateAction<Location[]>>;
}

const LocationMobileCard: React.FC<LocationMobileCardProps> = ({
  location,
  handleEdit,
  handleView,
  locations,
  setLocations,
}) => {
  const handleStatusChange = async (id: string | number, newStatus: string) => {
    const updatedLocations = await updateLocationStatus(
      id,
      newStatus,
      locations
    );
    setLocations(updatedLocations);
  };

  // Render a styled status badge
  const renderStatusBadge = (status: string) => {
    if (status === 'published') {
      return (
        <Badge className='bg-green-100 text-green-800 hover:bg-green-200'>
          <CheckCircle className='h-3 w-3 mr-1' /> Published
        </Badge>
      );
    } else if (status === 'draft') {
      return (
        <Badge className='bg-yellow-100 text-yellow-800 hover:bg-yellow-200'>
          <Clock className='h-3 w-3 mr-1' /> Draft
        </Badge>
      );
    } else {
      return (
        <Badge className='bg-red-100 text-red-800 hover:bg-red-200'>
          <AlertTriangle className='h-3 w-3 mr-1' /> {status}
        </Badge>
      );
    }
  };

  // Render a location type badge
  const renderTypeBadge = (isPrimary: boolean) => {
    return isPrimary ? (
      <Badge className='bg-blue-100 text-blue-800 hover:bg-blue-100'>
        Primary
      </Badge>
    ) : (
      <Badge className='bg-gray-100 text-gray-800 hover:bg-gray-100'>
        Secondary
      </Badge>
    );
  };

  return (
    <Card className='overflow-hidden border'>
      <CardContent className='p-5'>
        <div className='flex justify-between items-start mb-3'>
          <div className='flex items-center'>
            {getStatusIcon(location.status)}
            <h3 className='font-medium ml-2'>{location.name}</h3>
          </div>
          <div className='flex space-x-1'>
            <Button
              variant='ghost'
              size='sm'
              onClick={() => handleView(location.slug)}
              title='View location'
              className='h-8 w-8 p-0'>
              <Eye className='h-4 w-4' />
            </Button>
            <Button
              variant='ghost'
              size='sm'
              onClick={() => handleEdit(location.id)}
              title='Edit location'
              className='h-8 w-8 p-0'>
              <Edit className='h-4 w-4' />
            </Button>
          </div>
        </div>

        <div className='text-sm text-muted-foreground mb-3'>
          /{location.slug}
        </div>

        <div className='flex flex-col space-y-3'>
          <div>
            <div className='text-xs font-medium text-muted-foreground mb-1'>
              Type
            </div>
            {renderTypeBadge(location.is_primary)}
          </div>

          <div>
            <div className='text-xs font-medium text-muted-foreground mb-1'>
              Status
            </div>
            <Select
              value={location.status || 'published'}
              onValueChange={(value) => handleStatusChange(location.id, value)}>
              <SelectTrigger className='h-9 w-full'>
                <SelectValue placeholder='Select Status' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='published'>Published</SelectItem>
                <SelectItem value='draft'>Draft</SelectItem>
                <SelectItem value='archived'>Archived</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default LocationMobileCard;
