
import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import SidebarHeader from './SidebarHeader';
import Navigation from './Navigation';
import LogoutButton from './LogoutButton';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  // Function to handle window resize and set mobile state
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth < 768) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    // Check on initial load
    checkIfMobile();
    
    // Add event listener
    window.addEventListener('resize', checkIfMobile);
    
    // Clean up
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return (
    <>
      {/* Mobile toggle button */}
      {isMobile && (
        <button 
          onClick={() => setIsOpen(!isOpen)}
          className="md:hidden fixed top-4 left-4 z-50 bg-white p-2 rounded-md shadow-md"
          aria-label="Toggle sidebar"
        >
          {isOpen ? <X size={20} /> : <Menu size={20} />}
        </button>
      )}
      
      {/* Sidebar */}
      <div className={`
        ${isOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'} 
        fixed md:relative h-screen bg-white shadow-md z-40
        transition-transform duration-300 ease-in-out
        md:w-64 w-64
      `}>
        <div className="h-full flex flex-col">
          <SidebarHeader />
          <div className="flex-1 overflow-y-auto">
            <Navigation />
          </div>
          <LogoutButton />
        </div>
      </div>
      
      {/* Overlay for mobile */}
      {isOpen && isMobile && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
          onClick={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default Sidebar;
