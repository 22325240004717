import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';

interface ProjectCardProps {
  title: string;
  location: string;
  beforeImage?: string;
  afterImage?: string;
  description: string;
}

const ProjectCard = ({
  title,
  location,
  beforeImage,
  afterImage,
  description,
}: ProjectCardProps) => {
  const [showAfter, setShowAfter] = useState(false);
  const [beforeLoaded, setBeforeLoaded] = useState(false);
  const [afterLoaded, setAfterLoaded] = useState(false);
  const [beforeError, setBeforeError] = useState(false);
  const [afterError, setAfterError] = useState(false);

  // Preload images
  useEffect(() => {
    if (beforeImage) {
      const img = new Image();
      img.src = beforeImage;
      img.onload = () => setBeforeLoaded(true);
      img.onerror = () => setBeforeError(true);
    }

    if (afterImage) {
      const img = new Image();
      img.src = afterImage;
      img.onload = () => setAfterLoaded(true);
      img.onerror = () => setAfterError(true);
    }
  }, [beforeImage, afterImage]);

  // Determine if we have valid images to show
  const hasValidBefore = beforeImage && beforeLoaded && !beforeError;
  const hasValidAfter = afterImage && afterLoaded && !afterError;
  const hasValidImages = hasValidBefore || hasValidAfter;

  return (
    <div className='bg-white rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300'>
      <div
        className='relative h-64 bg-gray-200 bg-cover bg-center transition-all duration-500'
        style={
          showAfter && hasValidAfter
            ? { backgroundImage: `url(${afterImage})` }
            : hasValidBefore
            ? { backgroundImage: `url(${beforeImage})` }
            : {}
        }>
        {hasValidImages && (
          <div className='absolute top-0 left-0 bg-orange text-white px-3 py-1 text-sm font-medium'>
            {showAfter && hasValidAfter ? 'AFTER' : 'BEFORE'}
          </div>
        )}

        {hasValidBefore && hasValidAfter && (
          <div className='absolute inset-0 flex items-center justify-between px-3'>
            <button
              onClick={() => setShowAfter(false)}
              className={`bg-black/40 text-white rounded-full p-2 hover:bg-black/60 transition-colors ${
                !showAfter ? 'opacity-60' : 'opacity-100'
              }`}
              aria-label='View before image'>
              <ChevronLeft size={20} />
            </button>
            <button
              onClick={() => setShowAfter(true)}
              className={`bg-black/40 text-white rounded-full p-2 hover:bg-black/60 transition-colors ${
                showAfter ? 'opacity-60' : 'opacity-100'
              }`}
              aria-label='View after image'>
              <ChevronRight size={20} />
            </button>
          </div>
        )}

        {hasValidImages && (
          <div className='absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-black/70 to-transparent'></div>
        )}

        {!hasValidImages && (
          <div className='flex items-center justify-center h-full'>
            <p className='text-gray-500'>Images coming soon</p>
          </div>
        )}
      </div>

      <div className='p-5'>
        <div className='flex justify-between items-start mb-3'>
          <div>
            <h3 className='text-xl font-bold'>{title}</h3>
            <p className='text-gray-500 text-sm'>{location}</p>
          </div>
        </div>
        <p className='text-gray-600 mb-4'>{description}</p>
        <Link
          to={`/projects/${title.toLowerCase().replace(/\s+/g, '-')}`}
          className='text-brick hover:text-brick-dark font-medium inline-flex items-center'>
          View project details <ArrowRight className='ml-2 h-4 w-4' />
        </Link>
      </div>
    </div>
  );
};

const ProjectsSection = () => {
  const projects = [
    {
      title: 'Chimney Restoration',
      location: 'Etobicoke, ON',
      description:
        'Complete chimney rebuild with modern flashing and a custom cap to prevent future water damage.',
    },
    {
      title: 'Brick Wall Repair',
      location: 'Scarborough, ON',
      description:
        'Structural brick wall repair with matching bricks and color-matched mortar for a seamless finish.',
    },
    {
      title: 'Stone Patio Installation',
      location: 'North York, ON',
      description:
        'Custom natural stone patio with integrated fire pit and seating wall for year-round outdoor enjoyment.',
    },
  ];

  return (
    <section className='section-padding'>
      <div className='container mx-auto px-4'>
        <div className='text-center mb-12'>
          <h2 className='text-3xl md:text-4xl font-bold mb-4'>
            Featured Projects
          </h2>
          <p className='text-lg text-gray-600 max-w-3xl mx-auto'>
            Browse our project gallery to see the quality of our work.
          </p>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
          {projects.map((project, index) => (
            <ProjectCard
              key={index}
              title={project.title}
              location={project.location}
              description={project.description}
            />
          ))}
        </div>

        <div className='text-center mt-12'>
          <Link to='/projects' className='btn-primary inline-flex items-center'>
            View All Projects <ArrowRight className='ml-2 h-5 w-5' />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;
