import { supabase } from '../client';

// Fetch all blog posts
export const fetchPosts = async () => {
  const { data, error } = await supabase
    .from('blog_posts')
    .select(`
      *,
      categories:blog_post_categories(
        category:blog_categories(*)
      ),
      tags:blog_post_tags(
        tag:blog_tags(*)
      )
    `)
    .order('created_at', { ascending: false });

  if (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }

  // Transform the data to flatten the nested structure
  return data.map(post => ({
    ...post,
    categories: post.categories?.map(c => c.category) || [],
    tags: post.tags?.map(t => t.tag) || []
  }));
};

// Fetch a single blog post by slug
export const fetchPostWithRelations = async (slug: string) => {
  const { data, error } = await supabase
    .from('blog_posts')
    .select(`
      *,
      categories:blog_post_categories(
        category:blog_categories(*)
      ),
      tags:blog_post_tags(
        tag:blog_tags(*)
      )
    `)
    .eq('slug', slug)
    .maybeSingle();

  if (error) {
    console.error('Error fetching post:', error);
    throw error;
  }

  if (!data) return null;

  // Transform the data to flatten the nested structure
  return {
    ...data,
    categories: data.categories?.map(c => c.category) || [],
    tags: data.tags?.map(t => t.tag) || []
  };
};

// Fetch a single blog post by id
export const fetchPostById = async (id: string) => {
  const { data, error } = await supabase
    .from('blog_posts')
    .select(`
      *,
      categories:blog_post_categories(
        category:blog_categories(*)
      ),
      tags:blog_post_tags(
        tag:blog_tags(*)
      ),
      seo:blog_seo(*)
    `)
    .eq('id', id)
    .single();

  if (error) {
    console.error('Error fetching post:', error);
    throw error;
  }

  if (!data) return null;

  return {
    ...data,
    categories: data.categories?.map(c => c.category) || [],
    tags: data.tags?.map(t => t.tag) || [],
    seo: data.seo || null
  };
};

// Posts CRUD operations
export const createPost = async (postData: any) => {
  const { data, error } = await supabase
    .from('blog_posts')
    .insert([postData])
    .select()
    .single();

  if (error) throw error;
  return data;
};

export const updatePost = async (id: string, postData: any) => {
  const { data, error } = await supabase
    .from('blog_posts')
    .update(postData)
    .eq('id', id)
    .select()
    .single();

  if (error) throw error;
  return data;
};

export const deletePost = async (id: string) => {
  const { error } = await supabase
    .from('blog_posts')
    .delete()
    .eq('id', id);

  if (error) throw error;
  return true;
};

// SEO operations
export const fetchPostSeo = async (postId: string) => {
  const { data, error } = await supabase
    .from('blog_seo')
    .select('*')
    .eq('post_id', postId)
    .maybeSingle();

  if (error) {
    console.error('Error fetching post SEO:', error);
    throw error;
  }

  return data;
};

export const updatePostSeo = async (postId: string, seoData: any) => {
  // First check if SEO data already exists for this post
  const existingSeo = await fetchPostSeo(postId);
  
  if (existingSeo) {
    // Update existing SEO data
    const { data, error } = await supabase
      .from('blog_seo')
      .update(seoData)
      .eq('post_id', postId)
      .select()
      .single();

    if (error) throw error;
    return data;
  } else {
    // Create new SEO data
    const { data, error } = await supabase
      .from('blog_seo')
      .insert([{ ...seoData, post_id: postId }])
      .select()
      .single();

    if (error) throw error;
    return data;
  }
};
