
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { GMBLocation } from '@/types/gmb-location';
import { GMBLocationTableRow } from './GMBLocationTableRow';

interface GMBLocationTableProps {
  locations: GMBLocation[] | undefined;
  getWebsiteLocationName: (locationId?: string) => string;
  onEdit: (location: GMBLocation) => void;
  onDelete: (locationId: string) => void;
  loading?: boolean;
}

export const GMBLocationTable: React.FC<GMBLocationTableProps> = ({
  locations,
  getWebsiteLocationName,
  onEdit,
  onDelete,
  loading = false
}) => {
  const tableHeaders = (
    <TableRow className="bg-muted/30">
      <TableHead className="font-semibold">Name</TableHead>
      <TableHead className="font-semibold">Address</TableHead>
      <TableHead className="font-semibold">City</TableHead>
      <TableHead className="font-semibold">Province</TableHead>
      <TableHead className="font-semibold">Status</TableHead>
      <TableHead className="font-semibold">Website Location</TableHead>
      <TableHead className="text-right font-semibold">Actions</TableHead>
    </TableRow>
  );

  if (loading) {
    return (
      <div className="rounded-md border bg-background">
        <Table>
          <TableHeader>
            {tableHeaders}
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell colSpan={7} className="h-24 text-center">
                <div className="flex flex-col items-center justify-center text-muted-foreground">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary mb-2"></div>
                  <p>Loading locations...</p>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  if (!locations || locations.length === 0) {
    return (
      <div className="rounded-md border bg-background">
        <Table>
          <TableHeader>
            {tableHeaders}
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell colSpan={7} className="h-24 text-center">
                <div className="flex flex-col items-center justify-center text-muted-foreground">
                  <p>No locations found.</p>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <div className="rounded-md border bg-background">
      <Table>
        <TableHeader>
          {tableHeaders}
        </TableHeader>
        <TableBody>
          {locations.map((location) => (
            <GMBLocationTableRow
              key={location.id}
              location={location}
              websiteLocationName={getWebsiteLocationName(location.location_id)}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
