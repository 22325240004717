
import React from 'react';
import { Link } from 'react-router-dom';

interface RelatedPost {
  id: string;
  title: string;
  slug: string;
  excerpt?: string;
  featured_image?: string;
}

interface RelatedPostsProps {
  posts: RelatedPost[];
}

const RelatedPosts: React.FC<RelatedPostsProps> = ({ posts }) => {
  return (
    <div className="mt-16">
      <h2 className="text-2xl font-bold mb-8 text-gray-900 border-b border-gray-200 pb-4">Related Posts</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {posts.map((post) => (
          <div key={post.id} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow">
            {post.featured_image && (
              <div className="h-48 overflow-hidden">
                <img 
                  src={post.featured_image} 
                  alt={post.title} 
                  className="w-full h-full object-cover transition-transform hover:scale-105 duration-300"
                />
              </div>
            )}
            <div className="p-6">
              <h3 className="text-lg font-bold mb-2">
                <Link to={`/blog/${post.slug}`} className="hover:text-brick transition-colors">
                  {post.title}
                </Link>
              </h3>
              {post.excerpt && (
                <p className="text-gray-700 mb-4 line-clamp-2">{post.excerpt}</p>
              )}
              <Link 
                to={`/blog/${post.slug}`} 
                className="inline-flex items-center text-brick hover:text-brick-dark font-medium transition-colors"
              >
                Read more
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-4 w-4 ml-2" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M14 5l7 7m0 0l-7 7m7-7H3" 
                  />
                </svg>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelatedPosts;
