
import React from 'react';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';

interface GalleryHeaderProps {
  title: string;
  description?: string;
  backLink?: string;
}

const GalleryHeader = ({ title, description, backLink }: GalleryHeaderProps) => {
  return (
    <div className="mb-8">
      {backLink && (
        <Button variant="outline" asChild className="mb-4">
          <Link to={backLink} className="flex items-center">
            <ChevronLeft className="mr-2 h-4 w-4" />
            Back to Galleries
          </Link>
        </Button>
      )}
      
      <h1 className="text-4xl font-bold mb-4">{title}</h1>
      {description && (
        <p className="text-lg text-gray-600 mb-8 max-w-3xl">{description}</p>
      )}
    </div>
  );
};

export default GalleryHeader;
