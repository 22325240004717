
import React from 'react';
import { Button, ButtonProps } from './button';
import { useInteractionTracking } from '@/hooks/useEventTracking';

export interface TrackedButtonProps extends ButtonProps {
  trackingLabel?: string;
  trackingDestination?: string;
  trackingLocation?: string;
  trackingData?: Record<string, any>;
}

/**
 * TrackedButton component - A button that tracks clicks through the analytics system
 */
const TrackedButton = React.forwardRef<HTMLButtonElement, TrackedButtonProps>(
  ({ 
    trackingLabel, 
    trackingDestination, 
    trackingLocation = 'unknown',
    trackingData,
    onClick, 
    children, 
    ...props 
  }, ref) => {
    // Initialize tracking
    const { trackButtonClick, trackCTAClick } = useInteractionTracking();
    
    // Handle click to track
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      // Determine if this is a CTA (has destination) or regular button
      if (trackingDestination) {
        trackCTAClick(
          trackingLabel || (typeof children === 'string' ? children : 'Unnamed CTA'),
          trackingDestination,
          trackingLocation,
          trackingData
        );
      } else {
        trackButtonClick(
          trackingLabel || (typeof children === 'string' ? children : 'Unnamed Button'),
          trackingLocation,
          trackingData
        );
      }
      
      // Call the original onClick handler if provided
      if (onClick) {
        onClick(e);
      }
    };
    
    return (
      <Button 
        ref={ref} 
        onClick={handleClick} 
        {...props}
      >
        {children}
      </Button>
    );
  }
);

TrackedButton.displayName = 'TrackedButton';

export { TrackedButton };
