
import React from 'react';
import { FieldInfo } from './types';

export const CREDENTIAL_FIELDS: FieldInfo[] = [
  {
    id: 'wp_url',
    label: 'WordPress Site URL*',
    tooltip: (
      <>
        <p className='mb-1'>Enter the URL of your WordPress site.</p>
        <p className='mb-1'>Example: https://example.com</p>
        <p>This should be the root URL, not the admin or API URL.</p>
      </>
    ),
    placeholder: 'https://your-wordpress-site.com',
    required: true,
  },
  {
    id: 'api_key',
    label: 'API Key / Application Password',
    tooltip: (
      <>
        <p className='mb-1'>
          <strong>Recommended authentication method.</strong>
        </p>
        <p className='mb-1'>
          Generate an Application Password from your WordPress admin:
        </p>
        <p className='mb-1'>→ Users → Profile → Application Passwords</p>
        <p>The key format will be like: abcd 1234 efgh 5678</p>
      </>
    ),
    placeholder: 'API key or application password',
  },
  {
    id: 'wp_username',
    label: 'Username (Legacy Auth - Optional)',
    tooltip:
      'Legacy authentication method. Using Application Passwords (API Key) is strongly recommended instead for better security.',
    placeholder: 'WordPress admin username',
  },
  {
    id: 'wp_password',
    label: 'Password (Legacy Auth - Optional)',
    tooltip:
      'Legacy authentication method. Using Application Passwords (API Key) is strongly recommended instead for better security.',
    placeholder: 'WordPress admin password',
    type: 'password',
  },
];
