
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

export const BlogPostRedirect = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      navigate(`/admin/blog/posts/edit/${id}`, { replace: true });
    }
  }, [id, navigate]);

  return null;
};
