
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchStaticPageById, updateStaticPage, createStaticPage } from '@/lib/supabase';
import { toast } from 'sonner';

interface PageData {
  id: string;
  title: string;
  content: string;
  slug: string;
  meta_description?: string;
}

interface FormData {
  title: string;
  content: string;
  meta_description: string;
  slug: string;
}

export function useStaticPageForm(id: string | undefined) {
  const navigate = useNavigate();
  const [pageData, setPageData] = useState<PageData | null>(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    title: '',
    content: '',
    meta_description: '',
    slug: ''
  });

  const isNewPage = id === 'new';

  // Generate a URL-friendly slug from a string
  const generateSlug = (text: string): string => {
    return text
      .toLowerCase()
      .replace(/[^\w\s-]/g, '') // Remove special characters
      .replace(/\s+/g, '-')     // Replace spaces with hyphens
      .replace(/-+/g, '-')      // Replace multiple hyphens with a single hyphen
      .trim();                  // Trim any whitespace
  };

  // Auto-generate slug when title changes (only for new pages)
  useEffect(() => {
    if (isNewPage && formData.title && !formData.slug) {
      setFormData(prev => ({
        ...prev,
        slug: generateSlug(prev.title)
      }));
    }
  }, [formData.title, isNewPage]);

  useEffect(() => {
    const loadPage = async () => {
      if (isNewPage) {
        setPageData({
          id: 'new',
          title: '',
          content: '',
          slug: '',
          meta_description: ''
        });
        setFormData({
          title: '',
          content: '',
          meta_description: '',
          slug: ''
        });
        setLoading(false);
        return;
      }

      try {
        const pageId = id;
        const page = await fetchStaticPageById(pageId);
        
        if (page) {
          setPageData(page);
          setFormData({
            title: page.title,
            content: page.content,
            meta_description: page.meta_description || '',
            slug: page.slug
          });
        } else {
          toast.error('Page not found');
          navigate('/admin/pages');
        }
      } catch (error) {
        console.error('Error loading page:', error);
        toast.error('Failed to load page data');
      } finally {
        setLoading(false);
      }
    };

    loadPage();
  }, [id, isNewPage, navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    
    if (name === 'slug') {
      // Apply slug formatting rules while typing
      const slugValue = generateSlug(value);
      setFormData({ ...formData, [name]: slugValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateSlug = (slug: string): boolean => {
    // Check if slug is not empty and matches valid URL pattern
    const slugRegex = /^[a-z0-9-]+$/;
    return !!slug.trim() && slugRegex.test(slug);
  };

  const handleSave = async () => {
    if (!formData.title.trim()) {
      toast.error('Title is required');
      return;
    }

    if (!formData.content.trim()) {
      toast.error('Content is required');
      return;
    }

    // For new pages, auto-generate slug from title if it's empty
    if (isNewPage && !formData.slug.trim()) {
      const generatedSlug = generateSlug(formData.title);
      setFormData(prev => ({ ...prev, slug: generatedSlug }));
      formData.slug = generatedSlug;
    }

    // Validate slug format
    if (!validateSlug(formData.slug)) {
      toast.error('Slug must contain only lowercase letters, numbers, and hyphens');
      return;
    }

    setSaving(true);

    try {
      if (pageData?.id && pageData.id !== 'new') {
        await updateStaticPage(pageData.id, {
          title: formData.title,
          content: formData.content,
          meta_description: formData.meta_description
        });
        toast.success('Page updated successfully');
      } else {
        await createStaticPage({
          title: formData.title,
          content: formData.content,
          slug: formData.slug,
          meta_description: formData.meta_description
        });
        toast.success('Page created successfully');
      }
      
      navigate('/admin/pages');
    } catch (error) {
      console.error('Error saving page:', error);
      toast.error('Failed to save page');
    } finally {
      setSaving(false);
    }
  };

  return {
    formData,
    pageData,
    loading,
    saving,
    isNewPage,
    handleChange,
    handleSave
  };
}
