
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { NavigationItem as NavItemType } from './navigationConfig';

interface NavigationItemProps {
  item: NavItemType;
}

const NavigationItem: React.FC<NavigationItemProps> = ({ item }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { path, name, icon: Icon } = item;
  const isActive = location.pathname === path || location.pathname.startsWith(`${path}/`);
  
  return (
    <li>
      <Button 
        variant={isActive ? "secondary" : "ghost"} 
        className="w-full justify-start"
        onClick={() => navigate(path)}
      >
        <Icon className="mr-2 h-4 w-4" />
        {name}
      </Button>
    </li>
  );
};

export default NavigationItem;
