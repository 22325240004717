
import { useGalleryDelete } from './useGalleryDelete';
import { useGalleryNavigation } from './useGalleryNavigation';
import { useGalleryVisibility } from './useGalleryVisibility';
import { useGalleryReviewStatus } from './useGalleryReviewStatus';
import { ReviewStatus } from './types';

export const useGalleryActions = (galleries: any[], setGalleries: React.Dispatch<React.SetStateAction<any[]>>) => {
  // Gallery deletion
  const {
    deleting,
    confirmDeleteId,
    handleDeleteGallery,
    setConfirmDeleteId
  } = useGalleryDelete(galleries, setGalleries);

  // Gallery navigation
  const {
    handleEditGallery,
    handlePreviewGallery,
    handleManagePhotos
  } = useGalleryNavigation();

  // Gallery visibility
  const {
    handleTogglePublic
  } = useGalleryVisibility(galleries, setGalleries);

  // Gallery review status
  const {
    handleUpdateReviewStatus
  } = useGalleryReviewStatus(galleries, setGalleries);

  return {
    deleting,
    confirmDeleteId,
    handleEditGallery,
    handlePreviewGallery,
    handleDeleteGallery,
    handleTogglePublic,
    handleUpdateReviewStatus,
    handleManagePhotos
  };
};
