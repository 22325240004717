
import React from 'react';
import { navigationItems } from './navigationConfig';
import NavigationItem from './NavigationItem';

const Navigation = () => {
  return (
    <nav className="p-2">
      <div className="space-y-4">
        {navigationItems.map((category) => (
          <div key={category.category} className="space-y-1">
            <h3 className="font-semibold text-sm text-muted-foreground px-2">
              {category.category}
            </h3>
            <ul className="space-y-1">
              {category.items.map((item) => (
                <NavigationItem key={item.path} item={item} />
              ))}
            </ul>
          </div>
        ))}
      </div>
    </nav>
  );
};

export default Navigation;
