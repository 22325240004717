import React from 'react';
import { SiteSettings } from '@/types/settings';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Switch } from '@/components/ui/switch';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { InfoIcon, Upload } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';

interface SEOTabProps {
  settings: SiteSettings;
  saving: boolean;
  handleChange: (field: keyof SiteSettings, value: any) => void;
}

const SEOTab: React.FC<SEOTabProps> = ({ settings, saving, handleChange }) => {
  const [uploadingOgImage, setUploadingOgImage] = React.useState(false);
  
  const handleOgImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (file.size > 2 * 1024 * 1024) {
      toast.error("OG Image file is too large. Maximum size is 2MB.");
      return;
    }

    if (!file.type.startsWith('image/')) {
      toast.error("Please upload a valid image file.");
      return;
    }

    try {
      setUploadingOgImage(true);
      
      const fileName = `og-image-${Date.now()}.${file.name.split('.').pop()}`;
      const { error: uploadError } = await supabase.storage
        .from('site-assets')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      const { data: publicUrlData } = supabase.storage
        .from('site-assets')
        .getPublicUrl(fileName);

      if (publicUrlData) {
        handleChange('default_og_image', publicUrlData.publicUrl);
        toast.success("OG Image uploaded successfully");
      }
    } catch (error) {
      console.error("Error uploading OG Image:", error);
      toast.error("Failed to upload OG Image. Please try again.");
    } finally {
      setUploadingOgImage(false);
    }
  };

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>SEO Settings</CardTitle>
          <CardDescription>
            Configure search engine optimization (SEO) settings for your website
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <Alert className="bg-blue-50 border-blue-200">
            <InfoIcon className="h-4 w-4 text-blue-500" />
            <AlertDescription className="text-blue-700">
              These settings provide default metadata for your site when specific page metadata is not available.
              They help improve your site's visibility in search engines.
            </AlertDescription>
          </Alert>

          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="default_meta_title">Default Page Title</Label>
              <Input
                id="default_meta_title"
                value={settings.default_meta_title || ''}
                onChange={(e) => handleChange('default_meta_title', e.target.value)}
                placeholder="Fix My Brick | Professional Masonry Services"
                disabled={saving}
                className="w-full max-w-md"
              />
              <p className="text-sm text-gray-500">
                The default title for pages that don't specify a custom title. This appears in browser tabs and search results.
              </p>
            </div>

            <div className="space-y-2">
              <Label htmlFor="default_meta_description">Default Meta Description</Label>
              <Textarea
                id="default_meta_description"
                value={settings.default_meta_description || ''}
                onChange={(e) => handleChange('default_meta_description', e.target.value)}
                placeholder="Fix My Brick provides professional brick and masonry repair services across Ontario..."
                disabled={saving}
                className="w-full max-w-md h-24"
              />
              <p className="text-sm text-gray-500">
                The default description used in search results. Keep it under 160 characters for best results.
              </p>
            </div>

            <div className="space-y-2">
              <Label>Default Open Graph Image</Label>
              <div className="flex items-start gap-4">
                <div className="w-32 h-32 bg-gray-100 rounded-md overflow-hidden relative">
                  {settings.default_og_image ? (
                    <img 
                      src={settings.default_og_image} 
                      alt="OG Image" 
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center text-gray-400">
                      No image
                    </div>
                  )}
                </div>
                <div className="flex-1">
                  <Label htmlFor="og_image_upload" className="cursor-pointer inline-flex items-center px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-md">
                    <Upload className="h-4 w-4 mr-2" />
                    {uploadingOgImage ? 'Uploading...' : 'Upload Image'}
                    <input
                      id="og_image_upload"
                      type="file"
                      accept="image/*"
                      onChange={handleOgImageUpload}
                      disabled={saving || uploadingOgImage}
                      className="hidden"
                    />
                  </Label>
                  <p className="text-sm text-gray-500 mt-2">
                    Image displayed when your site is shared on social media. Optimal size: 1200 x 630 pixels.
                  </p>
                </div>
              </div>
            </div>

            <div className="space-y-2 pt-4 border-t">
              <div className="flex items-center justify-between">
                <div>
                  <Label htmlFor="enable_breadcrumbs" className="text-base font-medium">Enable Breadcrumbs</Label>
                  <p className="text-sm text-gray-500">
                    Show breadcrumb navigation on pages to improve user experience and SEO.
                  </p>
                </div>
                <Switch
                  id="enable_breadcrumbs"
                  checked={settings.enable_breadcrumbs !== false}
                  onCheckedChange={(checked) => handleChange('enable_breadcrumbs', checked)}
                  disabled={saving}
                />
              </div>
            </div>

            <div className="space-y-2 pt-4 border-t">
              <div className="flex items-center justify-between">
                <div>
                  <Label htmlFor="enable_structured_data" className="text-base font-medium">Enable Structured Data</Label>
                  <p className="text-sm text-gray-500">
                    Add schema.org markup to help search engines understand your content.
                  </p>
                </div>
                <Switch
                  id="enable_structured_data"
                  checked={settings.enable_structured_data !== false}
                  onCheckedChange={(checked) => handleChange('enable_structured_data', checked)}
                  disabled={saving}
                />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SEOTab;