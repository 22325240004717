
import React from 'react';
import { Label } from '@/components/ui/label';
import { Info } from 'lucide-react';

export const ContentHeader = () => {
  return (
    <div className="flex items-center gap-2">
      <Label htmlFor="content">Content</Label>
      <div className="flex items-center text-xs text-muted-foreground">
        <Info className="h-3 w-3 mr-1" />
        HTML formatting is supported
      </div>
    </div>
  );
};
