
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from '@/components/header';
import Footer from '@/components/Footer';

const NotFound = () => {
  const location = useLocation();

  useEffect(() => {
    // Log detailed information about the 404 error
    const referrer = document.referrer;
    console.error(
      "404 Error: User attempted to access non-existent route:",
      location.pathname,
      "\nReferrer:", referrer || "None",
      "\nCheck routes/index.ts for proper route configuration."
    );
    
    // If it's an admin route, provide more specific guidance
    if (location.pathname.startsWith('/admin')) {
      console.info(
        "TIP: This may be an admin route mismatch. " +
        "Check if the route is defined in ADMIN_ROUTES in routes/index.ts " +
        "and that components are using ADMIN_ROUTES constants instead of hardcoded paths."
      );
    }
  }, [location.pathname]);

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow flex items-center justify-center py-20">
        <div className="container mx-auto px-4 text-center">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg mx-auto">
            <div className="text-brick mb-4">
              <svg className="w-20 h-20 mx-auto" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 8V12M12 16H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <h1 className="text-4xl font-bold mb-4">404</h1>
            <p className="text-xl text-gray-600 mb-6">Oops! We couldn't find that page.</p>
            <p className="text-gray-600 mb-8">
              The page you're looking for might have been removed, had its name changed, or is temporarily unavailable.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <Link to="/" className="btn-primary">
                Return to Home
              </Link>
              <Link to="/contact" className="btn-outline">
                Contact Support
              </Link>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default NotFound;
