
import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import {
  MigrationCredentials,
  MigrationHistory,
} from '@/lib/supabase/wordpress';
import { FileUp, Loader2 } from 'lucide-react';
import MigrationConfirmDialog from './MigrationConfirmDialog';
import MigrationStats from './MigrationStats';
import MigrationStatus from './MigrationStatus';

interface MigrationControlsProps {
  credentials: MigrationCredentials | null;
  onStartMigration: () => Promise<any>;
  migrating: boolean;
  history: MigrationHistory[];
}

const MigrationControls: React.FC<MigrationControlsProps> = ({
  credentials,
  onStartMigration,
  migrating,
  history,
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [migrationError, setMigrationError] = useState<string | null>(null);
  const hasPreviousMigration = history.length > 0;
  const lastMigration = history.length > 0 ? history[0] : null;
  const canMigrate = Boolean(credentials?.wp_url);

  const handleStartMigration = async () => {
    try {
      setMigrationError(null);
      setShowConfirmDialog(false);
      await onStartMigration();
    } catch (error) {
      console.error('Error starting migration:', error);
      setMigrationError(
        error instanceof Error ? error.message : 'Failed to start migration'
      );
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>WordPress Migration</CardTitle>
          <CardDescription>
            Migrate your WordPress blog posts, categories, and tags to your new
            site
          </CardDescription>
        </CardHeader>
        <CardContent>
          <MigrationStatus 
            credentials={credentials}
            hasPreviousMigration={hasPreviousMigration}
            migrationError={migrationError}
          />

          <MigrationStats lastMigration={lastMigration} />
        </CardContent>
        <CardFooter>
          <Button
            onClick={
              hasPreviousMigration
                ? () => setShowConfirmDialog(true)
                : handleStartMigration
            }
            disabled={!canMigrate || migrating}
            className='w-full'>
            {migrating ? (
              <>
                <Loader2 className='mr-2 h-4 w-4 animate-spin' />
                Migration in Progress...
              </>
            ) : (
              <>
                <FileUp className='mr-2 h-4 w-4' />
                {hasPreviousMigration
                  ? 'Run Migration Again'
                  : 'Start Migration'}
              </>
            )}
          </Button>
        </CardFooter>
      </Card>

      <MigrationConfirmDialog
        open={showConfirmDialog}
        onOpenChange={setShowConfirmDialog}
        onConfirm={handleStartMigration}
      />
    </>
  );
};

export default MigrationControls;
