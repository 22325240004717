
import React from 'react';
import PostCard from './PostCard';

interface PostsListProps {
  posts: Array<{
    id: string;
    title: string;
    slug: string;
    excerpt?: string;
    published_at?: string;
    featured_image?: string;
    blog_categories?: Array<{
      id: string;
      name: string;
      slug: string;
    }>;
  }>;
}

const PostsList: React.FC<PostsListProps> = ({ posts }) => {
  if (posts.length === 0) {
    return (
      <div className="bg-white p-8 rounded-lg shadow text-center">
        <p className="text-gray-600">No blog posts available yet. Check back soon!</p>
      </div>
    );
  }
  
  return (
    <div className="space-y-8">
      {posts.map((post) => (
        <PostCard key={post.id} post={post} />
      ))}
    </div>
  );
};

export default PostsList;
