
import React from 'react';
import Header from '@/components/header';
import Footer from '@/components/Footer';

const GalleryLoading: React.FC = () => {
  return (
    <>
      <Header />
      <main className="container mx-auto px-4 py-12">
        <div className="py-20 text-center">Loading gallery...</div>
      </main>
      <Footer />
    </>
  );
};

export default GalleryLoading;
