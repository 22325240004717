
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useTrackingParams } from '@/hooks/useTrackingParams';
import { useIsMobile } from '@/hooks/use-mobile';

interface ModalContextType {
  isContactModalOpen: boolean;
  openContactModal: (serviceSlug?: string, locationName?: string) => void;
  closeContactModal: () => void;
  serviceSlug: string;
  locationName: string;
  isMobile: boolean;
  trackingParams: Record<string, string>;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [serviceSlug, setServiceSlug] = useState('');
  const [locationName, setLocationName] = useState('');
  const isMobile = useIsMobile();
  const trackingParams = useTrackingParams();

  const openContactModal = (service = '', location = '') => {
    setServiceSlug(service);
    setLocationName(location);
    setIsContactModalOpen(true);
  };

  const closeContactModal = () => {
    setIsContactModalOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        isContactModalOpen,
        openContactModal,
        closeContactModal,
        serviceSlug,
        locationName,
        isMobile,
        trackingParams
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
