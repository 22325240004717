
import React from 'react';
import { ServiceDetail } from '@/types/service';

interface ServiceProcessStepsProps {
  service: ServiceDetail;
}

const ServiceProcessSteps: React.FC<ServiceProcessStepsProps> = ({ service }) => {
  if (!service.process_steps || service.process_steps.length === 0) {
    return null;
  }

  return (
    <section className="py-12 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Our Process</h2>
        <div className="max-w-4xl mx-auto">
          {service.process_steps.map((step, index) => (
            <div key={index} className="mb-8 flex items-start">
              <div className="mr-4 pt-1 text-[#D00B0E]">
                <span className="flex items-center justify-center w-8 h-8 rounded-full bg-[#D00B0E] text-white font-bold">
                  {index + 1}
                </span>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceProcessSteps;
