
import React from 'react';
import { CheckCircle2, Info, AlertTriangle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { MigrationCredentials } from '@/lib/supabase/wordpress';

interface MigrationStatusProps {
  credentials: MigrationCredentials | null;
  hasPreviousMigration: boolean;
  migrationError: string | null;
}

const MigrationStatus: React.FC<MigrationStatusProps> = ({
  credentials,
  hasPreviousMigration,
  migrationError,
}) => {
  const canMigrate = Boolean(credentials?.wp_url);

  if (migrationError) {
    return (
      <Alert variant='destructive' className='mb-4'>
        <AlertTriangle className='h-4 w-4' />
        <AlertTitle>Migration Failed</AlertTitle>
        <AlertDescription>{migrationError}</AlertDescription>
      </Alert>
    );
  }

  if (!canMigrate) {
    return (
      <div className='bg-amber-50 border-l-4 border-amber-400 p-4'>
        <div className='flex'>
          <div className='flex-shrink-0'>
            <AlertTriangle className='h-5 w-5 text-amber-400' />
          </div>
          <div className='ml-3'>
            <p className='text-sm text-amber-700'>
              Please enter your WordPress URL before starting migration.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='space-y-4'>
      <div className='flex items-start gap-3'>
        {hasPreviousMigration ? (
          <CheckCircle2 className='h-5 w-5 text-green-500 mt-0.5 flex-shrink-0' />
        ) : (
          <Info className='h-5 w-5 text-blue-500 mt-0.5 flex-shrink-0' />
        )}
        <div>
          <p className='font-medium'>
            {hasPreviousMigration
              ? 'Ready to update content from'
              : 'Ready to migrate content from'}{' '}
            <strong>{credentials?.wp_url}</strong>
          </p>
          <p className='text-sm text-gray-500 mt-1'>
            This will import all blog posts, categories, and tags from your
            WordPress site.
            {hasPreviousMigration &&
              ' New content will be added while existing content with matching slugs will be skipped.'}
          </p>
        </div>
      </div>

      <Alert className='bg-gray-50'>
        <CheckCircle2 className='h-4 w-4' />
        <AlertTitle>Duplicate Prevention</AlertTitle>
        <AlertDescription>
          The migration process checks for existing slugs and skips any
          duplicates, making it safe to run multiple times.
        </AlertDescription>
      </Alert>
    </div>
  );
};

export default MigrationStatus;
