import { supabase } from './client';

// Function to fetch locations
export async function fetchLocations(signal?: AbortSignal) {
  try {
    const { data, error } = await supabase
      .from('locations')
      .select('*')
      .abortSignal(signal);

    if (error) {
      console.error('Error fetching locations:', error);
      return [];
    }

    return data || [];
  } catch (error) {
    if (signal?.aborted) {
      console.log('Locations fetch aborted');
      throw new Error('Request aborted');
    }

    console.error('Error in fetchLocations:', error);
    return [];
  }
}

// Function to fetch primary locations
export async function fetchPrimaryLocations(signal?: AbortSignal) {
  try {
    const { data, error } = await supabase
      .from('locations')
      .select('*')
      .eq('is_primary', true)
      .abortSignal(signal);

    if (error) {
      console.error('Error fetching primary locations:', error);
      return [];
    }

    return data || [];
  } catch (error) {
    if (signal?.aborted) {
      console.log('Primary locations fetch aborted');
      throw new Error('Request aborted');
    }

    console.error('Error in fetchPrimaryLocations:', error);
    return [];
  }
}

// Function to fetch location by slug
export async function fetchLocationBySlug(slug: string, signal?: AbortSignal) {
  try {
    const { data, error } = await supabase
      .from('locations')
      .select('*')
      .eq('slug', slug)
      .abortSignal(signal)
      .maybeSingle();

    if (error) {
      console.error(`Error fetching location with slug ${slug}:`, error);
      return null;
    }

    return data;
  } catch (error) {
    if (signal?.aborted) {
      console.log(`Location fetch for ${slug} aborted`);
      throw new Error('Request aborted');
    }

    console.error(`Error in fetchLocationBySlug for ${slug}:`, error);
    return null;
  }
}

// Function to fetch child locations for a parent location
export async function fetchChildLocations(
  parentId: string,
  signal?: AbortSignal
) {
  try {
    // Early abort check
    if (signal?.aborted) {
      console.log(`Child locations fetch for parent ${parentId} aborted before starting`);
      throw new Error('Request aborted');
    }
    
    // Check if parentId is a valid UUID before querying
    // UUID validation - must be in the correct format for Postgres
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    if (!uuidRegex.test(parentId)) {
      console.log(`Invalid UUID format for parent_id: ${parentId}, returning empty array`);
      return [];
    }

    const { data, error } = await supabase
      .from('locations')
      .select('*')
      .eq('parent_id', parentId)
      .abortSignal(signal);

    if (error) {
      // Check if error is abort-related
      if (error.message?.includes('abort') || error.message?.includes('cancel')) {
        console.log(`Child locations fetch for parent ${parentId} aborted during query`);
        throw new Error('Request aborted');
      }
      console.error(
        `Error fetching child locations for parent ${parentId}:`,
        error
      );
      return [];
    }

    return data || [];
  } catch (error: any) {
    // Handle AbortError more comprehensively
    if (
      signal?.aborted || 
      (error instanceof Error && error.name === 'AbortError') ||
      (error instanceof DOMException && error.name === 'AbortError') ||
      (error.message && error.message.includes('abort'))
    ) {
      console.log(`Child locations fetch for parent ${parentId} aborted`, error);
      throw error; // Re-throw the abort error for React Query to handle properly
    }

    console.error(
      `Error in fetchChildLocations for parent ${parentId}:`,
      error
    );
    return [];
  }
}
