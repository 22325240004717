
import React from 'react';
import { useParams } from 'react-router-dom';
import AdminLayout from '@/components/admin/AdminLayout';
import LoadingState from '@/components/admin/PageEditor/LoadingState';
import PageForm from '@/components/admin/PageEditor/PageForm';
import { useStaticPageForm } from '@/hooks/useStaticPageForm';

const AdminEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const {
    formData,
    loading,
    saving,
    isNewPage,
    handleChange,
    handleSave
  } = useStaticPageForm(id);

  if (loading) {
    return <LoadingState isNewPage={isNewPage} />;
  }

  return (
    <AdminLayout title={isNewPage ? 'Create New Page' : 'Edit Page'}>
      <PageForm
        formData={formData}
        isNewPage={isNewPage}
        saving={saving}
        handleChange={handleChange}
        handleSave={handleSave}
      />
    </AdminLayout>
  );
};

export default AdminEditPage;
