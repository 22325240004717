
import React from 'react';
import { Input } from '@/components/ui/input';
import { Search } from 'lucide-react';

interface GMBLocationSearchProps {
  search: string;
  onSearchChange: (value: string) => void;
  placeholder?: string;
}

export const GMBLocationSearch: React.FC<GMBLocationSearchProps> = ({ 
  search, 
  onSearchChange,
  placeholder = "Search locations..." 
}) => {
  return (
    <div className="relative w-full md:w-64">
      <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-500" />
      <Input
        placeholder={placeholder}
        className="pl-8"
        value={search}
        onChange={(e) => onSearchChange(e.target.value)}
      />
    </div>
  );
};
