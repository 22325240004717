
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';

export const useGalleryPhotoManagement = (id?: string) => {
  const addPhotoToGallery = async (imageUrl: string) => {
    if (!id || id === 'new') {
      toast.error('Please save the gallery first before adding photos');
      return;
    }
    
    try {
      const { data: existingPhotos, error: checkError } = await supabase
        .from('gallery_photos')
        .select('id, image_url')
        .eq('gallery_id', id)
        .eq('image_url', imageUrl)
        .maybeSingle();
      
      if (checkError) {
        throw checkError;
      }
      
      if (existingPhotos) {
        toast.info('This image is already in the gallery');
        return existingPhotos;
      }
      
      const { data, error } = await supabase
        .from('gallery_photos')
        .insert({
          gallery_id: id,
          image_url: imageUrl,
          caption: '',
          sort_order: Date.now()
        })
        .select();

      if (error) {
        throw error;
      }

      toast.success('Photo added to gallery');
      return data?.[0] || null;
    } catch (error) {
      console.error('Error adding photo to gallery:', error);
      toast.error('Failed to add photo to gallery');
      return null;
    }
  };

  return {
    addPhotoToGallery
  };
};
