/**
 * Utility functions for handling image URLs
 */

/**
 * Checks if a URL is a Cloudflare image delivery URL
 * @param url The URL to check
 * @returns Boolean indicating if the URL is a Cloudflare image URL
 */
export const isCloudflareImageUrl = (url: string): boolean => {
  if (!url) return false;
  return url.includes('imagedelivery.net');
};

/**
 * Simple check if URL is valid
 * @param url The URL to check
 * @returns Boolean indicating if the URL appears valid
 */
export const isValidUrl = (url: string): boolean => {
  if (!url) return false;

  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Adds CORS anonymous attribute to requests where needed
 * @param image The image element
 */
export const addCorsSupport = (img: HTMLImageElement): void => {
  // For Cloudflare images, use anonymous CORS mode
  if (img.src && isCloudflareImageUrl(img.src)) {
    img.crossOrigin = 'anonymous';
  }
};

/**
 * Monitors image loading and logs any errors
 * @param img The image element
 * @param onError Function to call on error
 * @param fallbackSrc Fallback source to use if image fails
 */
export const monitorImageLoading = (
  img: HTMLImageElement,
  onError: () => void,
  fallbackSrc: string = '/placeholder.svg'
): void => {
  // Set up proper error handling
  img.addEventListener('error', (e) => {
    console.error('Image load failed:', img.src);
    console.error('Error details:', {
      isCloudflareUrl: isCloudflareImageUrl(img.src),
      status: img.complete ? 'complete' : 'incomplete',
      naturalWidth: img.naturalWidth,
      naturalHeight: img.naturalHeight,
    });

    // Set fallback if provided
    if (fallbackSrc && img.src !== fallbackSrc) {
      img.src = fallbackSrc;
    }

    onError();
  });
};
