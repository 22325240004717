
import React from 'react';
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ReviewStatus } from '@/hooks/gallery-table/types';

interface ReviewStatusFieldProps {
  reviewStatus: ReviewStatus | null;
  handleReviewStatusChange: (status: ReviewStatus) => void;
}

const ReviewStatusField = ({
  reviewStatus,
  handleReviewStatusChange
}: ReviewStatusFieldProps) => {
  const reviewStatuses = [
    { value: "pending", label: "Pending" },
    { value: "approved", label: "Approved" },
    { value: "rejected", label: "Rejected" }
  ];

  return (
    <div className="space-y-2">
      <Label htmlFor="review_status">Review Status</Label>
      <Select 
        value={reviewStatus || "pending"} 
        onValueChange={(value) => {
          handleReviewStatusChange(value as ReviewStatus);
        }}
      >
        <SelectTrigger id="review_status" className="w-full">
          <SelectValue placeholder="Select review status" />
        </SelectTrigger>
        <SelectContent>
          {reviewStatuses.map((status) => (
            <SelectItem key={status.value} value={status.value}>
              {status.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default ReviewStatusField;
