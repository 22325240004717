import { useCallback } from 'react';
import { useTracking } from '../useTracking';
import type { TrackingEvent } from '../types';

export interface FormTrackingProps {
  formId: string;
  formName: string;
  serviceType?: string;
  locationName?: string;
}

export const useFormTracking = (props: FormTrackingProps) => {
  const { trackFormInteraction } = useTracking();
  const { formId, formName, serviceType, locationName } = props;

  const trackFormStart = useCallback(
    (data?: Record<string, any>) => {
      trackFormInteraction('start', formId, data, serviceType);
    },
    [formId, serviceType, trackFormInteraction]
  );

  const trackFormSubmit = useCallback(
    (data?: Record<string, any>) => {
      trackFormInteraction(
        'complete',
        formId,
        {
          ...data,
          form_name: formName,
          location: locationName,
        },
        serviceType
      );
    },
    [formId, formName, locationName, serviceType, trackFormInteraction]
  );

  const trackFormError = useCallback(
    (error: string, data?: Record<string, any>) => {
      trackFormInteraction(
        'error',
        formId,
        {
          ...data,
          error,
          form_name: formName,
          location: locationName,
        },
        serviceType
      );
    },
    [formId, formName, locationName, serviceType, trackFormInteraction]
  );

  return {
    trackFormStart,
    trackFormSubmit,
    trackFormError,
  };
};
