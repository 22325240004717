
import React from 'react';
import { Label } from "@/components/ui/label";

interface FileUploadAreaProps {
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  uploading: boolean;
}

const FileUploadArea = ({ onFileChange, uploading }: FileUploadAreaProps) => {
  return (
    <div className="flex items-center justify-center">
      <Label
        htmlFor="file-upload"
        className="cursor-pointer bg-background border border-dashed border-input rounded-md p-6 flex flex-col items-center justify-center w-full"
      >
        <div className="flex flex-col items-center justify-center">
          <svg
            className="mx-auto h-12 w-12 text-muted-foreground"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-muted-foreground">
            <span className="relative rounded-md font-medium text-primary focus-within:outline-none">
              Upload a file
            </span>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-muted-foreground">
            PNG, JPG up to 5MB
          </p>
        </div>
        <input
          id="file-upload"
          name="file-upload"
          type="file"
          className="sr-only"
          accept="image/png, image/jpeg"
          onChange={onFileChange}
          disabled={uploading}
        />
      </Label>
    </div>
  );
};

export default FileUploadArea;
