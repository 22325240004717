
import React from 'react';
import AdminLayout from '@/components/admin/AdminLayout';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import FranchiseImagesTable from '@/components/admin/franchise-images/FranchiseImagesTable';
import FranchiseImagesToolbar from '@/components/admin/franchise-images/FranchiseImagesToolbar';

const AdminFranchiseImages: React.FC = () => {
  return (
    <AdminLayout title="Franchise Images">
      <Card>
        <CardHeader>
          <CardTitle>Franchise Images</CardTitle>
        </CardHeader>
        <CardContent>
          <FranchiseImagesToolbar />
          <FranchiseImagesTable />
        </CardContent>
      </Card>
    </AdminLayout>
  );
};

export default AdminFranchiseImages;
