
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useLocationData } from '@/hooks/useLocationData';
import LocationLoading from '@/components/location/LocationLoading';
import LocationNotFound from '@/components/location/LocationNotFound';
import LocationView from '@/components/location/LocationView';
import LocationDetailLayout from '@/components/location/LocationDetailLayout';
import PageMeta from '@/components/SEO/PageMeta';
import LocationHero from '@/components/location/LocationHero';
import LocationServices from '@/components/location/LocationServices';
import LocationReviews from '@/components/location/LocationReviews';
import LocationSubareas from '@/components/location/LocationSubareas';
import LocationAreaInfo from '@/components/location/LocationAreaInfo';
import { MapPin, Phone, Mail, ArrowLeft } from 'lucide-react';
import { getLocationAddress } from '@/lib/locationCache';
import { useSettings } from '@/hooks/useSettings';
import { useQueryClient } from '@tanstack/react-query';

const LocationDetail: React.FC = () => {
  const { locationSlug, sublocationSlug } = useParams<{ locationSlug: string, sublocationSlug?: string }>();
  const { location, parentLocation, childLocations, loading } = useLocationData({ 
    locationSlug: locationSlug || '', 
    sublocationSlug 
  });
  const { settings } = useSettings();
  const queryClient = useQueryClient();

  // Prefetch child locations when parent location loads
  React.useEffect(() => {
    if (location?.id && typeof location.id === 'string' && childLocations.length > 0) {
      // Prefetch data for each child location
      childLocations.forEach(child => {
        queryClient.prefetchQuery({
          queryKey: ['location', child.slug],
          queryFn: ({ signal }) => 
            import('@/lib/supabase/locations').then(module => 
              module.fetchLocationBySlug(child.slug, signal)
            )
        });
      });
    }
  }, [location, childLocations, queryClient]);

  if (loading) {
    return <LocationLoading />;
  }

  if (!location) {
    return <LocationNotFound />;
  }

  // Determine which location to use for the meta title
  const displayLocation = sublocationSlug ? location : parentLocation;
  const parentName = parentLocation?.name;
  const metaTitle = location.meta_title || `${location.name} - Fix My Brick`;
  const metaDescription = location.meta_description || 
    `Professional masonry services in ${location.name}. Expert brick and stone repairs, chimney services, and more.`;

  // For a sublocation, use the parent's address or location info
  const isSubLocation = !!sublocationSlug;
  const contactLocation = isSubLocation && parentLocation ? parentLocation : location;
  const address = contactLocation.address || getLocationAddress(contactLocation.slug);

  return (
    <LocationDetailLayout
      locationName={location.name} 
      parentLocationName={parentName}
      locationSlug={locationSlug}
      parentLocationSlug={parentLocation?.slug}
    >
      <PageMeta
        title={metaTitle}
        description={metaDescription}
        canonical={location.canonical_url || `/locations/${locationSlug}${sublocationSlug ? `/${sublocationSlug}` : ''}`}
        type="website"
      />
      
      {/* Hero Section */}
      <LocationHero 
        location={location}
        parentLocation={parentLocation}
      />

      {/* Reviews Section - Moved up */}
      <LocationReviews location={location} />
      
      {/* Main content */}
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            {/* Main location view with description */}
            <LocationView 
              location={location} 
              parentLocation={parentLocation} 
              childLocations={childLocations} 
              sublocationSlug={sublocationSlug}
            />
            
            {/* Location Area Information */}
            {location.area_description && (
              <LocationAreaInfo location={location} />
            )}
          </div>
          
          <div className="lg:col-span-1">
            {/* Parent location link in sidebar */}
            {parentLocation && (
              <div className="bg-white shadow-md rounded-lg p-6 border border-gray-100 mb-6">
                <h2 className="text-xl font-semibold mb-4">Part of {parentLocation.name}</h2>
                <Link 
                  to={`/locations/${parentLocation.slug}`}
                  className="flex items-center text-D00B0E hover:underline group transition-all"
                >
                  <ArrowLeft className="h-5 w-5 mr-2 group-hover:-translate-x-1 transition-transform" />
                  <span>View {parentLocation.name} Area</span>
                </Link>
              </div>
            )}
          
            {/* Contact info card - now in sidebar (without Google Map) */}
            <div className="bg-white shadow-md rounded-lg p-6 border border-gray-100 mb-6">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-semibold">Contact Information</h2>
              </div>
              
              {address && (
                <div className="flex items-start mb-4">
                  <MapPin className="h-5 w-5 mr-3 text-D00B0E mt-1" />
                  <address className="not-italic">
                    {address}
                  </address>
                </div>
              )}
              
              {/* Phone number from location or settings */}
              <div className="flex items-center mb-4">
                <Phone className="h-5 w-5 mr-3 text-D00B0E" />
                <a href={`tel:${contactLocation.phone_number || settings.phone_number}`} className="hover:text-D00B0E">
                  {contactLocation.phone_number || settings.phone_number}
                </a>
              </div>
              
              {/* Email from location or settings */}
              <div className="flex items-center">
                <Mail className="h-5 w-5 mr-3 text-D00B0E" />
                <a href={`mailto:${contactLocation.email || settings.email}`} className="hover:text-D00B0E">
                  {contactLocation.email || settings.email}
                </a>
              </div>
            </div>
          </div>
        </div>
        
        {/* Moved Subareas/Child Locations to bottom row */}
        {childLocations.length > 0 && (
          <div className="mt-8">
            <LocationSubareas 
              location={location}
              childLocations={childLocations}
              sublocationSlug={sublocationSlug}
            />
          </div>
        )}
      </div>
      
      {/* Services Section */}
      <LocationServices location={location} />
      
    </LocationDetailLayout>
  );
};

export default LocationDetail;
