
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { LocationFormData } from '@/hooks/location/useLocationFormState';
import GeneralInfoTab from '@/components/admin/LocationEditor/GeneralInfoTab';
import ImagesTab from '@/components/admin/LocationEditor/ImagesTab';
import AreaInfoTab from '@/components/admin/LocationEditor/AreaInfoTab';
import SEOTabWrapper from '@/components/admin/LocationEditor/SEOTabWrapper';
import PreviewTab from '@/components/admin/LocationEditor/PreviewTab';
import FormActions from '@/components/admin/LocationEditor/FormActions';
import { Location } from '@/types/location';

interface LocationFormProps {
  formData: LocationFormData;
  setFormData: React.Dispatch<React.SetStateAction<LocationFormData>>;
  parentLocations: Location[];
  isNewLocation: boolean;
  saving: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  handleSave: () => Promise<void>;
}

const LocationForm: React.FC<LocationFormProps> = ({
  formData,
  setFormData,
  parentLocations,
  isNewLocation,
  saving,
  handleChange,
  handleSave
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="mb-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-800">
            {isNewLocation ? 'Add New Location' : 'Edit Location'}
          </h1>
        </div>
      </div>
        
      <Tabs defaultValue="general" className="w-full space-y-5">
        <TabsList className="grid w-full grid-cols-2 md:grid-cols-5">
          <TabsTrigger value="general">General</TabsTrigger>
          <TabsTrigger value="images">Images</TabsTrigger>
          <TabsTrigger value="area">Area Info</TabsTrigger>
          <TabsTrigger value="seo">SEO</TabsTrigger>
          <TabsTrigger value="preview">Preview</TabsTrigger>
        </TabsList>
        
        <TabsContent value="general" className="space-y-4">
          <GeneralInfoTab 
            formData={formData} 
            parentLocations={parentLocations} 
            handleChange={handleChange}
          />
        </TabsContent>
        
        <TabsContent value="images" className="space-y-4">
          <ImagesTab
            formData={formData}
            setFormData={setFormData}
          />
        </TabsContent>
        
        <TabsContent value="area" className="space-y-4">
          <AreaInfoTab
            areaDescription={formData.area_description || ''}
            handleChange={handleChange}
          />
        </TabsContent>
        
        <TabsContent value="seo" className="space-y-4">
          <SEOTabWrapper
            seoData={formData}
            onChange={(field, value) => {
              setFormData(prev => ({
                ...prev,
                [field]: value
              }));
            }}
          />
        </TabsContent>
        
        <TabsContent value="preview" className="space-y-4">
          <PreviewTab formData={formData} parentLocations={parentLocations} />
        </TabsContent>
      </Tabs>
      
      <FormActions
        saving={saving}
        handleSave={handleSave}
      />
    </div>
  );
};

export default LocationForm;
