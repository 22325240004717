
import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { RichTextEditor } from '@/components/admin/blog/RichTextEditor';
import { PostPreview } from '@/components/admin/blog/PostPreview';

interface EditorTabsProps {
  activeTab: string;
  setActiveTab: (value: string) => void;
  content: string;
  title: string;
  onChange: (value: string) => void;
}

export const EditorTabs = ({ activeTab, setActiveTab, content, title, onChange }: EditorTabsProps) => {
  return (
    <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value="edit">Edit</TabsTrigger>
        <TabsTrigger value="preview">Preview</TabsTrigger>
      </TabsList>
      <TabsContent value="edit" className="pt-4">
        <RichTextEditor content={content} onChange={onChange} />
      </TabsContent>
      <TabsContent value="preview" className="pt-4">
        <PostPreview content={content} title={title} />
      </TabsContent>
    </Tabs>
  );
};
