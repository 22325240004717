
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { CheckCircle, Clock, XCircle } from 'lucide-react';
import React from 'react';
import { Location } from '@/types/location';

// Update location status in the database
export const updateLocationStatus = async (id: string | number, newStatus: string, locations: Location[]): Promise<Location[]> => {
  try {
    const { error } = await supabase
      .from('locations')
      .update({ status: newStatus })
      .eq('id', id.toString()); // Convert id to string for comparison
    
    if (error) {
      throw error;
    }
    
    toast.success(`Location status updated to ${newStatus}`);
    
    // Update local state without requiring a refresh
    return locations.map(location => 
      location.id === id ? { ...location, status: newStatus } : location
    );
    
  } catch (error) {
    console.error('Error updating location status:', error);
    toast.error('Failed to update location status');
    return locations;
  }
};

// Get the appropriate status icon based on status
export const getStatusIcon = (status?: string) => {
  switch (status) {
    case 'published':
      return <CheckCircle className="h-4 w-4 text-green-500" />;
    case 'draft':
      return <Clock className="h-4 w-4 text-amber-500" />;
    case 'archived':
      return <XCircle className="h-4 w-4 text-gray-500" />;
    default:
      return <CheckCircle className="h-4 w-4 text-green-500" />;
  }
};
