
import React from 'react';
import AdminLayout from '@/components/admin/AdminLayout';
import { BlogPostsTable } from '@/components/admin/blog/BlogPostsTable';
import { BlogPostsToolbar } from '@/components/admin/blog/BlogPostsToolbar';
import { BlogPostRedirect } from '@/components/admin/blog/BlogPostRedirect';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useBlogPostsTable } from '@/hooks/blog/useBlogPostsTable';

const AdminBlogPosts = () => {
  const {
    filteredPosts: posts,
    isLoading: loading,
    searchTerm,
    statusFilter,
    setSearchTerm,
    setStatusFilter,
    deletePostMutation
  } = useBlogPostsTable();

  // Calculate posts count from the filtered posts array
  const postsCount = posts ? posts.length : 0;

  // Handle search change
  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  // Handle status filter change
  const handleStatusFilterChange = (value: string) => {
    setStatusFilter(value);
  };

  // Handle delete post
  const handleDeletePost = (id: string) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      deletePostMutation.mutate(id);
    }
  };

  return (
    <AdminLayout title="Blog Posts">
      <BlogPostRedirect />
      
      <Tabs defaultValue="posts" className="mb-6">
        <TabsList>
          <TabsTrigger value="posts">Posts</TabsTrigger>
          <TabsTrigger value="maintenance">Maintenance</TabsTrigger>
        </TabsList>
        
        <TabsContent value="posts">
          <BlogPostsToolbar 
            postsCount={postsCount}
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
            statusFilter={statusFilter}
            onStatusFilterChange={handleStatusFilterChange}
          />
          <BlogPostsTable 
            posts={posts}
            onDeletePost={handleDeletePost}
          />
        </TabsContent>
        
        <TabsContent value="maintenance">
          <div className="p-4 border rounded-md bg-muted/20 mb-4">
            <h3 className="text-lg font-medium mb-2">Blog Content Maintenance</h3>
            <p className="text-muted-foreground mb-4">
              HTML entities like &amp;#8217; (apostrophe) in blog posts are now automatically cleaned client-side when viewing content. 
              No server-side processing is needed.
            </p>
          </div>
        </TabsContent>
      </Tabs>
    </AdminLayout>
  );
};

export default AdminBlogPosts;
