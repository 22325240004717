
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import AdminLayout from '@/components/admin/AdminLayout';
import ServiceForm from '@/components/admin/ServiceEditor/ServiceForm';
import LoadingState from '@/components/admin/ServiceEditor/LoadingState';
import { useServiceForm } from '@/hooks/useServiceForm';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { HomeIcon, ArrowLeft } from 'lucide-react';
import { Button } from '@/components/ui/button';

const AdminEditService = () => {
  const { id } = useParams<{ id: string }>();
  const {
    formData,
    loading,
    saving,
    isNewService,
    handleChange,
    handleSave,
    addBenefit,
    removeBenefit,
    updateBenefit,
    addProcessStep,
    removeProcessStep,
    updateProcessStep,
    addFAQ,
    removeFAQ,
    updateFAQ,
    addGalleryImage,
    removeGalleryImage,
    updateGalleryImageCaption
  } = useServiceForm(id);

  if (loading) {
    return <LoadingState isNewService={isNewService} />;
  }

  return (
    <AdminLayout title={isNewService ? 'Add Service' : 'Edit Service'}>
      {/* Back button and breadcrumb navigation */}
      <div className="mb-6 flex flex-col sm:flex-row sm:items-center justify-between gap-4">
        <Button
          variant="outline"
          size="sm"
          asChild
          className="w-max flex items-center gap-1"
        >
          <Link to="/admin/services">
            <ArrowLeft className="h-4 w-4" />
            <span>Back to Services</span>
          </Link>
        </Button>
        
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to="/admin">
                  <HomeIcon className="h-4 w-4 mr-1" />
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to="/admin/services">Services</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink>
                {isNewService ? 'Add Service' : formData.title || 'Edit Service'}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>

      <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-6">
        <ServiceForm
          formData={formData}
          isNewService={isNewService}
          saving={saving}
          handleChange={handleChange}
          handleSave={handleSave}
          addBenefit={addBenefit}
          removeBenefit={removeBenefit}
          updateBenefit={updateBenefit}
          addProcessStep={addProcessStep}
          removeProcessStep={removeProcessStep}
          updateProcessStep={updateProcessStep}
          addFAQ={addFAQ}
          removeFAQ={removeFAQ}
          updateFAQ={updateFAQ}
          addGalleryImage={addGalleryImage}
          removeGalleryImage={removeGalleryImage}
          updateGalleryImageCaption={updateGalleryImageCaption}
        />
      </div>
    </AdminLayout>
  );
};

export default AdminEditService;
