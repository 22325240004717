import React from 'react';
import Header from '@/components/header';
import Footer from '@/components/Footer';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Fix My Brick</title>
        <meta name="description" content="Privacy Policy for Fix My Brick masonry and restoration services." />
      </Helmet>
      <Header />
      <main className="py-20 bg-white">
        <div className="container mx-auto px-4 md:px-8">
          <h1 className="text-3xl md:text-4xl font-bold mb-8">Privacy Policy</h1>
          
          <div className="prose max-w-none">
            <p className="text-lg mb-6">
              Last Updated: March 13, 2025
            </p>
            
            <h2 className="text-2xl font-semibold mt-8 mb-4">1. Introduction</h2>
            <p>
              Welcome to Fix My Brick ("we," "our," or "us"). We are committed to protecting your privacy and handling your personal information with transparency. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
            </p>
            
            <h2 className="text-2xl font-semibold mt-8 mb-4">2. Information We Collect</h2>
            <p>
              We collect information that you provide directly to us, such as when you fill out a contact form, request a quote, or interact with our website. This may include your name, email address, phone number, and address.
            </p>
            
            <h2 className="text-2xl font-semibold mt-8 mb-4">3. How We Use Your Information</h2>
            <p>
              We use the information we collect to provide, maintain, and improve our services, to communicate with you about our services, and to respond to your inquiries and requests.
            </p>
            
            <h2 className="text-2xl font-semibold mt-8 mb-4">4. Sharing Your Information</h2>
            <p>
              We do not sell or rent your personal information to third parties. We may share your information with trusted service providers who assist us in operating our website and conducting our business.
            </p>
            
            <h2 className="text-2xl font-semibold mt-8 mb-4">5. Your Rights</h2>
            <p>
              You have the right to access, correct, or delete your personal information. If you would like to exercise these rights, please contact us using the information provided below.
            </p>
            
            <h2 className="text-2xl font-semibold mt-8 mb-4">6. Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please contact us at:
            </p>
            <p>
              Fix My Brick Inc.<br/>
              68 Beverly Hills Dr<br/>
              North York, ON M3L 1A5<br/>
              Email: info@fixmybrick.ca<br/>
              Phone: (416) 800-2637
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
