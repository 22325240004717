
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Loader2, Save } from 'lucide-react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { BlogPostEditor } from './BlogPostEditor';
import { SEOForm } from './SEOForm';
import { PostFormData, SEOFormData } from '@/hooks/blog/useBlogPostForm';
import { format } from 'date-fns';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

interface BlogPostFormProps {
  formData: PostFormData;
  seoData: SEOFormData;
  isNewPost: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleStatusChange: (value: string) => void;
  handleSeoChange: (field: string, value: string) => void;
  handleSubmit: (e: React.FormEvent) => void;
  isSubmitting: boolean;
  onCancel: () => void;
}

export const BlogPostForm = ({
  formData,
  seoData,
  isNewPost,
  handleInputChange,
  handleStatusChange,
  handleSeoChange,
  handleSubmit,
  isSubmitting,
  onCancel
}: BlogPostFormProps) => {
  const [activeTab, setActiveTab] = useState("content");

  return (
    <form className="space-y-6" onSubmit={handleSubmit}>
      {!isNewPost && formData.created_at && (
        <div className="flex gap-4 text-sm text-muted-foreground">
          <div>
            Created: {format(new Date(formData.created_at), 'PPP')}
          </div>
          {formData.updated_at && (
            <div>
              Last updated: {format(new Date(formData.updated_at), 'PPP')}
            </div>
          )}
        </div>
      )}

      <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="content">Content</TabsTrigger>
          <TabsTrigger value="seo">SEO</TabsTrigger>
        </TabsList>

        <TabsContent value="content">
          <Card className="p-6">
            <div className="grid gap-6">
              <div className="grid md:grid-cols-2 gap-6">
                <div>
                  <Label htmlFor="title">Post Title</Label>
                  <Input 
                    id="title" 
                    placeholder="Enter post title" 
                    className="mt-1" 
                    value={formData.title}
                    onChange={handleInputChange}
                  />
                </div>
                
                <div>
                  <Label htmlFor="slug">Slug</Label>
                  <Input 
                    id="slug" 
                    placeholder="post-url-slug" 
                    className="mt-1" 
                    value={formData.slug}
                    onChange={handleInputChange}
                  />
                  <p className="text-sm text-muted-foreground mt-1">
                    URL path: /blog/{formData.slug}
                  </p>
                </div>
              </div>

              <div>
                <Label htmlFor="featured_image">Featured Image URL</Label>
                <Input 
                  id="featured_image" 
                  placeholder="https://example.com/image.jpg" 
                  className="mt-1" 
                  value={formData.featured_image || ''}
                  onChange={handleInputChange}
                />
                {formData.featured_image && (
                  <div className="mt-2">
                    <img 
                      src={formData.featured_image} 
                      alt="Featured" 
                      className="max-h-32 rounded-md"
                    />
                  </div>
                )}
              </div>

              <div>
                <Label htmlFor="status">Status</Label>
                <Select 
                  value={formData.status} 
                  onValueChange={handleStatusChange}
                >
                  <SelectTrigger className="mt-1">
                    <SelectValue placeholder="Select status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="draft">Draft</SelectItem>
                    <SelectItem value="published">Published</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div>
                <Label htmlFor="excerpt">Excerpt</Label>
                <Textarea 
                  id="excerpt" 
                  placeholder="Brief summary of the post" 
                  className="mt-1"
                  rows={3}
                  value={formData.excerpt}
                  onChange={handleInputChange}
                />
              </div>

              <BlogPostEditor 
                content={formData.content}
                title={formData.title}
                onChange={(value) => handleInputChange({ 
                  target: { id: 'content', value } 
                } as React.ChangeEvent<HTMLTextAreaElement>)}
              />
            </div>
          </Card>
        </TabsContent>

        <TabsContent value="seo">
          <SEOForm 
            seoData={seoData}
            onChange={handleSeoChange}
          />
        </TabsContent>
      </Tabs>

      <div className="pt-4 flex items-center gap-4">
        <Button 
          type="submit" 
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            <Save className="mr-2 h-4 w-4" />
          )}
          {isNewPost ? "Create Post" : "Update Post"}
        </Button>
        
        <Button 
          type="button" 
          variant="outline" 
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}
