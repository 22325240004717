
import React, { useState } from 'react';
import { TemplateSection } from './editor/TemplateSection';
import { EditorTabs } from './editor/EditorTabs';
import { ContentHeader } from './editor/ContentHeader';
import { generateTemplate } from './editor/templateUtils';

interface BlogPostEditorProps {
  content: string;
  title: string;
  onChange: (value: string) => void;
}

export const BlogPostEditor = ({ content, title, onChange }: BlogPostEditorProps) => {
  const [activeTab, setActiveTab] = useState<string>('edit');

  // Ensure content is a string to prevent errors
  const safeContent = typeof content === 'string' ? content : '';

  const handleInsertTemplate = (templateType: string) => {
    const templateHTML = generateTemplate(templateType);
    
    // Append the template to the existing content
    onChange(safeContent + templateHTML);
  };

  return (
    <div className="space-y-4">
      <ContentHeader />
      
      <TemplateSection onInsertTemplate={handleInsertTemplate} />
      
      <EditorTabs 
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        content={content}
        title={title}
        onChange={onChange}
      />
    </div>
  );
};
