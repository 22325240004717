
import { useState } from 'react';
import { GalleryImage } from './types';

export const useImageNavigation = (images: GalleryImage[]) => {
  const [selectedImage, setSelectedImage] = useState<number | null>(null);

  const navigateImage = (direction: 'prev' | 'next') => {
    if (!images.length || selectedImage === null) return;

    if (direction === 'next') {
      setSelectedImage((selectedImage + 1) % images.length);
    } else {
      setSelectedImage((selectedImage - 1 + images.length) % images.length);
    }
  };

  return {
    selectedImage,
    setSelectedImage,
    navigateImage
  };
};
