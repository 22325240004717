
import React from 'react';
import { Button } from '@/components/ui/button';
import { ArrowRight } from 'lucide-react';

interface SubmitButtonProps {
  isSubmitting: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ isSubmitting }) => {
  return (
    <>
      <Button 
        type="submit" 
        className="w-full bg-brick hover:bg-brick/90 text-white h-12 text-lg"
        disabled={isSubmitting}
      >
        {isSubmitting ? "Submitting..." : "Submit Request"}
        {!isSubmitting && <ArrowRight className="ml-2 h-5 w-5" />}
      </Button>
      
      <p className="text-xs text-gray-500 text-right">* Required fields</p>
    </>
  );
};

export default SubmitButton;
