
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { Location } from '@/types/location';
import { LocationFormData } from './useLocationFormState';
import { validateSlug } from '@/utils/locationUtils';

export function useLocationSave(location: Location | null) {
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  const saveLocation = async (formData: LocationFormData, isNewLocation: boolean) => {
    // Validate required fields
    if (!formData.name.trim()) {
      toast.error('Location name is required');
      return;
    }

    if (!formData.description.trim()) {
      toast.error('Description is required');
      return;
    }

    // Validate slug
    if (!validateSlug(formData.slug)) {
      toast.error('Slug must contain only lowercase letters, numbers, and hyphens');
      return;
    }

    setSaving(true);

    try {
      const locationData = {
        name: formData.name,
        slug: formData.slug,
        description: formData.description,
        area_description: formData.area_description,
        meta_description: formData.meta_description,
        is_primary: formData.is_primary,
        parent_id: formData.parent_id,
        meta_title: formData.meta_title,
        og_title: formData.og_title,
        og_description: formData.og_description,
        og_image: formData.og_image,
        canonical_url: formData.canonical_url,
        focus_keyword: formData.focus_keyword,
        custom_title: formData.custom_title,
        hero_image: formData.hero_image,
        gallery_images: formData.gallery_images
      };

      if (location?.id) {
        // Update existing location
        const { error } = await supabase
          .from('locations')
          .update(locationData)
          .eq('id', location.id);
        
        if (error) throw error;
        toast.success('Location updated successfully');
      } else {
        // Create new location
        const { error } = await supabase
          .from('locations')
          .insert(locationData);
        
        if (error) throw error;
        toast.success('Location created successfully');
      }
      
      navigate('/admin/locations');
    } catch (error) {
      console.error('Error saving location:', error);
      toast.error('Failed to save location');
    } finally {
      setSaving(false);
    }
  };

  return {
    saving,
    saveLocation
  };
}
