import type {
  TrackingEvent,
  LeadScore,
  LeadSource,
  TrackingEventName,
} from './types';

declare global {
  interface Window {
    plausible: (
      eventName: string,
      options?: { props?: Record<string, any> }
    ) => void;
  }
}

// Core tracking function
export const trackEvent = (event: TrackingEvent): void => {
  try {
    if (typeof window === 'undefined' || !window.plausible) {
      console.warn('Plausible not initialized');
      return;
    }

    window.plausible(event.name, {
      props: {
        ...event.props,
        timestamp: new Date().toISOString(),
        ...(event.options && { options: event.options }),
      },
    });
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};

// Lead source tracking
export const getLeadSource = (): LeadSource => {
  if (typeof window === 'undefined') {
    return {
      referrer: '',
      landing_page: '',
      device_type: '',
    };
  }

  const urlParams = new URLSearchParams(window.location.search);

  return {
    referrer: document.referrer || 'direct',
    utm_source: urlParams.get('utm_source') || undefined,
    utm_medium: urlParams.get('utm_medium') || undefined,
    utm_campaign: urlParams.get('utm_campaign') || undefined,
    landing_page: window.location.pathname,
    device_type: /Mobile|Android|iPhone/i.test(navigator.userAgent)
      ? 'mobile'
      : 'desktop',
  };
};

// Lead scoring
export const scoreLead = (
  formData: Record<string, any>,
  serviceType: string
): LeadScore => {
  let score = 0;
  let urgency: LeadScore['urgency'] = 'medium';

  // Score based on service type
  if (serviceType.toLowerCase().includes('emergency')) {
    score += 30;
    urgency = 'high';
  }

  // Score based on form completeness
  const requiredFields = ['name', 'email', 'phone', 'message'];
  const completedFields = requiredFields.filter((field) => !!formData[field]);
  score += (completedFields.length / requiredFields.length) * 20;

  // Score based on message content
  const messageText = (formData.message || '').toLowerCase();
  if (messageText.includes('urgent') || messageText.includes('emergency')) {
    score += 20;
    urgency = 'high';
  }

  // Determine quality based on score
  let quality: LeadScore['quality'] = 'cold';
  if (score >= 70) quality = 'hot';
  else if (score >= 40) quality = 'warm';

  return {
    quality,
    value: score,
    urgency,
  };
};

// Track page view with enhanced data
export const trackPageView = (additionalProps?: Record<string, any>) => {
  const leadSource = getLeadSource();

  trackEvent({
    name: 'pageview',
    props: {
      ...leadSource,
      ...additionalProps,
      path: window.location.pathname,
      title: document.title,
    },
  });
};

// Track form interactions
export const trackFormInteraction = (
  action: 'start' | 'complete' | 'error',
  formId: string,
  formData?: Record<string, any>,
  serviceType?: string
) => {
  const eventName = `form_${action}` as TrackingEventName;
  const leadSource = getLeadSource();

  let props: Record<string, any> = {
    form_id: formId,
    ...leadSource,
  };

  if (formData && action === 'complete') {
    const leadScore = scoreLead(formData, serviceType || '');
    props = {
      ...props,
      lead_score: leadScore,
      service_type: serviceType,
    };
  }

  trackEvent({
    name: eventName,
    props,
  });
};
