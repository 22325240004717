
import React from 'react';
import { TableBody, TableCell, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertCircle, RefreshCw, X } from 'lucide-react';

interface ErrorStateProps {
  error: string | null;
  retryAttempts: number;
  maxRetryAttempts: number;
  loading: boolean;
  onRetry: () => void;
  onClearFilters: () => void;
}

const ErrorState: React.FC<ErrorStateProps> = ({
  error,
  retryAttempts,
  maxRetryAttempts,
  loading,
  onRetry,
  onClearFilters
}) => {
  return (
    <div className="space-y-4">
      <Alert variant="destructive">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>
          {error || "An error occurred loading franchise images"}
          <div className="mt-2">
            <Button 
              variant="outline" 
              size="sm" 
              onClick={onRetry} 
              className="mt-2"
              disabled={loading}
            >
              <RefreshCw className={`h-4 w-4 mr-2 ${loading ? 'animate-spin' : ''}`} />
              {loading ? 'Retrying...' : 'Retry'}
            </Button>
            
            {retryAttempts >= maxRetryAttempts && (
              <Button 
                variant="outline" 
                size="sm" 
                onClick={onClearFilters} 
                className="mt-2 ml-2"
              >
                <X className="h-4 w-4 mr-2" />
                Clear Filters & Retry
              </Button>
            )}
          </div>
        </AlertDescription>
      </Alert>
      
      <div className="rounded-md border">
        <TableBody>
          <TableRow>
            <TableCell colSpan={6} className="text-center py-8 text-muted-foreground">
              <p>Error loading franchise images</p>
            </TableCell>
          </TableRow>
        </TableBody>
      </div>
    </div>
  );
};

export default ErrorState;
