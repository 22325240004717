
import React from 'react';

const ServiceAreas = () => {
  return (
    <div className="p-6 bg-gray-100 rounded-lg">
      <h3 className="font-semibold text-lg mb-3">Service Areas</h3>
      <p className="text-gray-700 mb-4">
        We proudly serve residential and commercial clients throughout:
      </p>
      <div className="grid grid-cols-2 gap-2 text-gray-700">
        <p>• Hamilton</p>
        <p>• St. Catherines</p>
        <p>• Niagara Region</p>
        <p>• Kitchener</p>
        <p>• Waterloo</p>
        <p>• Mississauga</p>
        <p>• Vaughan</p>
        <p>• North York</p>
        <p>• Greater Toronto Area</p>
        <p>• Oakville</p>
        <p>• Burlington</p>
        <p>• Pickering</p>
      </div>
    </div>
  );
};

export default ServiceAreas;
