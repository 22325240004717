
import React from 'react';
import { Switch } from "@/components/ui/switch";

interface StatusFilterProps {
  showOnlyPending: boolean;
  onShowOnlyPendingChange: (value: boolean) => void;
}

const StatusFilter = ({ showOnlyPending, onShowOnlyPendingChange }: StatusFilterProps) => {
  return (
    <div className="flex items-center space-x-2">
      <span className="text-sm text-muted-foreground">Show only pending</span>
      <Switch 
        checked={showOnlyPending} 
        onCheckedChange={onShowOnlyPendingChange} 
      />
    </div>
  );
};

export default StatusFilter;
