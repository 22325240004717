
import React from 'react';
import { Hammer, Phone } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Crop } from 'react-image-crop';

interface LogoPreviewProps {
  imageUrl: string;
  logoSize: number;
  getCroppedImg: () => string | null;
  completedCrop: Crop | null;
}

export const LogoPreview: React.FC<LogoPreviewProps> = ({
  imageUrl,
  logoSize,
  getCroppedImg,
  completedCrop
}) => {
  return (
    <div className="space-y-6">
      <div className="border-b pb-4">
        <h3 className="text-lg font-medium mb-2">Device Previews</h3>
        <p className="text-sm text-muted-foreground mb-4">See how your logo will appear across different devices</p>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <DevicePreview 
            title="Mobile"
            width="xs"
            imageUrl={imageUrl}
            getCroppedImg={getCroppedImg}
            completedCrop={completedCrop}
          />
          
          <DevicePreview 
            title="Tablet"
            width="md"
            imageUrl={imageUrl}
            getCroppedImg={getCroppedImg}
            completedCrop={completedCrop}
          />
          
          <DevicePreview 
            title="Desktop"
            width="lg"
            imageUrl={imageUrl}
            getCroppedImg={getCroppedImg}
            completedCrop={completedCrop}
          />
        </div>
      </div>

      <HeaderPreview 
        imageUrl={imageUrl} 
        logoSize={logoSize} 
        getCroppedImg={getCroppedImg} 
        completedCrop={completedCrop} 
      />
      
      <SidebarPreview 
        imageUrl={imageUrl} 
        logoSize={logoSize} 
        getCroppedImg={getCroppedImg} 
        completedCrop={completedCrop} 
      />
    </div>
  );
};

interface DevicePreviewProps {
  title: string;
  width: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  imageUrl: string;
  getCroppedImg: () => string | null;
  completedCrop: Crop | null;
}

const DevicePreview: React.FC<DevicePreviewProps> = ({
  title,
  width,
  imageUrl,
  getCroppedImg,
  completedCrop
}) => {
  // Calculate logo height based on device size
  const getLogoHeight = () => {
    switch(width) {
      case 'xs': return 36;
      case 'sm': return 44;
      case 'md': return 54;
      case 'lg': return 64;
      case 'xl': return 72;
      default: return 54;
    }
  };
  
  // Calculate device frame dimensions
  const getDeviceFrameClasses = () => {
    switch(width) {
      case 'xs': return 'w-full max-w-[320px] h-[180px]';
      case 'md': return 'w-full max-w-[480px] h-[200px]';
      case 'lg': return 'w-full max-w-[640px] h-[220px]';
      default: return 'w-full max-w-[320px] h-[180px]';
    }
  };
  
  return (
    <div className="border rounded-md p-3 bg-gray-50">
      <h4 className="text-sm font-medium mb-2">{title}</h4>
      <div className={`border rounded bg-white ${getDeviceFrameClasses()} overflow-hidden mx-auto`}>
        <div className="bg-brick text-white text-center py-1 text-xs font-medium">
          Family Owned Since 2002
        </div>
        <div className="p-2 border-b flex items-center justify-between">
          {imageUrl && completedCrop ? (
            <div className="flex-shrink-0">
              <img 
                src={getCroppedImg() || imageUrl}
                alt="Logo Preview"
                style={{
                  height: `${getLogoHeight()}px`,
                  maxWidth: width === 'xs' ? '150px' : width === 'md' ? '200px' : '260px',
                  objectFit: 'contain',
                  objectPosition: 'left'
                }}
              />
            </div>
          ) : (
            <div className="flex items-center space-x-1">
              <div className="bg-brick rounded p-1">
                <Hammer className="h-3 w-3 text-white" />
              </div>
              <span className="text-sm font-bold">Fix My Brick</span>
            </div>
          )}
          
          <Button size="sm" className="bg-brick text-white text-xs py-1 px-2 h-auto">
            {width === 'xs' ? 'Quote' : 'Get a Quote'}
          </Button>
        </div>
      </div>
    </div>
  );
};

interface PreviewProps {
  imageUrl: string;
  logoSize: number;
  getCroppedImg: () => string | null;
  completedCrop: Crop | null;
}

const HeaderPreview: React.FC<PreviewProps> = ({
  imageUrl,
  logoSize,
  getCroppedImg,
  completedCrop
}) => {
  return (
    <div>
      <h3 className="text-lg font-medium">Header Preview</h3>
      <div className="border rounded-md overflow-hidden">
        <div className="bg-brick text-white text-center py-1.5 text-sm font-medium">
          Family Owned And Operated Since 2002
        </div>
        <div className="bg-white shadow-sm border-b border-gray-100 p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              {imageUrl && completedCrop ? (
                <div style={{ height: `${logoSize}px` }}>
                  <img 
                    src={getCroppedImg() || imageUrl}
                    alt="Cropped Logo Preview"
                    style={{ height: '100%', width: 'auto', objectFit: 'contain' }}
                  />
                </div>
              ) : (
                <div className="bg-brick rounded-md p-1.5">
                  <Hammer className="h-5 w-5 text-white" />
                </div>
              )}
            </div>

            <div className="flex items-center space-x-4">
              <div className="flex items-center text-gray-700">
                <Phone size={16} className="mr-2" />
                <span className="font-medium">(905) 807-0404</span>
              </div>
              <Button className="bg-brick hover:bg-brick-dark shadow-sm">
                Get a Quote
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SidebarPreview: React.FC<PreviewProps> = ({
  imageUrl,
  logoSize,
  getCroppedImg,
  completedCrop
}) => {
  return (
    <div>
      <h3 className="text-lg font-medium mt-6">Sidebar Preview</h3>
      <div className="border rounded-md overflow-hidden bg-white w-64">
        <div className="p-4 border-b">
          <div className="flex items-center hover:opacity-80 transition-opacity">
            {imageUrl && completedCrop ? (
              <div className="h-24 w-full flex justify-center">
                <img 
                  src={getCroppedImg() || imageUrl}
                  alt="Cropped Logo Preview"
                  style={{ height: `${logoSize}px`, width: 'auto', objectFit: 'contain' }}
                />
              </div>
            ) : (
              <>
                <div className="bg-brick rounded-md p-1.5 mr-2">
                  <Hammer className="h-4 w-4 text-white" />
                </div>
                <div>
                  <h1 className="text-lg font-bold">Fix My <span className="text-brick">Brick</span></h1>
                  <p className="text-xs text-gray-600">Admin Dashboard</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
