
import React from 'react';
import Header from '@/components/header';
import Footer from '@/components/Footer';
import ContactSection from '@/components/ContactSection';
import AdminBar from '@/components/admin/AdminBar';

const Contact = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <AdminBar />
      <Header />
      <main className="flex-grow">
        <div className="py-8 md:py-12">
          <ContactSection />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Contact;
