
import React from 'react';
import { ServiceDetail } from '@/types/service';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';

interface SEOFormProps {
  formData: ServiceDetail;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const SEOForm: React.FC<SEOFormProps> = ({ formData, handleChange }) => {
  return (
    <div className="space-y-6">
      <div className="grid gap-4">
        <div>
          <Label htmlFor="meta_title">Meta Title</Label>
          <Input
            id="meta_title"
            name="meta_title"
            value={formData.meta_title || ''}
            onChange={handleChange}
            placeholder={formData.title || 'Meta title'}
          />
          <p className="text-xs text-gray-500 mt-1">
            Recommended length: 50-60 characters
          </p>
        </div>

        <div>
          <Label htmlFor="meta_description">Meta Description</Label>
          <Textarea
            id="meta_description"
            name="meta_description"
            value={formData.meta_description || ''}
            onChange={handleChange}
            placeholder={formData.description || 'Meta description'}
          />
          <p className="text-xs text-gray-500 mt-1">
            Recommended length: 150-160 characters
          </p>
        </div>

        <div>
          <Label htmlFor="focus_keyword">Focus Keyword</Label>
          <Input
            id="focus_keyword"
            name="focus_keyword"
            value={formData.focus_keyword || ''}
            onChange={handleChange}
            placeholder="Main keyword for this service"
          />
        </div>

        <div>
          <Label htmlFor="canonical_url">Canonical URL</Label>
          <Input
            id="canonical_url"
            name="canonical_url"
            value={formData.canonical_url || ''}
            onChange={handleChange}
            placeholder={`/services/${formData.slug}`}
          />
        </div>

        <div className="pt-4 border-t">
          <h3 className="text-lg font-medium mb-4">Social Media Preview</h3>

          <div className="space-y-4">
            <div>
              <Label htmlFor="og_title">Social Title</Label>
              <Input
                id="og_title"
                name="og_title"
                value={formData.og_title || ''}
                onChange={handleChange}
                placeholder={formData.meta_title || formData.title || 'Social title'}
              />
            </div>

            <div>
              <Label htmlFor="og_description">Social Description</Label>
              <Textarea
                id="og_description"
                name="og_description"
                value={formData.og_description || ''}
                onChange={handleChange}
                placeholder={formData.meta_description || formData.description || 'Social description'}
              />
            </div>

            <div>
              <Label htmlFor="og_image">Social Image URL</Label>
              <Input
                id="og_image"
                name="og_image"
                value={formData.og_image || ''}
                onChange={handleChange}
                placeholder={formData.image_url || 'Social image URL'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SEOForm;
