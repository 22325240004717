
import React from 'react';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { HelpCircle } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { FieldInfo } from './types';
import { UseFormRegister } from 'react-hook-form';
import { MigrationCredentials } from '@/lib/supabase/wordpress';

interface FormFieldProps {
  field: FieldInfo;
  register: UseFormRegister<MigrationCredentials>;
  error?: boolean;
  testButton?: {
    show: boolean;
    disabled: boolean;
    loading: boolean;
    onClick: () => void;
    value: string;
  };
}

const FormField: React.FC<FormFieldProps> = ({
  field,
  register,
  error,
  testButton,
}) => {
  return (
    <div className='space-y-1'>
      <div className='flex items-center space-x-2'>
        <Label htmlFor={field.id}>{field.label}</Label>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <HelpCircle className='h-4 w-4 text-gray-400 cursor-help' />
            </TooltipTrigger>
            <TooltipContent className='max-w-sm p-4'>
              {field.tooltip}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className='flex space-x-2'>
        <Input
          id={field.id}
          type={field.type || 'text'}
          placeholder={field.placeholder}
          className='flex-1'
          {...register(field.id as keyof MigrationCredentials, {
            required: field.required,
          })}
        />
        {testButton?.show && (
          <Button
            type='button'
            variant='outline'
            size='sm'
            disabled={testButton.disabled}
            onClick={testButton.onClick}
            className='whitespace-nowrap'>
            {testButton.loading ? 'Testing...' : 'Test URL'}
          </Button>
        )}
      </div>
      {error && (
        <span className='text-xs text-red-500'>
          {field.label} is required
        </span>
      )}
    </div>
  );
};

export default FormField;
