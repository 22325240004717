
import React, { useEffect, useRef } from 'react';

interface PostContentProps {
  content: string;
}

const PostContent: React.FC<PostContentProps> = ({ content }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    // Client-side function to clean common HTML entities in text nodes
    const cleanHtmlEntities = (element: HTMLElement) => {
      if (!element) return;
      
      // Process all child nodes
      const walker = document.createTreeWalker(
        element,
        NodeFilter.SHOW_TEXT,
        null
      );
      
      const nodesToReplace: Array<{ node: Text; newValue: string }> = [];
      
      let node;
      while (node = walker.nextNode() as Text) {
        const text = node.nodeValue || '';
        const cleanedText = text
          .replace(/&amp;/g, '&')
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
          .replace(/&quot;/g, '"')
          .replace(/&#039;/g, "'")
          .replace(/&#8217;/g, "'")
          .replace(/&#8216;/g, "'")
          .replace(/&#8220;/g, '"')
          .replace(/&#8221;/g, '"')
          .replace(/&#8211;/g, '–')
          .replace(/&#8212;/g, '—')
          .replace(/&nbsp;/g, ' ')
          .replace(/&#8230;/g, '...');
        
        if (cleanedText !== text) {
          nodesToReplace.push({ node, newValue: cleanedText });
        }
      }
      
      // Replace text in all nodes that need updating
      nodesToReplace.forEach(({ node, newValue }) => {
        node.nodeValue = newValue;
      });
    };
    
    // Run the cleanup after content is rendered
    if (contentRef.current) {
      cleanHtmlEntities(contentRef.current);
    }
  }, [content]);  // Re-run when content changes
  
  return (
    <div 
      ref={contentRef}
      className="blog-content mb-12"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default PostContent;
