
import React from 'react';
import { useAssetManager, AssetFolder } from '@/hooks/useAssetManager';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Folder, CheckIcon } from 'lucide-react';

interface AssetMoveDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  selectedAssets: string[];
  targetFolderId: string | null;
  onTargetFolderChange: (folderId: string | null) => void;
}

export const AssetMoveDialog: React.FC<AssetMoveDialogProps> = ({
  open,
  onOpenChange,
  selectedAssets,
  targetFolderId,
  onTargetFolderChange
}) => {
  const { folders, moveAssets } = useAssetManager();
  
  // Move selected assets to target folder
  const handleMoveAssets = async () => {
    if (selectedAssets.length === 0) return;
    
    await moveAssets(selectedAssets, targetFolderId);
    onOpenChange(false);
    onTargetFolderChange(null);
  };
  
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Move Assets</DialogTitle>
          <DialogDescription>
            Select a destination folder for the selected assets.
          </DialogDescription>
        </DialogHeader>
        
        <div className="space-y-4 py-2">
          <div className="space-y-2">
            <label className="text-sm font-medium">
              Moving {selectedAssets.length} {selectedAssets.length === 1 ? 'asset' : 'assets'} to:
            </label>
            
            <div className="max-h-60 overflow-y-auto border rounded-md p-2">
              <div
                className={`flex items-center p-2 rounded-md cursor-pointer ${
                  targetFolderId === null ? 'bg-muted' : 'hover:bg-muted/50'
                }`}
                onClick={() => onTargetFolderChange(null)}
              >
                <div className="flex items-center gap-2 flex-1">
                  <Folder size={16} />
                  <span>Root folder</span>
                </div>
                
                {targetFolderId === null && (
                  <CheckIcon size={16} className="text-primary" />
                )}
              </div>
              
              {folders.map(folder => (
                <div
                  key={folder.id}
                  className={`flex items-center p-2 rounded-md cursor-pointer ${
                    targetFolderId === folder.id ? 'bg-muted' : 'hover:bg-muted/50'
                  }`}
                  onClick={() => onTargetFolderChange(folder.id)}
                >
                  <div className="flex items-center gap-2 flex-1">
                    <Folder size={16} />
                    <span>{folder.name}</span>
                  </div>
                  
                  {targetFolderId === folder.id && (
                    <CheckIcon size={16} className="text-primary" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={handleMoveAssets}>
            Move Assets
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
