
import React from 'react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Phone, Mail, MapPin, Facebook, Instagram, Twitter } from 'lucide-react';
import { SiteSettings } from '@/types/settings';

interface ContactInfoTabProps {
  settings: SiteSettings;
  saving: boolean;
  handleChange: (field: keyof SiteSettings, value: string) => void;
}

const ContactInfoTab: React.FC<ContactInfoTabProps> = ({
  settings,
  saving,
  handleChange
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Contact Information</CardTitle>
        <CardDescription>
          This information will be displayed across the website, including the footer, header, and contact page.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div>
            <Label 
              htmlFor="phone_number" 
              className="flex items-center gap-2"
            >
              <Phone className="h-4 w-4" /> Phone Number
            </Label>
            <Input
              id="phone_number"
              value={settings.phone_number}
              onChange={(e) => handleChange('phone_number', e.target.value)}
              placeholder="Enter phone number"
              disabled={saving}
              required
            />
          </div>

          <div>
            <Label 
              htmlFor="email" 
              className="flex items-center gap-2"
            >
              <Mail className="h-4 w-4" /> Email Address
            </Label>
            <Input
              id="email"
              type="email"
              value={settings.email}
              onChange={(e) => handleChange('email', e.target.value)}
              placeholder="Enter email address"
              disabled={saving}
              required
            />
          </div>

          <div>
            <Label 
              htmlFor="address" 
              className="flex items-center gap-2"
            >
              <MapPin className="h-4 w-4" /> Primary Address
            </Label>
            <Input
              id="address"
              value={settings.address}
              onChange={(e) => handleChange('address', e.target.value)}
              placeholder="Enter primary address"
              disabled={saving}
              required
            />
          </div>

          <div className="pt-4 border-t">
            <h3 className="text-lg font-medium mb-4">Social Media Links</h3>
            <div className="space-y-4">
              <div>
                <Label 
                  htmlFor="facebook_url" 
                  className="flex items-center gap-2"
                >
                  <Facebook className="h-4 w-4" /> Facebook URL
                </Label>
                <Input
                  id="facebook_url"
                  value={settings.facebook_url || ''}
                  onChange={(e) => handleChange('facebook_url', e.target.value)}
                  placeholder="Enter Facebook URL"
                  disabled={saving}
                />
              </div>

              <div>
                <Label 
                  htmlFor="instagram_url" 
                  className="flex items-center gap-2"
                >
                  <Instagram className="h-4 w-4" /> Instagram URL
                </Label>
                <Input
                  id="instagram_url"
                  value={settings.instagram_url || ''}
                  onChange={(e) => handleChange('instagram_url', e.target.value)}
                  placeholder="Enter Instagram URL"
                  disabled={saving}
                />
              </div>

              <div>
                <Label 
                  htmlFor="twitter_url" 
                  className="flex items-center gap-2"
                >
                  <Twitter className="h-4 w-4" /> Twitter URL
                </Label>
                <Input
                  id="twitter_url"
                  value={settings.twitter_url || ''}
                  onChange={(e) => handleChange('twitter_url', e.target.value)}
                  placeholder="Enter Twitter URL"
                  disabled={saving}
                />
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ContactInfoTab;
