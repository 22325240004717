
import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Star } from 'lucide-react';
import { GMBLocation, Review } from '@/lib/supabase';
import { toast } from 'sonner';

interface ReviewFormDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  isEditMode: boolean;
  currentReview: Partial<Review>;
  gmbLocations?: GMBLocation[];
  onInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onSelectChange: (value: string, fieldName: string) => void;
  onStarClick: (rating: number) => void;
  onSubmit: () => void;
  getStarRating: (rating: string) => number;
}

const ReviewFormDialog: React.FC<ReviewFormDialogProps> = ({
  isOpen,
  onOpenChange,
  isEditMode,
  currentReview,
  gmbLocations,
  onInputChange,
  onSelectChange,
  onStarClick,
  onSubmit,
  getStarRating,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>{isEditMode ? 'Edit Review' : 'Add New Review'}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="reviewer_name" className="text-right">
              Reviewer Name*
            </Label>
            <Input
              id="reviewer_name"
              name="reviewer_name"
              value={currentReview.reviewer_name}
              onChange={onInputChange}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="location_id" className="text-right">
              Location*
            </Label>
            <Select
              value={currentReview.location_id || "placeholder"}
              onValueChange={(value) => onSelectChange(value, 'location_id')}
            >
              <SelectTrigger className="col-span-3">
                <SelectValue placeholder="Select a location" />
              </SelectTrigger>
              <SelectContent>
                {gmbLocations?.map((location) => (
                  <SelectItem key={location.google_place_id} value={location.google_place_id}>
                    {location.location_name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label className="text-right">Rating*</Label>
            <div className="flex col-span-3">
              {[1, 2, 3, 4, 5].map((rating) => (
                <Star
                  key={rating}
                  size={24}
                  className={`cursor-pointer ${
                    rating <= getStarRating(currentReview.star_rating || 'FIVE')
                      ? "text-yellow-400 fill-yellow-400"
                      : "text-gray-300"
                  }`}
                  onClick={() => onStarClick(rating)}
                />
              ))}
            </div>
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="reviewer_comment" className="text-right">
              Comment
            </Label>
            <Textarea
              id="reviewer_comment"
              name="reviewer_comment"
              value={currentReview.reviewer_comment || ''}
              onChange={onInputChange}
              className="col-span-3"
              rows={4}
            />
          </div>
        </div>
        <DialogFooter>
          <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button type="button" onClick={onSubmit}>
            {isEditMode ? 'Update' : 'Add'} Review
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ReviewFormDialog;
