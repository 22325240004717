
import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

interface ImagePreviewProps {
  imageUrl: string;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ imageUrl }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div 
        className="h-10 w-10 rounded-md overflow-hidden cursor-pointer"
        onClick={() => setOpen(true)}
      >
        <img 
          src={imageUrl} 
          alt="Image preview" 
          className="h-full w-full object-cover"
        />
      </div>
      
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Image Preview</DialogTitle>
          </DialogHeader>
          <div className="flex justify-center p-4">
            <img 
              src={imageUrl} 
              alt="Full image preview" 
              className="max-h-[70vh] max-w-full object-contain"
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImagePreview;
