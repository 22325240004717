
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FileText, MapPin, Info, BookOpen, Image, Star, FileCheck, Home } from 'lucide-react';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import { cn } from '@/lib/utils';
import { serviceLinks, LocationLinks, navigationItems } from './navigationData';

const DesktopNavigation = () => {
  const [serviceLoading, setServiceLoading] = useState(false);
  const [servicesData, setServicesData] = useState(serviceLinks);

  return (
    <div className="hidden md:flex items-center space-x-0 lg:space-x-1">
      {/* Services Dropdown */}
      <NavigationMenu>
        <NavigationMenuList>
          <NavigationMenuItem>
            <NavigationMenuTrigger className="flex items-center space-x-1 px-2 lg:px-3 py-1.5 lg:py-2 text-xs lg:text-sm font-medium text-gray-700 hover:text-D00B0E rounded-md hover:bg-gray-50 transition-colors bg-transparent hover:bg-gray-50 data-[state=open]:bg-gray-50 data-[state=open]:text-D00B0E">
              <FileText className="h-3.5 w-3.5 lg:h-4 lg:w-4" />
              <span>Services</span>
            </NavigationMenuTrigger>
            <NavigationMenuContent>
              <ul className="grid w-[280px] md:w-[400px] gap-1 md:gap-2 p-2 md:p-4 md:grid-cols-2 lg:w-[600px]">
                {serviceLoading ? (
                  <li className="col-span-2 p-4 text-center text-gray-500">
                    Loading services...
                  </li>
                ) : (
                  servicesData.map((service) => (
                    <li key={service.slug}>
                      <NavigationMenuLink asChild>
                        <Link
                          to={`/our-services/${service.slug}`}
                          className={cn(
                            "flex items-center select-none rounded-md p-2 md:p-3 leading-none no-underline outline-none transition-colors hover:bg-gray-50 hover:text-D00B0E"
                          )}
                        >
                          <div className="text-xs md:text-sm font-medium">{service.name}</div>
                        </Link>
                      </NavigationMenuLink>
                    </li>
                  ))
                )}
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
      
      {/* Locations Dropdown */}
      <NavigationMenu>
        <NavigationMenuList>
          <NavigationMenuItem>
            <NavigationMenuTrigger className="flex items-center space-x-1 px-2 lg:px-3 py-1.5 lg:py-2 text-xs lg:text-sm font-medium text-gray-700 hover:text-D00B0E rounded-md hover:bg-gray-50 transition-colors bg-transparent hover:bg-gray-50 data-[state=open]:bg-gray-50 data-[state=open]:text-D00B0E">
              <MapPin className="h-3.5 w-3.5 lg:h-4 lg:w-4" />
              <span>Locations</span>
            </NavigationMenuTrigger>
            <NavigationMenuContent>
              <ul className="grid w-[280px] md:w-[450px] gap-1 md:gap-2 p-2 md:p-4 lg:w-[600px]">
                {LocationLinks.map((location) => (
                  <li key={location.slug} className="col-span-1">
                    <NavigationMenuLink asChild>
                      <Link
                        to={`/locations/${location.slug}`}
                        className={cn(
                          "flex flex-col select-none rounded-md p-2 md:p-3 leading-none no-underline outline-none transition-colors hover:bg-gray-50 hover:text-D00B0E"
                        )}
                      >
                        <div className="flex items-center space-x-2 mb-0.5 md:mb-1">
                          <MapPin className="h-4 w-4 text-D00B0E" />
                          <div className="text-xs md:text-sm font-medium">{location.name}</div>
                        </div>
                        <div className="text-[10px] md:text-xs text-gray-500 pl-5 md:pl-7">
                          {location.address}
                        </div>
                      </Link>
                    </NavigationMenuLink>
                  </li>
                ))}
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
      
      <Link to="/gallery" className="flex items-center space-x-1 px-2 lg:px-3 py-1.5 lg:py-2 text-xs lg:text-sm font-medium text-gray-700 hover:text-D00B0E rounded-md hover:bg-gray-50 transition-colors">
        <Image className="h-3.5 w-3.5 lg:h-4 lg:w-4" />
        <span>Gallery</span>
      </Link>
      
      {/* Blog link removed */}
      
      <Link to="/reviews" className="flex items-center space-x-1 px-2 lg:px-3 py-1.5 lg:py-2 text-xs lg:text-sm font-medium text-gray-700 hover:text-D00B0E rounded-md hover:bg-gray-50 transition-colors">
        <Star className="h-3.5 w-3.5 lg:h-4 lg:w-4" />
        <span>Reviews</span>
      </Link>
      
      <Link to="/wsib" className="flex items-center space-x-1 px-2 lg:px-3 py-1.5 lg:py-2 text-xs lg:text-sm font-medium text-gray-700 hover:text-D00B0E rounded-md hover:bg-gray-50 transition-colors">
        <FileCheck className="h-3.5 w-3.5 lg:h-4 lg:w-4" />
        <span>WSIB</span>
      </Link>
    </div>
  );
};

export default DesktopNavigation;
