
/**
 * Helper functions for the rich text editor
 */

/**
 * Insert HTML tags around selected text
 */
export const insertTag = (text: string, start: number, end: number, openTag: string, closeTag: string): string => {
  return text.substring(0, start) + openTag + text.substring(start, end) + closeTag + text.substring(end);
};

/**
 * Process selected content for HTML formatting
 */
export const processEditorAction = (
  action: string, 
  content: string, 
  start: number, 
  end: number
): string => {
  const selectedContent = content.substring(start, end);
  let newContent = content;
  
  switch (action) {
    case 'bold':
      newContent = insertTag(content, start, end, '<strong>', '</strong>');
      break;
    case 'italic':
      newContent = insertTag(content, start, end, '<em>', '</em>');
      break;
    case 'underline':
      newContent = insertTag(content, start, end, '<u>', '</u>');
      break;
    case 'strikethrough':
      newContent = insertTag(content, start, end, '<s>', '</s>');
      break;
    case 'h1':
      newContent = insertTag(content, start, end, '<h1>', '</h1>');
      break;
    case 'h2':
      newContent = insertTag(content, start, end, '<h2>', '</h2>');
      break;
    case 'h3':
      newContent = insertTag(content, start, end, '<h3>', '</h3>');
      break;
    case 'paragraph':
      newContent = insertTag(content, start, end, '<p>', '</p>');
      break;
    case 'quote':
      newContent = insertTag(content, start, end, '<blockquote>', '</blockquote>');
      break;
    case 'code':
      newContent = insertTag(content, start, end, '<code>', '</code>');
      break;
    case 'align-left':
      newContent = insertTag(content, start, end, '<div style="text-align: left;">', '</div>');
      break;
    case 'align-center':
      newContent = insertTag(content, start, end, '<div style="text-align: center;">', '</div>');
      break;
    case 'align-right':
      newContent = insertTag(content, start, end, '<div style="text-align: right;">', '</div>');
      break;
    case 'link':
      const url = prompt('Enter URL:', 'https://');
      if (url) {
        newContent = insertTag(content, start, end, `<a href="${url}" target="_blank" rel="noopener noreferrer">`, '</a>');
      }
      break;
    case 'image':
      const imageUrl = prompt('Enter image URL:', 'https://');
      const altText = prompt('Enter alt text:', selectedContent || 'Image description');
      if (imageUrl) {
        newContent = content.substring(0, start) + 
                    `<figure>
  <img src="${imageUrl}" alt="${altText}" />
  <figcaption>${altText}</figcaption>
</figure>` + 
                    content.substring(end);
      }
      break;
    case 'ul':
      if (selectedContent.includes('\n')) {
        // For multi-line selection, wrap each line in <li> tags
        const lines = selectedContent.split('\n');
        const formattedLines = lines.map(line => `  <li>${line}</li>`).join('\n');
        newContent = content.substring(0, start) + 
                    '<ul>\n' + formattedLines + '\n</ul>' + 
                    content.substring(end);
      } else {
        // For single line, just create a single list item
        newContent = insertTag(content, start, end, '<ul>\n  <li>', '</li>\n</ul>');
      }
      break;
    case 'ol':
      if (selectedContent.includes('\n')) {
        // For multi-line selection, wrap each line in <li> tags
        const lines = selectedContent.split('\n');
        const formattedLines = lines.map(line => `  <li>${line}</li>`).join('\n');
        newContent = content.substring(0, start) + 
                    '<ol>\n' + formattedLines + '\n</ol>' + 
                    content.substring(end);
      } else {
        // For single line, just create a single list item
        newContent = insertTag(content, start, end, '<ol>\n  <li>', '</li>\n</ol>');
      }
      break;
    case 'div':
      newContent = insertTag(content, start, end, '<div class="custom-section">', '</div>');
      break;
    default:
      break;
  }
  
  return newContent;
};
