
import React from 'react';
import { TableCell, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Check, X } from 'lucide-react';
import { Badge } from "@/components/ui/badge";
import ImagePreview from './ImagePreview';
import { FranchiseImage } from '@/hooks/franchise-images/types';

interface FranchiseImagesTableRowProps {
  image: FranchiseImage;
  approving: {[key: string]: boolean};
  rejecting: {[key: string]: boolean};
  updateImageStatus: (id: string | number, status: 'approved' | 'rejected' | 'pending') => void;
}

const FranchiseImagesTableRow: React.FC<FranchiseImagesTableRowProps> = ({
  image,
  approving,
  rejecting,
  updateImageStatus
}) => {
  const getStatusBadge = (status: string) => {
    switch (status) {
      case 'approved':
        return <Badge variant="success" className="flex items-center gap-1"><Check className="h-3 w-3" />Approved</Badge>;
      case 'rejected':
        return <Badge variant="destructive" className="flex items-center gap-1"><X className="h-3 w-3" />Rejected</Badge>;
      default:
        return <Badge variant="outline" className="flex items-center gap-1">Pending</Badge>;
    }
  };

  return (
    <TableRow>
      <TableCell>
        <ImagePreview imageUrl={image.image_url} />
      </TableCell>
      <TableCell>#{image.job_number}</TableCell>
      <TableCell>{image.franchise_region}</TableCell>
      <TableCell className="max-w-[200px] truncate" title={image.file_name}>
        {image.file_name}
      </TableCell>
      <TableCell>{getStatusBadge(image.review_status)}</TableCell>
      <TableCell>
        <div className="flex space-x-2">
          <Button
            variant="outline"
            size="sm"
            className="text-green-500 border-green-500 hover:bg-green-50"
            onClick={() => updateImageStatus(image.id, 'approved')}
            disabled={approving[image.id] || image.review_status === 'approved'}
          >
            <Check className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            size="sm"
            className="text-red-500 border-red-500 hover:bg-red-50"
            onClick={() => updateImageStatus(image.id, 'rejected')}
            disabled={rejecting[image.id] || image.review_status === 'rejected'}
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default FranchiseImagesTableRow;
