
import React from 'react';
import { Button } from "@/components/ui/button";

interface FormActionsProps {
  saving: boolean;
  validatingSlug: boolean;
  onCancel: () => void;
}

const FormActions = ({
  saving,
  validatingSlug,
  onCancel
}: FormActionsProps) => {
  return (
    <div className="space-y-4 pt-4">
      <Button 
        type="submit"
        disabled={saving || validatingSlug}
        className="mr-2"
      >
        {saving ? 'Saving...' : 'Save Gallery'}
      </Button>
      <Button
        type="button"
        variant="outline"
        onClick={onCancel}
      >
        Cancel
      </Button>
    </div>
  );
};

export default FormActions;
