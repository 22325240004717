
import { useGalleryData } from './useGalleryData';
import { useGalleryFormFields } from './useGalleryFormFields';
import { useGalleryFormSubmission } from './useGalleryFormSubmission';
import { useGallerySlugValidation } from './useGallerySlugValidation';
import { useGalleryPhotoManagement } from './useGalleryPhotoManagement';
import { useServiceOptions } from './useServiceOptions';

export const useGalleryForm = (id?: string) => {
  // Get basic gallery data
  const { 
    gallery, 
    setGallery, 
    loading, 
    isEditing 
  } = useGalleryData(id);

  // Form field handlers
  const {
    handleChange,
    handleSelectChange,
    handleCheckboxChange,
    handleImageUpload,
    handleReviewStatusChange,
    handleServiceChange
  } = useGalleryFormFields(gallery, setGallery);

  // Slug validation and generation
  const {
    validatingSlug,
    generateSlug,
    validateSlug
  } = useGallerySlugValidation(gallery, setGallery, id);

  // Form submission
  const {
    saving,
    handleSubmit,
    handleSaveGallery,
    navigateBack
  } = useGalleryFormSubmission(gallery, validateSlug, isEditing, id);

  // Photo management
  const {
    addPhotoToGallery
  } = useGalleryPhotoManagement(id);
  
  // Service options
  const {
    services: availableServices,
    loading: loadingServices
  } = useServiceOptions();

  return {
    gallery,
    setGallery,
    loading,
    saving,
    validatingSlug,
    isEditing,
    handleChange,
    handleSelectChange,
    handleCheckboxChange,
    handleImageUpload,
    handleReviewStatusChange,
    handleServiceChange,
    generateSlug,
    validateSlug,
    handleSubmit,
    handleSaveGallery,
    navigateBack,
    addPhotoToGallery,
    availableServices,
    loadingServices
  };
};

export * from './useGalleryData';
export * from './useGalleryFormFields';
export * from './useGalleryFormSubmission';
export * from './useGallerySlugValidation';
export * from './useGalleryPhotoManagement';
export * from './useServiceOptions';
