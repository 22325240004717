
import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { format } from 'date-fns';
import { MigrationHistory as MigrationHistoryType } from '@/lib/supabase/wordpress';

interface MigrationHistoryProps {
  history: MigrationHistoryType[];
  loading: boolean;
}

const MigrationHistory: React.FC<MigrationHistoryProps> = ({ history, loading }) => {
  if (loading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Migration History</CardTitle>
          <CardDescription>Loading migration history...</CardDescription>
        </CardHeader>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Migration History</CardTitle>
        <CardDescription>
          Previous WordPress content migration attempts
        </CardDescription>
      </CardHeader>
      <CardContent>
        {history.length === 0 ? (
          <div className="text-center py-4 text-gray-500">
            No migration history yet. Start your first migration above.
          </div>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Date</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Posts</TableHead>
                <TableHead>Categories</TableHead>
                <TableHead>Tags</TableHead>
                <TableHead>Notes</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {history.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {item.migration_date 
                      ? format(new Date(item.migration_date), 'MMM d, yyyy h:mm a')
                      : 'N/A'}
                  </TableCell>
                  <TableCell>
                    <Badge
                      variant={
                        item.status === 'success' 
                          ? 'default' 
                          : item.status === 'failed' 
                            ? 'destructive' 
                            : 'secondary'
                      }
                    >
                      {item.status}
                    </Badge>
                  </TableCell>
                  <TableCell>{item.posts_migrated}</TableCell>
                  <TableCell>{item.categories_migrated}</TableCell>
                  <TableCell>{item.tags_migrated}</TableCell>
                  <TableCell className="max-w-md truncate">{item.notes || 'No notes'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};

export default MigrationHistory;
