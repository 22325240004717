
import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';

interface DashboardCounts {
  pages: number;
  locations: number;
  services: number;
  testimonials: number;
}

interface ActivityItem {
  type: 'page' | 'service' | 'location' | 'testimonial';
  action: string;
  name: string;
  timestamp: string;
  timeAgo: string;
}

export function useDashboardData() {
  const [counts, setCounts] = useState<DashboardCounts>({
    pages: 0,
    locations: 0,
    services: 0,
    testimonials: 0
  });
  const [recentActivity, setRecentActivity] = useState<ActivityItem[]>([]);
  const [loading, setLoading] = useState(true);

  // Helper function to get relative time
  const getTimeAgo = (date: Date): string => {
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    
    if (diffInSeconds < 60) return 'just now';
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)} days ago`;
    
    return `${Math.floor(diffInSeconds / 604800)} weeks ago`;
  };

  useEffect(() => {
    const fetchCounts = async () => {
      setLoading(true);
      try {
        // Fetch counts from Supabase
        const [pagesResult, locationsResult, servicesResult, testimonialsResult] = await Promise.all([
          supabase.from('static_pages').select('id', { count: 'exact', head: true }),
          supabase.from('locations').select('id', { count: 'exact', head: true }),
          supabase.from('services').select('id', { count: 'exact', head: true }),
          supabase.from('testimonials').select('id', { count: 'exact', head: true })
        ]);

        // Update counts
        setCounts({
          pages: pagesResult.count || 0,
          locations: locationsResult.count || 0,
          services: servicesResult.count || 0,
          testimonials: testimonialsResult.count || 0
        });

        // Fetch recent activity (most recently updated items)
        const recentItems: ActivityItem[] = [];

        // Get recent pages
        const { data: recentPages } = await supabase
          .from('static_pages')
          .select('title, updated_at')
          .order('updated_at', { ascending: false })
          .limit(2);

        if (recentPages) {
          recentPages.forEach(page => {
            recentItems.push({
              type: 'page',
              action: 'updated',
              name: page.title,
              timestamp: page.updated_at,
              timeAgo: getTimeAgo(new Date(page.updated_at))
            });
          });
        }

        // Get recent services
        const { data: recentServices } = await supabase
          .from('services')
          .select('title, updated_at')
          .order('updated_at', { ascending: false })
          .limit(2);

        if (recentServices) {
          recentServices.forEach(service => {
            recentItems.push({
              type: 'service',
              action: 'updated',
              name: service.title,
              timestamp: service.updated_at,
              timeAgo: getTimeAgo(new Date(service.updated_at))
            });
          });
        }

        // Get recent locations
        const { data: recentLocations } = await supabase
          .from('locations')
          .select('name, updated_at')
          .order('updated_at', { ascending: false })
          .limit(2);

        if (recentLocations) {
          recentLocations.forEach(location => {
            recentItems.push({
              type: 'location',
              action: 'updated',
              name: location.name,
              timestamp: location.updated_at,
              timeAgo: getTimeAgo(new Date(location.updated_at))
            });
          });
        }

        // Get recent testimonials
        const { data: recentTestimonials } = await supabase
          .from('testimonials')
          .select('client_name, created_at')
          .order('created_at', { ascending: false })
          .limit(2);

        if (recentTestimonials) {
          recentTestimonials.forEach(testimonial => {
            recentItems.push({
              type: 'testimonial',
              action: 'added',
              name: testimonial.client_name,
              timestamp: testimonial.created_at,
              timeAgo: getTimeAgo(new Date(testimonial.created_at))
            });
          });
        }

        // Sort all items by timestamp (newest first)
        recentItems.sort((a, b) => 
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        );

        // Take the 4 most recent items
        setRecentActivity(recentItems.slice(0, 4));

      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        toast.error("Failed to load dashboard data");
      } finally {
        setLoading(false);
      }
    };

    fetchCounts();
  }, []);

  return {
    counts,
    recentActivity,
    loading
  };
}
