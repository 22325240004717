
import React from 'react';
import { TableCell, TableRow, TableBody } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { X } from 'lucide-react';

interface EmptyStateProps {
  hasFilters: boolean;
  onClearFilters: () => void;
}

const EmptyState: React.FC<EmptyStateProps> = ({ hasFilters, onClearFilters }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={6} className="text-center py-8 text-muted-foreground">
          <div className="flex flex-col items-center gap-2">
            {hasFilters ? (
              <>
                <p>No images found matching the current filters</p>
                <Button 
                  variant="outline" 
                  size="sm"
                  className="mt-2"
                  onClick={onClearFilters}
                >
                  <X className="h-4 w-4 mr-2" />
                  Clear Filters
                </Button>
              </>
            ) : (
              <p>No franchise images found</p>
            )}
          </div>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default EmptyState;
