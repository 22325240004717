import React, { useState, useMemo } from 'react';
import { Layout } from '@/components/Layout';
import { useQuery } from '@tanstack/react-query';
import { fetchAllReviews, Review } from '@/lib/supabase';
import { Star, Search, User, MapPin } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import PageMeta from '@/components/SEO/PageMeta';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { formatDate } from '@/lib/utils';
import ReviewCard from '@/components/ui/review-card';
import ContactButton from '@/components/shared/ContactButton';

const Reviews = () => {
  const { data: reviews, isLoading: reviewsLoading } = useQuery({
    queryKey: ['all-reviews'],
    queryFn: fetchAllReviews,
    staleTime: 1000 * 60 * 5,
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('newest');

  const getStarRating = (rating: string): number => {
    if (rating === 'FIVE') return 5;
    if (rating === 'FOUR') return 4;
    if (rating === 'THREE') return 3;
    if (rating === 'TWO') return 2;
    if (rating === 'ONE') return 1;
    return parseInt(rating, 10) || 5;
  };

  const filteredAndSortedReviews = useMemo(() => {
    if (!reviews) return [];

    let result = [...reviews];
    
    result = result.filter(review => {
      const rating = getStarRating(review.star_rating);
      return rating >= 4 && review.reviewer_comment && review.reviewer_comment.trim() !== '';
    });
    
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      result = result.filter(review => {
        return (
          (review.reviewer_name && review.reviewer_name.toLowerCase().includes(term)) ||
          (review.reviewer_comment && review.reviewer_comment.toLowerCase().includes(term)) ||
          (review.location_name && review.location_name.toLowerCase().includes(term))
        );
      });
    }
    
    result.sort((a, b) => {
      switch (sortBy) {
        case 'highest':
          return getStarRating(b.star_rating) - getStarRating(a.star_rating);
        case 'lowest':
          return getStarRating(a.star_rating) - getStarRating(b.star_rating);
        case 'oldest':
          return new Date(a.reviewer_create_time || a.create_time || a.created_at).getTime() - 
                 new Date(b.reviewer_create_time || b.create_time || b.created_at).getTime();
        case 'newest':
        default:
          return new Date(b.reviewer_create_time || b.create_time || b.created_at).getTime() - 
                 new Date(a.reviewer_create_time || a.create_time || a.created_at).getTime();
      }
    });
    
    return result;
  }, [reviews, searchTerm, sortBy]);

  const isLoading = reviewsLoading;

  return (
    <Layout>
      <PageMeta
        title="Customer Reviews | Fix My Brick"
        description="Read reviews from our satisfied customers about our masonry services across Ontario. See what clients are saying about Fix My Brick."
        canonical="/reviews"
        type="website"
      />

      <div className="bg-gray-900 text-white relative">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-900/80 to-gray-900/70 z-0"></div>
        <div 
          className="absolute inset-0 bg-cover bg-center opacity-50 z-[-1]"
          style={{ backgroundImage: "url('https://images.unsplash.com/photo-1578474846511-04ba529f0b88?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2000&q=80')" }}
        ></div>
        
        <div className="container mx-auto px-4 py-20 relative z-10">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-4">Customer Reviews</h1>
            <p className="text-xl text-gray-200 mb-6">
              Hear what our satisfied customers have to say about our masonry services
            </p>
          </div>
        </div>
      </div>

      <section className="py-8 bg-gray-50 border-b border-gray-200">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
            <div className="relative w-full md:w-1/3">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
              <Input
                type="text"
                placeholder="Search reviews..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 w-full"
              />
            </div>
            
            <div className="w-full md:w-auto">
              <Select value={sortBy} onValueChange={setSortBy}>
                <SelectTrigger className="w-full sm:w-[180px]">
                  <SelectValue placeholder="Sort by" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="newest">Newest First</SelectItem>
                  <SelectItem value="oldest">Oldest First</SelectItem>
                  <SelectItem value="highest">Highest Rating</SelectItem>
                  <SelectItem value="lowest">Lowest Rating</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
      </section>

      <section className="py-12 bg-white">
        <div className="container mx-auto px-4">
          {isLoading ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {[...Array(4)].map((_, index) => (
                <Card key={index} className="animate-pulse shadow-sm">
                  <CardContent className="p-6">
                    <div className="flex items-start gap-4">
                      <div className="w-12 h-12 rounded-full bg-gray-200"></div>
                      <div className="flex-1">
                        <div className="h-5 bg-gray-200 rounded w-1/3 mb-2"></div>
                        <div className="h-4 bg-gray-200 rounded w-1/4 mb-4"></div>
                        <div className="flex gap-1 mb-4">
                          {[...Array(5)].map((_, i) => (
                            <div key={i} className="w-4 h-4 rounded-full bg-gray-200"></div>
                          ))}
                        </div>
                        <div className="h-4 bg-gray-200 rounded w-full mb-2"></div>
                        <div className="h-4 bg-gray-200 rounded w-full mb-2"></div>
                        <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          ) : filteredAndSortedReviews.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {filteredAndSortedReviews.map((review) => (
                <ReviewCard 
                  key={review.id} 
                  review={review} 
                  showLocation={true} 
                />
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <h3 className="text-xl font-medium text-gray-700 mb-2">No reviews found</h3>
              <p className="text-gray-500">
                {searchTerm
                  ? "Try adjusting your search criteria."
                  : "We don't have any reviews that match your criteria at the moment."}
              </p>
            </div>
          )}
        </div>
      </section>

      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to experience our service?</h2>
          <p className="text-gray-600 mb-8 max-w-2xl mx-auto">
            Join our many satisfied customers and get your masonry project completed by trusted professionals.
          </p>
          <ContactButton 
            className="px-6 py-3 h-14 text-lg"
            variant="primary"
          >
            Get a Free Quote
          </ContactButton>
        </div>
      </section>
    </Layout>
  );
};

export default Reviews;
