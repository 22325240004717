
import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

export interface ServiceCardProps {
  title: string;
  description: string;
  link: string;
  imageUrl?: string;
}

const ServiceCard = ({ title, description, link, imageUrl }: ServiceCardProps) => {
  return (
    <div className="bg-white overflow-hidden shadow-sm rounded-lg flex flex-col h-full">
      {imageUrl && (
        <div className="h-48 overflow-hidden">
          <img 
            src={imageUrl} 
            alt={title} 
            className="w-full h-full object-cover"
          />
        </div>
      )}
      
      <div className="p-6 flex flex-col flex-grow">
        <h3 className="text-xl font-bold mb-3">{title}</h3>
        <p className="text-gray-600 mb-6 flex-grow">{description}</p>
        
        <Link 
          to={link}
          className="text-D00B0E hover:underline flex items-center mt-auto group"
        >
          Learn more <ArrowRight className="ml-1 h-4 w-4 group-hover:translate-x-1 transition-transform" />
        </Link>
      </div>
    </div>
  );
};

export default ServiceCard;
