
import { useState } from 'react';
import { supabase } from '@/lib/supabase';
import { ServiceDetail } from '@/types/service';
import { toast } from 'sonner';
import { generateSlug, validateServiceForm } from '@/utils/serviceFormUtils';
import { invalidateServiceCache, cacheService } from '@/lib/serviceCache';

/**
 * Hook for handling service save operations
 */
export const useServiceSave = (
  formData: ServiceDetail, 
  isNewService: boolean, 
  id?: string
) => {
  const [saving, setSaving] = useState(false);

  const handleSave = async () => {
    try {
      setSaving(true);
      console.log('Saving service data:', formData);

      // Generate slug from title if not provided
      const updatedFormData = { ...formData };
      if (!updatedFormData.slug && updatedFormData.title) {
        updatedFormData.slug = generateSlug(updatedFormData.title);
      }

      // Validate form data
      const { isValid, message } = validateServiceForm(
        updatedFormData.title, 
        updatedFormData.description, 
        updatedFormData.full_description
      );
      
      if (!isValid) {
        toast.error(message);
        setSaving(false);
        return null;
      }

      // Create the data object with base fields and SEO fields
      // Create a copy of the form data and remove review_ids to avoid overwriting them
      const { review_ids, ...formDataWithoutReviews } = updatedFormData;
      
      // Create the final service data object
      const serviceData = {
        title: formDataWithoutReviews.title,
        slug: formDataWithoutReviews.slug,
        description: formDataWithoutReviews.description,
        full_description: formDataWithoutReviews.full_description,
        benefits: formDataWithoutReviews.benefits || [],
        process_steps: formDataWithoutReviews.process_steps || [],
        faq: formDataWithoutReviews.faq || [],
        image_url: formDataWithoutReviews.image_url || '',
        hero_image: formDataWithoutReviews.hero_image || null,
        hero_headline: formDataWithoutReviews.hero_headline || null,
        cta_text: formDataWithoutReviews.cta_text || 'Ready to start your project? Contact our team today for a free consultation and quote.',
        // Include SEO fields
        meta_title: formDataWithoutReviews.meta_title || null,
        meta_description: formDataWithoutReviews.meta_description || null,
        og_title: formDataWithoutReviews.og_title || null,
        og_description: formDataWithoutReviews.og_description || null,
        og_image: formDataWithoutReviews.og_image || null,
        canonical_url: formDataWithoutReviews.canonical_url || null,
        focus_keyword: formDataWithoutReviews.focus_keyword || null,
        // Include is_featured field
        is_featured: formDataWithoutReviews.is_featured || false,
        // Include gallery_images field
        gallery_images: formDataWithoutReviews.gallery_images || [],
        // Explicitly exclude review_ids as they are managed separately in the ReviewsTab component
      };

      console.log('Service data being saved:', serviceData);

      if (isNewService) {
        // Create new service - don't include the id field at all
        const { data, error } = await supabase
          .from('services')
          .insert([serviceData])
          .select();

        if (error) {
          throw error;
        }

        if (data && data.length > 0) {
          // Add the newly created service to the cache
          cacheService(data[0]);
          
          // Also invalidate the services list
          invalidateServiceCache(data[0].slug);
          
          toast.success('Service created successfully');
          return data[0].id;
        } else {
          throw new Error('No data returned after service creation');
        }
      } else {
        // Ensure we have a valid id
        if (!id) {
          throw new Error('Invalid service ID for update');
        }
        
        // Update existing service - explicitly include the id for the update
        const serviceDataWithId = { ...serviceData, id };
        
        const { data, error } = await supabase
          .from('services')
          .update(serviceDataWithId)
          .eq('id', id)
          .select();

        if (error) {
          throw error;
        }

        if (data && data.length > 0) {
          // Update the service in the cache
          cacheService(data[0]);
          
          // Also invalidate the services list to force a refresh
          invalidateServiceCache(data[0].slug);
          
          toast.success('Service updated successfully');
          return id;
        } else {
          // Even if no data was returned, we still want to invalidate the cache
          if (serviceData.slug) {
            invalidateServiceCache(serviceData.slug);
          }
          
          // Force an invalidation of the entire services list
          invalidateServiceCache('');
          
          toast.success('Service updated successfully');
          return id;
        }
      }
    } catch (error: any) {
      console.error('Error saving service:', error);
      toast.error(`Failed to save service: ${error.message}`);
      return null;
    } finally {
      setSaving(false);
    }
  };

  return {
    saving,
    handleSave
  };
};
