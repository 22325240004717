import React from 'react';
import { Link } from 'react-router-dom';
import { MapPin } from 'lucide-react';
import { Location } from '@/types/location';
import ContactButton from '../shared/ContactButton';

interface LocationSubareasProps {
  location: Location;
  childLocations: Location[];
  sublocationSlug?: string;
}

const LocationSubareas: React.FC<LocationSubareasProps> = ({ 
  location, 
  childLocations,
  sublocationSlug
}) => {
  if (childLocations.length === 0) {
    return null;
  }
  
  return (
    <div className="bg-white shadow-md rounded-lg p-6 border border-gray-100 mb-8">
      <h2 className="text-2xl font-semibold mb-6">Service Areas in {location.name}</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {childLocations.map(child => (
          <Link 
            key={child.id}
            to={`/locations/${location.slug}/${child.slug}`}
            className={`p-3 border rounded-md flex items-center hover:bg-gray-50 transition-colors ${
              sublocationSlug === child.slug ? 'bg-gray-100 border-D00B0E' : ''
            }`}
          >
            <MapPin className="h-4 w-4 mr-2 text-D00B0E" />
            {child.name}
          </Link>
        ))}
      </div>
      
      <div className="mt-8 flex justify-center">
        <ContactButton
          locationName={location.name}
          className="py-3 px-6 rounded-md font-medium inline-block"
          variant="primary"
        >
          Get a Free Quote
        </ContactButton>
      </div>
    </div>
  );
};

export default LocationSubareas;
