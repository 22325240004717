import React from 'react';
import { useModal } from './modal';

interface ContactButtonProps {
  className?: string;
  children: React.ReactNode;
  serviceSlug?: string;
  locationName?: string;
  variant?: 'primary' | 'secondary' | 'outlined';
}

const ContactButton: React.FC<ContactButtonProps> = ({
  className = '',
  children,
  serviceSlug = '',
  locationName = '',
  variant = 'primary'
}) => {
  const { openContactModal } = useModal();
  
  // Define base styles for different variants
  const baseStyles = 'inline-block font-bold py-2 px-6 rounded-md transition-colors duration-200 text-center';
  
  // Apply styles based on variant
  const variantStyles = {
    primary: 'bg-[#D00B0E] hover:bg-[#D00B0E]/90 text-white',
    secondary: 'bg-blue-600 hover:bg-blue-700 text-white',
    outlined: 'border-2 border-[#D00B0E] text-[#D00B0E] hover:bg-[#D00B0E] hover:text-white'
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    openContactModal(serviceSlug, locationName);
  };

  return (
    <button
      onClick={handleClick}
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
    >
      {children}
    </button>
  );
};

export default ContactButton;
