import React from 'react';
import AdminLayout from '@/components/admin/AdminLayout';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@/components/ui/breadcrumb';
import { Home, Plus, Search } from 'lucide-react';
import ReviewFormDialog from '@/components/admin/reviews/ReviewFormDialog';
import ReviewsTable from '@/components/admin/reviews/ReviewsTable';
import { useAdminReviews } from '@/hooks/useAdminReviews';
import { Review } from '@/lib/supabase';
import { toast } from 'sonner';

const AdminReviews = () => {
  const {
    activeTab,
    setActiveTab,
    search,
    setSearch,
    isDialogOpen,
    setIsDialogOpen,
    isEditMode,
    setIsEditMode,
    currentReview,
    setCurrentReview,
    gmbLocations,
    filteredReviews,
    isLoading,
    createReviewMutation,
    updateReviewMutation,
    deleteReviewMutation,
  } = useAdminReviews();

  const getLocationName = (locationId: string) => {
    if (!gmbLocations) return 'Unknown';
    const location = gmbLocations.find(location => location.google_place_id === locationId);
    return location ? location.location_name : 'Unknown';
  };

  const getStarRating = (rating: string): number => {
    if (rating === 'FIVE') return 5;
    if (rating === 'FOUR') return 4;
    if (rating === 'THREE') return 3;
    if (rating === 'TWO') return 2;
    if (rating === 'ONE') return 1;
    return parseInt(rating, 10) || 5;
  };

  const convertToStarRating = (rating: number): string => {
    switch (rating) {
      case 5: return 'FIVE';
      case 4: return 'FOUR';
      case 3: return 'THREE';
      case 2: return 'TWO';
      case 1: return 'ONE';
      default: return 'FIVE';
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-CA', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const handleEdit = (review: Review) => {
    setCurrentReview({
      ...review,
      star_rating: review.star_rating
    });
    setIsEditMode(true);
    setIsDialogOpen(true);
  };

  const handleDelete = (id: string) => {
    if (window.confirm('Are you sure you want to delete this review?')) {
      deleteReviewMutation.mutate(id);
    }
  };

  const handleAddNew = () => {
    setCurrentReview({
      reviewer_name: '',
      star_rating: 'FIVE',
      reviewer_comment: '',
      location_id: gmbLocations && gmbLocations.length > 0 ? gmbLocations[0].google_place_id : '',
      review_id: `manual-${Date.now()}`,
      create_time: new Date().toISOString(),
      update_time: new Date().toISOString(),
    });
    setIsEditMode(false);
    setIsDialogOpen(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setCurrentReview(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (value: string, fieldName: string) => {
    setCurrentReview(prev => ({ ...prev, [fieldName]: value }));
  };

  const handleStarClick = (rating: number) => {
    setCurrentReview(prev => ({ ...prev, star_rating: convertToStarRating(rating) }));
  };

  const handleSubmit = () => {
    if (!currentReview.reviewer_name || !currentReview.star_rating || !currentReview.location_id) {
      toast.error('Please fill in all required fields');
      return;
    }

    if (isEditMode && currentReview.id) {
      updateReviewMutation.mutate({ 
        id: currentReview.id, 
        review: {
          reviewer_name: currentReview.reviewer_name,
          star_rating: currentReview.star_rating,
          reviewer_comment: currentReview.reviewer_comment,
          location_id: currentReview.location_id,
          update_time: new Date().toISOString(),
        } 
      });
    } else {
      createReviewMutation.mutate({
        reviewer_name: currentReview.reviewer_name!,
        star_rating: currentReview.star_rating!,
        reviewer_comment: currentReview.reviewer_comment || '',
        location_id: currentReview.location_id!,
        review_id: `manual-${Date.now()}`,
        create_time: new Date().toISOString(),
        update_time: new Date().toISOString(),
      });
    }
  };

  return (
    <AdminLayout title="Reviews Management">
      <Breadcrumb className="mb-4">
        <BreadcrumbItem>
          <BreadcrumbLink href="/admin/dashboard">
            <Home className="h-4 w-4 mr-2" />
            Dashboard
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>Reviews Management</BreadcrumbItem>
      </Breadcrumb>

      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Manage Reviews</h2>
        <Button onClick={handleAddNew}>
          <Plus size={16} className="mr-2" />
          Add Review
        </Button>
      </div>

      <div className="bg-white rounded-lg shadow mb-6">
        <div className="p-4 border-b">
          <div className="flex flex-col md:flex-row justify-between gap-4">
            <Tabs defaultValue={activeTab} className="w-full md:w-auto" onValueChange={setActiveTab}>
              <TabsList>
                <TabsTrigger value="all">All Reviews</TabsTrigger>
                <TabsTrigger value="google">Google Reviews</TabsTrigger>
                <TabsTrigger value="manual">Manual Reviews</TabsTrigger>
              </TabsList>
            </Tabs>
            <div className="relative w-full md:w-64">
              <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-500" />
              <Input
                placeholder="Search reviews..."
                className="pl-8"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>

        <ReviewsTable
          reviews={filteredReviews || []}
          onEdit={handleEdit}
          onDelete={handleDelete}
          getLocationName={getLocationName}
          getStarRating={getStarRating}
          formatDate={formatDate}
          isLoading={isLoading}
        />
      </div>

      <ReviewFormDialog
        isOpen={isDialogOpen}
        onOpenChange={setIsDialogOpen}
        isEditMode={isEditMode}
        currentReview={currentReview}
        gmbLocations={gmbLocations}
        onInputChange={handleInputChange}
        onSelectChange={handleSelectChange}
        onStarClick={handleStarClick}
        onSubmit={handleSubmit}
        getStarRating={getStarRating}
      />
    </AdminLayout>
  );
};

export default AdminReviews;
