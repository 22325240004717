
/**
 * Utility functions for handling URL parameters, especially for tracking
 */

/**
 * Get tracking parameters from the URL
 * @returns Object with all tracking parameters found in the URL
 */
export const getTrackingParams = (): Record<string, string> => {
  const params: Record<string, string> = {};
  
  // Only run in browser
  if (typeof window === 'undefined') return params;
  
  // Get URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  
  // Standard UTM parameters
  const utmParams = [
    'utm_source', 
    'utm_medium', 
    'utm_campaign', 
    'utm_term', 
    'utm_content'
  ];
  
  // Get UTM parameters
  utmParams.forEach(param => {
    const value = urlParams.get(param);
    if (value) {
      params[param] = value;
    }
  });
  
  // Get additional tracking parameters
  const additionalParams = ['ref', 'source', 'fbclid', 'gclid'];
  additionalParams.forEach(param => {
    const value = urlParams.get(param);
    if (value) {
      params[param] = value;
    }
  });
  
  return params;
};

/**
 * Save tracking parameters to sessionStorage
 * @param params Parameters to save
 */
export const saveTrackingParams = (params: Record<string, string>): void => {
  if (typeof window === 'undefined' || !params || Object.keys(params).length === 0) return;
  
  try {
    sessionStorage.setItem('tracking_params', JSON.stringify(params));
  } catch (error) {
    console.warn('Failed to save tracking params to sessionStorage:', error);
  }
};

/**
 * Get previously saved tracking parameters from sessionStorage
 * @returns Object with saved tracking parameters
 */
export const getSavedTrackingParams = (): Record<string, string> => {
  if (typeof window === 'undefined') return {};
  
  try {
    const params = sessionStorage.getItem('tracking_params');
    return params ? JSON.parse(params) : {};
  } catch (error) {
    console.warn('Failed to get tracking params from sessionStorage:', error);
    return {};
  }
};
