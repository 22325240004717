
import React, { useState } from 'react';
import { useBlogData } from '@/hooks/useBlogData';
import BlogLayout from '@/components/blog/BlogLayout';
import PostsList from '@/components/blog/PostsList';
import BlogHeader from '@/components/blog/BlogHeader';
import BlogSidebar from '@/components/blog/BlogSidebar';
import BlogSearch from '@/components/blog/BlogSearch';
import PageMeta from '@/components/SEO/PageMeta';
import LoadingState from '@/components/blog/LoadingState';
import { useDebounce } from '@/hooks/useDebounce';

const Blog: React.FC = () => {
  const { posts, categories, loading, error } = useBlogData();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  // Filter posts based on search term and selected category
  const filteredPosts = posts.filter(post => {
    const matchesSearch = debouncedSearchTerm === '' || 
      post.title.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
      (post.excerpt && post.excerpt.toLowerCase().includes(debouncedSearchTerm.toLowerCase()));
    
    const matchesCategory = selectedCategory === null || 
      post.blog_categories?.some(cat => cat.slug === selectedCategory);
    
    return matchesSearch && matchesCategory;
  });

  // Clear all filters
  const clearFilters = () => {
    setSearchTerm('');
    setSelectedCategory(null);
  };

  // Handle category selection from sidebar
  const handleCategorySelect = (categorySlug: string) => {
    setSelectedCategory(categorySlug === selectedCategory ? null : categorySlug);
  };

  return (
    <BlogLayout>
      <PageMeta
        title="Masonry Blog - Fix My Brick"
        description="Read the latest articles about brick repairs, masonry restoration, and property maintenance from the experts at Fix My Brick."
        canonical="/blog"
      />

      <div className="max-w-6xl mx-auto">
        <BlogHeader title="Our Blog" subtitle="Read the latest news and tips from our masonry experts" />

        {loading ? (
          <LoadingState />
        ) : error ? (
          <div className="p-8 text-center">
            <p className="text-gray-500">Failed to load blog posts. Please try again later.</p>
          </div>
        ) : (
          <>
            <BlogSearch 
              searchTerm={searchTerm} 
              onSearchChange={setSearchTerm} 
              selectedCategory={selectedCategory}
              categoryName={selectedCategory ? categories.find(c => c.slug === selectedCategory)?.name : null}
              onClearFilters={clearFilters}
              postsCount={filteredPosts.length}
            />
            
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-10 mt-6">
              <div className="lg:col-span-3">
                {filteredPosts.length > 0 ? (
                  <PostsList posts={filteredPosts} />
                ) : (
                  <div className="p-8 text-center bg-white rounded-lg shadow">
                    <p className="text-gray-500">No matching blog posts found. Try adjusting your search.</p>
                  </div>
                )}
              </div>

              <div className="lg:col-span-1">
                <BlogSidebar 
                  categories={categories} 
                  onCategorySelect={handleCategorySelect}
                  selectedCategory={selectedCategory}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </BlogLayout>
  );
};

export default Blog;
