
import { supabase } from './client';

// Function to fetch a static page by slug
export async function fetchStaticPageBySlug(slug: string) {
  try {
    const { data, error } = await supabase
      .from('static_pages')
      .select('*')
      .eq('slug', slug)
      .single();
    
    if (error) {
      console.error(`Error fetching static page with slug ${slug}:`, error);
      return null;
    }
    
    return data;
  } catch (error) {
    console.error(`Error in fetchStaticPageBySlug for ${slug}:`, error);
    return null;
  }
}

// Function to fetch a static page by ID
export async function fetchStaticPageById(id: string) {
  try {
    const { data, error } = await supabase
      .from('static_pages')
      .select('*')
      .eq('id', id)
      .single();
    
    if (error) {
      console.error(`Error fetching static page with id ${id}:`, error);
      return null;
    }
    
    return data;
  } catch (error) {
    console.error(`Error in fetchStaticPageById for ${id}:`, error);
    return null;
  }
}

// Function to fetch all static pages
export async function fetchStaticPages() {
  try {
    const { data, error } = await supabase
      .from('static_pages')
      .select('*')
      .order('title', { ascending: true });
    
    if (error) {
      console.error('Error fetching static pages:', error);
      return [];
    }
    
    return data || [];
  } catch (error) {
    console.error('Error in fetchStaticPages:', error);
    return [];
  }
}

// Function to update a static page content
export async function updateStaticPage(id: string, data: {
  title?: string;
  content?: string;
  meta_description?: string;
}) {
  try {
    const { error } = await supabase
      .from('static_pages')
      .update(data)
      .eq('id', id);
    
    if (error) {
      console.error(`Error updating static page with id ${id}:`, error);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error(`Error in updateStaticPage for ${id}:`, error);
    return false;
  }
}

// Function to create a new static page
export async function createStaticPage(data: {
  title: string;
  content: string;
  slug: string;
  meta_description?: string;
}) {
  try {
    // Check if slug already exists
    const { data: existingPage } = await supabase
      .from('static_pages')
      .select('id')
      .eq('slug', data.slug)
      .single();
    
    if (existingPage) {
      throw new Error(`A page with slug "${data.slug}" already exists`);
    }
    
    const { data: newPage, error } = await supabase
      .from('static_pages')
      .insert([data])
      .select()
      .single();
    
    if (error) {
      console.error('Error creating static page:', error);
      throw error;
    }
    
    return newPage;
  } catch (error) {
    console.error('Error in createStaticPage:', error);
    throw error;
  }
}

// Function to delete a static page
export async function deleteStaticPage(id: string) {
  try {
    const { error } = await supabase
      .from('static_pages')
      .delete()
      .eq('id', id);
    
    if (error) {
      console.error(`Error deleting static page with id ${id}:`, error);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error(`Error in deleteStaticPage for ${id}:`, error);
    return false;
  }
}
