
import React from 'react';
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { LoaderCircle } from 'lucide-react';

interface BasicInfoFieldsProps {
  title: string;
  slug: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSlugChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  generateSlug: () => void;
  validatingSlug: boolean;
}

const BasicInfoFields = ({
  title,
  slug,
  handleChange,
  handleSlugChange,
  generateSlug,
  validatingSlug
}: BasicInfoFieldsProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="space-y-2">
        <Label htmlFor="title">Gallery Title</Label>
        <Input
          id="title"
          name="title"
          value={title}
          onChange={handleChange}
          placeholder="Enter gallery title"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="slug">Gallery Slug</Label>
        <div className="flex gap-2 items-center">
          <div className="flex-1 relative">
            <Input
              id="slug"
              name="slug"
              value={slug}
              onChange={handleSlugChange}
              placeholder="enter-gallery-slug"
            />
            {validatingSlug && (
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                <LoaderCircle className="h-4 w-4 animate-spin text-gray-400" />
              </div>
            )}
          </div>
          <Button type="button" variant="outline" onClick={generateSlug}>
            Generate
          </Button>
        </div>
        <p className="text-xs text-gray-500 mt-1">
          This will be used in the URL: /gallery/<span className="font-mono">{slug || 'example-slug'}</span>
        </p>
      </div>
    </div>
  );
};

export default BasicInfoFields;
