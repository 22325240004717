
import { supabase } from '../client';

// Fetch blog categories
export const fetchCategories = async () => {
  const { data, error } = await supabase
    .from('blog_categories')
    .select('*')
    .order('name');

  if (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }

  return data;
};

// Categories CRUD operations
export const createCategory = async (data: { name: string; slug: string; description?: string }) => {
  const { data: newCategory, error } = await supabase
    .from('blog_categories')
    .insert([data])
    .select()
    .single();

  if (error) throw error;
  return newCategory;
};

export const updateCategory = async (id: string, data: { name?: string; slug?: string; description?: string }) => {
  const { data: updatedCategory, error } = await supabase
    .from('blog_categories')
    .update(data)
    .eq('id', id)
    .select()
    .single();

  if (error) throw error;
  return updatedCategory;
};

export const deleteCategory = async (id: string) => {
  const { error } = await supabase
    .from('blog_categories')
    .delete()
    .eq('id', id);

  if (error) throw error;
  return true;
};

// Post-category relationships
export const updatePostCategories = async (postId: string, categoryIds: string[]) => {
  // First delete existing relationships
  await supabase
    .from('blog_post_categories')
    .delete()
    .eq('post_id', postId);

  // Then insert new ones
  if (categoryIds.length > 0) {
    const { error } = await supabase
      .from('blog_post_categories')
      .insert(categoryIds.map(categoryId => ({
        post_id: postId,
        category_id: categoryId
      })));

    if (error) throw error;
  }

  return true;
};
