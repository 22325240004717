
import React from 'react';
import { TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Skeleton } from "@/components/ui/skeleton";

const LoadingState: React.FC = () => {
  return (
    <>
      <TableHeader>
        <TableRow>
          <TableHead>Image</TableHead>
          <TableHead>Job Number</TableHead>
          <TableHead>Franchise</TableHead>
          <TableHead>File Name</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Actions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {Array(5).fill(0).map((_, index) => (
          <TableRow key={index} className="animate-pulse">
            <TableCell><Skeleton className="h-12 w-12 rounded-md bg-muted/70" /></TableCell>
            <TableCell><Skeleton className="h-5 w-20 bg-muted/70" /></TableCell>
            <TableCell><Skeleton className="h-5 w-28 bg-muted/70" /></TableCell>
            <TableCell><Skeleton className="h-5 w-40 bg-muted/70" /></TableCell>
            <TableCell><Skeleton className="h-6 w-24 bg-muted/70 rounded-full" /></TableCell>
            <TableCell>
              <div className="flex space-x-2">
                <Skeleton className="h-9 w-9 rounded-md bg-muted/70" />
                <Skeleton className="h-9 w-9 rounded-md bg-muted/70" />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </>
  );
};

export default LoadingState;
