import { supabase } from './client';
import { getFallbackServiceContent } from '@/data/fallbackServiceContent';
import { ServiceDetail } from '@/types/service';
import { cacheService, getCachedService } from '../serviceCache';
import { v4 as uuidv4 } from 'uuid';

const pendingRequests: Record<string, Promise<ServiceDetail | null>> = {};

export async function ensureServicesData() {
  try {
    const fallbackServices = getFallbackServiceContent();

    const additionalServices: Record<string, any> = {
      parging: {
        title: 'Parging',
        slug: 'parging',
        description:
          'Professional parging services to protect your foundation while giving it a clean, polished look.',
        full_description:
          'At Fix My Brick, our parging services help protect your foundation while giving it a clean, polished look. Customers often mention how "spotless" we leave their properties, and appreciate the renewed, cohesive appearance our work provides.',
        benefits: [
          'Moisture Defence - Properly applied parging helps keep water out, reducing the risk of leaks or long-term damage.',
          'Enhanced Aesthetics - A smooth, consistent finish can dramatically improve the look of your foundation or walls.',
          'Increased Durability - By reinforcing and smoothing over uneven areas, parging extends the life of your exterior surfaces.',
        ],
        process_steps: [
          {
            title: 'Assessment & Planning',
            description:
              'We begin by inspecting your foundation or exterior walls, identifying any cracks or damage that need attention before parging.',
          },
          {
            title: 'Surface Preparation',
            description:
              'Next, we clean and prepare the surface to ensure proper adhesion. Our clients consistently praise how tidy we keep the worksite.',
          },
          {
            title: 'Application & Finishing',
            description:
              'Using high-quality mixes, we apply an even layer of parging that protects and smooths the surface, giving it a refreshed, cohesive look.',
          },
          {
            title: 'Inspection & Cleanup',
            description:
              'We finalise the project with a thorough cleanup and inspection, ensuring the result meets our "spotless" standard and your complete satisfaction.',
          },
        ],
        faq: [
          {
            question: 'How long does parging last?',
            answer:
              'When properly applied and maintained, parging can protect your foundation for many years.',
          },
          {
            question: 'Does parging help prevent moisture issues?',
            answer:
              'Absolutely. Parging acts as a barrier that helps seal out water and minimise potential leaks.',
          },
          {
            question: 'Will I need to fix cracks before parging?',
            answer:
              'Yes. We repair any underlying cracks or damage first to ensure the parging layer adheres properly and delivers the best results.',
          },
        ],
        image_url:
          'https://images.unsplash.com/photo-1604599855950-7cef16ebe87e?q=80&w=1887&auto=format&fit=crop',
        hero_image:
          'https://images.unsplash.com/photo-1578301978069-45264734cddc?q=80&w=2064&auto=format&fit=crop',
        hero_headline: 'Professional Parging for a Smooth, Lasting Finish',
        cta_text:
          "Don't let rough or damaged surfaces undermine your home or business. Contact us today for a free consultation and see how our parging services can give your foundation a lasting, polished finish.",
        is_featured: true,
        id: uuidv4(),
      },
      'chimney-removal': {
        title: 'Chimney Removal',
        slug: 'chimney-removal',
        description:
          'Professional chimney removal and roof repair services for homes across Ontario.',
        full_description:
          "At Fix My Brick, we provide expert chimney removal services that include professional roof repairs and restoration after the chimney is removed. Our team ensures a seamless transition that maintains your home's structural integrity and appearance.",
        benefits: [
          'Complete Solution - We handle every aspect from chimney dismantling to roof restoration.',
          'Structural Integrity - Our skilled team ensures your roof remains strong and protected.',
          'Clean Finish - We leave your property clean and debris-free after the removal process.',
        ],
        process_steps: [
          {
            title: 'Inspection & Planning',
            description:
              'We start with a thorough assessment of your chimney and roof to determine the best removal approach and restoration needs.',
          },
          {
            title: 'Controlled Dismantling',
            description:
              'Using specialized equipment, we carefully dismantle the chimney brick by brick to minimize impact on your home.',
          },
          {
            title: 'Roof Restoration',
            description:
              'Once the chimney is removed, we professionally repair and restore your roof to match the existing structure and materials.',
          },
          {
            title: 'Final Cleanup',
            description:
              'We thoroughly clean the worksite, removing all debris and ensuring your property looks pristine.',
          },
        ],
        faq: [
          {
            question: 'Why would I need to remove my chimney?',
            answer:
              'Common reasons include non-functional chimneys, leaks causing water damage, structural issues, or renovations that no longer include a fireplace.',
          },
          {
            question: 'How long does chimney removal take?',
            answer:
              'Depending on the chimney size and complexity, most projects can be completed in 1-3 days, including roof repairs.',
          },
          {
            question: "Will chimney removal affect my home's value?",
            answer:
              "When done professionally with proper roof restoration, chimney removal typically doesn't negatively impact home value and can sometimes improve it by resolving structural issues.",
          },
        ],
        image_url:
          'https://images.unsplash.com/photo-1592135969260-e0cd6057e3b4?q=80&w=1964&auto=format&fit=crop',
        hero_image:
          'https://images.unsplash.com/photo-1519999482648-25049ddd37b1?q=80&w=2069&auto=format&fit=crop',
        hero_headline: 'Professional Chimney Removal & Roof Restoration',
        cta_text:
          'Is your chimney causing problems or no longer needed? Contact us today for a free consultation on our professional chimney removal and roof restoration services.',
        is_featured: true,
        id: uuidv4(),
      },
    };

    const allServices = { ...fallbackServices, ...additionalServices };

    const { data: existingServiceSlugs, error: fetchError } = await supabase
      .from('services')
      .select('slug');

    if (fetchError) {
      console.error('Error fetching existing services:', fetchError);
      return false;
    }

    const existingSlugs = new Set(
      existingServiceSlugs?.map((s) => s.slug) || []
    );

    for (const slug of Object.keys(allServices)) {
      const service = allServices[slug];

      try {
        if (!existingSlugs.has(slug)) {
          const serviceId =
            typeof service.id === 'string' && service.id.length === 36
              ? service.id
              : uuidv4();

          const { error: insertError } = await supabase
            .from('services')
            .insert({
              id: serviceId,
              title: service.title,
              slug: service.slug,
              description: service.description,
              full_description: service.full_description,
              benefits: service.benefits,
              process_steps: service.process_steps,
              faq: service.faq,
              image_url: service.image_url,
              cta_text: service.cta_text,
              hero_image: service.hero_image,
              hero_headline: service.hero_headline,
              is_featured: service.is_featured,
            });

          if (insertError) {
            console.error(
              `Error inserting service ${service.title}:`,
              insertError
            );
          } else {
            console.log(`Service ${service.title} inserted successfully`);
          }
        } else {
          console.log(`Service ${service.title} already exists`);
        }
      } catch (error) {
        console.error(`Failed to process service ${service.title}:`, error);
      }
    }

    return true;
  } catch (error) {
    console.error('Error in ensureServicesData:', error);
    return false;
  }
}

export async function fetchServices(signal?: AbortSignal) {
  try {
    // Check for early abort before any async operations
    if (signal?.aborted) {
      console.log('Services fetch aborted before starting');
      throw new Error('Request aborted');
    }
    
    await ensureServicesData();

    // Check abort status again after ensureServicesData
    if (signal?.aborted) {
      console.log('Services fetch aborted after ensureServicesData');
      throw new Error('Request aborted');
    }

    const { data, error } = await supabase
      .from('services')
      .select('*')
      .abortSignal(signal);

    if (error) {
      // Check if the error is an abort error
      if (error.message?.includes('abort') || error.message?.includes('cancel')) {
        console.log('Services fetch aborted during supabase query');
        throw new Error('Request aborted');
      }
      console.error('Error fetching services:', error);
      return Object.values(getFallbackServiceContent());
    }

    if (data) {
      const servicesWithStringIds = data.map((service) => ({
        ...service,
        id: String(service.id),
      }));
      servicesWithStringIds.forEach((service) => cacheService(service));
      return servicesWithStringIds;
    }

    return [];
  } catch (error: any) {
    // Handle AbortError more comprehensively
    if (
      signal?.aborted || 
      (error instanceof Error && error.name === 'AbortError') ||
      (error instanceof DOMException && error.name === 'AbortError') ||
      (error.message && error.message.includes('abort'))
    ) {
      console.log('Services fetch aborted', error);
      throw error; // Re-throw the abort error for React Query to handle properly
    }

    console.error('Error in fetchServices:', error);
    return Object.values(getFallbackServiceContent());
  }
}

export async function fetchServiceBySlug(
  slug: string,
  signal?: AbortSignal
): Promise<ServiceDetail | null> {
  if (!slug) {
    console.error('Invalid slug provided to fetchServiceBySlug');
    return null;
  }

  // Check early abort
  if (signal?.aborted) {
    console.log(`Service fetch for ${slug} aborted before starting`);
    throw new Error('Request aborted');
  }

  const requestKey = slug;

  // Handle existing requests with abort signal
  if (pendingRequests[requestKey] && !signal?.aborted) {
    console.log(`Using existing request for ${slug}`);
    try {
      return await pendingRequests[requestKey];
    } catch (error) {
      delete pendingRequests[requestKey];
      
      // If the error is an abort, propagate it
      if (signal?.aborted || 
          (error instanceof Error && (error.name === 'AbortError' || error.message.includes('abort')))) {
        throw error;
      }
    }
  }

  // Use cache for better performance
  const cachedService = getCachedService(slug);
  if (cachedService) {
    console.log(`Using cached data for service: ${slug}`);
    return {
      ...cachedService,
      id: String(cachedService.id),
    };
  }

  const requestPromise = (async () => {
    try {
      // Check abort again before making the API call
      if (signal?.aborted) {
        console.log(`Service fetch for ${slug} aborted before API call`);
        throw new Error('Request aborted');
      }

      const { data, error } = await supabase
        .from('services')
        .select('*')
        .eq('slug', slug)
        .abortSignal(signal)
        .maybeSingle();

      // Check abort after API call
      if (signal?.aborted) {
        console.log(`Service fetch for ${slug} aborted after API call`);
        throw new Error('Request aborted');
      }

      if (!error && data) {
        const serviceWithStringId = {
          ...data,
          id: String(data.id),
        };
        cacheService(serviceWithStringId);
        return serviceWithStringId;
      }

      // Check if error is abort-related
      if (error && (error.message?.includes('abort') || error.message?.includes('cancel'))) {
        console.log(`Service fetch for ${slug} aborted during query execution`);
        throw new Error('Request aborted');
      }

      // Handle other errors
      if (error && !signal?.aborted) {
        console.error(
          `Error fetching service with slug ${slug}:`,
          error.message || error
        );
      }

      // Try fallback data
      const fallbackData = getFallbackServiceContent()[slug];
      if (fallbackData) {
        console.log(`Using fallback data for service: ${slug}`);
        return {
          ...fallbackData,
          id: String(fallbackData.id),
        };
      }

      if (!signal?.aborted) {
        console.error(`Service with slug ${slug} not found`);
      }

      return null;
    } catch (error: any) {
      // Comprehensive abort error checking
      if (
        signal?.aborted || 
        (error instanceof Error && error.name === 'AbortError') ||
        (error instanceof DOMException && error.name === 'AbortError') ||
        (error.message && (error.message.includes('abort') || error.message.includes('cancel')))
      ) {
        console.log(`Service fetch for ${slug} aborted:`, error.message || error);
        throw new Error('Request aborted');
      }

      console.error(
        `Error in fetchServiceBySlug for ${slug}:`,
        error instanceof Error ? error.message : error
      );

      // Try fallback data on error
      const fallbackData = getFallbackServiceContent()[slug];
      if (fallbackData) {
        return {
          ...fallbackData,
          id: String(fallbackData.id),
        };
      }
      return null;
    } finally {
      delete pendingRequests[requestKey];
    }
  })();

  pendingRequests[requestKey] = requestPromise;
  return requestPromise;
}
