import React, { useEffect, useRef, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import ReviewCard from '@/components/ui/review-card';
import { Review } from '@/lib/supabase/reviews';

interface ReviewCarouselProps {
  reviews: Review[];
  className?: string;
}

const ReviewCarousel: React.FC<ReviewCarouselProps> = ({
  reviews,
  className = '',
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const nextSlide = () => {
    setCurrentIndex((prev) => (prev === reviews.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentIndex((prev) => (prev === 0 ? reviews.length - 1 : prev - 1));
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX);
    setIsGrabbing(true);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    setIsGrabbing(false);

    if (touchStart - touchEnd > 75) {
      // Swipe left
      nextSlide();
    } else if (touchStart - touchEnd < -75) {
      // Swipe right
      prevSlide();
    }
  };

  // Auto-scroll every 4 seconds
  useEffect(() => {
    if (reviews.length <= 1) return;

    const interval = setInterval(() => {
      nextSlide();
    }, 4000);

    return () => clearInterval(interval);
  }, [reviews.length]);

  if (!reviews || reviews.length === 0) {
    return (
      <div className={`bg-white p-6 rounded-lg text-center ${className}`}>
        <p className='text-gray-500'>No reviews available</p>
      </div>
    );
  }

  return (
    <div
      className={`${className} relative overflow-hidden w-full`}
      style={{ maxWidth: '100%', width: '100%' }}>
      <div
        ref={containerRef}
        className={`flex transition-transform duration-500 ease-out ${
          isGrabbing ? 'cursor-grabbing' : 'cursor-grab'
        }`}
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
          width: '100%',
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}>
        {reviews.map((review, index) => (
          <div
            key={review.id || index}
            className='w-full flex-shrink-0 flex-grow-0 px-0'
            style={{ minWidth: '100%', maxWidth: '100%' }}>
            <ReviewCard
              review={review}
              showLocation={true}
              className='max-w-full'
            />
          </div>
        ))}
      </div>

      {reviews.length > 1 && (
        <>
          <button
            onClick={prevSlide}
            className='absolute left-0 top-1/2 transform -translate-y-1/2 bg-white/80 hover:bg-white rounded-full p-1 shadow-md z-10'
            aria-label='Previous review'>
            <ChevronLeft className='h-6 w-6 text-D00B0E' />
          </button>

          <button
            onClick={nextSlide}
            className='absolute right-0 top-1/2 transform -translate-y-1/2 bg-white/80 hover:bg-white rounded-full p-1 shadow-md z-10'
            aria-label='Next review'>
            <ChevronRight className='h-6 w-6 text-D00B0E' />
          </button>

          <div className='absolute bottom-2 left-0 right-0 flex justify-center gap-1.5'>
            {reviews.map((_, index) => (
              <button
                key={index}
                className={`h-2 rounded-full transition-all ${
                  index === currentIndex ? 'w-6 bg-D00B0E' : 'w-2 bg-gray-300'
                }`}
                onClick={() => setCurrentIndex(index)}
                aria-label={`Go to review ${index + 1}`}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ReviewCarousel;
