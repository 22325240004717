
import React from 'react';
import { GalleryFormProps } from './form/types';
import BasicInfoFields from './form/BasicInfoFields';
import FranchiseFields from './form/FranchiseFields';
import ImageUploader from './image-uploader';
import DescriptionField from './form/DescriptionField';
import CreatedDateInfo from './form/CreatedDateInfo';
import VisibilityField from './form/VisibilityField';
import FormActions from './form/FormActions';
import ReviewStatusField from './form/ReviewStatusField';
import ServiceSelectionField from './form/ServiceSelectionField';

const GalleryForm = ({
  gallery,
  saving,
  validatingSlug = false,
  handleChange,
  handleSelectChange,
  handleCheckboxChange,
  handleImageUpload,
  generateSlug,
  validateSlug,
  handleSubmit,
  onCancel,
  onViewFranchiseImages,
  handleReviewStatusChange,
  handleServiceChange,
  availableServices = [],
  loadingServices = false
}: GalleryFormProps) => {
  const handleSlugChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    if (validateSlug) {
      // Debounce validation to avoid too many requests
      const timeoutId = setTimeout(() => {
        validateSlug(e.target.value);
      }, 500);
      
      return () => clearTimeout(timeoutId);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <BasicInfoFields
        title={gallery.title}
        slug={gallery.slug}
        handleChange={handleChange}
        handleSlugChange={handleSlugChange}
        generateSlug={generateSlug}
        validatingSlug={validatingSlug}
      />

      {handleSelectChange && (
        <FranchiseFields
          franchiseRegion={gallery.franchise_region}
          jobNumber={gallery.job_number}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
        />
      )}
      
      {handleServiceChange && (
        <ServiceSelectionField
          serviceId={gallery.service_id}
          handleServiceChange={handleServiceChange}
          availableServices={availableServices}
          loading={loadingServices}
        />
      )}
      
      <ImageUploader 
        currentImageUrl={gallery.cover_image}
        onImageUploaded={handleImageUpload}
      />

      <DescriptionField 
        description={gallery.description}
        handleChange={handleChange}
      />

      <CreatedDateInfo createdOn={gallery.created_on} />

      <VisibilityField 
        isPublic={gallery.is_public}
        handleCheckboxChange={handleCheckboxChange}
      />
      
      {handleReviewStatusChange && (
        <ReviewStatusField
          reviewStatus={gallery.review_status}
          handleReviewStatusChange={handleReviewStatusChange}
        />
      )}

      <FormActions
        saving={saving}
        validatingSlug={validatingSlug}
        onCancel={onCancel}
      />
    </form>
  );
};

export default GalleryForm;
