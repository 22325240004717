
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AdminLayout from '@/components/admin/AdminLayout';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import GalleryForm from '@/components/admin/galleries/GalleryForm';
import GalleryFormLoading from '@/components/admin/galleries/GalleryFormLoading';
import FranchiseImagesView from '@/components/admin/galleries/FranchiseImagesView';
import { useGalleryForm } from '@/hooks/gallery'; 
import { toast } from 'sonner';
import { supabase } from '@/lib/supabase';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from "@/components/ui/breadcrumb";

const AdminGalleryEdit = () => {
  const { id } = useParams<{ id: string }>();
  const [franchiseImagesOpen, setFranchiseImagesOpen] = useState(false);
  
  const {
    gallery,
    setGallery,
    loading,
    saving,
    validatingSlug,
    isEditing,
    handleChange,
    handleSelectChange,
    handleCheckboxChange,
    handleImageUpload,
    handleReviewStatusChange,
    handleServiceChange,
    generateSlug,
    validateSlug,
    handleSubmit,
    navigateBack,
    addPhotoToGallery,
    availableServices,
    loadingServices
  } = useGalleryForm(id);

  // Auto-show franchise images when both fields are filled
  useEffect(() => {
    if (gallery.franchise_region && gallery.job_number) {
      setFranchiseImagesOpen(true);
    }
  }, [gallery.franchise_region, gallery.job_number]);

  const handleAddFranchiseImage = async (imageUrl: string) => {
    await addPhotoToGallery(imageUrl);
  };

  const handleSetCoverImage = async (imageUrl: string) => {
    if (!id) return;
    
    try {
      const { error } = await supabase
        .from('galleries')
        .update({ cover_image: imageUrl })
        .eq('id', id);
        
      if (error) throw error;
      
      // Update local state
      handleImageUpload(imageUrl);
      
      toast.success('Cover image updated successfully');
    } catch (error) {
      console.error('Error setting cover image:', error);
      toast.error('Failed to set cover image');
    }
  };

  return (
    <AdminLayout title={isEditing ? "Edit Gallery" : "New Gallery"}>
      <div className="mb-6">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/admin/dashboard">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href="/admin/galleries">Galleries</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink>{isEditing ? "Edit Gallery" : "New Gallery"}</BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      
      {loading ? (
        <GalleryFormLoading />
      ) : (
        <Card>
          <CardHeader>
            <CardTitle>{isEditing ? "Edit Gallery" : "Create New Gallery"}</CardTitle>
          </CardHeader>
          <CardContent>
            <GalleryForm
              gallery={gallery}
              saving={saving}
              validatingSlug={validatingSlug}
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              handleCheckboxChange={handleCheckboxChange}
              handleImageUpload={handleImageUpload}
              handleReviewStatusChange={isEditing ? handleReviewStatusChange : undefined}
              handleServiceChange={handleServiceChange}
              generateSlug={generateSlug}
              validateSlug={validateSlug}
              handleSubmit={handleSubmit}
              onCancel={navigateBack}
              onViewFranchiseImages={() => setFranchiseImagesOpen(true)}
              availableServices={availableServices}
              loadingServices={loadingServices}
            />
            
            {gallery.franchise_region && gallery.job_number && (
              <FranchiseImagesView
                open={franchiseImagesOpen}
                onClose={() => setFranchiseImagesOpen(false)}
                franchiseRegion={gallery.franchise_region}
                jobNumber={gallery.job_number}
                onAddToGallery={handleAddFranchiseImage}
                galleryId={id}
                coverImage={gallery.cover_image}
                onSetCoverImage={handleSetCoverImage}
              />
            )}
          </CardContent>
        </Card>
      )}
    </AdminLayout>
  );
};

export default AdminGalleryEdit;
