
import { toast } from 'sonner';
import { HeroBackgroundCreate } from '@/types/heroBackground';
import { useHeroBackgroundsMutations } from './useHeroBackgroundsMutations';
import { useBackgroundUpload } from './useBackgroundUpload';
import { UseHeroBackgroundsActions } from './types';

export const useHeroBackgroundsActions = (
  onAddSuccess: () => void,
  onUpdateSuccess: () => void
): UseHeroBackgroundsActions => {
  const { createMutation, updateMutation, deleteMutation } = useHeroBackgroundsMutations(
    onAddSuccess,
    onUpdateSuccess
  );
  
  const { 
    uploadingBackground, 
    setUploadingBackground, 
    handleBackgroundUpload 
  } = useBackgroundUpload();

  const addBackground = async (
    name: string,
    file: File | null,
    url: string = ''
  ) => {
    try {
      let imageUrl = url;

      // If a file was provided, upload it first
      if (file) {
        const uploadedUrl = await handleBackgroundUpload(file);
        if (!uploadedUrl) return;
        imageUrl = uploadedUrl;
      }

      if (!imageUrl) {
        toast.error('Please provide either a file or a URL for the background');
        return;
      }

      // Create the background
      await createMutation.mutateAsync({
        name,
        url: imageUrl,
        is_preset: false,
      });
    } catch (error) {
      console.error('Error adding background:', error);
      toast.error('Failed to add background');
    }
  };

  const updateBackground = async (
    id: string,
    name: string,
    file: File | null,
    url?: string
  ) => {
    try {
      const updates: Partial<HeroBackgroundCreate> = { name };

      // If a file was provided, upload it first
      if (file) {
        const uploadedUrl = await handleBackgroundUpload(file);
        if (!uploadedUrl) return;
        updates.url = uploadedUrl;
      } else if (url) {
        // If a URL was provided directly (no file)
        updates.url = url;
      }

      // Update the background
      await updateMutation.mutateAsync({ id, updates });
    } catch (error) {
      console.error('Error updating background:', error);
      toast.error('Failed to update background');
    }
  };

  const deleteBackground = async (id: string) => {
    try {
      await deleteMutation.mutateAsync(id);
    } catch (error) {
      console.error('Error deleting background:', error);
      toast.error('Failed to delete background');
    }
  };

  return {
    setUploadingBackground,
    handleBackgroundUpload,
    addBackground,
    updateBackground,
    deleteBackground,
    setShowAddDialog: () => {}, 
    setShowEditDialog: () => {},
    setSelectedBackground: () => {},
  };
};
