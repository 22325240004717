
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Save } from 'lucide-react';

interface FormActionsProps {
  saving: boolean;
  handleSave: () => Promise<void>;
}

const FormActions: React.FC<FormActionsProps> = ({ saving, handleSave }) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-end mt-6">
      <Button
        variant="outline" 
        className="mr-2"
        onClick={() => navigate('/admin/locations')}
      >
        Cancel
      </Button>
      <Button 
        onClick={handleSave}
        disabled={saving}
      >
        {saving ? 'Saving...' : 'Save Location'} 
        {!saving && <Save className="ml-2 h-4 w-4" />}
      </Button>
    </div>
  );
};

export default FormActions;
