import React from 'react';
import { Asset } from '@/hooks/useAssetManager';
import { Card, CardFooter } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { FileIcon, ImageIcon, Upload, FolderPlus } from 'lucide-react';
import { formatBytes } from '@/utils/formatters';
import { Button } from '@/components/ui/button';

interface AssetGridProps {
  assets: Asset[];
  selectedAssets: string[];
  onSelect: (id: string, isSelected: boolean) => void;
  onAssetClick: (asset: Asset) => void;
  onUploadClick?: () => void;
  onCreateFolderClick?: () => void;
}

export const AssetGrid: React.FC<AssetGridProps> = ({
  assets,
  selectedAssets,
  onSelect,
  onAssetClick,
  onUploadClick,
  onCreateFolderClick,
}) => {
  const isImage = (mimeType: string) => mimeType.startsWith('image/');

  return (
    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4'>
      {assets.map((asset) => {
        const isSelected = selectedAssets.includes(asset.id);

        return (
          <Card
            key={asset.id}
            className={`overflow-hidden cursor-pointer hover:shadow-md transition-shadow relative ${
              isSelected ? 'ring-2 ring-primary ring-offset-2' : ''
            }`}
            onClick={() => onAssetClick(asset)}>
            <div className='absolute top-2 left-2 z-10'>
              <Checkbox
                checked={isSelected}
                onCheckedChange={(checked) => {
                  onSelect(asset.id, checked as boolean);
                }}
                onClick={(e) => e.stopPropagation()}
              />
            </div>

            <div className='h-40 bg-muted flex items-center justify-center'>
              {isImage(asset.mime_type) ? (
                <img
                  src={asset.file_path}
                  alt={asset.seo?.alt_text || asset.original_filename}
                  className='w-full h-full object-cover'
                />
              ) : (
                <div className='text-center p-4'>
                  <FileIcon size={48} className='mx-auto mb-2' />
                  <p className='text-xs truncate w-full'>
                    {asset.original_filename}
                  </p>
                </div>
              )}
            </div>

            <CardFooter className='p-2 text-xs flex justify-between'>
              <div className='truncate max-w-[70%]'>
                {asset.original_filename}
              </div>
              <div className='text-muted-foreground'>
                {formatBytes(asset.file_size)}
              </div>
            </CardFooter>
          </Card>
        );
      })}

      {assets.length === 0 && (
        <div className='col-span-full py-16 text-center'>
          <div className='bg-muted/30 rounded-full w-20 h-20 flex items-center justify-center mx-auto mb-4'>
            <ImageIcon size={36} className='text-muted-foreground' />
          </div>
          <h3 className='text-lg font-medium mb-2'>No assets found</h3>
          <p className='text-muted-foreground mb-6 max-w-md mx-auto'>
            Upload images, documents, and other files to organize and manage
            your content.
          </p>
          <div className='flex gap-3 justify-center'>
            {onUploadClick && (
              <Button 
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onUploadClick();
                }} 
                className='gap-2'
              >
                <Upload size={16} />
                Upload Assets
              </Button>
            )}
            {onCreateFolderClick && (
              <Button
                variant='outline'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onCreateFolderClick();
                }}
                className='gap-2'>
                <FolderPlus size={16} />
                Create Folder
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
