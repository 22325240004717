import React from 'react';
import { Button } from '@/components/ui/button';
import { ArrowRight, Phone } from 'lucide-react';
import { Link } from 'react-router-dom';
import { PhoneButton } from '@/components/ui/phone-button';

const CallToActionSection = () => {
  return (
    <section className='py-16 bg-gray-50 border-t border-b border-gray-200'>
      <div className='container mx-auto px-4'>
        <div className='max-w-3xl mx-auto text-center'>
          <h2 className='text-3xl md:text-4xl font-bold mb-4'>
            Ready to Restore Your Brickwork?
          </h2>
          <p className='text-lg text-gray-600 mb-8'>
            We're here to help, whether you need a quick fix or a full
            restoration. Get in touch for a free estimate and experience the
            difference that genuine, customer-centred service makes.
          </p>

          <div className='flex flex-col sm:flex-row gap-4 justify-center'>
            <Button
              size='lg'
              className='bg-[#D00B0E] hover:bg-[#D00B0E]/90 text-white px-8 h-14 font-medium text-lg'
              asChild>
              <Link to='/contact'>
                Get a Free Estimate <ArrowRight className='ml-2 h-5 w-5' />
              </Link>
            </Button>

            <PhoneButton
              phoneNumber='9058070404'
              displayNumber='(905) 807-0404'
              variant='secondary'
              size='lg'
              className='h-14 font-medium text-lg'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToActionSection;
