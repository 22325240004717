
import React, { useState } from 'react';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Search, Star, Save, Filter, Check } from 'lucide-react';
import { useReviewsForService } from '@/hooks/useReviewsForService';
import { ServiceDetail } from '@/types/service';
import { Spinner } from '@/components/ui/spinner';
import { Badge } from '@/components/ui/badge';
import { 
  Select, 
  SelectContent, 
  SelectItem, 
  SelectTrigger, 
  SelectValue 
} from '@/components/ui/select';
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';

interface ReviewsTabProps {
  formData: ServiceDetail;
  saving: boolean;
}

const ReviewsTab: React.FC<ReviewsTabProps> = ({ formData, saving: formSaving }) => {
  const serviceId = formData.id;
  
  const {
    loading,
    filteredReviews,
    selectedReviews,
    searchTerm,
    setSearchTerm,
    toggleReviewSelection,
    isReviewSelected,
    saveReviewSelections,
    saving: savingReviews
  } = useReviewsForService(serviceId);

  // New state for star rating filter
  const [ratingFilter, setRatingFilter] = useState<string>('all');
  
  // Filter reviews by star rating
  const ratingFilteredReviews = ratingFilter === 'all' 
    ? filteredReviews 
    : filteredReviews.filter(review => {
        const rating = review.star_rating;
        if (ratingFilter === '5') return rating === 'FIVE';
        if (ratingFilter === '4') return rating === 'FOUR';
        if (ratingFilter === '3') return rating === 'THREE';
        if (ratingFilter === '2') return rating === 'TWO';
        if (ratingFilter === '1') return rating === 'ONE';
        return true;
      });

  // Format date as YYYY-MM-DD
  const formatDate = (date: string | null | undefined) => {
    if (!date) return 'N/A';
    return new Date(date).toLocaleDateString('en-CA');
  };

  // Helper to convert string rating to number
  const starRatingToNumber = (rating: string): number => {
    switch (rating) {
      case 'FIVE': return 5;
      case 'FOUR': return 4;
      case 'THREE': return 3;
      case 'TWO': return 2;
      case 'ONE': return 1;
      default: return 5;
    }
  };

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium">Service Reviews</h3>
            <div className="flex items-center gap-3">
              <Badge variant="outline" className="bg-[#D00B0E] text-white font-normal">
                {selectedReviews.length} selected
              </Badge>
              <Button 
                variant="outline" 
                onClick={saveReviewSelections}
                disabled={formSaving || savingReviews || loading}
                className="flex items-center gap-2"
              >
                {savingReviews ? <Spinner className="h-4 w-4" /> : <Save className="h-4 w-4" />}
                Save Review Selections
              </Button>
            </div>
          </div>
          
          <div className="flex flex-col gap-2">
            <div className="text-sm text-muted-foreground">
              Select reviews to display on this service page. Changes are only saved when you click "Save Review Selections".
            </div>
            <div className="text-sm bg-amber-50 text-amber-800 p-2 rounded border border-amber-200">
              <strong>Important:</strong> Review selections are managed separately from other service information. 
              You must click the "Save Review Selections" button above to save your review choices.
              Clicking the main "Save" button elsewhere will not save review changes.
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-4">
            <div className="relative flex-grow">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
              <Input
                className="pl-10"
                placeholder="Search reviews by name or content..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                disabled={loading}
              />
            </div>
            
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" className="flex items-center gap-2">
                  <Filter className="h-4 w-4" />
                  Filter
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-48">
                <DropdownMenuGroup>
                  <DropdownMenuItem
                    className={ratingFilter === 'all' ? 'bg-slate-100' : ''}
                    onClick={() => setRatingFilter('all')}
                  >
                    <div className="flex items-center justify-between w-full">
                      <span>All Ratings</span>
                      {ratingFilter === 'all' && <Check className="h-4 w-4 ml-2" />}
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className={ratingFilter === '5' ? 'bg-slate-100' : ''}
                    onClick={() => setRatingFilter('5')}
                  >
                    <div className="flex items-center justify-between w-full">
                      <span className="flex items-center">
                        5 <Star className="h-3 w-3 text-yellow-400 fill-yellow-400 ml-1" />
                      </span>
                      {ratingFilter === '5' && <Check className="h-4 w-4 ml-2" />}
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className={ratingFilter === '4' ? 'bg-slate-100' : ''}
                    onClick={() => setRatingFilter('4')}
                  >
                    <div className="flex items-center justify-between w-full">
                      <span className="flex items-center">
                        4 <Star className="h-3 w-3 text-yellow-400 fill-yellow-400 ml-1" />
                      </span>
                      {ratingFilter === '4' && <Check className="h-4 w-4 ml-2" />}
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className={ratingFilter === '3' ? 'bg-slate-100' : ''}
                    onClick={() => setRatingFilter('3')}
                  >
                    <div className="flex items-center justify-between w-full">
                      <span className="flex items-center">
                        3 <Star className="h-3 w-3 text-yellow-400 fill-yellow-400 ml-1" />
                      </span>
                      {ratingFilter === '3' && <Check className="h-4 w-4 ml-2" />}
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className={ratingFilter === '2' ? 'bg-slate-100' : ''}
                    onClick={() => setRatingFilter('2')}
                  >
                    <div className="flex items-center justify-between w-full">
                      <span className="flex items-center">
                        2 <Star className="h-3 w-3 text-yellow-400 fill-yellow-400 ml-1" />
                      </span>
                      {ratingFilter === '2' && <Check className="h-4 w-4 ml-2" />}
                    </div>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className={ratingFilter === '1' ? 'bg-slate-100' : ''}
                    onClick={() => setRatingFilter('1')}
                  >
                    <div className="flex items-center justify-between w-full">
                      <span className="flex items-center">
                        1 <Star className="h-3 w-3 text-yellow-400 fill-yellow-400 ml-1" />
                      </span>
                      {ratingFilter === '1' && <Check className="h-4 w-4 ml-2" />}
                    </div>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>

          {loading ? (
            <div className="p-8 flex items-center justify-center">
              <Spinner className="h-8 w-8 text-[#D00B0E]" />
            </div>
          ) : ratingFilteredReviews.length === 0 ? (
            <div className="text-center py-8 text-muted-foreground">
              {searchTerm || ratingFilter !== 'all' ? 'No reviews match your search or filter' : 'No reviews available'}
            </div>
          ) : (
            <div className="space-y-4 max-h-[600px] overflow-y-auto pr-2">
              {ratingFilteredReviews.map((review) => (
                <div 
                  key={review.id} 
                  className={`border rounded-md p-4 transition-all cursor-pointer ${
                    isReviewSelected(review.id) 
                      ? 'border-[#D00B0E] bg-red-50' 
                      : 'hover:border-gray-400'
                  }`}
                  onClick={() => toggleReviewSelection(review)}
                >
                  <div className="flex-1">
                    <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-2">
                      <div className="flex items-center gap-2">
                        <h4 className="font-medium">{review.reviewer_name}</h4>
                        {review.google_name && (
                          <Badge variant="outline" className="text-xs">Google</Badge>
                        )}
                      </div>
                      
                      <div className="flex items-center">
                        <div className="flex">
                          {[...Array(5)].map((_, i) => (
                            <Star 
                              key={i} 
                              className={`h-4 w-4 ${
                                i < starRatingToNumber(review.star_rating) 
                                  ? "text-yellow-400 fill-yellow-400" 
                                  : "text-gray-300"
                              }`} 
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                    
                    {review.reviewer_comment && (
                      <p className="mt-2 text-sm text-gray-700 line-clamp-3">{review.reviewer_comment}</p>
                    )}
                    
                    <div className="mt-2 text-xs text-gray-500 flex justify-between items-center">
                      <span>{formatDate(review.create_time?.toString())}</span>
                      
                      {isReviewSelected(review.id) && (
                        <span className="text-[#D00B0E] flex items-center">
                          <Check className="h-4 w-4 mr-1" /> Selected
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ReviewsTab;
