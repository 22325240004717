import React from 'react';
import { Location } from '@/types/location';
import { useQuery } from '@tanstack/react-query';
import { fetchLocationReviews } from '@/lib/supabase/reviews';
import { fetchGMBLocations } from '@/lib/supabase';
import { Button } from '@/components/ui/button';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { formatDate } from '@/lib/utils';
import ReviewCard from '@/components/ui/review-card';

interface LocationReviewsProps {
  location: Location;
}

const LocationReviews: React.FC<LocationReviewsProps> = ({ location }) => {
  // Use the google_place_id directly from the location if available
  const googlePlaceId = location?.google_place_id || null;

  // Fetch reviews for this location using the google_place_id
  const { data: reviews, isLoading: isLoadingReviews } = useQuery({
    queryKey: ['location-reviews', googlePlaceId],
    queryFn: () => googlePlaceId ? fetchLocationReviews(googlePlaceId) : Promise.resolve([]),
    enabled: !!googlePlaceId,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  // Use fallback reviews if no real reviews are available
  const fallbackReviews = [
    {
      id: '1',
      reviewer_name: "Michael T.",
      star_rating: "FIVE",
      reviewer_comment: "Excellent work repairing the brick on my century home. The team was professional, on-time, and left the property clean. Highly recommend!",
      create_time: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(), // 1 month ago
      location_name: location.name,
    },
    {
      id: '2',
      reviewer_name: "Sarah L.",
      star_rating: "FIVE",
      reviewer_comment: "Fix My Brick did a fantastic job with our chimney repair. The estimate was fair, and the quality of work was outstanding. Will definitely use again.",
      create_time: new Date(Date.now() - 60 * 24 * 60 * 60 * 1000).toISOString(), // 2 months ago
      location_name: location.name,
    },
    {
      id: '3',
      reviewer_name: "Robert K.",
      star_rating: "FOUR",
      reviewer_comment: "Very pleased with the stone masonry work on our front porch. The craftsmanship shows and they were respectful of our property throughout the project.",
      create_time: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString(), // 3 months ago
      location_name: location.name,
    }
  ];

  const isLoading = isLoadingReviews;
  const displayReviews = reviews?.length ? reviews.slice(0, 3) : fallbackReviews;

  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Customer Reviews in {location.name}</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Don't just take our word for it. Here's what our customers in {location.name} have to say about our masonry services.
          </p>
        </div>
        
        {isLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[1, 2, 3].map((i) => (
              <div key={i} className="bg-white rounded-lg shadow animate-pulse overflow-hidden">
                <div className="p-4 border-b border-gray-100">
                  <div className="flex items-center space-x-3">
                    <div className="rounded-full bg-gray-200 h-10 w-10"></div>
                    <div className="space-y-2">
                      <div className="h-4 bg-gray-200 rounded w-24"></div>
                      <div className="flex space-x-1">
                        {[...Array(5)].map((_, i) => (
                          <div key={i} className="h-3 w-3 bg-gray-200 rounded-full"></div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-4">
                  <div className="h-4 bg-gray-200 rounded w-full mb-2"></div>
                  <div className="h-4 bg-gray-200 rounded w-full mb-2"></div>
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {displayReviews.map((review) => (
              <ReviewCard 
                key={review.id} 
                review={review} 
                showLocation={true}
                locationName={location.name}
              />
            ))}
          </div>
        )}
        
        <div className="text-center mt-12">
          <Button 
            className="bg-[#1a73e8] hover:bg-[#1765cc] text-white px-6 py-3 rounded-md font-medium transition-colors"
            asChild
          >
            <a 
              href="/reviews"
              rel="noopener noreferrer"
            >
              See More Reviews
            </a>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default LocationReviews;
