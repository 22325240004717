
import React from 'react';
import { Asset } from '@/hooks/useAssetManager';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Checkbox } from '@/components/ui/checkbox';
import { 
  ImageIcon, 
  FileIcon, 
  FileTextIcon, 
  FileArchiveIcon,
  ClockIcon 
} from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { formatBytes } from '@/utils/formatters';
import { format } from 'date-fns';

interface AssetListProps {
  assets: Asset[];
  selectedAssets: string[];
  onSelect: (id: string, isSelected: boolean) => void;
  onAssetClick: (asset: Asset) => void;
}

export const AssetList: React.FC<AssetListProps> = ({ 
  assets, 
  selectedAssets,
  onSelect,
  onAssetClick 
}) => {
  const getFileIcon = (mimeType: string) => {
    if (mimeType.startsWith('image/')) {
      return <ImageIcon size={16} />;
    } else if (mimeType.includes('pdf')) {
      return <FileTextIcon size={16} />;
    } else if (mimeType.includes('zip') || mimeType.includes('compress')) {
      return <FileArchiveIcon size={16} />;
    } else {
      return <FileIcon size={16} />;
    }
  };

  // Format file type for display
  const formatFileType = (mimeType: string) => {
    if (mimeType.startsWith('image/')) {
      return mimeType.split('/')[1].toUpperCase();
    }
    if (mimeType.includes('pdf')) return 'PDF';
    if (mimeType.includes('zip')) return 'ZIP';
    if (mimeType.includes('doc')) return 'DOC';
    return mimeType.split('/')[1]?.toUpperCase() || 'FILE';
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-12">
            <Checkbox
              checked={assets.length > 0 && selectedAssets.length === assets.length}
              onCheckedChange={(checked) => {
                if (checked) {
                  // Select all
                  const allIds = assets.map(asset => asset.id);
                  onSelect('all', true);
                } else {
                  // Deselect all
                  onSelect('all', false);
                }
              }}
            />
          </TableHead>
          <TableHead>Filename</TableHead>
          <TableHead>Type</TableHead>
          <TableHead>Size</TableHead>
          <TableHead>Date Added</TableHead>
          <TableHead>Description</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {assets.map(asset => {
          const isSelected = selectedAssets.includes(asset.id);
          
          return (
            <TableRow 
              key={asset.id}
              className={`cursor-pointer hover:bg-muted/50 ${isSelected ? 'bg-primary/10' : ''}`}
              onClick={() => onAssetClick(asset)}
            >
              <TableCell>
                <Checkbox
                  checked={isSelected}
                  onCheckedChange={(checked) => {
                    onSelect(asset.id, checked as boolean);
                  }}
                  onClick={(e) => e.stopPropagation()}
                />
              </TableCell>
              <TableCell className="font-medium flex items-center gap-2">
                {getFileIcon(asset.mime_type)}
                <span className="truncate max-w-[200px]">{asset.original_filename}</span>
              </TableCell>
              <TableCell>
                <Badge variant="outline">
                  {formatFileType(asset.mime_type)}
                </Badge>
              </TableCell>
              <TableCell>{formatBytes(asset.file_size)}</TableCell>
              <TableCell className="text-muted-foreground">
                <div className="flex items-center gap-1">
                  <ClockIcon size={14} />
                  {format(new Date(asset.created_at), 'MMM d, yyyy')}
                </div>
              </TableCell>
              <TableCell className="text-muted-foreground truncate max-w-[200px]">
                {asset.seo?.description || '-'}
              </TableCell>
            </TableRow>
          );
        })}
        
        {assets.length === 0 && (
          <TableRow>
            <TableCell colSpan={6} className="h-32 text-center">
              <div className="flex flex-col items-center justify-center text-muted-foreground">
                <ImageIcon size={32} className="mb-2" />
                <p>No assets found</p>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
