
import { Location } from '@/types/location';

// Sample location data that matches the Location type
const SampleLocations: Location[] = [
  {
    id: '1',
    name: 'Toronto',
    slug: 'toronto',
    is_primary: true,
    status: 'published',
    address: '123 Main St',
    city: 'Toronto',
    province: 'ON',
    postal_code: 'M5V 2N4',
    country: 'Canada',
  },
  {
    id: '2',
    name: 'Mississauga',
    slug: 'mississauga',
    is_primary: false,
    status: 'published',
    address: '456 Queen St',
    city: 'Mississauga',
    province: 'ON',
    postal_code: 'L5B 3C2',
    country: 'Canada',
  },
  {
    id: '3',
    name: 'Scarborough',
    slug: 'scarborough',
    is_primary: false,
    status: 'draft',
    address: '789 King St',
    city: 'Scarborough',
    province: 'ON',
    postal_code: 'M1P 2L7',
    country: 'Canada',
  },
  {
    id: '4',
    name: 'Etobicoke',
    slug: 'etobicoke',
    is_primary: false,
    status: 'published',
    address: '101 Bloor St',
    city: 'Etobicoke',
    province: 'ON',
    postal_code: 'M9C 1C2',
    country: 'Canada',
  },
  {
    id: '5',
    name: 'North York',
    slug: 'north-york',
    is_primary: false,
    status: 'draft',
    address: '202 Yonge St',
    city: 'North York',
    province: 'ON',
    postal_code: 'M2N 5P7',
    country: 'Canada',
  },
  {
    id: '6',
    name: 'Markham',
    slug: 'markham',
    is_primary: false,
    status: 'published',
    address: '303 Bay St',
    city: 'Markham',
    province: 'ON',
    postal_code: 'L3R 2Z1',
    country: 'Canada',
  },
  {
    id: '7',
    name: 'Richmond Hill',
    slug: 'richmond-hill',
    is_primary: false,
    status: 'published',
    address: '404 Dundas St',
    city: 'Richmond Hill',
    province: 'ON',
    postal_code: 'L4B 3Z9',
    country: 'Canada',
  },
  {
    id: '8',
    name: 'Vaughan',
    slug: 'vaughan',
    is_primary: false,
    status: 'archived',
    address: '505 Eglinton Ave',
    city: 'Vaughan',
    province: 'ON',
    postal_code: 'L4L 4Y8',
    country: 'Canada',
  },
  {
    id: '9',
    name: 'Brampton',
    slug: 'brampton',
    is_primary: false,
    status: 'published',
    address: '606 College St',
    city: 'Brampton',
    province: 'ON',
    postal_code: 'L6Y 4R9',
    country: 'Canada',
  },
  {
    id: '10',
    name: 'Oakville',
    slug: 'oakville',
    is_primary: false,
    status: 'published',
    address: '707 Spadina Ave',
    city: 'Oakville',
    province: 'ON',
    postal_code: 'L6H 2L1',
    country: 'Canada',
  },
  {
    id: '11',
    name: 'Burlington',
    slug: 'burlington',
    is_primary: false,
    status: 'draft',
    address: '808 St Clair Ave',
    city: 'Burlington',
    province: 'ON',
    postal_code: 'L7R 2M6',
    country: 'Canada',
  },
  {
    id: '12',
    name: 'Hamilton',
    slug: 'hamilton',
    is_primary: false,
    status: 'published',
    address: '909 Danforth Ave',
    city: 'Hamilton',
    province: 'ON',
    postal_code: 'L8N 2Z7',
    country: 'Canada',
  },
];

export default SampleLocations;
