
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X, Phone, Hammer, MessageSquare } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useSettings } from '@/hooks/useSettings';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';
import LazyImage from '@/components/ui/lazy-image';
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    settings,
    loading
  } = useSettings();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return <header className="sticky top-0 z-50">
      {/* Family Owned Banner */}
      <div className="bg-brick text-white text-center text-sm md:text-base font-medium py-[13px]">
        Family Owned And Operated Since 2002
      </div>
      
      {/* Phone Number Bar */}
      <div className="bg-stone-100 border-b border-gray-200 my-0 py-[6px]">
        <div className="container mx-auto px-4">
          <div className="flex justify-end gap-4 py-1">
            <a href={`tel:${settings.phone_number?.replace(/\D/g, '')}`} className="flex items-center text-gray-700 hover:text-brick transition-colors text-sm">
              <Phone size={16} className="mr-2 flex-shrink-0" />
              <span className="font-medium">{settings.phone_number}</span>
            </a>
            <a href={`sms:${settings.phone_number?.replace(/\D/g, '')}`} className="flex items-center text-gray-700 hover:text-brick transition-colors text-sm">
              <MessageSquare size={16} className="mr-2 flex-shrink-0" />
              <span className="font-medium">Send us a text</span>
            </a>
          </div>
        </div>
      </div>
      
      <div className="bg-white shadow-sm border-b border-gray-100">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between py-3 md:py-4 lg:py-0 lg:h-24">
            {/* Logo */}
            <Link to="/" className="flex items-center flex-shrink-0 max-w-[180px] sm:max-w-[200px] md:max-w-[220px] lg:max-w-[260px] transition-all duration-300">
              <img src="/images/fmb-logo-2023-black.svg" alt="Fix My Brick Logo" className="w-full h-auto max-h-[36px] sm:max-h-[44px] md:max-h-[54px] lg:max-h-[64px] object-contain object-left transition-all duration-300" width={133} height={60} />
            </Link>

            {/* Desktop Navigation */}
            <DesktopNavigation />

            {/* CTA Button */}
            <div className="hidden md:flex items-center">
              <Link to="/contact">
                <Button className="bg-brick hover:bg-brick-dark shadow-sm text-sm lg:text-base py-1 px-3 lg:py-2 lg:px-4 whitespace-nowrap">
                  Get a Quote
                </Button>
              </Link>
            </div>

            {/* Mobile Menu Button */}
            <button className="md:hidden text-gray-700 p-2 rounded-md hover:bg-gray-100" onClick={toggleMenu} aria-label="Toggle menu">
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>

          {/* Mobile Menu */}
          {isMenuOpen && <MobileNavigation closeMenu={() => setIsMenuOpen(false)} phoneNumber={settings.phone_number} />}
        </div>
      </div>
    </header>;
};
export default Header;
