
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import AdminLayout from '@/components/admin/AdminLayout';
import LocationForm from '@/components/admin/LocationEditor/LocationForm';
import LoadingState from '@/components/admin/LocationEditor/LoadingState';
import { useLocationForm } from '@/hooks/location';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { HomeIcon, MapPinIcon } from 'lucide-react';

const AdminEditLocation = () => {
  const { id } = useParams<{ id: string }>();
  const locationId = id || 'new';
  
  const {
    formData,
    setFormData,
    parentLocations,
    loading,
    saving,
    isNewLocation,
    handleChange,
    handleSave
  } = useLocationForm(locationId);

  if (loading) {
    return <LoadingState isNewLocation={isNewLocation} />;
  }

  return (
    <AdminLayout title={isNewLocation ? 'Add Location' : 'Edit Location'}>
      <div className="mb-4">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to="/admin/dashboard">
                  <HomeIcon className="h-4 w-4 mr-1" />
                  Dashboard
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to="/admin/locations">
                  <MapPinIcon className="h-4 w-4 mr-1" />
                  Locations
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink>
                {isNewLocation ? 'Add Location' : 'Edit Location'}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>

      <LocationForm
        formData={formData}
        setFormData={setFormData}
        parentLocations={parentLocations}
        isNewLocation={isNewLocation}
        saving={saving}
        handleChange={handleChange}
        handleSave={handleSave}
      />
    </AdminLayout>
  );
};

export default AdminEditLocation;
