import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import StatusFilter from './franchise-images/StatusFilter';
import SocialMediaImageGrid from './franchise-images/SocialMediaImageGrid';
import { useImageStatusUpdate } from '@/hooks/franchise-images/useImageStatusUpdate';
import { isCloudflareImageUrl } from '@/lib/imageUtils';
import { Button } from '@/components/ui/button';

interface FranchiseImagesViewProps {
  open: boolean;
  onClose: () => void;
  franchiseRegion: string;
  jobNumber: number;
  onAddToGallery?: (imageUrl: string) => void;
  galleryId?: string;
  coverImage?: string;
  onSetCoverImage?: (imageUrl: string) => void;
}

const FranchiseImagesView = ({
  open,
  franchiseRegion,
  jobNumber,
  onAddToGallery,
  coverImage,
  onSetCoverImage,
}: FranchiseImagesViewProps) => {
  const [images, setImages] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [showOnlyPending, setShowOnlyPending] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [imageErrors, setImageErrors] = useState<Record<string, boolean>>({});
  const [debugMode, setDebugMode] = useState(true);

  const { approving, rejecting, updateImageStatus } = useImageStatusUpdate();

  useEffect(() => {
    if (franchiseRegion && jobNumber) {
      fetchImages();
    }
  }, [franchiseRegion, jobNumber, showOnlyPending]);

  const fetchImages = async () => {
    try {
      setLoading(true);
      setImages([]);

      let query = supabase
        .from('franchise_image_jobs')
        .select('*')
        .eq('franchise_region', franchiseRegion)
        .eq('job_number', jobNumber);

      if (showOnlyPending) {
        query = query.eq('review_status', 'pending');
      }

      const { data, error } = await query;

      if (error) throw error;

      // Verify data
      if (data && data.length > 0) {
        console.log(
          `Retrieved ${data.length} franchise images for job #${jobNumber}`
        );

        // Verify and sanitize image URLs before storing them
        const processedImages = data.map((img) => {
          // Log raw image data
          console.log('Raw image data:', img);

          // Create a sanitized version of the image
          const sanitizedImage = {
            ...img,
            image_url: img.image_url || '', // Ensure image_url is not undefined
          };

          return sanitizedImage;
        });

        // Print the first few image URLs for debugging
        processedImages.slice(0, 3).forEach((img, i) => {
          console.log(`Image ${i + 1} URL:`, img.image_url);
          console.log(
            `Is Cloudflare URL:`,
            isCloudflareImageUrl(img.image_url)
          );

          // Try to preload image for debugging
          if (debugMode) {
            const testImg = new Image();
            testImg.onload = () =>
              console.log(`Test image ${i + 1} loaded successfully`);
            testImg.onerror = () =>
              console.error(`Test image ${i + 1} failed to load`);
            testImg.src = img.image_url;
          }
        });

        setImages(processedImages);
      } else {
        console.log(
          `No franchise images found for job #${jobNumber} in ${franchiseRegion}`
        );
        setImages([]);
      }
    } catch (error) {
      console.error('Error fetching franchise images:', error);
      toast.error('Failed to load franchise images');
      setImages([]);
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (imageId: string) => {
    updateImageStatus(imageId, 'approved');
  };

  const handleReject = async (imageId: string) => {
    updateImageStatus(imageId, 'rejected');
  };

  const handlePrevImage = () => {
    setActiveImageIndex((prev) => (prev > 0 ? prev - 1 : images.length - 1));
  };

  const handleNextImage = () => {
    setActiveImageIndex((prev) => (prev < images.length - 1 ? prev + 1 : 0));
  };

  // Track image load errors
  const handleImageError = (imageId: string) => {
    console.error(`Image with ID ${imageId} failed to load`);
    setImageErrors((prev) => ({
      ...prev,
      [imageId]: true,
    }));
  };

  // Force refresh images
  const handleRefreshImages = () => {
    setImageErrors({});
    fetchImages();
  };

  if (!franchiseRegion || !jobNumber || !open) {
    return null;
  }

  return (
    <Card>
      <CardContent className='pt-6'>
        <div className='flex justify-between items-center mb-4'>
          <h3 className='text-lg font-medium'>
            Franchise Images - Job #{jobNumber} ({franchiseRegion})
          </h3>
          <div className='flex items-center gap-2'>
            <Button
              variant='outline'
              size='sm'
              onClick={handleRefreshImages}
              className='flex items-center'>
              Refresh Images
            </Button>
            <StatusFilter
              showOnlyPending={showOnlyPending}
              onShowOnlyPendingChange={setShowOnlyPending}
            />
          </div>
        </div>

        {loading ? (
          <div className='text-center py-8'>Loading images...</div>
        ) : images.length === 0 ? (
          <div className='text-center py-8'>
            <p className='text-muted-foreground'>
              No images found for this job.
            </p>
            <Button onClick={handleRefreshImages} className='mt-4'>
              Retry
            </Button>
          </div>
        ) : (
          <SocialMediaImageGrid
            images={images}
            loading={loading}
            coverImage={coverImage}
            onAddToGallery={onAddToGallery}
            onSetCoverImage={onSetCoverImage}
            onApprove={handleApprove}
            onReject={handleReject}
            approving={approving}
            rejecting={rejecting}
            activeImageIndex={activeImageIndex}
            setActiveImageIndex={setActiveImageIndex}
            onPrevImage={handlePrevImage}
            onNextImage={handleNextImage}
            onImageError={handleImageError}
            imageErrors={imageErrors}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default FranchiseImagesView;
