
import React from 'react';
import AdminLayout from '@/components/admin/AdminLayout';
import { AssetLibrary } from '@/components/admin/assets/AssetLibrary';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@/components/ui/breadcrumb';
import { HomeIcon } from 'lucide-react';

const AdminAssets: React.FC = () => {
  return (
    <AdminLayout title="Asset Library">
      <div className="p-6">
        <Breadcrumb className="mb-6">
          <BreadcrumbItem>
            <BreadcrumbLink href="/admin">
              <HomeIcon className="h-4 w-4 mr-2" />
              Dashboard
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink>Asset Library</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        
        <AssetLibrary />
      </div>
    </AdminLayout>
  );
};

export default AdminAssets;
