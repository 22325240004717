import { useState, useCallback, useEffect } from 'react';

export interface TableState<T> {
  data: T[];
  filteredData: T[];
  pagination: {
    pageIndex: number;
    pageSize: number;
    pageCount: number;
  };
  sorting: {
    column: string | null;
    direction: 'asc' | 'desc';
  };
  search: string;
}

export interface UseAdminTableProps<T> {
  data: T[];
  initialState?: Partial<TableState<T>>;
  searchFields?: (keyof T)[];
}

export interface UseAdminTableReturn<T> {
  tableState: TableState<T>;
  currentPageData: T[];
  totalItems: number;
  setPage: (page: number) => void;
  setPageSize: (size: number) => void;
  setSearch: (term: string) => void;
  setSort: (column: string, direction: 'asc' | 'desc') => void;
}

export function useAdminTable<T>({
  data,
  initialState,
  searchFields = []
}: UseAdminTableProps<T>): UseAdminTableReturn<T> {
  const [tableState, setTableState] = useState<TableState<T>>({
    data: data || [],
    filteredData: data || [],
    pagination: {
      pageIndex: initialState?.pagination?.pageIndex || 0,
      pageSize: initialState?.pagination?.pageSize || 10,
      pageCount: Math.ceil((data?.length || 0) / (initialState?.pagination?.pageSize || 10)),
    },
    sorting: {
      column: initialState?.sorting?.column || null,
      direction: initialState?.sorting?.direction || 'asc',
    },
    search: initialState?.search || '',
  });

  // Apply search filter
  const applySearch = useCallback((items: T[], searchTerm: string) => {
    if (!searchTerm.trim() || searchFields.length === 0) {
      return items;
    }

    const lowerCaseSearch = searchTerm.toLowerCase();
    
    return items.filter(item => {
      return searchFields.some(field => {
        const value = item[field];
        if (value === null || value === undefined) return false;
        return String(value).toLowerCase().includes(lowerCaseSearch);
      });
    });
  }, [searchFields]);

  // Apply sorting
  const applySorting = useCallback((items: T[], column: string | null, direction: 'asc' | 'desc') => {
    if (!column) return items;
    
    return [...items].sort((a: any, b: any) => {
      const valueA = a[column];
      const valueB = b[column];
      
      if (valueA === valueB) return 0;
      
      // Handle undefined/null values
      if (valueA === undefined || valueA === null) return 1;
      if (valueB === undefined || valueB === null) return -1;
      
      // Compare based on types
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return direction === 'asc' 
          ? valueA.localeCompare(valueB) 
          : valueB.localeCompare(valueA);
      }
      
      return direction === 'asc' 
        ? valueA - valueB 
        : valueB - valueA;
    });
  }, []);

  // Get current page data
  const getCurrentPageData = useCallback((items: T[], pageIndex: number, pageSize: number) => {
    const start = pageIndex * pageSize;
    return items.slice(start, start + pageSize);
  }, []);

  // Update filtered data when data, search, or sorting changes
  useEffect(() => {
    let filtered = [...data];
    
    // Apply search
    filtered = applySearch(filtered, tableState.search);
    
    // Apply sorting
    filtered = applySorting(filtered, tableState.sorting.column, tableState.sorting.direction);
    
    // Update page count
    const pageCount = Math.max(1, Math.ceil(filtered.length / tableState.pagination.pageSize));
    
    // Adjust current page if needed
    let pageIndex = tableState.pagination.pageIndex;
    if (pageIndex >= pageCount) {
      pageIndex = Math.max(0, pageCount - 1);
    }
    
    setTableState(prev => ({
      ...prev,
      data: data,
      filteredData: filtered,
      pagination: {
        ...prev.pagination,
        pageIndex: pageIndex,
        pageCount: pageCount,
      }
    }));
  }, [data, tableState.search, tableState.sorting.column, tableState.sorting.direction, tableState.pagination.pageSize, applySearch, applySorting]);

  // Set page
  const setPage = useCallback((page: number) => {
    setTableState(prev => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageIndex: page - 1, // Convert from 1-indexed to 0-indexed
      }
    }));
  }, []);

  // Set page size
  const setPageSize = useCallback((size: number) => {
    setTableState(prev => {
      const newPageCount = Math.ceil(prev.filteredData.length / size);
      // Adjust current page if needed
      let pageIndex = prev.pagination.pageIndex;
      if (pageIndex >= newPageCount) {
        pageIndex = Math.max(0, newPageCount - 1);
      }
      
      return {
        ...prev,
        pagination: {
          pageIndex: pageIndex,
          pageSize: size,
          pageCount: newPageCount,
        }
      };
    });
  }, []);

  // Set search
  const setSearch = useCallback((term: string) => {
    setTableState(prev => ({
      ...prev,
      search: term,
      // Reset to first page when searching
      pagination: {
        ...prev.pagination,
        pageIndex: 0,
      }
    }));
  }, []);

  // Set sort
  const setSort = useCallback((column: string, direction: 'asc' | 'desc') => {
    setTableState(prev => ({
      ...prev,
      sorting: {
        column,
        direction,
      }
    }));
  }, []);

  // Get current page data
  const currentPageData = getCurrentPageData(
    tableState.filteredData,
    tableState.pagination.pageIndex,
    tableState.pagination.pageSize
  );

  return {
    tableState,
    currentPageData,
    totalItems: tableState.filteredData.length,
    setPage,
    setPageSize,
    setSearch,
    setSort,
  };
}