import React from 'react';
import AdminLayout from '@/components/admin/AdminLayout';
import CredentialsForm from '@/components/admin/WordPress/CredentialsForm';
import MigrationControls from '@/components/admin/WordPress/MigrationControls';
import MigrationHistory from '@/components/admin/WordPress/MigrationHistory';
import { useWordPressMigration } from '@/hooks/useWordPressMigration';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { AlertCircle } from 'lucide-react';

const AdminWordPressMigration = () => {
  const {
    credentials,
    migrationHistory,
    loading,
    migrating,
    saving,
    saveCredentials,
    startMigration,
  } = useWordPressMigration();

  return (
    <AdminLayout title='WordPress Migration'>
      <div className='grid gap-6'>
        <Alert className='relative overflow-hidden border-l-4 border-blue-600'>
          <AlertCircle className='h-4 w-4' />
          <AlertTitle className='text-lg font-semibold'>
            WordPress Migration Tool
          </AlertTitle>
          <AlertDescription className='mt-2'>
            <p className='mb-2'>
              This tool imports blog posts, categories, and tags from your
              WordPress site to your Supabase database.
            </p>

            <details className='mt-2 text-sm'>
              <summary className='font-medium cursor-pointer'>
                How the migration works
              </summary>
              <div className='mt-2 space-y-2 pl-4 border-l-2 border-gray-200'>
                <p>
                  1. <strong>Enter WordPress credentials</strong> - Provide URL
                  and authentication details
                </p>
                <p>
                  2. <strong>Start migration</strong> - The system connects to
                  WordPress API
                </p>
                <p>
                  3. <strong>Data transfer</strong> - Categories and tags are
                  imported first, then posts
                </p>
                <p>
                  4. <strong>Duplicate prevention</strong> - Items with existing
                  slugs are automatically skipped
                </p>
              </div>
            </details>

            <details className='mt-2 text-sm'>
              <summary className='font-medium cursor-pointer'>
                What gets migrated
              </summary>
              <div className='mt-2 space-y-1 pl-4 border-l-2 border-gray-200'>
                <p>
                  ✓ <strong>Blog Categories</strong> - All categories with
                  slugs, descriptions
                </p>
                <p>
                  ✓ <strong>Blog Tags</strong> - All tags with slugs,
                  descriptions
                </p>
                <p>
                  ✓ <strong>Blog Posts</strong> - Title, content, excerpts,
                  publish date, featured image
                </p>
                <p>
                  ✓ <strong>Relationships</strong> - All posts are linked to
                  their categories and tags
                </p>
                <p>
                  ✗ <strong>Not included</strong> - Comments, custom fields,
                  users, revisions
                </p>
              </div>
            </details>

            <div className='mt-4 p-2 bg-amber-50 border-l-4 border-amber-400 text-amber-700 text-sm'>
              <strong>Note:</strong> Running a migration multiple times is safe
              - existing content will not be duplicated.
            </div>
          </AlertDescription>
        </Alert>

        <div className='grid md:grid-cols-2 gap-6'>
          <CredentialsForm
            initialValues={credentials}
            onSubmit={saveCredentials}
            saving={saving}
          />

          <MigrationControls
            credentials={credentials}
            onStartMigration={startMigration}
            migrating={migrating}
            history={migrationHistory}
          />
        </div>

        <MigrationHistory history={migrationHistory} loading={loading} />
      </div>
    </AdminLayout>
  );
};

export default AdminWordPressMigration;
