import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '@/lib/supabase';

interface FooterLinkProps {
  href: string;
  label: string;
}

const FooterLink: React.FC<FooterLinkProps> = ({ href, label }) => (
  <li className='py-2'>
    <Link
      to={href}
      className='text-gray-300 hover:text-white transition-colors text-base relative group'>
      <span className='group-hover:pl-1 transition-all duration-200 ease-in-out'>
        {label}
      </span>
      <span className='absolute bottom-0 left-0 w-0 h-[1px] bg-white group-hover:w-full transition-all duration-300 ease-in-out'></span>
    </Link>
  </li>
);

export const QuickLinks: React.FC = () => {
  return (
    <div className='space-y-6'>
      <h3 className='text-xl font-semibold text-white border-b border-gray-600 pb-3'>
        Quick Links
      </h3>
      <ul className='space-y-2'>
        <FooterLink href='/' label='Home' />
        <FooterLink href='/our-services' label='Services' />
        <FooterLink href='/reviews' label='Reviews' />
        <FooterLink href='/locations' label='Locations' />
        <FooterLink href='/about' label='About Us' />
        <FooterLink href='/contact' label='Contact' />
      </ul>
    </div>
  );
};

type Service = {
  id: string;
  title: string;
  slug: string;
};

export const ServiceLinks: React.FC = () => {
  const [services, setServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const { data, error } = await supabase
          .from('services')
          .select('id, title, slug')
          .order('title');

        if (error) {
          console.error('Error fetching services:', error);
        } else {
          setServices(data || []);
        }
      } catch (err) {
        console.error('Error fetching services:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  const servicesToDisplay =
    services.length > 0
      ? services
      : [
          {
            id: '1',
            title: 'Brick Porch Construction & Repair',
            slug: 'brick-porch-construction-repair',
          },
          { id: '2', title: 'Brick Repair', slug: 'brick-repair' },
          { id: '3', title: 'Chimney Removal', slug: 'chimney-removal' },
          { id: '4', title: 'Chimney Repair', slug: 'chimney-repair' },
          { id: '5', title: 'Fireplace Repair', slug: 'fireplace-repair' },
          { id: '6', title: 'Parging', slug: 'parging' },
          { id: '7', title: 'Stone Masonry', slug: 'stone-masonry' },
          {
            id: '8',
            title: 'Tuckpointing & Repointing',
            slug: 'tuckpointing-repointing',
          },
        ];

  return (
    <div className='space-y-6'>
      <h3 className='text-xl font-semibold text-white border-b border-gray-600 pb-3'>
        Our Services
      </h3>
      <ul className='space-y-2'>
        {servicesToDisplay.map((service, index) => (
          <FooterLink
            key={service.id || index}
            href={`/services/${service.slug}`}
            label={service.title}
          />
        ))}
      </ul>
    </div>
  );
};
