
import React from 'react';
import { CheckCircle2, AlertTriangle } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription } from '@/components/ui/alert';

interface MigrationConfirmDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onConfirm: () => Promise<void>;
}

const MigrationConfirmDialog: React.FC<MigrationConfirmDialogProps> = ({
  open,
  onOpenChange,
  onConfirm,
}) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirm Migration</DialogTitle>
          <DialogDescription>
            You've already run a migration from this WordPress site. Running it
            again will:
          </DialogDescription>
        </DialogHeader>

        <div className='space-y-4 py-2'>
          <div className='flex items-start gap-2'>
            <CheckCircle2 className='h-5 w-5 text-green-500 mt-0.5' />
            <p>
              Import any <strong>new</strong> posts, categories and tags that
              have been created since the last migration
            </p>
          </div>
          <div className='flex items-start gap-2'>
            <CheckCircle2 className='h-5 w-5 text-green-500 mt-0.5' />
            <p>
              Skip any content that already exists (based on matching slugs)
            </p>
          </div>
          <div className='flex items-start gap-2'>
            <CheckCircle2 className='h-5 w-5 text-green-500 mt-0.5' />
            <p>Create a new migration history record to track this import</p>
          </div>

          <Alert className='mt-4'>
            <AlertTriangle className='h-4 w-4' />
            <AlertDescription>
              This process cannot be canceled once started. Please wait for it
              to complete.
            </AlertDescription>
          </Alert>
        </div>

        <DialogFooter>
          <Button
            variant='outline'
            onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={onConfirm}>
            Proceed with Migration
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default MigrationConfirmDialog;
