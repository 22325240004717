
import { useState, useCallback, useRef } from 'react';
import { FranchiseImageFilters } from './types';

export const useFiltering = () => {
  const [filters, setFilters] = useState<FranchiseImageFilters>({});
  const filterTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleFilterChange = useCallback((newFilters: FranchiseImageFilters) => {
    // Clear any pending filter updates
    if (filterTimeoutRef.current) {
      clearTimeout(filterTimeoutRef.current);
    }

    // Process filters to remove 'all' values which should be treated as no filter
    const processedFilters: FranchiseImageFilters = {};
    
    // Only add filters that have actual values (not 'all' or undefined)
    if (newFilters.franchise && newFilters.franchise !== 'all') {
      processedFilters.franchise = newFilters.franchise;
    }
    
    // For status, we need to check if it exists and is not the string 'all'
    if (newFilters.status && newFilters.status !== ('all' as any)) {
      processedFilters.status = newFilters.status;
    }
    
    if (newFilters.searchTerm && newFilters.searchTerm.trim() !== '') {
      processedFilters.searchTerm = newFilters.searchTerm.trim();
    }
    
    // Use object equality check to prevent unnecessary updates
    const filtersChanged = JSON.stringify(filters) !== JSON.stringify(processedFilters);
    
    if (filtersChanged) {
      console.log('Setting filters to:', processedFilters);
      setFilters(processedFilters);
    } else {
      console.log('Filters unchanged, skipping update');
    }
  }, [filters]);

  const clearFilters = useCallback(() => {
    console.log('Clearing all filters');
    setFilters({});
  }, []);

  return {
    filters,
    handleFilterChange,
    clearFilters
  };
};
