
import React, { useState } from 'react';
import { Image, Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';

interface AddBackgroundDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onAdd: (name: string, file: File | null) => Promise<void>;
  isUploading: boolean;
}

const AddBackgroundDialog: React.FC<AddBackgroundDialogProps> = ({
  open,
  onOpenChange,
  onAdd,
  isUploading,
}) => {
  const [newBackgroundName, setNewBackgroundName] = useState('');
  const [newBackgroundFile, setNewBackgroundFile] = useState<File | null>(null);

  const handleAdd = async () => {
    await onAdd(newBackgroundName, newBackgroundFile);
    resetForm();
  };

  const resetForm = () => {
    setNewBackgroundName('');
    setNewBackgroundFile(null);
  };

  const handleClose = () => {
    resetForm();
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className='sm:max-w-[525px]'>
        <DialogHeader>
          <DialogTitle className='text-xl'>Add New Background</DialogTitle>
          <DialogDescription>
            Upload a new background image to your library without applying it
            immediately.
          </DialogDescription>
        </DialogHeader>

        <div className='space-y-6 py-4'>
          <div className='space-y-2'>
            <Label htmlFor='bg-name' className='text-sm font-medium'>
              Background Name
            </Label>
            <Input
              id='bg-name'
              placeholder='e.g., Stone Patio'
              value={newBackgroundName}
              onChange={(e) => setNewBackgroundName(e.target.value)}
              className='w-full'
            />
          </div>

          <div className='space-y-2'>
            <Label className='text-sm font-medium'>Image File</Label>
            <div
              className='border-2 border-dashed border-gray-300 hover:border-primary/50 rounded-lg p-8 text-center transition-colors cursor-pointer'
              onClick={() =>
                document.getElementById('bg-file-upload')?.click()
              }>
              {newBackgroundFile ? (
                <div className='space-y-3'>
                  <div className='w-full aspect-video max-h-[160px] mx-auto overflow-hidden rounded border border-gray-200'>
                    <img
                      src={URL.createObjectURL(newBackgroundFile)}
                      alt='Preview'
                      className='w-full h-full object-cover'
                    />
                  </div>
                  <p className='text-sm font-medium text-gray-700'>
                    {newBackgroundFile.name}
                  </p>
                  <Button
                    variant='outline'
                    size='sm'
                    onClick={(e) => {
                      e.stopPropagation();
                      setNewBackgroundFile(null);
                    }}>
                    Change Image
                  </Button>
                </div>
              ) : (
                <div className='py-4'>
                  <Image className='h-10 w-10 mx-auto text-gray-400 mb-2' />
                  <p className='text-sm font-medium text-gray-700 mb-1'>
                    Drag & drop or click to upload
                  </p>
                  <p className='text-xs text-gray-500'>
                    Recommended: 2000x1200px (16:9). Max 5MB.
                  </p>
                </div>
              )}
              <input
                id='bg-file-upload'
                type='file'
                className='sr-only'
                accept='image/*'
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) setNewBackgroundFile(file);
                }}
              />
            </div>
          </div>
        </div>

        <DialogFooter className='flex justify-between items-center gap-3 pt-2'>
          <Button
            variant='outline'
            onClick={handleClose}
            className='px-4'>
            Cancel
          </Button>
          <Button
            onClick={handleAdd}
            disabled={
              !newBackgroundName || !newBackgroundFile || isUploading
            }
            className='relative overflow-hidden px-5'>
            {isUploading && (
              <div className='absolute inset-0 bg-black/20 flex items-center justify-center'>
                <div className='h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin'></div>
              </div>
            )}
            <Plus className='h-4 w-4 mr-2' />
            {isUploading ? 'Uploading...' : 'Add to Library'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddBackgroundDialog;
