
import React, { useState } from 'react';
import { Button } from "@/components/ui/button";
import { DialogTrigger, Dialog } from "@/components/ui/dialog";
import { Plus, Filter, Search } from 'lucide-react';
import { Skeleton } from "@/components/ui/skeleton";
import PhotoUploadDialog from './photos/PhotoUploadDialog';
import PhotoItem from './photos/PhotoItem';
import { PhotoGridProps } from './photos/types';
import { Input } from "@/components/ui/input";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ReviewStatus } from '@/hooks/gallery-table/types';
import { Pagination } from '@/components/ui/pagination';

const PhotoGrid = ({
  photos,
  loading,
  uploading,
  deleting,
  confirmDeleteId,
  onUpload,
  onDelete,
  onCaptionUpdate,
  onUpdatePhotoReviewStatus
}: PhotoGridProps) => {
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterReviewStatus, setFilterReviewStatus] = useState<ReviewStatus | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12; // Photos per page

  // Filter photos based on search and review status
  const filteredPhotos = photos.filter(photo => {
    const matchesSearch = !searchQuery || 
      photo.caption?.toLowerCase().includes(searchQuery.toLowerCase());
    
    const matchesReviewStatus = !filterReviewStatus || 
      photo.review_status === filterReviewStatus;
    
    return matchesSearch && matchesReviewStatus;
  });

  // Paginate the filtered photos
  const totalFilteredPhotos = filteredPhotos.length;
  const totalPages = Math.ceil(totalFilteredPhotos / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const paginatedPhotos = filteredPhotos.slice(startIndex, startIndex + pageSize);

  // Reset to first page when filters change
  React.useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, filterReviewStatus]);

  if (loading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {[...Array(6)].map((_, i) => (
          <div key={i} className="relative aspect-square rounded overflow-hidden bg-gray-100">
            <Skeleton className="h-full w-full" />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium">Gallery Photos ({totalFilteredPhotos})</h3>
        <Dialog open={uploadDialogOpen} onOpenChange={setUploadDialogOpen}>
          <DialogTrigger asChild>
            <Button>
              <Plus className="mr-2 h-4 w-4" />
              Add Photo
            </Button>
          </DialogTrigger>
          <PhotoUploadDialog
            open={uploadDialogOpen}
            onOpenChange={setUploadDialogOpen}
            uploading={uploading}
            onUpload={onUpload}
          />
        </Dialog>
      </div>

      {/* Photo search and filters */}
      <div className="flex flex-wrap gap-4 items-center">
        <div className="relative flex-1 min-w-[200px]">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search captions..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="pl-8"
          />
        </div>
        
        <div className="flex items-center gap-2">
          <Filter className="h-4 w-4 text-muted-foreground" />
          <span className="text-sm font-medium">Review Status:</span>
          <Select
            value={filterReviewStatus || "all"}
            onValueChange={(value) => {
              setFilterReviewStatus(value === "all" ? null : value as ReviewStatus);
            }}
          >
            <SelectTrigger className="w-[150px]">
              <SelectValue placeholder="All Photos" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Photos</SelectItem>
              <SelectItem value="pending">Pending</SelectItem>
              <SelectItem value="approved">Approved</SelectItem>
              <SelectItem value="rejected">Rejected</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      {photos.length === 0 ? (
        <div className="border rounded-md p-8 text-center text-muted-foreground">
          <p>No photos in this gallery yet.</p>
          <p className="text-sm mt-2">Click "Add Photo" to upload images.</p>
        </div>
      ) : filteredPhotos.length === 0 ? (
        <div className="border rounded-md p-8 text-center text-muted-foreground">
          <p>No photos match your search criteria.</p>
          <p className="text-sm mt-2">Try adjusting your filters.</p>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {paginatedPhotos.map(photo => (
              <PhotoItem
                key={photo.id}
                photo={photo}
                deleting={deleting}
                confirmDeleteId={confirmDeleteId}
                onDelete={onDelete}
                onCaptionUpdate={onCaptionUpdate}
                onUpdateReviewStatus={onUpdatePhotoReviewStatus}
              />
            ))}
          </div>
          
          {/* Pagination */}
          {totalPages > 1 && (
            <div className="flex justify-center mt-8">
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PhotoGrid;
