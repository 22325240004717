
import React from 'react';
import { Textarea } from '@/components/ui/textarea';

const MessageField: React.FC = () => {
  return (
    <div>
      <Textarea 
        name="message" 
        placeholder="Tell us about your project requirements" 
        className="w-full min-h-[100px] text-gray-900 !important"
        style={{ color: '#1f2937' }}
      />
    </div>
  );
};

export default MessageField;
