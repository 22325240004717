
import React from 'react';
import AdminLayout from '@/components/admin/AdminLayout';
import WSIBCertificateForm from '@/components/admin/wsib/WSIBCertificateForm';
import WSIBCertificatePreview from '@/components/admin/wsib/WSIBCertificatePreview';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { FileText } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';

const AdminWSIB = () => {
  const { data: certificate, isLoading } = useQuery({
    queryKey: ['wsib-certificate'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('wsib_certificate')
        .select('*')
        .order('created_at', { ascending: false })
        .limit(1)
        .maybeSingle();
      
      if (error) {
        console.error('Error fetching WSIB certificate:', error);
        throw error;
      }
      
      return data;
    }
  });

  return (
    <AdminLayout title="WSIB Certificate">
      <div className="grid md:grid-cols-2 gap-6 mb-6">
        <Card>
          <CardHeader className="pb-3">
            <CardTitle className="text-xl flex items-center">
              <FileText className="h-5 w-5 mr-2 text-D00B0E" />
              Manage WSIB Certificate
            </CardTitle>
            <CardDescription>
              Update your Workplace Safety and Insurance Board certification information and document.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <WSIBCertificateForm />
          </CardContent>
        </Card>
        
        <WSIBCertificatePreview 
          certificate={certificate} 
          isLoading={isLoading} 
        />
      </div>
    </AdminLayout>
  );
};

export default AdminWSIB;
