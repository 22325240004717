
import { ServiceDetail } from "@/types/service";

export function getFallbackServiceContent(): Record<string, ServiceDetail> {
  return {
    'chimney-repair': {
      id: '1',
      title: 'Chimney Repair',
      slug: 'chimney-repair',
      description: 'Professional chimney repair and restoration services to ensure your chimney is safe, efficient, and visually appealing.',
      full_description: `A damaged chimney is more than just an eyesore – it's a potential safety hazard that can lead to water damage, structural issues, and even fire risks. Our expert chimney repair services address a wide range of common problems from minor crack repair to complete chimney rebuilding.

With over 20 years of experience, Fix My Brick's certified masons use industry-leading techniques and high-quality materials to restore your chimney to optimal condition. We understand the importance of both structural integrity and aesthetic appeal, ensuring your chimney not only functions safely but also enhances your home's appearance and value.`,
      benefits: [
        'Prevents water damage to your home',
        'Eliminates fire hazards',
        'Improves heating efficiency',
        'Extends the lifespan of your chimney system',
        'Enhances your home\'s curb appeal and value'
      ],
      process_steps: [
        {
          title: 'Comprehensive Inspection',
          description: 'Our certified technicians conduct a thorough assessment of your chimney structure, identifying all damaged areas and potential safety concerns.'
        },
        {
          title: 'Detailed Proposal',
          description: 'We provide a clear, itemized estimate with recommended repairs, timeline, and transparent pricing.'
        },
        {
          title: 'Expert Repair',
          description: 'Our skilled masons perform the necessary repairs using premium materials and proven techniques.'
        },
        {
          title: 'Quality Assurance',
          description: 'A final inspection ensures all repairs meet our strict quality standards and industry code requirements.'
        }
      ],
      faq: [
        {
          question: 'How do I know if my chimney needs repair?',
          answer: 'Look for signs such as cracked or missing bricks, crumbling mortar, white staining (efflorescence), water damage inside the home near the chimney, or a leaning chimney structure. We offer free inspections to properly assess your chimney\'s condition.'
        },
        {
          question: 'How long does chimney repair typically take?',
          answer: 'Most standard chimney repairs can be completed in 1-3 days, depending on the extent of the damage. Complete chimney rebuilds may take 4-7 days. We\'ll provide a specific timeline during your consultation.'
        },
        {
          question: 'Can chimney repairs be done in winter?',
          answer: 'While we can perform emergency repairs year-round, extensive chimney work is ideally done in temperatures above 40°F (4°C) to allow proper mortar curing. We offer temporary solutions for winter issues and schedule comprehensive repairs for optimal conditions.'
        },
        {
          question: 'Do you provide a warranty on chimney repairs?',
          answer: 'Yes, Fix My Brick offers a 5-year warranty on all chimney repair work, covering both materials and labor. This warranty is transferable if you sell your home.'
        }
      ],
      image_url: '/images/chimney-repair.jpg',
      cta_text: 'Don\'t risk chimney damage escalating into costly structural issues or safety hazards. Contact Fix My Brick today for a free inspection and expert repair solution.'
    },
    'brick-repair': {
      id: '2',
      title: 'Brick Repair',
      slug: 'brick-repair',
      description: 'Expert brick repair services to restore the structural integrity and appearance of your brick surfaces.',
      full_description: `Damaged brickwork doesn't just affect your home's appearance – it can compromise its structural integrity and lead to more serious issues down the line. Our comprehensive brick repair services address everything from minor cosmetic issues to major structural concerns, ensuring your brick surfaces remain beautiful and durable for decades to come.

Fix My Brick's master masons bring over two decades of specialized experience to every project. We meticulously match new bricks to your existing masonry and use proper techniques to ensure seamless repairs that are virtually indistinguishable from the original construction.`,
      benefits: [
        'Prevents water infiltration and structural damage',
        'Restores your home\'s curb appeal and market value',
        'Addresses safety concerns from unstable masonry',
        'More cost-effective than delayed repairs or full replacement',
        'Preserves the character and authenticity of your masonry'
      ],
      process_steps: [
        {
          title: 'Thorough Assessment',
          description: 'We carefully evaluate all damaged areas, identifying underlying issues and the full extent of needed repairs.'
        },
        {
          title: 'Custom Solution',
          description: 'Our team develops a tailored repair plan that addresses your specific masonry issues while matching your home\'s aesthetic.'
        },
        {
          title: 'Precision Repair',
          description: 'Using period-appropriate techniques and carefully color-matched materials, we execute repairs that blend seamlessly with your existing brickwork.'
        },
        {
          title: 'Final Inspection',
          description: 'We conduct a comprehensive review of all repairs to ensure perfect integration with existing masonry and long-term durability.'
        }
      ],
      faq: [
        {
          question: 'Can you match my existing brick color and texture?',
          answer: 'Yes, we specialize in sourcing bricks that closely match your existing masonry in color, texture, and size. For historic properties, we can often locate reclaimed period bricks or use specialized tinting techniques to ensure a perfect match.'
        },
        {
          question: 'Is it better to repair or replace damaged brick walls?',
          answer: 'In most cases, skilled repairs are preferable to complete replacement, preserving the original character and being more cost-effective. Our assessment will determine if the wall\'s structural integrity allows for repair or if rebuilding sections is necessary.'
        },
        {
          question: 'How do you handle brick repair for historical properties?',
          answer: 'We have specialized expertise in historical masonry techniques and restoration. For heritage properties, we use period-appropriate methods, materials, and can work within historical preservation guidelines while obtaining any necessary permissions.'
        },
        {
          question: 'What causes brick deterioration?',
          answer: 'The most common causes include water infiltration, freeze-thaw cycles, foundation settlement, improper previous repairs, salt exposure, and age. Our repair approach addresses not just the visible damage but the underlying causes to prevent recurrence.'
        }
      ],
      image_url: '/images/brick-repair.jpg',
      cta_text: 'Don\'t let damaged brickwork compromise your home\'s beauty and integrity. Contact Fix My Brick today for expert assessment and seamless repairs.'
    },
    'tuckpointing': {
      id: '3',
      title: 'Tuckpointing & Repointing',
      slug: 'tuckpointing',
      description: 'Restore the integrity and appearance of your masonry with our professional tuckpointing and repointing services.',
      full_description: `Mortar joints are the critical but often overlooked component of any masonry structure. As they deteriorate over time, they can allow water infiltration that damages not just the bricks but potentially your home's interior as well. Our tuckpointing and repointing services restore these crucial joints, protecting your masonry and enhancing its appearance.

Tuckpointing is more than just filling in gaps – it's a skilled craft that requires precision, experience, and an eye for detail. Our masons carefully remove damaged mortar to the proper depth, mix new mortar to match your existing masonry, and apply it with expert technique to ensure longevity and a clean, professional finish.`,
      benefits: [
        'Prevents water damage to masonry and interior spaces',
        'Extends the lifespan of your brick structures by decades',
        'Improves structural stability and integrity',
        'Enhances the appearance and value of your property',
        'More cost-effective than replacing entire masonry sections'
      ],
      process_steps: [
        {
          title: 'Mortar Joint Evaluation',
          description: 'We assess the condition of existing mortar joints, identifying areas of deterioration and determining the appropriate repair approach.'
        },
        {
          title: 'Mortar Matching',
          description: 'Our team carefully analyzes your existing mortar to create a custom mix that matches in color, texture, and composition.'
        },
        {
          title: 'Precision Restoration',
          description: 'Using specialized tools, we remove damaged mortar to the proper depth and apply new mortar with expert technique for a seamless finish.'
        },
        {
          title: 'Protective Finishing',
          description: 'After proper curing, we clean the masonry surface and can apply appropriate sealants if needed for additional protection.'
        }
      ],
      faq: [
        {
          question: 'What\'s the difference between tuckpointing and repointing?',
          answer: 'Repointing is the process of removing and replacing deteriorated mortar in masonry joints. Tuckpointing is a specialized type of repointing that uses two contrasting colors of mortar to create the illusion of very fine joints, traditionally used in historic restoration. We offer both services depending on your needs and preferences.'
        },
        {
          question: 'How often should tuckpointing be done?',
          answer: 'Typically, mortar joints should be inspected every 15-20 years, but this varies based on exposure to weather, quality of original installation, and local climate conditions. In harsh environments with freeze-thaw cycles, more frequent maintenance may be necessary.'
        },
        {
          question: 'Will new mortar match my existing mortar?',
          answer: 'Yes, we take great care to match the color, texture, and composition of your existing mortar. For historic properties, we can analyze the original mortar and create a custom mix that matches not just in appearance but in performance characteristics.'
        },
        {
          question: 'Can tuckpointing be done in any weather?',
          answer: 'For optimal results, tuckpointing should be performed when temperatures remain between 40°F and 90°F (4°C-32°C) for at least 24 hours after application. We schedule work accordingly to ensure proper curing and longevity of the repairs.'
        }
      ],
      image_url: '/images/tuckpointing.jpg',
      cta_text: 'Don\'t wait for water damage to reveal the cost of deteriorated mortar joints. Contact Fix My Brick today for expert tuckpointing that protects your masonry investment.'
    },
    'stone-masonry': {
      id: '4',
      title: 'Stone Masonry',
      slug: 'stone-masonry',
      description: 'Custom stone masonry services including installation, repair, and restoration for all your stone structure needs.',
      full_description: `Stone masonry combines timeless beauty with unmatched durability, creating structures that stand the test of time while enhancing any property's aesthetic appeal. Whether you're looking to add natural stone elements to your home, restore existing stonework, or undertake a major stone construction project, our master stone masons bring the artistry and technical expertise needed for exceptional results.

At Fix My Brick, we work with a wide variety of natural stone materials – from limestone and sandstone to granite, marble, and fieldstone – skillfully crafting each piece to create beautiful, enduring stone structures. Our comprehensive stone masonry services encompass both new construction and expert restoration of historical stonework.`,
      benefits: [
        'Unparalleled durability and longevity compared to other building materials',
        'Increases property value with timeless, premium aesthetics',
        'Natural thermal mass properties for improved energy efficiency',
        'Extremely low maintenance requirements',
        'Unique character that cannot be replicated with manufactured materials'
      ],
      process_steps: [
        {
          title: 'Design Consultation',
          description: 'We work closely with you to understand your vision, recommend appropriate stone types, and develop a design that balances aesthetics, function, and budget.'
        },
        {
          title: 'Material Selection',
          description: 'Our team helps you select the ideal stone variety based on your project requirements, location, climate conditions, and design preferences.'
        },
        {
          title: 'Expert Craftsmanship',
          description: 'Our master stone masons meticulously cut, shape, and place each stone with precision and attention to detail.'
        },
        {
          title: 'Finishing and Sealing',
          description: 'We properly finish and seal your stonework to enhance its natural beauty and ensure its protection from the elements for decades to come.'
        }
      ],
      faq: [
        {
          question: 'What types of stone masonry projects do you handle?',
          answer: 'We provide comprehensive stone masonry services including stone walls and facades, stone patios and walkways, stone steps and entranceways, stone fireplace construction and facing, stone pillars and columns, retaining walls, garden features, and historic stone restoration.'
        },
        {
          question: 'How do you determine which type of stone is best for my project?',
          answer: 'We consider several factors: the project\'s location and exposure to elements, architectural style, intended use, maintenance preferences, budget, and local availability. Each stone type has unique characteristics in terms of hardness, porosity, color stability, and workability.'
        },
        {
          question: 'Is natural stone more expensive than manufactured alternatives?',
          answer: 'While the initial investment for natural stone is typically higher than manufactured products, its exceptional longevity, minimal maintenance requirements, timeless appeal, and positive impact on property values make it more cost-effective over the life of the structure.'
        },
        {
          question: 'How do you address existing stone masonry that needs repair?',
          answer: 'Our approach to stone repair involves carefully assessing the damage, identifying matching stone materials, using appropriate traditional and modern techniques, and ensuring structural integrity while preserving the original character and appearance of the stonework.'
        }
      ],
      image_url: '/images/stone-masonry.jpg',
      cta_text: 'Transform your property with the timeless beauty and unmatched durability of expert stone masonry. Contact Fix My Brick today to discuss your vision.'
    },
    'fireplace-repair': {
      id: '5',
      title: 'Fireplace Repair',
      slug: 'fireplace-repair',
      description: 'Comprehensive fireplace repair services to restore functionality, safety, and beauty to your hearth.',
      full_description: `A fireplace should be the warm, inviting focal point of your home – but when it's damaged or deteriorating, it can become both an eyesore and a safety hazard. Our fireplace repair services address the full spectrum of fireplace issues, from cosmetic concerns to serious structural problems, restoring both the beauty and functionality of this important home feature.

Fix My Brick's specialized fireplace repair team combines technical expertise with careful craftsmanship to deliver repairs that ensure your fireplace is not just visually appealing but safe and efficient. We work with all types of masonry fireplaces including traditional brick, stone, and historic hearths.`,
      benefits: [
        'Ensures safe operation by addressing potential fire hazards',
        'Improves heating efficiency and performance',
        'Restores the visual appeal of your home\'s focal point',
        'Prevents smoke and carbon monoxide issues',
        'Protects your home from heat and water damage'
      ],
      process_steps: [
        {
          title: 'Comprehensive Inspection',
          description: 'We thoroughly assess your fireplace structure, smoke chamber, damper, firebox, hearth, and exterior components to identify all issues requiring attention.'
        },
        {
          title: 'Detailed Repair Plan',
          description: 'Based on our findings, we create a custom repair strategy that addresses all safety concerns while preserving the aesthetic qualities of your fireplace.'
        },
        {
          title: 'Expert Restoration',
          description: 'Our skilled masons implement repairs using appropriate materials and techniques, whether restoring historic features or rebuilding damaged sections.'
        },
        {
          title: 'Safety Verification',
          description: 'Upon completion, we conduct thorough testing to ensure your fireplace functions properly and safely before returning it to service.'
        }
      ],
      faq: [
        {
          question: 'How can I tell if my fireplace needs repair?',
          answer: 'Look for warning signs such as cracked or missing bricks in the firebox, damaged or crumbling mortar joints, white staining (efflorescence), rusted damper components, smoke entering the room during fires, a damaged hearth, or visible water damage around the fireplace or chimney.'
        },
        {
          question: 'Can you repair my fireplace without affecting its historic character?',
          answer: 'Absolutely. For historic fireplaces, we use period-appropriate materials and techniques to ensure repairs maintain the authenticity and character of the original construction while improving functionality and safety.'
        },
        {
          question: 'Is it possible to convert my wood-burning fireplace to gas?',
          answer: 'Yes, we can modify existing masonry fireplaces to accommodate gas inserts or gas log sets. This conversion involves proper sizing, venting considerations, and ensuring the fireplace structure can safely accommodate the new heating system.'
        },
        {
          question: 'How long does fireplace repair typically take?',
          answer: 'Most standard fireplace repairs can be completed in 1-3 days. More extensive rebuilding or restoration work may take 3-5 days. We strive to minimize disruption to your home during the repair process.'
        }
      ],
      image_url: '/images/fireplace-repair.jpg',
      cta_text: 'Don\'t compromise on the safety and beauty of your home\'s hearth. Contact Fix My Brick today for expert fireplace assessment and repair.'
    },
    'brick-porch': {
      id: '6',
      title: 'Brick Porch Construction & Repair',
      slug: 'brick-porch',
      description: 'Expert brick porch construction and repair services to enhance your home\'s entrance and curb appeal.',
      full_description: `Your home's porch creates a crucial first impression while providing functional outdoor living space. Our brick porch services encompass both the construction of new porches and the meticulous repair of existing structures, creating entrances that combine beauty, durability, and structural integrity.

From simple stoop repairs to complete porch rebuilding, Fix My Brick's experienced masons apply time-tested techniques and quality materials to create porches that enhance your home's architecture while withstanding the elements for decades to come. We understand that a porch is both a functional necessity and an architectural statement.`,
      benefits: [
        'Significantly enhances curb appeal and home value',
        'Creates additional functional outdoor living space',
        'Provides better protection for your entryway from weather',
        'Addresses safety concerns from deteriorating steps or railings',
        'Delivers decades of durability with minimal maintenance'
      ],
      process_steps: [
        {
          title: 'Detailed Consultation',
          description: 'We assess your current porch or discuss your vision for a new one, considering architectural style, practical needs, and aesthetic preferences.'
        },
        {
          title: 'Custom Design',
          description: 'For new construction or major rebuilds, we create a custom design that complements your home\'s architecture while meeting all structural requirements.'
        },
        {
          title: 'Professional Construction',
          description: 'Our experienced masons build or repair your porch with exceptional attention to detail, ensuring proper foundations, drainage, and structural integrity.'
        },
        {
          title: 'Quality Finishing',
          description: 'We complete your project with appropriate sealing, railings, and finishing touches that enhance both beauty and functionality.'
        }
      ],
      faq: [
        {
          question: 'How can I tell if my brick porch needs repair or rebuilding?',
          answer: 'Warning signs include visible cracks in the structure, leaning or sagging sections, deteriorated mortar joints, loose bricks or stones, damaged steps, water pooling on the porch surface, or foundation cracks. We provide free assessments to determine if repair or rebuilding is more appropriate.'
        },
        {
          question: 'Can you match my home\'s existing brickwork?',
          answer: 'Yes, we specialize in sourcing bricks that closely match your home\'s existing masonry in color, texture, and size. For historic homes, we can often locate reclaimed period bricks or use specialized techniques to ensure a seamless visual integration.'
        },
        {
          question: 'Do brick porches require special maintenance?',
          answer: 'Brick porches are relatively low-maintenance, but benefit from periodic inspection and upkeep. We recommend annual inspections, prompt mortar joint repairs, proper drainage maintenance, and appropriate sealing in some cases. We can provide a customized maintenance plan.'
        },
        {
          question: 'How long does it take to build or repair a brick porch?',
          answer: 'Typical repair projects take 2-5 days depending on the extent of damage. New porch construction or complete rebuilds generally take 1-3 weeks, depending on size, complexity, and any custom features. Weather conditions may affect the timeline.'
        }
      ],
      image_url: '/images/brick-porch.jpg',
      cta_text: 'Make a lasting impression with a beautiful, durable brick porch that welcomes guests and enhances your home\'s value. Contact Fix My Brick today for expert consultation.'
    }
  };
}
