
import React, { Suspense } from 'react';
import { Link, useParams } from 'react-router-dom';
import BlogLayout from '@/components/blog/BlogLayout';
import LoadingState from '@/components/blog/LoadingState';
import ErrorState from '@/components/blog/ErrorState';
import RelatedPosts from '@/components/blog/RelatedPosts';
import PostContent from '@/components/blog/PostContent';
import PostCategories from '@/components/blog/PostCategories';
import PostHeader from '@/components/blog/PostHeader';
import PostTags from '@/components/blog/PostTags';
import { useBlogPostData } from '@/hooks/useBlogPostData';
import PageMeta from '@/components/SEO/PageMeta';
import '@/styles/blog.css';
import { Skeleton } from '@/components/ui/skeleton';

const BlogPostLoading = () => (
  <div className="max-w-4xl mx-auto">
    <div className="mb-6">
      <Skeleton className="h-60 w-full bg-gray-200 rounded-md" />
    </div>
    
    <div className="mb-4">
      <Skeleton className="h-8 w-32 bg-gray-200 rounded-md" />
    </div>
    
    <div className="mb-8 space-y-3">
      <Skeleton className="h-12 w-full bg-gray-200 rounded-md" />
      <div className="flex space-x-4">
        <Skeleton className="h-6 w-40 bg-gray-200 rounded-md" />
        <Skeleton className="h-6 w-32 bg-gray-200 rounded-md" />
      </div>
    </div>
    
    <div className="space-y-4 mb-10">
      <Skeleton className="h-4 w-full bg-gray-200 rounded-md" />
      <Skeleton className="h-4 w-full bg-gray-200 rounded-md" />
      <Skeleton className="h-4 w-5/6 bg-gray-200 rounded-md" />
      <Skeleton className="h-4 w-full bg-gray-200 rounded-md" />
      <Skeleton className="h-4 w-4/5 bg-gray-200 rounded-md" />
    </div>
  </div>
);

const BlogPost: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { post, relatedPosts, loading, error } = useBlogPostData(slug);

  if (loading) {
    return (
      <BlogLayout>
        <BlogPostLoading />
      </BlogLayout>
    );
  }

  if (error || !post) {
    return (
      <BlogLayout>
        <div className="container mx-auto px-4 py-16">
          <ErrorState message="Sorry, the blog post you're looking for doesn't exist or has been removed." />
          <div className="text-center mt-6">
            <Link 
              to="/blog" 
              className="inline-block bg-brick hover:bg-brick-dark text-white font-medium px-4 py-2 rounded"
            >
              Back to Blog
            </Link>
          </div>
        </div>
      </BlogLayout>
    );
  }

  const postSchema = {
    "@type": "BlogPosting",
    "headline": post.title,
    "description": post.excerpt || post.title,
    "datePublished": post.published_at,
    "dateModified": post.updated_at || post.published_at,
    "author": {
      "@type": "Person",
      "name": post.author_name || "Fix My Brick"
    }
  };

  const seo = post.seo || {};
  const featuredImage = post.featured_image;
  
  return (
    <BlogLayout featuredImage={featuredImage}>
      <PageMeta 
        title={seo.meta_title || post.title}
        description={seo.meta_description || post.excerpt || `${post.title} - Read our masonry blog post.`}
        image={seo.og_image || post.featured_image || undefined}
        type="article"
        canonical={seo.canonical_url}
        schema={postSchema}
      />
      
      <article>
        <div className="max-w-4xl mx-auto">
          <PostCategories categories={post.blog_categories} />
          <PostHeader title={post.title} authorName={post.author_name} publishedAt={post.published_at} />
          <PostContent content={post.content} />
          <PostTags tags={post.blog_tags} />
          
          {relatedPosts.length > 0 && <RelatedPosts posts={relatedPosts} />}
          
          <div className="mt-12 text-center">
            <Link 
              to="/blog" 
              className="inline-block bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium px-6 py-3 rounded-md transition-colors"
            >
              Back to All Posts
            </Link>
          </div>
        </div>
      </article>
    </BlogLayout>
  );
};

export default BlogPost;
