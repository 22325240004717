import React from 'react';
import { CheckCircle, AlertCircle, Loader2, XCircle, RefreshCw } from 'lucide-react';

interface SubmissionStatusProps {
  state: 'success' | 'error' | 'submitting';
  clientName?: string;
  leadId?: string | null;
  error?: string | null;
  onRetry?: () => void;
  onReset?: () => void;
}

const SubmissionStatus: React.FC<SubmissionStatusProps> = ({
  state,
  clientName,
  leadId,
  error,
  onRetry,
  onReset
}) => {
  if (state === 'submitting') {
    return (
      <div className="flex flex-col items-center justify-center p-6 space-y-4">
        <Loader2 className="h-16 w-16 text-primary animate-spin" />
        <h3 className="text-xl font-semibold text-center">Submitting Your Request</h3>
        <p className="text-center text-gray-600">
          We're sending your information to our team. This may take a moment...
        </p>
      </div>
    );
  }

  if (state === 'success') {
    return (
      <div className="flex flex-col items-center justify-center p-6 space-y-4 bg-green-50 rounded-lg">
        <div className="flex items-center justify-center h-24 w-24 rounded-full bg-green-100">
          <CheckCircle className="h-16 w-16 text-green-600" />
        </div>
        <h3 className="text-2xl font-bold text-center text-green-800">
          Thank You{clientName ? `, ${clientName.split(' ')[0]}` : ''}!
        </h3>
        <p className="text-center text-green-700 text-lg">
          Your request has been submitted successfully.
        </p>
        <div className="bg-white p-4 rounded-md w-full">
          <p className="text-gray-700">
            Our team will review your message and get back to you shortly. 
            We typically respond within 1-2 business days.
          </p>
          {leadId && (
            <p className="text-sm text-gray-600 mt-2">
              Reference ID: {leadId}
            </p>
          )}
        </div>
        <button
          onClick={onReset}
          className="mt-4 inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary"
        >
          <XCircle className="mr-2 h-4 w-4" />
          Close
        </button>
      </div>
    );
  }

  if (state === 'error') {
    return (
      <div className="flex flex-col items-center justify-center p-6 space-y-4 bg-red-50 rounded-lg">
        <div className="flex items-center justify-center h-24 w-24 rounded-full bg-red-100">
          <AlertCircle className="h-16 w-16 text-red-600" />
        </div>
        <h3 className="text-2xl font-bold text-center text-red-800">
          Submission Failed
        </h3>
        <p className="text-center text-red-700 text-lg">
          We couldn't process your request at this time.
        </p>
        {error && (
          <div className="bg-white p-4 rounded-md w-full">
            <p className="text-sm text-gray-600">Error details:</p>
            <p className="text-red-600 text-sm mt-1">{error}</p>
          </div>
        )}
        <div className="flex space-x-4 mt-4">
          <button
            onClick={onRetry}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-primary border border-transparent rounded-md hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary"
          >
            <RefreshCw className="mr-2 h-4 w-4" />
            Retry Submission
          </button>
          <button
            onClick={onReset}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary"
          >
            <XCircle className="mr-2 h-4 w-4" />
            Cancel
          </button>
        </div>
        <p className="text-center text-gray-600 text-sm">
          You can also try again later or contact us directly at <a href="mailto:info@fixmybrick.ca" className="text-primary hover:underline">info@fixmybrick.ca</a>.
        </p>
      </div>
    );
  }

  return null;
};

export default SubmissionStatus;