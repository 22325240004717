import React from 'react';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  TableFooter
} from '@/components/ui/table';
import { Skeleton } from '@/components/ui/skeleton';
import { Button } from '@/components/ui/button';
import { XCircle, RefreshCcw, AlertCircle, Search } from 'lucide-react';
import { Input } from '@/components/ui/input';

// Define column type
export interface ColumnDef<T> {
  header: React.ReactNode;
  cell: (row: T) => React.ReactNode;
  id?: string;
  accessorKey?: string;
  className?: string;
}

// Props for the AdminTable component
export interface AdminTableProps<T> {
  data: T[] | undefined;
  columns: ColumnDef<T>[];
  isLoading?: boolean;
  isError?: boolean;
  onRetry?: () => void;
  emptyMessage?: string;
  pagination?: React.ReactNode;
  actions?: React.ReactNode;
  searchable?: boolean;
  onSearch?: (searchTerm: string) => void;
  className?: string;
}

// TableLoadingState component
export const TableLoadingState = ({ columns }: { columns: number }) => (
  <>
    {Array.from({ length: 5 }).map((_, rowIndex) => (
      <TableRow key={`loading-row-${rowIndex}`}>
        {Array.from({ length: columns }).map((_, colIndex) => (
          <TableCell key={`loading-cell-${rowIndex}-${colIndex}`}>
            <Skeleton className="h-8 w-full" />
          </TableCell>
        ))}
      </TableRow>
    ))}
  </>
);

// TableEmptyState component
export const TableEmptyState = ({ 
  message, 
  columns 
}: { 
  message: string;
  columns: number;
}) => (
  <TableRow>
    <TableCell colSpan={columns} className="h-24 text-center">
      <div className="flex flex-col items-center justify-center text-muted-foreground">
        <XCircle className="h-8 w-8 mb-2 text-muted-foreground/70" />
        <p>{message}</p>
      </div>
    </TableCell>
  </TableRow>
);

// TableErrorState component
export const TableErrorState = ({ 
  columns,
  onRetry
}: { 
  columns: number;
  onRetry?: () => void;
}) => (
  <TableRow>
    <TableCell colSpan={columns} className="h-24 text-center">
      <div className="flex flex-col items-center justify-center">
        <AlertCircle className="h-8 w-8 mb-2 text-red-500" />
        <p className="text-muted-foreground mb-2">Error loading data</p>
        {onRetry && (
          <Button 
            variant="outline" 
            size="sm" 
            onClick={onRetry} 
            className="flex items-center gap-1"
          >
            <RefreshCcw className="h-4 w-4" />
            Retry
          </Button>
        )}
      </div>
    </TableCell>
  </TableRow>
);

// TableSearchBar component
export const TableSearchBar = ({ 
  onSearch 
}: { 
  onSearch: (term: string) => void;
}) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    onSearch(term);
  };
  
  return (
    <div className="relative max-w-sm">
      <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
      <Input
        type="search"
        placeholder="Search..."
        className="pl-8 max-w-sm"
        value={searchTerm}
        onChange={handleSearch}
      />
    </div>
  );
};

// Main AdminTable component
export function AdminTable<T>({ 
  data, 
  columns, 
  isLoading = false, 
  isError = false, 
  onRetry,
  emptyMessage = "No data found", 
  pagination, 
  actions,
  searchable = false,
  onSearch,
  className
}: AdminTableProps<T>) {
  return (
    <div className="space-y-4">
      {(actions || searchable) && (
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 mb-4">
          {searchable && onSearch && (
            <TableSearchBar onSearch={onSearch} />
          )}
          {actions && <div className="ml-auto">{actions}</div>}
        </div>
      )}
      
      <div className={`rounded-md border bg-background ${className}`}>
        <Table>
          <TableHeader>
            <TableRow className="bg-muted/30">
              {columns.map((column, i) => (
                <TableHead key={i} className={column.className}>
                  {column.header}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          
          <TableBody>
            {isLoading && <TableLoadingState columns={columns.length} />}
            {isError && <TableErrorState columns={columns.length} onRetry={onRetry} />}
            {!isLoading && !isError && (!data || data.length === 0) && (
              <TableEmptyState message={emptyMessage} columns={columns.length} />
            )}
            {!isLoading && !isError && data && data.length > 0 && (
              data.map((row, i) => (
                <TableRow key={i}>
                  {columns.map((column, j) => (
                    <TableCell key={j} className={column.className}>
                      {column.cell(row)}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      
      {pagination && <div className="mt-4">{pagination}</div>}
    </div>
  );
}