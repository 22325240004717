
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { 
  Edit, 
  Eye, 
  Trash2,
  AlertTriangle,
  CheckCircle,
  Clock
} from 'lucide-react';
import { ADMIN_ROUTES } from '@/routes';
import { Badge } from '@/components/ui/badge';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/components/ui/alert-dialog';
import { ServiceDetail } from '@/types/service';

interface ServiceStatusProps {
  status: string;
}

const ServiceStatus: React.FC<ServiceStatusProps> = ({ status }) => {
  if (status === 'published') {
    return (
      <Badge className="bg-green-100 text-green-800 hover:bg-green-200">
        <CheckCircle className="h-3 w-3 mr-1" />
        Published
      </Badge>
    );
  } else if (status === 'draft') {
    return (
      <Badge className="bg-yellow-100 text-yellow-800 hover:bg-yellow-200">
        <Clock className="h-3 w-3 mr-1" />
        Draft
      </Badge>
    );
  } else {
    return (
      <Badge className="bg-red-100 text-red-800 hover:bg-red-200">
        <AlertTriangle className="h-3 w-3 mr-1" />
        {status}
      </Badge>
    );
  }
};

interface ServiceTableProps {
  services: ServiceDetail[];
  filteredServices: ServiceDetail[];
  confirmDeleteId: string | null;
  deleting: boolean;
  onEdit: (id: string) => void;
  onPreview: (slug: string) => void;
  onDelete: (id: string | null) => void;
}

const ServiceTable: React.FC<ServiceTableProps> = ({
  services,
  filteredServices,
  confirmDeleteId,
  deleting,
  onEdit,
  onPreview,
  onDelete
}) => {
  const navigate = useNavigate();

  const handleEditClick = (id: string | number) => {
    navigate(ADMIN_ROUTES.EDIT_SERVICE_PATH(String(id)));
  };

  return (
    <div>
      <div className="rounded-md border mb-4">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[300px]">Service Name</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Featured</TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredServices.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} className="h-24 text-center">
                  {services.length === 0 ? 'No services found. Create your first service!' : 'No services match your search.'}
                </TableCell>
              </TableRow>
            ) : (
              filteredServices.map((service) => (
                <TableRow key={service.id}>
                  <TableCell className="font-medium">{service.title}</TableCell>
                  <TableCell>
                    <ServiceStatus status={service.status || 'published'} />
                  </TableCell>
                  <TableCell>
                    {service.is_featured ? (
                      <Badge className="bg-blue-100 text-blue-800 hover:bg-blue-200">
                        Featured
                      </Badge>
                    ) : (
                      <span className="text-muted-foreground text-sm">-</span>
                    )}
                  </TableCell>
                  <TableCell className="text-right">
                    <div className="flex justify-end gap-2">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleEditClick(service.id)}
                        title="Edit service"
                      >
                        <Edit className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => onPreview(service.slug)}
                        title="Preview service"
                      >
                        <Eye className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => onDelete(String(service.id))}
                        className="text-red-500 hover:text-red-700 hover:bg-red-50"
                        title="Delete service"
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>

      <div className="mt-4 flex justify-end">
        <Button onClick={() => navigate(ADMIN_ROUTES.EDIT_SERVICE_PATH('new'))}>
          Add Service
        </Button>
      </div>

      <AlertDialog open={confirmDeleteId !== null} onOpenChange={() => onDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the service and remove it from the website.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel disabled={deleting}>Cancel</AlertDialogCancel>
            <AlertDialogAction 
              onClick={() => onDelete(confirmDeleteId)} 
              disabled={deleting}
              className="bg-red-600 hover:bg-red-700"
            >
              {deleting ? 'Deleting...' : 'Delete'}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default ServiceTable;
