
import React, { useState } from 'react';
import { Table, TableBody } from "@/components/ui/table";
import { useFranchiseImagesTable } from '@/hooks/franchise-images';
import FranchiseImagesTablePagination from './FranchiseImagesTablePagination';
import FranchiseImagesTableHeader from './FranchiseImagesTableHeader';
import FranchiseImagesTableRow from './FranchiseImagesTableRow';
import LoadingState from './LoadingState';
import EmptyState from './EmptyState';
import ErrorState from './ErrorState';
import PaginationLoadingIndicator from './PaginationLoadingIndicator';

const FranchiseImagesTable: React.FC = () => {
  const {
    images,
    loading,
    error,
    currentPage,
    pageSize,
    totalCount,
    approving,
    rejecting,
    handlePageChange,
    handlePageSizeChange,
    updateImageStatus,
    clearFilters,
    filters,
    refetch,
    isFetching
  } = useFranchiseImagesTable();
  
  // Maximum number of retry attempts
  const MAX_RETRY_ATTEMPTS = 3;
  const [retryAttempts, setRetryAttempts] = useState(0);
  
  // Retry fetching data if there's an error
  const handleRetry = () => {
    if (retryAttempts < MAX_RETRY_ATTEMPTS) {
      setRetryAttempts(prev => prev + 1);
      refetch();
    } else {
      // If maximum retries reached, clear filters and try once more
      clearFilters();
      setRetryAttempts(0);
    }
  };
  
  // Check if we have active filters
  const hasFilters = Object.keys(filters).length > 0;
  
  if (loading && images.length === 0) {
    return (
      <div className="rounded-md border bg-background">
        <Table>
          <LoadingState />
        </Table>
      </div>
    );
  }
  
  if (error) {
    return (
      <ErrorState 
        error={error}
        retryAttempts={retryAttempts}
        maxRetryAttempts={MAX_RETRY_ATTEMPTS}
        loading={loading}
        onRetry={handleRetry}
        onClearFilters={clearFilters}
      />
    );
  }
  
  if (images.length === 0) {
    return (
      <div className="rounded-md border bg-background">
        <Table>
          <FranchiseImagesTableHeader />
          <EmptyState 
            hasFilters={hasFilters}
            onClearFilters={clearFilters}
          />
        </Table>
      </div>
    );
  }
  
  return (
    <div className="space-y-4">
      <div className="rounded-md border bg-background">
        <Table>
          <FranchiseImagesTableHeader />
          <TableBody className={isFetching ? "opacity-70 transition-opacity duration-200" : ""}>
            {images.map((image) => (
              <FranchiseImagesTableRow 
                key={image.id}
                image={image}
                approving={approving}
                rejecting={rejecting}
                updateImageStatus={updateImageStatus}
              />
            ))}
          </TableBody>
        </Table>
      </div>
      
      {isFetching && <PaginationLoadingIndicator />}
      
      <FranchiseImagesTablePagination
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        itemsShown={images.length}
      />
    </div>
  );
};

export default FranchiseImagesTable;
