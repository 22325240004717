
import React from 'react';
import Header from '@/components/header';
import Footer from '@/components/Footer';
import GalleryHeader from './GalleryHeader';

const GalleryNotFound: React.FC = () => {
  return (
    <>
      <Header />
      <main className="container mx-auto px-4 py-12">
        <GalleryHeader 
          title="Gallery Not Found" 
          description="The gallery you're looking for doesn't exist or has been removed."
          backLink="/gallery"
        />
      </main>
      <Footer />
    </>
  );
};

export default GalleryNotFound;
