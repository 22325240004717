
import React from 'react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { SiteSettings } from '@/types/settings';

interface SiteIdentityTabProps {
  settings: SiteSettings;
  saving: boolean;
  handleChange: (field: keyof SiteSettings, value: string) => void;
}

const SiteIdentityTab: React.FC<SiteIdentityTabProps> = ({
  settings,
  saving,
  handleChange
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Site Identity</CardTitle>
        <CardDescription>
          Configure how your site appears in browser tabs and when shared on social media.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <div>
            <Label htmlFor="business_name">Business Name</Label>
            <Input
              id="business_name"
              value={settings.business_name}
              onChange={(e) => handleChange('business_name', e.target.value)}
              disabled={saving}
              placeholder="Enter your business name"
              className="mt-1"
            />
            <p className="text-xs text-gray-500 mt-1">
              This name will appear in browser tabs and when your site is shared on social media.
            </p>
          </div>
          
          {settings.favicon_url && (
            <div className="bg-gray-50 p-4 rounded-md">
              <h3 className="text-sm font-medium mb-2">Current Favicon Preview</h3>
              <div className="flex items-center space-x-4">
                <div className="border rounded-md p-2 bg-white">
                  <img 
                    src={settings.favicon_url} 
                    alt="Current favicon" 
                    className="h-6 w-6"
                  />
                </div>
                <div>
                  <p className="text-xs text-gray-500">Browser tab preview:</p>
                  <div className="flex items-center space-x-2 mt-1 border rounded-t-md bg-gray-100 p-1.5 text-xs">
                    <img 
                      src={settings.favicon_url} 
                      alt="Tab favicon" 
                      className="h-4 w-4"
                    />
                    <span className="truncate max-w-[150px]">Home | {settings.business_name}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          <div className="pt-4 border-t">
            <h3 className="text-sm font-medium mb-2">How This Appears</h3>
            <ul className="list-disc ml-5 text-xs text-gray-600 space-y-1">
              <li>Browser tabs will show: "Page Name | {settings.business_name}"</li>
              <li>Social shares will use this name as the site identifier</li>
              <li>Search engines will index this as your site name</li>
            </ul>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SiteIdentityTab;
