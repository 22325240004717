
/**
 * Format bytes to a human-readable string with appropriate units
 */
export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

/**
 * Format a date string to a localized date format
 */
export const formatDate = (
  dateString: string, 
  options: Intl.DateTimeFormatOptions = { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric' 
  }
): string => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-CA', options).format(date);
};

/**
 * Format a number with thousand separators
 */
export const formatNumber = (number: number): string => {
  return new Intl.NumberFormat('en-CA').format(number);
};

/**
 * Format a price value to currency
 */
export const formatCurrency = (
  value: number, 
  options: Intl.NumberFormatOptions = { 
    style: 'currency', 
    currency: 'CAD' 
  }
): string => {
  return new Intl.NumberFormat('en-CA', options).format(value);
};

/**
 * Format a percentage
 */
export const formatPercent = (
  value: number, 
  decimals = 1
): string => {
  return `${value.toFixed(decimals)}%`;
};

/**
 * Truncate a string with ellipsis if it exceeds maxLength
 */
export const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};
