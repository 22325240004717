
import React, { useState } from 'react';
import { useAssetManager, AssetFolder } from '@/hooks/useAssetManager';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription
} from '@/components/ui/sheet';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Folder, Plus, MoreVertical } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';

interface AssetFolderSheetProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onCreateFolder: () => void;
  onDeleteFolder: (folderId: string) => void;
}

export const AssetFolderSheet: React.FC<AssetFolderSheetProps> = ({
  open,
  onOpenChange,
  onCreateFolder,
  onDeleteFolder
}) => {
  const { folders, renameFolder } = useAssetManager();
  const [renameFolderId, setRenameFolderId] = useState<string | null>(null);
  const [renameValue, setRenameValue] = useState('');
  
  // Rename a folder
  const handleRenameFolder = async () => {
    if (!renameFolderId || !renameValue.trim()) return;
    
    await renameFolder(renameFolderId, renameValue);
    setRenameFolderId(null);
    setRenameValue('');
  };
  
  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>Manage Folders</SheetTitle>
          <SheetDescription>
            Create, rename, or delete folders to organize your assets.
          </SheetDescription>
        </SheetHeader>
        
        <div className="mt-6">
          <Button 
            className="w-full mb-4 gap-2"
            onClick={onCreateFolder}
          >
            <Plus size={16} />
            Create New Folder
          </Button>
          
          <div className="space-y-1 max-h-[60vh] overflow-y-auto">
            {folders.map(folder => (
              <div
                key={folder.id}
                className="flex items-center justify-between p-2 border rounded-md"
              >
                {renameFolderId === folder.id ? (
                  <div className="flex-1 flex items-center gap-2">
                    <Input
                      value={renameValue}
                      onChange={(e) => setRenameValue(e.target.value)}
                      autoFocus
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleRenameFolder();
                        } else if (e.key === 'Escape') {
                          setRenameFolderId(null);
                        }
                      }}
                    />
                    <Button size="sm" onClick={handleRenameFolder}>Save</Button>
                    <Button 
                      size="sm" 
                      variant="ghost"
                      onClick={() => setRenameFolderId(null)}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className="flex items-center gap-2">
                      <Folder size={16} />
                      <span>{folder.name}</span>
                    </div>
                    
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="icon" className="h-8 w-8">
                          <MoreVertical size={16} />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuItem
                          onClick={() => {
                            setRenameFolderId(folder.id);
                            setRenameValue(folder.name);
                          }}
                        >
                          Rename folder
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => onDeleteFolder(folder.id)}
                          className="text-destructive"
                        >
                          Delete folder
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </>
                )}
              </div>
            ))}
            
            {folders.length === 0 && (
              <div className="text-center py-4 text-muted-foreground">
                <Folder size={24} className="mx-auto mb-2" />
                <p>No folders yet</p>
              </div>
            )}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
