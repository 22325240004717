
import React from 'react';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Info } from 'lucide-react';

interface AreaInfoTabProps {
  areaDescription: string;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const AreaInfoTab: React.FC<AreaInfoTabProps> = ({
  areaDescription,
  handleChange
}) => {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div>
        <Label htmlFor="area_description" className="block text-sm font-medium mb-1">
          Area Information
        </Label>
        <Textarea
          id="area_description"
          name="area_description"
          value={areaDescription || ''}
          onChange={handleChange}
          placeholder="Enter information about this area (HTML supported)"
          rows={15}
        />
        <div className="mt-2 space-y-2">
          <p className="text-xs text-gray-500">
            Provide detailed information about this area. This content will be displayed in the "About [Location Name]" section.
            HTML formatting is supported for rich text layout.
          </p>
          <Alert variant="default" className="bg-blue-50 border-blue-200">
            <Info className="h-4 w-4 text-blue-500" />
            <AlertDescription className="text-xs text-blue-700">
              If this field is left empty, the "About" section will not be displayed on the location page.
            </AlertDescription>
          </Alert>
        </div>
      </div>
    </div>
  );
};

export default AreaInfoTab;
