
import React from 'react';
import AdminLayout from '@/components/admin/AdminLayout';

interface LoadingStateProps {
  isNewPage: boolean;
}

const LoadingState: React.FC<LoadingStateProps> = ({ isNewPage }) => {
  return (
    <AdminLayout title={isNewPage ? 'Create New Page' : 'Edit Page'}>
      <div className="flex justify-center py-8">
        <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full"></div>
      </div>
    </AdminLayout>
  );
};

export default LoadingState;
