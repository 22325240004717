
import { supabase } from './client';
import { HeroBackground, HeroBackgroundCreate } from '@/types/heroBackground';
import { deleteFileByUrl, STORAGE_BUCKET } from '@/utils/imageUpload';

// Fetch all hero backgrounds
export const fetchHeroBackgrounds = async (): Promise<HeroBackground[]> => {
  const { data, error } = await supabase
    .from('hero_backgrounds')
    .select('*')
    .order('is_preset', { ascending: false })
    .order('name');

  if (error) {
    console.error('Error fetching hero backgrounds:', error);
    return [];
  }

  return data as HeroBackground[];
};

// Create a new hero background
export const createHeroBackground = async (
  background: HeroBackgroundCreate
): Promise<HeroBackground | null> => {
  const { data, error } = await supabase
    .from('hero_backgrounds')
    .insert(background)
    .select()
    .single();

  if (error) {
    console.error('Error creating hero background:', error);
    return null;
  }

  return data as HeroBackground;
};

// Update an existing hero background
export const updateHeroBackground = async (
  id: string,
  updates: Partial<HeroBackgroundCreate>
): Promise<boolean> => {
  // If the URL is being updated, we should check if we need to delete the old image
  if (updates.url) {
    try {
      const { data: oldBackground } = await supabase
        .from('hero_backgrounds')
        .select('url')
        .eq('id', id)
        .single();
      
      // If we have an old URL and it's from our storage, try to delete it
      if (oldBackground?.url && oldBackground.url.includes(STORAGE_BUCKET)) {
        // Don't await this - we don't want to block the update if deletion fails
        deleteFileByUrl(oldBackground.url).catch(err => {
          console.warn('Failed to delete old background file:', err);
        });
      }
    } catch (error) {
      console.warn('Failed to check for old background image:', error);
      // Continue with the update
    }
  }

  const { error } = await supabase
    .from('hero_backgrounds')
    .update(updates)
    .eq('id', id);

  if (error) {
    console.error('Error updating hero background:', error);
    return false;
  }

  return true;
};

// Delete a hero background
export const deleteHeroBackground = async (id: string): Promise<boolean> => {
  try {
    // First, get the background to check if it's a file in our storage
    const { data: background, error: fetchError } = await supabase
      .from('hero_backgrounds')
      .select('*')
      .eq('id', id)
      .single();
      
    if (fetchError) {
      console.error('Error fetching hero background for deletion:', fetchError);
      return false;
    }
    
    // Delete from the database
    const { error } = await supabase
      .from('hero_backgrounds')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting hero background:', error);
      return false;
    }
    
    // If this was a file in our storage, attempt to delete the file too
    if (background && background.url && !background.is_preset) {
      try {
        // Use our utility function to delete the file
        await deleteFileByUrl(background.url);
      } catch (storageError) {
        console.warn('Error deleting background image file:', storageError);
        // Continue as the main operation (DB deletion) was successful
      }
    }

    return true;
  } catch (error) {
    console.error('Unexpected error during hero background deletion:', error);
    return false;
  }
};

// Export the hero background type from this file for re-export from lib/supabase/index.ts
export type { HeroBackground, HeroBackgroundCreate };
