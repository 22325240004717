
import React from 'react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Image, Crop } from 'lucide-react';
import { SiteSettings } from '@/types/settings';

interface BrandingTabProps {
  settings: SiteSettings;
  saving: boolean;
  uploadingLogo: boolean;
  uploadingFavicon: boolean;
  handleLogoUpload: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleFaviconUpload: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleOpenCropDialog: () => void;
}

const BrandingTab: React.FC<BrandingTabProps> = ({
  settings,
  saving,
  uploadingLogo,
  uploadingFavicon,
  handleLogoUpload,
  handleFaviconUpload,
  handleOpenCropDialog
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Site Branding</CardTitle>
        <CardDescription>
          Upload and manage your website's logo and favicon. These will be displayed across the site.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-8">
          <div>
            <Label 
              htmlFor="logo" 
              className="flex items-center gap-2 mb-2"
            >
              <Image className="h-4 w-4" /> Website Logo
            </Label>
            
            {settings.logo_url && (
              <div className="mb-4 p-4 border rounded-md bg-gray-50">
                <div className="text-sm text-gray-500 mb-2">Current Logo:</div>
                <img 
                  src={settings.logo_url} 
                  alt="Current logo" 
                  className="max-h-24 max-w-[300px] object-contain"
                />
                <div className="mt-4 flex items-center">
                  <Button 
                    type="button" 
                    variant="outline" 
                    size="sm" 
                    onClick={handleOpenCropDialog}
                    className="flex items-center"
                    disabled={uploadingLogo || saving}
                  >
                    <Crop className="h-4 w-4 mr-2" /> Adjust Logo
                  </Button>
                  <span className="text-xs text-gray-500 ml-3">
                    Crop and resize your logo for optimal display
                  </span>
                </div>
              </div>
            )}
            
            <div className="mt-2">
              <Input
                id="logo"
                type="file"
                accept="image/*"
                onChange={handleLogoUpload}
                disabled={uploadingLogo || saving}
                className="mt-1"
              />
              <p className="text-xs text-gray-500 mt-1">
                Recommended: PNG or SVG format, transparent background, max 2MB
              </p>
            </div>
          </div>

          <div>
            <Label 
              htmlFor="favicon" 
              className="flex items-center gap-2 mb-2"
            >
              <Image className="h-4 w-4" /> Favicon
            </Label>
            
            {settings.favicon_url && (
              <div className="mb-4 p-4 border rounded-md bg-gray-50">
                <div className="text-sm text-gray-500 mb-2">Current Favicon:</div>
                <img 
                  src={settings.favicon_url} 
                  alt="Current favicon" 
                  className="h-8 w-8 object-contain"
                />
              </div>
            )}
            
            <div className="mt-2">
              <Input
                id="favicon"
                type="file"
                accept=".ico,.png"
                onChange={handleFaviconUpload}
                disabled={uploadingFavicon || saving}
                className="mt-1"
              />
              <p className="text-xs text-gray-500 mt-1">
                ICO or PNG format, square dimensions (e.g., 32x32px, 64x64px), max 1MB
              </p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default BrandingTab;
