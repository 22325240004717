
import React from 'react';
import { Location } from '@/types/location';

interface LocationAreaInfoProps {
  location: Location;
}

const LocationAreaInfo: React.FC<LocationAreaInfoProps> = ({ location }) => {
  // Return null if area_description is empty, null, or undefined
  if (!location.area_description || location.area_description.trim() === '') {
    return null;
  }
  
  return (
    <div className="mt-12">
      <h2 className="text-2xl font-bold mb-4">About {location.name}</h2>
      <div className="prose max-w-none">
        <div dangerouslySetInnerHTML={{ __html: location.area_description }} />
      </div>
    </div>
  );
};

export default LocationAreaInfo;
