import React, { useEffect, useState } from 'react';
import { useSettings } from '@/hooks/useSettings';
import FooterCompanyInfo from './FooterCompanyInfo';
import { QuickLinks, ServiceLinks } from './FooterLinks';
import FooterContactInfo from './FooterContactInfo';
import FooterLocations from './FooterLocations';
import FooterCopyright from './FooterCopyright';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { settings, loading } = useSettings();
  const [locationsLoading, setLocationsLoading] = useState(true);

  const locationsToDisplay = [
    {
      name: 'Greater Toronto Area',
      address: '68 Beverly Hills Dr, North York, ON M3L 1A5',
    },
    {
      name: 'Greater Hamilton Region',
      address: '498 Glancaster Rd, Hamilton ON L0R 1W0',
    },
    {
      name: 'Kitchener - Waterloo Area',
      address: '290 King St E, Kitchener, ON N2G 2L2',
    },
    {
      name: 'Peel Region',
      address: '55 Village Centre Pl, Mississauga, ON L4Z 1V9',
    },
    {
      name: 'Greater Niagara Region',
      address: '21 Duke St, St. Catharines, ON L2R 5W1',
    },
    {
      name: 'London Area',
      address: '1172 Hamilton Rd unit 203, London ON N5W 1A9, Canada',
    },
  ];

  useEffect(() => {
    // Simulate loading state
    setLocationsLoading(false);
  }, []);

  return (
    <footer className='bg-black text-white'>
      <div className='container mx-auto px-6 md:px-8'>
        {/* First row with company info, links, and contact */}
        <div className='pt-28 pb-16 md:pt-24 md:pb-14 grid grid-cols-1 gap-16 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-12 md:gap-14'>
          {/* Company Info */}
          <div className='lg:col-span-3 space-y-10 md:space-y-8'>
            <FooterCompanyInfo settings={settings} />
          </div>

          {/* Quick Links */}
          <div className='lg:col-span-3 space-y-8'>
            <QuickLinks />
          </div>

          {/* Services */}
          <div className='lg:col-span-3 space-y-8'>
            <ServiceLinks />
          </div>

          {/* Contact Info (without locations) */}
          <div className='lg:col-span-3 space-y-10 md:space-y-8'>
            <FooterContactInfo
              phoneNumber={settings.phone_number}
              email={settings.email}
            />
          </div>
        </div>

        {/* Second row with locations */}
        <div className='pb-20 md:pb-16'>
          <FooterLocations
            locationsToDisplay={locationsToDisplay}
            locationsLoading={locationsLoading}
          />
        </div>

        <FooterCopyright currentYear={currentYear} />
      </div>
      {/* Extra padding at the bottom for mobile to ensure visibility above floating bar */}
      <div className='h-16 md:h-0'></div>
    </footer>
  );
};

export default Footer;
