
import React from 'react';
import { useAssetManager, AssetFolder } from '@/hooks/useAssetManager';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import { Folder, FolderPlus, MoreVertical } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';

interface AssetFolderSidebarProps {
  currentFolder: string | null;
  onFolderSelect: (folderId: string | null) => void;
  onCreateFolder: () => void;
  onManageFolders: () => void;
}

export const AssetFolderSidebar: React.FC<AssetFolderSidebarProps> = ({
  currentFolder,
  onFolderSelect,
  onCreateFolder,
  onManageFolders
}) => {
  const { folders, renameFolder, deleteFolder } = useAssetManager();
  const [renameFolderId, setRenameFolderId] = React.useState<string | null>(null);
  const [renameValue, setRenameValue] = React.useState('');
  
  // Helper function to render folder hierarchy with proper indentation
  const renderFolders = (
    folders: AssetFolder[],
    parentId: string | null = null,
    level = 0
  ) => {
    const indent = level * 16; // 16px per level
    
    return folders
      .filter(folder => folder.parent_id === parentId)
      .map(folder => (
        <React.Fragment key={folder.id}>
          <div
            className={`flex items-center justify-between p-2 rounded-md cursor-pointer ${
              currentFolder === folder.id ? 'bg-muted/50 font-medium' : 'hover:bg-muted/30'
            }`}
            style={{ paddingLeft: `${8 + indent}px` }}
            onClick={() => onFolderSelect(folder.id)}
          >
            <div className="flex items-center gap-2 truncate flex-grow">
              <Folder size={16} className="text-primary/80 shrink-0" />
              <span className="truncate">{folder.name}</span>
            </div>
            
            <DropdownMenu>
              <DropdownMenuTrigger asChild onClick={(e) => e.stopPropagation()}>
                <Button variant="ghost" size="icon" className="h-8 w-8 opacity-0 group-hover:opacity-100">
                  <MoreVertical size={16} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    const folderToRename = folders.find(f => f.id === folder.id);
                    if (folderToRename) {
                      setRenameFolderId(folder.id);
                      setRenameValue(folderToRename.name);
                    }
                  }}
                >
                  Rename folder
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="text-destructive"
                >
                  Delete folder
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          
          {/* Render subfolders */}
          {renderFolders(folders, folder.id, level + 1)}
        </React.Fragment>
      ));
  };
  
  // Handle rename folder
  const handleRenameFolder = async () => {
    if (!renameFolderId || !renameValue.trim()) return;
    
    await renameFolder(renameFolderId, renameValue);
    setRenameFolderId(null);
    setRenameValue('');
  };
  
  // Get current folder name
  const currentFolderName = currentFolder
    ? folders.find(f => f.id === currentFolder)?.name
    : 'All Assets';
  
  return (
    <div className="col-span-3 border rounded-md overflow-hidden bg-background">
      <div className="p-3 border-b bg-muted/20 flex justify-between items-center">
        <h3 className="font-medium">Folders</h3>
        <Button 
          variant="ghost" 
          size="icon"
          onClick={onCreateFolder}
          title="Create new folder"
        >
          <FolderPlus size={16} />
        </Button>
      </div>
      
      <ScrollArea className="h-[calc(100vh-12rem)]">
        <div className="p-2">
          <div
            className={`flex items-center p-2 rounded-md cursor-pointer ${
              currentFolder === null ? 'bg-muted/50 font-medium' : 'hover:bg-muted/30'
            }`}
            onClick={() => onFolderSelect(null)}
          >
            <Folder size={16} className="mr-2" />
            <span>All Assets</span>
          </div>
          
          {renderFolders(folders)}
        </div>
      </ScrollArea>
      
      <Button 
        variant="ghost" 
        className="w-full border-t rounded-none text-sm h-10 hover:bg-muted/20"
        onClick={onManageFolders}
      >
        Manage Folders
      </Button>
    </div>
  );
};
