
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '@/components/header';
import Footer from '@/components/Footer';
import { FileCheck } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import LazyImage from '@/components/ui/lazy-image';
import { formatDate } from '@/lib/utils';

const WSIB = () => {
  const { data: certificate, isLoading } = useQuery({
    queryKey: ['wsib-certificate-public'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('wsib_certificate')
        .select('*')
        .order('created_at', { ascending: false })
        .limit(1)
        .maybeSingle();
      
      if (error) {
        console.error('Error fetching WSIB certificate:', error);
        throw error;
      }
      
      return data;
    }
  });

  const isPDF = certificate?.file_url?.toLowerCase().endsWith('.pdf');

  return (
    <>
      <Helmet>
        <title>WSIB Certification | Fix My Brick</title>
        <meta name="description" content="View our Workplace Safety and Insurance Board (WSIB) certification, ensuring that we maintain the highest safety standards for all our masonry projects." />
      </Helmet>
      <div className="min-h-screen flex flex-col">
        <Header />
        <main className="flex-grow">
          <div className="container mx-auto px-4 py-12">
            <div className="max-w-4xl mx-auto">
              <h1 className="text-3xl md:text-4xl font-bold mb-6 text-D00B0E">WSIB Certification</h1>
              
              <div className="bg-white rounded-lg shadow-md overflow-hidden mb-8">
                <div className="p-6">
                  <div className="flex items-start space-x-4 mb-6">
                    <div className="rounded-full bg-red-50 p-3">
                      <FileCheck className="h-6 w-6 text-D00B0E" />
                    </div>
                    <div>
                      <h2 className="text-xl font-semibold mb-1">Workplace Safety and Insurance Board</h2>
                      <p className="text-gray-600">
                        Our WSIB certification demonstrates our commitment to workplace safety and compliance with Canadian safety regulations.
                      </p>
                    </div>
                  </div>

                  <div className="border-t border-gray-100 pt-6">
                    {isLoading ? (
                      <div className="flex justify-center items-center h-64 bg-gray-50 animate-pulse rounded-md">
                        <FileCheck className="h-16 w-16 text-gray-200" />
                      </div>
                    ) : certificate && certificate.file_url ? (
                      <div className="flex flex-col md:flex-row gap-8">
                        <div className="md:w-1/2">
                          <h3 className="text-lg font-medium mb-4">Our Current Certificate</h3>
                          {certificate.title && (
                            <p className="mb-2 font-medium">{certificate.title}</p>
                          )}
                          {certificate.description && (
                            <p className="text-gray-600 mb-4">{certificate.description}</p>
                          )}
                          {certificate.expiry_date && (
                            <p className="text-sm text-gray-500 mb-4">
                              Valid until: {formatDate(certificate.expiry_date)}
                            </p>
                          )}
                          <a 
                            href={certificate.file_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn-primary inline-flex items-center"
                          >
                            <FileCheck className="h-4 w-4 mr-2" />
                            {isPDF ? "View PDF Certificate" : "View Full Certificate"}
                          </a>
                        </div>
                        <div className="md:w-1/2">
                          {isPDF ? (
                            <div className="aspect-[3/4] bg-gray-50 flex flex-col items-center justify-center p-8 text-center border rounded-md">
                              <FileCheck className="h-16 w-16 text-D00B0E mb-4" />
                              <p className="text-gray-600 mb-2">PDF Certificate</p>
                              <a 
                                href={certificate.file_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn-outline mt-4 inline-flex items-center"
                              >
                                <FileCheck className="mr-2 h-4 w-4" />
                                Open PDF
                              </a>
                            </div>
                          ) : (
                            <div className="border rounded-md overflow-hidden">
                              <LazyImage 
                                src={certificate.file_url} 
                                alt="WSIB Certificate" 
                                className="w-full object-contain" 
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-center items-center h-64 bg-gray-50 rounded-md">
                        <p className="text-gray-500">Certificate information coming soon.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="prose max-w-none">
                <h2>What is WSIB?</h2>
                <p>
                  The Workplace Safety and Insurance Board (WSIB) is an independent agency of the Government of Ontario that provides no-fault collective liability insurance for workplaces in Ontario. WSIB coverage ensures that workers are protected in case of workplace injuries or illnesses.
                </p>
                
                <h2>Why WSIB Matters</h2>
                <p>
                  When you hire a contractor like Fix My Brick, verifying their WSIB certification is essential for several reasons:
                </p>
                <ul>
                  <li>It confirms that the company follows proper safety protocols</li>
                  <li>It protects you from liability should a worker be injured on your property</li>
                  <li>It demonstrates the company's commitment to worker safety and legal compliance</li>
                </ul>
                
                <h2>Our Commitment to Safety</h2>
                <p>
                  At Fix My Brick, we prioritize safety on every job site. Our valid WSIB certification is just one aspect of our comprehensive safety program that ensures our team members work in the safest environment possible while delivering exceptional masonry services.
                </p>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default WSIB;
