
import React from 'react';
import AdminLayout from '@/components/admin/AdminLayout';
import { Skeleton } from '@/components/ui/skeleton';
import { Button } from '@/components/ui/button';
import { ArrowLeft } from 'lucide-react';

interface LoadingStateProps {
  isNewLocation: boolean;
}

const LoadingState: React.FC<LoadingStateProps> = ({ isNewLocation }) => {
  return (
    <AdminLayout title={isNewLocation ? 'Add Location' : 'Edit Location'}>
      <div className="mb-6">
        <Button variant="outline" disabled>
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back to Locations
        </Button>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <div className="space-y-6">
          <div>
            <Skeleton className="h-5 w-40 mb-1" />
            <Skeleton className="h-10 w-full" />
          </div>
          
          <div>
            <Skeleton className="h-5 w-40 mb-1" />
            <Skeleton className="h-10 w-full" />
          </div>
          
          <div>
            <Skeleton className="h-5 w-32" />
          </div>
          
          <div>
            <Skeleton className="h-5 w-40 mb-1" />
            <Skeleton className="h-10 w-full" />
          </div>
          
          <div>
            <Skeleton className="h-5 w-40 mb-1" />
            <Skeleton className="h-32 w-full" />
          </div>
          
          <div className="flex justify-end">
            <Skeleton className="h-10 w-24 mr-2" />
            <Skeleton className="h-10 w-32" />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default LoadingState;
