
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AdminLayout from '@/components/admin/AdminLayout';
import { Button } from '@/components/ui/button';
import { ArrowLeft, Eye, Loader2 } from 'lucide-react';
import { useBlogPostForm } from '@/hooks/blog/useBlogPostForm';
import { BlogPostForm } from '@/components/admin/blog/BlogPostForm';

const AdminBlogPost = () => {
  const navigate = useNavigate();
  const {
    formData,
    seoData,
    isNewPost,
    isLoadingPost,
    handleInputChange,
    handleStatusChange,
    handleSeoChange,
    handleSubmit,
    createPostMutation,
    updatePostMutation
  } = useBlogPostForm();

  // Handle preview
  const handlePreview = () => {
    if (formData.slug) {
      window.open(`/blog/${formData.slug}`, '_blank');
    }
  };

  // Access the error state from the hook
  const postError = useBlogPostForm().postError;

  return (
    <AdminLayout title={isNewPost ? "Create New Blog Post" : "Edit Blog Post"}>
      <div className="mb-6">
        <div className="flex justify-between items-center mb-4">
          <Button 
            variant="outline" 
            onClick={() => navigate('/admin/blog/posts')}
          >
            <ArrowLeft className="mr-2 h-4 w-4" /> Back to Posts
          </Button>
          
          {!isNewPost && (
            <Button
              variant="outline"
              onClick={handlePreview}
            >
              <Eye className="mr-2 h-4 w-4" /> Preview
            </Button>
          )}
        </div>
        
        {isLoadingPost && !isNewPost ? (
          <div className="flex justify-center items-center p-20">
            <Loader2 className="h-8 w-8 animate-spin text-primary" />
          </div>
        ) : postError ? (
          <div className="flex flex-col items-center justify-center p-20 text-center">
            <div className="text-red-500 mb-4 text-lg font-semibold">
              Error loading blog post
            </div>
            <p className="text-muted-foreground mb-4">
              There was a problem loading this blog post. Please try again or contact support.
            </p>
            <Button 
              variant="outline" 
              onClick={() => window.location.reload()}
            >
              Try Again
            </Button>
          </div>
        ) : (
          <BlogPostForm 
            formData={formData}
            seoData={seoData}
            isNewPost={isNewPost}
            handleInputChange={handleInputChange}
            handleStatusChange={handleStatusChange}
            handleSeoChange={handleSeoChange}
            handleSubmit={handleSubmit}
            isSubmitting={createPostMutation.isPending || updatePostMutation.isPending}
            onCancel={() => navigate('/admin/blog/posts')}
          />
        )}
      </div>
    </AdminLayout>
  );
};

export default AdminBlogPost;
