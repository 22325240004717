
import { supabase } from './client';

// Types for WordPress API responses
export interface WPPost {
  id: number;
  date: string;
  slug: string;
  status: string;
  title: { rendered: string };
  content: { rendered: string };
  excerpt: { rendered: string };
  featured_media: number;
  categories: number[];
  tags: number[];
  _embedded?: any;
}

export interface WPCategory {
  id: number;
  name: string;
  slug: string;
  description: string;
  parent: number;
}

export interface WPTag {
  id: number;
  name: string;
  slug: string;
  description: string;
}

export interface WPMedia {
  id: number;
  source_url: string;
  alt_text: string;
  caption: { rendered: string };
}

export interface MigrationCredentials {
  id?: string;
  wp_url: string;
  api_key?: string;
  wp_username?: string;
  wp_password?: string;
}

export interface MigrationHistory {
  id?: string;
  migration_date?: string;
  status: 'success' | 'failed' | 'in-progress';
  posts_migrated: number;
  categories_migrated: number;
  tags_migrated: number;
  notes?: string;
}

// Save WordPress credentials
export const saveWPCredentials = async (credentials: MigrationCredentials) => {
  console.log('Attempting to save credentials:', credentials);
  
  // Make sure we have at least the required fields
  if (!credentials.wp_url) {
    throw new Error('WordPress URL is required');
  }
  
  // Prepare payload by removing undefined values and ensuring no empty strings
  const payload = {
    wp_url: credentials.wp_url.trim(),
    api_key: credentials.api_key?.trim() || null,
    wp_username: credentials.wp_username?.trim() || null,
    wp_password: credentials.wp_password?.trim() || null
  };

  // Only include ID if it exists and is not empty
  if (credentials.id) {
    payload['id'] = credentials.id;
  }
  
  try {
    // If we're updating an existing record
    if (credentials.id) {
      console.log('Updating existing credentials with ID:', credentials.id);
      const { data, error } = await supabase
        .from('wp_migration_credentials')
        .update(payload)
        .eq('id', credentials.id)
        .select('*')
        .single();

      if (error) {
        console.error('Supabase error updating credentials:', error);
        throw error;
      }
      
      console.log('Credentials updated successfully:', data);
      return data;
    } 
    // We're creating a new record
    else {
      console.log('Creating new credentials record with payload:', payload);
      const { data, error } = await supabase
        .from('wp_migration_credentials')
        .insert([payload])
        .select('*')
        .single();

      if (error) {
        console.error('Supabase error saving credentials:', error);
        throw error;
      }
      
      if (!data) {
        throw new Error('No data returned from credentials save operation');
      }
      
      console.log('Credentials saved successfully:', data);
      return data;
    }
  } catch (error) {
    console.error('Error in saveWPCredentials:', error);
    throw error;
  }
};

// Get WordPress credentials
export const getWPCredentials = async () => {
  try {
    const { data, error } = await supabase
      .from('wp_migration_credentials')
      .select('*')
      .maybeSingle();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error in getWPCredentials:', error);
    throw error;
  }
};

// Get migration history
export const getMigrationHistory = async () => {
  try {
    const { data, error } = await supabase
      .from('wp_migration_history')
      .select('*')
      .order('migration_date', { ascending: false });

    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Error in getMigrationHistory:', error);
    throw error;
  }
};
