
import { useState, useMemo, useCallback } from 'react';
import { useFranchiseOptions } from './useFranchiseOptions';
import { useImageStatusUpdate } from './useImageStatusUpdate';
import { usePagination } from './usePagination';
import { useFiltering } from './useFiltering';
import { useReactQueryFranchiseImages } from './useReactQueryFranchiseImages';
import { FranchiseImageFilters } from './types';

export const useFranchiseImagesTable = () => {
  // Manage all hooks with consistent rendering order
  const {
    currentPage,
    pageSize,
    handlePageChange,
    handlePageSizeChange
  } = usePagination();
  
  const {
    filters,
    handleFilterChange,
    clearFilters
  } = useFiltering();
  
  const { franchiseOptions, loading: loadingOptions } = useFranchiseOptions();
  
  const {
    approving,
    rejecting,
    updateImageStatus,
    isUpdating
  } = useImageStatusUpdate();
  
  // Use React Query for data fetching - only initiated once other hooks are stable
  const {
    images,
    totalCount,
    isLoading,
    isError,
    error,
    isFetching,
    refetch
  } = useReactQueryFranchiseImages(currentPage, pageSize, filters);
  
  // Memoize filter handling function to prevent unnecessary rerenders
  const applyFilters = useCallback((newFilters: FranchiseImageFilters) => {
    console.log('Applying filters:', newFilters);
    handleFilterChange(newFilters);
    // Reset to page 1 when filters change
    handlePageChange(1);
  }, [handleFilterChange, handlePageChange]);
  
  const clearAllFilters = useCallback(() => {
    clearFilters();
    handlePageChange(1);
  }, [clearFilters, handlePageChange]);
  
  // Calculate loading state to prevent UI flashing
  const loading = isLoading;
  
  // Calculate whether there's a network error
  const networkError = isError 
    ? (error as Error).message 
    : null;
  
  return {
    images,
    loading,
    error: networkError,
    currentPage,
    pageSize,
    totalCount,
    filters,
    franchiseOptions,
    approving,
    rejecting,
    handlePageChange,
    handlePageSizeChange,
    handleFilterChange: applyFilters,
    clearFilters: clearAllFilters,
    updateImageStatus,
    refetch,
    isFetching
  };
};
