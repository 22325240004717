import React from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '@/components/header';
import Footer from '@/components/Footer';
import PageMeta from '@/components/SEO/PageMeta';
import { Card, CardContent } from '@/components/ui/card';
import { Link } from 'react-router-dom';
import { Shield, Wrench, Award, User, CheckCircle } from 'lucide-react';
import ContactButton from '@/components/shared/ContactButton';

const About: React.FC = () => {
  // Create schema.org structured data for the About page
  const aboutSchema = {
    "@type": "AboutPage",
    "name": "About Fix My Brick",
    "description": "Learn about our masonry services company, our history, values, and expertise in brick repair and masonry work."
  };

  return (
    <div className="min-h-screen flex flex-col">
      <PageMeta 
        title="About Us | Fix My Brick"
        description="Your trusted partner in masonry restoration and repair, proudly serving Southern Ontario for over 20 years."
        schema={aboutSchema}
      />
      <Header />
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-50 to-white py-16 md:py-24">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <div className="inline-block bg-[#D00B0E]/10 px-4 py-1 rounded-full mb-4">
                <span className="text-[#D00B0E] font-medium">Our Story</span>
              </div>
              <h1 className="text-4xl md:text-5xl font-bold mb-4">About Fix My Brick</h1>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                Your trusted partner in masonry restoration and repair, proudly serving Southern Ontario for over 20 years.
              </p>
            </div>

            <div className="max-w-4xl mx-auto mb-20 bg-white p-8 rounded-lg shadow-md border-l-4 border-[#D00B0E]">
              <h2 className="text-3xl font-bold mb-6">Our Story</h2>
              <p className="text-gray-700 mb-4 leading-relaxed">
                Fix My Brick began as a small, family-run business with a simple goal: to bring reliable, quality masonry restoration to homeowners and property managers across Southern Ontario. Founded in the early 2000s, our passion for brick and mortar repair quickly grew into a trusted name in the industry. We set out to do more than just fix cracks and chipped bricks—we wanted to restore the original strength and character of every structure we touched. Today, we remain dedicated to that mission, continually evolving while staying true to our roots as a family-owned business.
              </p>
            </div>

            <div className="mb-20">
              <h2 className="text-3xl font-bold mb-10 text-center">Our Values</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <Card className="bg-white shadow-lg border-none hover:shadow-xl transition-shadow overflow-hidden">
                  <div className="bg-[#D00B0E] h-2"></div>
                  <CardContent className="p-8">
                    <div className="bg-[#D00B0E]/10 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                      <Shield className="text-[#D00B0E] w-6 h-6" />
                    </div>
                    <h3 className="text-xl font-semibold mb-3">Quality Craftsmanship</h3>
                    <p className="text-gray-700">
                      We believe that every repair should stand the test of time. Our in-house team uses the finest materials and proven techniques to ensure lasting results.
                    </p>
                  </CardContent>
                </Card>
                
                <Card className="bg-white shadow-lg border-none hover:shadow-xl transition-shadow overflow-hidden">
                  <div className="bg-[#D00B0E] h-2"></div>
                  <CardContent className="p-8">
                    <div className="bg-[#D00B0E]/10 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                      <CheckCircle className="text-[#D00B0E] w-6 h-6" />
                    </div>
                    <h3 className="text-xl font-semibold mb-3">Honesty & Integrity</h3>
                    <p className="text-gray-700">
                      Transparency is at the heart of our business. From clear pricing to open communication, we work closely with you to meet your expectations without surprises.
                    </p>
                  </CardContent>
                </Card>
                
                <Card className="bg-white shadow-lg border-none hover:shadow-xl transition-shadow overflow-hidden">
                  <div className="bg-[#D00B0E] h-2"></div>
                  <CardContent className="p-8">
                    <div className="bg-[#D00B0E]/10 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                      <User className="text-[#D00B0E] w-6 h-6" />
                    </div>
                    <h3 className="text-xl font-semibold mb-3">Customer-Centric Approach</h3>
                    <p className="text-gray-700">
                      Your satisfaction drives us. We treat your home like our own—ensuring a tidy worksite, minimal disruption, and a finished product that you can be proud of.
                    </p>
                  </CardContent>
                </Card>
              </div>
            </div>

            <div className="max-w-4xl mx-auto mb-20">
              <div className="bg-gray-50 p-10 rounded-lg">
                <div className="flex items-center justify-center bg-[#D00B0E] text-white w-16 h-16 rounded-full mx-auto mb-6">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </div>
                <h2 className="text-3xl font-bold mb-6 text-center">Our Team</h2>
                <p className="text-gray-700 mb-4 text-center leading-relaxed">
                  Our team of dedicated masonry professionals brings decades of experience to every project. We invest in continuous training and hold ourselves to the highest standards, ensuring that every repair and restoration reflects our commitment to quality and care. Whether it's a small repair or a full-scale restoration, our dedicated crew is committed to delivering the exceptional service you deserve.
                </p>
              </div>
            </div>

            <div className="mb-20">
              <h2 className="text-3xl font-bold mb-10 text-center">Why Choose Fix My Brick</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="flex items-start bg-white p-6 rounded-lg shadow-md">
                  <div className="bg-D00B0E text-white rounded-full p-3 mr-4 flex-shrink-0">
                    <Wrench className="h-6 w-6" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">In-House Expertise</h3>
                    <p className="text-gray-700">We never subcontract our work. Every project is handled by our own trained professionals, ensuring consistent quality and attention to detail.</p>
                  </div>
                </div>
                
                <div className="flex items-start bg-white p-6 rounded-lg shadow-md">
                  <div className="bg-D00B0E text-white rounded-full p-3 mr-4 flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Comprehensive Services</h3>
                    <p className="text-gray-700">From chimney repair to brick restoration and custom masonry design, we offer a full range of services tailored to your needs.</p>
                  </div>
                </div>
                
                <div className="flex items-start bg-white p-6 rounded-lg shadow-md">
                  <div className="bg-D00B0E text-white rounded-full p-3 mr-4 flex-shrink-0">
                    <Award className="h-6 w-6" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Proven Track Record</h3>
                    <p className="text-gray-700">With over two decades of experience and countless satisfied customers, our reputation is built on reliable service and lasting results.</p>
                  </div>
                </div>
                
                <div className="flex items-start bg-white p-6 rounded-lg shadow-md">
                  <div className="bg-D00B0E text-white rounded-full p-3 mr-4 flex-shrink-0">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4c-3.866 0-7 3.134-7 7 0 3.866 3.134 7 7 7 3.866 0 7-3.134 7-7 0-3.866-3.134-7-7-7zm0 11a4 4 0 100-8 4 4 0 000 8z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 0v2m0-2h2m-2 0H9" />
                    </svg>
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Personalized Service</h3>
                    <p className="text-gray-700">We believe in making a personal connection with every client, from your first inquiry to the final walkthrough. Your home is unique, and we treat it with the care it deserves.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-D00B0E text-white p-10 rounded-lg max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold mb-4 text-center">Get in Touch</h2>
              <p className="text-center mb-6">
                Ready to restore and enhance your property? Contact Fix My Brick today for a free, no-obligation consultation, and discover how our commitment to quality and integrity can transform your space.
              </p>
              <div className="text-center">
                <ContactButton className="bg-white text-D00B0E hover:bg-gray-100 font-bold py-3 px-8 rounded-md transition-colors" variant="outlined">
                  Get a Free Quote
                </ContactButton>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default About;
