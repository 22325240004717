import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchAllReviews } from '@/lib/supabase';
import { Star, MapPin } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import ReviewCard from '@/components/ui/review-card';

// Sample reviews data to use as fallback
const sampleReviews = [
  {
    reviewer_name: 'John Smith',
    reviewer_comment: 'Fix My Brick completely transformed our deteriorating chimney. Excellent craftsmanship and attention to detail!',
    star_rating: 'FIVE',
    reviewer_photo_url: '/placeholder.svg',
    location_id: 'toronto'
  },
  {
    reviewer_name: 'Sarah Johnson',
    reviewer_comment: 'We had several brick repairs done on our century home. Their team matched the original brick perfectly - you can\'t tell where the repairs were made!',
    star_rating: 'FIVE',
    reviewer_photo_url: '/placeholder.svg',
    location_id: 'mississauga'
  },
  {
    reviewer_name: 'Michael Wong',
    reviewer_comment: 'Professional service from estimate to completion. Fix My Brick restored our fireplace to its original beauty while ensuring it functions safely.',
    star_rating: 'FIVE',
    reviewer_photo_url: '/placeholder.svg',
    location_id: 'hamilton'
  },
  {
    reviewer_name: 'Jennifer Miller',
    reviewer_comment: 'The team rebuilt our front porch steps with remarkable skill. They were efficient, clean, and the results exceeded our expectations.',
    star_rating: 'FIVE',
    reviewer_photo_url: '/placeholder.svg',
    location_id: 'ottawa'
  }
];

const TestimonialsSection = () => {
  const { data: reviewsData, isLoading } = useQuery({
    queryKey: ['home-reviews'],
    queryFn: fetchAllReviews,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const { data: locations } = useQuery({
    queryKey: ['gmb-locations'],
    queryFn: async () => {
      const { fetchGMBLocations } = await import('@/lib/supabase');
      return fetchGMBLocations();
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const getStarRating = (rating: string): number => {
    if (rating === 'FIVE') return 5;
    if (rating === 'FOUR') return 4;
    if (rating === 'THREE') return 3;
    if (rating === 'TWO') return 2;
    if (rating === 'ONE') return 1;
    return 5;
  };

  const getLocationName = (locationId: string | null) => {
    if (!locationId || !locations) return 'Unknown Location';
    const location = locations.find(loc => loc.google_place_id === locationId);
    return location ? location.location_name : 'Unknown Location';
  };

  const filteredReviews = reviewsData?.filter(review => {
    const rating = getStarRating(review.star_rating);
    return rating >= 4 && review.reviewer_comment && review.reviewer_comment.trim() !== '';
  }) || [];

  // Use filtered reviews if available, otherwise use sample reviews
  const reviews = filteredReviews.length > 0 ? filteredReviews.slice(0, 4) : sampleReviews;

  return (
    <section className="py-12 md:py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">What Our Customers Say</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Hear from our satisfied clients across Ontario about their experience with our masonry services.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {reviews.map((review, index) => (
            <ReviewCard 
              key={index} 
              review={review} 
              showLocation={true}
              locationName={typeof review.location_id === 'string' ? getLocationName(review.location_id) : 'Ontario'}
              className="h-full"
            />
          ))}
        </div>

        <div className="mt-12 text-center">
          <Button 
            className="bg-[#B00A0D] hover:bg-[#A0090C] text-white"
            asChild
          >
            <a href="/reviews">Read More Reviews</a>
          </Button>
        </div>
      </div>
    </section>
  );
};

export { TestimonialsSection };
export default TestimonialsSection;
