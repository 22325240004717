
import React from 'react';
import AdminLayout from '@/components/admin/AdminLayout';
import { useGMBLocations } from '@/hooks/gmb-locations/useGMBLocations';
import { useGMBLocationFiltering } from '@/hooks/gmb-locations/useGMBLocationFiltering';
import { useGMBLocationForm } from '@/hooks/gmb-locations/useGMBLocationForm';
import { GMBLocationTable } from '@/components/admin/gmb-locations/GMBLocationTable';
import { GMBLocationToolbar } from '@/components/admin/gmb-locations/GMBLocationToolbar';
import { GMBLocationForm } from '@/components/admin/gmb-locations/GMBLocationForm';
import { useGMBLocationMutations } from '@/hooks/gmb-locations/useGMBLocationMutations';

const AdminGMBLocations = () => {
  // Get GMB locations data
  const { gmbLocations, websiteLocations, isLoading, getWebsiteLocationName } = useGMBLocations();
  
  // Search and filtering
  const { search, setSearch, filteredLocations } = useGMBLocationFiltering(gmbLocations);
  
  // Form state and handlers
  const { 
    formData,
    handleInputChange,
    isSubmitting,
    isDialogOpen,
    setIsDialogOpen,
    isEditMode,
    currentLocation,
    handleEdit,
    handleAddNew,
    handleSubmit
  } = useGMBLocationForm();

  // Delete mutation
  const { deleteGMBLocation } = useGMBLocationMutations();

  const handleDelete = async (locationId: string) => {
    if (window.confirm('Are you sure you want to delete this location?')) {
      await deleteGMBLocation.mutateAsync(locationId);
    }
  };

  return (
    <AdminLayout title="Google My Business Locations">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Manage GMB Locations</h2>
      </div>

      <GMBLocationToolbar
        search={search}
        onSearchChange={setSearch}
        onAddNew={handleAddNew}
      />

      {isLoading ? (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full"></div>
        </div>
      ) : (
        <GMBLocationTable
          locations={filteredLocations ?? []}
          onEdit={handleEdit}
          onDelete={handleDelete}
          getWebsiteLocationName={getWebsiteLocationName}
        />
      )}

      <GMBLocationForm
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        formData={formData}
        handleInputChange={handleInputChange}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        isEditing={isEditMode}
        websiteLocations={websiteLocations}
      />
    </AdminLayout>
  );
};

export default AdminGMBLocations;
