
import { useState } from 'react';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';

export const useGalleryDelete = (galleries: any[], setGalleries: React.Dispatch<React.SetStateAction<any[]>>) => {
  const [deleting, setDeleting] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState<string | null>(null);

  const handleDeleteGallery = async (id: string) => {
    if (confirmDeleteId === id) {
      try {
        setDeleting(true);
        const { error } = await supabase
          .from('galleries')
          .delete()
          .eq('id', id);

        if (error) {
          throw error;
        }

        setGalleries(galleries.filter(gallery => gallery.id !== id));
        toast.success('Gallery deleted successfully');
      } catch (error) {
        console.error('Error deleting gallery:', error);
        toast.error('Failed to delete gallery');
      } finally {
        setDeleting(false);
        setConfirmDeleteId(null);
      }
    } else {
      setConfirmDeleteId(id);
    }
  };

  return {
    deleting,
    confirmDeleteId,
    handleDeleteGallery,
    setConfirmDeleteId
  };
};
