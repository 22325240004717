
import { useState } from 'react';
import { toast } from 'sonner';
import { uploadFile, STORAGE_BUCKET } from '@/utils/imageUpload';

export const useBackgroundUpload = () => {
  const [uploadingBackground, setUploadingBackground] = useState(false);

  // Handle background image upload
  const handleBackgroundUpload = async (file: File): Promise<string | null> => {
    try {
      setUploadingBackground(true);

      // Validate file type
      if (!file.type.startsWith('image/')) {
        toast.error('Please upload an image file.');
        return null;
      }

      // Use our shared upload utility
      const uploadedUrl = await uploadFile(file, {
        bucket: STORAGE_BUCKET,
        prefix: 'hero-bg',
      });

      if (!uploadedUrl) {
        throw new Error('Failed to upload background image');
      }

      return uploadedUrl;
    } catch (error) {
      console.error('Error uploading hero background:', error);
      toast.error('Failed to upload hero background. Please try again.');
      return null;
    } finally {
      setUploadingBackground(false);
    }
  };

  return {
    uploadingBackground,
    setUploadingBackground,
    handleBackgroundUpload,
  };
};
