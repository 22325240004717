
import { useState } from 'react';
import { supabase } from '@/lib/supabase';

export const useGalleryPreview = () => {
  const [slideshowOpen, setSlideshowOpen] = useState(false);
  const [previewGalleryId, setPreviewGalleryId] = useState<string | null>(null);
  const [galleryTitle, setGalleryTitle] = useState<string | undefined>();
  const [franchiseRegion, setFranchiseRegion] = useState<string | null>(null);
  const [jobNumber, setJobNumber] = useState<number | null>(null);

  const openGalleryPreview = async (galleryId: string) => {
    try {
      const { data, error } = await supabase
        .from('galleries')
        .select('title, franchise_region, job_number')
        .eq('id', galleryId)
        .single();
        
      if (error) throw error;
      
      setPreviewGalleryId(galleryId);
      setGalleryTitle(data.title);
      setFranchiseRegion(data.franchise_region);
      setJobNumber(data.job_number);
      setSlideshowOpen(true);
    } catch (error) {
      console.error('Error getting gallery data for preview:', error);
      // Still open the slideshow even if we couldn't get the title
      setPreviewGalleryId(galleryId);
      setSlideshowOpen(true);
    }
  };

  const closeGalleryPreview = () => {
    setSlideshowOpen(false);
    setPreviewGalleryId(null);
    setGalleryTitle(undefined);
    setFranchiseRegion(null);
    setJobNumber(null);
  };

  return {
    slideshowOpen,
    previewGalleryId,
    galleryTitle,
    franchiseRegion,
    jobNumber,
    openGalleryPreview,
    closeGalleryPreview
  };
};
