
import { useState } from 'react';

export const useWordPressUrlTest = () => {
  const [testingUrl, setTestingUrl] = useState(false);
  const [urlTestResult, setUrlTestResult] = useState<{
    success: boolean;
    message: string;
  } | null>(null);

  const testWordPressUrl = async (url: string) => {
    if (!url) return;

    setTestingUrl(true);
    setUrlTestResult(null);

    try {
      // Simple client-side test for URL formatting and reachability
      const urlObj = new URL(url);
      const testUrl = urlObj.origin + '/wp-json/';

      try {
        const response = await fetch(testUrl, {
          method: 'HEAD',
          mode: 'no-cors', // This allows us to at least check if the URL is reachable
        });

        // Most likely the request was successful since we didn't get an exception
        setUrlTestResult({
          success: true,
          message: 'WordPress URL appears to be valid',
        });
      } catch (err) {
        // If we get here, there's likely a CORS issue, but the URL might still be valid
        setUrlTestResult({
          success: true,
          message:
            'URL is formatted correctly, but we cannot verify WordPress API access from the browser',
        });
      }
    } catch (err) {
      // Invalid URL format
      setUrlTestResult({
        success: false,
        message:
          'URL format is invalid. Please enter a complete URL including http:// or https://',
      });
    } finally {
      setTestingUrl(false);
    }
  };

  return {
    testingUrl,
    urlTestResult,
    testWordPressUrl
  };
};
