import React, { useRef, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { isCloudflareImageUrl, addCorsSupport } from '@/lib/imageUtils';
import LazyLoad from './lazy-load';
import { Skeleton } from './skeleton';

interface LazyImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
  height?: string | number;
  width?: string | number;
  className?: string;
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  fallbackSrc?: string;
}

const LazyImage = ({
  src,
  alt,
  height,
  width,
  className,
  objectFit = 'cover',
  fallbackSrc = '/placeholder.svg',
  ...props
}: LazyImageProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [imgSrc, setImgSrc] = React.useState<string>(src);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  // Update image source if prop changes
  React.useEffect(() => {
    if (src !== imgSrc && !hasError) {
      setImgSrc(src);
      setIsLoaded(false);
    }
  }, [src, imgSrc, hasError]);

  // Apply CORS handling for Cloudflare images
  React.useEffect(() => {
    if (imgRef.current && isCloudflareImageUrl(imgSrc)) {
      imgRef.current.crossOrigin = 'anonymous';
    }
  }, [imgSrc]);

  // Check if image is already loaded (could be cached)
  useEffect(() => {
    if (imgRef.current && imgRef.current.complete) {
      // Check if image has valid dimensions - if so, it's loaded
      if (imgRef.current.naturalWidth > 0) {
        setIsLoaded(true);
      }
    }
  }, [imgSrc]);

  const handleError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const target = event.target as HTMLImageElement;

    // Always log errors for troubleshooting the gallery image issue
    console.error(`Image load failed: ${imgSrc}`);
    console.error('Image error details:', {
      src: target.src,
      originalSrc: src,
      isCloudflareUrl: isCloudflareImageUrl(imgSrc),
      status: target.complete ? 'complete' : 'incomplete',
      naturalHeight: target.naturalHeight,
      naturalWidth: target.naturalWidth,
      error: (event as any).error?.message || 'Unknown error',
    });

    // Only set fallback if we're not already showing it
    if (imgSrc !== fallbackSrc) {
      setImgSrc(fallbackSrc);
      setHasError(true);
    }
  };

  const handleLoad = () => {
    setIsLoaded(true);
    setHasError(false);
  };

  // Force images to be visible after a timeout as a fallback
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isLoaded && imgRef.current) {
        // Check if image actually has dimensions but load event didn't fire
        if (imgRef.current.naturalWidth > 0) {
          setIsLoaded(true);
        }
      }
    }, 1000); // 1 second timeout
    
    return () => clearTimeout(timer);
  }, [isLoaded, imgSrc]);

  return (
    <LazyLoad
      height={height}
      width={width}
      placeholder={<Skeleton className={cn('h-full w-full', className)} />}>
      <div className={cn('relative', className)} style={{ height, width }}>
        <img
          ref={imgRef}
          src={imgSrc}
          alt={alt}
          className={cn(
            'transition-opacity duration-300',
            isLoaded ? 'opacity-100' : 'opacity-0',
            className
          )}
          style={{ objectFit, height: '100%', width: '100%' }}
          onLoad={handleLoad}
          onError={handleError}
          {...props}
        />
        {!isLoaded && (
          <div className='absolute inset-0'>
            <Skeleton className='h-full w-full' />
          </div>
        )}
      </div>
    </LazyLoad>
  );
};

export default LazyImage;
