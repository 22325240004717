
import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { SortField, SortDirection, ReviewStatus } from './types';

export const useFetchGalleries = (
  sortField: SortField,
  sortDirection: SortDirection,
  filterPublic: boolean | null,
  filterFranchise: string | null,
  filterReviewStatus: ReviewStatus | null,
  currentPage: number,
  pageSize: number
) => {
  const [galleries, setGalleries] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchGalleries();
    // When filters change, reset to first page
    // This effect is handled by the parent component
  }, [sortField, sortDirection, filterPublic, filterFranchise, filterReviewStatus]); 

  useEffect(() => {
    fetchGalleries();
  }, [currentPage, pageSize]);

  const fetchGalleries = async () => {
    try {
      setLoading(true);
      
      // First get the count for pagination
      let countQuery = supabase
        .from('galleries')
        .select('id', { count: 'exact', head: true });
      
      // Apply filters to count query
      if (filterPublic !== null) {
        countQuery = countQuery.eq('is_public', filterPublic);
      }
      
      if (filterFranchise !== null) {
        countQuery = countQuery.eq('franchise_region', filterFranchise);
      }

      // Apply review status filter if set
      if (filterReviewStatus !== null) {
        countQuery = countQuery.eq('review_status', filterReviewStatus);
      }

      const { count, error: countError } = await countQuery;
      
      if (countError) throw countError;
      
      setTotalCount(count || 0);

      // Then get the actual data with pagination
      let query = supabase
        .from('galleries')
        .select('*')
        .order(sortField, { ascending: sortDirection === 'asc', nullsFirst: sortField === 'franchise_region' ? false : undefined })
        .range((currentPage - 1) * pageSize, currentPage * pageSize - 1);
      
      // Apply public/private filter if set
      if (filterPublic !== null) {
        query = query.eq('is_public', filterPublic);
      }
      
      // Apply franchise filter if set
      if (filterFranchise !== null) {
        query = query.eq('franchise_region', filterFranchise);
      }
      
      // Apply review status filter if set
      if (filterReviewStatus !== null) {
        query = query.eq('review_status', filterReviewStatus);
      }
      
      const { data, error } = await query;

      if (error) {
        throw error;
      }

      setGalleries(data || []);
    } catch (error) {
      console.error('Error fetching galleries:', error);
      toast.error('Failed to load galleries');
    } finally {
      setLoading(false);
    }
  };

  return { galleries, loading, totalCount, fetchGalleries };
};
