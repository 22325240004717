import { useState, useEffect } from 'react';
import { Location } from '@/types/location';
import { generateSlug } from '@/utils/locationUtils';

export interface LocationFormData {
  name: string;
  slug: string;
  description: string;
  area_description?: string;
  meta_description: string;
  is_primary: boolean;
  parent_id: string | null;
  meta_title?: string;
  og_title?: string;
  og_description?: string;
  og_image?: string;
  canonical_url?: string;
  focus_keyword?: string;
  custom_title?: string;
  hero_image?: string;
  gallery_images?: string[];
}

export const DEFAULT_FORM_DATA: LocationFormData = {
  name: '',
  slug: '',
  description: '',
  area_description: '',
  meta_description: '',
  is_primary: false,
  parent_id: null,
  meta_title: '',
  og_title: '',
  og_description: '',
  og_image: '',
  canonical_url: '',
  focus_keyword: '',
  custom_title: '',
  hero_image: '',
  gallery_images: [],
};

export function useLocationFormState(location: Location | null, isNewLocation: boolean) {
  const [formData, setFormData] = useState<LocationFormData>(DEFAULT_FORM_DATA);

  // Effect to populate form data when a location is loaded
  useEffect(() => {
    if (isNewLocation) {
      setFormData(DEFAULT_FORM_DATA);
      return;
    }

    if (location) {
      setFormData({
        name: location.name,
        slug: location.slug,
        description: location.description || '',
        area_description: location.area_description || '',
        meta_description: location.meta_description || '',
        is_primary: !!location.is_primary,
        parent_id: location.parent_id || null,
        meta_title: location.meta_title || '',
        og_title: location.og_title || '',
        og_description: location.og_description || '',
        og_image: location.og_image || '',
        canonical_url: location.canonical_url || '',
        focus_keyword: location.focus_keyword || '',
        custom_title: location.custom_title || '',
        hero_image: location.hero_image || '',
        gallery_images: location.gallery_images || [],
      });
    }
  }, [location, isNewLocation]);

  // Effect to auto-generate slug from name for new locations
  useEffect(() => {
    if (isNewLocation && formData.name && !formData.slug) {
      setFormData(prev => ({
        ...prev,
        slug: generateSlug(prev.name)
      }));
    }
  }, [formData.name, isNewLocation]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    
    if (name === 'slug') {
      const slugValue = generateSlug(value);
      setFormData({ ...formData, [name]: slugValue });
    } else if (name === 'is_primary') {
      const checked = (e.target as HTMLInputElement).checked;
      setFormData({ ...formData, [name]: checked });
    } else if (name === 'parent_id') {
      const parentId = value === '' ? null : value;
      setFormData({ ...formData, [name]: parentId });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  return {
    formData,
    setFormData,
    handleChange
  };
}
