import React, { useState, useEffect } from 'react';
import AdminLayout from '@/components/admin/AdminLayout';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { ExternalLink, FileIcon, Mail, ForwardIcon, Share2 } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { 
  Breadcrumb, 
  BreadcrumbItem, 
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbList,
  BreadcrumbSeparator
} from "@/components/ui/breadcrumb";

interface Lead {
  id: string;
  client_name: string;
  client_email: string;
  client_phone: string;
  service_requested: string;
  submission_url: string;
  page_title: string;
  status: string;
  created_at: string;
  message?: string;
  service_street?: string;
  file_uploads?: string[];
}

const AdminLeads = () => {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [viewMessage, setViewMessage] = useState<{id: string, message: string} | null>(null);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [sendingEmail, setSendingEmail] = useState(false);
  const [isForwardToAdmin, setIsForwardToAdmin] = useState(false);
  const [adminEmails, setAdminEmails] = useState('info@fixmybrick.ca, hi@aaronbaker.co');

  useEffect(() => {
    fetchLeads();
  }, []);

  const fetchLeads = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('leads')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        throw error;
      }

      setLeads(data || []);
    } catch (error) {
      console.error('Error fetching leads:', error);
      toast.error('Failed to load leads');
    } finally {
      setLoading(false);
    }
  };

  const filteredLeads = leads.filter(lead => 
    (lead.client_name && lead.client_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (lead.client_email && lead.client_email.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (lead.service_requested && lead.service_requested.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const updateLeadStatus = async (id: string, newStatus: string) => {
    try {
      const { error } = await supabase
        .from('leads')
        .update({ status: newStatus })
        .eq('id', id);

      if (error) {
        throw error;
      }

      setLeads(leads.map(lead => 
        lead.id === id ? { ...lead, status: newStatus } : lead
      ));
      
      toast.success(`Lead status updated to ${newStatus}`);
    } catch (error) {
      console.error('Error updating lead status:', error);
      toast.error('Failed to update lead status');
    }
  };
  
  const openEmailDialog = (lead: Lead, isForward = false) => {
    setSelectedLead(lead);
    setIsForwardToAdmin(isForward);
    
    if (isForward) {
      setEmailSubject(`Lead from ${lead.client_name}: ${lead.service_requested || 'General Inquiry'}`);
      
      const messageBody = `
Lead Details:
--------------
Name: ${lead.client_name}
Email: ${lead.client_email}
Phone: ${lead.client_phone || 'Not provided'}
Service: ${lead.service_requested || 'General Inquiry'}
Address: ${lead.service_street || 'Not provided'}
Submitted: ${new Date(lead.created_at).toLocaleString()}
Page: ${lead.submission_url || 'Not available'}

Message:
${lead.message || 'No message provided'}

${lead.file_uploads && lead.file_uploads.length > 0 ? 
`Attachments: ${lead.file_uploads.length} file(s)
${lead.file_uploads.join('\n')}` : 'No attachments'}
`;
      
      setEmailMessage(messageBody);
    } else {
      setEmailSubject(`Re: Your inquiry about ${lead.service_requested || 'our services'}`);
      setEmailMessage(`Dear ${lead.client_name},\n\nThank you for your interest in Fix My Brick.\n\n`);
    }
    
    setEmailDialogOpen(true);
  };
  
  const sendEmail = async () => {
    if (!selectedLead) return;
    
    try {
      setSendingEmail(true);
      
      const response = await fetch('/api/send-lead-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          leadId: selectedLead.id,
          to: isForwardToAdmin ? adminEmails : selectedLead.client_email,
          subject: emailSubject,
          message: emailMessage,
          leadName: selectedLead.client_name,
          isForwardToAdmin: isForwardToAdmin,
        }),
      });
      
      const responseData = await response.json();
      
      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to send email');
      }
      
      if (!isForwardToAdmin) {
        await updateLeadStatus(selectedLead.id, 'contacted');
      }
      
      toast.success(isForwardToAdmin ? 'Lead forwarded to admin successfully' : 'Email sent successfully');
      setEmailDialogOpen(false);
      
      setEmailSubject('');
      setEmailMessage('');
      setSelectedLead(null);
      setIsForwardToAdmin(false);
    } catch (error) {
      console.error('Error sending email:', error);
      toast.error(`Failed to send email: ${(error as Error).message}`);
    } finally {
      setSendingEmail(false);
    }
  };

  const StatusBadge = ({ status }: { status: string }) => (
    <span className={`inline-block px-2 py-1 rounded-full text-xs font-medium ${
      status === 'new' ? 'bg-blue-100 text-blue-800' : 
      status === 'contacted' ? 'bg-yellow-100 text-yellow-800' : 
      status === 'completed' ? 'bg-green-100 text-green-800' : 
      status === 'canceled' ? 'bg-red-100 text-red-800' :
      'bg-gray-100 text-gray-800'
    }`}>
      {status && status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );

  const FileAttachments = ({ files }: { files?: string[] }) => {
    if (!files || files.length === 0) return null;
    
    return (
      <div className="mt-2">
        <p className="text-xs font-medium mb-1">Attachments:</p>
        <div className="flex flex-wrap gap-2">
          {files.map((fileUrl, index) => (
            <TooltipProvider key={index}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <a 
                    href={fileUrl} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex items-center gap-1 text-xs text-blue-600 hover:text-blue-800 hover:underline"
                  >
                    <FileIcon className="h-3 w-3" />
                    <span>File {index + 1}</span>
                    <ExternalLink className="h-3 w-3" />
                  </a>
                </TooltipTrigger>
                <TooltipContent>
                  <p>View {fileUrl.split('/').pop()}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>
      </div>
    );
  };

  const MessageViewer = ({ message }: { message?: string }) => {
    if (!message) return <span className="text-gray-500 italic">No message</span>;
    
    if (message.length > 50) {
      return (
        <div>
          <p className="text-sm truncate">{message.substring(0, 50)}...</p>
          <button 
            onClick={() => setViewMessage({id: 'tempId', message})}
            className="text-xs text-blue-600 hover:underline"
          >
            View full message
          </button>
        </div>
      );
    }
    
    return <p className="text-sm">{message}</p>;
  };

  const LeadCard = ({ lead }: { lead: Lead }) => (
    <Card className="mb-4">
      <CardContent className="pt-6">
        <div className="flex justify-between items-center mb-3">
          <div>
            <div className="text-sm font-medium">
              {new Date(lead.created_at).toLocaleDateString()}
            </div>
            <div className="text-xs text-muted-foreground">
              {new Date(lead.created_at).toLocaleTimeString()}
            </div>
          </div>
          <StatusBadge status={lead.status || 'new'} />
        </div>
        
        <h3 className="font-medium mb-1">{lead.client_name}</h3>
        <div className="text-sm mb-3">
          <div className="flex items-center justify-between">
            <div>{lead.client_email}</div>
            <button
              onClick={() => openEmailDialog(lead)}
              className="p-1 rounded-full hover:bg-blue-100 text-blue-600"
              title="Send email to this lead"
            >
              <Mail className="h-4 w-4" />
            </button>
          </div>
          <div className="text-muted-foreground">{lead.client_phone}</div>
          {lead.service_street && (
            <div className="text-muted-foreground">{lead.service_street}</div>
          )}
        </div>
        
        <div className="text-sm mb-3">
          <div className="font-medium">Service:</div>
          <div>{lead.service_requested || "General Inquiry"}</div>
        </div>

        {lead.message && (
          <div className="text-sm mb-3">
            <div className="font-medium">Message:</div>
            <MessageViewer message={lead.message} />
          </div>
        )}
        
        <FileAttachments files={lead.file_uploads} />
        
        <div className="text-xs text-muted-foreground mb-4 truncate">
          <div title={lead.submission_url}>{lead.submission_url}</div>
          <div title={lead.page_title}>{lead.page_title}</div>
        </div>
        
        <div className="flex justify-between items-center">
          <button
            onClick={() => openEmailDialog(lead, true)}
            className="text-xs flex items-center gap-1 px-2 py-1 rounded border border-gray-300 hover:bg-gray-100"
            title="Forward lead to admin"
          >
            <Share2 className="h-3 w-3" />
            <span>Forward to Admin</span>
          </button>
          
          <select 
            className="text-xs p-1 border rounded"
            onChange={(e) => updateLeadStatus(lead.id, e.target.value)}
            value={lead.status || 'new'}
          >
            <option value="new">New</option>
            <option value="contacted">Contacted</option>
            <option value="completed">Completed</option>
            <option value="canceled">Canceled</option>
          </select>
        </div>
      </CardContent>
    </Card>
  );

  return (
    <AdminLayout title="Leads Management">
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/admin">Dashboard</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>Leads</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="mb-4 flex items-center justify-between flex-wrap gap-2">
        <Input
          type="text"
          placeholder="Search leads..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="max-w-md"
        />
        <Button onClick={fetchLeads}>Refresh</Button>
      </div>

      {viewMessage && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-lg p-6 max-w-2xl w-full max-h-[80vh] overflow-auto">
            <h3 className="text-lg font-bold mb-4">Message</h3>
            <div className="whitespace-pre-wrap mb-6">{viewMessage.message}</div>
            <div className="flex justify-end">
              <Button onClick={() => setViewMessage(null)}>Close</Button>
            </div>
          </div>
        </div>
      )}
      
      <Dialog open={emailDialogOpen} onOpenChange={setEmailDialogOpen}>
        <DialogContent className="max-w-2xl">
          <DialogHeader>
            <DialogTitle>
              {isForwardToAdmin 
                ? `Forward Lead: ${selectedLead?.client_name}` 
                : `Send Email to ${selectedLead?.client_name}`}
            </DialogTitle>
            <DialogDescription>
              {isForwardToAdmin 
                ? "This will forward the lead details to admin email addresses." 
                : "This email will be sent directly to the lead's email address."}
            </DialogDescription>
          </DialogHeader>
          
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <label htmlFor="to" className="text-right font-medium">
                To:
              </label>
              <div className="col-span-3">
                {isForwardToAdmin ? (
                  <Input 
                    id="adminEmails" 
                    value={adminEmails} 
                    onChange={(e) => setAdminEmails(e.target.value)}
                    placeholder="Comma-separated admin email addresses" 
                  />
                ) : (
                  <Input 
                    id="to" 
                    value={selectedLead?.client_email || ''} 
                    disabled 
                  />
                )}
              </div>
            </div>
            
            <div className="grid grid-cols-4 items-center gap-4">
              <label htmlFor="subject" className="text-right font-medium">
                Subject:
              </label>
              <div className="col-span-3">
                <Input 
                  id="subject" 
                  value={emailSubject} 
                  onChange={(e) => setEmailSubject(e.target.value)} 
                />
              </div>
            </div>
            
            <div className="grid grid-cols-4 items-start gap-4">
              <label htmlFor="message" className="text-right font-medium mt-2">
                Message:
              </label>
              <div className="col-span-3">
                <textarea 
                  id="message" 
                  className="w-full min-h-[200px] p-2 border rounded-md" 
                  value={emailMessage} 
                  onChange={(e) => setEmailMessage(e.target.value)} 
                />
              </div>
            </div>
          </div>
          
          <DialogFooter>
            <Button variant="outline" onClick={() => setEmailDialogOpen(false)}>
              Cancel
            </Button>
            <Button 
              onClick={sendEmail} 
              disabled={sendingEmail || !emailSubject || !emailMessage || (isForwardToAdmin && !adminEmails)}
            >
              {sendingEmail 
                ? 'Sending...' 
                : isForwardToAdmin 
                  ? 'Forward to Admin' 
                  : 'Send Email'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Card>
        <CardHeader>
          <CardTitle>Service Leads</CardTitle>
        </CardHeader>
        <CardContent>
          {loading ? (
            <div className="py-10 text-center">Loading leads...</div>
          ) : (
            <>
              <div className="md:hidden">
                {filteredLeads.length > 0 ? (
                  filteredLeads.map((lead) => (
                    <LeadCard key={lead.id} lead={lead} />
                  ))
                ) : (
                  <div className="text-center py-4 text-muted-foreground">No leads found.</div>
                )}
              </div>
              
              <div className="hidden md:block overflow-x-auto">
                <Table>
                  <TableCaption>A list of all service leads.</TableCaption>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[120px]">Date</TableHead>
                      <TableHead>Name</TableHead>
                      <TableHead>Contact</TableHead>
                      <TableHead>Service</TableHead>
                      <TableHead>Message</TableHead>
                      <TableHead>Files</TableHead>
                      <TableHead>Page Info</TableHead>
                      <TableHead>Status</TableHead>
                      <TableHead className="w-[100px]">Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredLeads.length > 0 ? (
                      filteredLeads.map((lead) => (
                        <TableRow key={lead.id}>
                          <TableCell className="font-medium">
                            <div>{new Date(lead.created_at).toLocaleDateString()}</div>
                            <div className="text-xs text-muted-foreground">
                              {new Date(lead.created_at).toLocaleTimeString()}
                            </div>
                          </TableCell>
                          <TableCell>{lead.client_name}</TableCell>
                          <TableCell>
                            <div className="flex items-center gap-2">
                              <span>{lead.client_email}</span>
                              <button
                                onClick={() => openEmailDialog(lead)}
                                className="p-1 rounded-full hover:bg-blue-100 text-blue-600"
                                title="Send email to this lead"
                              >
                                <Mail className="h-4 w-4" />
                              </button>
                            </div>
                            <div className="text-sm text-muted-foreground">{lead.client_phone}</div>
                            {lead.service_street && (
                              <div className="text-sm text-muted-foreground">{lead.service_street}</div>
                            )}
                          </TableCell>
                          <TableCell>{lead.service_requested || "General Inquiry"}</TableCell>
                          <TableCell className="max-w-[200px]">
                            {lead.message && <MessageViewer message={lead.message} />}
                          </TableCell>
                          <TableCell>
                            <FileAttachments files={lead.file_uploads} />
                          </TableCell>
                          <TableCell className="max-w-[200px] truncate">
                            <div title={lead.submission_url} className="truncate">{lead.submission_url}</div>
                            <div title={lead.page_title} className="text-sm text-muted-foreground truncate">{lead.page_title}</div>
                          </TableCell>
                          <TableCell>
                            <StatusBadge status={lead.status || 'new'} />
                          </TableCell>
                          <TableCell>
                            <div className="flex flex-col gap-2">
                              <select 
                                className="text-xs p-1 border rounded"
                                onChange={(e) => updateLeadStatus(lead.id, e.target.value)}
                                value={lead.status || 'new'}
                              >
                                <option value="new">New</option>
                                <option value="contacted">Contacted</option>
                                <option value="completed">Completed</option>
                                <option value="canceled">Canceled</option>
                              </select>

                              <button
                                onClick={() => openEmailDialog(lead, true)}
                                className="text-xs flex items-center gap-1 px-2 py-1 rounded border border-gray-300 hover:bg-gray-100"
                                title="Forward lead to admin"
                              >
                                <Share2 className="h-3 w-3" />
                                <span>Forward</span>
                              </button>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9} className="text-center">No leads found.</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </AdminLayout>
  );
};

export default AdminLeads;
