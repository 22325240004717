import { useEffect } from 'react';
import { useTracking } from '@/components/tracking/TrackingProvider';
import { dataLayer } from '@/utils/dataLayer';

// Form tracking hook
export const useFormTracking = (formId: string, formName?: string) => {
  const { isInitialized } = useTracking();

  useEffect(() => {
    if (isInitialized) {
      // Track form view when component mounts
      dataLayer.trackForm('form_view', {
        form_id: formId,
        form_name: formName || formId,
      });
    }
  }, [isInitialized, formId, formName]);

  const trackFormSubmit = (additionalData?: Record<string, any>) => {
    if (isInitialized) {
      dataLayer.trackForm('form_submit', {
        form_id: formId,
        form_name: formName || formId,
        ...additionalData,
      });
    }
  };

  const trackFormError = (errorMessage: string) => {
    if (isInitialized) {
      dataLayer.trackForm('form_error', {
        form_id: formId,
        form_name: formName || formId,
        error_message: errorMessage,
      });
    }
  };

  return {
    trackFormSubmit,
    trackFormError,
  };
};

// Interaction tracking hook for buttons, links, CTAs
export const useInteractionTracking = () => {
  const { isInitialized } = useTracking();

  const trackButtonClick = (
    buttonText: string,
    buttonLocation: string,
    additionalData?: Record<string, any>
  ) => {
    if (isInitialized) {
      dataLayer.trackClick('button_click', {
        element_text: buttonText,
        element_location: buttonLocation,
        ...additionalData,
      });
    }
  };

  const trackCTAClick = (
    ctaText: string,
    destination: string,
    ctaLocation: string,
    additionalData?: Record<string, any>
  ) => {
    if (isInitialized) {
      dataLayer.trackClick('cta_click', {
        element_text: ctaText,
        element_destination: destination,
        element_location: ctaLocation,
        ...additionalData,
      });
    }
  };

  const trackPhoneCall = (
    phoneNumber: string,
    callSource: string,
    additionalData?: Record<string, any>
  ) => {
    if (isInitialized) {
      dataLayer.trackCall({
        phone_number: phoneNumber,
        call_source: callSource,
        ...additionalData,
      });
    }
  };

  return {
    trackButtonClick,
    trackCTAClick,
    trackPhoneCall,
  };
};

// Content tracking hook for services, galleries, locations
export const useContentTracking = () => {
  const { isInitialized } = useTracking();

  const trackServiceView = (
    serviceId: string,
    serviceName: string,
    additionalData?: Record<string, any>
  ) => {
    if (isInitialized) {
      dataLayer.trackContentView('service_view', {
        content_id: serviceId,
        content_name: serviceName,
        content_type: 'service',
        ...additionalData,
      });
    }
  };

  const trackGalleryView = (
    galleryId: string,
    galleryName: string,
    additionalData?: Record<string, any>
  ) => {
    if (isInitialized) {
      dataLayer.trackContentView('gallery_view', {
        content_id: galleryId,
        content_name: galleryName,
        content_type: 'gallery',
        ...additionalData,
      });
    }
  };

  const trackLocationView = (
    locationId: string,
    locationName: string,
    additionalData?: Record<string, any>
  ) => {
    if (isInitialized) {
      dataLayer.trackContentView('location_view', {
        content_id: locationId,
        content_name: locationName,
        content_type: 'location',
        ...additionalData,
      });
    }
  };

  return {
    trackServiceView,
    trackGalleryView,
    trackLocationView,
  };
};

// Scroll depth tracking
export const useScrollTracking = (thresholds: number[] = [25, 50, 75, 90]) => {
  const { isInitialized } = useTracking();

  useEffect(() => {
    if (!isInitialized) return;

    // Track scroll depth
    const trackedThresholds = new Set<number>();
    const pageHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );

    const onScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const scrollPercentage = Math.round((scrollPosition / pageHeight) * 100);

      // Check if we've crossed any of our thresholds
      for (const threshold of thresholds) {
        if (!trackedThresholds.has(threshold) && scrollPercentage >= threshold) {
          trackedThresholds.add(threshold);
          dataLayer.trackScrollDepth({
            scroll_depth: threshold,
            scroll_unit: 'percent',
          });
        }
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isInitialized, thresholds]);
};