
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from '@/components/ui/table';
import { Edit, Eye, CheckCircle, Clock, XCircle } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';

interface StaticPage {
  id: string;
  title: string;
  slug: string;
  status: string;
  created_at: string;
  updated_at: string;
}

interface PagesTableProps {
  pages: StaticPage[];
  loading: boolean;
}

const PagesTable: React.FC<PagesTableProps> = ({ pages, loading }) => {
  const navigate = useNavigate();
  
  const handleEdit = (id: string) => {
    navigate(`/admin/edit-page/${id}`);
  };

  const handleView = (slug: string) => {
    window.open(`/${slug}`, '_blank');
  };
  
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleStatusChange = async (id: string, newStatus: string) => {
    try {
      const { error } = await supabase
        .from('static_pages')
        .update({ status: newStatus })
        .eq('id', id);
      
      if (error) {
        throw error;
      }
      
      toast.success(`Page status updated to ${newStatus}`);
      
      // Update local state without requiring a refresh
      const updatedPages = pages.map(page => 
        page.id === id ? { ...page, status: newStatus } : page
      );
      
      // This will trigger a re-render with the updated status
      // We're modifying the reference directly which isn't ideal
      // but it works for simple UI updates without requiring a refetch
      Object.assign(pages, updatedPages);
      
    } catch (error) {
      console.error('Error updating page status:', error);
      toast.error('Failed to update page status');
    }
  };
  
  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'published':
        return <CheckCircle className="h-4 w-4 text-green-500" />;
      case 'draft':
        return <Clock className="h-4 w-4 text-amber-500" />;
      case 'archived':
        return <XCircle className="h-4 w-4 text-gray-500" />;
      default:
        return <CheckCircle className="h-4 w-4 text-green-500" />;
    }
  };
  
  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full"></div>
      </div>
    );
  }
  
  // Mobile card for each page
  const MobilePageCard = ({ page }: { page: StaticPage }) => (
    <Card className="mb-4">
      <CardContent className="pt-6">
        <div className="flex justify-between items-start mb-2">
          <div className="flex items-center">
            {getStatusIcon(page.status)}
            <h3 className="font-medium ml-2">{page.title}</h3>
          </div>
          <div className="flex space-x-1">
            <Button 
              variant="ghost" 
              size="sm" 
              onClick={() => handleView(page.slug)}
              className="h-8 w-8 p-0"
            >
              <Eye className="h-4 w-4" />
            </Button>
            <Button 
              variant="ghost" 
              size="sm" 
              onClick={() => handleEdit(page.id)}
              className="h-8 w-8 p-0"
            >
              <Edit className="h-4 w-4" />
            </Button>
          </div>
        </div>
        <div className="text-sm text-muted-foreground">/{page.slug}</div>
        <div className="grid grid-cols-2 gap-2 mt-3 text-xs">
          <div>
            <div className="text-muted-foreground">Created</div>
            <div>{formatDate(page.created_at)}</div>
          </div>
          <div>
            <div className="text-muted-foreground">Updated</div>
            <div>{formatDate(page.updated_at)}</div>
          </div>
        </div>
        <div className="mt-3">
          <div className="text-xs text-muted-foreground mb-1">Status</div>
          <Select
            value={page.status || 'published'}
            onValueChange={(value) => handleStatusChange(page.id, value)}
          >
            <SelectTrigger className="h-8 text-xs">
              <SelectValue placeholder="Select Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="published">Published</SelectItem>
              <SelectItem value="draft">Draft</SelectItem>
              <SelectItem value="archived">Archived</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </CardContent>
    </Card>
  );
  
  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      {/* Mobile view */}
      <div className="md:hidden p-4">
        {pages.length === 0 ? (
          <div className="text-center py-8 text-gray-500">
            No pages found. Create your first page to get started.
          </div>
        ) : (
          pages.map((page) => (
            <MobilePageCard key={page.id} page={page} />
          ))
        )}
      </div>
      
      {/* Desktop view */}
      <div className="hidden md:block overflow-x-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Title</TableHead>
              <TableHead>Slug</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Created</TableHead>
              <TableHead>Updated</TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {pages.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} className="text-center py-8 text-gray-500">
                  No pages found. Create your first page to get started.
                </TableCell>
              </TableRow>
            ) : (
              pages.map((page) => (
                <TableRow key={page.id}>
                  <TableCell className="font-medium">
                    <div className="flex items-center">
                      {getStatusIcon(page.status)}
                      <span className="ml-2">{page.title}</span>
                    </div>
                  </TableCell>
                  <TableCell>/{page.slug}</TableCell>
                  <TableCell>
                    <Select
                      value={page.status || 'published'}
                      onValueChange={(value) => handleStatusChange(page.id, value)}
                    >
                      <SelectTrigger className="h-8 w-[120px]">
                        <SelectValue placeholder="Status" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="published">Published</SelectItem>
                        <SelectItem value="draft">Draft</SelectItem>
                        <SelectItem value="archived">Archived</SelectItem>
                      </SelectContent>
                    </Select>
                  </TableCell>
                  <TableCell>{formatDate(page.created_at)}</TableCell>
                  <TableCell>{formatDate(page.updated_at)}</TableCell>
                  <TableCell className="text-right">
                    <Button 
                      variant="ghost" 
                      size="sm" 
                      onClick={() => handleView(page.slug)}
                      className="mr-2"
                    >
                      <Eye className="h-4 w-4" />
                    </Button>
                    <Button 
                      variant="ghost" 
                      size="sm" 
                      onClick={() => handleEdit(page.id)}
                    >
                      <Edit className="h-4 w-4" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default PagesTable;
