
import React from 'react';
import { ArrowRight } from 'lucide-react';
import { TrackedButton } from '@/components/ui/tracked-button';
import { PhoneButton } from '@/components/ui/phone-button';
import { useServiceTracking } from '@/lib/tracking/hooks/useServiceTracking';

interface ServiceCTAProps {
  service: {
    id: string; // Updated to match the ServiceDetail interface
    title: string;
    slug: string;
    cta_text: string;
  };
}

const ServiceCTA: React.FC<ServiceCTAProps> = ({ service }) => {
  const { trackServiceQuoteRequest, trackServicePhoneCall } =
    useServiceTracking({
      serviceId: service.id,
      serviceName: service.title,
      serviceSlug: service.slug,
    });

  const handleQuoteClick = () => {
    trackServiceQuoteRequest('service_cta', {
      cta_position: 'bottom',
      cta_type: 'primary',
    });
  };

  const handlePhoneClick = () => {
    trackServicePhoneCall('service_cta', {
      cta_position: 'bottom',
      cta_type: 'secondary',
    });
  };

  return (
    <section className='py-20 pb-28 md:pb-20 bg-gradient-to-br from-primary to-primary/90 text-white'>
      <div className='container mx-auto px-4 text-center'>
        <h2 className='text-2xl md:text-3xl font-bold mb-6'>
          Ready to Get Started?
        </h2>
        <p className='text-lg max-w-3xl mx-auto mb-10 leading-relaxed opacity-90'>
          {service.cta_text}
        </p>
        <div className='flex flex-col sm:flex-row justify-center gap-4 max-w-md mx-auto'>
          <TrackedButton
            size='lg'
            className='bg-white text-primary hover:bg-gray-100 h-14 px-8 text-lg shadow-lg w-full'
            onClick={handleQuoteClick}
            asChild>
            <a href='#contact'>
              Request a Quote <ArrowRight className='ml-2 h-5 w-5' />
            </a>
          </TrackedButton>

          <PhoneButton
            phoneNumber='9058070404'
            displayNumber='(905) 807-0404'
            variant='white'
            size='lg'
            fullWidth
            onClick={handlePhoneClick}
            className='shadow-lg'
          />
        </div>
      </div>
    </section>
  );
};

export default ServiceCTA;
