import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Upload, Folder, X, Loader2, LogIn } from 'lucide-react';
import { Progress } from '@/components/ui/progress';
import { Card, CardContent } from '@/components/ui/card';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useAssetManager, AssetFolder } from '@/hooks/useAssetManager';
import { FileIcon } from 'lucide-react';
import { toast } from 'sonner';
import { supabase } from '@/lib/supabase/client';

interface AssetUploaderProps {
  onComplete?: () => void;
  currentFolderId?: string | null;
}

export const AssetUploader: React.FC<AssetUploaderProps> = ({
  onComplete,
  currentFolderId,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>(
    {}
  );
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(
    currentFolderId || null
  );
  const [newFolderName, setNewFolderName] = useState('');
  const [showNewFolderInput, setShowNewFolderInput] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  const { uploadAsset, folders, createFolder, refreshAssets } =
    useAssetManager();

  // Check authentication status on mount
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await supabase.auth.getSession();
        setIsAuthenticated(!!data.session);
      } catch (error) {
        console.error('Error checking auth status:', error);
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  // Login helper
  const handleLogin = async () => {
    try {
      // Redirect to login page
      window.location.href = '/admin/login';
    } catch (error) {
      console.error('Login error:', error);
      toast.error('Failed to initiate login');
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      setFiles((prev) => [...prev, ...newFiles]);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      const newFiles = Array.from(e.dataTransfer.files);
      setFiles((prev) => [...prev, ...newFiles]);
    }
  };

  const removeFile = (index: number) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleCreateFolder = async () => {
    if (!newFolderName) return;

    await createFolder(newFolderName, selectedFolderId);
    setNewFolderName('');
    setShowNewFolderInput(false);
  };

  const handleUpload = async () => {
    if (files.length === 0) return;

    // Check authentication first
    if (!isAuthenticated) {
      toast.error('You need to be logged in to upload files');
      return;
    }

    setIsUploading(true);
    const newProgress: Record<string, number> = {};
    files.forEach((file) => {
      newProgress[file.name] = 0;
    });
    setUploadProgress(newProgress);

    try {
      // Upload files sequentially
      for (const file of files) {
        try {
          await uploadAsset(file, selectedFolderId || undefined);

          // Update progress after each file
          setUploadProgress((prev) => ({
            ...prev,
            [file.name]: 100,
          }));
        } catch (fileError) {
          console.error(`Error uploading ${file.name}:`, fileError);
          toast.error(
            `Failed to upload ${file.name}. ${getErrorMessage(fileError)}`
          );

          // Mark this file as failed but continue with others
          setUploadProgress((prev) => ({
            ...prev,
            [file.name]: -1, // -1 can indicate error state
          }));
        }
      }

      refreshAssets();

      // Check if any files failed
      const hasFailures = Object.values(uploadProgress).some(
        (progress) => progress === -1
      );
      if (hasFailures) {
        toast.warning(
          "Some files couldn't be uploaded. Please check the error messages."
        );
      } else {
        toast.success('All files uploaded successfully!');
        setFiles([]);
        setIsOpen(false);
      }

      if (onComplete) onComplete();
    } catch (error) {
      console.error('Upload process failed:', error);
      toast.error(`Upload process failed: ${getErrorMessage(error)}`);
    } finally {
      setIsUploading(false);
    }
  };

  // Helper function to extract meaningful error messages
  const getErrorMessage = (error: any): string => {
    if (!error) return 'Unknown error';

    if (typeof error === 'string') return error;

    // Handle Supabase errors
    if (error.message) {
      if (
        error.message.includes('storage limit') ||
        error.message.includes('quota')
      ) {
        return 'Storage quota exceeded.';
      }
      if (error.message.includes('too large')) {
        return 'File is too large.';
      }
      if (error.message.includes('permission')) {
        return 'Permission denied.';
      }

      return error.message;
    }

    if (error.error_description) return error.error_description;

    return 'An unexpected error occurred';
  };

  // Helper function to render folder hierarchy with proper indentation
  const renderFolderOptions = (
    folders: AssetFolder[],
    parentId: string | null = null,
    level = 0
  ) => {
    const indent = '\u00A0\u00A0\u00A0'.repeat(level);

    return folders
      .filter((folder) => folder.parent_id === parentId)
      .map((folder) => (
        <div key={folder.id} className='w-full'>
          <SelectItem value={folder.id}>
            {indent}
            {folder.name}
          </SelectItem>
          {renderFolderOptions(folders, folder.id, level + 1)}
        </div>
      ));
  };

  return (
    <>
      {isAuthenticated === false ? (
        <Button onClick={handleLogin} className='gap-2' variant='outline'>
          <LogIn size={16} />
          Login to Upload
        </Button>
      ) : (
        <Button 
          onClick={() => setIsOpen(true)} 
          className='gap-2 asset-upload-trigger'
        >
          <Upload size={16} />
          Upload Assets
        </Button>
      )}

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className='max-w-2xl'>
          <DialogHeader>
            <DialogTitle>Upload Assets</DialogTitle>
            <DialogDescription>
              Upload files to your asset library. Select a folder and drag files
              here.
            </DialogDescription>
          </DialogHeader>

          <div className='mb-4'>
            <Label htmlFor='folder-select'>Save to folder</Label>
            <div className='flex gap-2 mt-2'>
              <Select
                value={selectedFolderId || 'root'}
                onValueChange={(value) =>
                  setSelectedFolderId(value === 'root' ? null : value)
                }>
                <SelectTrigger className='w-full'>
                  <SelectValue placeholder='Root folder' />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value='root'>Root folder</SelectItem>
                  {renderFolderOptions(folders)}
                </SelectContent>
              </Select>

              <Button
                variant='outline'
                size='icon'
                onClick={() => setShowNewFolderInput(!showNewFolderInput)}>
                <Folder size={16} />
              </Button>
            </div>
          </div>

          {showNewFolderInput && (
            <div className='mb-4 flex gap-2'>
              <Input
                placeholder='New folder name'
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
              />
              <Button onClick={handleCreateFolder}>Create</Button>
            </div>
          )}

          <Card
            className='border-dashed border-2 cursor-pointer h-40 hover:border-primary/50 transition-colors relative overflow-hidden group'
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
            onClick={() => fileInputRef.current?.click()}>
            <div className='absolute inset-0 bg-primary/5 opacity-0 group-hover:opacity-100 transition-opacity'></div>
            <CardContent className='flex flex-col items-center justify-center h-full'>
              <div className='bg-primary/10 rounded-full p-4 mb-3'>
                <Upload className='h-6 w-6 text-primary' />
              </div>
              <p className='font-medium text-sm mb-1'>
                Drag and drop files here
              </p>
              <p className='text-xs text-muted-foreground'>
                or <span className='text-primary font-medium'>browse</span> to
                upload
              </p>
              <p className='text-xs text-muted-foreground mt-4 max-w-xs text-center'>
                Supported formats: Images, PDF, Word, Excel, and more
              </p>
              <input
                type='file'
                ref={fileInputRef}
                onChange={handleFileChange}
                multiple
                className='hidden'
                accept='image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              />
            </CardContent>
          </Card>

          {files.length > 0 && (
            <div className='mt-4'>
              <div className='flex items-center justify-between mb-2'>
                <h3 className='font-medium'>Selected Files ({files.length})</h3>
                {!isUploading && (
                  <Button
                    variant='ghost'
                    size='sm'
                    onClick={() => setFiles([])}
                    className='text-xs'>
                    Clear all
                  </Button>
                )}
              </div>
              <div className='max-h-48 overflow-y-auto space-y-2 border rounded-md p-2'>
                {files.map((file, index) => (
                  <div
                    key={`${file.name}-${index}`}
                    className={`flex items-center justify-between p-2 rounded-md ${
                      uploadProgress[file.name] === -1
                        ? 'bg-red-50'
                        : 'bg-muted/30'
                    }`}>
                    <div className='flex items-center gap-2 truncate'>
                      {file.type.startsWith('image/') ? (
                        <div className='w-8 h-8 rounded overflow-hidden bg-background flex-shrink-0'>
                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            className='w-full h-full object-cover'
                          />
                        </div>
                      ) : (
                        <FileIcon className='h-5 w-5 text-muted-foreground flex-shrink-0' />
                      )}
                      <div className='overflow-hidden'>
                        <div className='truncate max-w-[200px] text-sm'>
                          {file.name}
                        </div>
                        <div className='text-xs text-muted-foreground'>
                          {(file.size / 1024).toFixed(0)}KB
                        </div>
                      </div>
                    </div>

                    {uploadProgress[file.name] === 100 && (
                      <div className='text-green-500 text-xs font-medium'>
                        Uploaded
                      </div>
                    )}
                    {uploadProgress[file.name] === -1 && (
                      <div className='text-red-500 text-xs font-medium'>
                        Failed
                      </div>
                    )}
                    {uploadProgress[file.name] > 0 &&
                      uploadProgress[file.name] < 100 && (
                        <Progress
                          value={uploadProgress[file.name]}
                          className='w-20'
                        />
                      )}
                    {uploadProgress[file.name] === 0 && (
                      <Button
                        size='icon'
                        variant='ghost'
                        onClick={() => removeFile(index)}
                        disabled={isUploading}
                        className='h-7 w-7'>
                        <X size={14} />
                      </Button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          <DialogFooter className='mt-6'>
            <Button
              variant='outline'
              onClick={() => setIsOpen(false)}
              disabled={isUploading}>
              Cancel
            </Button>
            <Button
              onClick={handleUpload}
              disabled={files.length === 0 || isUploading}
              className='gap-2'>
              {isUploading ? (
                <>
                  <Loader2 size={16} className='animate-spin' />
                  Uploading...
                </>
              ) : (
                <>
                  <Upload size={16} />
                  Upload {files.length > 0 ? `(${files.length})` : ''}
                </>
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
