import React from 'react';

export const fallbackServices = [
  {
    title: "Brick Repair",
    description: "Expert brick repair services to restore the structural integrity and appearance of your brick surfaces.",
    link: "/services/brick-repair",
    imageUrl: "https://images.unsplash.com/photo-1618090584176-7132b9911bd3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80"
  },
  {
    title: "Tuckpointing & Repointing",
    description: "Restore the integrity and appearance of your masonry with our professional tuckpointing and repointing services.",
    link: "/services/tuckpointing",
    imageUrl: "https://images.unsplash.com/photo-1584463623578-37726932ba2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
  },
  {
    title: "Chimney Repair",
    description: "Professional chimney repair and restoration services to ensure your chimney is safe, efficient, and visually appealing.",
    link: "/services/chimney-repair",
    imageUrl: "https://images.unsplash.com/photo-1464465708419-9242c65fef44?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
  },
  {
    title: "Stone Masonry",
    description: "Custom stone masonry services including installation, repair, and restoration for all your stone structure needs.",
    link: "/services/stone-masonry",
    imageUrl: "https://images.unsplash.com/photo-1595351057616-8006b9f85d1e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80"
  },
  {
    title: "Fireplace Repair",
    description: "Comprehensive fireplace repair services to restore functionality, safety, and beauty to your hearth.",
    link: "/services/fireplace-repair",
    imageUrl: "https://images.unsplash.com/photo-1484566090521-1d77b7664be1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
  },
  {
    title: "Brick Porch Construction & Repair",
    description: "Expert brick porch construction and repair services to enhance your home's entrance and curb appeal.",
    link: "/services/brick-porch",
    imageUrl: "https://images.unsplash.com/photo-1592595896616-c37162298647?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
  },
];

// Keep the getServiceIcon function for backward compatibility
export const getServiceIcon = () => null;
