import React from 'react';
import { Location } from '@/types/location';
import LocationBackgroundFallback from './LocationBackgroundFallback';

interface LocationBackgroundProps {
  location: Location;
}

const LocationBackground: React.FC<LocationBackgroundProps> = ({
  location,
}) => {
  // Check if we have a hero_image and use it, then fall back to other options
  // Removed console.log to prevent unnecessary re-renders

  // If we have no image at all, use the fallback component
  if (!location.hero_image && !location.og_image) {
    return <LocationBackgroundFallback locationName={location.name} />;
  }

  const backgroundImage = location.hero_image
    ? `url('${location.hero_image}')`
    : location.og_image
    ? `url('${location.og_image}')`
    : "url('/placeholder.svg')";

  return (
    <>
      {/* Background image div - with corrected z-index to ensure visibility */}
      <div
        className='absolute inset-0 bg-cover bg-center z-[-2]'
        style={{ backgroundImage }}
        data-image-source={
          location.hero_image
            ? 'hero_image'
            : location.og_image
            ? 'og_image'
            : 'default'
        }></div>

      {/* Dark gradient overlay - needs to be above the background but below content */}
      <div className='absolute inset-0 bg-gradient-to-b from-gray-900/70 to-gray-900/60 z-[-1]'></div>
    </>
  );
};

export default LocationBackground;
