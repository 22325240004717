
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AdminLayout from '@/components/admin/AdminLayout';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from 'lucide-react';
import PhotoGrid from '@/components/admin/galleries/PhotoGrid';
import { useGalleryPhotos } from '@/hooks/gallery-photos';
import { Skeleton } from "@/components/ui/skeleton";
import { supabase } from '@/lib/supabase';
import { toast } from "sonner";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from "@/components/ui/breadcrumb";

const AdminGalleryPhotos = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [galleryTitle, setGalleryTitle] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const {
    photos,
    loading: loadingPhotos,
    uploading,
    deleting,
    confirmDeleteId,
    uploadPhoto,
    deletePhoto,
    updatePhotoCaption,
    updatePhotoReviewStatus
  } = useGalleryPhotos(id || '');

  useEffect(() => {
    const fetchGalleryTitle = async () => {
      if (!id) return;
      
      try {
        const { data, error } = await supabase
          .from('galleries')
          .select('title')
          .eq('id', id)
          .maybeSingle();
        
        if (error) {
          console.error('Error fetching gallery title:', error);
          throw error;
        }
        
        if (data) {
          setGalleryTitle(data.title);
        } else {
          console.error('Gallery not found with ID:', id);
          toast.error('Gallery not found');
          navigate('/admin/galleries');
        }
      } catch (error) {
        console.error('Error fetching gallery title:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGalleryTitle();
  }, [id, navigate]);

  const handleBack = () => {
    navigate('/admin/galleries');
  };

  return (
    <AdminLayout title={loading ? "Gallery Photos" : `Gallery Photos: ${galleryTitle}`}>
      <div className="mb-6">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/admin/dashboard">Dashboard</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href="/admin/galleries">Galleries</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink>Gallery Photos</BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>

      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>
            {loading ? (
              <Skeleton className="h-8 w-64" />
            ) : (
              `Manage Photos: ${galleryTitle}`
            )}
          </CardTitle>
          <Button variant="outline" onClick={handleBack}>
            <ArrowLeft className="mr-2 h-4 w-4" />
            Back to Galleries
          </Button>
        </CardHeader>
        <CardContent>
          <PhotoGrid
            photos={photos}
            loading={loadingPhotos}
            uploading={uploading}
            deleting={deleting}
            confirmDeleteId={confirmDeleteId}
            onUpload={uploadPhoto}
            onDelete={deletePhoto}
            onCaptionUpdate={updatePhotoCaption}
            onUpdatePhotoReviewStatus={updatePhotoReviewStatus}
          />
        </CardContent>
      </Card>
    </AdminLayout>
  );
};

export default AdminGalleryPhotos;
