
import React from 'react';

interface BlogHeaderProps {
  title: string;
  subtitle: string;
}

const BlogHeader: React.FC<BlogHeaderProps> = ({ title, subtitle }) => {
  return (
    <div className="mb-12 text-center">
      <h1 className="text-4xl font-bold mb-4">{title}</h1>
      <p className="text-lg text-gray-600">{subtitle}</p>
    </div>
  );
};

export default BlogHeader;
