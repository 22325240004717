
import React from 'react';
import { 
  Bold, 
  Italic, 
  Heading1, 
  Heading2, 
  Heading3,
  Link,
  List,
  ListOrdered,
  Quote,
  Image,
  Code,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Underline,
  PilcrowSquare,
  Strikethrough
} from 'lucide-react';
import { ToolbarButton } from './ToolbarButton';

interface EditorToolbarProps {
  onToolbarAction: (action: string) => void;
}

export const EditorToolbar = ({ onToolbarAction }: EditorToolbarProps) => {
  return (
    <div className="flex flex-wrap gap-1 p-2 bg-muted rounded-md">
      <div className="flex flex-wrap gap-1 border-r pr-1 mr-1">
        <ToolbarButton action="h1" icon={<Heading1 className="h-4 w-4" />} tooltip="Heading 1" onClick={onToolbarAction} />
        <ToolbarButton action="h2" icon={<Heading2 className="h-4 w-4" />} tooltip="Heading 2" onClick={onToolbarAction} />
        <ToolbarButton action="h3" icon={<Heading3 className="h-4 w-4" />} tooltip="Heading 3" onClick={onToolbarAction} />
        <ToolbarButton action="paragraph" icon={<PilcrowSquare className="h-4 w-4" />} tooltip="Paragraph" onClick={onToolbarAction} />
      </div>
      
      <div className="flex flex-wrap gap-1 border-r pr-1 mr-1">
        <ToolbarButton action="bold" icon={<Bold className="h-4 w-4" />} tooltip="Bold" onClick={onToolbarAction} />
        <ToolbarButton action="italic" icon={<Italic className="h-4 w-4" />} tooltip="Italic" onClick={onToolbarAction} />
        <ToolbarButton action="underline" icon={<Underline className="h-4 w-4" />} tooltip="Underline" onClick={onToolbarAction} />
        <ToolbarButton action="strikethrough" icon={<Strikethrough className="h-4 w-4" />} tooltip="Strikethrough" onClick={onToolbarAction} />
      </div>
      
      <div className="flex flex-wrap gap-1 border-r pr-1 mr-1">
        <ToolbarButton action="align-left" icon={<AlignLeft className="h-4 w-4" />} tooltip="Align Left" onClick={onToolbarAction} />
        <ToolbarButton action="align-center" icon={<AlignCenter className="h-4 w-4" />} tooltip="Align Center" onClick={onToolbarAction} />
        <ToolbarButton action="align-right" icon={<AlignRight className="h-4 w-4" />} tooltip="Align Right" onClick={onToolbarAction} />
      </div>
      
      <div className="flex flex-wrap gap-1 border-r pr-1 mr-1">
        <ToolbarButton action="ul" icon={<List className="h-4 w-4" />} tooltip="Unordered List" onClick={onToolbarAction} />
        <ToolbarButton action="ol" icon={<ListOrdered className="h-4 w-4" />} tooltip="Ordered List" onClick={onToolbarAction} />
        <ToolbarButton action="quote" icon={<Quote className="h-4 w-4" />} tooltip="Blockquote" onClick={onToolbarAction} />
        <ToolbarButton action="code" icon={<Code className="h-4 w-4" />} tooltip="Code" onClick={onToolbarAction} />
      </div>
      
      <div className="flex flex-wrap gap-1">
        <ToolbarButton action="link" icon={<Link className="h-4 w-4" />} tooltip="Insert Link" onClick={onToolbarAction} />
        <ToolbarButton action="image" icon={<Image className="h-4 w-4" />} tooltip="Insert Image" onClick={onToolbarAction} />
        <ToolbarButton action="div" icon={<div className="h-4 w-4 flex items-center justify-center text-xs font-bold">DIV</div>} tooltip="Div Container" onClick={onToolbarAction} />
      </div>
    </div>
  );
};
