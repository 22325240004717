
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { Location } from '@/types/location';
import { ServiceDetail } from '@/types/service';
import { useServicesQuery } from '@/hooks/useServiceQuery';
import { Card, CardContent } from '@/components/ui/card';

interface LocationServicesProps {
  location: Location;
}

const LocationServices: React.FC<LocationServicesProps> = ({ location }) => {
  const { data: services, isLoading } = useServicesQuery();
  const [featuredServices, setFeaturedServices] = useState<ServiceDetail[]>([]);
  
  useEffect(() => {
    // Filter for featured services when data is available
    if (services && services.length > 0) {
      const featured = services.filter(service => service.is_featured);
      setFeaturedServices(featured.length > 0 ? featured : services.slice(0, 4));
    }
  }, [services]);

  return (
    <section className="bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Our Services in {location.name}</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            We provide a wide range of masonry services throughout {location.name}. Our team of experts is ready to help with your next project.
          </p>
        </div>
        
        {isLoading ? (
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#D00B0E]"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {featuredServices.map((service) => (
              <Card key={service.slug} className="overflow-hidden flex flex-col h-full border border-gray-100 shadow-sm">
                {service.image_url && (
                  <div className="h-48 overflow-hidden">
                    <img 
                      src={service.image_url} 
                      alt={service.title} 
                      className="w-full h-full object-cover"
                    />
                  </div>
                )}
                <CardContent className="p-6 flex flex-col flex-grow">
                  <h3 className="text-xl font-semibold mb-3">{service.title}</h3>
                  <p className="text-gray-600 mb-4 flex-grow">{service.description}</p>
                  <Link 
                    to={`/our-services/${service.slug}`}
                    className="text-[#D00B0E] hover:underline flex items-center mt-2"
                  >
                    Learn more <ArrowRight className="h-4 w-4 ml-1" />
                  </Link>
                </CardContent>
              </Card>
            ))}
          </div>
        )}
        
        <div className="text-center mt-12">
          <Link 
            to="/our-services"
            className="bg-[#D00B0E] hover:bg-[#D00B0E]/90 text-white px-6 py-3 rounded-md font-medium transition-colors inline-block"
          >
            View All Services
          </Link>
        </div>
      </div>
    </section>
  );
};

export default LocationServices;
