import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { ServiceDetail } from '@/types/service';
import { toast } from 'sonner';

export const useServicesTable = () => {
  const [services, setServices] = useState<ServiceDetail[]>([]);
  const [filteredServices, setFilteredServices] = useState<ServiceDetail[]>([]);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchServices();
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredServices(services);
    } else {
      const lowercaseQuery = searchQuery.toLowerCase();
      const filtered = services.filter(
        (service) =>
          service.title.toLowerCase().includes(lowercaseQuery) ||
          service.description.toLowerCase().includes(lowercaseQuery) ||
          service.slug.toLowerCase().includes(lowercaseQuery)
      );
      setFilteredServices(filtered);
    }
  }, [searchQuery, services]);

  const fetchServices = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase.from('services').select('*');

      if (error) {
        throw error;
      }

      setServices(data || []);
      setFilteredServices(data || []);
    } catch (error) {
      console.error('Error fetching services:', error);
      toast.error('Failed to load services');
    } finally {
      setLoading(false);
    }
  };

  const handleEditService = (id: string) => {
    navigate(`/admin/edit-service/${id}`);
  };

  const handlePreviewService = (slug: string) => {
    window.open(`/services/${slug}`, '_blank');
  };

  const handleDeleteService = async (id: string | null) => {
    if (id === null) {
      setConfirmDeleteId(null);
      return;
    }

    if (confirmDeleteId === null) {
      setConfirmDeleteId(id);
      return;
    }

    try {
      setDeleting(true);
      const { error } = await supabase.from('services').delete().eq('id', id);

      if (error) {
        throw error;
      }

      setServices((prevServices) =>
        prevServices.filter((service) => service.id !== id)
      );
      toast.success('Service deleted successfully');
    } catch (error) {
      console.error('Error deleting service:', error);
      toast.error('Failed to delete service');
    } finally {
      setDeleting(false);
      setConfirmDeleteId(null);
    }
  };

  return {
    services,
    filteredServices,
    loading,
    deleting,
    confirmDeleteId,
    searchQuery,
    setSearchQuery,
    handleEditService,
    handlePreviewService,
    handleDeleteService,
  };
};
