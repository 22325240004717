import React from 'react';
import { Location } from '@/types/location';
import LocationBackground from './LocationBackground';
import ContactRequestForm from '../shared/ContactRequestForm';
import { Button } from '@/components/ui/button';
import { useModal } from '@/components/shared/modal/ModalContext';
import MobileFloatingBar from '@/components/shared/MobileFloatingBar';
import ReviewCarousel from '@/components/shared/ReviewCarousel';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { Review } from '@/lib/supabase/reviews';

interface LocationHeroProps {
  location: Location;
  parentLocation?: Location | null;
}

const LocationHero: React.FC<LocationHeroProps> = ({
  location,
  parentLocation,
}) => {
  const { openContactModal } = useModal();

  // Use the custom_title if available, otherwise fall back to the location name
  const displayName = location.custom_title || location.name;

  const handleQuoteButtonClick = () => {
    openContactModal(undefined, location.name);
  };

  // Calculate review count from location's review_ids if available
  const reviewCount = location.review_ids?.length || 0;

  // Fetch the reviews for this location for mobile display
  const { data: reviews } = useQuery({
    queryKey: ['location-reviews-preview', location.id, location.review_ids],
    queryFn: async () => {
      if (!location.review_ids || location.review_ids.length === 0) {
        return [];
      }

      const { data, error } = await supabase
        .from('reviews')
        .select('*')
        .in('id', location.review_ids)
        .order('create_time', { ascending: false })
        .limit(5);

      if (error) throw error;
      return data as Review[];
    },
    enabled:
      Array.isArray(location.review_ids) && location.review_ids.length > 0,
  });

  return (
    <div className='relative text-white overflow-hidden'>
      {/* The background component handles both the image and overlay */}
      <LocationBackground location={location} />

      <div className='container mx-auto px-4 py-12 md:py-20 relative z-10'>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 items-center'>
          <div className='max-w-3xl'>
            <h1 className='text-4xl md:text-5xl font-bold mb-4'>
              {displayName}
            </h1>
            <p className='text-gray-200 mb-6'>
              Professional masonry services in {location.name}. Expert repairs,
              restoration, and installation.
            </p>

            <div className='mt-8 flex flex-wrap gap-4'>
              <a
                href='/services'
                className='bg-brick text-white hover:bg-brick/90 px-6 py-3 rounded-md font-medium transition-colors'>
                Our Services
              </a>
            </div>

            {/* Mobile Reviews Preview (only visible on mobile) */}
            {reviews && reviews.length > 0 && (
              <div className='mt-8 lg:hidden'>
                <h3 className='text-xl font-semibold mb-4'>
                  What Our Customers Say
                </h3>
                <div className='bg-black/30 p-4 rounded-lg'>
                  <ReviewCarousel reviews={reviews} />
                </div>
              </div>
            )}
          </div>

          <div className='hidden lg:block'>
            <div className='bg-white rounded-lg shadow-xl overflow-hidden'>
              <ContactRequestForm locationName={location.name} />
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Floating Bar */}
      <MobileFloatingBar />
    </div>
  );
};

export default LocationHero;
