
import { useState } from 'react';
import { HeroBackground } from '@/types/heroBackground';
import { UseHeroBackgroundsState } from './types';

export const useHeroBackgroundsState = (): UseHeroBackgroundsState => {
  const [uploadingBackground, setUploadingBackground] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedBackground, setSelectedBackground] = useState<HeroBackground | null>(null);

  return {
    uploadingBackground,
    showAddDialog,
    showEditDialog,
    selectedBackground,
    setUploadingBackground,
    setShowAddDialog,
    setShowEditDialog,
    setSelectedBackground,
  };
};
