
import React, { useEffect, useRef } from 'react';
import { Card } from '@/components/ui/card';
import { format } from 'date-fns';
import '@/styles/blog.css';

interface PostPreviewProps {
  content: string;
  title: string;
}

export const PostPreview = ({ content, title }: PostPreviewProps) => {
  // Format the current date as the publication date
  const formattedDate = format(new Date(), 'PPP');
  const contentRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    // Client-side function to clean common HTML entities in text nodes
    const cleanHtmlEntities = (element: HTMLElement) => {
      if (!element) return;
      
      // Process all child nodes
      const walker = document.createTreeWalker(
        element,
        NodeFilter.SHOW_TEXT,
        null
      );
      
      const nodesToReplace: Array<{ node: Text; newValue: string }> = [];
      
      let node;
      while (node = walker.nextNode() as Text) {
        const text = node.nodeValue || '';
        const cleanedText = text
          .replace(/&amp;/g, '&')
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
          .replace(/&quot;/g, '"')
          .replace(/&#039;/g, "'")
          .replace(/&#8217;/g, "'")
          .replace(/&#8216;/g, "'")
          .replace(/&#8220;/g, '"')
          .replace(/&#8221;/g, '"')
          .replace(/&#8211;/g, '–')
          .replace(/&#8212;/g, '—')
          .replace(/&nbsp;/g, ' ')
          .replace(/&#8230;/g, '...');
        
        if (cleanedText !== text) {
          nodesToReplace.push({ node, newValue: cleanedText });
        }
      }
      
      // Replace text in all nodes that need updating
      nodesToReplace.forEach(({ node, newValue }) => {
        node.nodeValue = newValue;
      });
    };
    
    // Run the cleanup after content is rendered
    if (contentRef.current) {
      cleanHtmlEntities(contentRef.current);
    }
  }, [content]); // Re-run when content changes

  return (
    <div className="space-y-2">
      <h3 className="text-base font-medium">Live Preview</h3>
      <Card className="p-6 min-h-[400px] overflow-auto max-h-[600px] bg-white shadow-md">
        <div className="prose max-w-none md:prose-lg">
          <div className="text-sm text-gray-500 mb-1">{formattedDate}</div>
          <h1 className="text-3xl font-bold mb-6 text-gray-900">
            {title?.replace(/&#8217;/g, "'")
              .replace(/&#8216;/g, "'")
              .replace(/&#8220;/g, '"')
              .replace(/&#8221;/g, '"')
              .replace(/&#8211;/g, '–')
              .replace(/&#8212;/g, '—')
              .replace(/&nbsp;/g, ' ')
              .replace(/&#8230;/g, '...')
              .replace(/&amp;/g, '&')
              .replace(/&lt;/g, '<')
              .replace(/&gt;/g, '>')
              .replace(/&quot;/g, '"')
              .replace(/&#039;/g, "'") || 'Post Title'}
          </h1>
          {content ? (
            <div 
              ref={contentRef}
              dangerouslySetInnerHTML={{ __html: content }} 
              className="blog-content"
            />
          ) : (
            <div className="text-gray-500 italic">
              <p>Your post content will appear here...</p>
            </div>
          )}
        </div>
      </Card>
      <div className="text-xs text-muted-foreground mt-2">
        This preview shows how the blog post will appear to readers. Images and formatting are rendered as they will display on the published page.
      </div>
    </div>
  );
};
