
import React from 'react';
import { Shield, ThumbsUp, Clock, Sparkles } from 'lucide-react';

const WhyChooseSection = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Why Choose Fix My Brick?</h2>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
            We take pride in our work and our customers appreciate the difference our attention to detail makes.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Skilled Craftsmanship */}
          <div className="p-6 bg-gray-50 rounded-lg shadow-sm border border-gray-100 flex flex-col items-center text-center hover:shadow-md transition-shadow">
            <div className="bg-[#D00B0E]/10 p-3 rounded-full mb-4">
              <Sparkles className="h-8 w-8 text-[#D00B0E]" />
            </div>
            <h3 className="text-xl font-bold mb-2">Skilled Craftsmanship</h3>
            <p className="text-gray-600">
              Our team is passionate about quality, from the materials we use to the techniques we employ.
            </p>
          </div>

          {/* Respect for Your Home */}
          <div className="p-6 bg-gray-50 rounded-lg shadow-sm border border-gray-100 flex flex-col items-center text-center hover:shadow-md transition-shadow">
            <div className="bg-[#D00B0E]/10 p-3 rounded-full mb-4">
              <ThumbsUp className="h-8 w-8 text-[#D00B0E]" />
            </div>
            <h3 className="text-xl font-bold mb-2">Respect for Your Home</h3>
            <p className="text-gray-600">
              We treat your property with care—arriving on time, communicating clearly, and leaving everything tidy.
            </p>
          </div>

          {/* Community-Focused */}
          <div className="p-6 bg-gray-50 rounded-lg shadow-sm border border-gray-100 flex flex-col items-center text-center hover:shadow-md transition-shadow">
            <div className="bg-[#D00B0E]/10 p-3 rounded-full mb-4">
              <Shield className="h-8 w-8 text-[#D00B0E]" />
            </div>
            <h3 className="text-xl font-bold mb-2">Community-Focused</h3>
            <p className="text-gray-600">
              We're proud to serve Ontario, building lasting relationships and standing behind our work.
            </p>
          </div>
          
          {/* On-Time Service */}
          <div className="p-6 bg-gray-50 rounded-lg shadow-sm border border-gray-100 flex flex-col items-center text-center hover:shadow-md transition-shadow md:col-span-1 lg:col-span-3/2 lg:col-start-2">
            <div className="bg-[#D00B0E]/10 p-3 rounded-full mb-4">
              <Clock className="h-8 w-8 text-[#D00B0E]" />
            </div>
            <h3 className="text-xl font-bold mb-2">On-Time Service</h3>
            <p className="text-gray-600">
              We value your time and commit to showing up when promised, completing projects efficiently without rushing quality.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseSection;
