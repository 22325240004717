
import React from 'react';
import { ServiceDetail } from '@/types/service';

interface ServiceDescriptionProps {
  service: ServiceDetail;
}

const ServiceDescription: React.FC<ServiceDescriptionProps> = ({ service }) => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-2xl md:text-3xl font-bold mb-8 text-center">Professional {service.title} Services</h2>
          <div className="prose prose-lg max-w-none">
            <p className="mb-6 text-gray-700 whitespace-pre-line leading-relaxed">{service.full_description}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceDescription;
