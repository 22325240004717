import {
  Home,
  Book,
  Image,
  LayoutDashboard,
  ListChecks,
  Mail,
  Info,
  Hammer,
  Building,
  Wrench,
  Paintbrush,
  HardHat,
  Construction,
  MapPin,
  Star,
} from 'lucide-react';
import { fetchServices } from '@/lib/supabase/services';
import { ServiceDetail } from '@/types/service';
import { getServiceIcon } from '@/components/services/serviceUtils';

export const navigationItems = [
  {
    label: 'Home',
    path: '/',
    icon: Home,
  },
  {
    label: 'Services',
    path: '/our-services',
    icon: ListChecks,
  },
  {
    label: 'Gallery',
    path: '/gallery',
    icon: Image,
  },
  {
    label: 'Reviews',
    path: '/reviews',
    icon: Star,
  },
  {
    label: 'About',
    path: '/about',
    icon: Info,
  },
];

export const adminNavigationItems = [
  {
    label: 'Dashboard',
    path: '/admin/dashboard',
    icon: LayoutDashboard,
  },
  {
    label: 'Galleries',
    path: '/admin/galleries',
    icon: Image,
  },
  {
    label: 'Blog',
    path: '/admin/blog',
    icon: Book,
  },
];

export const serviceLinks = [
  {
    name: 'Brick Porch Construction & Repair',
    slug: 'brick-porch',
    icon: Building,
    iconClassName: 'h-4 w-4 text-D00B0E',
  },
  {
    name: 'Brick Repair',
    slug: 'brick-repair',
    icon: Hammer,
    iconClassName: 'h-4 w-4 text-D00B0E',
  },
  {
    name: 'Chimney Repair',
    slug: 'chimney-repair',
    icon: Construction,
    iconClassName: 'h-4 w-4 text-D00B0E',
  },
  {
    name: 'Chimney Removal',
    slug: 'chimney-removal',
    icon: Wrench,
    iconClassName: 'h-4 w-4 text-D00B0E',
  },
  {
    name: 'Fireplace Repair',
    slug: 'fireplace-repair',
    icon: Construction,
    iconClassName: 'h-4 w-4 text-D00B0E',
  },
  {
    name: 'Parging',
    slug: 'parging',
    icon: Paintbrush,
    iconClassName: 'h-4 w-4 text-D00B0E',
  },
  {
    name: 'Stone Masonry',
    slug: 'stone-masonry',
    icon: Building,
    iconClassName: 'h-4 w-4 text-D00B0E',
  },
  {
    name: 'Tuckpointing & Repointing',
    slug: 'tuckpointing-repointing',
    icon: Paintbrush,
    iconClassName: 'h-4 w-4 text-D00B0E',
  },
];

export const LocationLinks = [
  {
    name: 'Greater Toronto Area',
    slug: 'toronto',
    address: '68 Beverly Hills Dr, North York, ON M3L 1A5',
    google_place_id: 'ChIJpTvG15DL1IkRd8S0KlBVNTI',
  },
  {
    name: 'Greater Hamilton Region',
    slug: 'hamilton',
    address: '498 Glancaster Rd, Hamilton ON L0R 1W0',
    google_place_id: 'ChIJUazNImKbLIgRopV7JVMdOS0',
  },
  {
    name: 'Kitchener - Waterloo Area',
    slug: 'kitchener-waterloo',
    address: '290 King St E, Kitchener, ON N2G 2L2',
    google_place_id: 'ChIJK2f_4SH0K4gRoECHKF83BIU',
  },
  {
    name: 'Peel Region',
    slug: 'peel-region',
    address: '55 Village Centre Pl, Mississauga, ON L4Z 1V9',
    google_place_id: 'ChIJHcZsrXFBK4gRoNX5LTq1jsI',
  },
  {
    name: 'Greater Niagara Region',
    slug: 'niagara',
    address: '21 Duke St, St. Catharines, ON L2R 5W1',
    google_place_id: 'ChIJ06qFs_9E04kRmAZx8l2fNBQ',
  },
  {
    name: 'London Area',
    slug: 'london',
    address: '1172 Hamilton Rd unit 203, London ON N5W 1A9, Canada',
    google_place_id: '',
  },
];

export const getServiceLinkIcon = (serviceTitle: string) => {
  const title = serviceTitle.toLowerCase();

  if (title.includes('brick')) {
    return Hammer;
  } else if (title.includes('chimney')) {
    return Wrench;
  } else if (title.includes('tuckpointing')) {
    return Paintbrush;
  } else if (title.includes('foundation')) {
    return HardHat;
  } else if (title.includes('masonry') || title.includes('construction')) {
    return Construction;
  } else {
    return Building;
  }
};

export const createServiceLinks = (services: ServiceDetail[]) => {
  return services.map((service) => ({
    name: service.title,
    slug: service.slug,
    icon: getServiceLinkIcon(service.title),
    iconClassName: 'h-4 w-4 text-D00B0E',
  }));
};

export const fetchServiceLinks = async () => {
  try {
    const services = await fetchServices();

    const featuredServices = services.filter((service) => service.is_featured);

    if (featuredServices.length > 0) {
      return createServiceLinks(featuredServices);
    }

    const limitedServices = services.slice(0, 6);
    return limitedServices.length > 0
      ? createServiceLinks(limitedServices)
      : serviceLinks;
  } catch (error) {
    console.error('Error fetching service links:', error);
    return serviceLinks;
  }
};
