
import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { ServiceDetail } from '@/types/service';
import { toast } from 'sonner';

// Initial state for a new service
export const defaultServiceData: ServiceDetail = {
  id: '', // This will be kept empty and excluded when creating a new service
  title: '',
  slug: '',
  description: '',
  full_description: '',
  benefits: [],
  process_steps: [],
  faq: [],
  image_url: '',
  hero_image: '',
  hero_headline: '',
  cta_text: 'Ready to start your project? Contact our team today for a free consultation and quote.',
  // Add SEO fields with default empty values
  meta_title: '',
  meta_description: '',
  og_title: '',
  og_description: '',
  og_image: '',
  canonical_url: '',
  focus_keyword: '',
  // Add is_featured with default value
  is_featured: false,
  // Add gallery_images with default empty array
  gallery_images: [],
  // Add review_ids with default empty array
  review_ids: []
};

/**
 * Hook for fetching service data
 */
export const useServiceData = (id?: string) => {
  const [formData, setFormData] = useState<ServiceDetail>({...defaultServiceData});
  const [loading, setLoading] = useState(true);
  const isNewService = !id || id === 'new';

  useEffect(() => {
    if (!isNewService) {
      fetchService();
    } else {
      setLoading(false);
    }
  }, [id]);

  const fetchService = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('services')
        .select('*')
        .eq('id', id)
        .single();

      if (error) {
        throw error;
      }

      setFormData({
        ...data,
        // Ensure id is always a string
        id: String(data.id),
        // Ensure these are always present even if null in the database
        hero_image: data.hero_image || '',
        hero_headline: data.hero_headline || '',
        meta_title: data.meta_title || '',
        meta_description: data.meta_description || '',
        og_title: data.og_title || '',
        og_description: data.og_description || '',
        og_image: data.og_image || '',
        canonical_url: data.canonical_url || '',
        focus_keyword: data.focus_keyword || '',
        is_featured: data.is_featured || false,
        gallery_images: data.gallery_images || [],
        review_ids: data.review_ids || []
      });
    } catch (error) {
      console.error('Error fetching service:', error);
      toast.error('Failed to load service data');
    } finally {
      setLoading(false);
    }
  };

  return {
    formData,
    setFormData,
    loading,
    isNewService
  };
};
