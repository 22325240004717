
import React from 'react';
import { Plus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { HeroBackground } from '@/types/heroBackground';
import BackgroundCard from './BackgroundCard';
import { SiteSettings } from '@/types/settings';

interface BackgroundGalleryProps {
  backgrounds: HeroBackground[];
  isLoading: boolean;
  activeBackgroundUrl: string;
  onAddClick: () => void;
  onUseBackground: (background: HeroBackground) => Promise<void>;
  onDeleteBackground: (background: HeroBackground) => Promise<void>;
}

const BackgroundGallery: React.FC<BackgroundGalleryProps> = ({
  backgrounds,
  isLoading,
  activeBackgroundUrl,
  onAddClick,
  onUseBackground,
  onDeleteBackground,
}) => {
  if (isLoading) {
    return (
      <div className='py-8 text-center text-gray-500'>
        Loading backgrounds...
      </div>
    );
  }

  return (
    <div className='space-y-4'>
      <div className='flex justify-between items-center'>
        <Label className='block text-sm font-medium text-gray-700'>
          Your uploaded backgrounds
        </Label>
        <Button variant='outline' size='sm' onClick={onAddClick}>
          <Plus className='h-4 w-4 mr-1' />
          Add New Background
        </Button>
      </div>

      {backgrounds.length === 0 ? (
        <div className='text-center py-8 text-gray-500 border border-dashed rounded-md'>
          <p>No backgrounds yet. Upload your first one!</p>
        </div>
      ) : (
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
          {backgrounds.map((background) => (
            <BackgroundCard
              key={background.id}
              background={background}
              isActive={activeBackgroundUrl === background.url}
              onUse={onUseBackground}
              onDelete={onDeleteBackground}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default BackgroundGallery;
