
import React from 'react';
import { SiteSettings } from '@/types/settings';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { InfoIcon } from 'lucide-react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';

interface TrackingTabProps {
  settings: SiteSettings;
  saving: boolean;
  handleChange: (field: keyof SiteSettings, value: string) => void;
}

const TrackingTab: React.FC<TrackingTabProps> = ({
  settings,
  saving,
  handleChange,
}) => {
  return (
    <div className='space-y-6'>
      <Card>
        <CardHeader>
          <CardTitle>Tracking & Analytics</CardTitle>
          <CardDescription>
            Configure tracking and analytics platforms for your website
          </CardDescription>
        </CardHeader>
        <CardContent className='space-y-4'>
          <Alert className='bg-amber-50 border-amber-200'>
            <InfoIcon className='h-4 w-4 text-amber-500' />
            <AlertDescription className='text-amber-700'>
              The settings configured here will enable tracking across your
              entire website. Make sure to comply with privacy regulations and
              update your privacy policy accordingly.
            </AlertDescription>
          </Alert>

          <Tabs defaultValue="general">
            <TabsList className="mb-4">
              <TabsTrigger value="general">General Tracking</TabsTrigger>
              <TabsTrigger value="advanced">Advanced Settings</TabsTrigger>
            </TabsList>
            
            <TabsContent value="general">
              <div className='space-y-6'>
                <div className='space-y-2'>
                  <Label htmlFor='google_analytics_id'>
                    Google Analytics Measurement ID
                  </Label>
                  <Input
                    id='google_analytics_id'
                    name='google_analytics_id'
                    value={settings.google_analytics_id || ''}
                    onChange={(e) =>
                      handleChange('google_analytics_id', e.target.value)
                    }
                    placeholder='G-XXXXXXXXXX'
                    disabled={saving}
                    className='w-full max-w-md'
                  />
                  <p className='text-sm text-gray-500'>
                    Enter your Google Analytics 4 Measurement ID (starts with G-).
                    Leave empty to disable tracking.
                  </p>
                </div>

                <div className='space-y-2'>
                  <Label htmlFor='gtm_container_id'>
                    Google Tag Manager Container ID
                  </Label>
                  <Input
                    id='gtm_container_id'
                    name='gtm_container_id'
                    value={settings.gtm_container_id || ''}
                    onChange={(e) => handleChange('gtm_container_id', e.target.value)}
                    placeholder='GTM-XXXXXXX'
                    disabled={saving}
                    className='w-full max-w-md'
                  />
                  <p className='text-sm text-gray-500'>
                    Enter your Google Tag Manager Container ID (starts with GTM-).
                    Leave empty to disable GTM.
                  </p>
                </div>

                <div className='space-y-2'>
                  <Label htmlFor='facebook_pixel_id'>
                    Facebook Pixel ID
                  </Label>
                  <Input
                    id='facebook_pixel_id'
                    name='facebook_pixel_id'
                    value={settings.facebook_pixel_id || ''}
                    onChange={(e) => handleChange('facebook_pixel_id', e.target.value)}
                    placeholder='XXXXXXXXXX'
                    disabled={saving}
                    className='w-full max-w-md'
                  />
                  <p className='text-sm text-gray-500'>
                    Enter your Facebook Pixel ID to enable tracking conversions.
                    Leave empty to disable Facebook tracking.
                  </p>
                </div>

                <div className='space-y-2'>
                  <Label htmlFor='plausible_domain'>Plausible Analytics Domain</Label>
                  <Input
                    id='plausible_domain'
                    name='plausible_domain'
                    value={settings.plausible_domain || ''}
                    onChange={(e) => handleChange('plausible_domain', e.target.value)}
                    placeholder='yourdomain.com'
                    disabled={saving}
                    className='w-full max-w-md'
                  />
                  <p className='text-sm text-gray-500'>
                    Enter your domain exactly as configured in Plausible Analytics
                    (e.g., yourdomain.com). Leave empty to disable Plausible tracking.
                  </p>
                </div>
              </div>
            </TabsContent>
            
            <TabsContent value="advanced">
              <div className='space-y-6'>
                <Alert className='bg-blue-50 border-blue-200'>
                  <InfoIcon className='h-4 w-4 text-blue-500' />
                  <AlertDescription className='text-blue-700'>
                    The advanced settings below require additional configuration in the Supabase database.
                    Contact your administrator if you need to set up server-side tracking.
                  </AlertDescription>
                </Alert>
                
                <div className='mt-4'>
                  <h3 className='text-sm font-medium mb-2'>Server-Side Tracking Status</h3>
                  <div className='p-4 bg-gray-50 rounded-md'>
                    <p className='text-sm'>
                      Server-side tracking allows for more reliable conversion tracking, especially
                      when ad blockers are used. Configuration is managed through the Supabase dashboard.
                    </p>
                    <div className='mt-4 flex gap-2'>
                      <Button variant="outline" size="sm" className='text-blue-600' 
                        onClick={() => window.open('https://supabase.com/dashboard/project/oveieyfzkpgdaoiwqzaf/functions', '_blank')}>
                        Manage Edge Functions
                      </Button>
                      <Button variant="outline" size="sm" className='text-blue-600'
                        onClick={() => window.open('https://supabase.com/dashboard/project/oveieyfzkpgdaoiwqzaf/editor', '_blank')}>
                        Configure Tracking Codes
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
};

export default TrackingTab;
