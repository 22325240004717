
import React from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import StandardizedLocationsTable from '@/components/admin/locations/StandardizedLocationsTable';
import { Location } from '@/types/location';
import FeaturesTab from './FeaturesTab';
import UsageTab from './UsageTab';

interface DemoTabsProps {
  locations: Location[];
  loading: boolean;
  error: boolean;
  onRetry: () => void;
}

const DemoTabs: React.FC<DemoTabsProps> = ({
  locations,
  loading,
  error,
  onRetry
}) => {
  return (
    <Tabs defaultValue="table">
      <TabsList className="mb-4">
        <TabsTrigger value="table">Table Demo</TabsTrigger>
        <TabsTrigger value="features">Features</TabsTrigger>
        <TabsTrigger value="usage">Usage Guidelines</TabsTrigger>
      </TabsList>
      
      <TabsContent value="table" className="space-y-4">
        <StandardizedLocationsTable 
          locations={locations}
          loading={loading}
          error={error}
          onRetry={onRetry}
        />
      </TabsContent>
      
      <FeaturesTab />
      <UsageTab />
    </Tabs>
  );
};

export default DemoTabs;
