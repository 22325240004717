
import React from 'react';
import { MigrationHistory } from '@/lib/supabase/wordpress';

interface MigrationStatsProps {
  lastMigration: MigrationHistory | null;
}

const MigrationStats: React.FC<MigrationStatsProps> = ({ lastMigration }) => {
  if (!lastMigration) return null;

  return (
    <div className='mt-3 bg-gray-50 p-3 rounded text-sm'>
      <div className='font-medium mb-1'>Last Migration Results:</div>
      <div className='grid grid-cols-3 gap-2'>
        <div className='text-center'>
          <div className='font-mono text-lg'>
            {lastMigration.posts_migrated}
          </div>
          <div className='text-xs text-gray-500'>Posts</div>
        </div>
        <div className='text-center'>
          <div className='font-mono text-lg'>
            {lastMigration.categories_migrated}
          </div>
          <div className='text-xs text-gray-500'>Categories</div>
        </div>
        <div className='text-center'>
          <div className='font-mono text-lg'>
            {lastMigration.tags_migrated}
          </div>
          <div className='text-xs text-gray-500'>Tags</div>
        </div>
      </div>
    </div>
  );
};

export default MigrationStats;
