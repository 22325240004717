
import React from 'react';

interface Category {
  id: string;
  name: string;
  slug: string;
}

interface PostCategoriesProps {
  categories?: Category[];
}

const PostCategories: React.FC<PostCategoriesProps> = ({ categories }) => {
  if (!categories || categories.length === 0) return null;

  return (
    <div className="flex flex-wrap gap-2 mb-6">
      {categories.map((category) => (
        <span 
          key={category.id} 
          className="text-sm bg-gray-100 text-gray-700 px-3 py-1 rounded-full"
        >
          {category.name}
        </span>
      ))}
    </div>
  );
};

export default PostCategories;
