
import React from 'react';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';

interface SEOFormProps {
  seoData: {
    meta_title?: string;
    meta_description?: string;
    og_title?: string;
    og_description?: string;
    og_image?: string;
    canonical_url?: string;
    focus_keyword?: string;
  };
  onChange: (field: string, value: string) => void;
}

export const SEOForm: React.FC<SEOFormProps> = ({ seoData, onChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(e.target.id, e.target.value);
  };

  return (
    <Card className="p-6">
      <div className="space-y-4">
        <h3 className="text-lg font-medium">Search Engine Optimization</h3>
        <p className="text-sm text-muted-foreground">
          These settings help improve how this post appears in search engine results.
        </p>

        <div className="space-y-4">
          <div>
            <Label htmlFor="meta_title">Meta Title</Label>
            <Input
              id="meta_title"
              value={seoData.meta_title || ''}
              onChange={handleChange}
              placeholder="Enter SEO title (defaults to post title if empty)"
              className="mt-1"
            />
            <p className="text-xs text-muted-foreground mt-1">
              Recommended length: 50-60 characters
            </p>
          </div>

          <div>
            <Label htmlFor="meta_description">Meta Description</Label>
            <Textarea
              id="meta_description"
              value={seoData.meta_description || ''}
              onChange={handleChange}
              placeholder="Enter a description that will appear in search results"
              className="mt-1"
              rows={3}
            />
            <p className="text-xs text-muted-foreground mt-1">
              Recommended length: 155-160 characters
            </p>
          </div>

          <div>
            <Label htmlFor="focus_keyword">Focus Keyword</Label>
            <Input
              id="focus_keyword"
              value={seoData.focus_keyword || ''}
              onChange={handleChange}
              placeholder="Main keyword for this post"
              className="mt-1"
            />
          </div>

          <div>
            <Label htmlFor="canonical_url">Canonical URL (optional)</Label>
            <Input
              id="canonical_url"
              value={seoData.canonical_url || ''}
              onChange={handleChange}
              placeholder="https://example.com/original-content"
              className="mt-1"
            />
            <p className="text-xs text-muted-foreground mt-1">
              Use this when content is duplicated from another source
            </p>
          </div>

          <h4 className="text-md font-medium pt-2">Social Media Preview</h4>
          
          <div>
            <Label htmlFor="og_title">Social Media Title</Label>
            <Input
              id="og_title"
              value={seoData.og_title || ''}
              onChange={handleChange}
              placeholder="Title when shared on social media"
              className="mt-1"
            />
          </div>

          <div>
            <Label htmlFor="og_description">Social Media Description</Label>
            <Textarea
              id="og_description"
              value={seoData.og_description || ''}
              onChange={handleChange}
              placeholder="Description when shared on social media"
              className="mt-1"
              rows={2}
            />
          </div>

          <div>
            <Label htmlFor="og_image">Social Media Image URL</Label>
            <Input
              id="og_image"
              value={seoData.og_image || ''}
              onChange={handleChange}
              placeholder="https://example.com/image.jpg"
              className="mt-1"
            />
            {seoData.og_image && (
              <div className="mt-2">
                <img 
                  src={seoData.og_image} 
                  alt="OG Preview" 
                  className="max-h-32 rounded-md"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
