import { Location } from '@/types/location';

// Primary locations for fallback if API requests fail DO NOT EDIT THIS FILE
export const primaryLocations: Record<string, Location> = {
  hamilton: {
    id: 'hamilton',
    name: 'Greater Hamilton Region',
    slug: 'hamilton',
    address: '498 Glancaster Rd, Hamilton ON L0R 1W0',
    city: 'Hamilton',
    province: 'ON',
    postal_code: 'L0R 1W0',
    country: 'Canada',
    parent_id: null,
    is_primary: true,
    description: 'Masonry services for Hamilton and surrounding areas.',
    meta_description: null,
    google_place_id: 'ChIJUazNImKbLIgRopV7JVMdOS0', // Added this field
  },
  mississauga: {
    id: 'mississauga',
    name: 'Peel Region',
    slug: 'peel-region',
    address: '55 Village Centre Pl, Mississauga, ON L4Z 1V9',
    city: 'Mississauga',
    province: 'ON',
    postal_code: 'L4Z 1V9',
    country: 'Canada',
    parent_id: null,
    is_primary: true,
    description:
      'Professional masonry services for Mississauga and surrounding areas.',
    meta_description: null,
    google_place_id: 'ChIJHcZsrXFBK4gRoNX5LTq1jsI', // Added this field
  },
  toronto: {
    id: 'toronto',
    name: 'Greater Toronto Area',
    slug: 'toronto',
    address: '68 Beverly Hills Dr, North York, ON M3L 1A5',
    city: 'Toronto',
    province: 'ON',
    postal_code: 'M3L 1A5',
    country: 'Canada',
    parent_id: null,
    is_primary: true,
    description:
      'Expert masonry solutions for Toronto and neighboring communities.',
    meta_description: null,
    google_place_id: 'ChIJpTvG15DL1IkRd8S0KlBVNTI', // Added this field
  },
  niagara: {
    id: 'niagara',
    name: 'Greater Niagara Region',
    slug: 'niagara',
    address: '21 Duke St, St. Catharines, ON L2R 5W1',
    city: 'St. Catharines',
    province: 'ON',
    postal_code: 'L2R 5W1',
    country: 'Canada',
    parent_id: null,
    is_primary: true,
    description: 'Quality masonry services throughout the Niagara region.',
    meta_description: null,
    google_place_id: 'ChIJ06qFs_9E04kRmAZx8l2fNBQ', // Added this field
  },
  kitchener: {
    id: 'kitchener',
    name: 'Kitchener - Waterloo Area',
    slug: 'kitchener-waterloo',
    address: '290 King St E, Kitchener, ON N2G 2L2',
    city: 'Kitchener',
    province: 'ON',
    postal_code: 'N2G 2L2',
    country: 'Canada',
    parent_id: null,
    is_primary: true,
    description:
      'Reliable masonry services for Kitchener, Waterloo and surrounding areas.',
    meta_description: null,
    google_place_id: 'ChIJK2f_4SH0K4gRoECHKF83BIU', // Added this field
  },
};
