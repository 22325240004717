
import React from 'react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { CheckCircle2, XCircle } from 'lucide-react';

interface FormErrorProps {
  error: string | null;
}

export const FormError: React.FC<FormErrorProps> = ({ error }) => {
  if (!error) return null;
  
  return (
    <Alert variant="destructive" className="py-2">
      <div className="flex items-center">
        <XCircle className="h-4 w-4 mr-2" />
        <AlertDescription>{error}</AlertDescription>
      </div>
    </Alert>
  );
};

interface UrlTestResultProps {
  result: {
    success: boolean;
    message: string;
  } | null;
}

export const UrlTestResult: React.FC<UrlTestResultProps> = ({ result }) => {
  if (!result) return null;
  
  return (
    <Alert
      variant={result.success ? 'default' : 'destructive'}
      className='py-2'>
      <div className='flex items-center'>
        {result.success ? (
          <CheckCircle2 className='h-4 w-4 mr-2' />
        ) : (
          <XCircle className='h-4 w-4 mr-2' />
        )}
        <AlertDescription>{result.message}</AlertDescription>
      </div>
    </Alert>
  );
};
