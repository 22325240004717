import { supabase } from './client';

export interface Review {
  id: string;
  review_id: string;
  location_id: string;  // This is now the Google Place ID
  reviewer_name: string;
  reviewer_photo_url?: string;
  star_rating: string;
  reviewer_comment?: string;
  create_time: string;
  update_time: string;
  google_name?: string;
  created_at: string;
  updated_at: string;
  reviewer_create_time?: string;
  reviewer_update_time?: string;
  reply_comment?: string;
  reply_update_time?: string;
  location_name?: string; // Added this property as it exists in the DB
}

// Fetch reviews for a specific location
export async function fetchLocationReviews(locationId: string) {
  try {
    const { data, error } = await supabase
      .from('reviews')
      .select('*')
      .eq('location_id', locationId)
      .order('create_time', { ascending: false });
    
    if (error) {
      console.error('Error fetching location reviews:', error);
      return [];
    }
    
    return data as Review[];
  } catch (error) {
    console.error('Error in fetchLocationReviews:', error);
    return [];
  }
}

// Create a new review
export async function createReview(review: Omit<Review, 'id' | 'created_at' | 'updated_at'>) {
  try {
    // Ensure both created_at/updated_at and create_time/update_time are set
    const reviewData = {
      ...review,
      created_at: review.create_time,
      updated_at: review.update_time
    };

    const { data, error } = await supabase
      .from('reviews')
      .insert(reviewData)
      .select()
      .single();
    
    if (error) {
      console.error('Error creating review:', error);
      return null;
    }
    
    return data as Review;
  } catch (error) {
    console.error('Error in createReview:', error);
    return null;
  }
}

// Update an existing review
export async function updateReview(id: string, updates: Partial<Review>) {
  try {
    // Ensure both created_at/updated_at and create_time/update_time are synchronized
    const updateData = { ...updates };
    
    if (updates.create_time && !updates.created_at) {
      updateData.created_at = updates.create_time;
    }
    
    if (updates.update_time && !updates.updated_at) {
      updateData.updated_at = updates.update_time;
    }

    const { data, error } = await supabase
      .from('reviews')
      .update(updateData)
      .eq('id', id)
      .select()
      .single();
    
    if (error) {
      console.error('Error updating review:', error);
      return null;
    }
    
    return data as Review;
  } catch (error) {
    console.error('Error in updateReview:', error);
    return null;
  }
}

// Delete a review
export async function deleteReview(id: string) {
  try {
    const { error } = await supabase
      .from('reviews')
      .delete()
      .eq('id', id);
    
    if (error) {
      console.error('Error deleting review:', error);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Error in deleteReview:', error);
    return false;
  }
}

// Fetch all reviews
export async function fetchAllReviews() {
  try {
    const { data, error } = await supabase
      .from('reviews')
      .select('*')
      .order('create_time', { ascending: false });
    
    if (error) {
      console.error('Error fetching all reviews:', error);
      return [];
    }
    
    return data as Review[];
  } catch (error) {
    console.error('Error in fetchAllReviews:', error);
    return [];
  }
}

// Fetch reviews by multiple location IDs
export async function fetchReviewsByLocationIds(locationIds: string[]) {
  try {
    const { data, error } = await supabase
      .from('reviews')
      .select('*')
      .in('location_id', locationIds)
      .order('create_time', { ascending: false });
    
    if (error) {
      console.error('Error fetching reviews by location IDs:', error);
      return [];
    }
    
    return data as Review[];
  } catch (error) {
    console.error('Error in fetchReviewsByLocationIds:', error);
    return [];
  }
}
