import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES } from '@/routes';

export const useGalleryNavigation = () => {
  const navigate = useNavigate();

  const handleEditGallery = (id: string) => {
    navigate(ADMIN_ROUTES.EDIT_GALLERY_PATH(id));
  };

  const handlePreviewGallery = (slug: string) => {
    window.open(`/gallery/${slug}`, '_blank');
  };

  const handleManagePhotos = (id: string) => {
    navigate(ADMIN_ROUTES.GALLERY_PHOTOS_PATH(id));
  };

  return {
    handleEditGallery,
    handlePreviewGallery,
    handleManagePhotos,
  };
};
