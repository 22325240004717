
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AdminLayout from '@/components/admin/AdminLayout';
import ProjectForm from '@/components/admin/projects/ProjectForm';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { 
  fetchProjectById, 
  createProject, 
  updateProject 
} from '@/lib/supabase/projects';
import { ProjectCreate, ProjectUpdate } from '@/lib/supabase/projects/types';

// Define the form data type that will be used in this component
interface ProjectFormData {
  title: string;
  slug: string;
  description: string;
  content: string;
  service_category: string;
  location: string;
  completion_date: string;
  services_provided: string[];
  published: boolean;
  featured_image?: string;
  gallery?: any;
}

const AdminEditProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isNewProject = !id;
  
  const [formData, setFormData] = useState<ProjectFormData>({
    title: '',
    slug: '',
    description: '',
    content: '',
    service_category: '',
    location: '',
    completion_date: '',
    services_provided: [],
    published: false,
  });
  
  const [isSlugManuallyChanged, setIsSlugManuallyChanged] = useState(false);
  const [serviceInput, setServiceInput] = useState('');
  const [loading, setLoading] = useState(!isNewProject);
  
  // Fetch project data if editing an existing project
  const { data: project, isLoading: isLoadingProject } = useQuery({
    queryKey: ['project', id],
    queryFn: () => fetchProjectById(id as string),
    enabled: !isNewProject,
  });
  
  // Set form data when project data is loaded
  useEffect(() => {
    if (project) {
      setFormData({
        title: project.title || '',
        slug: project.slug || '',
        description: project.description || '',
        content: project.content || '',
        service_category: project.service_category || '',
        location: project.location || '',
        completion_date: project.completion_date || '',
        services_provided: project.services_provided || [],
        published: project.published || false,
        featured_image: project.featured_image || '',
        gallery: project.gallery || null,
      });
      setLoading(false);
    }
  }, [project]);
  
  // Auto-generate slug from title if slug hasn't been manually changed
  useEffect(() => {
    if (!isSlugManuallyChanged && formData.title) {
      const generatedSlug = formData.title
        .toLowerCase()
        .replace(/[^\w\s-]/g, '')  // Remove special characters
        .replace(/\s+/g, '-')      // Replace spaces with hyphens
        .replace(/-+/g, '-');      // Replace multiple hyphens with single hyphen
      
      setFormData(prev => ({ ...prev, slug: generatedSlug }));
    }
  }, [formData.title, isSlugManuallyChanged]);
  
  // Handle form field changes
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    
    // If the slug field is being manually edited, set the flag
    if (name === 'slug') {
      setIsSlugManuallyChanged(true);
    }
    
    setFormData(prev => ({ ...prev, [name]: value }));
  };
  
  // Handle toggle for published status
  const handlePublishedToggle = (checked: boolean) => {
    setFormData(prev => ({ ...prev, published: checked }));
  };
  
  // Handle adding a service to the services_provided array
  const handleAddService = () => {
    if (serviceInput.trim()) {
      setFormData(prev => ({
        ...prev,
        services_provided: [...(prev.services_provided || []), serviceInput.trim()]
      }));
      setServiceInput('');
    }
  };
  
  // Handle removing a service from the services_provided array
  const handleRemoveService = (index: number) => {
    setFormData(prev => ({
      ...prev,
      services_provided: (prev.services_provided || []).filter((_, i) => i !== index)
    }));
  };
  
  // Create project mutation
  const createProjectMutation = useMutation({
    mutationFn: createProject,
    onSuccess: () => {
      toast.success('Project created successfully');
      navigate('/admin/projects');
      queryClient.invalidateQueries({ queryKey: ['projects'] });
    },
    onError: (error: Error) => {
      toast.error(`Error creating project: ${error.message}`);
    }
  });
  
  // Update project mutation
  const updateProjectMutation = useMutation({
    mutationFn: (data: { id: string; project: ProjectUpdate }) => 
      updateProject(data.id, data.project),
    onSuccess: () => {
      toast.success('Project updated successfully');
      navigate('/admin/projects');
      queryClient.invalidateQueries({ queryKey: ['projects'] });
    },
    onError: (error: Error) => {
      toast.error(`Error updating project: ${error.message}`);
    }
  });
  
  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validation
    if (!formData.title) {
      toast.error('Title is required');
      return;
    }
    
    if (!formData.slug) {
      toast.error('Slug is required');
      return;
    }
    
    if (!/^[a-z0-9-]+$/.test(formData.slug)) {
      toast.error('Slug must contain only lowercase letters, numbers, and hyphens');
      return;
    }
    
    if (isNewProject) {
      createProjectMutation.mutate(formData as ProjectCreate);
    } else if (id) {
      updateProjectMutation.mutate({ id, project: formData as ProjectUpdate });
    }
  };

  const navigateBack = () => {
    navigate('/admin/projects');
  };

  return (
    <AdminLayout title={isNewProject ? "Create Project" : "Edit Project"}>
      <ProjectForm
        formData={formData}
        loading={isLoadingProject}
        saving={createProjectMutation.isPending || updateProjectMutation.isPending}
        isNewProject={isNewProject}
        serviceInput={serviceInput}
        setServiceInput={setServiceInput}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleAddService={handleAddService}
        handleRemoveService={handleRemoveService}
        handlePublishedToggle={handlePublishedToggle}
        navigateBack={navigateBack}
      />
    </AdminLayout>
  );
};

export default AdminEditProject;
