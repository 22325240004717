import React from 'react';
import { useNavigate } from 'react-router-dom';
import LocationsDesktopTable from './LocationsDesktopTable';
import LocationMobileCard from './LocationMobileCard';
import { Location } from '@/types/location';
import { Skeleton } from '@/components/ui/skeleton';

interface LocationsTableProps {
  locations: Location[];
  loading: boolean;
}

const LocationsTable: React.FC<LocationsTableProps> = ({
  locations,
  loading,
}) => {
  const navigate = useNavigate();
  const [filteredLocations, setFilteredLocations] =
    React.useState<Location[]>(locations);

  // Update filteredLocations when locations prop changes
  React.useEffect(() => {
    setFilteredLocations(locations);
  }, [locations]);

  const handleEdit = (id: string | number) => {
    // Updated navigation path to match the route defined in App.tsx
    navigate(`/admin/edit-location/${id}`);
  };

  const handleView = (slug: string) => {
    window.open(`/locations/${slug}`, '_blank');
  };

  if (loading) {
    return (
      <div className='space-y-4'>
        <Skeleton className='h-12 w-full' />
        <Skeleton className='h-64 w-full' />
      </div>
    );
  }

  const emptyStateMessage = (
    <div className='text-center py-10 text-muted-foreground'>
      No locations found. Create your first location to get started.
    </div>
  );

  return (
    <>
      <LocationsDesktopTable
        locations={filteredLocations}
        handleEdit={handleEdit}
        handleView={handleView}
        setLocations={setFilteredLocations}
      />

      <div className='md:hidden space-y-4'>
        {filteredLocations.length === 0 ? (
          <div className='rounded-md border p-4'>{emptyStateMessage}</div>
        ) : (
          filteredLocations.map((location) => (
            <LocationMobileCard
              key={location.id}
              location={location}
              handleEdit={handleEdit}
              handleView={handleView}
              locations={filteredLocations}
              setLocations={setFilteredLocations}
            />
          ))
        )}
      </div>
    </>
  );
};

export default LocationsTable;
