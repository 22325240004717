
import React, { Suspense } from 'react';
import Header from './header';
import Footer from './footer';
import { Skeleton } from './ui/skeleton';
import AdminBar from './admin/AdminBar';
import ScrollToTop from './ScrollToTop';
import { ModalProvider } from './shared/modal';
import ContactFormModal from './shared/modal/ContactFormModal';

interface LayoutProps {
  children: React.ReactNode;
}

const PageLoadingState: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-16 min-h-[50vh] flex items-center justify-center">
      <div className="flex flex-col items-center gap-6 max-w-xl w-full">
        <div className="flex flex-col w-full gap-4">
          <Skeleton className="h-10 w-3/4 bg-gray-200" />
          <Skeleton className="h-4 w-full bg-gray-200" />
          <Skeleton className="h-4 w-5/6 bg-gray-200" />
          <Skeleton className="h-4 w-2/3 bg-gray-200" />
        </div>
        <div className="flex items-center justify-center w-16 h-16">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-D00B0E"></div>
        </div>
      </div>
    </div>
  );
};

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <ModalProvider>
      <ScrollToTop />
      <AdminBar />
      <Header />
      <main>
        <Suspense fallback={<PageLoadingState />}>
          {children}
        </Suspense>
      </main>
      <Footer />
      <ContactFormModal />
    </ModalProvider>
  );
};
