
/**
 * Generate HTML templates for blog post layouts
 */

export const generateTemplate = (templateType: string): string => {
  switch(templateType) {
    case 'twoColumn':
      return `
<div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
  <div>
    <h3>Left Column Heading</h3>
    <p>Content for the left column goes here. Replace this text with your content.</p>
  </div>
  <div>
    <h3>Right Column Heading</h3>
    <p>Content for the right column goes here. Replace this text with your content.</p>
  </div>
</div>`;
      
    case 'callout':
      return `
<div class="bg-gray-100 border-l-4 border-gray-500 p-4 mb-6">
  <p class="font-bold">Important Note:</p>
  <p>This is a callout box. Use it to highlight important information. Replace this text with your content.</p>
</div>`;
      
    case 'imageText':
      return `
<div class="flex flex-col md:flex-row gap-6 items-center mb-6">
  <div class="md:w-1/2">
    <img src="https://example.com/your-image.jpg" alt="Description of image" class="rounded-lg shadow-md" />
  </div>
  <div class="md:w-1/2">
    <h3>Heading Next to Image</h3>
    <p>Text content that appears next to the image. Replace this with your description.</p>
  </div>
</div>`;
      
    case 'quote':
      return `
<blockquote class="border-l-4 border-gray-300 pl-4 py-2 italic mb-6">
  <p>"This is a quote or testimonial that you want to highlight. Replace this text with your quote."</p>
  <cite>— Source Name</cite>
</blockquote>`;
      
    default:
      return '';
  }
};
