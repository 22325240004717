
import { useState, useEffect } from 'react';
import { toast } from 'sonner';
import { 
  getWPCredentials, 
  saveWPCredentials, 
  getMigrationHistory,
  MigrationCredentials,
  MigrationHistory
} from '@/lib/supabase/wordpress';
import { supabase } from '@/lib/supabase/client';

export const useWordPressMigration = () => {
  const [credentials, setCredentials] = useState<MigrationCredentials | null>(null);
  const [migrationHistory, setMigrationHistory] = useState<MigrationHistory[]>([]);
  const [loading, setLoading] = useState(true);
  const [migrating, setMigrating] = useState(false);
  const [saving, setSaving] = useState(false);

  // Load WordPress credentials and migration history
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const [creds, history] = await Promise.all([
          getWPCredentials(),
          getMigrationHistory()
        ]);
        
        setCredentials(creds || null);
        setMigrationHistory(history);
      } catch (error) {
        console.error('Error loading WordPress migration data:', error);
        toast.error('Failed to load WordPress migration data');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  // Save WordPress credentials
  const handleSaveCredentials = async (data: MigrationCredentials) => {
    try {
      setSaving(true);
      console.log('Saving WordPress credentials:', data);
      
      // Ensure we don't pass empty values - let the API function handle this
      const sanitizedData: MigrationCredentials = {
        wp_url: data.wp_url,
        api_key: data.api_key || undefined,
        wp_username: data.wp_username || undefined,
        wp_password: data.wp_password || undefined
      };
      
      // Only include ID if it exists
      if (data.id) {
        sanitizedData.id = data.id;
      }
      
      console.log('Sanitized data:', sanitizedData);
      const savedCredentials = await saveWPCredentials(sanitizedData);
      console.log('Credentials saved successfully:', savedCredentials);
      setCredentials(savedCredentials);
      toast.success('WordPress credentials saved successfully');
      return savedCredentials;
    } catch (error) {
      console.error('Error saving WordPress credentials:', error);
      if (error instanceof Error) {
        toast.error(`Failed to save WordPress credentials: ${error.message}`);
      } else {
        toast.error('Failed to save WordPress credentials');
      }
      throw error;
    } finally {
      setSaving(false);
    }
  };

  // Start WordPress migration using edge function
  const handleStartMigration = async () => {
    if (!credentials?.wp_url) {
      toast.error('WordPress URL is required');
      return;
    }

    try {
      setMigrating(true);
      toast.info('Starting WordPress migration...');
      
      // Prepare the payload with all available auth methods
      const payload: Record<string, string> = {
        wp_url: credentials.wp_url,
      };
      
      // Add any available auth credentials
      if (credentials.api_key) {
        payload.api_key = credentials.api_key;
      }
      
      if (credentials.wp_username) {
        payload.wp_username = credentials.wp_username;
      }
      
      if (credentials.wp_password) {
        payload.wp_password = credentials.wp_password;
      }
      
      // Call the edge function to perform the migration
      const { data, error } = await supabase.functions.invoke('wp-migration', {
        body: payload
      });
      
      if (error) {
        throw error;
      }
      
      // Refresh migration history
      const history = await getMigrationHistory();
      setMigrationHistory(history);
      
      toast.success(`Migration completed: ${data.posts_migrated} posts, ${data.categories_migrated} categories, ${data.tags_migrated} tags migrated`);
      
      return data;
    } catch (error) {
      console.error('Error during WordPress migration:', error);
      toast.error('WordPress migration failed');
      throw error;
    } finally {
      setMigrating(false);
    }
  };

  return {
    credentials,
    migrationHistory,
    loading,
    migrating,
    saving,
    saveCredentials: handleSaveCredentials,
    startMigration: handleStartMigration
  };
};
