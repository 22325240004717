
import React from 'react';
import { Check, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { HeroBackground } from '@/types/heroBackground';
import { SiteSettings } from '@/types/settings';
import { toast } from 'sonner';

interface BackgroundCardProps {
  background: HeroBackground;
  isActive: boolean;
  onUse: (background: HeroBackground) => Promise<void>;
  onDelete: (background: HeroBackground) => Promise<void>;
}

const BackgroundCard: React.FC<BackgroundCardProps> = ({
  background,
  isActive,
  onUse,
  onDelete,
}) => {
  return (
    <Card
      key={background.id}
      className={`overflow-hidden transition-all group ${
        isActive
          ? 'ring-2 ring-primary ring-offset-2 shadow-md'
          : 'hover:shadow-lg hover:scale-102 hover:border-primary/30'
      }`}>
      <div className='aspect-video relative'>
        {/* Background image */}
        <div
          className='w-full h-48 bg-gray-100 bg-cover bg-center cursor-pointer transition-transform duration-300 group-hover:scale-105'
          style={{ backgroundImage: `url(${background.url})` }}
          title={background.name}
          onClick={() => onUse(background)}
        />

        {/* Dark overlay with buttons on hover */}
        <div className='absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center'>
          <div className='flex gap-2'>
            <Button
              variant='secondary'
              size='sm'
              className='bg-white/90 hover:bg-white text-gray-800'
              onClick={async (e) => {
                e.stopPropagation();
                await onUse(background);
                toast.success(`"${background.name}" set as active background`);
              }}>
              <Check className='h-4 w-4 mr-1' /> Use
            </Button>
            <Button
              variant='destructive'
              size='sm'
              className='bg-red-600/90 hover:bg-red-600'
              onClick={async (e) => {
                e.stopPropagation();
                if (window.confirm(`Delete background "${background.name}"?`)) {
                  await onDelete(background);
                  toast.success(`"${background.name}" deleted`);
                }
              }}>
              <Trash2 className='h-4 w-4 mr-1' /> Delete
            </Button>
          </div>
        </div>

        {/* Always visible active indicator */}
        {isActive && (
          <div className='absolute top-2 left-2 px-2 py-1 bg-green-500 text-white text-xs font-medium rounded-full'>
            Active
          </div>
        )}
      </div>
      <div className='p-3 flex justify-between items-center'>
        <p className='text-sm font-medium truncate'>{background.name}</p>
      </div>
    </Card>
  );
};

export default BackgroundCard;
