import React from 'react';
import { Image, Plus, X } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';

interface BackgroundActionsProps {
  hasActiveBackground: boolean;
  isUploading: boolean;
  isSaving: boolean;
  onUpload: () => void;
  onAddToLibrary: () => void;
  onClearBackground: () => void;
}

const BackgroundActions: React.FC<BackgroundActionsProps> = ({
  hasActiveBackground,
  isUploading,
  isSaving,
  onUpload,
  onAddToLibrary,
  onClearBackground,
}) => {
  return (
    <div className='mt-6 pt-6 border-t border-gray-200'>
      <div className='bg-gray-50 rounded-xl p-5 shadow-sm'>
        <h4 className='font-medium text-gray-800 mb-4'>Background Actions</h4>
        <div className='flex flex-col md:flex-row md:justify-between gap-6'>
          {/* Quick upload */}
          <div className='bg-white p-4 rounded-lg shadow-sm border border-gray-100 flex-1'>
            <Label
              htmlFor='custom-hero-bg'
              className='block text-sm font-medium text-gray-700 mb-2'>
              Quick Upload
            </Label>
            <p className='text-xs text-gray-500 mb-3'>
              Upload a new image and apply it immediately as your hero
              background.
            </p>
            <div className='flex items-center gap-2'>
              <Button
                variant='default'
                className='flex items-center gap-2 bg-primary hover:bg-primary/90 relative overflow-hidden'
                disabled={isSaving || isUploading}
                onClick={onUpload}>
                {isUploading && (
                  <div className='absolute inset-0 bg-black/20 flex items-center justify-center'>
                    <div className='h-5 w-5 border-2 border-white border-t-transparent rounded-full animate-spin'></div>
                  </div>
                )}
                <Image className='h-4 w-4' />
                {isUploading ? 'Uploading...' : 'Upload & Apply'}
              </Button>
            </div>
            <p className='mt-3 text-xs text-gray-500'>
              Recommended size: 2000x1200px (16:9). Max 5MB.
            </p>
          </div>

          {/* Other actions */}
          <div className='bg-white p-4 rounded-lg shadow-sm border border-gray-100 flex-1'>
            <h5 className='text-sm font-medium text-gray-700 mb-2'>
              Other Actions
            </h5>
            <p className='text-xs text-gray-500 mb-3'>
              Manage your current hero background.
            </p>
            <div className='flex flex-col gap-2'>
              <Button
                variant='outline'
                size='sm'
                onClick={onAddToLibrary}
                className='justify-start'>
                <Plus className='h-4 w-4 mr-2' />
                Add to Library
              </Button>

              {hasActiveBackground ? (
                <Button
                  variant='outline'
                  size='sm'
                  className='text-red-600 border-red-200 hover:bg-red-50 justify-start'
                  onClick={onClearBackground}>
                  <X className='h-4 w-4 mr-2' />
                  Clear Background
                </Button>
              ) : (
                <Button
                  variant='outline'
                  size='sm'
                  disabled
                  className='text-gray-400 justify-start'>
                  <X className='h-4 w-4 mr-2' />
                  Clear Background
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackgroundActions;
