
import { ServiceDetail } from '@/types/service';
import { getFallbackServiceContent } from '@/data/fallbackServiceContent';
import { queryClient } from './queryClient';

// Cache for storing service data by slug
const serviceCache: Record<string, ServiceDetail> = {};

// Initialize cache with fallback services
export const initializeServiceCache = async () => {
  const fallbackServices = getFallbackServiceContent();

  // Populate cache with fallback data for quick access
  Object.values(fallbackServices).forEach((service) => {
    // Ensure ID is a string
    const serviceWithStringId = {
      ...service,
      id: String(service.id)
    };
    serviceCache[service.slug] = serviceWithStringId;

    // Also pre-populate the React Query cache for immediate access
    queryClient.setQueryData(['service', service.slug], serviceWithStringId);
  });

  // No longer automatically creating services in the database
  console.log('Service cache initialized with fallback data');
};

// Get service from cache or return null
export const getCachedService = (slug: string): ServiceDetail | null => {
  if (!slug) {
    return null;
  }

  // First check React Query cache
  const queryCache = queryClient.getQueryData<ServiceDetail>(['service', slug]);
  if (queryCache) {
    // Ensure ID is a string
    return {
      ...queryCache,
      id: String(queryCache.id)
    };
  }

  // Then check local cache
  const cachedService = serviceCache[slug];
  if (cachedService) {
    // Ensure ID is a string
    return {
      ...cachedService,
      id: String(cachedService.id)
    };
  }
  
  return null;
};

// Add a service to the cache
export const cacheService = (service: ServiceDetail): void => {
  if (service && service.slug) {
    // Ensure ID is a string
    const serviceWithStringId = {
      ...service,
      id: String(service.id)
    };
    
    // Update local cache
    serviceCache[service.slug] = serviceWithStringId;

    // Also update React Query cache
    queryClient.setQueryData(['service', service.slug], serviceWithStringId);

    // Log caching for debugging
    console.log(`Service cached: ${service.slug}`);
  }
};

// Invalidate a service in the cache to force a refresh
export const invalidateServiceCache = (slug: string): void => {
  if (slug) {
    // Remove from local cache
    if (serviceCache[slug]) {
      delete serviceCache[slug];
      console.log(`Service removed from local cache: ${slug}`);
    }

    // Invalidate in React Query cache
    queryClient.invalidateQueries({ queryKey: ['service', slug] });
    console.log(`Service invalidated in React Query cache: ${slug}`);
  }

  // Also invalidate the entire services list to refresh any service listings
  queryClient.invalidateQueries({ queryKey: ['services'] });
  console.log('Services list invalidated');
};

// Initialize cache when the module loads
initializeServiceCache();
