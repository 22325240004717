
import { useState } from 'react';
import { GMBLocation, GMBLocationCreate } from '@/types/gmb-location';
import { createGMBLocation, updateGMBLocation } from '@/lib/supabase/gmb-locations';
import { toast } from 'sonner';

const initialFormState: GMBLocationCreate = {
  google_place_id: '',
  location_name: '',
  address: '',
  city: '',
  province: '',
  postal_code: '',
  country: 'Canada',
  phone_number: '',
  website_url: '',
  is_active: true,
  location_id: ''
};

export const useGMBLocationForm = () => {
  const [formData, setFormData] = useState<GMBLocationCreate>(initialFormState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentLocation, setCurrentLocation] = useState<GMBLocation | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    
    if (type === 'checkbox') {
      const target = e.target as HTMLInputElement;
      setFormData(prev => ({
        ...prev,
        [name]: target.checked
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSelectChange = (name: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      [name]: value === 'none' ? '' : value
    }));
  };

  const handleToggleChange = (name: string, checked: boolean) => {
    setFormData(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const handleEdit = (location: GMBLocation) => {
    setCurrentLocation(location);
    setFormData({
      google_place_id: location.google_place_id,
      location_name: location.location_name,
      address: location.address || '',
      city: location.city || '',
      province: location.province || '',
      postal_code: location.postal_code || '',
      country: location.country || 'Canada',
      phone_number: location.phone_number || '',
      website_url: location.website_url || '',
      is_active: location.is_active,
      location_id: location.location_id || ''
    });
    setIsEditMode(true);
    setIsDialogOpen(true);
  };

  const handleAddNew = () => {
    setFormData(initialFormState);
    setCurrentLocation(null);
    setIsEditMode(false);
    setIsDialogOpen(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      if (isEditMode && currentLocation) {
        // Update existing location
        const updated = await updateGMBLocation(currentLocation.id, formData);
        if (updated) {
          toast.success('Location updated successfully');
          setIsDialogOpen(false);
        }
      } else {
        // Create new location
        const created = await createGMBLocation(formData);
        if (created) {
          toast.success('Location created successfully');
          setIsDialogOpen(false);
        }
      }
    } catch (error) {
      console.error('Error saving location:', error);
      toast.error('Failed to save location');
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setFormData(initialFormState);
  };

  return {
    formData,
    setFormData,
    isSubmitting,
    setIsSubmitting,
    handleInputChange,
    handleSelectChange,
    handleToggleChange,
    resetForm,
    isDialogOpen,
    setIsDialogOpen,
    isEditMode,
    currentLocation,
    handleEdit,
    handleAddNew,
    handleSubmit
  };
};
