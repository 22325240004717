import { useCallback } from 'react';
import { useTracking } from '../useTracking';

export interface ServiceTrackingProps {
  serviceId?: string;
  serviceName?: string;
  serviceSlug?: string;
  locationName?: string;
}

type TrackingProps = Record<string, string | number | boolean>;

export const useServiceTracking = (props: ServiceTrackingProps) => {
  const { trackEvent } = useTracking();
  const { serviceId, serviceName, serviceSlug, locationName } = props;

  const trackServiceView = useCallback(
    (additionalProps?: TrackingProps) => {
      trackEvent({
        name: 'service_view',
        props: {
          service_id: serviceId,
          service_name: serviceName,
          service_slug: serviceSlug,
          location: locationName,
          page_url: window.location.href,
          ...additionalProps,
        },
      });
    },
    [serviceId, serviceName, serviceSlug, locationName, trackEvent]
  );

  const trackServiceInteraction = useCallback(
    (
      action: 'click' | 'hover' | 'scroll' | 'expand' | 'collapse',
      element: string,
      additionalProps?: TrackingProps
    ) => {
      trackEvent({
        name: 'service_interaction',
        props: {
          action,
          element,
          service_id: serviceId,
          service_name: serviceName,
          service_slug: serviceSlug,
          location: locationName,
          page_url: window.location.href,
          ...additionalProps,
        },
      });
    },
    [serviceId, serviceName, serviceSlug, locationName, trackEvent]
  );

  const trackServiceQuoteRequest = useCallback(
    (source: string, additionalProps?: TrackingProps) => {
      trackEvent({
        name: 'quote_request',
        props: {
          source,
          service_id: serviceId,
          service_name: serviceName,
          service_slug: serviceSlug,
          location: locationName,
          page_url: window.location.href,
          ...additionalProps,
        },
      });
    },
    [serviceId, serviceName, serviceSlug, locationName, trackEvent]
  );

  const trackServicePhoneCall = useCallback(
    (source: string, additionalProps?: TrackingProps) => {
      trackEvent({
        name: 'phone_call',
        props: {
          source,
          service_id: serviceId,
          service_name: serviceName,
          service_slug: serviceSlug,
          location: locationName,
          page_url: window.location.href,
          ...additionalProps,
        },
      });
    },
    [serviceId, serviceName, serviceSlug, locationName, trackEvent]
  );

  return {
    trackServiceView,
    trackServiceInteraction,
    trackServiceQuoteRequest,
    trackServicePhoneCall,
  };
};
