import { supabase } from '../client';
import { Project, ProjectCreate, ProjectUpdate } from './types';

// Function to create a project
export async function createProject(projectData: ProjectCreate): Promise<Project> {
  try {
    // Check if slug already exists
    const { data: existingProject } = await supabase
      .from('projects')
      .select('id')
      .eq('slug', projectData.slug)
      .single();
    
    if (existingProject) {
      throw new Error(`A project with slug "${projectData.slug}" already exists`);
    }
    
    // Ensure services_provided is an array or default to empty array
    const dataToInsert = {
      ...projectData,
      services_provided: projectData.services_provided || []
    };
    
    const { data, error } = await supabase
      .from('projects')
      .insert([dataToInsert])
      .select()
      .single();
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error creating project:', error);
    throw error;
  }
}

// Function to update a project
export async function updateProject(id: string, projectData: ProjectUpdate): Promise<Project> {
  try {
    // Ensure services_provided is an array or keep it unchanged
    const dataToUpdate = {
      ...projectData,
      services_provided: projectData.services_provided || []
    };
    
    const { data, error } = await supabase
      .from('projects')
      .update(dataToUpdate)
      .eq('id', id)
      .select()
      .single();
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error(`Error updating project with id ${id}:`, error);
    throw error;
  }
}

// Function to delete a project
export async function deleteProject(id: string): Promise<boolean> {
  try {
    const { error } = await supabase
      .from('projects')
      .delete()
      .eq('id', id);
    
    if (error) throw error;
    return true;
  } catch (error) {
    console.error(`Error deleting project with id ${id}:`, error);
    throw error;
  }
}
