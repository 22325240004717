
import React from 'react';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { PlusCircle, X } from 'lucide-react';

interface FAQFormProps {
  faq: {
    question: string;
    answer: string;
  }[];
  addFAQ: () => void;
  removeFAQ: (index: number) => void;
  updateFAQ: (index: number, field: string, value: string, faqIndex: number) => void;
}

const FAQForm: React.FC<FAQFormProps> = ({
  faq,
  addFAQ,
  removeFAQ,
  updateFAQ
}) => {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <Label>Frequently Asked Questions</Label>
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={addFAQ}
          className="flex items-center gap-1"
        >
          <PlusCircle className="w-4 h-4" />
          Add FAQ
        </Button>
      </div>

      {faq && faq.map((item, index) => (
        <div key={index} className="p-4 border rounded-md space-y-4">
          <div className="flex items-center justify-between">
            <Label className="text-base font-medium">FAQ {index + 1}</Label>
            <Button
              type="button"
              variant="ghost"
              size="icon"
              onClick={() => removeFAQ(index)}
              className="flex-shrink-0"
            >
              <X className="w-4 h-4" />
            </Button>
          </div>

          <div className="space-y-2">
            <Label htmlFor={`faq-question-${index}`}>Question</Label>
            <Textarea
              id={`faq-question-${index}`}
              value={item.question}
              onChange={(e) => updateFAQ(index, 'question', e.target.value, index)}
              placeholder="FAQ question"
              rows={2}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor={`faq-answer-${index}`}>Answer</Label>
            <Textarea
              id={`faq-answer-${index}`}
              value={item.answer}
              onChange={(e) => updateFAQ(index, 'answer', e.target.value, index)}
              placeholder="FAQ answer"
              rows={4}
            />
          </div>
        </div>
      ))}

      {(!faq || faq.length === 0) && (
        <p className="text-sm text-gray-500">No FAQs added yet.</p>
      )}
    </div>
  );
};

export default FAQForm;
