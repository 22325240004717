
import { useQuery } from '@tanstack/react-query';
import { useState, useRef, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { FranchiseImage, FranchiseImageFilters } from './types';

// Cache time constants
const STALE_TIME = 5 * 60 * 1000; // 5 minutes
const CACHE_TIME = 10 * 60 * 1000; // 10 minutes

export const useReactQueryFranchiseImages = (
  currentPage: number,
  pageSize: number,
  filters: FranchiseImageFilters
) => {
  const [totalCount, setTotalCount] = useState(0);
  const prevQueryKey = useRef<any[]>([]);
  
  // Query key includes all parameters that affect the query
  const queryKey = ['franchise-images', currentPage, pageSize, filters];
  
  // Check if query parameters have changed
  const hasQueryChanged = JSON.stringify(prevQueryKey.current) !== JSON.stringify(queryKey);
  
  // Update stored query key when it changes
  useEffect(() => {
    if (hasQueryChanged) {
      prevQueryKey.current = queryKey;
    }
  }, [queryKey, hasQueryChanged]);

  // Define the query function
  const fetchImages = async () => {
    try {
      console.log('Fetching images with filters:', filters);
      
      // Check if auth session exists
      const { data: session } = await supabase.auth.getSession();
      if (!session.session) {
        throw new Error("User not authenticated");
      }
      
      // Start with a basic query
      let query = supabase
        .from('franchise_image_jobs')
        .select('*', { count: 'exact' });
      
      // Apply filters
      if (filters.franchise) {
        query = query.eq('franchise_region', filters.franchise);
      }
      
      if (filters.status) {
        query = query.eq('review_status', filters.status);
      }
      
      // Handle search
      if (filters.searchTerm && filters.searchTerm.trim() !== '') {
        const searchTerm = `%${filters.searchTerm.trim()}%`;
        
        // Search for either job number or filename
        query = query.or(`job_number.ilike.${searchTerm},file_name.ilike.${searchTerm}`);
      }
      
      // Get total count first (without pagination)
      const countQuery = query;
      const { count, error: countError } = await countQuery;
      
      if (countError) throw countError;
      
      // Set total count for pagination
      if (count !== null) {
        setTotalCount(count);
      }
      
      // Apply sorting and pagination to the original query
      query = query
        .order('created_on', { ascending: false })
        .range(
          (currentPage - 1) * pageSize, 
          currentPage * pageSize - 1
        );
      
      // Execute query
      const { data, error } = await query;
      
      if (error) throw error;
      
      return data as FranchiseImage[];
    } catch (err: any) {
      // Convert to a proper error that React Query can handle
      console.error('Error fetching franchise images:', err);
      throw new Error(err.message || 'Failed to fetch franchise images');
    }
  };

  // Use React Query to fetch and cache data
  const result = useQuery({
    queryKey,
    queryFn: fetchImages,
    staleTime: STALE_TIME,
    gcTime: CACHE_TIME,
    retry: (failureCount, error) => {
      // Implement exponential backoff - retry up to 3 times for most errors
      // but don't retry auth errors
      if (error.message === "User not authenticated") return false;
      return failureCount < 3;
    },
    retryDelay: (attemptIndex) => {
      // Exponential backoff: 1s, 2s, 4s, etc.
      return Math.min(1000 * 2 ** attemptIndex, 30000);
    }
  });

  return {
    ...result,
    images: result.data || [],
    totalCount,
    setTotalCount
  };
};
