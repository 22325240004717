
import React, { ChangeEvent } from 'react';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { ArrowLeft, Save, Loader2 } from 'lucide-react';

// Define the interface for the form data
interface ProjectFormValues {
  title: string;
  slug: string;
  description: string;
  content: string;
  service_category?: string;
  location?: string;
  completion_date?: string;
  services_provided?: string[];
  published: boolean;
  featured_image?: string;
  gallery?: any;
}

interface ProjectFormProps {
  formData: ProjectFormValues;
  loading: boolean;
  saving: boolean;
  isNewProject: boolean;
  serviceInput: string;
  setServiceInput: (value: string) => void;
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSubmit: (e: React.FormEvent) => void;
  handleAddService: () => void;
  handleRemoveService: (index: number) => void;
  handlePublishedToggle: (checked: boolean) => void;
  navigateBack: () => void;
}

const ProjectForm: React.FC<ProjectFormProps> = ({
  formData,
  loading,
  saving,
  isNewProject,
  serviceInput,
  setServiceInput,
  handleChange,
  handleSubmit,
  handleAddService,
  handleRemoveService,
  handlePublishedToggle,
  navigateBack
}) => {
  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  return (
    <>
      <div className="mb-6">
        <Button
          variant="outline"
          onClick={navigateBack}
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back to Projects
        </Button>
      </div>
      
      <form onSubmit={handleSubmit}>
        <Card className="p-6 space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <Label htmlFor="title" className="mb-2 block">
                Project Title *
              </Label>
              <Input
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Enter project title"
                required
              />
            </div>
            
            <div>
              <Label htmlFor="slug" className="mb-2 block">
                URL Slug *
              </Label>
              <Input
                id="slug"
                name="slug"
                value={formData.slug}
                onChange={handleChange}
                placeholder="project-slug"
                className="font-mono"
                required
              />
              <p className="text-xs text-muted-foreground mt-1">
                URL: example.com/projects/{formData.slug || '[slug]'}
              </p>
            </div>
          </div>
          
          <div>
            <Label htmlFor="description" className="mb-2 block">
              Short Description
            </Label>
            <Textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Brief description of the project (shown in project listings)"
              rows={3}
            />
          </div>
          
          <div>
            <Label htmlFor="content" className="mb-2 block">
              Project Content
            </Label>
            <Textarea
              id="content"
              name="content"
              value={formData.content}
              onChange={handleChange}
              placeholder="Full project details (HTML content supported)"
              rows={10}
              className="font-mono"
            />
            <p className="text-xs text-muted-foreground mt-1">
              HTML formatting is supported. Use headings, paragraphs, lists, etc. for structured content.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <Label htmlFor="service_category" className="mb-2 block">
                Service Category
              </Label>
              <Input
                id="service_category"
                name="service_category"
                value={formData.service_category || ''}
                onChange={handleChange}
                placeholder="E.g., Concrete Pouring, Stamped Concrete"
              />
            </div>
            
            <div>
              <Label htmlFor="location" className="mb-2 block">
                Project Location
              </Label>
              <Input
                id="location"
                name="location"
                value={formData.location || ''}
                onChange={handleChange}
                placeholder="E.g., Toronto, ON"
              />
            </div>
          </div>
          
          <div>
            <Label htmlFor="completion_date" className="mb-2 block">
              Completion Date
            </Label>
            <Input
              id="completion_date"
              name="completion_date"
              type="date"
              value={formData.completion_date || ''}
              onChange={handleChange}
            />
          </div>
          
          <div>
            <Label className="mb-2 block">
              Services Provided
            </Label>
            <div className="flex space-x-2 mb-2">
              <Input
                value={serviceInput}
                onChange={(e) => setServiceInput(e.target.value)}
                placeholder="Add a service"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddService();
                  }
                }}
              />
              <Button type="button" onClick={handleAddService}>
                Add
              </Button>
            </div>
            
            {(formData.services_provided || []).length > 0 && (
              <div className="flex flex-wrap gap-2 mt-2">
                {(formData.services_provided || []).map((service, index) => (
                  <div 
                    key={index} 
                    className="bg-muted px-3 py-1 rounded-full flex items-center"
                  >
                    <span>{service}</span>
                    <button
                      type="button"
                      className="ml-2 text-muted-foreground hover:text-destructive"
                      onClick={() => handleRemoveService(index)}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
          
          <div className="flex items-center space-x-2">
            <Switch
              id="published"
              checked={formData.published || false}
              onCheckedChange={handlePublishedToggle}
            />
            <Label htmlFor="published">
              {formData.published ? 'Published' : 'Draft'}
            </Label>
          </div>
          
          <div className="flex justify-end space-x-2">
            <Button
              type="button"
              variant="outline"
              onClick={navigateBack}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={saving}
            >
              {saving ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Saving...
                </>
              ) : (
                <>
                  <Save className="mr-2 h-4 w-4" />
                  Save Project
                </>
              )}
            </Button>
          </div>
        </Card>
      </form>
    </>
  );
};

export default ProjectForm;
