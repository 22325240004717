
import { useQuery } from '@tanstack/react-query';
import { fetchHeroBackgrounds } from '@/lib/supabase';
import { HeroBackground } from '@/types/heroBackground';

export const useHeroBackgroundsQuery = () => {
  return useQuery<HeroBackground[], Error>({
    queryKey: ['heroBackgrounds'],
    queryFn: fetchHeroBackgrounds,
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    gcTime: 30 * 60 * 1000, // Cache for 30 minutes (formerly cacheTime)
    refetchOnWindowFocus: false, // Don't refetch when window regains focus
    refetchOnMount: false, // Don't refetch when component mounts if we have cached data
  });
};
