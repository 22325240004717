
import React from 'react';
import { Image } from 'lucide-react';
import { Label } from '@/components/ui/label';

interface CurrentBackgroundPreviewProps {
  imageUrl: string;
}

const CurrentBackgroundPreview: React.FC<CurrentBackgroundPreviewProps> = ({ imageUrl }) => {
  if (imageUrl) {
    return (
      <div className='mb-6'>
        <Label className='block text-sm font-medium text-gray-700 mb-2'>
          Current Background
        </Label>
        <div className='aspect-video w-full max-w-3xl rounded-lg overflow-hidden border border-gray-200 shadow-md relative group'>
          {/* Background image with hover effect */}
          <div
            className='w-full h-full bg-cover bg-center transition-all duration-300 group-hover:scale-105'
            style={{ backgroundImage: `url(${imageUrl})` }}
            title='Current hero background'></div>

          {/* Dark overlay and text */}
          <div className='absolute inset-0 bg-black/60 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center'>
            <p className='text-white text-lg font-semibold'>
              Active Background
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='mb-6'>
      <Label className='block text-sm font-medium text-gray-700 mb-2'>
        Current Background
      </Label>
      <div className='aspect-video w-full max-w-3xl rounded-lg overflow-hidden border border-gray-200 bg-gray-50 flex items-center justify-center'>
        <div className='text-center p-6'>
          <Image className='h-12 w-12 mx-auto text-gray-300 mb-2' />
          <p className='text-gray-500'>No active background selected</p>
          <p className='text-sm text-gray-400 mt-1'>
            Upload or select one from the gallery below
          </p>
        </div>
      </div>
    </div>
  );
};

export default CurrentBackgroundPreview;
