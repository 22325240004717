import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Location } from '@/types/location';
import { Button } from '@/components/ui/button';
import { Edit, Eye, CheckCircle, Clock, AlertTriangle, Plus, RefreshCcw } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Badge } from '@/components/ui/badge';
import { getStatusIcon, updateLocationStatus } from './utils/locationStatus';
import { AdminTable, ColumnDef, TablePagination } from '@/components/admin/shared';
import { useAdminTable } from '@/hooks/useAdminTable';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useMediaQuery } from '@/hooks/use-mobile';

interface LocationsTableProps {
  locations: Location[];
  loading: boolean;
  error?: boolean;
  onRetry?: () => void;
}

const StandardizedLocationsTable: React.FC<LocationsTableProps> = ({
  locations,
  loading,
  error,
  onRetry
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');
  
  // Use our table state hook
  const {
    tableState,
    currentPageData,
    totalItems,
    setPage,
    setPageSize,
    setSearch,
  } = useAdminTable({
    data: locations,
    initialState: {
      pagination: {
        pageSize: 10,
        pageIndex: 0,
        pageCount: Math.ceil(locations.length / 10)
      }
    },
    searchFields: ['name', 'slug']
  });

  // Update locations when they change
  useEffect(() => {
    // The useAdminTable hook handles this internally now
  }, [locations]);

  const handleEdit = (id: string | number) => {
    navigate(`/admin/edit-location/${id}`);
  };

  const handleView = (slug: string) => {
    window.open(`/locations/${slug}`, '_blank');
  };

  const handleStatusChange = async (id: string | number, newStatus: string) => {
    await updateLocationStatus(id, newStatus, locations);
    // We would typically refetch data here, but for demo purposes we'll skip that
  };

  // Render a styled status badge
  const renderStatusBadge = (status: string) => {
    if (status === 'published') {
      return (
        <Badge className='bg-green-100 text-green-800 hover:bg-green-200'>
          <CheckCircle className='h-3 w-3 mr-1' /> Published
        </Badge>
      );
    } else if (status === 'draft') {
      return (
        <Badge className='bg-yellow-100 text-yellow-800 hover:bg-yellow-200'>
          <Clock className='h-3 w-3 mr-1' /> Draft
        </Badge>
      );
    } else {
      return (
        <Badge className='bg-red-100 text-red-800 hover:bg-red-200'>
          <AlertTriangle className='h-3 w-3 mr-1' /> {status}
        </Badge>
      );
    }
  };

  // Render a location type badge
  const renderTypeBadge = (isPrimary: boolean) => {
    return isPrimary ? (
      <Badge className='bg-blue-100 text-blue-800 hover:bg-blue-100'>
        Primary
      </Badge>
    ) : (
      <Badge className='bg-gray-100 text-gray-800 hover:bg-gray-100'>
        Secondary
      </Badge>
    );
  };

  // Define table columns
  const columns: ColumnDef<Location>[] = [
    {
      header: 'Name',
      cell: (row) => (
        <div className='flex items-center'>
          {getStatusIcon(row.status)}
          <span className='ml-2 font-medium'>{row.name}</span>
        </div>
      ),
    },
    {
      header: 'Slug',
      cell: (row) => <span className='text-muted-foreground'>/{row.slug}</span>,
    },
    {
      header: 'Type',
      cell: (row) => renderTypeBadge(row.is_primary),
    },
    {
      header: 'Status',
      cell: (row) => (
        <Select
          value={row.status || 'published'}
          onValueChange={(value) => handleStatusChange(row.id, value)}
        >
          <SelectTrigger className='h-9 w-[130px]'>
            <SelectValue placeholder='Status' />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value='published'>Published</SelectItem>
            <SelectItem value='draft'>Draft</SelectItem>
            <SelectItem value='archived'>Archived</SelectItem>
          </SelectContent>
        </Select>
      ),
    },
    {
      header: 'Actions',
      cell: (row) => (
        <div className='flex justify-end space-x-1'>
          <Button
            variant='ghost'
            size='sm'
            onClick={() => handleView(row.slug)}
            title='View location'
            className='h-8 w-8 p-0'
          >
            <Eye className='h-4 w-4' />
          </Button>
          <Button
            variant='ghost'
            size='sm'
            onClick={() => handleEdit(row.id)}
            title='Edit location'
            className='h-8 w-8 p-0'
          >
            <Edit className='h-4 w-4' />
          </Button>
        </div>
      ),
      className: 'text-right',
    },
  ];

  // Mobile card view for each location
  const MobileLocationCard = ({ location }: { location: Location }) => (
    <Card className="mb-4">
      <CardHeader className="pb-2">
        <CardTitle className="text-lg flex items-center gap-2">
          {getStatusIcon(location.status)}
          {location.name}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-2">
          <div className="flex justify-between">
            <span className="text-muted-foreground">Slug:</span>
            <span>/{location.slug}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-muted-foreground">Type:</span>
            <span>{renderTypeBadge(location.is_primary)}</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-muted-foreground">Status:</span>
            <Select
              value={location.status || 'published'}
              onValueChange={(value) => handleStatusChange(location.id, value)}
            >
              <SelectTrigger className='h-8 w-[110px]'>
                <SelectValue placeholder='Status' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='published'>Published</SelectItem>
                <SelectItem value='draft'>Draft</SelectItem>
                <SelectItem value='archived'>Archived</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex justify-end gap-2 pt-2">
            <Button
              variant='outline'
              size='sm'
              onClick={() => handleView(location.slug)}
            >
              <Eye className='h-4 w-4 mr-1' /> View
            </Button>
            <Button
              variant='default'
              size='sm'
              onClick={() => handleEdit(location.id)}
            >
              <Edit className='h-4 w-4 mr-1' /> Edit
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  );

  // Actions for the table
  const tableActions = (
    <Button 
      onClick={() => navigate('/admin/edit-location/new')}
      size="sm"
    >
      <Plus className="h-4 w-4 mr-1" /> Add Location
    </Button>
  );

  if (isMobile) {
    return (
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold">Locations</h2>
          {tableActions}
        </div>
        
        {loading ? (
          Array.from({ length: 3 }).map((_, i) => (
            <Card key={i} className="mb-4">
              <CardHeader className="pb-2">
                <div className="h-6 w-2/3 bg-muted rounded animate-pulse" />
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {Array.from({ length: 3 }).map((_, j) => (
                    <div key={j} className="h-5 bg-muted rounded animate-pulse" />
                  ))}
                </div>
              </CardContent>
            </Card>
          ))
        ) : error ? (
          <Card className="bg-red-50 border-red-200">
            <CardHeader>
              <CardTitle className="text-red-800">Error loading locations</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-red-600 mb-4">Unable to load location data. Please try again.</p>
              {onRetry && (
                <Button variant="outline" onClick={onRetry}>
                  <RefreshCcw className="h-4 w-4 mr-1" /> Retry
                </Button>
              )}
            </CardContent>
          </Card>
        ) : currentPageData.length === 0 ? (
          <Card className="bg-muted/20">
            <CardContent className="pt-6 text-center">
              <p className="text-muted-foreground mb-4">
                No locations found. Create your first location to get started.
              </p>
              <Button onClick={() => navigate('/admin/edit-location/new')}>
                <Plus className="h-4 w-4 mr-1" /> Add Location
              </Button>
            </CardContent>
          </Card>
        ) : (
          <>
            {currentPageData.map((location) => (
              <MobileLocationCard key={location.id} location={location} />
            ))}
            <TablePagination
              currentPage={tableState.pagination.pageIndex + 1}
              pageCount={tableState.pagination.pageCount}
              pageSize={tableState.pagination.pageSize}
              totalItems={totalItems}
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
            />
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      <AdminTable
        data={currentPageData}
        columns={columns}
        isLoading={loading}
        isError={error}
        onRetry={onRetry}
        emptyMessage="No locations found. Create your first location to get started."
        actions={tableActions}
        searchable={true}
        onSearch={setSearch}
      />
      
      <TablePagination
        currentPage={tableState.pagination.pageIndex + 1}
        pageCount={tableState.pagination.pageCount}
        pageSize={tableState.pagination.pageSize}
        totalItems={totalItems}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
      />
    </div>
  );
};

export default StandardizedLocationsTable;