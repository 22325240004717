
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminLayout from '@/components/admin/AdminLayout';
import { Button } from '@/components/ui/button';
import { PlusCircle } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { Location } from '@/types/location';
import LocationsTable from '@/components/admin/locations/LocationsTable';

const AdminLocations = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('locations')
        .select('*')
        .order('name', { ascending: true });

      if (error) {
        throw error;
      }

      setLocations(data || []);
    } catch (error) {
      console.error('Error fetching locations:', error);
      toast.error('Failed to load locations');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminLayout title="Locations">
      <div className="mb-6 flex justify-between items-center">
        <p className="text-gray-500">
          Manage service locations and city pages.
        </p>
        <Button onClick={() => navigate('/admin/edit-location/new')}>
          <PlusCircle className="mr-2 h-4 w-4" /> Add Location
        </Button>
      </div>

      <LocationsTable locations={locations} loading={loading} />
    </AdminLayout>
  );
};

export default AdminLocations;
