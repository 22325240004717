
import React from 'react';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';

interface CategoriesSidebarProps {
  categories: Array<{
    id: string;
    name: string;
    slug: string;
    post_count?: number;
  }>;
  onCategorySelect?: (categorySlug: string) => void;
  selectedCategory?: string | null;
}

const CategoriesSidebar: React.FC<CategoriesSidebarProps> = ({ 
  categories,
  onCategorySelect,
  selectedCategory
}) => {
  // Sort categories by post count (descending)
  const sortedCategories = [...categories].sort((a, b) => 
    (b.post_count || 0) - (a.post_count || 0)
  );

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h3 className="text-xl font-bold mb-4">Categories</h3>
      <ul className="space-y-2">
        {sortedCategories.length === 0 ? (
          <li className="text-gray-500">No categories available</li>
        ) : (
          sortedCategories.map((category) => (
            <li key={category.id}>
              {onCategorySelect ? (
                <button
                  onClick={() => onCategorySelect(category.slug)}
                  className={cn(
                    "w-full text-left text-gray-700 hover:text-[#D00B0E] flex items-center justify-between transition-colors",
                    selectedCategory === category.slug && "text-[#D00B0E] font-medium"
                  )}
                >
                  <span>{category.name}</span>
                  <span className={cn(
                    "bg-gray-100 text-gray-700 text-xs px-2 py-1 rounded-full",
                    selectedCategory === category.slug && "bg-[#D00B0E]/10 text-[#D00B0E]"
                  )}>
                    {category.post_count || 0}
                  </span>
                </button>
              ) : (
                <Link 
                  to={`/blog/category/${category.slug}`}
                  className="text-gray-700 hover:text-[#D00B0E] flex items-center justify-between"
                >
                  <span>{category.name}</span>
                  <span className="bg-gray-100 text-gray-700 text-xs px-2 py-1 rounded-full">
                    {category.post_count || 0}
                  </span>
                </Link>
              )}
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default CategoriesSidebar;
