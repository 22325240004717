
import React, { useState } from 'react';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { toast } from 'sonner';
import AdminLayout from '@/components/admin/AdminLayout';

const AdminInviteUser = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState<string[]>([]);

  const handleInviteUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Invite the user using Supabase Auth
      const { error } = await supabase.auth.admin.inviteUserByEmail(email);

      if (error) {
        toast.error(error.message);
      } else {
        toast.success(`Invitation sent to ${email}`);
        setInvitedUsers(prev => [...prev, email]);
        setEmail('');
      }
    } catch (error) {
      console.error('Error inviting user:', error);
      toast.error('An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminLayout title="Invite Users">
      <div className="max-w-2xl mx-auto">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h2 className="text-xl font-semibold mb-4">Invite New Admin User</h2>
          
          <form onSubmit={handleInviteUser} className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="email" className="block text-sm font-medium">Email Address</label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="new-admin@example.com"
                required
              />
            </div>
            
            <Button 
              type="submit" 
              disabled={loading}
            >
              {loading ? 'Sending Invitation...' : 'Send Invitation'}
            </Button>
          </form>
        </div>

        {invitedUsers.length > 0 && (
          <div className="mt-8 bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-xl font-semibold mb-4">Recently Invited Users</h2>
            <ul className="space-y-2">
              {invitedUsers.map((userEmail, index) => (
                <li key={index} className="p-3 bg-gray-50 rounded-md flex items-center">
                  <span className="mr-2">✉️</span> {userEmail}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default AdminInviteUser;
