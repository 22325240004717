
import React from 'react';
import { 
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import FileUploadArea from './FileUploadArea';

interface UploadDialogProps {
  onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  uploading: boolean;
}

const UploadDialog = ({ onFileChange, uploading }: UploadDialogProps) => {
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Upload Gallery Cover Image</DialogTitle>
      </DialogHeader>
      <div className="space-y-4 py-4">
        <p className="text-sm text-muted-foreground">
          Select an image file (JPG, PNG) up to 5MB in size.
        </p>
        <FileUploadArea 
          onFileChange={onFileChange}
          uploading={uploading}
        />
      </div>
    </DialogContent>
  );
};

export default UploadDialog;
