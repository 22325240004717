import React from 'react';
import ContactButton from '../shared/ContactButton';

const ContactPromo: React.FC = () => {
  return (
    <div className="bg-gray-50 rounded-lg shadow p-6">
      <h3 className="text-xl font-bold mb-4">Need Masonry Help?</h3>
      <p className="text-gray-700 mb-4">
        Have questions about your masonry project? Our experts are here to help!
      </p>
      <ContactButton 
        className="inline-block font-medium px-4 py-2 rounded"
        variant="primary"
      >
        Get a Free Quote
      </ContactButton>
    </div>
  );
};

export default ContactPromo;
