
import React from 'react';

const BrickPatternBackground: React.FC = () => {
  return (
    <div className="w-full h-48 md:h-64 lg:h-80 relative overflow-hidden">
      <div className="absolute inset-0 bg-D00B0E/10"></div>
      <div 
        className="w-full h-full" 
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%23D00B0E' fill-opacity='0.1'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
          backgroundSize: '42px 44px',
        }}
      ></div>
      <div className="absolute inset-0 bg-gradient-to-b from-gray-800/40 to-gray-900/60"></div>
    </div>
  );
};

export default BrickPatternBackground;
