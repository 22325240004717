import React from 'react';
import { Facebook, Instagram, Twitter } from 'lucide-react';
import LazyImage from '@/components/ui/lazy-image';
import { SiteSettings } from '@/types/settings';
interface FooterCompanyInfoProps {
  settings: SiteSettings;
}
const FooterCompanyInfo: React.FC<FooterCompanyInfoProps> = ({
  settings
}) => {
  return <div className="space-y-6">
      <div className="flex items-center">
        <img src="/images/fmb-logo-2023-white.svg" alt="Fix My Brick Logo" className="h-16 md:h-20 w-auto object-contain" height={80} width="auto" />
      </div>
      <p className="text-gray-300 leading-relaxed text-sm">Trusted masonry experts with 20+ years of experience, proudly helping thousands of homeowners across Southern Ontario restore and protect their homes.</p>
      <div className="flex space-x-4">
        <a href={settings.facebook_url} target="_blank" rel="noopener noreferrer" className="bg-gray-800/80 hover:bg-white/20 p-2.5 rounded-full transition-colors group" aria-label="Facebook">
          <Facebook size={18} className="text-gray-300 group-hover:text-white group-hover:scale-110 transition-all" />
        </a>
        <a href={settings.instagram_url} target="_blank" rel="noopener noreferrer" className="bg-gray-800/80 hover:bg-white/20 p-2.5 rounded-full transition-colors group" aria-label="Instagram">
          <Instagram size={18} className="text-gray-300 group-hover:text-white group-hover:scale-110 transition-all" />
        </a>
        <a href={settings.twitter_url} target="_blank" rel="noopener noreferrer" className="bg-gray-800/80 hover:bg-white/20 p-2.5 rounded-full transition-colors group" aria-label="Twitter">
          <Twitter size={18} className="text-gray-300 group-hover:text-white group-hover:scale-110 transition-all" />
        </a>
      </div>
    </div>;
};
export default FooterCompanyInfo;