
import { useState, Dispatch, SetStateAction } from 'react';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { generateSlug as generateSlugUtil } from '@/utils/serviceFormUtils';
import { GalleryFormValues } from '@/components/admin/galleries/form/types';

export const useGallerySlugValidation = (
  gallery: GalleryFormValues,
  setGallery: Dispatch<SetStateAction<GalleryFormValues>>,
  id?: string
) => {
  const [validatingSlug, setValidatingSlug] = useState(false);
  
  const generateSlug = () => {
    const slug = generateSlugUtil(gallery.title);
    setGallery({ ...gallery, slug });
    validateSlug(slug);
  };

  const validateSlug = async (slug: string) => {
    if (!slug) return true;
    
    try {
      setValidatingSlug(true);
      
      const { data, error } = await supabase
        .from('galleries')
        .select('id')
        .eq('slug', slug)
        .neq('id', id || '0')
        .maybeSingle();
        
      if (error) throw error;
      
      if (data) {
        toast.error('This slug is already in use. Please choose another.');
        return false;
      }
      
      return true;
    } catch (error) {
      console.error('Error validating slug:', error);
      return false;
    } finally {
      setValidatingSlug(false);
    }
  };

  return {
    validatingSlug,
    generateSlug,
    validateSlug
  };
};
