import React from 'react';
import { Phone, Mail } from 'lucide-react';

interface FooterContactInfoProps {
  phoneNumber: string;
  email: string;
}

const FooterContactInfo: React.FC<FooterContactInfoProps> = ({
  phoneNumber,
  email
}) => {
  return (
    <div className="space-y-8">
      <h3 className="text-xl font-semibold text-white border-b border-gray-600 pb-3">Contact Us</h3>
      
      {/* Phone and Email */}
      <div className="space-y-6">
        <div className="flex items-center group">
          <Phone size={20} className="mr-4 text-gray-400 group-hover:text-white transition-colors" />
          <a href={`tel:${phoneNumber}`} className="text-gray-300 hover:text-white transition-colors text-lg">
            {phoneNumber}
          </a>
        </div>
        
        <div className="flex items-center group">
          <Mail size={20} className="mr-4 text-gray-400 group-hover:text-white transition-colors" />
          <a href={`mailto:${email}`} className="text-gray-300 hover:text-white transition-colors text-lg">
            {email}
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterContactInfo;
