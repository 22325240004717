
import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { SiteSettings } from '@/types/settings';
import { toast } from 'sonner';
import { v4 as uuidv4 } from 'uuid';

// Default settings with compatibility for database schema
const defaultSettings: SiteSettings = {
  id: '', // This will be replaced with a generated UUID during creation
  phone_number: '(905) 807-0404',
  email: 'info@fixmybrick.ca',
  address: '498 Glancaster Rd, Hamilton, ON L0R 1W0',
  facebook_url: 'https://facebook.com',
  instagram_url: 'https://instagram.com',
  twitter_url: 'https://twitter.com',
  logo_url: '',
  logo_size: '24',
  favicon_url: '',
  home_hero_image: '',
  business_name: 'Fix My Brick',
  google_analytics_id: '',
  gtm_container_id: '',
  plausible_domain: '', // Added support for Plausible Analytics
  facebook_pixel_id: '', // Added Facebook Pixel ID
  
  // SEO default settings
  default_meta_title: 'Fix My Brick | Professional Masonry & Brick Repair Services',
  default_meta_description: 'Fix My Brick provides professional brick and masonry repair services across Ontario. Expert brick repair, chimney repair, and more.',
  default_og_image: '/og-image.png',
  schema_org_data: {},
  enable_breadcrumbs: true,
  enable_structured_data: true,
};

export const useSettings = () => {
  const [settings, setSettings] = useState<SiteSettings>(defaultSettings);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setLoading(true);
      
      // Check if the settings table exists, if not create it
      await ensureSettingsTable();
      
      const { data, error } = await supabase
        .from('site_settings')
        .select('*')
        .maybeSingle(); // Using maybeSingle instead of single to handle case when no rows exist

      if (error && error.code !== 'PGRST116') {
        // If error is not "no rows returned", it's a real error
        throw error;
      }
      
      if (!data) {
        // No settings found, let's create default ones
        await createDefaultSettings();
        setSettings(defaultSettings);
      } else {
        // Settings found, update state with database values
        setSettings({
          ...defaultSettings, // Start with defaults for any missing fields
          ...data, // Override with actual database values
          // Handle nulls
          logo_url: data.logo_url || '',
          favicon_url: data.favicon_url || '',
          home_hero_image: data.home_hero_image || '',
          business_name: data.business_name || defaultSettings.business_name,
          google_analytics_id: data.google_analytics_id || '',
          gtm_container_id: data.gtm_container_id || '',
          plausible_domain: data.plausible_domain || '',
          facebook_pixel_id: data.facebook_pixel_id || '',
          
          // SEO fields
          default_meta_title: data.default_meta_title || defaultSettings.default_meta_title,
          default_meta_description: data.default_meta_description || defaultSettings.default_meta_description,
          default_og_image: data.default_og_image || defaultSettings.default_og_image,
          schema_org_data: data.schema_org_data || defaultSettings.schema_org_data,
          enable_breadcrumbs: data.enable_breadcrumbs !== null ? data.enable_breadcrumbs : defaultSettings.enable_breadcrumbs,
          enable_structured_data: data.enable_structured_data !== null ? data.enable_structured_data : defaultSettings.enable_structured_data
        });
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
      toast.error('Failed to load site settings');
    } finally {
      setLoading(false);
    }
  };

  const ensureSettingsTable = async () => {
    try {
      // Check if the table exists by trying to query it
      const { error } = await supabase
        .from('site_settings')
        .select('id')
        .limit(1);

      // If the error is about relation not existing, we need to create the table
      if (error && error.code === '42P01') {
        // Table doesn't exist, we should prompt the user to create it
        toast.error('Settings table does not exist. Please contact your administrator.');
        return false;
      }
      
      return true;
    } catch (error) {
      console.error('Error checking settings table:', error);
      return false;
    }
  };

  const createDefaultSettings = async () => {
    try {
      // Create a copy of defaultSettings with a generated UUID
      const settingsToInsert = {
        ...defaultSettings,
        id: uuidv4() // Generate a valid UUID
      };
      
      const { error } = await supabase
        .from('site_settings')
        .insert([settingsToInsert]);
      
      if (error) throw error;
      
      return true;
    } catch (error) {
      console.error('Error creating default settings:', error);
      return false;
    }
  };

  const updateSettings = async (newSettings: SiteSettings) => {
    try {
      setSaving(true);
      
      // If we have an ID, use it for updating
      if (settings.id) {
        const { error } = await supabase
          .from('site_settings')
          .update(newSettings)
          .eq('id', settings.id);
        
        if (error) throw error;
      } else {
        // If no ID, we'll create a new record with a generated UUID
        const settingsToUpsert = {
          ...newSettings,
          id: uuidv4() // Generate a valid UUID
        };
        
        const { error } = await supabase
          .from('site_settings')
          .upsert(settingsToUpsert);
        
        if (error) throw error;
      }
      
      setSettings(newSettings);
      toast.success('Settings updated successfully');
      
      // If Google Analytics ID or Plausible domain was updated, reload the page to initialize the new tracking
      if (newSettings.google_analytics_id !== settings.google_analytics_id || 
          newSettings.plausible_domain !== settings.plausible_domain ||
          newSettings.gtm_container_id !== settings.gtm_container_id) {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
      
      return true;
    } catch (error) {
      console.error('Error updating settings:', error);
      toast.error('Failed to update settings');
      return false;
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (field: keyof SiteSettings, value: string | boolean | Record<string, any>) => {
    setSettings((prev) => ({
      ...prev,
      [field]: value
    }));
  };

  return {
    settings,
    loading,
    saving,
    handleChange,
    updateSettings,
    fetchSettings
  };
};
