
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { GMBLocation, GMBLocationCreate } from '@/types/gmb-location';

export const useGMBLocationMutations = () => {
  const queryClient = useQueryClient();

  const createGMBLocation = useMutation({
    mutationFn: async (locationData: GMBLocationCreate) => {
      const { data, error } = await supabase
        .from('gmb_locations')
        .insert(locationData)
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['gmbLocations'] });
      toast.success('Location created successfully');
    },
    onError: (error) => {
      console.error('Error creating location:', error);
      toast.error('Failed to create location');
    }
  });

  const updateGMBLocation = useMutation({
    mutationFn: async (location: { id: string, data: GMBLocationCreate }) => {
      const { id, data } = location;
      const { data: updatedData, error } = await supabase
        .from('gmb_locations')
        .update(data)
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;
      return updatedData;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['gmbLocations'] });
      toast.success('Location updated successfully');
    },
    onError: (error) => {
      console.error('Error updating location:', error);
      toast.error('Failed to update location');
    }
  });

  const deleteGMBLocation = useMutation({
    mutationFn: async (locationId: string) => {
      const { error } = await supabase
        .from('gmb_locations')
        .delete()
        .eq('id', locationId);

      if (error) throw error;
      return locationId;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['gmbLocations'] });
      toast.success('Location deleted successfully');
    },
    onError: (error) => {
      console.error('Error deleting location:', error);
      toast.error('Failed to delete location');
    }
  });

  return {
    createGMBLocation,
    updateGMBLocation,
    deleteGMBLocation
  };
};
