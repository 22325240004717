
import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { fetchPosts, deletePost } from '@/lib/supabase/blog';

export interface Post {
  id: string;
  title: string;
  slug: string;
  status: string;
  published_at: string | null;
  created_at: string;
  updated_at: string;
}

export const useBlogPostsTable = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [sortField, setSortField] = useState<keyof Post>('updated_at');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const queryClient = useQueryClient();
  
  const { data: posts, isLoading } = useQuery({
    queryKey: ['blog-posts'],
    queryFn: fetchPosts
  });

  const deletePostMutation = useMutation({
    mutationFn: deletePost,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['blog-posts'] });
      toast.success('Post deleted successfully');
    },
    onError: (error: Error) => {
      toast.error(`Error deleting post: ${error.message}`);
    }
  });

  const handleDelete = (id: string) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      deletePostMutation.mutate(id);
    }
  };

  const handleSort = (field: keyof Post) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
  };

  const filteredPosts = posts ? posts
    .filter((post: Post) => 
      (post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
       post.slug.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (statusFilter === 'all' || post.status === statusFilter)
    )
    .sort((a: Post, b: Post) => {
      if (['created_at', 'updated_at', 'published_at'].includes(sortField)) {
        const dateA = new Date(a[sortField] || 0).getTime();
        const dateB = new Date(b[sortField] || 0).getTime();
        return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
      }
      
      if (a[sortField] < b[sortField]) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    }) : [];

  // Calculate the count of posts after filtering
  const postsCount = filteredPosts.length;

  return {
    searchTerm,
    setSearchTerm,
    statusFilter,
    setStatusFilter,
    sortField,
    sortDirection,
    handleSort,
    handleDelete,
    filteredPosts,
    isLoading,
    deletePostMutation,
    postsCount // Added the postsCount property to the returned object
  };
};
