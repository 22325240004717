
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { Star, Edit, Trash } from 'lucide-react';
import { Review, GMBLocation } from '@/lib/supabase';

interface ReviewsTableProps {
  reviews: Review[];
  onEdit: (review: Review) => void;
  onDelete: (id: string) => void;
  getLocationName: (locationId: string) => string;
  getStarRating: (rating: string) => number;
  formatDate: (dateString: string) => string;
  isLoading: boolean;
}

const ReviewsTable: React.FC<ReviewsTableProps> = ({
  reviews,
  onEdit,
  onDelete,
  getLocationName,
  getStarRating,
  formatDate,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <div className="rounded-md border bg-background overflow-x-auto">
        <Table>
          <TableHeader>
            <TableRow className="bg-muted/30">
              <TableHead className="font-semibold">Reviewer</TableHead>
              <TableHead className="font-semibold">Location</TableHead>
              <TableHead className="font-semibold">Rating</TableHead>
              <TableHead className="hidden md:table-cell font-semibold">Comment</TableHead>
              <TableHead className="font-semibold">Date</TableHead>
              <TableHead className="font-semibold">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell colSpan={6} className="h-24 text-center">
                <div className="flex flex-col items-center justify-center text-muted-foreground">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary mb-2"></div>
                  <p>Loading reviews...</p>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <div className="rounded-md border bg-background overflow-x-auto">
      <Table>
        <TableHeader>
          <TableRow className="bg-muted/30">
            <TableHead className="font-semibold">Reviewer</TableHead>
            <TableHead className="font-semibold">Location</TableHead>
            <TableHead className="font-semibold">Rating</TableHead>
            <TableHead className="hidden md:table-cell font-semibold">Comment</TableHead>
            <TableHead className="font-semibold">Date</TableHead>
            <TableHead className="font-semibold">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {reviews && reviews.length > 0 ? (
            reviews.map((review) => (
              <TableRow key={review.id}>
                <TableCell className="font-medium">{review.reviewer_name}</TableCell>
                <TableCell>{getLocationName(review.location_id)}</TableCell>
                <TableCell>
                  <div className="flex">
                    {[...Array(5)].map((_, i) => (
                      <Star
                        key={i}
                        size={16}
                        className={i < getStarRating(review.star_rating) ? "text-yellow-400 fill-yellow-400" : "text-gray-300"}
                      />
                    ))}
                  </div>
                </TableCell>
                <TableCell className="hidden md:table-cell max-w-xs truncate">
                  {review.reviewer_comment || 'No comment'}
                </TableCell>
                <TableCell>{formatDate(review.create_time)}</TableCell>
                <TableCell>
                  <div className="flex space-x-2">
                    <Button variant="outline" size="sm" onClick={() => onEdit(review)}>
                      <Edit size={16} />
                    </Button>
                    <Button 
                      variant="outline" 
                      size="sm" 
                      className="text-red-500 hover:text-red-700"
                      onClick={() => onDelete(review.id)}
                    >
                      <Trash size={16} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} className="h-24 text-center">
                <div className="flex flex-col items-center justify-center text-muted-foreground">
                  <p>No reviews found. Add your first review.</p>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default ReviewsTable;
