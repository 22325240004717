
import React, { Suspense } from 'react';
import Header from '@/components/header';
import Footer from '@/components/Footer';
import LoadingState from './LoadingState';
import AdminBar from '@/components/admin/AdminBar';
import BrickPatternBackground from './BrickPatternBackground';
import { useLocation } from 'react-router-dom';

interface BlogLayoutProps {
  children: React.ReactNode;
  featuredImage?: string;
}

const BlogLayout: React.FC<BlogLayoutProps> = ({ children, featuredImage }) => {
  const location = useLocation();
  const isPostPage = location.pathname.startsWith('/blog/') && location.pathname !== '/blog';

  return (
    <div className="min-h-screen flex flex-col">
      <AdminBar />
      <Header />
      <main className="flex-grow">
        {isPostPage && (
          featuredImage ? (
            <div className="w-full h-64 md:h-80 lg:h-96 relative">
              <img 
                src={featuredImage}
                alt="Blog Featured"
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black/20"></div>
            </div>
          ) : (
            <BrickPatternBackground />
          )
        )}
        
        <section className="bg-white py-16">
          <div className="container mx-auto px-4">
            <Suspense fallback={<LoadingState />}>
              {children}
            </Suspense>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default BlogLayout;
