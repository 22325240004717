
import React from 'react';
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";

interface VisibilityFieldProps {
  isPublic: boolean;
  handleCheckboxChange: (checked: boolean) => void;
}

const VisibilityField = ({
  isPublic,
  handleCheckboxChange
}: VisibilityFieldProps) => {
  return (
    <div className="flex items-center space-x-2">
      <Checkbox 
        id="is_public" 
        checked={isPublic} 
        onCheckedChange={handleCheckboxChange}
      />
      <Label htmlFor="is_public">Make gallery publicly visible</Label>
    </div>
  );
};

export default VisibilityField;
