
import { ServiceDetail } from '@/types/service';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';

/**
 * Hook for handling updates to service form fields
 */
export const useServiceFields = (
  formData: ServiceDetail,
  setFormData: React.Dispatch<React.SetStateAction<ServiceDetail>>
) => {
  // Generic field change handler for form inputs
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    
    // Handle checkbox inputs differently
    if (type === 'checkbox') {
      const checked = (e.target as HTMLInputElement).checked;
      setFormData((prev) => ({
        ...prev,
        [name]: checked
      }));
      return;
    }
    
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // Benefits handlers
  const addBenefit = () => {
    setFormData((prev) => ({
      ...prev,
      benefits: [...prev.benefits, '']
    }));
  };

  const removeBenefit = (index: number) => {
    setFormData((prev) => ({
      ...prev,
      benefits: prev.benefits.filter((_, i) => i !== index)
    }));
  };

  const updateBenefit = (index: number, value: string) => {
    setFormData((prev) => {
      const newBenefits = [...prev.benefits];
      newBenefits[index] = value;
      return {
        ...prev,
        benefits: newBenefits
      };
    });
  };

  // Process steps handlers
  const addProcessStep = () => {
    setFormData((prev) => ({
      ...prev,
      process_steps: [...prev.process_steps, { title: '', description: '' }]
    }));
  };

  const removeProcessStep = (index: number) => {
    setFormData((prev) => ({
      ...prev,
      process_steps: prev.process_steps.filter((_, i) => i !== index)
    }));
  };

  const updateProcessStep = (index: number, field: string, value: string, stepIndex: number) => {
    setFormData((prev) => {
      const newProcessSteps = [...prev.process_steps];
      newProcessSteps[index] = { 
        ...newProcessSteps[index], 
        [field]: value 
      };
      return {
        ...prev,
        process_steps: newProcessSteps
      };
    });
  };

  // FAQ handlers
  const addFAQ = () => {
    setFormData((prev) => ({
      ...prev,
      faq: [...prev.faq, { question: '', answer: '' }]
    }));
  };

  const removeFAQ = (index: number) => {
    setFormData((prev) => ({
      ...prev,
      faq: prev.faq.filter((_, i) => i !== index)
    }));
  };

  const updateFAQ = (index: number, field: string, value: string, faqIndex: number) => {
    setFormData((prev) => {
      const newFAQ = [...prev.faq];
      newFAQ[index] = { 
        ...newFAQ[index], 
        [field]: value 
      };
      return {
        ...prev,
        faq: newFAQ
      };
    });
  };

  // Gallery images handlers
  const addGalleryImage = () => {
    setFormData((prev) => ({
      ...prev,
      gallery_images: [...(prev.gallery_images || []), { 
        id: uuidv4(), 
        image_url: '', 
        caption: '' 
      }]
    }));
  };

  const removeGalleryImage = (index: number) => {
    setFormData((prev) => {
      const newGalleryImages = [...(prev.gallery_images || [])];
      newGalleryImages.splice(index, 1);
      return {
        ...prev,
        gallery_images: newGalleryImages
      };
    });
  };

  const updateGalleryImageCaption = (index: number, value: string) => {
    setFormData((prev) => {
      const newGalleryImages = [...(prev.gallery_images || [])];
      if (index >= 0 && index < newGalleryImages.length) {
        newGalleryImages[index] = {
          ...newGalleryImages[index],
          caption: value
        };
      }
      return {
        ...prev,
        gallery_images: newGalleryImages
      };
    });
  };

  return {
    handleChange,
    addBenefit,
    removeBenefit,
    updateBenefit,
    addProcessStep,
    removeProcessStep,
    updateProcessStep,
    addFAQ,
    removeFAQ,
    updateFAQ,
    addGalleryImage,
    removeGalleryImage,
    updateGalleryImageCaption
  };
};
