import React from 'react';
import { Layout } from '@/components/Layout';
import { Link } from 'react-router-dom';
import { ChevronRight, MapPin } from 'lucide-react';
interface LocationDetailLayoutProps {
  children: React.ReactNode;
  locationName?: string;
  parentLocationName?: string;
  locationSlug?: string;
  parentLocationSlug?: string;
}
const LocationDetailLayout: React.FC<LocationDetailLayoutProps> = ({
  children,
  locationName,
  parentLocationName,
  locationSlug,
  parentLocationSlug
}) => {
  // The ScrollToTop component is now included via the Layout component
  return <Layout>
      {/* Breadcrumb navigation */}
      {(locationName || parentLocationName) && <div className="bg-gray-50 py-3 border-b">
          <div className="container mx-auto px-4">
            <div className="flex items-center text-sm">
              <Link to="/" className="text-gray-500 hover:text-D00B0E">
                Home
              </Link>
              <ChevronRight className="h-4 w-4 mx-1 text-gray-400" />
              
              <Link to="/locations" className="text-gray-500 hover:text-D00B0E">
                Service Areas
              </Link>
              
              {parentLocationName && parentLocationSlug && <>
                  <ChevronRight className="h-4 w-4 mx-1 text-gray-400" />
                  <Link to={`/locations/${parentLocationSlug}`} className="text-gray-500 hover:text-D00B0E">
                    {parentLocationName}
                  </Link>
                </>}
              
              {locationName && <>
                  <ChevronRight className="h-4 w-4 mx-1 text-gray-400" />
                  <span className="text-D00B0E font-medium">
                    {locationName}
                  </span>
                </>}
            </div>
          </div>
        </div>}
      
      <div className="py-0">
        {children}
      </div>
    </Layout>;
};
export default LocationDetailLayout;