import React from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue 
} from '@/components/ui/select';

interface ServiceSelectionFieldsProps {
  selectedService: string;
  onServiceChange: (value: string) => void;
  showOtherServiceInput: boolean;
  otherService: string;
  onOtherServiceChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  predefinedServices: Array<{ value: string; label: string }>;
}

const ServiceSelectionFields: React.FC<ServiceSelectionFieldsProps> = ({
  selectedService,
  onServiceChange,
  showOtherServiceInput,
  otherService,
  onOtherServiceChange,
  predefinedServices
}) => {
  return (
    <>
      <div className="space-y-2">
        <Label htmlFor="service" className="text-sm text-gray-600">Service Needed</Label>
        <Select
          value={selectedService}
          onValueChange={onServiceChange}
        >
          <SelectTrigger className="w-full" style={{ color: '#1f2937' }}>
            <SelectValue placeholder="Select a service (optional)" className="text-gray-900" />
          </SelectTrigger>
          <SelectContent>
            {predefinedServices.map((service) => (
              <SelectItem key={service.value} value={service.value} className="text-gray-900">
                {service.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      
      {/* Other Service Input - conditionally shown */}
      {showOtherServiceInput && (
        <div>
          <Input 
            type="text" 
            name="other-service" 
            placeholder="Please specify the service needed" 
            value={otherService}
            onChange={onOtherServiceChange}
            required={showOtherServiceInput}
            className="w-full text-gray-900 !important"
            style={{ color: '#1f2937' }}
          />
        </div>
      )}
    </>
  );
};

export default ServiceSelectionFields;
