
import React, { useState, useRef, useEffect } from 'react';
import { Input } from '@/components/ui/input';

interface PhoneInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  required?: boolean;
  name?: string;
  className?: string;
  style?: React.CSSProperties;
  id?: string;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  value,
  onChange,
  placeholder = 'Phone Number',
  required = false,
  name,
  className,
  style,
  id,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  
  // Format the phone number with the pattern (XXX)-XXX-XXXX
  const formatPhoneNumber = (input: string): string => {
    // Remove all non-digit characters
    const digits = input.replace(/\D/g, '');
    
    // Apply formatting based on the number of digits
    if (digits.length === 0) return '';
    if (digits.length <= 3) return `(${digits}`;
    if (digits.length <= 6) return `(${digits.slice(0, 3)})-${digits.slice(3)}`;
    return `(${digits.slice(0, 3)})-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
  };

  // Handle input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    onChange(formattedValue);
  };

  // Handle pasting of phone numbers
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const formattedValue = formatPhoneNumber(pastedText);
    onChange(formattedValue);
  };

  // Prevent non-digit characters from being typed
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow navigation keys, delete, backspace
    const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
    if (allowedKeys.includes(e.key)) return;
    
    // Allow Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
    if ((e.ctrlKey || e.metaKey) && ['a', 'c', 'v', 'x'].includes(e.key.toLowerCase())) return;
    
    // Allow digits
    if (/^\d$/.test(e.key)) return;
    
    // Prevent all other input
    e.preventDefault();
  };
  
  return (
    <Input
      ref={inputRef}
      type="tel"
      inputMode="tel"
      id={id}
      name={name}
      value={value}
      onChange={handleChange}
      onPaste={handlePaste}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      required={required}
      className={className}
      style={style}
      maxLength={14} // (XXX)-XXX-XXXX = 14 characters
    />
  );
};

export default PhoneInput;
