
import React from 'react';
import AdminLayout from '@/components/admin/AdminLayout';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import ServiceTable from '@/components/admin/services/ServiceTable';
import ServiceSearch from '@/components/admin/services/ServiceSearch';
import { useServicesTable } from '@/hooks/useServicesTable';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { Link } from 'react-router-dom';
import { HomeIcon } from 'lucide-react';

const AdminServices = () => {
  const {
    services,
    filteredServices,
    loading,
    deleting,
    confirmDeleteId,
    searchQuery,
    setSearchQuery,
    handleEditService,
    handlePreviewService,
    handleDeleteService
  } = useServicesTable();

  return (
    <AdminLayout title="Services">
      {/* Breadcrumb Navigation */}
      <div className="mb-6">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to="/admin">
                  <HomeIcon className="h-4 w-4 mr-1" />
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink>
                Services
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>

      <ServiceSearch
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
      />

      <Card>
        <CardHeader>
          <CardTitle>Services</CardTitle>
        </CardHeader>
        <CardContent>
          {loading ? (
            <div className="py-10 text-center">Loading services...</div>
          ) : (
            <ServiceTable
              services={services}
              filteredServices={filteredServices}
              confirmDeleteId={confirmDeleteId}
              deleting={deleting}
              onEdit={handleEditService}
              onPreview={handlePreviewService}
              onDelete={handleDeleteService}
            />
          )}
        </CardContent>
      </Card>
    </AdminLayout>
  );
};

export default AdminServices;
