
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { ArrowLeft } from 'lucide-react';

const AdminBar = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const { data: sessionData } = await supabase.auth.getSession();
      
      if (sessionData.session) {
        setIsAdmin(true);
      }
    };
    
    checkAdminStatus();
    
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        setIsAdmin(!!session);
      }
    );
    
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);
  
  if (!isAdmin) return null;
  
  return (
    <div className="bg-D00B0E text-white w-full py-2 fixed top-0 left-0 z-[100]">
      <div className="container mx-auto px-4 flex items-center justify-between">
        <span className="text-sm font-medium">Admin Mode</span>
        <Link 
          to="/admin/dashboard" 
          className="flex items-center text-sm bg-white bg-opacity-20 hover:bg-opacity-30 px-3 py-1 rounded transition-colors"
        >
          <ArrowLeft size={16} className="mr-2" />
          Back to Admin
        </Link>
      </div>
    </div>
  );
};

export default AdminBar;
