
import React, { useEffect, useState } from 'react';
import { ServiceDetail } from '@/types/service';
import ServiceCard from './ServiceCard';
import { fallbackServices } from './serviceUtils';
import { getCachedService } from '@/lib/serviceCache';

interface ServiceGridProps {
  loading: boolean;
  featuredServices: ServiceDetail[];
}

const ServiceGrid: React.FC<ServiceGridProps> = ({ loading, featuredServices }) => {
  const [displayServices, setDisplayServices] = useState<any[]>([]);
  
  useEffect(() => {
    if (featuredServices && featuredServices.length > 0) {
      setDisplayServices(featuredServices.map(service => ({
        title: service.title,
        description: service.description,
        link: `/services/${service.slug}`,
        imageUrl: service.image_url
      })));
    } else {
      // Check for these services directly in the cache
      const serviceSlugs = [
        'brick-repair',
        'chimney-repair',
        'fireplace-repair',
        'stone-masonry',
        'parging',
        'chimney-removal',
        'masonry-restoration'
      ];
      
      const cachedServices = serviceSlugs
        .map(slug => getCachedService(slug))
        .filter(Boolean);
      
      if (cachedServices.length > 0) {
        setDisplayServices(cachedServices.map(service => ({
          title: service?.title || '',
          description: service?.description || '',
          link: `/services/${service?.slug}`,
          imageUrl: service?.image_url
        })));
      } else {
        setDisplayServices(fallbackServices);
      }
    }
  }, [featuredServices]);

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-D00B0E"></div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {displayServices.map((service, index) => (
        <ServiceCard
          key={index}
          title={service.title}
          description={service.description}
          link={service.link}
          imageUrl={service.imageUrl}
        />
      ))}
    </div>
  );
};

export default ServiceGrid;
