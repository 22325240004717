
import React from 'react';
import SEOForm from '@/components/admin/SEOForm';

interface SEOTabWrapperProps {
  seoData: {
    meta_title?: string;
    meta_description?: string;
    og_title?: string;
    og_description?: string;
    og_image?: string;
    canonical_url?: string;
    focus_keyword?: string;
  };
  onChange: (field: string, value: string) => void;
}

const SEOTabWrapper: React.FC<SEOTabWrapperProps> = ({ seoData, onChange }) => {
  return <SEOForm seoData={seoData} onChange={onChange} />;
};

export default SEOTabWrapper;
