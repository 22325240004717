
import React, { useState } from 'react';
import { Input } from '@/components/ui/input';
import PhoneInput from '@/components/shared/PhoneInput';

const PersonalInfoFields: React.FC = () => {
  const [phoneValue, setPhoneValue] = useState('');

  return (
    <>
      <div>
        <Input 
          type="text" 
          name="name" 
          placeholder="Full Name *" 
          required 
          className="w-full text-gray-900 !important"
          style={{ color: '#1f2937' }}
        />
      </div>
      <div>
        <Input 
          type="email" 
          name="email" 
          placeholder="Email Address *" 
          required 
          className="w-full text-gray-900 !important"
          style={{ color: '#1f2937' }}
        />
      </div>
      <div>
        <PhoneInput 
          name="phone" 
          value={phoneValue}
          onChange={setPhoneValue}
          placeholder="Phone Number (Optional)" 
          className="w-full text-gray-900"
          style={{ color: '#1f2937' }}
        />
      </div>
      <div>
        <Input 
          type="text" 
          name="address" 
          placeholder="Home Address (Optional)" 
          className="w-full text-gray-900 !important"
          style={{ color: '#1f2937' }}
        />
      </div>
    </>
  );
};

export default PersonalInfoFields;
