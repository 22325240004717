
import React, { useState } from 'react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { 
  Edit, 
  Trash2, 
  X, 
  Check, 
  Loader2, 
  Clock,
  CheckCircle,
  XCircle
} from 'lucide-react';
import { Badge } from "@/components/ui/badge";
import { PhotoItemProps } from './types';
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger 
} from "@/components/ui/dropdown-menu";
import { ReviewStatus } from '@/hooks/gallery-table/types';

const PhotoItem = ({ 
  photo, 
  deleting, 
  confirmDeleteId, 
  onDelete, 
  onCaptionUpdate,
  onUpdateReviewStatus
}: PhotoItemProps) => {
  const [editCaptionId, setEditCaptionId] = useState<string | null>(null);
  const [newCaption, setNewCaption] = useState("");

  const startEditCaption = () => {
    setEditCaptionId(photo.id);
    setNewCaption(photo.caption || "");
  };

  const saveCaption = async () => {
    if (editCaptionId) {
      await onCaptionUpdate(editCaptionId, newCaption);
      setEditCaptionId(null);
    }
  };

  // Get review status badge
  const getReviewStatusBadge = (status: ReviewStatus) => {
    switch(status) {
      case 'approved':
        return (
          <Badge variant="success" className="flex items-center gap-1 absolute top-2 left-2">
            <CheckCircle className="h-3 w-3" />
          </Badge>
        );
      case 'rejected':
        return (
          <Badge variant="destructive" className="flex items-center gap-1 absolute top-2 left-2">
            <XCircle className="h-3 w-3" />
          </Badge>
        );
      default:
        return (
          <Badge variant="outline" className="flex items-center gap-1 absolute top-2 left-2 bg-white/70">
            <Clock className="h-3 w-3" />
          </Badge>
        );
    }
  };

  return (
    <div className="relative group border rounded-md overflow-hidden bg-gray-50">
      <div className="aspect-square">
        <img 
          src={photo.image_url} 
          alt={photo.caption || 'Gallery photo'} 
          className="h-full w-full object-cover"
        />
      </div>
      
      {/* Review Status Badge */}
      {getReviewStatusBadge(photo.review_status || 'pending')}
      
      <div className="absolute inset-0 bg-black/0 group-hover:bg-black/20 transition-all duration-200"></div>
      
      <div className="absolute top-2 right-2 flex space-x-1 opacity-0 group-hover:opacity-100 transition-opacity">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button 
              size="icon" 
              variant="secondary"
            >
              <CheckCircle className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={() => onUpdateReviewStatus(photo.id, 'pending')}>
              <Clock className="mr-2 h-4 w-4" />
              <span>Mark Pending</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => onUpdateReviewStatus(photo.id, 'approved')}>
              <CheckCircle className="mr-2 h-4 w-4" />
              <span>Approve</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => onUpdateReviewStatus(photo.id, 'rejected')}>
              <XCircle className="mr-2 h-4 w-4" />
              <span>Reject</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        
        <Button 
          size="icon" 
          variant="secondary"
          onClick={startEditCaption}
        >
          <Edit className="h-4 w-4" />
        </Button>
        <Button 
          size="icon" 
          variant={confirmDeleteId === photo.id ? "destructive" : "secondary"}
          onClick={() => onDelete(photo.id)}
          disabled={deleting}
        >
          {deleting && confirmDeleteId === photo.id ? (
            <Loader2 className="h-4 w-4 animate-spin" />
          ) : (
            <Trash2 className="h-4 w-4" />
          )}
        </Button>
      </div>
      
      {editCaptionId === photo.id ? (
        <div className="absolute bottom-0 left-0 right-0 bg-background p-2 flex gap-2">
          <Input
            value={newCaption}
            onChange={(e) => setNewCaption(e.target.value)}
            placeholder="Enter caption"
            className="text-sm"
          />
          <Button size="icon" variant="ghost" onClick={() => setEditCaptionId(null)}>
            <X className="h-4 w-4" />
          </Button>
          <Button size="icon" variant="ghost" onClick={saveCaption}>
            <Check className="h-4 w-4" />
          </Button>
        </div>
      ) : (
        photo.caption && (
          <div className="absolute bottom-0 left-0 right-0 bg-background/80 backdrop-blur-sm p-2">
            <p className="text-sm truncate">{photo.caption}</p>
          </div>
        )
      )}
    </div>
  );
};

export default PhotoItem;
