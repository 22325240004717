import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase, getAuthenticatedStorage } from '@/lib/supabase/client';
import { toast } from 'sonner';
import { v4 as uuidv4 } from 'uuid';

export type Asset = {
  id: string;
  filename: string;
  original_filename: string;
  file_path: string;
  file_size: number;
  mime_type: string;
  width?: number;
  height?: number;
  folder_id?: string;
  is_archived: boolean;
  created_at: string;
  updated_at: string;
  seo?: {
    title?: string;
    alt_text?: string;
    description?: string;
    caption?: string;
    keywords?: string[];
  };
};

export type AssetFolder = {
  id: string;
  name: string;
  parent_id?: string;
  created_at: string;
  updated_at: string;
};

export type AssetFilter = {
  search?: string;
  folder_id?: string;
  mime_types?: string[];
  dateRange?: { start: Date; end: Date };
  archived?: boolean;
  type?: 'image' | 'document' | 'other';
};

export const useAssetManager = () => {
  const queryClient = useQueryClient();
  const [currentFolder, setCurrentFolder] = useState<string | null>(null);
  const [filters, setFilters] = useState<AssetFilter>({
    archived: false,
  });
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);

  // Fetch assets with filtering
  const fetchAssets = async ({
    folder_id,
    search,
    mime_types,
    archived,
    type,
  }: AssetFilter) => {
    let query = supabase.from('site_assets').select(`
        *,
        asset_seo (*)
      `);

    // Apply filters
    if (folder_id) {
      query = query.eq('folder_id', folder_id);
    } else if (folder_id === null) {
      query = query.is('folder_id', null);
    }

    if (search) {
      query = query.or(
        `filename.ilike.%${search}%,original_filename.ilike.%${search}%`
      );
    }

    // Apply type filter (translating to appropriate mime types)
    if (type) {
      if (type === 'image') {
        query = query.ilike('mime_type', 'image/%');
      } else if (type === 'document') {
        query = query.or('mime_type.ilike.application/pdf,mime_type.ilike.application/msword,mime_type.ilike.application/vnd.openxmlformats%');
      } else if (type === 'other') {
        query = query.not('mime_type', 'ilike', 'image/%')
                     .not('mime_type', 'ilike', 'application/pdf')
                     .not('mime_type', 'ilike', 'application/msword')
                     .not('mime_type', 'ilike', 'application/vnd.openxmlformats%');
      }
    }
    
    // If specific mime types are provided, they override the type filter
    if (mime_types && mime_types.length > 0) {
      query = query.in('mime_type', mime_types);
    }

    query = query.eq('is_archived', archived === true);

    // Sort by newest first
    query = query.order('created_at', { ascending: false });

    console.log('Running query with filters:', { folder_id, search, type, mime_types, archived });
    const { data, error } = await query;

    if (error) throw error;
    return data as Asset[];
  };

  // Fetch folders
  const fetchFolders = async () => {
    const { data, error } = await supabase
      .from('asset_folders')
      .select('*')
      .order('name');

    if (error) throw error;
    return data as AssetFolder[];
  };

  // Upload asset
  const uploadAsset = async (file: File, folder_id?: string) => {
    try {
      // Get authenticated storage access
      const { storage, isAuthenticated, authBypassEnabled, isDev } =
        await getAuthenticatedStorage();

      // Log authentication status for debugging
      console.log('Upload attempted with auth status:', {
        isAuthenticated,
        authBypassEnabled,
        isDev,
      });

      // Extract file extension
      const fileExtension = file.name.split('.').pop();

      // Create a shortened clean filename: only use first 30 chars of original name, remove special chars
      const cleanName = file.name
        .substring(0, 30)
        .replace(/[^a-zA-Z0-9-_.]/g, '')
        .trim();

      // Create final filename with UUID and extension
      const filename = `${uuidv4()}-${cleanName}.${fileExtension}`;
      const filePath = `uploads/${filename}`;

      // 1. Upload to storage with explicit authentication
      const { error: uploadError } = await storage
        .from('site_assets')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: false,
        });

      if (uploadError) {
        console.error('Upload error:', uploadError);

        // If unauthorized, provide a more helpful error message
        if ((uploadError as any).statusCode === '403') {
          if (authBypassEnabled) {
            throw new Error(`Storage access denied. You need to be logged in to upload files. 
              In development mode with auth bypass, you need to configure Supabase RLS policies 
              to allow public uploads, or sign in as an authenticated user.`);
          } else if (!isAuthenticated) {
            throw new Error(
              'Authentication required. Please log in to upload files.'
            );
          } else {
            throw new Error(
              'Access denied. Your account does not have permission to upload files.'
            );
          }
        }

        throw uploadError;
      }

      // 2. Get public URL
      const {
        data: { publicUrl },
      } = storage.from('site_assets').getPublicUrl(filePath);

      // 3. Create database record
      const { data, error: dbError } = await supabase
        .from('site_assets')
        .insert({
          filename,
          original_filename: file.name,
          file_path: publicUrl,
          file_size: file.size,
          mime_type: file.type,
          folder_id: folder_id || null,
        })
        .select()
        .single();

      if (dbError) {
        // Attempt to clean up storage if DB insert fails
        await storage.from('site_assets').remove([filePath]);
        throw dbError;
      }

      return data as Asset;
    } catch (error) {
      console.error('Error in uploadAsset:', error);
      throw error;
    }
  };

  // Delete asset
  const deleteAsset = async (id: string) => {
    // Get file path first
    const { data: asset } = await supabase
      .from('site_assets')
      .select('filename')
      .eq('id', id)
      .single();

    if (!asset) throw new Error('Asset not found');

    // Remove from storage
    const filePath = `uploads/${asset.filename}`;
    await supabase.storage.from('site_assets').remove([filePath]);

    // Remove from database
    const { error } = await supabase.from('site_assets').delete().eq('id', id);

    if (error) throw error;
    return true;
  };

  // Archive/unarchive asset
  const toggleArchiveAsset = async (id: string, archive: boolean) => {
    const { data, error } = await supabase
      .from('site_assets')
      .update({ is_archived: archive })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data as Asset;
  };

  // Update asset SEO
  const updateAssetSEO = async (assetId: string, seoData: Asset['seo']) => {
    const { data, error } = await supabase
      .from('asset_seo')
      .upsert({
        asset_id: assetId,
        ...seoData,
      })
      .select()
      .single();

    if (error) throw error;

    // Also update the cache
    queryClient.setQueryData(['assets'], (oldData: Asset[] | undefined) => {
      if (!oldData) return oldData;
      return oldData.map((asset) =>
        asset.id === assetId ? { ...asset, seo: data } : asset
      );
    });

    return data;
  };

  // Create folder
  const createFolder = async (name: string, parent_id?: string) => {
    const { data, error } = await supabase
      .from('asset_folders')
      .insert({
        name,
        parent_id: parent_id || null,
      })
      .select()
      .single();

    if (error) throw error;
    return data as AssetFolder;
  };

  // Rename folder
  const renameFolder = async (id: string, name: string) => {
    const { data, error } = await supabase
      .from('asset_folders')
      .update({ name })
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    return data as AssetFolder;
  };

  // Delete folder
  const deleteFolder = async (id: string) => {
    // Check if folder has assets or subfolders
    const { count: assetCount, error: countError } = await supabase
      .from('site_assets')
      .select('id', { count: 'exact', head: true })
      .eq('folder_id', id);

    if (countError) throw countError;

    const { count: subfoldersCount, error: foldersCountError } = await supabase
      .from('asset_folders')
      .select('id', { count: 'exact', head: true })
      .eq('parent_id', id);

    if (foldersCountError) throw foldersCountError;

    if ((assetCount || 0) > 0 || (subfoldersCount || 0) > 0) {
      throw new Error(
        'Cannot delete folder that contains assets or subfolders'
      );
    }

    const { error } = await supabase
      .from('asset_folders')
      .delete()
      .eq('id', id);

    if (error) throw error;
    return true;
  };

  // Move assets to folder
  const moveAssetsToFolder = async (
    assetIds: string[],
    folderId: string | null
  ) => {
    const { data, error } = await supabase
      .from('site_assets')
      .update({ folder_id: folderId })
      .in('id', assetIds)
      .select();

    if (error) throw error;
    return data as Asset[];
  };

  // Queries and mutations
  const assetsQuery = useQuery({
    queryKey: ['assets', filters],
    queryFn: () => fetchAssets(filters),
  });

  const foldersQuery = useQuery({
    queryKey: ['asset-folders'],
    queryFn: fetchFolders,
  });

  const uploadMutation = useMutation({
    mutationFn: ({ file, folderId }: { file: File; folderId?: string }) =>
      uploadAsset(file, folderId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['assets'] });
      toast.success('Asset uploaded successfully');
    },
    onError: (error) => {
      toast.error(
        `Upload failed: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`
      );
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteAsset,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['assets'] });
      toast.success('Asset deleted successfully');
    },
    onError: (error) => {
      toast.error(
        `Delete failed: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`
      );
    },
  });

  const archiveMutation = useMutation({
    mutationFn: ({ id, archive }: { id: string; archive: boolean }) =>
      toggleArchiveAsset(id, archive),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['assets'] });
      toast.success(
        `Asset ${variables.archive ? 'archived' : 'restored'} successfully`
      );
    },
    onError: (error) => {
      toast.error(
        `Operation failed: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`
      );
    },
  });

  const updateSEOMutation = useMutation({
    mutationFn: ({
      assetId,
      seoData,
    }: {
      assetId: string;
      seoData: Asset['seo'];
    }) => updateAssetSEO(assetId, seoData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['assets'] });
      toast.success('SEO data updated successfully');
    },
    onError: (error) => {
      toast.error(
        `Update failed: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`
      );
    },
  });

  const createFolderMutation = useMutation({
    mutationFn: ({ name, parentId }: { name: string; parentId?: string }) =>
      createFolder(name, parentId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['asset-folders'] });
      toast.success('Folder created successfully');
    },
    onError: (error) => {
      toast.error(
        `Create folder failed: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`
      );
    },
  });

  const renameFolderMutation = useMutation({
    mutationFn: ({ id, name }: { id: string; name: string }) =>
      renameFolder(id, name),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['asset-folders'] });
      toast.success('Folder renamed successfully');
    },
    onError: (error) => {
      toast.error(
        `Rename failed: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`
      );
    },
  });

  const deleteFolderMutation = useMutation({
    mutationFn: deleteFolder,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['asset-folders'] });
      toast.success('Folder deleted successfully');
    },
    onError: (error) => {
      toast.error(
        `Delete failed: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`
      );
    },
  });

  const moveAssetsMutation = useMutation({
    mutationFn: ({
      assetIds,
      folderId,
    }: {
      assetIds: string[];
      folderId: string | null;
    }) => moveAssetsToFolder(assetIds, folderId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['assets'] });
      setSelectedAssets([]);
      toast.success('Assets moved successfully');
    },
    onError: (error) => {
      toast.error(
        `Move failed: ${
          error instanceof Error ? error.message : 'Unknown error'
        }`
      );
    },
  });

  return {
    // State
    currentFolder,
    setCurrentFolder,
    filters,
    setFilters,
    selectedAssets,
    setSelectedAssets,

    // Queries
    assets: assetsQuery.data || [],
    folders: foldersQuery.data || [],
    isLoading: assetsQuery.isLoading || foldersQuery.isLoading,
    isError: assetsQuery.isError || foldersQuery.isError,
    error: assetsQuery.error || foldersQuery.error,

    // Mutations
    uploadAsset: (file: File, folderId?: string) =>
      uploadMutation.mutate({ file, folderId }),
    deleteAsset: (id: string) => deleteMutation.mutate(id),
    archiveAsset: (id: string, archive: boolean) =>
      archiveMutation.mutate({ id, archive }),
    updateAssetSEO: (assetId: string, seoData: Asset['seo']) =>
      updateSEOMutation.mutate({ assetId, seoData }),
    createFolder: (name: string, parentId?: string) =>
      createFolderMutation.mutate({ name, parentId }),
    renameFolder: (id: string, name: string) =>
      renameFolderMutation.mutate({ id, name }),
    deleteFolder: (id: string) => deleteFolderMutation.mutate(id),
    moveAssets: (assetIds: string[], folderId: string | null) =>
      moveAssetsMutation.mutate({ assetIds, folderId }),

    // Utils
    refreshAssets: () =>
      queryClient.invalidateQueries({ queryKey: ['assets'] }),
    refreshFolders: () =>
      queryClient.invalidateQueries({ queryKey: ['asset-folders'] }),
  };
};
