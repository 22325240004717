
import { useState, useEffect } from 'react';
import { useFetchGalleryPhotos } from './useFetchGalleryPhotos';
import { usePhotoUpload } from './usePhotoUpload';
import { usePhotoManagement } from './usePhotoManagement';
import { GalleryPhoto } from './types';
import { ReviewStatus } from '@/hooks/gallery-table/types';

export const useGalleryPhotos = (galleryId: string) => {
  const [photos, setPhotos] = useState<GalleryPhoto[]>([]);
  const { fetchPhotos, loading } = useFetchGalleryPhotos();
  const { uploadPhoto, uploading } = usePhotoUpload();
  const { 
    deletePhoto, 
    updatePhotoCaption,
    updatePhotoReviewStatus,
    deleting, 
    confirmDeleteId 
  } = usePhotoManagement();

  useEffect(() => {
    if (galleryId) {
      fetchGalleryPhotos();
    }
  }, [galleryId]);

  const fetchGalleryPhotos = async () => {
    if (!galleryId) return;
    const data = await fetchPhotos(galleryId);
    setPhotos(data);
  };

  const handleUploadPhoto = async (file: File) => {
    const success = await uploadPhoto(galleryId, file);
    if (success) {
      await fetchGalleryPhotos();
    }
  };

  const handleDeletePhoto = async (id: string) => {
    const success = await deletePhoto(id);
    if (success) {
      setPhotos(photos.filter(photo => photo.id !== id));
    }
  };

  const handleUpdatePhotoCaption = async (id: string, caption: string) => {
    const success = await updatePhotoCaption(id, caption);
    if (success) {
      setPhotos(
        photos.map(photo => 
          photo.id === id 
            ? { ...photo, caption } 
            : photo
        )
      );
    }
  };

  const handleUpdatePhotoReviewStatus = async (id: string, status: ReviewStatus) => {
    const success = await updatePhotoReviewStatus(id, status);
    if (success) {
      setPhotos(
        photos.map(photo => 
          photo.id === id 
            ? { ...photo, review_status: status } 
            : photo
        )
      );
    }
  };

  return {
    photos,
    loading,
    uploading,
    deleting,
    confirmDeleteId,
    uploadPhoto: handleUploadPhoto,
    deletePhoto: handleDeletePhoto,
    updatePhotoCaption: handleUpdatePhotoCaption,
    updatePhotoReviewStatus: handleUpdatePhotoReviewStatus
  };
};

export * from './types';
