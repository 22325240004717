import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './lib/queryClient';
import { initializeServiceCache } from './lib/serviceCache';
import { supabase } from './lib/supabase';

// Initialize Google Analytics if it's configured in settings
const initializeGoogleAnalytics = async () => {
  try {
    const { data, error } = await supabase
      .from('site_settings')
      .select('google_analytics_id, gtm_container_id')
      .single();

    if (error) {
      console.error('Error fetching Google Analytics ID:', error);
      return;
    }

    const analyticsId = data?.google_analytics_id;
    const gtmId = data?.gtm_container_id;

    // Force direct GA4 implementation even if GTM is configured
    // Since current GTM setup is only for server-side tracking
    console.log('Loading direct GA4 implementation alongside GTM');

    // Direct GA4 implementation only if GTM is not used
    if (analyticsId && analyticsId.trim() !== '') {
      // Create script elements for Google Analytics
      const gtagScript = document.createElement('script');
      gtagScript.async = true;
      gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
      document.head.appendChild(gtagScript);

      // Initialize gtag
      window.dataLayer = window.dataLayer || [];
      // Define the gtag function
      function gtag(...args: any[]) {
        window.dataLayer.push(arguments);
      }

      // Use the gtag function
      gtag('js', new Date());
      gtag('config', analyticsId);

      // Add gtag function to window
      window.gtag = gtag;

      console.log('Google Analytics initialized with ID:', analyticsId);
    }
  } catch (error) {
    console.error('Failed to initialize Google Analytics:', error);
  }
};

// Initialize the service cache
// This will now handle all service content consistently
initializeServiceCache().catch((error) => {
  console.error('Failed to initialize service cache:', error);
});

// Initialize Google Analytics
initializeGoogleAnalytics().catch((error) => {
  console.error('Failed to initialize Google Analytics:', error);
});

// Function to verify GTM container ID
async function verifyGTMContainerId() {
  try {
    // Import supabase client
    const { supabase } = await import('./lib/supabase');

    // Fetch site settings
    const { data, error } = await supabase
      .from('site_settings')
      .select('gtm_container_id')
      .single();

    if (error) {
      console.error('Error fetching GTM container ID:', error);
      return;
    }

    const gtmId = data?.gtm_container_id;

    if (gtmId) {
      // Log the verification result
      console.log(`GTM container ID in database: ${gtmId}`);
      
      // Look for this GTM container ID in the DOM
      const gtmScripts = document.querySelectorAll('script');
      let foundInScript = false;
      
      gtmScripts.forEach((script) => {
        if (script.innerHTML.includes(gtmId)) {
          foundInScript = true;
        }
      });
      
      console.log(`GTM container ID found in scripts: ${foundInScript ? 'Yes' : 'No'}`);
      
      // If the current container ID is different than what's in the scripts,
      // only then replace it (this is to avoid replacing our hardcoded value)
      const gtmScriptContent = Array.from(gtmScripts).find(script => 
        script.innerHTML.includes('googletagmanager.com/gtm.js')
      )?.innerHTML;
      
      if (gtmScriptContent && !gtmScriptContent.includes(gtmId) && gtmScriptContent.includes('GTM-')) {
        // Extract current GTM ID from script
        const currentGtmId = gtmScriptContent.match(/GTM-[A-Z0-9]+/)?.[0];
        
        if (currentGtmId && currentGtmId !== gtmId) {
          console.log(`Replacing GTM ID ${currentGtmId} with ${gtmId}`);
          
          // Update GTM container ID in the script tag
          gtmScripts.forEach((script) => {
            if (script.innerHTML.includes('googletagmanager.com/gtm.js')) {
              script.innerHTML = script.innerHTML.replace(currentGtmId, gtmId);
            }
          });

          // Update GTM container ID in the noscript iframe
          const gtmIframes = document.querySelectorAll('iframe');
          gtmIframes.forEach((iframe) => {
            if (iframe.src.includes('googletagmanager.com/ns.html')) {
              iframe.src = iframe.src.replace(currentGtmId, gtmId);
            }
          });

          console.log('GTM container ID updated successfully');
        }
      }
    } else {
      console.warn('No GTM container ID found in site settings');
    }
  } catch (err) {
    console.error('Failed to verify GTM container ID:', err);
  }
}

// Call the function to verify GTM container ID
verifyGTMContainerId();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
