import React from 'react';
import { SiteSettings } from '@/types/settings';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { InfoIcon, CheckCircleIcon, XCircleIcon } from 'lucide-react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Badge } from '@/components/ui/badge';

interface GTMTabProps {
  settings: SiteSettings;
  saving: boolean;
  handleChange: (field: keyof SiteSettings, value: string) => void;
}

const GTMTab: React.FC<GTMTabProps> = ({ 
  settings, 
  saving, 
  handleChange 
}) => {
  const hasGtmId = !!settings.gtm_container_id;

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <div className="flex items-center justify-between">
            <CardTitle>Google Tag Manager</CardTitle>
            {hasGtmId ? (
              <Badge variant="outline" className="bg-green-50 text-green-700 border-green-200">
                <CheckCircleIcon className="h-4 w-4 mr-1" />
                Configured
              </Badge>
            ) : (
              <Badge variant="outline" className="bg-amber-50 text-amber-700 border-amber-200">
                <XCircleIcon className="h-4 w-4 mr-1" />
                Not Configured
              </Badge>
            )}
          </div>
          <CardDescription>
            Configure Google Tag Manager to centrally manage all your marketing and analytics tags
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <Alert className="bg-amber-50 border-amber-200">
            <InfoIcon className="h-4 w-4 text-amber-500" />
            <AlertDescription className="text-amber-700">
              Google Tag Manager allows you to manage all your marketing tags (Google Analytics, Facebook Pixel, etc.) 
              through a single interface without changing code. It's the recommended approach for tag management.
            </AlertDescription>
          </Alert>
          
          <div className="space-y-2">
            <Label htmlFor="gtm_container_id">GTM Container ID</Label>
            <Input
              id="gtm_container_id"
              name="gtm_container_id"
              value={settings.gtm_container_id || ''}
              onChange={(e) => handleChange('gtm_container_id', e.target.value)}
              placeholder="GTM-XXXXXXX"
              disabled={saving}
              className="w-full max-w-md"
            />
            <p className="text-sm text-gray-500">
              Enter your Google Tag Manager Container ID (starts with GTM-). Leave empty to disable GTM.
            </p>
          </div>

          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="gtm-setup">
              <AccordionTrigger className="text-sm font-medium">
                How to set up Google Tag Manager
              </AccordionTrigger>
              <AccordionContent>
                <ol className="list-decimal pl-5 space-y-2 text-sm text-gray-700">
                  <li>Go to <a href="https://tagmanager.google.com/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Google Tag Manager</a></li>
                  <li>Create a new account if you don't have one already</li>
                  <li>Create a new container for your website</li>
                  <li>You'll receive a Container ID in the format GTM-XXXXXXX</li>
                  <li>Enter that ID in the field above</li>
                  <li>In GTM, set up your tags (like Google Analytics, Facebook Pixel, etc.)</li>
                  <li>Publish your container to make your tags live</li>
                </ol>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="gtm-benefits">
              <AccordionTrigger className="text-sm font-medium">
                Benefits of using Google Tag Manager
              </AccordionTrigger>
              <AccordionContent>
                <ul className="list-disc pl-5 space-y-2 text-sm text-gray-700">
                  <li><span className="font-medium">No code changes needed:</span> Add or modify tags without developer assistance</li>
                  <li><span className="font-medium">Centralized management:</span> Manage all your tracking tags in one place</li>
                  <li><span className="font-medium">Version control:</span> Track changes and roll back if needed</li>
                  <li><span className="font-medium">Debug mode:</span> Test your tags before publishing</li>
                  <li><span className="font-medium">User permissions:</span> Control who can make changes to your tags</li>
                  <li><span className="font-medium">Tag firing rules:</span> Set up sophisticated rules for when tags should fire</li>
                </ul>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </CardContent>
      </Card>
    </div>
  );
};

export default GTMTab;