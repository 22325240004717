
import { useState } from 'react';
import { supabase } from '@/lib/supabase';
import { Gallery, GalleryImage } from './types';

export const useFetchGallery = () => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  
  const fetchGallery = async (slug: string): Promise<Gallery | null> => {
    try {
      setLoading(true);
      
      // First, get the gallery details
      const { data, error } = await supabase
        .from('galleries')
        .select('*')
        .eq('slug', slug)
        .eq('is_public', true)
        .single();
      
      if (error) {
        if (error.code === 'PGRST116') {
          setNotFound(true);
        } else {
          console.error('Error fetching gallery:', error);
        }
        return null;
      }
      
      // Next, get the gallery's photos
      const { data: photosData, error: photosError } = await supabase
        .from('gallery_photos')
        .select('id, image_url, caption')
        .eq('gallery_id', data.id)
        .order('sort_order', { ascending: true });
      
      if (photosError) {
        console.error('Error fetching gallery photos:', photosError);
      }
      
      let galleryImages = photosData || [];
      
      // If we have job_number and franchise_region, also get franchise images
      if (data.job_number && data.franchise_region) {
        const { data: franchiseImages, error: franchiseError } = await supabase
          .from('franchise_image_jobs')
          .select('id, image_url')
          .eq('job_number', data.job_number)
          .eq('franchise_region', data.franchise_region);
        
        if (franchiseError) {
          console.error('Error fetching franchise images:', franchiseError);
        } else if (franchiseImages && franchiseImages.length > 0) {
          // Get list of images we already have to avoid duplicates
          const existingUrls = new Set(galleryImages.map(img => img.image_url));
          
          // Format franchise images to match gallery images structure
          const formattedFranchiseImages = franchiseImages
            .filter(img => !existingUrls.has(img.image_url))
            .map(img => ({
              id: `franchise-${img.id}`,
              image_url: img.image_url,
              caption: `Job ${data.job_number} Image`
            }));
          
          // Combine all images
          galleryImages = [...galleryImages, ...formattedFranchiseImages];
        }
      }
      
      // Debug gallery images
      console.log("Gallery images loaded:", {
        count: galleryImages.length,
        sampleURLs: galleryImages.slice(0, 3).map(img => img.image_url)
      });
      
      // Set the complete gallery data with images
      return {
        ...data,
        images: galleryImages
      };
      
    } catch (error) {
      console.error('Error in gallery fetch:', error);
      setNotFound(true);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchGallery,
    loading,
    notFound,
    setLoading
  };
};
