
import React from 'react';
import { Card } from '@/components/ui/card';

interface TemplateSectionProps {
  onInsertTemplate: (templateType: string) => void;
}

export const TemplateSection = ({ onInsertTemplate }: TemplateSectionProps) => {
  return (
    <Card className="p-4 bg-muted/50">
      <h4 className="text-sm font-medium mb-2">Insert Layout Template:</h4>
      <div className="flex flex-wrap gap-2">
        <button 
          onClick={() => onInsertTemplate('twoColumn')} 
          className="px-3 py-1 text-xs bg-white border rounded hover:bg-gray-50"
        >
          Two Columns
        </button>
        <button 
          onClick={() => onInsertTemplate('callout')} 
          className="px-3 py-1 text-xs bg-white border rounded hover:bg-gray-50"
        >
          Callout Box
        </button>
        <button 
          onClick={() => onInsertTemplate('imageText')} 
          className="px-3 py-1 text-xs bg-white border rounded hover:bg-gray-50"
        >
          Image + Text
        </button>
        <button 
          onClick={() => onInsertTemplate('quote')} 
          className="px-3 py-1 text-xs bg-white border rounded hover:bg-gray-50"
        >
          Quote Block
        </button>
      </div>
    </Card>
  );
};
