
import React from 'react';
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";

interface ServiceSelectionFieldProps {
  serviceId: string | null | undefined;
  handleServiceChange: (serviceId: string | null) => void;
  availableServices: { id: string; title: string }[];
  loading: boolean;
}

const ServiceSelectionField = ({
  serviceId,
  handleServiceChange,
  availableServices,
  loading
}: ServiceSelectionFieldProps) => {
  return (
    <div className="space-y-2">
      <Label htmlFor="service">Associated Service</Label>
      {loading ? (
        <Skeleton className="h-10 w-full" />
      ) : (
        <Select 
          value={serviceId || "none"} 
          onValueChange={(value) => handleServiceChange(value === "none" ? null : value)}
        >
          <SelectTrigger id="service">
            <SelectValue placeholder="Select a service (optional)" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="none">None</SelectItem>
            {availableServices.map((service) => (
              <SelectItem key={service.id} value={service.id}>
                {service.title}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}
      <p className="text-sm text-muted-foreground">
        Link this gallery to a specific service for better organization
      </p>
    </div>
  );
};

export default ServiceSelectionField;
