
import { 
  LayoutDashboard, 
  FileCog, 
  MapPin, 
  Star, 
  MessageSquare, 
  Settings, 
  ImageIcon, 
  FileText, 
  UserPlus,
  Database,
  BookOpen,
  FolderIcon,
  HardHat,
  Building,
} from 'lucide-react';
import { LucideIcon } from 'lucide-react';

export interface NavigationItem {
  path: string;
  name: string;
  icon: LucideIcon;
  description?: string;
}

export interface NavigationCategory {
  category: string;
  items: NavigationItem[];
}

export const navigationItems: NavigationCategory[] = [
  {
    category: 'Overview',
    items: [
      {
        path: '/admin',
        name: 'Dashboard',
        icon: LayoutDashboard,
        description: 'View analytics and key metrics',
      }
    ]
  },
  {
    category: 'Content',
    items: [
      {
        path: '/admin/services',
        name: 'Services',
        icon: FileCog,
        description: 'Manage service pages',
      },
      {
        path: '/admin/locations',
        name: 'Locations',
        icon: MapPin,
        description: 'Manage location pages',
      },
      {
        path: '/admin/projects',
        name: 'Projects',
        icon: Building,
        description: 'Manage project showcases',
      },
      {
        path: '/admin/reviews',
        name: 'Reviews',
        icon: Star,
        description: 'Manage customer reviews',
      },
      {
        path: '/admin/leads',
        name: 'Leads',
        icon: MessageSquare,
        description: 'View and manage customer inquiries',
      },
      {
        path: '/admin/blog',
        name: 'Blog',
        icon: BookOpen,
        description: 'Manage blog posts and categories',
      },
      {
        path: '/admin/static-pages',
        name: 'Static Pages',
        icon: FileText,
        description: 'Manage static pages',
      },
      {
        path: '/admin/galleries',
        name: 'Gallery',
        icon: ImageIcon,
        description: 'Manage photo galleries',
      },
      {
        path: '/admin/assets',
        name: 'Asset Library',
        icon: FolderIcon,
        description: 'Manage media assets',
      },
      {
        path: '/admin/wsib',
        name: 'WSIB',
        icon: HardHat,
        description: 'Manage WSIB certificates',
      }
    ]
  },
  {
    category: 'Administration',
    items: [
      {
        path: '/admin/gmb-locations',
        name: 'GMB Locations',
        icon: MapPin,
        description: 'Manage Google My Business locations',
      },
      {
        path: '/admin/migration',
        name: 'WP Migration',
        icon: Database,
        description: 'WordPress migration utilities',
      },
      {
        path: '/admin/invite',
        name: 'Invite User',
        icon: UserPlus,
        description: 'Invite new admin users',
      },
      {
        path: '/admin/settings',
        name: 'Settings',
        icon: Settings,
        description: 'Configure site settings',
      }
    ]
  }
];
