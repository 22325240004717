
import { supabase } from './client';

export interface GMBLocation {
  id: string;
  google_place_id: string;
  location_name: string;
  address?: string;
  city?: string;
  province?: string;
  postal_code?: string;
  country?: string;
  phone_number?: string;
  website_url?: string;
  latitude?: number;
  longitude?: number;
  is_active: boolean;
  location_id?: string;
  created_at: string;
  updated_at: string;
}

export interface GMBLocationCreate {
  google_place_id: string;
  location_name: string;
  address?: string;
  city?: string;
  province?: string;
  postal_code?: string;
  country?: string;
  phone_number?: string;
  website_url?: string;
  latitude?: number;
  longitude?: number;
  is_active?: boolean;
  location_id?: string;
}

// Fetch all GMB locations
export async function fetchGMBLocations() {
  try {
    const { data, error } = await supabase
      .from('gmb_locations')
      .select('*')
      .order('location_name', { ascending: true });
    
    if (error) {
      console.error('Error fetching GMB locations:', error);
      return [];
    }
    
    return data as GMBLocation[];
  } catch (error) {
    console.error('Error in fetchGMBLocations:', error);
    return [];
  }
}

// Fetch a single GMB location by ID
export async function fetchGMBLocationById(id: string) {
  try {
    const { data, error } = await supabase
      .from('gmb_locations')
      .select('*')
      .eq('id', id)
      .single();
    
    if (error) {
      console.error('Error fetching GMB location by ID:', error);
      return null;
    }
    
    return data as GMBLocation;
  } catch (error) {
    console.error('Error in fetchGMBLocationById:', error);
    return null;
  }
}

// Fetch a GMB location by Google Place ID
export async function fetchGMBLocationByPlaceId(placeId: string) {
  try {
    const { data, error } = await supabase
      .from('gmb_locations')
      .select('*')
      .eq('google_place_id', placeId)
      .single();
    
    if (error) {
      console.error('Error fetching GMB location by Place ID:', error);
      return null;
    }
    
    return data as GMBLocation;
  } catch (error) {
    console.error('Error in fetchGMBLocationByPlaceId:', error);
    return null;
  }
}

// Create a new GMB location
export async function createGMBLocation(location: GMBLocationCreate) {
  try {
    const { data, error } = await supabase
      .from('gmb_locations')
      .insert(location)
      .select()
      .single();
    
    if (error) {
      console.error('Error creating GMB location:', error);
      return null;
    }
    
    return data as GMBLocation;
  } catch (error) {
    console.error('Error in createGMBLocation:', error);
    return null;
  }
}

// Update an existing GMB location
export async function updateGMBLocation(id: string, updates: Partial<GMBLocationCreate>) {
  try {
    const { data, error } = await supabase
      .from('gmb_locations')
      .update(updates)
      .eq('id', id)
      .select()
      .single();
    
    if (error) {
      console.error('Error updating GMB location:', error);
      return null;
    }
    
    return data as GMBLocation;
  } catch (error) {
    console.error('Error in updateGMBLocation:', error);
    return null;
  }
}

// Delete a GMB location
export async function deleteGMBLocation(id: string) {
  try {
    const { error } = await supabase
      .from('gmb_locations')
      .delete()
      .eq('id', id);
    
    if (error) {
      console.error('Error deleting GMB location:', error);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Error in deleteGMBLocation:', error);
    return false;
  }
}

// Fetch GMB locations by associated location ID
export async function fetchGMBLocationsByLocationId(locationId: string) {
  try {
    const { data, error } = await supabase
      .from('gmb_locations')
      .select('*')
      .eq('location_id', locationId);
    
    if (error) {
      console.error('Error fetching GMB locations by location ID:', error);
      return [];
    }
    
    return data as GMBLocation[];
  } catch (error) {
    console.error('Error in fetchGMBLocationsByLocationId:', error);
    return [];
  }
}
