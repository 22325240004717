
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { 
  createPost, 
  updatePost,
  fetchPostById,
  updatePostCategories,
  updatePostTags,
  updatePostSeo
} from '@/lib/supabase/blog';

export interface PostFormData {
  title: string;
  slug: string;
  excerpt: string;
  content: string;
  status: string;
  categories: string[];
  tags: string[];
  featured_image?: string | null;
  created_at?: string;
  updated_at?: string;
}

export interface SEOFormData {
  meta_title?: string;
  meta_description?: string;
  focus_keyword?: string;
  canonical_url?: string;
  og_title?: string;
  og_description?: string;
  og_image?: string;
}

export function useBlogPostForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isNewPost = !id;

  const [formData, setFormData] = useState<PostFormData>({
    title: '',
    slug: '',
    excerpt: '',
    content: '',
    status: 'draft',
    categories: [],
    tags: [],
    featured_image: null
  });

  const [seoData, setSeoData] = useState<SEOFormData>({
    meta_title: '',
    meta_description: '',
    focus_keyword: '',
    canonical_url: '',
    og_title: '',
    og_description: '',
    og_image: ''
  });

  // Fetch post data if editing
  const { data: post, isLoading: isLoadingPost, error: postError } = useQuery({
    queryKey: ['blog-post', id],
    queryFn: () => fetchPostById(id!),
    enabled: !!id,
    retry: 3,
    meta: {
      onError: (error: Error) => {
        console.error("Error fetching blog post:", error);
        toast.error(`Failed to load blog post: ${error.message}`);
      }
    }
  });

  // Update formData when post data is loaded
  useEffect(() => {
    if (post) {
      setFormData({
        title: post.title || '',
        slug: post.slug || '',
        excerpt: post.excerpt || '',
        content: post.content || '',
        status: post.status || 'draft',
        categories: post.categories?.map(c => c.id) || [],
        tags: post.tags?.map(t => t.id) || [],
        featured_image: post.featured_image,
        created_at: post.created_at,
        updated_at: post.updated_at
      });

      // Load SEO data if available
      if (post.seo) {
        setSeoData({
          meta_title: post.seo.meta_title || '',
          meta_description: post.seo.meta_description || '',
          focus_keyword: post.seo.focus_keyword || '',
          canonical_url: post.seo.canonical_url || '',
          og_title: post.seo.og_title || '',
          og_description: post.seo.og_description || '',
          og_image: post.seo.og_image || ''
        });
      }
    }
  }, [post]);

  // Create post mutation
  const createPostMutation = useMutation({
    mutationFn: async (data: PostFormData) => {
      const { categories, tags, ...postData } = data;
      const newPost = await createPost(postData);
      return newPost;
    },
    onSuccess: async (newPost) => {
      if (formData.categories.length > 0) {
        await updatePostCategories(newPost.id, formData.categories);
      }
      
      if (formData.tags.length > 0) {
        await updatePostTags(newPost.id, formData.tags);
      }
      
      // Save SEO data if provided
      if (Object.values(seoData).some(val => val && val.trim() !== '')) {
        await updatePostSeo(newPost.id, seoData);
      }
      
      queryClient.invalidateQueries({ queryKey: ['blog-posts'] });
      toast.success('Post created successfully');
      navigate('/admin/blog/posts');
    },
    onError: (error: Error) => {
      toast.error(`Error creating post: ${error.message}`);
    }
  });

  // Update post mutation
  const updatePostMutation = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: PostFormData }) => {
      const { categories, tags, ...postData } = data;
      await updatePost(id, postData);
      
      if (categories.length > 0) {
        await updatePostCategories(id, categories);
      }
      
      if (tags.length > 0) {
        await updatePostTags(id, tags);
      }
      
      // Save SEO data
      await updatePostSeo(id, seoData);
      
      return true;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['blog-posts'] });
      queryClient.invalidateQueries({ queryKey: ['blog-post', id] });
      toast.success('Post updated successfully');
      navigate('/admin/blog/posts');
    },
    onError: (error: Error) => {
      toast.error(`Error updating post: ${error.message}`);
    }
  });

  // Handle input changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    
    if (id === 'title' && isNewPost) {
      setFormData({
        ...formData,
        title: value,
        slug: value.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-|-$/g, '')
      });
    } else {
      setFormData({
        ...formData,
        [id]: value
      });
    }
  };

  const handleStatusChange = (value: string) => {
    setFormData({
      ...formData,
      status: value
    });
  };

  const handleSeoChange = (field: string, value: string) => {
    setSeoData({
      ...seoData,
      [field]: value
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!formData.title || !formData.slug || !formData.content) {
      toast.error('Title, slug, and content are required');
      return;
    }
    
    if (isNewPost) {
      createPostMutation.mutate(formData);
    } else if (id) {
      updatePostMutation.mutate({ id, data: formData });
    }
  };

  return {
    formData,
    seoData,
    isNewPost,
    isLoadingPost,
    postError,
    handleInputChange,
    handleStatusChange,
    handleSeoChange,
    handleSubmit,
    createPostMutation,
    updatePostMutation
  };
}
