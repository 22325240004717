
import { useState } from 'react';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FranchiseImage } from './types';

export const useImageStatusUpdate = () => {
  const [approving, setApproving] = useState<{[key: string]: boolean}>({});
  const [rejecting, setRejecting] = useState<{[key: string]: boolean}>({});
  
  // Get access to the query client for cache invalidation
  const queryClient = useQueryClient();

  // Create a mutation for updating image status
  const mutation = useMutation({
    mutationFn: async (params: {
      id: string | number,
      status: 'approved' | 'rejected' | 'pending'
    }) => {
      const { id, status } = params;
      
      // Set loading state based on action
      if (status === 'approved') {
        setApproving(prev => ({ ...prev, [id]: true }));
      } else if (status === 'rejected') {
        setRejecting(prev => ({ ...prev, [id]: true }));
      }
      
      // Update status in Supabase
      const { data, error } = await supabase
        .from('franchise_image_jobs')
        .update({ review_status: status })
        .eq('id', id)
        .select('*')
        .single();
      
      if (error) throw error;
      
      // After updating the image status, trigger the gallery status update function
      try {
        await supabase.functions.invoke('update-gallery-status');
      } catch (functionError) {
        console.error('Error invoking update-gallery-status function:', functionError);
        // We don't want to fail the mutation if the function call fails
        // as the image status has already been updated successfully
      }
      
      return data as FranchiseImage;
    },
    onSuccess: (data) => {
      // Show success message
      toast.success(`Image ${data.review_status} successfully`);
      
      // Invalidate queries to refetch data
      queryClient.invalidateQueries({
        queryKey: ['franchise-images']
      });
      
      // Also invalidate galleries data to reflect any status changes
      queryClient.invalidateQueries({
        queryKey: ['galleries']
      });
    },
    onError: (error: Error) => {
      console.error(`Error updating image status:`, error);
      toast.error(`Failed to update image status: ${error.message}`);
    },
    onSettled: (_, __, variables) => {
      // Clear loading state regardless of success or failure
      const { id, status } = variables;
      
      if (status === 'approved') {
        setApproving(prev => ({ ...prev, [id]: false }));
      } else if (status === 'rejected') {
        setRejecting(prev => ({ ...prev, [id]: false }));
      }
    }
  });
  
  const updateImageStatus = (
    id: string | number,
    status: 'approved' | 'rejected' | 'pending'
  ) => {
    mutation.mutate({ id, status });
  };
  
  return {
    approving,
    rejecting,
    updateImageStatus,
    isUpdating: mutation.isPending
  };
};
