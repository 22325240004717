
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { 
  fetchGMBLocations, 
  fetchLocations
} from '@/lib/supabase';

export const useGMBLocations = () => {
  const { data: gmbLocations, isLoading: gmbLoading } = useQuery({
    queryKey: ['gmbLocations'],
    queryFn: fetchGMBLocations,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const { data: websiteLocations, isLoading: locationsLoading } = useQuery({
    queryKey: ['locations'],
    queryFn: () => fetchLocations(),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const isLoading = gmbLoading || locationsLoading;

  const getWebsiteLocationName = (locationId?: string) => {
    if (!locationId || !websiteLocations) return 'Not linked';
    const location = websiteLocations.find(loc => loc.id.toString() === locationId);
    return location ? location.name : 'Not linked';
  };

  return {
    gmbLocations,
    websiteLocations,
    isLoading,
    getWebsiteLocationName
  };
};
