
import React from 'react';
import Header from '@/components/header';
import Footer from '@/components/Footer';
import { Helmet } from 'react-helmet-async';
import ProjectsSection from '@/components/ProjectsSection';
import { Card, CardContent } from '@/components/ui/card';
import { ArrowLeft } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';
import { cn } from '@/lib/utils';

const Projects = () => {
  const { slug } = useParams<{ slug: string }>();
  
  return (
    <>
      <Helmet>
        <title>{slug ? `${slug.replace(/-/g, ' ')} Project | Fix My Brick` : 'Our Projects | Fix My Brick'}</title>
        <meta 
          name="description" 
          content="View our completed masonry and brick repair projects across the Greater Toronto Area. Before and after photos showcasing our quality workmanship."
        />
      </Helmet>
      
      <div className="min-h-screen flex flex-col">
        <Header />
        
        <main className="flex-grow py-8 md:py-12">
          <div className="container mx-auto px-4">
            {slug ? (
              // Project detail view
              <div className="max-w-4xl mx-auto">
                <Link to="/projects" className="inline-flex items-center text-D00B0E hover:text-D00B0E/80 mb-6">
                  <ArrowLeft className="mr-2 h-4 w-4" />
                  Back to all projects
                </Link>
                
                <Card className={cn("overflow-hidden mb-8")}>
                  <div className="relative h-[300px] md:h-[400px] bg-cover bg-center" 
                    style={{ backgroundImage: `url(https://images.unsplash.com/photo-1635424710928-0544e8512eae?q=80&w=1740&auto=format&fit=crop)` }}>
                    <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent flex items-end">
                      <div className="p-6 text-white">
                        <h1 className="text-2xl md:text-4xl font-bold capitalize mb-2">
                          {slug.replace(/-/g, ' ')}
                        </h1>
                        <p className="text-white/80">Toronto, ON</p>
                      </div>
                    </div>
                  </div>
                  
                  <CardContent className="p-6">
                    <div className="grid md:grid-cols-2 gap-8">
                      <div>
                        <h2 className="text-xl font-semibold mb-4">Project Overview</h2>
                        <p className="text-gray-700 mb-4">
                          This {slug.replace(/-/g, ' ')} project required extensive repairs to restore both functionality and aesthetic appeal. Our team worked diligently to ensure all work was completed to the highest standards.
                        </p>
                        <p className="text-gray-700">
                          The project was completed on time and on budget, with the homeowner extremely satisfied with the results. We used premium materials that match the original construction while providing improved durability.
                        </p>
                      </div>
                      
                      <div>
                        <h2 className="text-xl font-semibold mb-4">Project Details</h2>
                        <ul className="space-y-2 text-gray-700">
                          <li><span className="font-medium">Location:</span> Toronto, ON</li>
                          <li><span className="font-medium">Duration:</span> 2 weeks</li>
                          <li><span className="font-medium">Services:</span> Masonry Repair, Tuckpointing</li>
                          <li><span className="font-medium">Materials:</span> Premium brick, Polymer-modified mortar</li>
                          <li><span className="font-medium">Challenge:</span> Matching historic materials</li>
                        </ul>
                      </div>
                    </div>
                    
                    <div className="mt-8">
                      <h2 className="text-xl font-semibold mb-4">Before & After</h2>
                      <div className="grid md:grid-cols-2 gap-4">
                        <div>
                          <p className="font-medium mb-2">Before</p>
                          <div className="aspect-video bg-gray-200 rounded-md overflow-hidden">
                            <img
                              src="https://images.unsplash.com/photo-1631941618804-b5db0132d036?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1080&q=80"
                              alt="Before repair"
                              className="w-full h-full object-cover"
                            />
                          </div>
                        </div>
                        <div>
                          <p className="font-medium mb-2">After</p>
                          <div className="aspect-video bg-gray-200 rounded-md overflow-hidden">
                            <img
                              src="https://images.unsplash.com/photo-1605276277265-84e8b16dc0dd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1080&q=80"
                              alt="After repair"
                              className="w-full h-full object-cover"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            ) : (
              // Projects listing view
              <>
                <div className="text-center mb-12">
                  <h1 className="text-3xl md:text-4xl font-bold mb-4">Our Projects</h1>
                  <p className="text-lg text-gray-600 max-w-3xl mx-auto">
                    Browse our collection of completed masonry and brick repair projects. See the before and after transformations that showcase our quality workmanship.
                  </p>
                </div>
                
                <ProjectsSection />
              </>
            )}
          </div>
        </main>
        
        <Footer />
      </div>
    </>
  );
};

export default Projects;
