
import React from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { PlusCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

interface BlogPostsToolbarProps {
  postsCount: number;
  searchTerm: string;
  onSearchChange: (value: string) => void;
  statusFilter: string;
  onStatusFilterChange: (value: string) => void;
}

export const BlogPostsToolbar = ({
  postsCount,
  searchTerm,
  onSearchChange,
  statusFilter,
  onStatusFilterChange
}: BlogPostsToolbarProps) => {
  const navigate = useNavigate();

  return (
    <div className="mb-6 flex flex-col space-y-4 md:space-y-0 md:flex-row md:justify-between md:items-center">
      <div className="flex flex-col">
        <div className="flex items-center gap-4">
          <Button onClick={() => navigate('/admin/blog/posts/new')}>
            <PlusCircle className="mr-2 h-4 w-4" />
            New Post
          </Button>
          <p className="text-sm text-muted-foreground">
            Total Posts: {postsCount}
          </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 w-full md:w-auto">
        <Select
          value={statusFilter}
          onValueChange={onStatusFilterChange}
        >
          <SelectTrigger className="w-full md:w-[150px]">
            <SelectValue placeholder="Filter by status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Statuses</SelectItem>
            <SelectItem value="draft">Draft</SelectItem>
            <SelectItem value="published">Published</SelectItem>
          </SelectContent>
        </Select>
        <Input 
          placeholder="Search posts..." 
          value={searchTerm}
          onChange={(e) => onSearchChange(e.target.value)}
          className="w-full md:w-[250px]"
        />
      </div>
    </div>
  );
};
