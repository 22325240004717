
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AdminLayout from '@/components/admin/AdminLayout';
import { Button } from '@/components/ui/button';
import { PlusCircle } from 'lucide-react';
import { Card } from '@/components/ui/card';

const AdminBlog = () => {
  const navigate = useNavigate();

  return (
    <AdminLayout title="Blog Management">
      <div className="mb-6 flex justify-between items-center">
        <div>
          <h2 className="text-lg font-medium">Blog Posts</h2>
          <p className="text-sm text-muted-foreground">
            Manage your blog posts, categories, and tags
          </p>
        </div>
        <Button onClick={() => navigate('/admin/blog/new')}>
          <PlusCircle className="mr-2 h-4 w-4" />
          New Post
        </Button>
      </div>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        <Card className="p-4">
          <h3 className="font-medium mb-2">Posts</h3>
          <p className="text-sm text-muted-foreground mb-4">
            Create and manage your blog posts
          </p>
          <Button variant="outline" className="w-full" onClick={() => navigate('/admin/blog/posts')}>
            Manage Posts
          </Button>
        </Card>

        <Card className="p-4">
          <h3 className="font-medium mb-2">Categories</h3>
          <p className="text-sm text-muted-foreground mb-4">
            Organize posts with categories
          </p>
          <Button variant="outline" className="w-full" onClick={() => navigate('/admin/blog/categories')}>
            Manage Categories
          </Button>
        </Card>

        <Card className="p-4">
          <h3 className="font-medium mb-2">Tags</h3>
          <p className="text-sm text-muted-foreground mb-4">
            Add tags to make posts discoverable
          </p>
          <Button variant="outline" className="w-full" onClick={() => navigate('/admin/blog/tags')}>
            Manage Tags
          </Button>
        </Card>
      </div>
    </AdminLayout>
  );
};

export default AdminBlog;
