
import { useState, useEffect } from 'react';
import { Location } from '@/types/location';
import { toast } from 'sonner';
import { useLocationQuery, useChildLocationsQuery } from './useLocationQuery';
import { initializeLocationCache } from '@/lib/locationCache';
import { fetchLocationBySlug } from '@/lib/supabase/locations';

// Initialize the cache when this module loads
initializeLocationCache();

interface UseLocationDataProps {
  locationSlug?: string;
  sublocationSlug?: string;
}

interface LocationDataResult {
  location: Location | null;
  parentLocation: Location | null;
  childLocations: Location[];
  loading: boolean;
}

export const useLocationData = ({ 
  locationSlug, 
  sublocationSlug 
}: UseLocationDataProps): LocationDataResult => {
  const [parentLocation, setParentLocation] = useState<Location | null>(null);

  // Use React Query for location data
  const {
    data: location,
    isLoading: locationLoading,
    error: locationError
  } = useLocationQuery(sublocationSlug || locationSlug);

  // Handle sublocation parent fetching if needed
  useEffect(() => {
    async function loadParentLocation() {
      if (sublocationSlug && locationSlug && location?.parent_id) {
        try {
          const parentData = await fetchLocationBySlug(locationSlug);
          setParentLocation(parentData);
        } catch (error) {
          console.error("Error loading parent location:", error);
          setParentLocation(null);
        }
      } else {
        setParentLocation(null);
      }
    }

    if (location) {
      loadParentLocation();
    }
  }, [location, sublocationSlug, locationSlug]);

  // Use React Query for child locations
  const {
    data: childLocations = [],
    isLoading: childrenLoading
  } = useChildLocationsQuery(
    location?.id && typeof location.id === 'string' ? location.id : undefined
  );

  // Handle errors
  useEffect(() => {
    if (locationError && (locationSlug || sublocationSlug)) {
      toast.error("Failed to load location details");
    }
  }, [locationError, locationSlug, sublocationSlug]);

  return { 
    location, 
    parentLocation, 
    childLocations, 
    loading: locationLoading || childrenLoading
  };
};
