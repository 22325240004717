import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchStaticPages, fetchServices, fetchPosts } from '@/lib/supabase';
import { fetchLocations } from '@/lib/supabase';
import Header from '@/components/header';
import Footer from '@/components/Footer';
import { Helmet } from 'react-helmet-async';

interface PageLink {
  url: string;
  title: string;
  lastModified?: string;
}

const SiteMap = () => {
  const [pages, setPages] = useState<PageLink[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadSiteMap() {
      setLoading(true);
      
      try {
        // Get static pages
        const staticPages = await fetchStaticPages();
        const staticPageLinks = staticPages.map(page => ({
          url: `/${page.slug}`,
          title: page.title,
          lastModified: page.updated_at
        }));
        
        // Get services
        const services = await fetchServices();
        const serviceLinks = services.map(service => ({
          url: `/services/${service.slug}`,
          title: service.title
        }));
        
        // Get locations
        const locations = await fetchLocations();
        const locationLinks = locations.map(location => ({
          url: `/locations/${location.slug}`,
          title: location.name
        }));
        
        // Get blog posts
        const posts = await fetchPosts();
        const postLinks = posts
          .filter(post => post.published)
          .map(post => ({
            url: `/blog/${post.slug}`,
            title: post.title,
            lastModified: post.updated_at
          }));
        
        // Combine all links
        const allLinks: PageLink[] = [
          { url: '/', title: 'Home' },
          { url: '/contact', title: 'Contact Us' },
          { url: '/blog', title: 'Blog' },
          ...staticPageLinks,
          ...serviceLinks,
          ...locationLinks,
          ...postLinks
        ];
        
        setPages(allLinks);
      } catch (error) {
        console.error('Error loading sitemap:', error);
      }
      
      setLoading(false);
    }
    
    loadSiteMap();
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>Sitemap | Fix My Brick</title>
        <meta name="description" content="A complete sitemap of the Fix My Brick website with links to all our pages." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      
      <Header />
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-12">
          <h1 className="text-3xl md:text-4xl font-bold mb-8">Sitemap</h1>
          
          {loading ? (
            <div className="animate-pulse space-y-4">
              {[...Array(10)].map((_, i) => (
                <div key={i} className="h-6 bg-gray-200 rounded w-1/3"></div>
              ))}
            </div>
          ) : (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div>
                <h2 className="text-2xl font-bold mb-4">Main Pages</h2>
                <ul className="space-y-2">
                  {pages
                    .filter(page => !page.url.includes('/services/') && !page.url.includes('/locations/') && !page.url.includes('/blog/'))
                    .map((page, index) => (
                      <li key={index}>
                        <Link 
                          to={page.url} 
                          className="text-brick hover:text-brick-dark hover:underline"
                        >
                          {page.title}
                        </Link>
                        {page.lastModified && (
                          <span className="text-sm text-gray-500 ml-2">
                            (Updated: {new Date(page.lastModified).toLocaleDateString()})
                          </span>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
              
              <div>
                <h2 className="text-2xl font-bold mb-4">Services</h2>
                <ul className="space-y-2">
                  {pages
                    .filter(page => page.url.includes('/services/'))
                    .map((page, index) => (
                      <li key={index}>
                        <Link 
                          to={page.url} 
                          className="text-brick hover:text-brick-dark hover:underline"
                        >
                          {page.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
              
              <div>
                <h2 className="text-2xl font-bold mb-4">Locations</h2>
                <ul className="space-y-2">
                  {pages
                    .filter(page => page.url.includes('/locations/'))
                    .map((page, index) => (
                      <li key={index}>
                        <Link 
                          to={page.url} 
                          className="text-brick hover:text-brick-dark hover:underline"
                        >
                          {page.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
              
              <div>
                <h2 className="text-2xl font-bold mb-4">Blog Posts</h2>
                <ul className="space-y-2">
                  {pages
                    .filter(page => page.url.includes('/blog/'))
                    .map((page, index) => (
                      <li key={index}>
                        <Link 
                          to={page.url} 
                          className="text-brick hover:text-brick-dark hover:underline"
                        >
                          {page.title}
                        </Link>
                        {page.lastModified && (
                          <span className="text-sm text-gray-500 ml-2">
                            (Updated: {new Date(page.lastModified).toLocaleDateString()})
                          </span>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}
          
          <div className="mt-12 p-6 bg-gray-50 rounded-lg">
            <h2 className="text-2xl font-bold mb-4">XML Sitemap</h2>
            <p className="mb-4">
              For search engines, we also provide an XML sitemap at:
            </p>
            <code className="bg-gray-200 p-2 rounded">
              {window.location.origin}/sitemap.xml
            </code>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default SiteMap;
