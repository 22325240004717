
import React from 'react';
import {
  FormHeader,
  PersonalInfoFields,
  ServiceSelectionFields,
  MessageField,
  FileUploadField,
  SubmitButton,
  useContactForm,
  ContactFormProps
} from './contact-form';

const ContactRequestForm: React.FC<ContactFormProps> = (props) => {
  const {
    isSubmitting,
    files,
    selectedService,
    otherService,
    showOtherServiceInput,
    predefinedServices,
    handleServiceChange,
    handleOtherServiceChange,
    handleFilesChange,
    removeFile,
    handleSubmit,
    className
  } = useContactForm(props);

  return (
    <div className={className}>
      <FormHeader />
      <form onSubmit={handleSubmit} className="p-6 space-y-4">
        <PersonalInfoFields />
        
        <ServiceSelectionFields 
          selectedService={selectedService}
          onServiceChange={handleServiceChange}
          showOtherServiceInput={showOtherServiceInput}
          otherService={otherService}
          onOtherServiceChange={handleOtherServiceChange}
          predefinedServices={predefinedServices}
        />
        
        <MessageField />
        
        <FileUploadField 
          files={files}
          onFilesChange={handleFilesChange}
          onRemoveFile={removeFile}
        />
        
        <SubmitButton isSubmitting={isSubmitting} />
      </form>
    </div>
  );
};

export default ContactRequestForm;
