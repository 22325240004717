
import React from 'react';
import { Button } from '@/components/ui/button';
import { ChevronLeft, ChevronRight, MoreHorizontal } from 'lucide-react';
import { cn } from '@/lib/utils';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  siblingCount?: number;
}

export function Pagination({
  totalPages,
  currentPage,
  onPageChange,
  siblingCount = 1
}: PaginationProps) {
  // No pagination needed if there's just one page
  if (totalPages <= 1) return null;

  const createPaginationItems = () => {
    // Always show first page
    const start = [1];
    
    // Calculate the range of pages to show around the current page
    let rangeStart = Math.max(2, currentPage - siblingCount);
    let rangeEnd = Math.min(totalPages - 1, currentPage + siblingCount);

    // Adjust range to always show a consistent number of buttons
    if (currentPage - siblingCount < 2) {
      rangeEnd = Math.min(totalPages - 1, 2 + siblingCount * 2);
    }
    if (currentPage + siblingCount > totalPages - 1) {
      rangeStart = Math.max(2, totalPages - 1 - siblingCount * 2);
    }

    // Create the items
    const items = [];

    // Add ellipsis if needed between first page and range start
    if (rangeStart > 2) {
      items.push('start-ellipsis');
    }

    // Add the range of pages
    for (let i = rangeStart; i <= rangeEnd; i++) {
      items.push(i);
    }

    // Add ellipsis if needed between range end and last page
    if (rangeEnd < totalPages - 1) {
      items.push('end-ellipsis');
    }

    // Always show last page if there's more than one page
    if (totalPages > 1) {
      items.push(totalPages);
    }

    return [start, items].flat();
  };

  const paginationItems = createPaginationItems();

  return (
    <div className="flex items-center space-x-2">
      <Button 
        variant="outline"
        size="icon"
        onClick={() => onPageChange(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
      >
        <ChevronLeft className="h-4 w-4" />
      </Button>
      
      {paginationItems.map((page, i) => {
        // Render ellipsis
        if (page === 'start-ellipsis' || page === 'end-ellipsis') {
          return (
            <Button
              key={`ellipsis-${i}`}
              variant="ghost"
              size="icon"
              disabled
            >
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          );
        }

        // Render page number
        return (
          <Button
            key={`page-${page}`}
            variant={page === currentPage ? "default" : "outline"}
            size="icon"
            onClick={() => onPageChange(page as number)}
            className={cn(
              "h-8 w-8",
              page === currentPage ? "pointer-events-none" : ""
            )}
          >
            {page}
          </Button>
        );
      })}
      
      <Button 
        variant="outline"
        size="icon"
        onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
        disabled={currentPage === totalPages}
      >
        <ChevronRight className="h-4 w-4" />
      </Button>
    </div>
  );
}
