
import { supabase } from '@/lib/supabase';
import { v4 as uuidv4 } from 'uuid';

// Constants
export const STORAGE_BUCKET = 'site-assets';
export const SERVICE_IMAGES_BUCKET = 'service_images';
export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export interface FileUploadOptions {
  /**
   * The storage bucket to upload to
   * @default 'site-assets'
   */
  bucket?: string;
  
  /**
   * Maximum file size in bytes
   * @default 5242880 (5MB)
   */
  maxSize?: number;
  
  /**
   * Prefix for the file name
   * @default ''
   */
  prefix?: string;
  
  /**
   * Whether to use timestamp instead of UUID for uniqueness
   * @default false
   */
  useTimestamp?: boolean;
}

/**
 * Uploads a file to Supabase storage and returns the public URL
 */
export async function uploadFile(
  file: File, 
  options: FileUploadOptions = {}
): Promise<string | null> {
  const { 
    bucket = STORAGE_BUCKET,
    maxSize = MAX_FILE_SIZE,
    prefix = '',
    useTimestamp = false
  } = options;
  
  try {
    // Validate file size
    if (file.size > maxSize) {
      throw new Error(`File is too large. Maximum size is ${maxSize / (1024 * 1024)}MB.`);
    }
    
    // Generate a unique filename
    const uniqueId = useTimestamp ? Date.now() : uuidv4();
    const fileExt = file.name.split('.').pop();
    const fileName = `${prefix}${prefix ? '-' : ''}${uniqueId}.${fileExt}`;
    
    // Upload to storage
    const { error: uploadError } = await supabase.storage
      .from(bucket)
      .upload(fileName, file);

    if (uploadError) throw uploadError;

    // Get public URL
    const { data: publicUrlData } = supabase.storage
      .from(bucket)
      .getPublicUrl(fileName);

    if (publicUrlData) {
      return publicUrlData.publicUrl;
    }
    
    return null;
  } catch (error) {
    console.error("Error uploading file:", error);
    return null;
  }
}

/**
 * Deletes a file from Supabase storage by URL
 */
export async function deleteFileByUrl(
  url: string, 
  bucket: string = STORAGE_BUCKET
): Promise<boolean> {
  try {
    // Check if the URL is from our storage
    if (!url.includes(bucket)) {
      return false;
    }
    
    // Extract the filename from the URL
    const urlParts = url.split('/');
    const fileName = urlParts[urlParts.length - 1];
    
    if (!fileName) {
      return false;
    }
    
    // Delete from storage
    const { error } = await supabase.storage
      .from(bucket)
      .remove([fileName]);
      
    if (error) {
      console.warn('Failed to delete file from storage:', error);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Error deleting file:', error);
    return false;
  }
}

// Legacy function for backward compatibility
export const uploadServiceImage = async (file: File): Promise<string | null> => {
  try {
    return await uploadFile(file, { 
      bucket: SERVICE_IMAGES_BUCKET,
      prefix: 'service'
    });
  } catch (error) {
    console.error('Error in uploadServiceImage:', error);
    return null;
  }
};
