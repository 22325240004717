
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { 
  createHeroBackground, 
  updateHeroBackground, 
  deleteHeroBackground, 
  HeroBackgroundCreate 
} from '@/lib/supabase';

export const useHeroBackgroundsMutations = (
  onCreateSuccess?: () => void,
  onUpdateSuccess?: () => void
) => {
  const queryClient = useQueryClient();

  // Create background mutation
  const createMutation = useMutation({
    mutationFn: createHeroBackground,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['heroBackgrounds'] });
      toast.success('Background added successfully');
      if (onCreateSuccess) onCreateSuccess();
    },
    onError: (error) => {
      console.error('Error creating hero background:', error);
      toast.error('Failed to add background');
    },
  });

  // Update background mutation
  const updateMutation = useMutation({
    mutationFn: ({
      id,
      updates,
    }: {
      id: string;
      updates: Partial<HeroBackgroundCreate>;
    }) => updateHeroBackground(id, updates),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['heroBackgrounds'] });
      toast.success('Background updated successfully');
      if (onUpdateSuccess) onUpdateSuccess();
    },
    onError: (error) => {
      console.error('Error updating hero background:', error);
      toast.error('Failed to update background');
    },
  });

  // Delete background mutation
  const deleteMutation = useMutation({
    mutationFn: deleteHeroBackground,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['heroBackgrounds'] });
      toast.success('Background deleted successfully');
    },
    onError: (error) => {
      console.error('Error deleting hero background:', error);
      toast.error('Failed to delete background');
    },
  });

  return {
    createMutation,
    updateMutation,
    deleteMutation,
  };
};
