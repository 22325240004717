
import { supabase } from './client';

interface ServerTrackingOptions {
  platform: 'ga4' | 'facebook';
  eventName: string;
  properties?: Record<string, any>;
  userData?: Record<string, any>;
  url?: string;
}

/**
 * Send a tracking event to the server-side tracking Edge Function
 * This allows for reliable conversion tracking even when client-side tracking is blocked
 */
export async function sendServerSideTracking({
  platform,
  eventName,
  properties = {},
  userData = {},
  url
}: ServerTrackingOptions): Promise<{ success: boolean; error?: string }> {
  try {
    // Prepare the event data
    const eventData = {
      event_name: eventName,
      properties,
      user_data: userData,
      url: url || (typeof window !== 'undefined' ? window.location.href : undefined)
    };

    // Call the Edge Function using the Supabase Functions client
    const { data, error } = await supabase.functions.invoke('track', {
      body: { platform, event_data: eventData }
    });

    if (error) {
      console.error('Server-side tracking error:', error);
      return { success: false, error: error.message };
    }

    // Return success without including the data property
    return { success: true };
  } catch (error) {
    console.error('Failed to send server-side tracking:', error);
    return { 
      success: false, 
      error: error instanceof Error ? error.message : 'Unknown error occurred'
    };
  }
}

/**
 * Track a form submission server-side
 * This is particularly important for lead generation and conversion tracking
 */
export async function trackFormSubmissionServerSide(
  formId: string,
  formName: string,
  formData: Record<string, any>
): Promise<void> {
  try {
    // Extract user data for better tracking match
    const userData = {
      email: formData.email,
      phone: formData.phone,
      name: formData.name,
    };

    // Send to GA4
    await sendServerSideTracking({
      platform: 'ga4',
      eventName: 'form_submit',
      properties: {
        form_id: formId,
        form_name: formName,
        ...formData
      },
      userData
    });
    
    // Send to Facebook
    await sendServerSideTracking({
      platform: 'facebook',
      eventName: 'form_submit', // Will be mapped to Lead in the Edge Function
      properties: {
        form_id: formId,
        form_name: formName,
        ...formData
      },
      userData
    });
  } catch (error) {
    // Log but don't throw to avoid interrupting form submission
    console.error('Server-side conversion tracking failed:', error);
  }
}
