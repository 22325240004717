
import React, { useState } from 'react';
import AdminLayout from '@/components/admin/AdminLayout';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import SampleLocations from '@/components/admin/table-standards/SampleLocations';
import DemoActions from '@/components/admin/table-standards/DemoActions';
import DemoTabs from '@/components/admin/table-standards/DemoTabs';

const TableStandardsDemo = () => {
  const [locations] = useState(SampleLocations);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  
  const handleSimulateLoading = () => {
    setLoading(true);
    setError(false);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  
  const handleSimulateError = () => {
    setLoading(false);
    setError(true);
  };
  
  const handleRetry = () => {
    setLoading(true);
    setError(false);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  return (
    <AdminLayout title="Table Standards Demo">
      <div className="space-y-6 p-6">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold">Table Standards Demo</h1>
          <DemoActions 
            loading={loading}
            error={error}
            onSimulateLoading={handleSimulateLoading}
            onSimulateError={handleSimulateError}
            onRetry={handleRetry}
          />
        </div>
        
        <Card>
          <CardHeader>
            <CardTitle>Standardized Table Components</CardTitle>
            <CardDescription>
              A demonstration of the new standardized table components with responsive design.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <DemoTabs 
              locations={locations}
              loading={loading}
              error={error}
              onRetry={handleRetry}
            />
          </CardContent>
        </Card>
      </div>
    </AdminLayout>
  );
};

export default TableStandardsDemo;
