
/**
 * Utility functions for service form handling
 */

/**
 * Generates a URL-friendly slug from a title
 */
export const generateSlug = (title: string): string => {
  return title
    .toLowerCase()
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-');
};

/**
 * Validates the required fields of a service form
 */
export const validateServiceForm = (
  title: string, 
  description: string, 
  fullDescription: string
): { isValid: boolean; message?: string } => {
  if (!title || !description || !fullDescription) {
    return {
      isValid: false,
      message: 'Please fill in all required fields'
    };
  }
  
  return { isValid: true };
};
