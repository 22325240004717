
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { MigrationCredentials } from '@/lib/supabase/wordpress';
import { FormError, UrlTestResult } from './FormAlerts';
import FormField from './FormField';
import { useWordPressUrlTest } from './useWordPressUrlTest';
import { CREDENTIAL_FIELDS } from './formConstants';
import { CredentialsFormProps } from './types';

const CredentialsForm: React.FC<CredentialsFormProps> = ({
  initialValues,
  onSubmit,
  saving,
}) => {
  const [formError, setFormError] = useState<string | null>(null);
  const { testingUrl, urlTestResult, testWordPressUrl } = useWordPressUrlTest();
  
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<MigrationCredentials>({
    defaultValues: {
      id: initialValues?.id || '',
      wp_url: initialValues?.wp_url || '',
      api_key: initialValues?.api_key || '',
      wp_username: initialValues?.wp_username || '',
      wp_password: initialValues?.wp_password || '',
    },
  });

  const currentWpUrl = watch('wp_url');

  const handleFormSubmit = async (data: MigrationCredentials) => {
    try {
      setFormError(null);
      
      // Ensure wp_url is set
      if (!data.wp_url) {
        setFormError('WordPress URL is required');
        return;
      }

      console.log('Form data being submitted:', data);
      await onSubmit(data);
    } catch (error) {
      console.error('Error in form submission:', error);
      if (error instanceof Error) {
        setFormError(error.message);
      } else {
        setFormError('An unknown error occurred while saving credentials');
      }
    }
  };

  const handleTestUrl = () => testWordPressUrl(currentWpUrl);

  return (
    <Card>
      <CardHeader>
        <CardTitle>WordPress Credentials</CardTitle>
        <CardDescription>
          Enter your WordPress site details to set up migration
        </CardDescription>
      </CardHeader>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <CardContent className='space-y-4'>
          <FormError error={formError} />
          
          {CREDENTIAL_FIELDS.map((field) => (
            <FormField
              key={field.id}
              field={field}
              register={register}
              error={!!errors[field.id as keyof MigrationCredentials]}
              testButton={
                field.id === 'wp_url'
                  ? {
                      show: true,
                      disabled: !currentWpUrl || testingUrl,
                      loading: testingUrl,
                      onClick: handleTestUrl,
                      value: currentWpUrl,
                    }
                  : undefined
              }
            />
          ))}

          <UrlTestResult result={urlTestResult} />
          
          <div className="rounded-md bg-blue-50 p-3 text-sm text-blue-700">
            <p className="font-medium">Authentication Recommendation</p>
            <p className="mt-1">
              WordPress API Key (Application Password) is the preferred method for authenticating with your WordPress site. 
              The username/password fields are only provided for backward compatibility.
            </p>
          </div>
        </CardContent>
        <CardFooter className='flex flex-col space-y-2'>
          <Button type='submit' disabled={saving} className='w-full'>
            {saving ? 'Saving...' : 'Save Credentials'}
          </Button>
          <p className='text-xs text-gray-500 text-center'>
            Credentials are securely stored in your Supabase database.
          </p>
        </CardFooter>
      </form>
    </Card>
  );
};

export default CredentialsForm;
