
import { useHeroBackgroundsState } from './useHeroBackgroundsState';
import { useHeroBackgroundsQuery } from './useHeroBackgroundsQuery';
import { useHeroBackgroundsActions } from './useHeroBackgroundsActions';
import { UseHeroBackgroundsResult } from './types';

export const useHeroBackgrounds = (): UseHeroBackgroundsResult => {
  // Get state handling
  const {
    uploadingBackground,
    showAddDialog,
    showEditDialog,
    selectedBackground,
    setUploadingBackground,
    setShowAddDialog,
    setShowEditDialog,
    setSelectedBackground,
  } = useHeroBackgroundsState();

  // Setup actions with callbacks for dialog handling
  const actions = useHeroBackgroundsActions(
    // On add success
    () => setShowAddDialog(false),
    // On update success
    () => {
      setShowEditDialog(false);
      setSelectedBackground(null);
    }
  );

  // Get data query
  const { data: backgrounds = [], isLoading, error } = useHeroBackgroundsQuery();

  return {
    // State
    uploadingBackground,
    showAddDialog,
    showEditDialog,
    selectedBackground,
    
    // Query results
    backgrounds,
    isLoading,
    error,
    
    // Actions
    setUploadingBackground,
    setShowAddDialog,
    setShowEditDialog,
    setSelectedBackground,
    addBackground: actions.addBackground,
    updateBackground: actions.updateBackground,
    deleteBackground: actions.deleteBackground,
    handleBackgroundUpload: actions.handleBackgroundUpload,
  };
};

// Re-export types
export * from './types';
