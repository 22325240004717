
import React from 'react';
import { Loader2 } from 'lucide-react';
import LocationDetailLayout from './LocationDetailLayout';

const LocationLoading: React.FC = () => {
  return (
    <LocationDetailLayout>
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center justify-center py-20">
          <Loader2 className="h-12 w-12 text-D00B0E animate-spin mb-4" />
          <h2 className="text-xl font-medium">Loading location information...</h2>
          <p className="text-gray-500 mt-2">Please wait while we retrieve the details for this service area.</p>
        </div>
      </div>
    </LocationDetailLayout>
  );
};

export default LocationLoading;
