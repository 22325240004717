import React from 'react';
import { Badge } from "@/components/ui/badge";
import { CheckCircle, XCircle, Clock, AlertCircle } from 'lucide-react';
import { ReviewStatus } from '@/hooks/gallery-table/types';

interface ReviewStatusBadgeProps {
  status?: ReviewStatus;
}

const ReviewStatusBadge: React.FC<ReviewStatusBadgeProps> = ({ status = 'pending' }) => {
  switch(status) {
    case 'approved':
      return (
        <Badge variant="success" className="flex items-center gap-1">
          <CheckCircle className="h-3 w-3" /> Approved
        </Badge>
      );
    case 'rejected':
      return (
        <Badge variant="destructive" className="flex items-center gap-1">
          <XCircle className="h-3 w-3" /> Rejected
        </Badge>
      );
    case 'pending':
      return (
        <Badge variant="outline" className="flex items-center gap-1">
          <Clock className="h-3 w-3" /> Pending
        </Badge>
      );
    default:
      return (
        <Badge variant="secondary" className="flex items-center gap-1">
          <AlertCircle className="h-3 w-3" /> Unknown
        </Badge>
      );
  }
};

export default ReviewStatusBadge;
