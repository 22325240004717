import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import Sidebar from './Sidebar/Sidebar';
import LoadingSpinner from './LoadingSpinner';
import { toast } from 'sonner';

interface AdminLayoutProps {
  children: React.ReactNode;
  title: string;
}

const AdminLayout = ({ children, title }: AdminLayoutProps) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [bypassAuth, setBypassAuth] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const isDevelopment = import.meta.env.MODE === 'development';
    
    // Check if bypass mode is enabled in localStorage
    const storedBypassValue = localStorage.getItem('bypass_auth');
    const shouldBypass = storedBypassValue !== 'false';
    setBypassAuth(shouldBypass);

    // If auth bypass is enabled for local development only
    if (isDevelopment && shouldBypass) {
      console.log('DEV MODE: Authentication bypassed for local development');
      setAuthenticated(true);
      setLoading(false);
      return;
    }
    
    // For production or when bypass is disabled, use normal authentication
    const checkSession = async () => {
      try {
        const { data, error } = await supabase.auth.getSession();
        
        if (error) {
          console.error('Error checking auth session:', error);
          navigate('/admin/login');
          return;
        }
        
        if (!data.session) {
          console.log('No active session found, redirecting to login');
          navigate('/admin/login');
        } else {
          console.log('Session found, user is authenticated');
          setAuthenticated(true);
        }
      } catch (err) {
        console.error('Unexpected error in auth check:', err);
        navigate('/admin/login');
      } finally {
        setLoading(false);
      }
    };

    checkSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        console.log('Auth state changed:', event);
        if (event === 'SIGNED_OUT') {
          navigate('/admin/login');
        } else if (event === 'SIGNED_IN' && session) {
          setAuthenticated(true);
        }
      }
    );

    return () => {
      if (authListener && authListener.subscription) {
        authListener.subscription.unsubscribe();
      }
    };
  }, [navigate]);

  // Function to toggle auth bypass mode
  const toggleAuthBypass = () => {
    const newValue = !bypassAuth;
    setBypassAuth(newValue);
    localStorage.setItem('bypass_auth', newValue ? 'true' : 'false');
    toast.info(`Authentication bypass ${newValue ? 'enabled' : 'disabled'}. Reloading...`);
    setTimeout(() => window.location.reload(), 1500);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!authenticated) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col md:flex-row w-full overflow-hidden">
      <Sidebar />
      
      <div className="flex-1 flex flex-col overflow-hidden w-full">
        <header className="bg-white shadow-sm p-4 md:mt-0 mt-12 flex justify-between items-center">
          <h1 className="text-xl md:text-2xl font-bold text-[#D00B0E] truncate">{title}</h1>
          
          {import.meta.env.MODE === 'development' && (
            <button 
              onClick={toggleAuthBypass}
              className={`text-xs px-2 py-1 rounded ${bypassAuth ? 'bg-yellow-200 text-yellow-800' : 'bg-green-200 text-green-800'}`}
            >
              {bypassAuth ? 'Auth Bypass ON' : 'Auth Bypass OFF'}
            </button>
          )}
        </header>
        
        <main className="p-4 overflow-auto flex-1">
          <div className="max-w-full">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;