
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchAllReviews, fetchGMBLocations, updateReview, deleteReview, createReview, Review } from '@/lib/supabase';
import { toast } from 'sonner';

export const useAdminReviews = () => {
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState('all');
  const [search, setSearch] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentReview, setCurrentReview] = useState<Partial<Review>>({
    reviewer_name: '',
    star_rating: 'FIVE',
    reviewer_comment: '',
    location_id: '',
    review_id: '',
    google_name: '',
    create_time: new Date().toISOString(),
    update_time: new Date().toISOString(),
  });

  // Fetch reviews and locations
  const { data: reviews, isLoading: reviewsLoading } = useQuery({
    queryKey: ['reviews'],
    queryFn: fetchAllReviews,
    staleTime: 1000 * 60 * 5,
  });

  const { data: gmbLocations, isLoading: locationsLoading } = useQuery({
    queryKey: ['gmbLocations'],
    queryFn: fetchGMBLocations,
    staleTime: 1000 * 60 * 5,
  });

  // Mutations
  const createReviewMutation = useMutation({
    mutationFn: createReview,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reviews'] });
      toast.success('Review added successfully');
      setIsDialogOpen(false);
    },
    onError: (error) => {
      console.error('Error creating review:', error);
      toast.error('Failed to add review');
    },
  });

  const updateReviewMutation = useMutation({
    mutationFn: ({ id, review }: { id: string; review: Partial<Review> }) => 
      updateReview(id, review),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reviews'] });
      toast.success('Review updated successfully');
      setIsDialogOpen(false);
    },
    onError: (error) => {
      console.error('Error updating review:', error);
      toast.error('Failed to update review');
    },
  });

  const deleteReviewMutation = useMutation({
    mutationFn: deleteReview,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reviews'] });
      toast.success('Review deleted successfully');
    },
    onError: (error) => {
      console.error('Error deleting review:', error);
      toast.error('Failed to delete review');
    },
  });

  // Filter reviews based on tab and search term
  const filteredReviews = reviews?.filter(review => {
    const matchesSearch = 
      search === '' || 
      review.reviewer_name.toLowerCase().includes(search.toLowerCase()) ||
      (review.reviewer_comment && review.reviewer_comment.toLowerCase().includes(search.toLowerCase())) ||
      (review.google_name && review.google_name.toLowerCase().includes(search.toLowerCase()));
    
    if (activeTab === 'all') {
      return matchesSearch;
    } else if (activeTab === 'google') {
      return matchesSearch && review.review_id.startsWith('google-');
    } else if (activeTab === 'manual') {
      return matchesSearch && review.review_id.startsWith('manual-');
    }
    
    return false;
  });

  return {
    activeTab,
    setActiveTab,
    search,
    setSearch,
    isDialogOpen,
    setIsDialogOpen,
    isEditMode,
    setIsEditMode,
    currentReview,
    setCurrentReview,
    reviews,
    gmbLocations,
    filteredReviews,
    isLoading: reviewsLoading || locationsLoading,
    createReviewMutation,
    updateReviewMutation,
    deleteReviewMutation,
  };
};
