
import React, { useEffect, useRef, useState } from 'react';
import { ChevronRight } from 'lucide-react';
import { Card, CardContent } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import LazyImage from "@/components/ui/lazy-image";
import { isCloudflareImageUrl } from '@/lib/imageUtils';

// Simple image component specifically for gallery images
const GalleryImage = ({ src, alt }: { src: string, alt: string }) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);

  // Apply CORS for Cloudflare images
  useEffect(() => {
    if (imgRef.current && isCloudflareImageUrl(src)) {
      imgRef.current.crossOrigin = "anonymous";
    }
  }, [src]);

  return (
    <>
      {!loaded && !error && <Skeleton className="absolute inset-0" />}
      <img
        ref={imgRef}
        src={error ? '/placeholder.svg' : src}
        alt={alt}
        className="w-full h-full object-cover"
        style={{ display: 'block' }} // Ensure image is visible
        onLoad={() => setLoaded(true)}
        onError={(e) => {
          console.error(`Gallery image failed to load: ${src}`);
          setError(true);
        }}
      />
    </>
  );
};

interface ImageGridProps {
  images: Array<{
    id: string;
    image_url: string;
    caption?: string;
  }>;
  loading?: boolean;
  onImageClick?: (index: number) => void;
}

const ImageGrid = ({ images, loading, onImageClick }: ImageGridProps) => {
  if (loading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {[...Array(6)].map((_, i) => (
          <Card key={i} className="h-[300px] overflow-hidden">
            <Skeleton className="h-full w-full" />
          </Card>
        ))}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {images.map((image, index) => (
        <Card 
          key={image.id}
          className="overflow-hidden h-[300px] w-full hover:shadow-md transition-shadow"
        >
          <div className="relative h-[230px] overflow-hidden">
            <GalleryImage 
              src={image.image_url}
              alt={image.caption || 'Gallery image'}
            />
            {image.caption && (
              <div className="absolute bottom-0 left-0 right-0 bg-black/60 text-white p-2 text-sm">
                {image.caption}
              </div>
            )}
          </div>
          <CardContent className="p-3 flex justify-end bg-white border-t">
            <Button 
              variant="ghost" 
              size="sm" 
              className="text-D00B0E hover:bg-D00B0E/10 hover:text-D00B0E"
              onClick={() => onImageClick?.(index)}
            >
              View <ChevronRight className="h-4 w-4 ml-1" />
            </Button>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ImageGrid;
