
import React from 'react';
import { Button } from '@/components/ui/button';
import { Loader2 as LoadingCircle, RotateCcw } from 'lucide-react';

interface DemoActionsProps {
  loading: boolean;
  error: boolean;
  onSimulateLoading: () => void;
  onSimulateError: () => void;
  onRetry: () => void;
}

const DemoActions: React.FC<DemoActionsProps> = ({
  loading,
  error,
  onSimulateLoading,
  onSimulateError,
  onRetry
}) => {
  return (
    <div className="flex gap-2">
      <Button 
        variant="outline" 
        size="sm" 
        onClick={onSimulateLoading}
        disabled={loading}
      >
        {loading ? (
          <>
            <LoadingCircle className="h-4 w-4 mr-1 animate-spin" /> 
            Loading...
          </>
        ) : (
          "Simulate Loading"
        )}
      </Button>
      <Button 
        variant="outline" 
        size="sm" 
        onClick={onSimulateError}
        disabled={loading}
      >
        Simulate Error
      </Button>
      {error && (
        <Button 
          variant="default" 
          size="sm" 
          onClick={onRetry}
        >
          <RotateCcw className="h-4 w-4 mr-1" />
          Retry
        </Button>
      )}
    </div>
  );
};

export default DemoActions;
