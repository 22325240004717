
import React, { useState } from 'react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Loader2 } from 'lucide-react';
import { 
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogClose
} from "@/components/ui/dialog";
import { UploadDialogProps } from './types';

const PhotoUploadDialog = ({ open, onOpenChange, uploading, onUpload }: UploadDialogProps) => {
  const [caption, setCaption] = useState("");

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const file = e.target.files[0];
    const fileSize = file.size / 1024 / 1024; // size in MB

    if (fileSize > 5) {
      alert("File size exceeds 5MB limit");
      return;
    }

    await onUpload(file);
    setCaption("");
    onOpenChange(false);
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Upload Photo</DialogTitle>
      </DialogHeader>
      <div className="space-y-4 py-4">
        <div className="space-y-2">
          <label htmlFor="caption" className="text-sm font-medium">
            Caption (optional)
          </label>
          <Input
            id="caption"
            placeholder="Enter photo caption"
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
          />
        </div>
        <div className="space-y-2">
          <label htmlFor="photo-upload" className="text-sm font-medium">
            Photo
          </label>
          <div className="flex items-center justify-center">
            <label
              htmlFor="photo-upload"
              className="cursor-pointer bg-background border border-dashed border-input rounded-md p-6 flex flex-col items-center justify-center w-full"
            >
              <div className="flex flex-col items-center justify-center">
                <svg
                  className="mx-auto h-12 w-12 text-muted-foreground"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-muted-foreground">
                  <span className="relative rounded-md font-medium text-primary focus-within:outline-none">
                    Upload a file
                  </span>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-muted-foreground">
                  PNG, JPG up to 5MB
                </p>
              </div>
              <input
                id="photo-upload"
                name="photo-upload"
                type="file"
                className="sr-only"
                accept="image/png, image/jpeg"
                onChange={handleFileChange}
                disabled={uploading}
              />
            </label>
          </div>
        </div>
        {uploading && (
          <div className="flex items-center justify-center">
            <Loader2 className="h-6 w-6 animate-spin text-primary" />
            <span className="ml-2">Uploading...</span>
          </div>
        )}
      </div>
      <DialogClose asChild>
        <Button variant="outline">Cancel</Button>
      </DialogClose>
    </DialogContent>
  );
};

export default PhotoUploadDialog;
