import React, { useRef, useEffect } from 'react';
import {
  FormHeader,
  PersonalInfoFields,
  ServiceSelectionFields,
  MessageField,
  FileUploadField,
  SubmitButton,
  SubmissionStatus,
  useContactForm,
  ContactFormProps,
} from './';
import { useFormTracking } from '@/lib/tracking/hooks/useFormTracking';

const ContactRequestForm: React.FC<ContactFormProps> = (props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const formDataRef = useRef<Record<string, any>>({});

  // Initialize form tracking
  const formId = props.formId || 'contact-form';
  const formName = props.formName || 'Contact Request Form';
  const { trackFormStart, trackFormSubmit, trackFormError } = useFormTracking({
    formId,
    formName,
    serviceType: props.defaultService,
    locationName: props.locationName,
  });

  const {
    isSubmitting,
    submissionState,
    submissionError,
    leadId,
    clientName,
    files,
    selectedService,
    otherService,
    showOtherServiceInput,
    predefinedServices,
    handleServiceChange,
    handleOtherServiceChange,
    handleFilesChange,
    removeFile,
    handleSubmit,
    retrySubmission,
    resetForm,
  } = useContactForm(props);

  // Track form start when user interacts with any field
  useEffect(() => {
    const form = formRef.current;
    if (!form) return;

    const handleInteraction = () => {
      trackFormStart({
        service: selectedService,
        location: props.locationName || 'unknown',
        page_url: window.location.href,
      });
      // Remove listener after first interaction
      form.removeEventListener('input', handleInteraction);
    };

    form.addEventListener('input', handleInteraction);
    return () => form.removeEventListener('input', handleInteraction);
  }, [trackFormStart, selectedService, props.locationName]);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Store form data for retry
    const formElement = e.currentTarget;
    const formData = new FormData(formElement);

    const finalService = selectedService
      ? showOtherServiceInput
        ? otherService
        : selectedService
      : null;

    formDataRef.current = {
      name: formData.get('name')?.toString() || '',
      email: formData.get('email')?.toString() || '',
      phone: formData.get('phone')?.toString() || '',
      address: formData.get('address')?.toString() || '',
      message: formData.get('message')?.toString() || '',
      service: finalService,
      service_slug: props.serviceSlug || null,
      location: props.locationName || null,
      submission_url: window.location.href,
      form_id: formId,
    };

    try {
      // Track form submission
      trackFormSubmit({
        form_name: formName,
        service: finalService,
        location: props.locationName || 'unknown',
        page_url: window.location.href,
      });

      await handleSubmit(e);
    } catch (error) {
      // Track form error
      trackFormError(error instanceof Error ? error.message : 'Unknown error', {
        service: finalService,
        location: props.locationName || 'unknown',
        page_url: window.location.href,
      });
      throw error;
    }
  };

  const handleRetry = () => {
    if (formRef.current) {
      // Track retry attempt
      trackFormSubmit({
        form_name: formName,
        service: formDataRef.current.service,
        location: props.locationName || 'unknown',
        page_url: window.location.href,
        is_retry: true,
      });

      retrySubmission(formRef.current, formDataRef.current);
    }
  };

  // Show submission status if not idle
  if (submissionState !== 'idle') {
    return (
      <div className='bg-white text-gray-900 rounded-lg shadow-xl overflow-hidden'>
        <FormHeader />
        <div className='p-6'>
          <SubmissionStatus
            state={submissionState as 'submitting' | 'success' | 'error'}
            clientName={clientName}
            leadId={leadId}
            error={submissionError}
            onRetry={handleRetry}
            onReset={resetForm}
          />
        </div>
      </div>
    );
  }

  return (
    <div className='bg-white text-gray-900 rounded-lg shadow-xl overflow-hidden'>
      <FormHeader />
      <form ref={formRef} onSubmit={handleFormSubmit} className='p-6 space-y-4'>
        <PersonalInfoFields />

        <ServiceSelectionFields
          selectedService={selectedService}
          onServiceChange={handleServiceChange}
          showOtherServiceInput={showOtherServiceInput}
          otherService={otherService}
          onOtherServiceChange={handleOtherServiceChange}
          predefinedServices={predefinedServices}
        />

        <MessageField />

        <FileUploadField
          files={files}
          onFilesChange={handleFilesChange}
          onRemoveFile={removeFile}
        />

        <SubmitButton isSubmitting={isSubmitting} />
      </form>
    </div>
  );
};

export default ContactRequestForm;
