
import React, { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { LogOut } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const LogoutButton = () => {
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isDevelopment, setIsDevelopment] = useState(false);
  const [bypassAuth, setBypassAuth] = useState(true);

  // Check if we're in development mode and if auth is bypassed
  useEffect(() => {
    setIsDevelopment(import.meta.env.MODE === 'development');
    const storedBypassValue = localStorage.getItem('bypass_auth');
    setBypassAuth(storedBypassValue !== 'false');
  }, []);

  const handleLogout = async () => {
    setIsLoggingOut(true);
    
    try {
      // If in development mode with auth bypass enabled, just redirect to login
      if (isDevelopment && bypassAuth) {
        console.log('Development mode with auth bypass detected, bypassing Supabase logout');
        toast.success('Logged out successfully (Development Mode)');
        navigate('/admin/login');
        return;
      }
      
      // For production or dev with auth testing, proceed with normal logout flow
      // Check if we have a session first
      const { data: sessionData } = await supabase.auth.getSession();
      
      // If no session, just redirect to login
      if (!sessionData?.session) {
        console.log('No active session found, redirecting to login');
        toast.info('Your session has expired. Please login again.');
        navigate('/admin/login');
        return;
      }
      
      // Try to sign out properly
      const { error } = await supabase.auth.signOut();
      
      if (error) {
        console.error('Logout error:', error);
        
        // If we get an AuthSessionMissingError, the session is already invalid
        // so we can just redirect to login
        if (error.message?.includes('Auth session missing')) {
          toast.info('Your session has expired. Redirecting to login.');
          navigate('/admin/login');
          return;
        }
        
        toast.error('Failed to log out. Please try again.');
      } else {
        toast.success('Successfully logged out');
        navigate('/admin/login');
      }
    } catch (err) {
      console.error('Unexpected error during logout:', err);
      toast.error('An unexpected error occurred during logout');
      
      // Force redirect to login page even on error
      navigate('/admin/login');
    } finally {
      setIsLoggingOut(false);
    }
  };

  return (
    <div className="absolute bottom-0 w-64 p-4 border-t">
      <Button 
        variant="ghost" 
        className="w-full justify-start text-[#D00B0E] hover:text-white hover:bg-[#D00B0E]"
        onClick={handleLogout}
        disabled={isLoggingOut}
      >
        <LogOut className="mr-2 h-4 w-4" />
        {isLoggingOut ? 'Logging out...' : 'Logout'}
      </Button>
    </div>
  );
};

export default LogoutButton;
