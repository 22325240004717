
import { useState, useEffect } from 'react';
import { toast } from 'sonner';
import { submitContactFormWithEdgeFunction } from '@/lib/supabase/contactForm';
import { trackFormSubmissionServerSide } from '@/lib/supabase/tracking';
import { useTracking } from '@/lib/tracking/useTracking';

export interface ContactFormProps {
  defaultService?: string;
  serviceSlug?: string;
  locationName?: string;
  className?: string;
  trackingParams?: Record<string, string>;
  formId?: string;
  formName?: string;
}

// Form submission states
type SubmissionState = 'idle' | 'submitting' | 'success' | 'error';

interface FormSubmissionResponse {
  success: boolean;
  data?: {
    id: string;
  };
  error?: string;
}

export const useContactForm = (props: ContactFormProps) => {
  const {
    defaultService = '',
    serviceSlug = '',
    locationName = '',
    className = '',
    trackingParams = {},
    formId = 'contact-form',
    formName = 'Contact Request Form',
  } = props;

  // Initialize tracking
  const { trackEvent } = useTracking();

  // State for form data
  const [submissionState, setSubmissionState] =
    useState<SubmissionState>('idle');
  const [files, setFiles] = useState<File[]>([]);
  const [selectedService, setSelectedService] = useState(defaultService);
  const [otherService, setOtherService] = useState('');
  const [showOtherServiceInput, setShowOtherServiceInput] = useState(false);
  const [submissionError, setSubmissionError] = useState<string | null>(null);
  const [leadId, setLeadId] = useState<string | null>(null);
  const [clientName, setClientName] = useState<string>('');

  // URL tracking
  const [submissionSource, setSubmissionSource] = useState('');

  // Predefined services list
  const predefinedServices = [
    { value: 'brick-repair', label: 'Brick Repair' },
    { value: 'tuckpointing', label: 'Tuckpointing' },
    { value: 'chimney-repair', label: 'Chimney Repair' },
    { value: 'stone-masonry', label: 'Stone Masonry' },
    { value: 'concrete-work', label: 'Concrete Work' },
    { value: 'masonry-restoration', label: 'Masonry Restoration' },
    { value: 'other', label: 'Other (Please specify)' },
  ];

  // Set the submission source on component mount
  useEffect(() => {
    setSubmissionSource(window.location.href);
  }, []);

  // Initialize selected service based on default
  useEffect(() => {
    if (defaultService) {
      setSelectedService(defaultService);
      // Check if default service is "other"
      const isOther = !predefinedServices.some(
        (s) => s.value === defaultService.toLowerCase().replace(/\s+/g, '-')
      );

      if (isOther) {
        setShowOtherServiceInput(true);
        setOtherService(defaultService);
      }
    }
  }, [defaultService]);

  const handleServiceChange = (value: string) => {
    setSelectedService(value);
    setShowOtherServiceInput(value === 'other');

    // Track service selection
    trackEvent({
      name: 'service_selected',
      props: {
        service: value,
        form_id: formId,
        form_name: formName,
        location: locationName,
        page_url: window.location.href,
      },
    });
  };

  const handleOtherServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setOtherService(value);

    // Track other service input
    if (value.length > 2) {
      trackEvent({
        name: 'other_service_input',
        props: {
          service: value,
          form_id: formId,
          form_name: formName,
          location: locationName,
          page_url: window.location.href,
        },
      });
    }
  };

  const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);

      // Check file size limit (15MB per file)
      const invalidFiles = newFiles.filter(
        (file) => file.size > 15 * 1024 * 1024
      );
      if (invalidFiles.length > 0) {
        toast.error('One or more files exceed the 15MB size limit');
        return;
      }

      // Add new files to existing files (limit to max 5 files)
      const updatedFiles = [...files, ...newFiles].slice(0, 5);
      setFiles(updatedFiles);

      // Track file upload
      trackEvent({
        name: 'files_added',
        props: {
          file_count: newFiles.length,
          total_files: updatedFiles.length,
          form_id: formId,
          form_name: formName,
          location: locationName,
          page_url: window.location.href,
        },
      });
    }
  };

  const removeFile = (index: number) => {
    setFiles(files.filter((_, i) => i !== index));

    // Track file removal
    trackEvent({
      name: 'file_removed',
      props: {
        remaining_files: files.length - 1,
        form_id: formId,
        form_name: formName,
        location: locationName,
        page_url: window.location.href,
      },
    });
  };

  const resetForm = () => {
    setFiles([]);
    setSelectedService('');
    setOtherService('');
    setShowOtherServiceInput(false);
    setSubmissionState('idle');
    setSubmissionError(null);
    setLeadId(null);

    // Track form reset
    trackEvent({
      name: 'form_reset',
      props: {
        form_id: formId,
        form_name: formName,
        location: locationName,
        page_url: window.location.href,
      },
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmissionState('submitting');

    const formElement = e.currentTarget;
    const formData = new FormData(formElement);

    // Save client name for success message
    const nameValue = formData.get('name')?.toString() || '';
    setClientName(nameValue);

    // Determine the final service value (either selected or other)
    const finalService = selectedService
      ? showOtherServiceInput
        ? otherService
        : selectedService
      : null;

    // Prepare data object for the edge function
    const formDataObj = {
      name: nameValue,
      email: formData.get('email')?.toString() || '',
      phone: cleanPhoneNumber(formData.get('phone')?.toString() || ''),
      address: formData.get('address')?.toString() || '',
      message: formData.get('message')?.toString() || '',
      service: finalService,
      service_slug: serviceSlug || null,
      location: locationName || null,
      submission_url: submissionSource,
      form_id: formId,
      form_name: formName,
      submission_status: 'completed',
      // Add tracking parameters
      ...trackingParams,
    };

    try {
      console.log('Submitting form with files:', files.length);
      
      // Submit the form with files attached
      const response = await submitContactFormWithEdgeFunction(
        formDataObj,
        files
      );

      if (!response.success || response.error) {
        throw new Error(response.error || 'Form submission failed');
      }

      // Track successful submission server-side
      await trackFormSubmissionServerSide(formId, formName, {
        ...formDataObj,
        lead_id: response.leadId,
        status: 'success',
      });

      setLeadId(response.leadId || null);
      setSubmissionState('success');

      toast.success("Message sent! We'll get back to you as soon as possible.");

      // Reset form after successful submission
      resetForm();
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmissionError(
        error instanceof Error ? error.message : 'Unknown error'
      );
      setSubmissionState('error');

      // Track error server-side
      await trackFormSubmissionServerSide(formId, formName, {
        ...formDataObj,
        status: 'error',
        error: error instanceof Error ? error.message : 'Unknown error',
      });

      toast.error(
        'Something went wrong. Please try again or call us directly.'
      );
    }
  };

  const retrySubmission = async (
    formElement: HTMLFormElement,
    savedData: Record<string, any>
  ) => {
    setSubmissionState('submitting');

    try {
      const response = await submitContactFormWithEdgeFunction(
        savedData,
        files
      );

      if (!response.success || response.error) {
        throw new Error(response.error || 'Form submission failed');
      }

      // Track successful retry server-side
      await trackFormSubmissionServerSide(formId, formName, {
        ...savedData,
        lead_id: response.leadId,
        status: 'success',
        is_retry: true,
      });

      setLeadId(response.leadId || null);
      setSubmissionState('success');

      toast.success("Message sent! We'll get back to you as soon as possible.");
    } catch (error) {
      console.error('Error retrying submission:', error);
      setSubmissionError(
        error instanceof Error ? error.message : 'Unknown error'
      );
      setSubmissionState('error');

      // Track retry error server-side
      await trackFormSubmissionServerSide(formId, formName, {
        ...savedData,
        status: 'error',
        error: error instanceof Error ? error.message : 'Unknown error',
        is_retry: true,
      });

      toast.error(
        'Something went wrong. Please try again or call us directly.'
      );
    }
  };

  return {
    isSubmitting: submissionState === 'submitting',
    submissionState,
    submissionError,
    leadId,
    clientName,
    files,
    selectedService,
    otherService,
    showOtherServiceInput,
    predefinedServices,
    handleServiceChange,
    handleOtherServiceChange,
    handleFilesChange,
    removeFile,
    handleSubmit,
    retrySubmission,
    resetForm,
    className,
  };
};

const cleanPhoneNumber = (phone: string): string => {
  return phone.replace(/\D/g, '');
};
