
import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';

interface BlogPost {
  id: string;
  title: string;
  slug: string;
  excerpt?: string;
  published_at?: string;
  featured_image?: string;
  blog_categories?: Array<{
    id: string;
    name: string;
    slug: string;
  }>;
}

interface BlogCategory {
  id: string;
  name: string;
  slug: string;
  post_count?: number;
}

interface UseBlogDataReturn {
  posts: BlogPost[];
  categories: BlogCategory[];
  loading: boolean;
  error: string | null;
}

export const useBlogData = (): UseBlogDataReturn => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [categories, setCategories] = useState<BlogCategory[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        setLoading(true);
        
        // Fetch categories with post counts
        const { data: categoriesWithCounts, error: categoriesError } = await supabase
          .from('blog_categories')
          .select(`
            id,
            name,
            slug,
            blog_post_categories!inner(post_id)
          `);
          
        if (categoriesError) throw categoriesError;
        
        // Transform categories data to include post counts
        const transformedCategories = categoriesWithCounts?.map(category => ({
          id: category.id,
          name: category.name,
          slug: category.slug,
          post_count: category.blog_post_categories?.length || 0
        })) || [];
        
        // Fetch blog posts
        const { data: postsData, error: postsError } = await supabase
          .from('blog_posts')
          .select(`
            id,
            title,
            slug,
            excerpt,
            published_at,
            featured_image,
            blog_categories(id, name, slug)
          `)
          .eq('status', 'published')
          .order('published_at', { ascending: false });
          
        if (postsError) throw postsError;
        
        setPosts(postsData || []);
        setCategories(transformedCategories);
        
      } catch (err) {
        console.error('Error fetching blog data:', err);
        setError('Failed to load blog content. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchBlogData();
  }, []);

  return { posts, categories, loading, error };
};
