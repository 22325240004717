import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { Edit, Eye, CheckCircle, Clock, AlertTriangle } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Badge } from '@/components/ui/badge';
import { Location } from '@/types/location';
import { getStatusIcon, updateLocationStatus } from './utils/locationStatus';

interface LocationsDesktopTableProps {
  locations: Location[];
  handleEdit: (id: string | number) => void;
  handleView: (slug: string) => void;
  setLocations: React.Dispatch<React.SetStateAction<Location[]>>;
}

const LocationsDesktopTable: React.FC<LocationsDesktopTableProps> = ({
  locations,
  handleEdit,
  handleView,
  setLocations,
}) => {
  const handleStatusChange = async (id: string | number, newStatus: string) => {
    const updatedLocations = await updateLocationStatus(
      id,
      newStatus,
      locations
    );
    setLocations(updatedLocations);
  };

  // Render a styled status badge
  const renderStatusBadge = (status: string) => {
    if (status === 'published') {
      return (
        <Badge className='bg-green-100 text-green-800 hover:bg-green-200'>
          <CheckCircle className='h-3 w-3 mr-1' /> Published
        </Badge>
      );
    } else if (status === 'draft') {
      return (
        <Badge className='bg-yellow-100 text-yellow-800 hover:bg-yellow-200'>
          <Clock className='h-3 w-3 mr-1' /> Draft
        </Badge>
      );
    } else {
      return (
        <Badge className='bg-red-100 text-red-800 hover:bg-red-200'>
          <AlertTriangle className='h-3 w-3 mr-1' /> {status}
        </Badge>
      );
    }
  };

  // Render a location type badge
  const renderTypeBadge = (isPrimary: boolean) => {
    return isPrimary ? (
      <Badge className='bg-blue-100 text-blue-800 hover:bg-blue-100'>
        Primary
      </Badge>
    ) : (
      <Badge className='bg-gray-100 text-gray-800 hover:bg-gray-100'>
        Secondary
      </Badge>
    );
  };

  return (
    <div className='hidden md:block'>
      <div className='rounded-md border bg-background'>
        <Table>
          <TableHeader>
            <TableRow className="bg-muted/30">
              <TableHead className='font-semibold'>Name</TableHead>
              <TableHead className='font-semibold'>Slug</TableHead>
              <TableHead className='font-semibold'>Type</TableHead>
              <TableHead className='font-semibold'>Status</TableHead>
              <TableHead className='text-right font-semibold'>
                Actions
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {locations.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={5}
                  className='text-center py-10 text-muted-foreground'>
                  No locations found. Create your first location to get started.
                </TableCell>
              </TableRow>
            ) : (
              locations.map((location) => (
                <TableRow key={location.id} className='hover:bg-muted/30'>
                  <TableCell className='font-medium'>
                    <div className='flex items-center'>
                      {getStatusIcon(location.status)}
                      <span className='ml-2'>{location.name}</span>
                    </div>
                  </TableCell>
                  <TableCell className='text-muted-foreground'>
                    /{location.slug}
                  </TableCell>
                  <TableCell>{renderTypeBadge(location.is_primary)}</TableCell>
                  <TableCell>
                    <Select
                      value={location.status || 'published'}
                      onValueChange={(value) =>
                        handleStatusChange(location.id, value)
                      }>
                      <SelectTrigger className='h-9 w-[130px]'>
                        <SelectValue placeholder='Status' />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value='published'>Published</SelectItem>
                        <SelectItem value='draft'>Draft</SelectItem>
                        <SelectItem value='archived'>Archived</SelectItem>
                      </SelectContent>
                    </Select>
                  </TableCell>
                  <TableCell className='text-right'>
                    <div className='flex justify-end space-x-1'>
                      <Button
                        variant='ghost'
                        size='sm'
                        onClick={() => handleView(location.slug)}
                        title='View location'
                        className='h-8 w-8 p-0'>
                        <Eye className='h-4 w-4' />
                      </Button>
                      <Button
                        variant='ghost'
                        size='sm'
                        onClick={() => handleEdit(location.id)}
                        title='Edit location'
                        className='h-8 w-8 p-0'>
                        <Edit className='h-4 w-4' />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default LocationsDesktopTable;
