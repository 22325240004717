import React, { useEffect } from 'react';
import { Skeleton } from '@/components/ui/skeleton';
import { ReviewStatus } from '@/hooks/gallery-table/types';
import LazyImage from '@/components/ui/lazy-image';
import { Button } from '@/components/ui/button';
import {
  Plus,
  Check,
  X,
  Image as ImageIcon,
  ChevronLeft,
  ChevronRight,
} from 'lucide-react';

interface FranchiseImage {
  id: string;
  image_url: string;
  review_status: ReviewStatus;
}

interface SocialMediaImageGridProps {
  images: FranchiseImage[];
  loading: boolean;
  coverImage?: string;
  onAddToGallery?: (imageUrl: string) => void;
  onSetCoverImage?: (imageUrl: string) => void;
  onApprove: (id: string) => void;
  onReject: (id: string) => void;
  approving: { [key: string]: boolean };
  rejecting: { [key: string]: boolean };
  activeImageIndex: number;
  setActiveImageIndex: (index: number) => void;
  onPrevImage: () => void;
  onNextImage: () => void;
  onImageError?: (imageId: string) => void;
  imageErrors?: Record<string, boolean>;
}

const SocialMediaImageGrid = ({
  images,
  loading,
  coverImage,
  onAddToGallery,
  onSetCoverImage,
  onApprove,
  onReject,
  approving,
  rejecting,
  activeImageIndex,
  setActiveImageIndex,
  onPrevImage,
  onNextImage,
  onImageError,
  imageErrors = {},
}: SocialMediaImageGridProps) => {
  // Log all image URLs on component mount
  useEffect(() => {
    if (images.length > 0) {
      console.log('Image URLs in SocialMediaImageGrid:');
      images.forEach((img, i) => {
        console.log(`Grid Image ${i} URL:`, img.image_url);
      });
    }
  }, [images]);

  if (loading) {
    return (
      <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
        {[...Array(8)].map((_, i) => (
          <div key={i} className='aspect-square'>
            <Skeleton className='h-full w-full' />
          </div>
        ))}
      </div>
    );
  }

  if (images.length === 0) {
    return (
      <div className='text-center py-8 text-muted-foreground'>
        No images found for this job.
      </div>
    );
  }

  // Sort images, putting cover image first if it exists
  const sortedImages = [...images].sort((a, b) => {
    if (a.image_url === coverImage) return -1;
    if (b.image_url === coverImage) return 1;
    return 0;
  });

  const activeImage = sortedImages[activeImageIndex];

  const getStatusBadgeClass = (status: ReviewStatus | undefined) => {
    if (!status) return 'bg-yellow-500';

    switch (status) {
      case 'approved':
        return 'bg-green-500';
      case 'rejected':
        return 'bg-red-500';
      default:
        return 'bg-yellow-500';
    }
  };

  // Handle image error for specific image
  const handleImageError = (imageId: string) => {
    console.error(`Image failed to load in grid: ${imageId}`);
    if (onImageError) {
      onImageError(imageId);
    }
  };

  return (
    <div className='space-y-6'>
      {/* Main featured image with navigation */}
      <div className='relative border-2 rounded-lg overflow-hidden aspect-video bg-gray-100'>
        {/* Feature image */}
        {activeImage && (
          <div className='w-full h-full'>
            {/* Show direct img element for debugging */}
            <img
              src={activeImage.image_url}
              alt='Franchise image'
              className='w-full h-full object-contain'
              style={{
                display: imageErrors[activeImage.id] ? 'none' : 'block',
              }}
              onError={() => handleImageError(activeImage.id)}
            />

            {/* Status badge */}
            <div className='absolute top-3 left-3'>
              <span
                className={`${getStatusBadgeClass(
                  activeImage.review_status
                )} text-white px-2 py-1 rounded text-sm`}>
                {(activeImage.review_status &&
                  activeImage.review_status.charAt(0).toUpperCase() +
                    activeImage.review_status.slice(1)) ||
                  'Pending'}
              </span>
            </div>

            {/* Cover image badge */}
            {activeImage.image_url === coverImage && (
              <div className='absolute top-3 right-3'>
                <span className='bg-blue-500 text-white text-sm px-2 py-1 rounded flex items-center'>
                  <ImageIcon className='h-3 w-3 mr-1' />
                  Cover
                </span>
              </div>
            )}

            {/* Debug URL */}
            <div className='absolute bottom-3 left-3 right-3 bg-black/60 text-white text-xs p-2 overflow-hidden'>
              URL: {activeImage.image_url.substring(0, 50)}... (ID:{' '}
              {activeImage.id})
            </div>

            {/* Navigation arrows */}
            <button
              className='absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2 hover:bg-opacity-70'
              onClick={onPrevImage}
              aria-label='Previous image'>
              <ChevronLeft className='h-6 w-6' />
            </button>

            <button
              className='absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2 hover:bg-opacity-70'
              onClick={onNextImage}
              aria-label='Next image'>
              <ChevronRight className='h-6 w-6' />
            </button>
          </div>
        )}
      </div>

      {/* Action buttons for the active image */}
      {activeImage && (
        <div className='flex flex-wrap gap-2 justify-center md:justify-start'>
          {onAddToGallery && (
            <Button
              onClick={() => onAddToGallery(activeImage.image_url)}
              variant='secondary'
              size='sm'
              className='font-medium'>
              <Plus className='h-4 w-4 mr-1' />
              Add to Gallery
            </Button>
          )}

          {onSetCoverImage && (
            <Button
              onClick={() => onSetCoverImage(activeImage.image_url)}
              variant={
                activeImage.image_url === coverImage ? 'default' : 'outline'
              }
              size='sm'
              className='font-medium'>
              <ImageIcon className='h-4 w-4 mr-1' />
              {activeImage.image_url === coverImage
                ? 'Current Cover'
                : 'Set as Cover'}
            </Button>
          )}

          <Button
            onClick={() => onApprove(activeImage.id)}
            variant='outline'
            size='sm'
            className='bg-green-100 hover:bg-green-200 text-green-800 font-medium'
            disabled={
              approving[activeImage.id] ||
              activeImage.review_status === 'approved'
            }>
            <Check className='h-4 w-4 mr-1' />
            Approve
          </Button>

          <Button
            onClick={() => onReject(activeImage.id)}
            variant='outline'
            size='sm'
            className='bg-red-100 hover:bg-red-200 text-red-800 font-medium'
            disabled={
              rejecting[activeImage.id] ||
              activeImage.review_status === 'rejected'
            }>
            <X className='h-4 w-4 mr-1' />
            Reject
          </Button>
        </div>
      )}

      {/* Thumbnail grid */}
      <div className='grid grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-2'>
        {sortedImages.map((image, index) => (
          <div
            key={image.id}
            className={`relative aspect-square border-2 rounded-md overflow-hidden cursor-pointer transition-all duration-200 ${
              index === activeImageIndex
                ? 'border-D00B0E ring-2 ring-D00B0E'
                : `border-gray-200 hover:border-gray-400 ${
                    image.review_status === 'approved'
                      ? 'border-green-300'
                      : image.review_status === 'rejected'
                      ? 'border-red-300'
                      : ''
                  }`
            }`}
            onClick={() => setActiveImageIndex(index)}>
            {/* Use direct img instead of LazyImage for debugging */}
            <img
              src={image.image_url}
              alt='Thumbnail'
              className='w-full h-full object-cover'
              onError={() => handleImageError(image.id)}
            />
            <div
              className={`absolute inset-0 ${
                index === activeImageIndex ? 'bg-black bg-opacity-10' : ''
              }`}></div>

            {/* Small status indicator */}
            <div
              className={`absolute top-1 right-1 w-2 h-2 rounded-full ${
                image.review_status === 'approved'
                  ? 'bg-green-500'
                  : image.review_status === 'rejected'
                  ? 'bg-red-500'
                  : 'bg-yellow-500'
              }`}></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SocialMediaImageGrid;
