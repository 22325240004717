
import React, { useState } from 'react';
import { SiteSettings } from '@/types/settings';
import { toast } from 'sonner';
import { useHeroBackgrounds } from '@/hooks/hero-backgrounds';
import { useSettings } from '@/hooks/useSettings';
import { HeroBackground } from '@/types/heroBackground';
import {
  CurrentBackgroundPreview,
  BackgroundGallery,
  BackgroundActions,
  AddBackgroundDialog
} from './hero-background';

interface HeroBackgroundTabProps {
  settings: SiteSettings;
  saving: boolean;
  handleChange: (key: string, value: string) => void;
}

const HeroBackgroundTab: React.FC<HeroBackgroundTabProps> = ({
  settings,
  saving,
  handleChange,
}) => {
  // Get hero backgrounds from the hook
  const {
    backgrounds,
    isLoading,
    uploadingBackground,
    addBackground,
    deleteBackground,
    handleBackgroundUpload,
    showAddDialog,
    setShowAddDialog,
  } = useHeroBackgrounds();

  // State for background form
  const [newBackgroundName, setNewBackgroundName] = useState('');
  const [newBackgroundFile, setNewBackgroundFile] = useState<File | null>(null);
  const [uploadActive, setUploadActive] = useState(false);

  // Import updateSettings from useSettings hook
  const { updateSettings } = useSettings();

  // Handle direct setting update via file upload
  const handleDirectUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      setUploadActive(true);
      const uploadedUrl = await handleBackgroundUpload(file);
      if (uploadedUrl) {
        // Update settings with new hero background URL
        handleChange('home_hero_image', uploadedUrl);

        // Save to database immediately for the hero section to pick up the change
        await updateSettings({
          ...settings,
          home_hero_image: uploadedUrl,
        });

        toast.success('Hero background uploaded and applied successfully');
      }
    } catch (error) {
      console.error('Error uploading hero background:', error);
      toast.error('Failed to upload hero background. Please try again.');
    } finally {
      setUploadActive(false);
    }
  };

  // Handle new background form submission
  const handleAddBackgroundSubmit = async (name: string, file: File | null) => {
    if (!name.trim()) {
      toast.error('Please provide a name for the background');
      return;
    }

    if (!file) {
      toast.error('Please select an image file');
      return;
    }

    await addBackground(name, file);
  };

  // Handle using a background from the gallery
  const handleUseBackground = async (bg: HeroBackground) => {
    handleChange('home_hero_image', bg.url);

    // Save to database immediately for changes to take effect
    await updateSettings({
      ...settings,
      home_hero_image: bg.url,
    });
  };

  // Handle deleting a background
  const handleDeleteBackground = async (bg: HeroBackground) => {
    await deleteBackground(bg.id);
    
    // If this was the active background, clear it
    if (settings.home_hero_image === bg.url) {
      handleChange('home_hero_image', '');
    }
  };

  // Handle clearing the active background
  const handleClearBackground = async () => {
    handleChange('home_hero_image', '');

    // Save to database immediately for changes to take effect
    await updateSettings({
      ...settings,
      home_hero_image: '',
    });

    toast.success('Hero background cleared');
  };

  return (
    <div className='space-y-6'>
      <div>
        <h3 className='text-lg font-semibold mb-4'>
          Home Page Hero Background
        </h3>
        <p className='text-sm text-gray-500 mb-4'>
          Select a background image for the home page hero section or upload
          your own.
        </p>
      </div>

      {/* Current background preview */}
      <CurrentBackgroundPreview imageUrl={settings.home_hero_image} />

      {/* Background gallery */}
      <BackgroundGallery
        backgrounds={backgrounds}
        isLoading={isLoading}
        activeBackgroundUrl={settings.home_hero_image}
        onAddClick={() => setShowAddDialog(true)}
        onUseBackground={handleUseBackground}
        onDeleteBackground={handleDeleteBackground}
      />

      {/* Action panel */}
      <BackgroundActions
        hasActiveBackground={!!settings.home_hero_image}
        isUploading={uploadActive}
        isSaving={saving}
        onUpload={() => document.getElementById('custom-hero-bg')?.click()}
        onAddToLibrary={() => setShowAddDialog(true)}
        onClearBackground={handleClearBackground}
      />

      {/* Hidden file input for direct upload */}
      <input
        id='custom-hero-bg'
        type='file'
        className='sr-only'
        accept='image/*'
        onChange={handleDirectUpload}
        disabled={saving || uploadActive}
      />

      {/* Add new background dialog */}
      <AddBackgroundDialog
        open={showAddDialog}
        onOpenChange={setShowAddDialog}
        onAdd={handleAddBackgroundSubmit}
        isUploading={uploadingBackground}
      />
    </div>
  );
};

export default HeroBackgroundTab;
