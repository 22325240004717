
import React from 'react';
import { MessageSquare, ArrowUp } from 'lucide-react';
import { useModal } from './modal/ModalContext';

interface MobileFloatingBarProps {
  serviceTitle?: string;
}

const MobileFloatingBar: React.FC<MobileFloatingBarProps> = ({
  serviceTitle = 'our services',
}) => {
  const { openContactModal } = useModal();

  const handleBarClick = () => {
    openContactModal();
  };

  return (
    <div
      id='mobile-floating-bar'
      className='fixed bottom-0 left-0 right-0 z-50 md:hidden'
      style={{
        maxWidth: '100vw',
        transform: 'translateY(0)',
        willChange: 'transform',
      }}>
      <button
        onClick={handleBarClick}
        className='flex items-center justify-between w-full bg-brick text-white px-5 py-3 shadow-lg text-lg'>
        <div className='flex items-center'>
          <MessageSquare className='h-5 w-5 mr-2' />
          <span className='font-medium'>Get a Free Quote</span>
        </div>

        <div className='flex items-center'>
          <ArrowUp className='h-4 w-4' />
        </div>
      </button>
    </div>
  );
};

export default MobileFloatingBar;
