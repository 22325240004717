
import { useState } from 'react';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { ReviewStatus } from '@/hooks/gallery-table/types';

export const usePhotoManagement = () => {
  const [deleting, setDeleting] = useState(false);
  const [confirmDeleteId, setConfirmDeleteId] = useState<string | null>(null);

  const deletePhoto = async (id: string) => {
    if (confirmDeleteId === id) {
      try {
        setDeleting(true);

        // Delete from database
        const { error } = await supabase
          .from('gallery_photos')
          .delete()
          .eq('id', id);

        if (error) {
          throw error;
        }

        toast.success('Photo deleted successfully');
        return true;
      } catch (error) {
        console.error('Error deleting photo:', error);
        toast.error('Failed to delete photo');
        return false;
      } finally {
        setDeleting(false);
        setConfirmDeleteId(null);
      }
    } else {
      setConfirmDeleteId(id);
      return false;
    }
  };

  const updatePhotoCaption = async (id: string, caption: string) => {
    try {
      const { error } = await supabase
        .from('gallery_photos')
        .update({ caption })
        .eq('id', id);

      if (error) {
        throw error;
      }

      toast.success('Caption updated successfully');
      return true;
    } catch (error) {
      console.error('Error updating caption:', error);
      toast.error('Failed to update caption');
      return false;
    }
  };

  const updatePhotoReviewStatus = async (id: string, status: ReviewStatus) => {
    try {
      console.log(`Attempting to update photo ${id} to status ${status}`);
      
      // Check if review_status column exists
      const { data: columns, error: columnError } = await supabase
        .from('gallery_photos')
        .select('*')
        .limit(1);
      
      if (columnError) {
        throw columnError;
      }
      
      // If review_status doesn't exist in the table, show an info message
      if (columns && columns.length > 0 && !('review_status' in columns[0])) {
        console.log('review_status column does not exist in gallery_photos table');
        toast.info('Review status update feature is not available yet');
        return false;
      }
      
      // If we get here, the column exists and we can update it
      const { error } = await supabase
        .from('gallery_photos')
        .update({ review_status: status })
        .eq('id', id);

      if (error) {
        throw error;
      }

      toast.success('Review status updated successfully');
      return true;
    } catch (error) {
      console.error('Error updating review status:', error);
      toast.error('Failed to update review status');
      return false;
    }
  };

  return {
    deletePhoto,
    updatePhotoCaption,
    updatePhotoReviewStatus,
    deleting,
    confirmDeleteId,
    setConfirmDeleteId
  };
};
