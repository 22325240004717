
import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { GalleryFormValues } from '@/components/admin/galleries/form/types';
import { ReviewStatus } from '@/hooks/gallery-table/types';

export const useGalleryData = (id?: string) => {
  const [gallery, setGallery] = useState<GalleryFormValues>({
    title: '',
    description: '',
    slug: '',
    cover_image: '',
    is_public: false,
    franchise_region: null,
    job_number: null,
    review_status: 'pending' as ReviewStatus,
    service_id: null
  });
  const [loading, setLoading] = useState(true);
  const isEditing = id !== 'new' && !!id;

  useEffect(() => {
    if (isEditing) {
      fetchGallery();
    } else {
      setLoading(false);
    }
  }, [id]);

  const fetchGallery = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('galleries')
        .select('*')
        .eq('id', id)
        .single();

      if (error) {
        throw error;
      }

      setGallery({
        title: data.title || '',
        description: data.description || '',
        slug: data.slug || '',
        cover_image: data.cover_image || '',
        is_public: data.is_public || false,
        franchise_region: data.franchise_region || null,
        job_number: data.job_number || null,
        created_on: data.created_on,
        review_status: data.review_status || 'pending',
        service_id: data.service_id || null
      });
    } catch (error) {
      console.error('Error fetching gallery:', error);
      toast.error('Failed to load gallery');
    } finally {
      setLoading(false);
    }
  };

  return {
    gallery,
    setGallery,
    loading,
    isEditing
  };
};
