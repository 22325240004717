
import React from 'react';
import { Skeleton } from "@/components/ui/skeleton";

interface ImagePreviewProps {
  uploading: boolean;
  previewUrl: string | null;
}

const ImagePreview = ({ uploading, previewUrl }: ImagePreviewProps) => {
  if (uploading) {
    return <Skeleton className="h-48 w-full" />;
  }

  if (previewUrl) {
    return (
      <div className="relative w-full">
        <img
          src={previewUrl}
          alt="Gallery cover preview"
          className="w-full h-48 object-cover"
        />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-48 text-muted-foreground">
      <p>No image selected</p>
    </div>
  );
};

export default ImagePreview;
