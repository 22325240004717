import { useEffect } from 'react';
import {
  trackEvent,
  trackPageView,
  trackFormInteraction,
} from './trackingUtils';
import type { TrackingEvent } from './types';

export const useTracking = () => {
  useEffect(() => {
    // Track page view on mount and route changes
    trackPageView();
  }, []);

  return {
    trackEvent,
    trackPageView,
    trackFormInteraction,
  };
};

// Export types and utilities for direct usage
export type { TrackingEvent };
export { TRACKING_EVENTS } from './types';
