import React, { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { ArrowRight } from 'lucide-react';
import ContactRequestForm from './shared/ContactRequestForm';
import { Link } from 'react-router-dom';
import { useSettings } from '@/hooks/useSettings';
import { useModal } from './shared/modal/ModalContext';
import MobileFloatingBar from './shared/MobileFloatingBar';

// Image cache to prevent multiple network requests for the same image
const imageCache: Record<string, Promise<string>> = {};

// Keep track of known failed image URLs to avoid repeated attempts
const failedImageUrls = new Set<string>();

const preloadImage = (src: string): Promise<string> => {
  // Skip loading if this URL has previously failed
  if (failedImageUrls.has(src)) {
    return Promise.reject(new Error(`Known failed image URL: ${src}`));
  }

  // Return cached promise if we've already started loading this image
  if (imageCache[src]) {
    return imageCache[src];
  }

  // Check if src is a URL that starts with http/https
  const isExternalUrl = src.startsWith('http://') || src.startsWith('https://');

  // If it's an external URL, first check if it responds before loading the image
  const promise = new Promise<string>(async (resolve, reject) => {
    if (!src || typeof src !== 'string' || src.trim() === '') {
      reject(new Error('Invalid image source'));
      return;
    }

    const img = new Image();

    // Set up a single onload handler
    img.onload = () => {
      clearTimeout(timeoutId);
      resolve(src);
    };

    img.onerror = () => {
      clearTimeout(timeoutId);
      reject(new Error(`Failed to load image: ${src}`));
    };

    const timeoutId = setTimeout(() => {
      reject(new Error(`Image load timed out: ${src}`));
    }, 5000); // Reduced timeout period

    img.crossOrigin = 'anonymous';
    img.src = src;
  });

  // Store in cache
  imageCache[src] = promise;

  // Handle promise rejection to avoid unhandled promise rejection warnings
  promise.catch(() => {
    // Remove failed requests from cache and add to failed set
    delete imageCache[src];
    failedImageUrls.add(src);
  });

  return promise;
};

const HeroSection = () => {
  const { settings } = useSettings();
  const { openContactModal } = useModal();
  const [loadedImage, setLoadedImage] = useState<string | null>(null);
  const [imageLoading, setImageLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);

  // Use a dark background instead of fallback images
  const darkBackground = {
    background: 'linear-gradient(to bottom right, #505050, #202020)',
  };

  useEffect(() => {
    // Skip if we already have the correct image loaded
    if (loadedImage === settings?.home_hero_image) {
      return;
    }

    let isMounted = true;
    const mainImage = settings?.home_hero_image || '';

    // Only update state if we're actually changing the image
    if (loadedImage !== mainImage) {
      setImageLoading(true);
      setLoadError(false);
    }

    const loadImage = async () => {
      // Try to load main image if available and not in failed list
      if (mainImage && !failedImageUrls.has(mainImage)) {
        try {
          // If main image is an external URL (e.g., Unsplash), be extra cautious
          const src = await preloadImage(mainImage);
          if (isMounted) {
            setLoadedImage(src);
            setImageLoading(false);
          }
          return;
        } catch (error) {
          if (process.env.NODE_ENV !== 'production') {
            console.error('Error loading hero image:', {
              mainImage,
              error,
            });
          }
          // Don't try this image again
          failedImageUrls.add(mainImage);
        }
      }

      // If we reach here, the image failed to load - use dark background
      if (isMounted) {
        setLoadError(true);
        setImageLoading(false);
        setLoadedImage(null);
      }
    };

    loadImage();

    return () => {
      isMounted = false;
    };
  }, [settings?.home_hero_image, loadedImage]);

  // Use the dark background when no image is loaded
  const backgroundStyle = loadedImage
    ? { backgroundImage: `url(${loadedImage})` }
    : darkBackground;

  const handleQuoteButtonClick = () => {
    openContactModal();
  };

  return (
    <div className='relative min-h-[600px] w-full bg-black'>
      <div className='absolute inset-0 bg-black opacity-60 z-10'></div>

      <div
        className={`absolute inset-0 z-0 bg-center bg-cover transition-opacity duration-700 ${
          imageLoading ? 'opacity-0' : 'opacity-100'
        }`}
        style={backgroundStyle}></div>

      <div className='container mx-auto px-4 py-20 relative z-20 text-white'>
        <div className='grid lg:grid-cols-2 gap-10'>
          <div className='flex flex-col justify-center'>
            <h1 className='text-3xl md:text-4xl lg:text-5xl font-bold mb-6'>
              <span className='block'>Brick & Masonry Repairs</span>
              <span className='text-[#D00B0E]'>You Can Trust</span>
            </h1>
            <p className='text-lg mb-8 max-w-xl leading-relaxed'>
              At Fix My Brick, we believe every home deserves the finest care.
              Our team provides professional, tidy, and reliable brick and
              masonry services—so you can enjoy lasting results without the
              hassle.
            </p>
            <div className='flex flex-col sm:flex-row gap-4'>
              <Button
                size='lg'
                className='bg-[#D00B0E] hover:bg-[#D00B0E]/90 text-white px-8 h-14 font-medium text-lg w-full sm:w-auto'
                onClick={handleQuoteButtonClick}>
                Get a Free Quote <ArrowRight className='ml-2 h-5 w-5' />
              </Button>
              <Button
                variant='outline'
                size='lg'
                className='border-2 border-white bg-white text-[#D00B0E] hover:bg-transparent hover:text-white px-8 h-14 font-medium text-lg w-full sm:w-auto'
                asChild>
                <Link to='/services'>Our Services</Link>
              </Button>
            </div>
          </div>

          <div className='hidden lg:flex items-center'>
            <div className='bg-white shadow-xl rounded-lg overflow-hidden w-full text-gray-800'>
              <ContactRequestForm className='' />
            </div>
          </div>
        </div>
      </div>

      <MobileFloatingBar />
    </div>
  );
};

export default HeroSection;
