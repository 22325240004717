
import React from 'react';
import { TableHead, TableHeader, TableRow } from "@/components/ui/table";

const FranchiseImagesTableHeader: React.FC = () => {
  return (
    <TableHeader>
      <TableRow className="bg-muted/30">
        <TableHead className="font-semibold">Image</TableHead>
        <TableHead className="font-semibold">Job Number</TableHead>
        <TableHead className="font-semibold">Franchise</TableHead>
        <TableHead className="font-semibold">File Name</TableHead>
        <TableHead className="font-semibold">Status</TableHead>
        <TableHead className="font-semibold">Actions</TableHead>
      </TableRow>
    </TableHeader>
  );
};

export default FranchiseImagesTableHeader;
