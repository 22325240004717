import React from 'react';
import { MapPin } from 'lucide-react';

interface LocationType {
  name: string;
  address: string;
}

interface FooterLocationsProps {
  locationsToDisplay: LocationType[];
  locationsLoading: boolean;
}

const FooterLocations: React.FC<FooterLocationsProps> = ({
  locationsToDisplay,
  locationsLoading
}) => {
  return (
    <div className="space-y-8">
      <div className="flex items-start mb-6">
        <MapPin size={20} className="mt-1 mr-4 text-gray-400 flex-shrink-0" />
        <h3 className="text-xl font-semibold text-white">Our Locations</h3>
      </div>
      
      {locationsLoading ? (
        <div className="animate-pulse space-y-3">
          <div className="h-5 bg-gray-700/50 rounded w-3/4"></div>
          <div className="h-4 bg-gray-700/50 rounded w-full"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-y-10 gap-x-8 ml-8">
          {locationsToDisplay.map((location, index) => (
            <div key={index} className="group">
              <span className="text-white text-base font-medium block mb-2">{location.name}</span>
              <address className="text-gray-400 text-base block group-hover:text-gray-300 transition-colors not-italic leading-relaxed">
                {location.address}
              </address>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FooterLocations;
