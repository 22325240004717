
import { useState, useCallback, useMemo } from 'react';
import { GMBLocation } from '@/types/gmb-location';

export const useGMBLocationFiltering = (locations: GMBLocation[] | undefined) => {
  const [search, setSearch] = useState('');

  const handleSearchChange = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const filteredLocations = useMemo(() => {
    return locations?.filter(location => {
      const searchLower = search.toLowerCase();
      return (
        search === '' || 
        location.location_name.toLowerCase().includes(searchLower) ||
        (location.address && location.address.toLowerCase().includes(searchLower)) ||
        (location.city && location.city.toLowerCase().includes(searchLower)) ||
        (location.province && location.province.toLowerCase().includes(searchLower))
      );
    });
  }, [locations, search]);

  return {
    search,
    setSearch,
    handleSearchChange,
    filteredLocations
  };
};
