
import React from 'react';

interface LightBoxProps {
  images: Array<{
    image_url: string;
    caption?: string;
  }>;
  selectedIndex: number | null;
  onClose: () => void;
  onNavigate: (direction: 'prev' | 'next') => void;
}

const LightBox = ({ images, selectedIndex, onClose, onNavigate }: LightBoxProps) => {
  if (selectedIndex === null) return null;

  const currentImage = images[selectedIndex];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center p-4">
      <div className="absolute inset-0" onClick={onClose}></div>
      <div className="relative z-10 max-w-5xl w-full">
        <img 
          src={currentImage.image_url} 
          alt={currentImage.caption || 'Gallery image'}
          className="max-h-[80vh] max-w-full mx-auto object-contain"
          onError={(e) => {
            const img = e.target as HTMLImageElement;
            console.error('Failed to load image:', img.src);
            img.src = '/placeholder.svg';
          }}
        />
        {currentImage.caption && (
          <p className="text-white text-center mt-4">{currentImage.caption}</p>
        )}
        <button 
          className="absolute left-0 top-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white p-4 hover:bg-opacity-70"
          onClick={(e) => {
            e.stopPropagation();
            onNavigate('prev');
          }}
        >
          &larr;
        </button>
        <button 
          className="absolute right-0 top-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white p-4 hover:bg-opacity-70"
          onClick={(e) => {
            e.stopPropagation();
            onNavigate('next');
          }}
        >
          &rarr;
        </button>
        <button 
          className="absolute top-4 right-4 text-white bg-black bg-opacity-50 p-2 hover:bg-opacity-70"
          onClick={onClose}
        >
          X
        </button>
      </div>
    </div>
  );
};

export default LightBox;
