import * as React from "react"

const MOBILE_BREAKPOINT = 768

export function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState<boolean | undefined>(undefined)

  React.useEffect(() => {
    const mql = window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT - 1}px)`)
    const onChange = () => {
      setIsMobile(window.innerWidth < MOBILE_BREAKPOINT)
    }
    mql.addEventListener("change", onChange)
    setIsMobile(window.innerWidth < MOBILE_BREAKPOINT)
    return () => mql.removeEventListener("change", onChange)
  }, [])

  return !!isMobile
}

export function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = React.useState<boolean>(false)
  
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const media = window.matchMedia(query)
      
      // Set initial state
      setMatches(media.matches)
      
      // Add listener for future changes
      const listener = () => {
        setMatches(media.matches)
      }
      
      // Use addEventListener if available, otherwise use deprecated addListener
      if (media.addEventListener) {
        media.addEventListener("change", listener)
        return () => media.removeEventListener("change", listener)
      } else {
        // For older browsers
        media.addListener(listener)
        return () => media.removeListener(listener)
      }
    }
    return undefined
  }, [query])
  
  return matches
}
