
import React from 'react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Skeleton } from '@/components/ui/skeleton';

interface ActivityItem {
  type: 'page' | 'service' | 'location' | 'testimonial';
  action: string;
  name: string;
  timestamp: string;
  timeAgo: string;
}

interface RecentActivityProps {
  recentActivity: ActivityItem[];
  loading: boolean;
}

const RecentActivity: React.FC<RecentActivityProps> = ({ recentActivity, loading }) => {
  // Get color for activity item
  const getActivityColor = (type: string): string => {
    switch (type) {
      case 'page': return 'bg-green-500';
      case 'service': return 'bg-blue-500';
      case 'location': return 'bg-orange-500';
      case 'testimonial': return 'bg-purple-500';
      default: return 'bg-gray-500';
    }
  };

  // Convert type to display text
  const getTypeDisplay = (type: string): string => {
    switch (type) {
      case 'page': return 'Page';
      case 'service': return 'Service';
      case 'location': return 'Location';
      case 'testimonial': return 'Testimonial';
      default: return type;
    }
  };

  // Mobile card layout for activity items
  const MobileActivityCard = ({ item }: { item: ActivityItem }) => (
    <div className="border rounded-md p-3 mb-2">
      <div className="flex items-center mb-1">
        <div className={`w-2 h-2 ${getActivityColor(item.type)} rounded-full mr-2 flex-shrink-0`}></div>
        <p className="text-sm font-medium">{getTypeDisplay(item.type)}</p>
        <span className="ml-auto text-xs text-gray-500">{item.timeAgo}</span>
      </div>
      <p className="text-sm pl-4 truncate">"{item.name}" was {item.action}</p>
    </div>
  );

  // Loading skeleton for mobile
  const MobileLoadingSkeleton = () => (
    <div className="space-y-3">
      {Array(3).fill(0).map((_, index) => (
        <div key={index} className="border rounded-md p-3">
          <div className="flex items-center mb-2">
            <Skeleton className="h-2 w-2 rounded-full mr-2" />
            <Skeleton className="h-4 w-20" />
            <Skeleton className="h-3 w-16 ml-auto" />
          </div>
          <Skeleton className="h-4 w-full" />
        </div>
      ))}
    </div>
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle>Recent Activity</CardTitle>
        <CardDescription>
          Latest changes and updates to your website
        </CardDescription>
      </CardHeader>
      <CardContent>
        {/* Desktop view - table layout */}
        <div className="hidden md:block overflow-x-auto">
          {loading ? (
            <div className="space-y-4">
              {Array(4).fill(0).map((_, index) => (
                <div key={index} className="flex items-center">
                  <div className="w-2 h-2 bg-gray-200 animate-pulse rounded-full mr-2"></div>
                  <div className="h-4 w-48 bg-gray-200 animate-pulse rounded"></div>
                  <div className="ml-auto h-4 w-16 bg-gray-200 animate-pulse rounded"></div>
                </div>
              ))}
            </div>
          ) : (
            <div className="space-y-4">
              {recentActivity.length > 0 ? (
                recentActivity.map((item, index) => (
                  <div key={index} className="flex items-center min-w-[300px]">
                    <div className={`w-2 h-2 ${getActivityColor(item.type)} rounded-full mr-2 flex-shrink-0`}></div>
                    <p className="text-sm truncate">
                      {getTypeDisplay(item.type)} "{item.name}" was {item.action}
                    </p>
                    <span className="ml-auto text-xs text-gray-500 whitespace-nowrap pl-2">{item.timeAgo}</span>
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray-500">No recent activity found</p>
              )}
            </div>
          )}
        </div>

        {/* Mobile view - card layout */}
        <div className="md:hidden">
          {loading ? (
            <MobileLoadingSkeleton />
          ) : (
            <div>
              {recentActivity.length > 0 ? (
                recentActivity.map((item, index) => (
                  <MobileActivityCard key={index} item={item} />
                ))
              ) : (
                <p className="text-sm text-gray-500 text-center py-4">No recent activity found</p>
              )}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default RecentActivity;
