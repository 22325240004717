
import React from 'react';
import { TableHeader, TableRow, TableHead } from "@/components/ui/table";
import { ArrowUpDown } from 'lucide-react';

interface GalleryTableHeaderProps {
  sortField: string;
  sortDirection: 'asc' | 'desc';
  onSort: (field: any) => void;
}

const GalleryTableHeader: React.FC<GalleryTableHeaderProps> = ({
  sortField,
  sortDirection,
  onSort
}) => {
  const getSortIcon = (field: string) => {
    if (field !== sortField) return <ArrowUpDown className="ml-2 h-4 w-4" />;
    return sortDirection === 'asc' 
      ? <ArrowUpDown className="ml-2 h-4 w-4 text-D00B0E" /> 
      : <ArrowUpDown className="ml-2 h-4 w-4 text-D00B0E" />;
  };

  return (
    <TableHeader>
      <TableRow>
        <TableHead className="w-[80px]">
          Thumbnail
        </TableHead>
        <TableHead 
          className="cursor-pointer"
          onClick={() => onSort('title')}
        >
          <div className="flex items-center">
            Title {getSortIcon('title')}
          </div>
        </TableHead>
        <TableHead 
          className="cursor-pointer hidden md:table-cell"
          onClick={() => onSort('franchise_region')}
        >
          <div className="flex items-center">
            Franchise {getSortIcon('franchise_region')}
          </div>
        </TableHead>
        <TableHead 
          className="cursor-pointer hidden lg:table-cell"
          onClick={() => onSort('created_on')}
        >
          <div className="flex items-center">
            Created {getSortIcon('created_on')}
          </div>
        </TableHead>
        <TableHead className="hidden md:table-cell text-center">
          Public
        </TableHead>
        <TableHead
          className="cursor-pointer hidden lg:table-cell"
          onClick={() => onSort('review_status')}
        >
          <div className="flex items-center">
            Review {getSortIcon('review_status')}
          </div>
        </TableHead>
        <TableHead className="text-right">Actions</TableHead>
      </TableRow>
    </TableHeader>
  );
};

export default GalleryTableHeader;
