import React from 'react';
import { Link } from 'react-router-dom';
import { MapPin, ChevronRight } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';
import { LocationLinks } from '@/components/header/navigationData';
import { useChildLocationsQuery } from '@/hooks/useLocationQuery';
import { Location } from '@/types/location';

interface LocationCardProps {
  location: Location;
}

const LocationCard: React.FC<LocationCardProps> = ({ location }) => {
  const matchedNavLocation = LocationLinks.find(
    (l) => l.slug === location.slug
  );
  const locationAddress = matchedNavLocation?.address || location.address;

  // Validate ID is a proper UUID before passing to query
  const isValidUuid = (id: any): boolean => {
    if (!id || typeof id !== 'string') return false;
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(id);
  };

  // Only query child locations if we have a valid UUID
  const { data: sublocations = [] } = useChildLocationsQuery(
    isValidUuid(location.id) ? location.id : undefined
  );

  return (
    <Card className="overflow-hidden h-full shadow-md hover:shadow-lg transition-shadow">
      <CardContent className="p-6 flex flex-col h-full">
        <div className="flex items-center mb-4">
          <MapPin className="h-6 w-6 text-[#D00B0E] mr-2" />
          <h3 className="text-xl font-semibold">{location.name}</h3>
        </div>

        {locationAddress && <p className="text-gray-600 mb-4">{locationAddress}</p>}

        {/* Display sublocations if available */}
        {sublocations.length > 0 && (
          <div className="mb-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">Areas we serve:</h4>
            <ul className="space-y-1 pl-2">
              {sublocations.slice(0, 5).map((sublocation) => (
                <li key={sublocation.slug} className="text-sm">
                  <Link
                    to={`/locations/${location.slug}/${sublocation.slug}`}
                    className="flex items-center text-gray-600 hover:text-[#D00B0E] transition-colors"
                  >
                    <ChevronRight className="h-3 w-3 mr-1" />
                    {sublocation.name}
                  </Link>
                </li>
              ))}
              {sublocations.length > 5 && (
                <li className="text-sm text-gray-500 italic">
                  +{sublocations.length - 5} more areas
                </li>
              )}
            </ul>
          </div>
        )}

        <div className="mt-auto pt-4">
          <Link
            to={`/locations/${location.slug}`}
            className="bg-[#D00B0E] hover:bg-[#D00B0E]/90 text-white px-4 py-2 rounded-md inline-flex items-center"
          >
            View Services in {location.name.split(' ')[0]}
          </Link>
        </div>
      </CardContent>
    </Card>
  );
};

export default LocationCard;