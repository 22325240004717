
import React from 'react';

interface Tag {
  id: string;
  name: string;
  slug: string;
}

interface PostTagsProps {
  tags?: Tag[];
}

const PostTags: React.FC<PostTagsProps> = ({ tags }) => {
  if (!tags || tags.length === 0) return null;

  return (
    <div className="border-t border-b border-gray-200 py-6 mb-12">
      <div className="flex flex-wrap items-center">
        <span className="text-gray-700 mr-3 font-medium">Tags:</span>
        {tags.map((tag) => (
          <span 
            key={tag.id} 
            className="text-sm bg-gray-100 text-gray-700 px-3 py-1 rounded-full mr-2 mb-2"
          >
            {tag.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default PostTags;
