
import React, { useEffect, useRef, useState } from "react";
import { cn } from "@/lib/utils";

// Shared observer cache to avoid creating too many observers
const observerCache: Record<string, IntersectionObserver> = {};

// Get an observer from the cache or create a new one
const getObserver = (
  callback: IntersectionObserverCallback, 
  threshold: number, 
  rootMargin: string
): IntersectionObserver => {
  const key = `${threshold}-${rootMargin}`;
  
  if (!observerCache[key]) {
    observerCache[key] = new IntersectionObserver(callback, { threshold, rootMargin });
  }
  
  return observerCache[key];
};

interface LazyLoadProps {
  children: React.ReactNode;
  height?: string | number;
  width?: string | number;
  className?: string;
  placeholder?: React.ReactNode;
  threshold?: number;
  rootMargin?: string;
}

const LazyLoad = ({
  children,
  height,
  width,
  className,
  placeholder,
  threshold = 0.1,
  rootMargin = "0px",
}: LazyLoadProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  
  // Use a ref to store the current element
  const elementRef = useRef<Element | null>(null);

  useEffect(() => {
    const currentRef = containerRef.current;
    if (!currentRef) return;
    
    // Store the current element
    elementRef.current = currentRef;
    
    // Create a memoized callback to reduce observer instances
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        // Only process if this is our element
        if (entry.target === elementRef.current && entry.isIntersecting && !hasLoaded) {
          setIsVisible(true);
          setHasLoaded(true);
          
          // Get the observer that triggered this callback
          const observer = getObserver(handleIntersection, threshold, rootMargin);
          observer.unobserve(entry.target);
        }
      });
    };

    // Get or create an observer
    const observer = getObserver(handleIntersection, threshold, rootMargin);
    
    // Start observing
    observer.observe(currentRef);

    return () => {
      // Clean up
      if (currentRef && elementRef.current === currentRef) {
        observer.unobserve(currentRef);
        elementRef.current = null;
      }
    };
  }, [hasLoaded, threshold, rootMargin]);

  return (
    <div
      ref={containerRef}
      className={cn("overflow-hidden", className)}
      style={{ 
        height: !isVisible && height ? height : undefined, 
        width: !isVisible && width ? width : undefined 
      }}
    >
      {isVisible ? children : placeholder || (
        <div 
          className="animate-pulse bg-gray-200 rounded" 
          style={{ height: height || "100%", width: width || "100%" }}
        />
      )}
    </div>
  );
};

export default LazyLoad;
