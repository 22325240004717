
import React from 'react';
import { TableRow, TableCell } from '@/components/ui/table';
import { Badge } from '@/components/ui/badge';
import { GMBLocation } from '@/types/gmb-location';
import { Button } from '@/components/ui/button';
import { Edit, ExternalLink, Trash2 } from 'lucide-react';

interface GMBLocationTableRowProps {
  location: GMBLocation;
  websiteLocationName: string;
  onEdit: (location: GMBLocation) => void;
  onDelete: (locationId: string) => void;
}

export const GMBLocationTableRow: React.FC<GMBLocationTableRowProps> = ({
  location,
  websiteLocationName,
  onEdit,
  onDelete
}) => {
  return (
    <TableRow key={location.id}>
      <TableCell className="font-medium">{location.location_name}</TableCell>
      <TableCell>{location.address}</TableCell>
      <TableCell>{location.city}</TableCell>
      <TableCell>{location.province}</TableCell>
      <TableCell>
        <Badge variant={location.is_active ? "default" : "secondary"}>
          {location.is_active ? "Active" : "Inactive"}
        </Badge>
      </TableCell>
      <TableCell>{websiteLocationName}</TableCell>
      <TableCell className="text-right">
        <div className="flex justify-end gap-2">
          {location.website_url && (
            <Button
              variant="outline"
              size="icon"
              asChild
              title="Visit Website"
            >
              <a href={location.website_url} target="_blank" rel="noopener noreferrer">
                <ExternalLink className="h-4 w-4" />
              </a>
            </Button>
          )}
          <Button
            variant="outline"
            size="icon"
            onClick={() => onEdit(location)}
            title="Edit Location"
          >
            <Edit className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            size="icon"
            onClick={() => onDelete(location.id)}
            title="Delete Location"
            className="text-red-500 hover:text-red-700 hover:bg-red-50"
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};
