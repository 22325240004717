
import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Header from '@/components/header';
import Footer from '@/components/Footer';
import GalleryHeader from '@/components/gallery/GalleryHeader';
import ImageGrid from '@/components/gallery/ImageGrid';
import LightBox from '@/components/gallery/LightBox';
import GalleryLoading from '@/components/gallery/GalleryLoading';
import GalleryNotFound from '@/components/gallery/GalleryNotFound';
import { useGalleryDetail } from '@/hooks/useGalleryDetail';

const GalleryDetail = () => {
  const { slug } = useParams<{ slug: string }>();
  const { 
    gallery, 
    images, 
    loading, 
    selectedImage, 
    setSelectedImage, 
    navigateImage 
  } = useGalleryDetail(slug);

  if (loading) {
    return <GalleryLoading />;
  }

  if (!gallery) {
    return <GalleryNotFound />;
  }

  return (
    <>
      <Helmet>
        <title>{gallery.title} | Gallery</title>
        <meta name="description" content={gallery.description || `View our ${gallery.title} gallery.`} />
      </Helmet>

      <Header />

      <main className="container mx-auto px-4 py-12">
        <GalleryHeader 
          title={gallery.title}
          description={gallery.description}
          backLink="/gallery"
        />

        {images.length === 0 ? (
          <div className="py-12 text-center">
            <p className="text-lg text-gray-600">No images have been added to this gallery yet.</p>
          </div>
        ) : (
          <ImageGrid 
            images={images}
            onImageClick={(index) => setSelectedImage(index)}
          />
        )}

        <LightBox 
          images={images}
          selectedIndex={selectedImage}
          onClose={() => setSelectedImage(null)}
          onNavigate={navigateImage}
        />
      </main>

      <Footer />
    </>
  );
};

export default GalleryDetail;
