
import { useState, useEffect } from 'react';
import { useFetchGallery } from './useFetchGallery';
import { useImageNavigation } from './useImageNavigation';
import { Gallery, GalleryImage } from './types';

export type { Gallery, GalleryImage };

export const useGalleryDetail = (slug: string | undefined) => {
  const [gallery, setGallery] = useState<Gallery | null>(null);
  const { fetchGallery, loading, notFound, setLoading } = useFetchGallery();
  const { selectedImage, setSelectedImage, navigateImage } = useImageNavigation(gallery?.images || []);

  useEffect(() => {
    if (slug) {
      fetchGalleryData();
    }
  }, [slug]);

  const fetchGalleryData = async () => {
    if (!slug) return;
    
    setLoading(true);
    const galleryData = await fetchGallery(slug);
    setGallery(galleryData);
  };

  return {
    gallery,
    loading,
    notFound,
    selectedImage,
    setSelectedImage,
    navigateImage,
    images: gallery?.images || []
  };
};
