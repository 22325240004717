
import React, { useState } from 'react';
import { Star, MapPin, ChevronDown, ChevronUp } from 'lucide-react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Card } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { Review } from '@/lib/supabase/reviews';
import { Button } from '@/components/ui/button';

export interface ReviewCardProps {
  review: Review;
  showLocation?: boolean;
  locationName?: string;
  className?: string;
}

// Helper function to get star rating as a number
const getStarRating = (rating: string | number): number => {
  if (typeof rating === 'string') {
    if (rating === 'FIVE') return 5;
    if (rating === 'FOUR') return 4;
    if (rating === 'THREE') return 3;
    if (rating === 'TWO') return 2;
    if (rating === 'ONE') return 1;
    return parseInt(rating, 10) || 5;
  }
  return rating;
};

// Function to get relative time (e.g., "3 months ago")
const getRelativeTime = (dateString: string): string => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const now = new Date();
  const diffMs = now.getTime() - date.getTime();
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  
  if (diffDays < 1) return "Today";
  if (diffDays === 1) return "Yesterday";
  if (diffDays < 30) return `${diffDays} days ago`;
  
  const diffMonths = Math.floor(diffDays / 30);
  if (diffMonths === 1) return "1 month ago";
  if (diffMonths < 12) return `${diffMonths} months ago`;
  
  const diffYears = Math.floor(diffMonths / 12);
  if (diffYears === 1) return "1 year ago";
  return `${diffYears} years ago`;
};

// StarRating component for displaying rating stars
const StarRating = ({ rating }: { rating: number }) => {
  return (
    <div className="flex">
      {[...Array(5)].map((_, i) => (
        <Star
          key={i}
          className={`h-4 w-4 ${i < rating ? 'text-yellow-400 fill-yellow-400' : 'text-gray-300'}`}
        />
      ))}
    </div>
  );
};

// Main ReviewCard component
const ReviewCard: React.FC<ReviewCardProps> = ({ 
  review, 
  showLocation = false,
  locationName,
  className 
}) => {
  // State for expanded/collapsed review text
  const [expanded, setExpanded] = useState(false);
  
  // Get proper date string from various possible fields
  const dateString = review.reviewer_create_time || review.create_time || review.created_at;
  
  // Define character limit before showing "Read more"
  const characterLimit = 180;
  
  // Check if review comment exists and if it exceeds the character limit
  const hasLongComment = review.reviewer_comment && review.reviewer_comment.length > characterLimit;
  
  // Get the displayed text based on expanded state
  const displayText = !hasLongComment || expanded
    ? review.reviewer_comment
    : `${review.reviewer_comment?.substring(0, characterLimit)}...`;
  
  return (
    <Card className={cn("bg-white rounded-lg shadow overflow-hidden hover:shadow-md transition-shadow h-full flex flex-col", className)}>
      {/* Header with avatar, name, and date */}
      <div className="p-4 border-b border-gray-100">
        <div className="flex items-center">
          <Avatar className="h-10 w-10 mr-3">
            <AvatarImage src={review.reviewer_photo_url} alt={review.reviewer_name} />
            <AvatarFallback className="bg-[#1a73e8] text-white">
              {review.reviewer_name?.charAt(0)}
            </AvatarFallback>
          </Avatar>
          <div>
            <h3 className="font-medium text-[#202124]">{review.reviewer_name}</h3>
            <div className="flex items-center space-x-2">
              <StarRating rating={getStarRating(review.star_rating)} />
              <span className="text-xs text-gray-500">
                {getRelativeTime(dateString)}
              </span>
            </div>
          </div>
        </div>
      </div>
      
      {/* Review content */}
      <div className="p-4 flex-grow flex flex-col">
        {review.reviewer_comment ? (
          <>
            <p className="text-[#5f6368] text-sm">{displayText}</p>
            
            {/* Read more/less toggle button for long reviews */}
            {hasLongComment && (
              <Button 
                variant="ghost" 
                size="sm" 
                className="text-xs text-[#D00B0E] mt-2 self-start p-0 h-auto hover:bg-transparent"
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? (
                  <span className="flex items-center">Read less <ChevronUp className="ml-1 h-3 w-3" /></span>
                ) : (
                  <span className="flex items-center">Read more <ChevronDown className="ml-1 h-3 w-3" /></span>
                )}
              </Button>
            )}
          </>
        ) : (
          <p className="text-gray-400 text-sm italic">No comment provided</p>
        )}
        
        {/* Location name (optional) */}
        {showLocation && (
          <div className="mt-auto pt-4 flex items-center text-xs text-gray-600">
            <MapPin size={14} className="mr-1 text-[#1a73e8]" />
            {review.location_name || locationName}
          </div>
        )}
      </div>
    </Card>
  );
};

export default ReviewCard;
