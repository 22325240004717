import { createClient } from '@supabase/supabase-js';

// Helper function to log connection details in development mode only
const logConnectionDetails = (url: string, isDev: boolean) => {
  if (isDev) {
    console.log(
      `🔌 Supabase connected to: ${url} [${import.meta.env.MODE} environment]`
    );
  }
};

// Initialize the Supabase client based on environment
const getSupabaseConfig = () => {
  const isDev = import.meta.env.MODE === 'development';

  // Use environment variables
  const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
  const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

  // Log connection details in development
  logConnectionDetails(supabaseUrl, isDev);

  return { supabaseUrl, supabaseAnonKey };
};

const { supabaseUrl, supabaseAnonKey } = getSupabaseConfig();

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Utility to help with authenticated storage operations
export const getAuthenticatedStorage = async () => {
  const isDev = import.meta.env.MODE === 'development';
  const { data } = await supabase.auth.getSession();
  const isAuthenticated = !!data?.session;

  // For development with auth bypass
  const authBypassEnabled =
    isDev && localStorage.getItem('bypass_auth') !== 'false';

  if (!isAuthenticated && authBypassEnabled) {
    console.warn(
      '⚠️ Storage operations may fail: Using auth bypass in development, but not authenticated for Supabase storage.'
    );
    console.warn(
      'ℹ️ Suggestion: Either log in as a user, or configure storage RLS policies to allow unauthenticated uploads.'
    );
  }

  return {
    storage: supabase.storage,
    isAuthenticated,
    authBypassEnabled,
    isDev,
  };
};
