
import React from 'react';
import { TabsContent } from '@/components/ui/tabs';

const UsageTab: React.FC = () => {
  return (
    <TabsContent value="usage" className="space-y-4">
      <div className="prose max-w-none">
        <h3>Implementation Guidelines</h3>
        <ol>
          <li>
            <strong>Component Import:</strong>
            <pre className="bg-muted p-2 rounded-md text-sm">
              {`import { AdminTable, ColumnDef, TablePagination } from '@/components/admin/shared';
import { useAdminTable } from '@/hooks/useAdminTable';`}
            </pre>
          </li>
          <li>
            <strong>Define Columns:</strong>
            <pre className="bg-muted p-2 rounded-md text-sm">
              {`const columns: ColumnDef<YourDataType>[] = [
  {
    header: 'Name',
    cell: (row) => <span>{row.name}</span>,
  },
  // More columns...
];`}
            </pre>
          </li>
          <li>
            <strong>Setup Table State:</strong>
            <pre className="bg-muted p-2 rounded-md text-sm">
              {`const {
  tableState,
  currentPageData,
  totalItems,
  setPage,
  setPageSize,
  setSearch,
} = useAdminTable({
  data: yourData,
  searchFields: ['name', 'description']
});`}
            </pre>
          </li>
          <li>
            <strong>Render the Table:</strong>
            <pre className="bg-muted p-2 rounded-md text-sm">
              {`<AdminTable
  data={currentPageData}
  columns={columns}
  isLoading={loading}
  isError={error}
  onRetry={handleRetry}
  emptyMessage="No items found"
  actions={yourActions}
  searchable={true}
  onSearch={setSearch}
/>

<TablePagination
  currentPage={tableState.pagination.pageIndex + 1}
  pageCount={tableState.pagination.pageCount}
  pageSize={tableState.pagination.pageSize}
  totalItems={totalItems}
  onPageChange={setPage}
  onPageSizeChange={setPageSize}
/>`}
            </pre>
          </li>
        </ol>
      </div>
    </TabsContent>
  );
};

export default UsageTab;
