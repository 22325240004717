
import React from 'react';
import Header from '@/components/header';
import Footer from '@/components/Footer';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const NotFoundState: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow py-16 md:py-24">
        <div className="container mx-auto px-4 text-center">
          <div className="max-w-lg mx-auto">
            <h1 className="text-3xl md:text-4xl font-bold mb-6">Service Not Found</h1>
            <p className="text-lg text-gray-700 mb-8">The service you're looking for doesn't exist or has been moved. Please check the URL or browse our available services.</p>
            <Link to="/services" className="inline-flex items-center bg-brick text-white px-6 py-3 rounded hover:bg-brick/90 transition-colors">
              <ArrowLeft className="mr-2 h-5 w-5" /> 
              View All Services
            </Link>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default NotFoundState;
