import React from 'react';
import { Phone } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useInteractionTracking } from '@/hooks/useEventTracking';

interface PhoneButtonProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  phoneNumber: string;
  displayNumber?: string;
  variant?: 'primary' | 'secondary' | 'outline' | 'white' | 'ghost';
  size?: 'default' | 'sm' | 'lg';
  fullWidth?: boolean;
  trackingSource?: string; // Source of the phone call for tracking
}

/**
 * PhoneButton component - A consistent, accessible phone button with different style variants
 */
const PhoneButton = React.forwardRef<HTMLAnchorElement, PhoneButtonProps>(
  (
    {
      phoneNumber,
      displayNumber,
      variant = 'primary',
      size = 'default',
      fullWidth = false,
      trackingSource = 'general',
      className,
      ...props
    },
    ref
  ) => {
    // Initialize tracking
    const { trackPhoneCall } = useInteractionTracking();

    // Format the phone number for display if not provided
    const formattedNumber =
      displayNumber ||
      phoneNumber
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

    // Clean the phone number for the href
    const cleanNumber = phoneNumber.replace(/\D/g, '');

    // Size classes
    const sizeClasses = {
      sm: 'h-9 px-4 text-sm',
      default: 'h-10 px-6 text-base',
      lg: 'h-14 px-8 text-lg font-medium',
    };

    // Variant classes with improved naming and more options
    const variantClasses = {
      // Red background with white text (default)
      primary: 'bg-brick hover:bg-brick/90 text-white border-brick',

      // White background with red text and red border
      secondary:
        'bg-white text-brick hover:bg-transparent hover:text-brick border-2 border-brick',

      // Transparent with white text and white border (for dark backgrounds)
      outline:
        'bg-transparent text-white hover:bg-white/10 hover:text-white border-2 border-white',

      // White background with red text (for colored backgrounds)
      white: 'bg-white text-brick hover:bg-white/90 border-white shadow-lg',

      // Transparent with no border (minimal)
      ghost:
        'bg-transparent text-current hover:bg-current/10 hover:text-current',
    };

    // Handle click to track phone calls
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      // Track the phone call event
      trackPhoneCall(cleanNumber, trackingSource, {
        page_url: window.location.href,
        button_variant: variant,
        button_size: size,
      });

      // If there's an onClick handler from props, call it
      if (props.onClick) {
        props.onClick(e);
      }
    };

    return (
      <a
        ref={ref}
        href={`tel:${cleanNumber}`}
        onClick={handleClick}
        className={cn(
          // Base styles
          'inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md',
          'transition-colors duration-200',
          'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
          'disabled:pointer-events-none disabled:opacity-50',
          // Size styles
          sizeClasses[size],
          // Variant styles
          variantClasses[variant],
          // Width
          fullWidth ? 'w-full' : 'w-auto',
          // Custom class
          className
        )}
        {...props}>
        <Phone className={size === 'lg' ? 'h-5 w-5 mr-2' : 'h-4 w-4 mr-1.5'} />
        {formattedNumber}
      </a>
    );
  }
);

PhoneButton.displayName = 'PhoneButton';

export { PhoneButton };
