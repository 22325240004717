import React, { useState } from 'react';
import { ServiceDetail } from '@/types/service';
import LightBox from '@/components/gallery/LightBox';

interface ServiceGalleryProps {
  service: ServiceDetail;
}

const ServiceGallery: React.FC<ServiceGalleryProps> = ({ service }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null
  );

  // Don't render if no gallery images
  if (!service.gallery_images || service.gallery_images.length === 0) {
    return null;
  }

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
  };

  const handleCloseLightbox = () => {
    setSelectedImageIndex(null);
  };

  const handleNavigate = (direction: 'prev' | 'next') => {
    if (selectedImageIndex === null || !service.gallery_images) return;

    const total = service.gallery_images.length;
    if (direction === 'prev') {
      setSelectedImageIndex((selectedImageIndex - 1 + total) % total);
    } else {
      setSelectedImageIndex((selectedImageIndex + 1) % total);
    }
  };

  return (
    <section className='py-16 pb-28 md:pb-16 bg-gray-50'>
      <div className='container mx-auto px-4'>
        <h2 className='text-3xl font-bold mb-8 text-center'>Gallery</h2>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 overflow-hidden'>
          {service.gallery_images.map((image, index) => (
            <div
              key={image.id}
              className='overflow-hidden rounded-lg shadow-md cursor-pointer transition transform hover:scale-105 w-full'
              onClick={() => handleImageClick(index)}>
              <div className='aspect-w-4 aspect-h-3 bg-gray-200'>
                <img
                  src={image.image_url}
                  alt={
                    image.caption ||
                    `${service.title} gallery image ${index + 1}`
                  }
                  className='w-full h-full object-cover'
                />
              </div>
              {image.caption && (
                <div className='p-3 bg-white'>
                  <p className='text-sm text-gray-700'>{image.caption}</p>
                </div>
              )}
            </div>
          ))}
        </div>

        {selectedImageIndex !== null && (
          <LightBox
            images={service.gallery_images}
            selectedIndex={selectedImageIndex}
            onClose={handleCloseLightbox}
            onNavigate={handleNavigate}
          />
        )}
      </div>
    </section>
  );
};

export default ServiceGallery;
