
import React from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { Plus } from 'lucide-react';

interface ServiceSearchProps {
  searchQuery: string;
  onSearchChange: (query: string) => void;
}

const ServiceSearch = ({ searchQuery, onSearchChange }: ServiceSearchProps) => {
  return (
    <div className="mb-4 flex items-center justify-between">
      <Input
        type="text"
        placeholder="Search services..."
        value={searchQuery}
        onChange={(e) => onSearchChange(e.target.value)}
        className="max-w-md"
      />
      <Button asChild>
        <Link to="/admin/services/new" className="flex items-center">
          <Plus className="mr-2 h-4 w-4" />
          Add Service
        </Link>
      </Button>
    </div>
  );
};

export default ServiceSearch;
