
import React from 'react';
import { LocationFormData } from '@/hooks/location/useLocationFormState';
import { Location } from '@/types/location';

interface PreviewTabProps {
  formData: LocationFormData;
  parentLocations: Location[];
}

const PreviewTab: React.FC<PreviewTabProps> = ({ formData, parentLocations }) => {
  const parentLocation = parentLocations.find(loc => loc.id === formData.parent_id);
  const displayTitle = formData.custom_title || formData.name;
  
  // Determine if we need to show a fallback background
  const hasHeroImage = Boolean(formData.hero_image);
  
  const heroBackgroundStyle = hasHeroImage
    ? { backgroundImage: `url('${formData.hero_image}')` }
    : {};

  return (
    <div className="space-y-8 bg-white p-6 rounded-lg shadow">
      <div className="text-sm text-gray-500 mb-4">
        This is a preview of how the location page will appear to users. Some elements like contact forms are simplified for preview purposes.
      </div>
      
      {/* Hero Section Preview */}
      <div className="border rounded-lg overflow-hidden">
        <div className="relative bg-gray-900 text-white">
          {/* Background with overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-gray-900/80 to-gray-900/70 z-0"></div>
          
          {hasHeroImage ? (
            <div 
              className="absolute inset-0 bg-cover bg-center opacity-50 z-[-1]"
              style={heroBackgroundStyle}
            ></div>
          ) : (
            <div className="absolute inset-0 bg-gradient-to-br from-gray-900 to-gray-800 z-[-1]">
              {/* Simplified fallback pattern for preview */}
              <div className="absolute inset-0 opacity-10" 
                style={{ 
                  backgroundImage: `radial-gradient(#D00B0E 1px, transparent 2px)`,
                  backgroundSize: '24px 24px'
                }}
              />
            </div>
          )}
          
          <div className="p-8 relative z-10">
            <h1 className="text-3xl font-bold mb-4">{displayTitle}</h1>
            <p className="text-gray-200 mb-6">
              Professional masonry services in {formData.name}. Expert repairs, restoration, and installation.
            </p>
          </div>
        </div>
      </div>
      
      {/* Main Content Preview */}
      <div className="border rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Main Content</h2>
        <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: formData.description }} />
      </div>
      
      {/* Area Description Preview (if available) */}
      {formData.area_description && (
        <div className="border rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Area Information</h2>
          <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: formData.area_description }} />
        </div>
      )}
      
      {/* Gallery Preview (if available) */}
      {formData.gallery_images && formData.gallery_images.length > 0 && (
        <div className="border rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Gallery</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
            {formData.gallery_images.map((image, index) => (
              <img 
                key={index}
                src={image} 
                alt={`Gallery image ${index + 1}`}
                className="h-32 w-full object-cover rounded-md"
              />
            ))}
          </div>
        </div>
      )}
      
      {/* Parent Location Info (if applicable) */}
      {parentLocation && (
        <div className="border rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Part of {parentLocation.name}</h2>
          <p>This location is a sublocation of {parentLocation.name}.</p>
        </div>
      )}
      
      {/* Meta Information */}
      <div className="border rounded-lg p-6 bg-gray-50">
        <h2 className="text-xl font-semibold mb-4">SEO & Meta Information</h2>
        <div className="space-y-2">
          <p><strong>Meta Title:</strong> {formData.meta_title || formData.name}</p>
          <p><strong>Meta Description:</strong> {formData.meta_description}</p>
          <p><strong>URL Slug:</strong> /locations/{formData.slug}</p>
          {formData.canonical_url && <p><strong>Canonical URL:</strong> {formData.canonical_url}</p>}
          {formData.focus_keyword && <p><strong>Focus Keyword:</strong> {formData.focus_keyword}</p>}
        </div>
      </div>
    </div>
  );
};

export default PreviewTab;
