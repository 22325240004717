
import React from 'react';
import { TableRow, TableCell } from "@/components/ui/table";
import { Switch } from "@/components/ui/switch";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import GalleryActions from './GalleryActions';
import ReviewStatusBadge from './ReviewStatusBadge';
import { format } from 'date-fns';
import { ReviewStatus } from '@/hooks/gallery-table/types';
import LazyImage from '@/components/ui/lazy-image';
import { Images } from 'lucide-react';

interface GalleryTableRowProps {
  gallery: {
    id: string;
    title: string;
    slug: string;
    is_public: boolean;
    created_on: string;
    franchise_region: string | null;
    review_status: ReviewStatus;
    cover_image?: string | null;
    job_number?: number | null;
  };
  confirmDeleteId: string | null;
  deleting: boolean;
  onEdit: (id: string) => void;
  onPreview: (slug: string) => void;
  onDelete: (id: string) => void;
  onTogglePublic: (id: string, currentStatus: boolean) => void;
  onUpdateReviewStatus: (id: string, status: ReviewStatus) => void;
  onManagePhotos: (id: string) => void;
  onViewSlideshow: (id: string) => void;
}

const GalleryTableRow: React.FC<GalleryTableRowProps> = ({
  gallery,
  confirmDeleteId,
  deleting,
  onEdit,
  onPreview,
  onDelete,
  onTogglePublic,
  onUpdateReviewStatus,
  onManagePhotos,
  onViewSlideshow
}) => {
  const formattedDate = gallery.created_on 
    ? format(new Date(gallery.created_on), 'MMM d, yyyy')
    : 'Unknown';

  return (
    <TableRow key={gallery.id}>
      <TableCell className="w-[80px]">
        <div 
          className="relative w-16 h-16 rounded overflow-hidden bg-gray-50 cursor-pointer hover:shadow-md transition-shadow"
          onClick={() => onViewSlideshow(gallery.id)}
          title="View Gallery Images"
        >
          {gallery.cover_image ? (
            <LazyImage
              src={gallery.cover_image}
              alt={gallery.title}
              className="h-full w-full object-cover"
              fallbackSrc="/placeholder.svg"
            />
          ) : (
            <div className="flex items-center justify-center h-full w-full bg-gray-100 text-gray-400">
              <Images className="h-6 w-6" />
            </div>
          )}
        </div>
      </TableCell>
      <TableCell className="font-medium max-w-[200px] truncate">
        {gallery.title}
      </TableCell>
      <TableCell className="hidden md:table-cell">
        {gallery.franchise_region || '-'}
      </TableCell>
      <TableCell className="hidden lg:table-cell">
        {formattedDate}
      </TableCell>
      <TableCell className="hidden md:table-cell text-center">
        <Switch
          checked={gallery.is_public}
          onCheckedChange={() => onTogglePublic(gallery.id, gallery.is_public)}
        />
      </TableCell>
      <TableCell className="hidden lg:table-cell">
        <Popover>
          <PopoverTrigger asChild>
            <div className="cursor-pointer">
              <ReviewStatusBadge status={gallery.review_status} />
            </div>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-2">
            <Select
              defaultValue={gallery.review_status}
              onValueChange={(value) => {
                onUpdateReviewStatus(gallery.id, value as ReviewStatus);
              }}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Change status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="pending">Pending</SelectItem>
                <SelectItem value="approved">Approved</SelectItem>
                <SelectItem value="rejected">Rejected</SelectItem>
              </SelectContent>
            </Select>
          </PopoverContent>
        </Popover>
      </TableCell>
      <TableCell>
        <GalleryActions
          id={gallery.id}
          slug={gallery.slug}
          confirmDeleteId={confirmDeleteId}
          deleting={deleting}
          onEdit={onEdit}
          onPreview={onPreview}
          onDelete={onDelete}
          onManagePhotos={onManagePhotos}
          onViewSlideshow={onViewSlideshow}
        />
      </TableCell>
    </TableRow>
  );
};

export default GalleryTableRow;
