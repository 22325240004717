
import { useState, useEffect, useCallback } from 'react';
import { supabase } from '@/lib/supabase';
import { Review } from '@/lib/supabase/reviews';
import { toast } from 'sonner';

export const useReviewsForService = (serviceId?: string | number) => {
  const [loading, setLoading] = useState(true);
  const [allReviews, setAllReviews] = useState<Review[]>([]);
  const [selectedReviews, setSelectedReviews] = useState<Review[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [saving, setSaving] = useState(false);

  // Fetch all reviews and selected reviews for the service
  useEffect(() => {
    let isMounted = true;
    
    const fetchReviews = async () => {
      try {
        setLoading(true);
        
        // Fetch all reviews
        const { data: allReviewsData, error: allReviewsError } = await supabase
          .from('reviews')
          .select('*')
          .order('create_time', { ascending: false });
        
        if (allReviewsError) {
          throw allReviewsError;
        }
        
        // If component is unmounted, don't update state
        if (!isMounted) return;
        
        // Set all reviews first
        setAllReviews(allReviewsData || []);
        
        // If we have a service ID, fetch the service to get its review_ids
        if (serviceId) {
          const { data: serviceData, error: serviceError } = await supabase
            .from('services')
            .select('review_ids')
            .eq('id', serviceId)
            .single();
            
          if (serviceError) {
            console.error('Error fetching service review IDs:', serviceError);
          } else if (serviceData?.review_ids && Array.isArray(serviceData.review_ids)) {
            console.log('Service review_ids from database:', serviceData.review_ids);
            
            // Then filter the reviews based on the IDs stored in the service
            const selectedReviewsList = allReviewsData.filter(review => 
              serviceData.review_ids.includes(review.id)
            );
            
            console.log('Selected reviews count:', selectedReviewsList.length);
            
            // If component is unmounted, don't update state
            if (!isMounted) return;
            
            setSelectedReviews(selectedReviewsList);
          } else {
            console.log('No review_ids found for service or invalid format');
            // If component is unmounted, don't update state
            if (!isMounted) return;
            setSelectedReviews([]);
          }
        } else {
          // If no serviceId provided, clear selected reviews
          if (!isMounted) return;
          setSelectedReviews([]);
        }
      } catch (error) {
        console.error('Error fetching reviews:', error);
        toast.error('Failed to load reviews');
        // If component is unmounted, don't update state
        if (!isMounted) return;
        setAllReviews([]);
        setSelectedReviews([]);
      } finally {
        // If component is unmounted, don't update state
        if (!isMounted) return;
        setLoading(false);
      }
    };

    fetchReviews();
    
    // Cleanup function to handle component unmounting
    return () => {
      isMounted = false;
    };
  }, [serviceId]);

  // Filter reviews based on search term with debounce
  const filteredReviews = useCallback(() => {
    if (!searchTerm.trim()) return allReviews;
    
    const searchTermLower = searchTerm.toLowerCase();
    return allReviews.filter(review => 
      review.reviewer_name.toLowerCase().includes(searchTermLower) || 
      (review.reviewer_comment && review.reviewer_comment.toLowerCase().includes(searchTermLower))
    );
  }, [searchTerm, allReviews]);

  // Toggle a review selection
  const toggleReviewSelection = useCallback((review: Review) => {
    setSelectedReviews(prevSelected => {
      const isSelected = prevSelected.some(r => r.id === review.id);
      
      if (isSelected) {
        return prevSelected.filter(r => r.id !== review.id);
      } else {
        return [...prevSelected, review];
      }
    });
  }, []);

  // Check if a review is selected
  const isReviewSelected = useCallback((reviewId: string) => {
    return selectedReviews.some(review => review.id === reviewId);
  }, [selectedReviews]);

  // Save the selected review IDs to the service
  const saveReviewSelections = async () => {
    if (!serviceId) {
      toast.error('No service ID provided');
      return;
    }
    
    try {
      setSaving(true);
      
      const reviewIds = selectedReviews.map(review => review.id);
      console.log('Saving review IDs:', reviewIds);
      
      const { error } = await supabase
        .from('services')
        .update({ review_ids: reviewIds })
        .eq('id', serviceId);
      
      if (error) {
        console.error('Supabase error details:', error);
        throw error;
      }
      
      toast.success('Reviews updated successfully');
    } catch (error) {
      console.error('Error saving review selections:', error);
      toast.error('Failed to save review selections');
    } finally {
      setSaving(false);
    }
  };

  return {
    loading,
    allReviews,
    filteredReviews: filteredReviews(),
    selectedReviews,
    searchTerm,
    setSearchTerm,
    toggleReviewSelection,
    isReviewSelected,
    saveReviewSelections,
    saving
  };
};
