
import React from 'react';
import Header from '@/components/header';
import HeroSection from '@/components/HeroSection';
import ServicesSection from '@/components/ServicesSection';
import TestimonialsSection from '@/components/TestimonialsSection';
import WhyChooseSection from '@/components/WhyChooseSection';
import CallToActionSection from '@/components/CallToActionSection';
import ContactSection from '@/components/ContactSection';
import Footer from '@/components/Footer';
import PageMeta from '@/components/SEO/PageMeta';
import { useSettings } from '@/hooks/useSettings';

const Index = () => {
  const { settings } = useSettings();
  
  // Create the structured data for a LocalBusiness (more specific than just Organization)
  const homePageSchema = {
    "@type": "LocalBusiness",
    "name": settings.business_name || "Fix My Brick",
    "description": "Professional masonry and brick repair services across Ontario",
    "url": window.location.origin,
    "logo": settings.logo_url,
    "image": settings.home_hero_image || "/og-image.png",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": settings.address
    },
    "telephone": settings.phone_number,
    "email": settings.email,
    "priceRange": "$$",
    "areaServed": "Ontario",
    "openingHours": "Mo-Fr 08:00-17:00",
    "sameAs": [
      settings.facebook_url,
      settings.instagram_url,
      settings.twitter_url
    ].filter(Boolean) // Filter out empty values
  };

  return (
    <div className="min-h-screen flex flex-col">
      <PageMeta 
        canonical="/"
        schema={homePageSchema}
      />
      <Header />
      <main className="flex-grow">
        <HeroSection />
        <TestimonialsSection />
        <ServicesSection />
        <CallToActionSection />
        <WhyChooseSection />
        <ContactSection />
      </main>
      <Footer />
    </div>
  );
};

export default Index;
