
import React from 'react';
import AdminLayout from '@/components/admin/AdminLayout';
import PageActions from '@/components/admin/StaticPages/PageActions';
import PagesTable from '@/components/admin/StaticPages/PagesTable';
import { fetchStaticPages } from '@/lib/supabase';
import { useState, useEffect } from 'react';
import { toast } from 'sonner';

const AdminStaticPages = () => {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPages = async () => {
      try {
        const pagesData = await fetchStaticPages();
        setPages(pagesData);
      } catch (error) {
        console.error('Error loading pages:', error);
        toast.error('Failed to load pages');
      } finally {
        setLoading(false);
      }
    };

    loadPages();
  }, []);

  return (
    <AdminLayout title="Static Pages">
      <PageActions />
      <PagesTable pages={pages} loading={loading} />
    </AdminLayout>
  );
};

export default AdminStaticPages;
