
import React from 'react';

const PaginationLoadingIndicator: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-8">
      <div className="animate-spin h-4 w-4 border-2 border-primary border-t-transparent rounded-full mr-2"></div>
      <span className="text-xs text-muted-foreground">Loading results...</span>
    </div>
  );
};

export default PaginationLoadingIndicator;
