
import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';

export const useFranchiseOptions = () => {
  const [franchiseOptions, setFranchiseOptions] = useState<string[]>([]);

  useEffect(() => {
    const fetchFranchiseOptions = async () => {
      try {
        const { data, error } = await supabase
          .from('galleries')
          .select('franchise_region')
          .not('franchise_region', 'is', null)
          .order('franchise_region');
        
        if (error) throw error;
        
        // Extract unique franchise regions
        const uniqueFranchises = Array.from(
          new Set(
            data
              .map(item => item.franchise_region)
              .filter(Boolean)
          )
        );
        
        setFranchiseOptions(uniqueFranchises);
      } catch (error) {
        console.error('Error fetching franchise options:', error);
      }
    };
    
    fetchFranchiseOptions();
  }, []);

  return franchiseOptions;
};
