
import React from 'react';
import { LocationFormData } from '@/hooks/location/useLocationFormState';
import ImageUploader from './ImageUploader';
import GalleryUploader from './GalleryUploader';

interface ImagesTabProps {
  formData: LocationFormData;
  setFormData: React.Dispatch<React.SetStateAction<LocationFormData>>;
}

const ImagesTab: React.FC<ImagesTabProps> = ({ formData, setFormData }) => {
  const handleHeroImageUploaded = (url: string) => {
    setFormData(prev => ({
      ...prev,
      hero_image: url
    }));
  };

  const handleGalleryImagesChange = (images: string[]) => {
    setFormData(prev => ({
      ...prev,
      gallery_images: images
    }));
  };

  return (
    <div className="space-y-8 bg-white p-6 rounded-lg shadow">
      <ImageUploader 
        currentImageUrl={formData.hero_image}
        onImageUploaded={handleHeroImageUploaded}
        label="Hero Background Image"
      />
      
      <div className="border-t pt-8">
        <GalleryUploader 
          images={formData.gallery_images || []}
          onImagesChange={handleGalleryImagesChange}
        />
      </div>
    </div>
  );
};

export default ImagesTab;
