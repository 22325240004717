import React, {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { SiteSettings } from '@/types/settings';
import {
  GoogleTagManagerScript,
  GoogleTagManagerNoScript,
} from './GoogleTagManager';
import PlausibleAnalytics from './PlausibleAnalytics';
import { dataLayer, DataLayerEventName } from '@/utils/dataLayer';
import { sendServerSideTracking } from '@/lib/supabase/tracking';

interface TrackingContextType {
  isInitialized: boolean;
  trackEvent: (
    eventName: DataLayerEventName,
    eventData: Record<string, any>
  ) => void;
  trackServerSide: (
    platform: 'ga4' | 'facebook',
    eventName: string,
    properties?: Record<string, any>,
    userData?: Record<string, any>
  ) => Promise<{ success: boolean; error?: string }>;
  settings: Partial<SiteSettings> | null;
}

const TrackingContext = createContext<TrackingContextType>({
  isInitialized: false,
  trackEvent: () => {},
  trackServerSide: async () => ({ success: false }),
  settings: null,
});

interface TrackingProviderProps {
  children: ReactNode;
  settings: Partial<SiteSettings>;
}

export const TrackingProvider: React.FC<TrackingProviderProps> = ({
  children,
  settings,
}) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    // Initialize tracking when settings are available
    if (settings) {
      setIsInitialized(true);
    }
  }, [settings]);

  const trackEvent = (
    eventName: DataLayerEventName,
    eventData: Record<string, any>
  ) => {
    if (isInitialized) {
      dataLayer.push({
        event: eventName,
        ...eventData,
      });
    }
  };

  const trackServerSide = async (
    platform: 'ga4' | 'facebook',
    eventName: string,
    properties?: Record<string, any>,
    userData?: Record<string, any>
  ) => {
    if (!isInitialized) {
      return { success: false, error: 'Tracking not initialized' };
    }

    return sendServerSideTracking({
      platform,
      eventName,
      properties,
      userData,
    });
  };

  const value = {
    isInitialized,
    trackEvent,
    trackServerSide,
    settings,
  };

  // Add direct GA4 script if available
  const renderGA4Script = () => {
    if (!settings?.google_analytics_id) return null;
    
    return (
      <>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${settings.google_analytics_id}`} />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${settings.google_analytics_id}');
            `,
          }}
        />
      </>
    );
  };

  return (
    <TrackingContext.Provider value={value}>
      {settings?.gtm_container_id && (
        <GoogleTagManagerScript containerId={settings.gtm_container_id} />
      )}
      {settings?.google_analytics_id && renderGA4Script()}
      {/* Temporarily disabled until the database schema is updated */}
      {/* {settings?.plausible_domain && (
        <PlausibleAnalytics domain={settings.plausible_domain} />
      )} */}
      {children}
      {settings?.gtm_container_id && (
        <GoogleTagManagerNoScript containerId={settings.gtm_container_id} />
      )}
    </TrackingContext.Provider>
  );
};

export const useTracking = () => {
  const context = useContext(TrackingContext);
  if (!context) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }
  return context;
};

// Higher-order component for page tracking
export const withPageTracking = <P extends object>(
  Component: React.ComponentType<P>,
  pageType: string
) => {
  const WithPageTracking: React.FC<P> = (props) => {
    const { isInitialized, trackEvent } = useTracking();

    useEffect(() => {
      if (isInitialized) {
        // Track page view when component mounts
        trackEvent('page_view', {
          page_title: document.title,
          page_path: window.location.pathname,
          page_type: pageType,
        });
      }
    }, [isInitialized, trackEvent]);

    return <Component {...props} />;
  };

  return WithPageTracking;
};
