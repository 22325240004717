
import React from 'react';
import AdminLayout from '@/components/admin/AdminLayout';
import DashboardCards from '@/components/admin/dashboard/DashboardCards';
import RecentActivity from '@/components/admin/dashboard/RecentActivity';
import { useDashboardData } from '@/hooks/useDashboardData';

const AdminDashboard = () => {
  const { counts, recentActivity, loading } = useDashboardData();

  return (
    <AdminLayout title="Dashboard">
      <DashboardCards counts={counts} loading={loading} />
      
      <div className="mt-8">
        <RecentActivity recentActivity={recentActivity} loading={loading} />
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;
