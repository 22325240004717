
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import { Upload, X, Image as ImageIcon, FileText, File as FileIcon } from 'lucide-react';
import { Dialog, DialogContent } from '@/components/ui/dialog';

interface FileUploadFieldProps {
  files: File[];
  onFilesChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveFile: (index: number) => void;
}

const FileUploadField: React.FC<FileUploadFieldProps> = ({
  files,
  onFilesChange,
  onRemoveFile
}) => {
  const [previewFile, setPreviewFile] = useState<{file: File, url: string} | null>(null);
  
  const openPreview = (file: File) => {
    if (file.type.startsWith('image/')) {
      setPreviewFile({
        file,
        url: URL.createObjectURL(file)
      });
    }
  };

  const closePreview = () => {
    if (previewFile) {
      URL.revokeObjectURL(previewFile.url);
      setPreviewFile(null);
    }
  };

  const getFileIcon = (file: File) => {
    if (file.type.startsWith('image/')) {
      return <ImageIcon className="h-4 w-4 mr-2 text-blue-500" />;
    } else if (file.type === 'application/pdf') {
      return <FileText className="h-4 w-4 mr-2 text-red-500" />;
    } else {
      return <FileIcon className="h-4 w-4 mr-2 text-gray-500" />;
    }
  };

  const formatFileSize = (bytes: number): string => {
    if (bytes < 1024) return bytes + ' B';
    if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + ' KB';
    return (bytes / (1024 * 1024)).toFixed(1) + ' MB';
  };
  
  return (
    <div className="space-y-2">
      <label className="block text-sm text-gray-600">Upload photos (optional, max 5)</label>
      <div className="border-2 border-dashed border-gray-300 rounded-lg p-4 text-center cursor-pointer hover:bg-gray-50 transition-colors">
        <input
          type="file"
          id="photos"
          name="photos"
          accept="image/jpeg,image/png,image/webp,application/pdf"
          className="hidden"
          onChange={onFilesChange}
          multiple
        />
        <label htmlFor="photos" className="cursor-pointer flex flex-col items-center">
          <Upload className="h-6 w-6 text-gray-400 mb-2" />
          <span className="text-sm text-gray-700">Click to upload or drag and drop</span>
          <span className="text-xs text-gray-500 mt-1">jpeg, png, webp, pdf (Max: 15MB each)</span>
        </label>
      </div>
      
      {/* Preview of uploaded files */}
      {files.length > 0 && (
        <div className="mt-4">
          <p className="text-sm font-medium mb-2">Selected Files ({files.length}/5):</p>
          <div className="space-y-2 max-h-[30vh] overflow-y-auto">
            {files.map((file, index) => (
              <div key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded">
                <div className="flex items-center overflow-hidden">
                  {getFileIcon(file)}
                  <div>
                    <span className="text-sm truncate max-w-[150px] md:max-w-[200px] inline-block">{file.name}</span>
                    <span className="text-xs text-gray-500 block">{formatFileSize(file.size)}</span>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  {file.type.startsWith('image/') && (
                    <Button 
                      type="button" 
                      variant="ghost" 
                      size="sm" 
                      onClick={() => openPreview(file)}
                      className="h-8 w-8 p-0"
                      aria-label="Preview image"
                    >
                      <ImageIcon className="h-4 w-4 text-blue-500" />
                    </Button>
                  )}
                  <Button 
                    type="button" 
                    variant="ghost" 
                    size="sm" 
                    onClick={() => onRemoveFile(index)}
                    className="h-8 w-8 p-0"
                    aria-label="Remove file"
                  >
                    <X className="h-4 w-4 text-gray-500" />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Image preview dialog - portal to body to work in modals */}
      <Dialog 
        open={!!previewFile} 
        onOpenChange={(open) => !open && closePreview()}
      >
        <DialogContent 
          className="max-w-3xl" 
          onInteractOutside={(e) => e.preventDefault()}
          onEscapeKeyDown={(e) => {
            // Prevent the escape key from closing outer modals
            e.stopPropagation();
            e.preventDefault();
            closePreview();
          }}
        >
          {previewFile && (
            <div className="flex flex-col items-center">
              <h3 className="text-lg font-medium mb-4 text-center">{previewFile.file.name}</h3>
              <div className="relative w-full max-h-[60vh] overflow-auto">
                <img 
                  src={previewFile.url}
                  alt={previewFile.file.name}
                  className="mx-auto max-w-full h-auto"
                />
              </div>
              <p className="text-sm text-gray-500 mt-4">
                {formatFileSize(previewFile.file.size)} • {previewFile.file.type}
              </p>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FileUploadField;
