
import React from 'react';

const FormHeader: React.FC = () => {
  return (
    <div className="bg-brick text-white p-4 text-center rounded-t-md">
      <h3 className="text-xl font-bold md:text-2xl">Get a Free Quote</h3>
    </div>
  );
};

export default FormHeader;
