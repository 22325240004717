
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { GalleryFormValues } from '@/components/admin/galleries/form/types';

export const useGalleryFormSubmission = (
  gallery: GalleryFormValues,
  validateSlug: (slug: string) => Promise<boolean>,
  isEditing: boolean,
  id?: string
) => {
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!gallery.title) {
      toast.error('Please enter a gallery title');
      return;
    }
    
    if (!gallery.slug) {
      toast.error('Please enter a gallery slug');
      return;
    }
    
    const isSlugValid = await validateSlug(gallery.slug);
    if (!isSlugValid) return;
    
    await handleSaveGallery(gallery);
  };

  const handleSaveGallery = async (galleryData: GalleryFormValues) => {
    try {
      setSaving(true);

      if (!isEditing) {
        const { error } = await supabase
          .from('galleries')
          .insert([{
            ...galleryData,
            created_on: new Date().toISOString()
          }]);

        if (error) {
          throw error;
        }

        toast.success('Gallery created successfully');
        navigate('/admin/galleries');
      } else {
        const { error } = await supabase
          .from('galleries')
          .update(galleryData)
          .eq('id', id);

        if (error) {
          throw error;
        }

        toast.success('Gallery updated successfully');
        navigate('/admin/galleries');
      }
    } catch (error) {
      console.error('Error saving gallery:', error);
      toast.error('Failed to save gallery');
    } finally {
      setSaving(false);
    }
  };

  const navigateBack = () => {
    navigate('/admin/galleries');
  };

  return {
    saving,
    handleSubmit,
    handleSaveGallery,
    navigateBack
  };
};
