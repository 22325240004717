import React from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { Plus, Filter } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ReviewStatus } from '@/hooks/gallery-table/types';

interface GallerySearchProps {
  searchQuery: string;
  onSearchChange: (query: string) => void;
  filterPublic: boolean | null;
  onFilterChange: (status: boolean | null) => void;
  filterFranchise: string | null;
  onFranchiseFilterChange: (franchise: string | null) => void;
  franchiseOptions: string[];
  filterReviewStatus: ReviewStatus | null;
  onReviewStatusFilterChange: (status: ReviewStatus | null) => void;
}

const GallerySearch = ({ 
  searchQuery, 
  onSearchChange, 
  filterPublic,
  onFilterChange,
  filterFranchise,
  onFranchiseFilterChange,
  franchiseOptions,
  filterReviewStatus,
  onReviewStatusFilterChange
}: GallerySearchProps) => {
  return (
    <div className="mb-4 space-y-4">
      <div className="flex items-center justify-between">
        <Input
          type="text"
          placeholder="Search galleries..."
          value={searchQuery}
          onChange={(e) => onSearchChange(e.target.value)}
          className="max-w-md"
        />
        <Button asChild>
          <Link to="/admin/galleries/new" className="flex items-center">
            <Plus className="mr-2 h-4 w-4" />
            Add Gallery
          </Link>
        </Button>
      </div>
      
      <div className="flex items-center gap-4 flex-wrap">
        <div className="flex items-center gap-2">
          <Filter className="h-4 w-4 text-muted-foreground" />
          <span className="text-sm font-medium">Status:</span>
          <Select
            value={filterPublic === null ? "all" : filterPublic ? "public" : "private"}
            onValueChange={(value) => {
              if (value === "all") onFilterChange(null);
              else if (value === "public") onFilterChange(true);
              else onFilterChange(false);
            }}
          >
            <SelectTrigger className="w-[120px]">
              <SelectValue placeholder="Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All</SelectItem>
              <SelectItem value="public">Public</SelectItem>
              <SelectItem value="private">Private</SelectItem>
            </SelectContent>
          </Select>
        </div>
        
        <div className="flex items-center gap-2">
          <Filter className="h-4 w-4 text-muted-foreground" />
          <span className="text-sm font-medium">Franchise:</span>
          <Select
            value={filterFranchise || "all"}
            onValueChange={(value) => {
              onFranchiseFilterChange(value === "all" ? null : value);
            }}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select Franchise" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All Franchises</SelectItem>
              {franchiseOptions.map((franchise) => (
                <SelectItem key={franchise} value={franchise}>
                  {franchise}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="flex items-center gap-2">
          <Filter className="h-4 w-4 text-muted-foreground" />
          <span className="text-sm font-medium">Review:</span>
          <Select
            value={filterReviewStatus || "all"}
            onValueChange={(value) => {
              onReviewStatusFilterChange(value === "all" ? null : value as ReviewStatus);
            }}
          >
            <SelectTrigger className="w-[150px]">
              <SelectValue placeholder="Review Status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">All</SelectItem>
              <SelectItem value="pending">Pending</SelectItem>
              <SelectItem value="approved">Approved</SelectItem>
              <SelectItem value="rejected">Rejected</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default GallerySearch;
