import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error: any) => {
        // Don't retry aborted requests
        if (error.name === 'AbortError' || error.message?.includes('aborted')) {
          return false;
        }
        return failureCount < 2; // Retry other errors twice
      },
    },
  },
});
