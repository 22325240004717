import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Home } from 'lucide-react';
import { useSettings } from '@/hooks/useSettings';
import { Helmet } from 'react-helmet-async';

export interface Breadcrumb {
  label: string;
  path: string;
  active?: boolean;
}

interface BreadcrumbsProps {
  items: Breadcrumb[];
  className?: string;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items = [], className = '' }) => {
  const { settings } = useSettings();
  
  // Skip rendering if breadcrumbs are explicitly disabled in settings
  // Default to enabled if the field doesn't exist yet
  if (settings.enable_breadcrumbs === false) return null;
  
  // Ensure there's a home item at the start
  const breadcrumbItems = items.length > 0 && items[0].path !== '/' 
    ? [{ label: 'Home', path: '/' }, ...items]
    : items;
  
  // Create structured data for breadcrumbs
  const breadcrumbsSchema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': breadcrumbItems.map((item, index) => ({
      '@type': 'ListItem',
      'position': index + 1,
      'item': {
        '@id': `${window.location.origin}${item.path}`,
        'name': item.label
      }
    }))
  };
  
  return (
    <>
      {/* Add structured data - default to enabled if field doesn't exist yet */}
      {settings.enable_structured_data !== false && (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(breadcrumbsSchema)}
          </script>
        </Helmet>
      )}
      
      {/* Render visible breadcrumbs */}
      <nav aria-label="Breadcrumb" className={`py-2 text-sm ${className}`}>
        <ol className="flex flex-wrap items-center space-x-1">
          {breadcrumbItems.map((item, index) => {
            const isLast = index === breadcrumbItems.length - 1;
            
            return (
              <li key={item.path} className="flex items-center">
                {index > 0 && (
                  <ChevronRight className="h-4 w-4 mx-1 text-gray-400" />
                )}
                
                {isLast ? (
                  <span className="text-gray-500 font-medium" aria-current="page">
                    {item.label}
                  </span>
                ) : (
                  <Link
                    to={item.path}
                    className="text-blue-600 hover:text-blue-800 flex items-center"
                  >
                    {index === 0 && <Home className="h-3.5 w-3.5 mr-1" />}
                    {item.label}
                  </Link>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
    </>
  );
};

export default Breadcrumbs;