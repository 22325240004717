
import { useState } from 'react';
import { useFetchGalleries } from './useFetchGalleries';
import { useFranchiseOptions } from './useFranchiseOptions';
import { useGalleryActions } from './useGalleryActions';
import { SortField, SortDirection, ReviewStatus, UseGalleriesTableReturn } from './types';

export const useGalleriesTable = (): UseGalleriesTableReturn => {
  // State management
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState<SortField>('created_on');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [filterPublic, setFilterPublic] = useState<boolean | null>(null);
  const [filterFranchise, setFilterFranchise] = useState<string | null>(null);
  const [filterReviewStatus, setFilterReviewStatus] = useState<ReviewStatus | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Get franchise options
  const franchiseOptions = useFranchiseOptions();

  // Fetch galleries data
  const { galleries, loading, totalCount, fetchGalleries } = useFetchGalleries(
    sortField,
    sortDirection,
    filterPublic,
    filterFranchise,
    filterReviewStatus,
    currentPage,
    pageSize
  );

  // Gallery actions (edit, delete, etc.)
  const {
    deleting,
    confirmDeleteId,
    handleEditGallery,
    handlePreviewGallery,
    handleDeleteGallery,
    handleTogglePublic,
    handleUpdateReviewStatus,
    handleManagePhotos
  } = useGalleryActions(galleries, fetchGalleries);

  // Sorting function
  const handleSort = (field: SortField) => {
    // If clicking the same field, toggle direction
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // If clicking a new field, set it as the sort field with default desc direction
      setSortField(field);
      setSortDirection('desc');
    }
  };

  // Apply search filter in-memory (could be moved to backend for larger datasets)
  const filteredGalleries = galleries
    .filter(gallery => 
      gallery.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (gallery.franchise_region && gallery.franchise_region.toLowerCase().includes(searchQuery.toLowerCase()))
    );

  return {
    galleries,
    filteredGalleries,
    loading,
    deleting,
    confirmDeleteId,
    searchQuery,
    setSearchQuery,
    sortField,
    sortDirection,
    handleSort,
    filterPublic,
    setFilterPublic,
    filterFranchise,
    setFilterFranchise,
    filterReviewStatus,
    setFilterReviewStatus,
    franchiseOptions,
    handleEditGallery,
    handlePreviewGallery,
    handleDeleteGallery,
    handleTogglePublic,
    handleUpdateReviewStatus,
    handleManagePhotos,
    totalCount,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize
  };
};
