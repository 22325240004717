
import React from 'react';

interface LocationBackgroundFallbackProps {
  locationName: string;
}

/**
 * Provides a fallback background for locations without hero images
 * Uses a gradient pattern that's visually appealing and consistent with brand colors
 */
const LocationBackgroundFallback: React.FC<LocationBackgroundFallbackProps> = ({ locationName }) => {
  // Create a consistent but somewhat unique gradient based on location name
  // This ensures different locations get slightly different patterns
  const hash = locationName.split('').reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  
  // Generate a slight variation in the red accent color based on location name
  const accentHue = 0; // Keep the hue at red (0)
  const accentSat = 80 + (hash % 20); // Saturation between 80-100%
  const accentLight = 40 + (hash % 10); // Lightness between 40-50%
  
  return (
    <>
      {/* Background gradient with subtle pattern */}
      <div 
        className="absolute inset-0 bg-gradient-to-br from-gray-900 to-gray-800 z-[-2]" 
        data-image-source="fallback"
      >
        {/* Create a subtle pattern overlay */}
        <div className="absolute inset-0 opacity-10" 
          style={{ 
            backgroundImage: `radial-gradient(hsl(${accentHue}, ${accentSat}%, ${accentLight}%) 1px, transparent 2px)`,
            backgroundSize: '24px 24px'
          }}
        />
      </div>
      
      {/* Accent gradient overlay */}
      <div 
        className="absolute inset-0 opacity-20 z-[-1]" 
        style={{ 
          background: `linear-gradient(135deg, hsl(${accentHue}, ${accentSat}%, ${accentLight}%) 0%, transparent 50%)` 
        }}
      />
      
      {/* Dark gradient overlay to ensure text readability */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900/40 to-gray-900/30 z-[-1]"></div>
    </>
  );
};

export default LocationBackgroundFallback;
