
import { supabase } from '../client';

// Fetch blog tags
export const fetchTags = async () => {
  const { data, error } = await supabase
    .from('blog_tags')
    .select('*')
    .order('name');

  if (error) {
    console.error('Error fetching tags:', error);
    throw error;
  }

  return data;
};

// Tags CRUD operations
export const createTag = async (data: { name: string; slug: string; description?: string }) => {
  const { data: newTag, error } = await supabase
    .from('blog_tags')
    .insert([data])
    .select()
    .single();

  if (error) throw error;
  return newTag;
};

export const updateTag = async (id: string, data: { name?: string; slug?: string; description?: string }) => {
  const { data: updatedTag, error } = await supabase
    .from('blog_tags')
    .update(data)
    .eq('id', id)
    .select()
    .single();

  if (error) throw error;
  return updatedTag;
};

export const deleteTag = async (id: string) => {
  const { error } = await supabase
    .from('blog_tags')
    .delete()
    .eq('id', id);

  if (error) throw error;
  return true;
};

// Post-tag relationships
export const updatePostTags = async (postId: string, tagIds: string[]) => {
  // First delete existing relationships
  await supabase
    .from('blog_post_tags')
    .delete()
    .eq('post_id', postId);

  // Then insert new ones
  if (tagIds.length > 0) {
    const { error } = await supabase
      .from('blog_post_tags')
      .insert(tagIds.map(tagId => ({
        post_id: postId,
        tag_id: tagId
      })));

    if (error) throw error;
  }

  return true;
};
