import { useQuery } from '@tanstack/react-query';
import { fetchServiceBySlug } from '@/lib/supabase/services';
import { ServiceDetail } from '@/types/service';

/**
 * Custom hook to fetch a service with proper caching using React Query
 */
export const useServiceQuery = (slug: string) => {
  return useQuery({
    queryKey: ['service', slug],
    queryFn: ({ signal }) => fetchServiceBySlug(slug, signal),
    enabled: !!slug,
    retry: (failureCount, error: any) => {
      // Don't retry aborted requests
      if (error.name === 'AbortError' || error.message?.includes('aborted')) {
        return false;
      }
      return failureCount < 2; // Retry other errors twice
    },
    gcTime: 5 * 60 * 1000, // Keep data in cache for 5 minutes
  });
};

/**
 * Custom hook to fetch all services with proper caching
 */
export const useServicesQuery = () => {
  return useQuery({
    queryKey: ['services'],
    queryFn: async ({ signal }) => {
      try {
        const { fetchServices } = await import('@/lib/supabase/services');
        return await fetchServices(signal);
      } catch (error: any) {
        // Handle abort errors silently
        if (
          signal?.aborted ||
          error.name === 'AbortError' ||
          error.message?.includes('abort') ||
          error.message?.includes('cancel')
        ) {
          console.log('Services query aborted:', error.message);
          // Rethrow the error with consistent format
          throw new Error('Request aborted');
        }
        // Log other non-abort errors
        console.error('Error fetching services:', error);
        throw error;
      }
    },
    retry: (failureCount, error: any) => {
      // More comprehensive check for aborted requests
      if (
        error.name === 'AbortError' || 
        error.message?.includes('abort') || 
        error.message?.includes('cancel')
      ) {
        return false; // Don't retry aborted requests
      }
      
      // For server errors or network issues, retry up to twice
      if (error.status >= 500 || error.message?.includes('network')) {
        return failureCount < 2;
      }
      
      // For other errors, no retry
      return false;
    },
    gcTime: 5 * 60 * 1000, // Keep data in cache for 5 minutes
  });
};
