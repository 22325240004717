import React, { useState } from 'react';
import AdminLayout from '@/components/admin/AdminLayout';
import { useSettings } from '@/hooks/useSettings';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { LogoCropDialog } from '@/components/admin/settings/logo-crop';
import {
  ContactInfoTab,
  BrandingTab,
  SiteIdentityTab,
  HeroBackgroundTab,
  AnalyticsTab,
  TrackingTab,
  GTMTab,
  SEOTab,
  SettingsLoadingState,
} from '@/components/admin/settings';

const AdminSettings = () => {
  const { settings, loading, saving, handleChange, updateSettings } =
    useSettings();
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [uploadingFavicon, setUploadingFavicon] = useState(false);
  const [showCropDialog, setShowCropDialog] = useState(false);
  const [tempLogoUrl, setTempLogoUrl] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await updateSettings(settings);
  };

  const handleLogoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (file.size > 2 * 1024 * 1024) {
      toast.error('Logo file is too large. Maximum size is 2MB.');
      return;
    }

    if (!file.type.startsWith('image/')) {
      toast.error('Please upload an image file for the logo.');
      return;
    }

    try {
      setUploadingLogo(true);

      const tempUrl = URL.createObjectURL(file);
      setTempLogoUrl(tempUrl);
      setShowCropDialog(true);
    } catch (error) {
      console.error('Error processing logo:', error);
      toast.error('Failed to process logo. Please try again.');
    } finally {
      setUploadingLogo(false);
    }
  };

  const handleCropSave = async (croppedImageUrl: string, logoSize: number) => {
    try {
      setUploadingLogo(true);

      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();

      const fileName = `logo-${Date.now()}.png`;
      const file = new File([blob], fileName, { type: 'image/png' });

      const { error: uploadError } = await supabase.storage
        .from('site-assets')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      const { data: publicUrlData } = supabase.storage
        .from('site-assets')
        .getPublicUrl(fileName);

      if (publicUrlData) {
        handleChange('logo_url', publicUrlData.publicUrl);
        handleChange('logo_size', logoSize.toString());
        toast.success('Logo updated successfully');
      }

      URL.revokeObjectURL(croppedImageUrl);
      URL.revokeObjectURL(tempLogoUrl);
      setTempLogoUrl('');

      setShowCropDialog(false);
    } catch (error) {
      console.error('Error uploading cropped logo:', error);
      toast.error('Failed to upload cropped logo. Please try again.');
    } finally {
      setUploadingLogo(false);
    }
  };

  const handleOpenCropDialog = () => {
    if (settings.logo_url) {
      setTempLogoUrl(settings.logo_url);
      setShowCropDialog(true);
    } else {
      toast.error('Please upload a logo first before cropping.');
    }
  };

  const handleFaviconUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (file.size > 1 * 1024 * 1024) {
      toast.error('Favicon file is too large. Maximum size is 1MB.');
      return;
    }

    if (
      ![
        'image/png',
        'image/x-icon',
        'image/vnd.microsoft.icon',
        'image/ico',
      ].includes(file.type)
    ) {
      toast.error('Please upload a valid favicon file (ICO or PNG).');
      return;
    }

    try {
      setUploadingFavicon(true);

      const fileName = `favicon-${Date.now()}.${file.name.split('.').pop()}`;
      const { error: uploadError } = await supabase.storage
        .from('site-assets')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      const { data: publicUrlData } = supabase.storage
        .from('site-assets')
        .getPublicUrl(fileName);

      if (publicUrlData) {
        // Add a cache-busting query parameter to prevent browser caching
        const cacheBustUrl = `${publicUrlData.publicUrl}?t=${Date.now()}`;
        handleChange('favicon_url', cacheBustUrl);
        toast.success('Favicon uploaded successfully');
      }
    } catch (error) {
      console.error('Error uploading favicon:', error);
      toast.error('Failed to upload favicon. Please try again.');
    } finally {
      setUploadingFavicon(false);
    }
  };

  if (loading) {
    return (
      <AdminLayout title='Site Settings'>
        <SettingsLoadingState />
      </AdminLayout>
    );
  }

  return (
    <AdminLayout title='Site Settings'>
      <form onSubmit={handleSubmit}>
        <Tabs defaultValue='contact'>
          <TabsList className='mb-6'>
            <TabsTrigger value='contact'>Contact Info</TabsTrigger>
            <TabsTrigger value='identity'>Site Identity</TabsTrigger>
            <TabsTrigger value='branding'>Branding</TabsTrigger>
            <TabsTrigger value='hero'>Hero Background</TabsTrigger>
            <TabsTrigger value='seo'>SEO</TabsTrigger>
            <TabsTrigger value='analytics'>Analytics</TabsTrigger>
            <TabsTrigger value='tracking'>Tracking</TabsTrigger>
            <TabsTrigger value='gtm'>Tag Manager</TabsTrigger>
          </TabsList>

          <TabsContent value='contact'>
            <ContactInfoTab
              settings={settings}
              saving={saving}
              handleChange={handleChange}
            />
          </TabsContent>

          <TabsContent value='identity'>
            <SiteIdentityTab
              settings={settings}
              saving={saving}
              handleChange={handleChange}
            />
          </TabsContent>

          <TabsContent value='branding'>
            <BrandingTab
              settings={settings}
              saving={saving}
              uploadingLogo={uploadingLogo}
              uploadingFavicon={uploadingFavicon}
              handleLogoUpload={handleLogoUpload}
              handleFaviconUpload={handleFaviconUpload}
              handleOpenCropDialog={handleOpenCropDialog}
            />
          </TabsContent>

          <TabsContent value='hero'>
            <HeroBackgroundTab
              settings={settings}
              saving={saving}
              handleChange={handleChange}
            />
          </TabsContent>

          <TabsContent value='analytics'>
            <AnalyticsTab
              settings={settings}
              saving={saving}
              handleChange={handleChange}
            />
          </TabsContent>

          <TabsContent value='tracking'>
            <TrackingTab
              settings={settings}
              saving={saving}
              handleChange={handleChange}
            />
          </TabsContent>

          <TabsContent value='seo'>
            <SEOTab
              settings={settings}
              saving={saving}
              handleChange={handleChange}
            />
          </TabsContent>

          <TabsContent value='gtm'>
            <GTMTab
              settings={settings}
              saving={saving}
              handleChange={handleChange}
            />
          </TabsContent>
        </Tabs>

        <div className='flex justify-end mt-6'>
          <Button
            type='submit'
            disabled={saving || uploadingLogo || uploadingFavicon}>
            {saving ? 'Saving...' : 'Save Settings'}
          </Button>
        </div>
      </form>

      {showCropDialog && (
        <LogoCropDialog
          open={showCropDialog}
          onClose={() => {
            setShowCropDialog(false);
            if (tempLogoUrl && tempLogoUrl !== settings.logo_url) {
              URL.revokeObjectURL(tempLogoUrl);
              setTempLogoUrl('');
            }
          }}
          imageUrl={tempLogoUrl || settings.logo_url}
          onSave={handleCropSave}
        />
      )}
    </AdminLayout>
  );
};

export default AdminSettings;
