
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';

export const useBlogPostData = (slug?: string) => {
  const navigate = useNavigate();
  const [post, setPost] = useState<any | null>(null);
  const [relatedPosts, setRelatedPosts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPostData = async () => {
      if (!slug) return;
      
      try {
        setLoading(true);
        
        // Fetch post
        const { data, error: postError } = await supabase
          .from('blog_posts')
          .select(`
            *,
            blog_categories(id, name, slug),
            blog_tags(id, name, slug)
          `)
          .eq('slug', slug)
          .eq('status', 'published')
          .single();
          
        if (postError) throw postError;
        if (!data) {
          setError('Post not found');
          setLoading(false);
          return;
        }
        
        setPost(data);
        
        // Fetch related posts based on categories
        if (data.category_ids && data.category_ids.length > 0) {
          const { data: relatedData, error: relatedError } = await supabase
            .from('blog_posts')
            .select(`
              id,
              title,
              slug,
              excerpt,
              featured_image,
              published_at
            `)
            .eq('status', 'published')
            .neq('id', data.id)
            .overlaps('category_ids', data.category_ids)
            .order('published_at', { ascending: false })
            .limit(3);
            
          if (!relatedError && relatedData) {
            setRelatedPosts(relatedData);
          }
        }
        
      } catch (err) {
        console.error('Error fetching post data:', err);
        setError('Failed to load blog post. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchPostData();
  }, [slug, navigate]);

  return { post, relatedPosts, loading, error };
};
