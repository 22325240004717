
import React from 'react';
import { Link } from 'react-router-dom';

interface PostCardProps {
  post: {
    id: string;
    title: string;
    slug: string;
    excerpt?: string;
    published_at?: string;
    featured_image?: string;
    blog_categories?: Array<{
      id: string;
      name: string;
      slug: string;
    }>;
  };
}

const PostCard: React.FC<PostCardProps> = ({ post }) => {
  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      {post.featured_image && (
        <div className="h-60 overflow-hidden">
          <img 
            src={post.featured_image} 
            alt={post.title} 
            className="w-full h-full object-cover"
          />
        </div>
      )}
      <div className="p-6">
        <div className="flex flex-wrap gap-2 mb-3">
          {post.blog_categories && post.blog_categories.map((category) => (
            <Link 
              key={category.id} 
              to={`/blog/category/${category.slug}`}
              className="text-sm bg-gray-100 hover:bg-gray-200 text-gray-700 px-3 py-1 rounded-full"
            >
              {category.name}
            </Link>
          ))}
        </div>
        <h2 className="text-2xl font-bold mb-2">
          <Link to={`/blog/${post.slug}`} className="hover:text-brick">
            {post.title}
          </Link>
        </h2>
        {post.published_at && (
          <p className="text-gray-500 text-sm mb-3">
            {new Date(post.published_at).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </p>
        )}
        {post.excerpt && (
          <p className="text-gray-700 mb-4">{post.excerpt}</p>
        )}
        <Link 
          to={`/blog/${post.slug}`} 
          className="inline-flex items-center text-brick hover:text-brick-dark font-medium"
        >
          Read more
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-4 w-4 ml-2" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M14 5l7 7m0 0l-7 7m7-7H3" 
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default PostCard;
