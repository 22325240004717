
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';

const PageActions: React.FC = () => {
  const navigate = useNavigate();
  
  return (
    <div className="flex justify-between items-center mb-6">
      <h2 className="text-xl font-semibold">Manage Website Content</h2>
      <Button onClick={() => navigate('/admin/pages/new')}>
        <Plus className="mr-2 h-4 w-4" /> Add New Page
      </Button>
    </div>
  );
};

export default PageActions;
