
import React, { useState, useRef } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { EditorToolbar } from './editor/EditorToolbar';
import { EditorContent } from './editor/EditorContent';
import { EditorTips } from './editor/EditorTips';
import { processEditorAction } from './editor/EditorHelper';

interface RichTextEditorProps {
  content: string;
  onChange: (value: string) => void;
}

export const RichTextEditor = ({ content, onChange }: RichTextEditorProps) => {
  const [selectedText, setSelectedText] = useState<{ start: number; end: number } | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [editorView, setEditorView] = useState<'code' | 'normal'>('normal');

  const handleToolbarAction = (action: string) => {
    if (!textareaRef.current) return;
    
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    
    // Save the current selection for future reference
    setSelectedText({ start, end });
    
    const newContent = processEditorAction(action, content, start, end);
    onChange(newContent);
    
    // Reset the selection after the content is updated
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
      }
    }, 0);
  };

  return (
    <div className="space-y-4">
      <Tabs value={editorView} onValueChange={(value) => setEditorView(value as 'code' | 'normal')} className="w-full">
        <TabsList className="grid w-40 grid-cols-2">
          <TabsTrigger value="normal">Normal</TabsTrigger>
          <TabsTrigger value="code">Code View</TabsTrigger>
        </TabsList>
      </Tabs>
      
      <EditorToolbar onToolbarAction={handleToolbarAction} />
      
      <EditorContent 
        content={content}
        onChange={onChange}
        editorView={editorView}
        textareaRef={textareaRef}
      />
      
      <EditorTips />
    </div>
  );
};
