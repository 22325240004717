import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from '@/components/Layout';
import { useServicesQuery } from '@/hooks/useServiceQuery';
import { Card, CardContent } from '@/components/ui/card';
import PageMeta from '@/components/SEO/PageMeta';
import { usePrimaryLocationsQuery } from '@/hooks/useLocationQuery';
import LocationCard from '@/components/location/LocationCard';
import { toast } from 'sonner';

const Locations = () => {
  const { data: services, isLoading: servicesLoading } = useServicesQuery();
  const {
    data: primaryLocations = [],
    isLoading: primaryLocationsLoading,
    error: primaryLocationsError,
  } = usePrimaryLocationsQuery();

  // Show error toast if primary locations fail to load
  React.useEffect(() => {
    if (primaryLocationsError) {
      toast.error('Failed to load locations. Please try again later.');
    }
  }, [primaryLocationsError]);

  return (
    <Layout>
      <PageMeta
        title='Service Areas | Fix My Brick'
        description='Fix My Brick provides professional masonry services across Ontario. Find your location and get expert brick and stone services near you.'
        canonical='/locations'
        type='website'
      />

      <div className='bg-gray-900 text-white relative'>
        {/* Background with overlay */}
        <div className='absolute inset-0 bg-gradient-to-b from-gray-900/80 to-gray-900/70 z-0'></div>
        <div
          className='absolute inset-0 bg-cover bg-center opacity-50 z-[-1]'
          style={{ backgroundImage: "url('/placeholder.svg')" }}></div>

        <div className='container mx-auto px-4 py-20 relative z-10'>
          <div className='max-w-3xl mx-auto text-center'>
            <h1 className='text-4xl md:text-5xl font-bold mb-4'>
              Our Service Areas
            </h1>
            <p className='text-xl text-gray-200 mb-6'>
              Professional masonry services across Ontario
            </p>
          </div>
        </div>
      </div>

      <section className='py-16 bg-white'>
        <div className='container mx-auto px-4'>
          <div className='max-w-3xl mx-auto text-center mb-12'>
            <h2 className='text-3xl font-bold mb-4'>
              Find Services in Your Area
            </h2>
            <p className='text-gray-600'>
              Fix My Brick provides expert masonry services throughout Ontario.
              Choose your location to learn more about our services in your
              area.
            </p>
          </div>

          {primaryLocationsLoading ? (
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
              {[1, 2, 3].map((i) => (
                <Card
                  key={i}
                  className='overflow-hidden h-full shadow-md animate-pulse'>
                  <CardContent className='p-6 flex flex-col h-full'>
                    <div className='flex items-center mb-4'>
                      <div className='h-6 w-6 bg-gray-200 rounded-full mr-2'></div>
                      <div className='h-6 w-32 bg-gray-200 rounded'></div>
                    </div>
                    <div className='h-4 w-48 bg-gray-200 rounded mb-4'></div>
                    <div className='space-y-2 mb-4'>
                      <div className='h-3 w-32 bg-gray-200 rounded'></div>
                      <div className='h-3 w-40 bg-gray-200 rounded'></div>
                      <div className='h-3 w-36 bg-gray-200 rounded'></div>
                    </div>
                    <div className='mt-auto'>
                      <div className='h-10 w-32 bg-gray-200 rounded'></div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          ) : (
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
              {/* Use the LocationCard component instead of inline JSX */}
              {primaryLocations.map((location) => (
                <LocationCard key={location.slug} location={location} />
              ))}
            </div>
          )}
        </div>
      </section>

      {!servicesLoading && services && services.length > 0 && (
        <section className='py-16 bg-gray-50'>
          <div className='container mx-auto px-4'>
            <div className='max-w-3xl mx-auto text-center mb-12'>
              <h2 className='text-3xl font-bold mb-4'>Our Masonry Services</h2>
              <p className='text-gray-600'>
                We offer a wide range of professional masonry services
                throughout our service areas.
              </p>
            </div>

            <div className='flex flex-wrap justify-center gap-4'>
              {services.slice(0, 6).map((service) => (
                <Link
                  key={service.slug}
                  to={`/services/${service.slug}`}
                  className='bg-white shadow-md rounded-md px-6 py-3 text-gray-800 hover:bg-gray-100 transition-colors'>
                  {service.title}
                </Link>
              ))}

              <Link
                to='/services'
                className='bg-[#D00B0E] text-white shadow-md rounded-md px-6 py-3 hover:bg-[#D00B0E]/90 transition-colors'>
                View All Services
              </Link>
            </div>
          </div>
        </section>
      )}

      <section className='py-16 bg-white'>
        <div className='container mx-auto px-4'>
          <div className='max-w-3xl mx-auto text-center'>
            <h2 className='text-3xl font-bold mb-4'>Need Masonry Services?</h2>
            <p className='text-gray-600 mb-8'>
              Contact us today for a free consultation and quote on your masonry
              project.
            </p>
            <Link
              to='/contact'
              className='bg-[#D00B0E] hover:bg-[#D00B0E]/90 text-white px-6 py-3 rounded-md font-medium transition-colors inline-block'>
              Get a Free Quote
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Locations;
