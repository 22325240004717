
import React from 'react';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import { GMBLocationSearch } from './GMBLocationSearch';

interface GMBLocationToolbarProps {
  search: string;
  onSearchChange: (value: string) => void;
  onAddNew: () => void;
  buttonLabel?: string;
}

export const GMBLocationToolbar: React.FC<GMBLocationToolbarProps> = ({
  search,
  onSearchChange,
  onAddNew,
  buttonLabel = "Add Location"
}) => {
  return (
    <div className="bg-white rounded-lg shadow mb-6">
      <div className="p-4 border-b">
        <div className="flex justify-between gap-4">
          <GMBLocationSearch search={search} onSearchChange={onSearchChange} />
          <Button onClick={onAddNew} className="bg-[#D00B0E] hover:bg-[#B00A0C]">
            <Plus size={16} className="mr-2" />
            {buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};
