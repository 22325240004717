
import React, { useState } from 'react';
import { useAssetManager, Asset } from '@/hooks/useAssetManager';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetClose } from '@/components/ui/sheet';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { Label } from '@/components/ui/label';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  ImageIcon, 
  FileIcon, 
  Pencil, 
  Save, 
  Clock, 
  FileArchive, 
  Trash2, 
  RefreshCw,
  X
} from 'lucide-react';
import { Separator } from '@/components/ui/separator';
import { formatBytes } from '@/utils/formatters';
import { format } from 'date-fns';
import { toast } from 'sonner';
import { 
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle 
} from '@/components/ui/alert-dialog';

interface AssetDetailViewProps {
  asset: Asset | null;
  onClose: () => void;
}

export const AssetDetailView: React.FC<AssetDetailViewProps> = ({ asset, onClose }) => {
  const { updateAssetSEO, deleteAsset, archiveAsset } = useAssetManager();
  const [seoData, setSeoData] = useState<Asset['seo']>(
    asset?.seo || { title: '', alt_text: '', description: '', caption: '', keywords: [] }
  );
  const [isEditing, setIsEditing] = useState(false);
  const [keywordInput, setKeywordInput] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  // Update seoData when asset changes
  React.useEffect(() => {
    if (asset) {
      setSeoData(asset.seo || { 
        title: '', 
        alt_text: '', 
        description: '', 
        caption: '', 
        keywords: [] 
      });
    }
  }, [asset]);

  if (!asset) return null;

  const isImage = asset.mime_type.startsWith('image/');

  const handleSeoSave = async () => {
    if (!asset) return;
    
    try {
      await updateAssetSEO(asset.id, seoData);
      setIsEditing(false);
    } catch (error) {
      toast.error('Failed to update SEO information');
    }
  };

  const addKeyword = () => {
    if (!keywordInput.trim()) return;
    
    const keywords = seoData.keywords || [];
    setSeoData({
      ...seoData,
      keywords: [...keywords, keywordInput.trim()]
    });
    setKeywordInput('');
  };

  const removeKeyword = (index: number) => {
    const keywords = [...(seoData.keywords || [])];
    keywords.splice(index, 1);
    setSeoData({
      ...seoData,
      keywords
    });
  };

  const handleDelete = async () => {
    if (!asset) return;
    
    try {
      await deleteAsset(asset.id);
      onClose();
    } catch (error) {
      toast.error('Failed to delete asset');
    }
  };

  const handleArchiveToggle = async () => {
    if (!asset) return;
    
    try {
      await archiveAsset(asset.id, !asset.is_archived);
      onClose();
    } catch (error) {
      toast.error(`Failed to ${asset.is_archived ? 'restore' : 'archive'} asset`);
    }
  };

  return (
    <Sheet open={!!asset} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <SheetContent className="sm:max-w-md md:max-w-xl overflow-y-auto">
        <SheetHeader className="mb-4">
          <SheetTitle>Asset Details</SheetTitle>
        </SheetHeader>
        
        <Tabs defaultValue="preview" className="w-full">
          <TabsList className="mb-4">
            <TabsTrigger value="preview">Preview</TabsTrigger>
            <TabsTrigger value="metadata">Metadata</TabsTrigger>
            <TabsTrigger value="seo">SEO</TabsTrigger>
          </TabsList>
          
          <TabsContent value="preview" className="space-y-4">
            <div className="bg-muted rounded-md min-h-60 flex items-center justify-center">
              {isImage ? (
                <img
                  src={asset.file_path}
                  alt={asset.seo?.alt_text || asset.original_filename}
                  className="max-w-full max-h-80 object-contain"
                />
              ) : (
                <div className="text-center p-8">
                  <FileIcon size={72} className="mx-auto mb-4" />
                  <p className="text-lg">{asset.original_filename}</p>
                </div>
              )}
            </div>
            
            <div className="space-y-2">
              <h3 className="font-medium">{asset.original_filename}</h3>
              <div className="text-sm text-muted-foreground">
                {asset.seo?.description || 'No description provided'}
              </div>
            </div>
            
            <div className="flex gap-2">
              <Button asChild className="w-full" variant="outline">
                <a href={asset.file_path} target="_blank" rel="noopener noreferrer">
                  Open File
                </a>
              </Button>
              <Button 
                variant="destructive" 
                className="gap-2"
                onClick={() => setIsDeleteDialogOpen(true)}
              >
                <Trash2 size={16} />
                Delete
              </Button>
            </div>
          </TabsContent>
          
          <TabsContent value="metadata" className="space-y-4">
            <div className="grid gap-4">
              <div>
                <Label>Filename</Label>
                <div className="mt-1.5 text-sm">{asset.original_filename}</div>
              </div>
              
              <div>
                <Label>File Path</Label>
                <div className="mt-1.5 text-sm truncate">{asset.file_path}</div>
              </div>
              
              <div>
                <Label>File Type</Label>
                <div className="mt-1.5 text-sm">{asset.mime_type}</div>
              </div>
              
              <div>
                <Label>File Size</Label>
                <div className="mt-1.5 text-sm">{formatBytes(asset.file_size)}</div>
              </div>
              
              {(asset.width && asset.height) && (
                <div>
                  <Label>Dimensions</Label>
                  <div className="mt-1.5 text-sm">{asset.width} × {asset.height}</div>
                </div>
              )}
              
              <div>
                <Label>Upload Date</Label>
                <div className="mt-1.5 text-sm flex items-center gap-1">
                  <Clock size={14} />
                  {format(new Date(asset.created_at), 'PPpp')}
                </div>
              </div>
              
              <div>
                <Label>Status</Label>
                <div className="mt-1.5 text-sm">
                  {asset.is_archived ? 'Archived' : 'Active'}
                </div>
              </div>
            </div>
            
            <Separator />
            
            <div className="flex justify-between">
              <Button 
                variant={asset.is_archived ? "default" : "outline"}
                onClick={handleArchiveToggle}
                className="gap-2"
              >
                {asset.is_archived ? (
                  <>
                    <RefreshCw size={16} />
                    Restore
                  </>
                ) : (
                  <>
                    <FileArchive size={16} />
                    Archive
                  </>
                )}
              </Button>
              
              <Button 
                variant="destructive" 
                className="gap-2"
                onClick={() => setIsDeleteDialogOpen(true)}
              >
                <Trash2 size={16} />
                Delete
              </Button>
            </div>
          </TabsContent>
          
          <TabsContent value="seo" className="space-y-4">
            <div className="flex justify-between">
              <h3 className="font-medium">SEO Information</h3>
              {!isEditing ? (
                <Button 
                  variant="outline" 
                  size="sm" 
                  onClick={() => setIsEditing(true)}
                  className="gap-2"
                >
                  <Pencil size={14} />
                  Edit
                </Button>
              ) : (
                <Button 
                  variant="default" 
                  size="sm" 
                  onClick={handleSeoSave}
                  className="gap-2"
                >
                  <Save size={14} />
                  Save
                </Button>
              )}
            </div>
            
            <div className="space-y-4">
              <div>
                <Label htmlFor="title">Title</Label>
                {isEditing ? (
                  <Input
                    id="title"
                    value={seoData.title || ''}
                    onChange={(e) => setSeoData({ ...seoData, title: e.target.value })}
                    className="mt-1.5"
                  />
                ) : (
                  <div className="mt-1.5 text-sm">
                    {seoData.title || 'Not set'}
                  </div>
                )}
              </div>
              
              <div>
                <Label htmlFor="alt_text">Alt Text</Label>
                {isEditing ? (
                  <Input
                    id="alt_text"
                    value={seoData.alt_text || ''}
                    onChange={(e) => setSeoData({ ...seoData, alt_text: e.target.value })}
                    className="mt-1.5"
                  />
                ) : (
                  <div className="mt-1.5 text-sm">
                    {seoData.alt_text || 'Not set'}
                  </div>
                )}
              </div>
              
              <div>
                <Label htmlFor="description">Description</Label>
                {isEditing ? (
                  <Textarea
                    id="description"
                    value={seoData.description || ''}
                    onChange={(e) => setSeoData({ ...seoData, description: e.target.value })}
                    className="mt-1.5"
                  />
                ) : (
                  <div className="mt-1.5 text-sm">
                    {seoData.description || 'Not set'}
                  </div>
                )}
              </div>
              
              <div>
                <Label htmlFor="caption">Caption</Label>
                {isEditing ? (
                  <Input
                    id="caption"
                    value={seoData.caption || ''}
                    onChange={(e) => setSeoData({ ...seoData, caption: e.target.value })}
                    className="mt-1.5"
                  />
                ) : (
                  <div className="mt-1.5 text-sm">
                    {seoData.caption || 'Not set'}
                  </div>
                )}
              </div>
              
              <div>
                <Label htmlFor="keywords">Keywords</Label>
                {isEditing ? (
                  <>
                    <div className="flex gap-2 mt-1.5">
                      <Input
                        id="keywords"
                        value={keywordInput}
                        onChange={(e) => setKeywordInput(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && addKeyword()}
                        placeholder="Add keyword and press Enter"
                      />
                      <Button 
                        type="button" 
                        onClick={addKeyword}
                        variant="outline"
                      >
                        Add
                      </Button>
                    </div>
                    
                    <div className="flex flex-wrap gap-2 mt-2">
                      {(seoData.keywords || []).map((keyword, index) => (
                        <div 
                          key={index}
                          className="bg-muted px-2 py-1 rounded-md text-sm flex items-center gap-1"
                        >
                          {keyword}
                          <Button
                            type="button"
                            variant="ghost"
                            size="icon"
                            className="h-4 w-4"
                            onClick={() => removeKeyword(index)}
                          >
                            <X size={12} />
                          </Button>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="mt-1.5 flex flex-wrap gap-1">
                    {(seoData.keywords || []).length > 0 ? (
                      (seoData.keywords || []).map((keyword, index) => (
                        <span 
                          key={index}
                          className="bg-muted px-2 py-0.5 rounded-md text-sm"
                        >
                          {keyword}
                        </span>
                      ))
                    ) : (
                      <span className="text-sm text-muted-foreground">No keywords set</span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </SheetContent>
      
      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete this asset. This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction 
              onClick={handleDelete}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Sheet>
  );
};
