
import React from 'react';
import { Card } from '@/components/ui/card';
import { Textarea } from '@/components/ui/textarea';
import { cn } from '@/lib/utils';

interface EditorContentProps {
  content: string;
  onChange: (value: string) => void;
  editorView: 'code' | 'normal';
  textareaRef: React.RefObject<HTMLTextAreaElement>;
}

export const EditorContent = ({ content, onChange, editorView, textareaRef }: EditorContentProps) => {
  // Ensure content is never undefined or null
  const safeContent = content || '';
  
  return (
    <Card className="relative">
      <Textarea
        ref={textareaRef}
        value={safeContent}
        onChange={(e) => onChange(e.target.value)}
        className={cn(
          "min-h-[400px] font-mono p-4 resize-y",
          editorView === 'code' ? "font-mono text-sm" : ""
        )}
        placeholder="Write your blog post content here..."
      />
    </Card>
  );
};
