
import React from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { PlusCircle, X } from 'lucide-react';

interface ProcessStepsFormProps {
  processSteps: {
    title: string;
    description: string;
  }[];
  addProcessStep: () => void;
  removeProcessStep: (index: number) => void;
  updateProcessStep: (index: number, field: string, value: string, stepIndex: number) => void;
}

const ProcessStepsForm: React.FC<ProcessStepsFormProps> = ({
  processSteps,
  addProcessStep,
  removeProcessStep,
  updateProcessStep
}) => {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <Label>Process Steps</Label>
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={addProcessStep}
          className="flex items-center gap-1"
        >
          <PlusCircle className="w-4 h-4" />
          Add Process Step
        </Button>
      </div>

      {processSteps && processSteps.map((step, index) => (
        <div key={index} className="p-4 border rounded-md space-y-4">
          <div className="flex items-center justify-between">
            <Label className="text-base font-medium">Step {index + 1}</Label>
            <Button
              type="button"
              variant="ghost"
              size="icon"
              onClick={() => removeProcessStep(index)}
              className="flex-shrink-0"
            >
              <X className="w-4 h-4" />
            </Button>
          </div>

          <div className="space-y-2">
            <Label htmlFor={`process-step-title-${index}`}>Title</Label>
            <Input
              id={`process-step-title-${index}`}
              value={step.title}
              onChange={(e) => updateProcessStep(index, 'title', e.target.value, index)}
              placeholder="Step title"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor={`process-step-description-${index}`}>Description</Label>
            <Textarea
              id={`process-step-description-${index}`}
              value={step.description}
              onChange={(e) => updateProcessStep(index, 'description', e.target.value, index)}
              placeholder="Step description"
              rows={3}
            />
          </div>
        </div>
      ))}

      {(!processSteps || processSteps.length === 0) && (
        <p className="text-sm text-gray-500">No process steps added yet.</p>
      )}
    </div>
  );
};

export default ProcessStepsForm;
