
import React, { useState, useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Upload, X, Image as ImageIcon, RefreshCw } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { v4 as uuidv4 } from 'uuid';

interface ImageUploaderProps {
  currentImageUrl: string;
  onImageUploaded: (url: string) => void;
  label: string;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ 
  currentImageUrl, 
  onImageUploaded,
  label
}) => {
  const [uploading, setUploading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [refreshKey, setRefreshKey] = useState(Date.now());

  // When component mounts or the currentImageUrl changes, update the preview
  useEffect(() => {
    if (currentImageUrl) {
      setPreviewUrl(currentImageUrl);
    } else {
      setPreviewUrl(null);
    }
  }, [currentImageUrl, refreshKey]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];
    const fileSize = file.size / 1024 / 1024; // size in MB

    if (fileSize > 5) {
      toast.error("File size exceeds 5MB limit");
      return;
    }

    // Create a local preview immediately to improve UX
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target?.result) {
        setPreviewUrl(event.target.result as string);
      }
    };
    reader.readAsDataURL(file);

    // Start the upload process
    setUploading(true);

    try {
      const fileExt = file.name.split('.').pop();
      const fileName = `${uuidv4()}.${fileExt}`;
      const filePath = `services/${fileName}`;

      // Upload file
      const { error: uploadError } = await supabase.storage
        .from('images')
        .upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from('images')
        .getPublicUrl(filePath);

      onImageUploaded(publicUrl);
      toast.success('Image uploaded successfully');
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Failed to upload image');
      // Reset preview if upload fails
      setPreviewUrl(currentImageUrl || null);
    } finally {
      setUploading(false);
      // Reset the input
      e.target.value = '';
    }
  };

  const handleRemoveImage = () => {
    onImageUploaded('');
    setPreviewUrl(null);
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    onImageUploaded(url);
    setPreviewUrl(url); // Immediately update the preview with the new URL
  };

  const handleRefreshPreview = () => {
    // Force refresh the image by updating the refresh key
    setRefreshKey(Date.now());
  };

  return (
    <div className="space-y-4">
      <Label htmlFor={`image-upload-${label.replace(/\s+/g, '-').toLowerCase()}`}>{label}</Label>
      
      {previewUrl ? (
        <div className="relative">
          <img 
            src={`${previewUrl}?t=${refreshKey}`} 
            alt="Uploaded" 
            className="h-40 w-full object-cover rounded-md border border-gray-200"
            onError={() => {
              toast.error(`Failed to load image: ${previewUrl}`);
              // Optionally revert to a default image
              // setPreviewUrl('/placeholder.svg');
            }}
          />
          <div className="absolute top-2 right-2 flex gap-2">
            <Button
              type="button"
              variant="secondary"
              size="sm"
              onClick={handleRefreshPreview}
              title="Refresh image preview"
            >
              <RefreshCw className="h-4 w-4" />
            </Button>
            <Button
              type="button"
              variant="destructive"
              size="sm"
              onClick={handleRemoveImage}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </div>
      ) : (
        <div className="border-2 border-dashed border-gray-300 rounded-md p-6 text-center">
          <ImageIcon className="mx-auto h-12 w-12 text-gray-400" />
          <p className="mt-2 text-sm text-gray-500">No image uploaded</p>
        </div>
      )}
      
      <div className="flex flex-col sm:flex-row gap-2">
        <Input
          id={`image-upload-${label.replace(/\s+/g, '-').toLowerCase()}`}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="hidden"
        />
        <Button
          type="button"
          variant="outline"
          onClick={() => document.getElementById(`image-upload-${label.replace(/\s+/g, '-').toLowerCase()}`)?.click()}
          disabled={uploading}
          className="flex items-center justify-center"
        >
          {uploading ? (
            <span className="flex items-center">
              <span className="animate-spin mr-2">⏳</span> Uploading...
            </span>
          ) : (
            <span className="flex items-center">
              <Upload className="mr-2 h-4 w-4" /> Upload Image
            </span>
          )}
        </Button>
        
        <Input
          type="text"
          value={currentImageUrl || ''}
          onChange={handleUrlChange}
          placeholder="Or enter image URL"
          className="flex-1"
        />
      </div>
    </div>
  );
};

export default ImageUploader;
