import { useEffect, useState } from 'react';
import { getTrackingParams, saveTrackingParams, getSavedTrackingParams } from '@/utils/urlParams';

/**
 * Hook to get tracking parameters from URL or sessionStorage
 * This ensures UTM parameters and other tracking data are preserved throughout the session
 */
export function useTrackingParams(): Record<string, string> {
  const [trackingParams, setTrackingParams] = useState<Record<string, string>>({});
  
  useEffect(() => {
    // First check if there are tracking params in the URL
    const paramsFromUrl = getTrackingParams();
    
    if (Object.keys(paramsFromUrl).length > 0) {
      // If tracking params are in the URL, save them and use them
      saveTrackingParams(paramsFromUrl);
      setTrackingParams(paramsFromUrl);
    } else {
      // Otherwise, try to get previously saved tracking params
      const savedParams = getSavedTrackingParams();
      setTrackingParams(savedParams);
    }
  }, []);
  
  return trackingParams;
}
