import React from 'react';
import Header from '@/components/header';
import Footer from '@/components/Footer';
import { Helmet } from 'react-helmet-async';

const TermsOfService = () => {
  return (
    <>
      <Helmet>
        <title>Terms of Service | Fix My Brick</title>
        <meta name="description" content="Terms of Service for Fix My Brick masonry and restoration services." />
      </Helmet>
      <Header />
      <main className="py-20 bg-white">
        <div className="container mx-auto px-4 md:px-8">
          <h1 className="text-3xl md:text-4xl font-bold mb-8">Terms of Service</h1>
          
          <div className="prose max-w-none">
            <p className="text-lg mb-6">
              Last Updated: March 13, 2025
            </p>
            
            <h2 className="text-2xl font-semibold mt-8 mb-4">1. Acceptance of Terms</h2>
            <p>
              By accessing or using the Fix My Brick website and services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.
            </p>
            
            <h2 className="text-2xl font-semibold mt-8 mb-4">2. Services</h2>
            <p>
              Fix My Brick provides masonry and restoration services. The scope, timing, and cost of services will be outlined in a separate agreement or quote provided to you.
            </p>
            
            <h2 className="text-2xl font-semibold mt-8 mb-4">3. Payment Terms</h2>
            <p>
              Payment terms will be specified in your service agreement or quote. All prices are subject to change and will be confirmed at the time of service booking.
            </p>
            
            <h2 className="text-2xl font-semibold mt-8 mb-4">4. Warranties and Guarantees</h2>
            <p>
              Fix My Brick provides warranties on our workmanship as specified in your service agreement. These warranties are subject to proper maintenance and use of the repaired or installed structures.
            </p>
            
            <h2 className="text-2xl font-semibold mt-8 mb-4">5. Limitation of Liability</h2>
            <p>
              To the maximum extent permitted by law, Fix My Brick shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our services.
            </p>
            
            <h2 className="text-2xl font-semibold mt-8 mb-4">6. Governing Law</h2>
            <p>
              These Terms of Service shall be governed by and construed in accordance with the laws of Ontario, Canada, without regard to its conflict of law principles.
            </p>
            
            <h2 className="text-2xl font-semibold mt-8 mb-4">7. Contact Us</h2>
            <p>
              If you have any questions about these Terms of Service, please contact us at:
            </p>
            <p>
              Fix My Brick Inc.<br/>
              68 Beverly Hills Dr<br/>
              North York, ON M3L 1A5<br/>
              Email: info@fixmybrick.ca<br/>
              Phone: (416) 800-2637
            </p>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default TermsOfService;
