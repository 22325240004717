
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { ReviewStatus } from './types';

export const useGalleryReviewStatus = (galleries: any[], setGalleries: React.Dispatch<React.SetStateAction<any[]>>) => {
  const handleUpdateReviewStatus = async (id: string, status: ReviewStatus) => {
    try {
      const { error } = await supabase
        .from('galleries')
        .update({ review_status: status })
        .eq('id', id);

      if (error) {
        throw error;
      }

      setGalleries(
        galleries.map(gallery => 
          gallery.id === id 
            ? { ...gallery, review_status: status } 
            : gallery
        )
      );

      toast.success(`Gallery review status updated to ${status}`);
    } catch (error) {
      console.error('Error updating gallery review status:', error);
      toast.error('Failed to update gallery review status');
    }
  };

  return {
    handleUpdateReviewStatus
  };
};
