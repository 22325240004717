import React from 'react';
import { Button } from '@/components/ui/button';
import { Eye, Edit, Trash2, Images, MoreHorizontal, Play } from 'lucide-react';
import { Skeleton } from '@/components/ui/skeleton';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

interface GalleryActionsProps {
  id: string;
  slug: string;
  confirmDeleteId: string | null;
  deleting: boolean;
  onEdit: (id: string) => void;
  onPreview: (slug: string) => void;
  onDelete: (id: string) => void;
  onManagePhotos: (id: string) => void;
  onViewSlideshow: (id: string) => void;
}

const GalleryActions: React.FC<GalleryActionsProps> = ({
  id,
  slug,
  confirmDeleteId,
  deleting,
  onEdit,
  onPreview,
  onDelete,
  onManagePhotos,
  onViewSlideshow,
}) => {
  return (
    <div className='flex justify-end items-center gap-2'>
      {/* Primary Action: Manage Photos */}
      <Button
        variant='outline'
        size='sm'
        className='flex items-center'
        onClick={() => onManagePhotos(id)}>
        <Images className='mr-2 h-4 w-4' />
        <span>Manage Photos</span>
      </Button>

      {/* Secondary Actions in Dropdown */}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant='outline' size='sm'>
            <MoreHorizontal className='h-4 w-4' />
            <span className='sr-only'>More actions</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='end'>
          <DropdownMenuItem onClick={() => onPreview(slug)}>
            <Eye className='mr-2 h-4 w-4' /> Preview Gallery
          </DropdownMenuItem>

          <DropdownMenuItem onClick={() => onViewSlideshow(id)}>
            <Play className='mr-2 h-4 w-4' /> View Slideshow
          </DropdownMenuItem>

          <DropdownMenuItem onClick={() => onEdit(id)}>
            <Edit className='mr-2 h-4 w-4' /> Edit Details
          </DropdownMenuItem>

          <DropdownMenuSeparator />

          <DropdownMenuItem
            onClick={() => onDelete(id)}
            disabled={deleting && confirmDeleteId === id}
            className='text-destructive focus:text-destructive'>
            {deleting && confirmDeleteId === id ? (
              <>
                <Skeleton className='mr-2 h-4 w-4' />
                Deleting...
              </>
            ) : (
              <>
                <Trash2 className='mr-2 h-4 w-4' /> Delete Gallery
              </>
            )}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default GalleryActions;
