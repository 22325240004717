import React from 'react';
import { Link } from 'react-router-dom';
import { Hammer } from 'lucide-react';
import { useSettings } from '@/hooks/useSettings';
import LazyImage from '@/components/ui/lazy-image';

const SidebarHeader = () => {
  const { settings, loading } = useSettings();

  return (
    <div className='p-4 border-b'>
      <Link
        to='/'
        className='flex flex-col items-center hover:opacity-80 transition-opacity'>
        <div className='w-full text-center mb-2'>
          <p className='text-sm font-medium text-gray-600'>Admin Dashboard</p>
        </div>

        {settings.logo_url ? (
          <div className='h-20 w-full flex justify-center'>
            <LazyImage
              src={settings.logo_url}
              alt='Fix My Brick Logo'
              className='h-16 sm:h-20 w-auto object-contain'
              height={80}
              width='auto'
            />
          </div>
        ) : (
          <div className='flex items-center'>
            <div className='bg-brick rounded-md p-1.5 mr-2'>
              <Hammer className='h-4 w-4 text-white' />
            </div>
            <div>
              <h1 className='text-lg md:text-xl font-bold'>
                Fix My <span className='text-brick'>Brick</span>
              </h1>
            </div>
          </div>
        )}
      </Link>
    </div>
  );
};

export default SidebarHeader;
