
import { toast } from 'sonner';
import { supabase } from './client';

/**
 * Submit a contact form to the Supabase Edge Function
 * @param formData Form data from the contact form
 * @param files Optional array of files to upload
 * @param retryConfig Optional retry configuration
 * @returns Promise that resolves when the form is submitted
 */
export async function submitContactFormWithEdgeFunction(
  formData: Record<string, any>,
  files: File[] = [],
  retryConfig = { maxRetries: 2, initialDelay: 1000 }
): Promise<{ success: boolean; leadId?: string; requestId?: string; fileUrls?: string[]; error?: string }> {
  let retryCount = 0;
  const { maxRetries, initialDelay } = retryConfig;

  // Add form_id if not present
  if (!formData.form_id) {
    formData.form_id = 'contact';
  }

  // Add form_name if not present
  if (!formData.form_name) {
    formData.form_name = 'Contact Form';
  }

  // Add submission_status if not present
  if (!formData.submission_status) {
    formData.submission_status = 'completed';
  }

  const executeRequest = async (): Promise<{ success: boolean; leadId?: string; requestId?: string; fileUrls?: string[]; error?: string }> => {
    try {
      // Create a FormData object to send to the Edge Function
      const form = new FormData();
      
      // Add all form fields
      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          form.append(key, String(value));
        }
      });
      
      // Add files if present
      files.forEach(file => {
        form.append('files', file);
      });
      
      console.log('Submitting form with', files.length, 'files');
      console.log('Form data entries:', Array.from(form.entries()).map(([key, value]) => 
        key === 'files' ? `${key}: [File]` : `${key}: ${value}`
      ));
      
      // Instead of using direct fetch, use the Supabase Functions client
      const { data, error } = await supabase.functions.invoke('contact-form', {
        body: form,
      });
      
      if (error) {
        console.error('Error calling contact-form function:', error);
        throw new Error(error.message || 'Form submission failed');
      }
      
      return {
        success: true,
        leadId: data?.leadId,
        requestId: data?.requestId,
        fileUrls: data?.fileUrls,
      };
    } catch (error) {
      console.error(`Error submitting contact form (attempt ${retryCount + 1}/${maxRetries + 1}):`, error);
      
      // If we haven't exceeded max retries, try again with exponential backoff
      if (retryCount < maxRetries) {
        retryCount++;
        const backoffDelay = initialDelay * Math.pow(2, retryCount - 1);
        console.log(`Retrying in ${backoffDelay}ms...`);
        
        await new Promise(resolve => setTimeout(resolve, backoffDelay));
        return executeRequest();
      }
      
      // If we've exhausted all retries, return error
      return {
        success: false,
        error: error instanceof Error ? error.message : 'Unknown error occurred'
      };
    }
  };

  return executeRequest();
}
