
import React from 'react';
import { format } from 'date-fns';

interface CreatedDateInfoProps {
  createdOn?: string;
}

const CreatedDateInfo = ({ createdOn }: CreatedDateInfoProps) => {
  if (!createdOn) return null;
  
  return (
    <div className="text-sm text-muted-foreground">
      Created on: {format(new Date(createdOn), 'MMM d, yyyy')}
    </div>
  );
};

export default CreatedDateInfo;
