
import React, { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent } from '@/components/ui/card';
import { Calendar } from '@/components/ui/calendar';
import { FileText, Upload, Calendar as CalendarIcon, X } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { format } from 'date-fns';
import { toast } from 'sonner';
import { v4 as uuidv4 } from 'uuid';

interface WSIBCertificateFormProps {
  onSaved?: () => void;
}

interface Certificate {
  id: string;
  title: string;
  description: string | null;
  file_url: string | null;
  expiry_date: string | null;
}

const WSIBCertificateForm: React.FC<WSIBCertificateFormProps> = ({ onSaved }) => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [certificate, setCertificate] = useState<Certificate | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const [tempImageUrl, setTempImageUrl] = useState<string | null>(null);
  const [date, setDate] = useState<Date>();

  useEffect(() => {
    const fetchCertificate = async () => {
      setLoading(true);
      try {
        const { data, error } = await supabase
          .from('wsib_certificate')
          .select('*')
          .order('created_at', { ascending: false })
          .limit(1)
          .maybeSingle();

        if (error) {
          throw error;
        }

        if (data) {
          setCertificate(data);
          setTitle(data.title);
          setDescription(data.description || '');
          setFileUrl(data.file_url);
          setTempImageUrl(data.file_url);
          if (data.expiry_date) {
            const date = new Date(data.expiry_date);
            setExpiryDate(date);
            setDate(date);
          }
        } else {
          // Set default title if no certificate exists
          setTitle('WSIB Certificate');
        }
      } catch (err) {
        console.error('Error fetching certificate:', err);
        toast.error('Error loading certificate data');
      } finally {
        setLoading(false);
      }
    };

    fetchCertificate();
  }, []);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];
    const fileSize = file.size / 1024 / 1024; // size in MB

    // Check if file is too large (5MB limit)
    if (fileSize > 5) {
      toast.error('File size exceeds 5MB limit');
      return;
    }

    try {
      setUploading(true);

      // Create a temporary preview
      const objectUrl = URL.createObjectURL(file);
      setTempImageUrl(objectUrl);

      // Upload file to Supabase storage
      const fileExt = file.name.split('.').pop();
      const fileName = `${uuidv4()}.${fileExt}`;
      const filePath = `${fileName}`;

      const { data, error } = await supabase.storage
        .from('wsib_certificates')
        .upload(filePath, file);

      if (error) {
        throw error;
      }

      // Get public URL
      const { data: publicUrlData } = supabase.storage
        .from('wsib_certificates')
        .getPublicUrl(filePath);

      if (publicUrlData) {
        setFileUrl(publicUrlData.publicUrl);
        toast.success('Certificate uploaded successfully');
      }
    } catch (error) {
      console.error('Error uploading certificate:', error);
      toast.error('Error uploading certificate');
      setTempImageUrl(fileUrl); // Revert to previous image if failed
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!title.trim()) {
      toast.error('Title is required');
      return;
    }

    setSaving(true);

    try {
      const certificateData = {
        title,
        description: description || null,
        file_url: fileUrl,
        expiry_date: expiryDate ? expiryDate.toISOString().split('T')[0] : null,
      };

      let response;

      if (certificate?.id) {
        // Update existing certificate
        response = await supabase
          .from('wsib_certificate')
          .update(certificateData)
          .eq('id', certificate.id);
      } else {
        // Create new certificate
        response = await supabase
          .from('wsib_certificate')
          .insert([certificateData]);
      }

      if (response.error) {
        throw response.error;
      }

      toast.success('WSIB certificate saved successfully');
      if (onSaved) onSaved();
    } catch (error) {
      console.error('Error saving certificate:', error);
      toast.error('Error saving certificate');
    } finally {
      setSaving(false);
    }
  };

  const handleRemoveFile = () => {
    setFileUrl(null);
    setTempImageUrl(null);
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin h-8 w-8 border-4 border-D00B0E border-t-transparent rounded-full"></div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-4">
        <div>
          <Label htmlFor="title">Certificate Title</Label>
          <Input
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter certificate title"
            required
          />
        </div>

        <div>
          <Label htmlFor="description">Description (Optional)</Label>
          <Textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter certificate description or information"
            rows={5}
          />
        </div>

        <div>
          <Label htmlFor="expiry_date">Expiry Date (Optional)</Label>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={`w-full justify-start text-left font-normal ${!date && "text-muted-foreground"}`}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {date ? format(date, "PPP") : <span>Select a date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={date}
                onSelect={(date) => {
                  setDate(date);
                  setExpiryDate(date);
                }}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        </div>

        <div>
          <Label>Certificate File</Label>
          <Card className="mt-2">
            <CardContent className="p-4">
              {tempImageUrl ? (
                <div className="relative">
                  <div className="aspect-[3/4] bg-gray-100 rounded-md overflow-hidden border border-gray-200">
                    {tempImageUrl.toLowerCase().endsWith('.pdf') ? (
                      <div className="h-full flex flex-col items-center justify-center p-8 text-center">
                        <FileText className="h-16 w-16 text-D00B0E mb-4" />
                        <p className="text-gray-600 mb-2">PDF Certificate</p>
                        <Button 
                          variant="outline" 
                          size="sm"
                          onClick={() => window.open(tempImageUrl, '_blank')}
                          className="mt-2"
                        >
                          <FileText className="mr-2 h-4 w-4" />
                          View PDF
                        </Button>
                      </div>
                    ) : (
                      <img 
                        src={tempImageUrl} 
                        alt="WSIB Certificate Preview" 
                        className="w-full h-full object-contain"
                      />
                    )}
                  </div>
                  <Button
                    type="button"
                    variant="destructive"
                    size="icon"
                    className="absolute top-2 right-2"
                    onClick={handleRemoveFile}
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              ) : (
                <label className="flex flex-col items-center justify-center cursor-pointer p-6 border-2 border-dashed border-gray-300 rounded-md hover:bg-gray-50 transition-colors">
                  <Upload className="h-12 w-12 text-gray-400 mb-2" />
                  <span className="text-sm font-medium text-gray-900">Upload a file</span>
                  <span className="text-xs text-gray-500 mt-1">PDF, PNG, JPG up to 5MB</span>
                  <input
                    type="file"
                    className="hidden"
                    accept=".pdf,.png,.jpg,.jpeg"
                    onChange={handleFileChange}
                    disabled={uploading}
                  />
                </label>
              )}
            </CardContent>
          </Card>
        </div>
      </div>

      <div className="flex justify-end">
        <Button type="submit" disabled={saving || uploading}>
          {saving ? 'Saving...' : 'Save Certificate'}
        </Button>
      </div>
    </form>
  );
};

export default WSIBCertificateForm;
