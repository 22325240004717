
import React, { useState, useRef } from 'react';
import { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogDescription } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { toast } from 'sonner';
import { CropEditor } from './CropEditor';
import { LogoPreview } from './LogoPreview';

interface LogoCropDialogProps {
  open: boolean;
  onClose: () => void;
  imageUrl: string;
  onSave: (croppedImageUrl: string, size: number) => void;
}

const LogoCropDialog: React.FC<LogoCropDialogProps> = ({ 
  open, 
  onClose, 
  imageUrl, 
  onSave 
}) => {
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 90,
    height: 90,
    x: 5,
    y: 5
  });
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
  const [logoSize, setLogoSize] = useState<number>(24); // Height in rems
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const onCropChange = (newCrop: Crop) => {
    setCrop(newCrop);
  };

  const onCropComplete = (crop: Crop) => {
    setCompletedCrop(crop);
  };

  // This function is used for preview only
  const getCroppedImg = (): string | null => {
    if (!imgRef.current || !completedCrop) return null;

    try {
      const canvas = document.createElement('canvas');
      const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
      const ctx = canvas.getContext('2d');
      
      if (!ctx) return null;

      canvas.width = completedCrop.width * scaleX;
      canvas.height = completedCrop.height * scaleY;

      ctx.drawImage(
        imgRef.current,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY
      );

      return canvas.toDataURL('image/png');
    } catch (e) {
      console.error('Error creating preview crop:', e);
      return null;
    }
  };

  const handleSave = async () => {
    if (!imgRef.current || !completedCrop) {
      toast.error('Please crop the image first');
      return;
    }

    try {
      setIsProcessing(true);
      
      // For the actual save, we'll use a Blob object to avoid CORS issues
      const canvas = document.createElement('canvas');
      const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
      const ctx = canvas.getContext('2d');
      
      if (!ctx) {
        toast.error('Canvas context could not be created');
        return;
      }

      canvas.width = completedCrop.width * scaleX;
      canvas.height = completedCrop.height * scaleY;

      // Draw the image to the canvas
      ctx.drawImage(
        imgRef.current,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY
      );
      
      // Convert canvas to blob
      canvas.toBlob((blob) => {
        if (!blob) {
          toast.error('Failed to create image');
          setIsProcessing(false);
          return;
        }
        
        // Create a temporary URL for the blob
        const croppedImageUrl = URL.createObjectURL(blob);
        onSave(croppedImageUrl, logoSize);
        setIsProcessing(false);
      }, 'image/png');
      
    } catch (e) {
      console.error('Error saving cropped image:', e);
      toast.error('Failed to process the image');
      setIsProcessing(false);
    }
  };

  const onSizeChange = (value: number[]) => {
    setLogoSize(value[0]);
  };

  return (
    <Dialog open={open} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="max-w-4xl w-full">
        <DialogHeader>
          <DialogTitle>Adjust Logo</DialogTitle>
          <DialogDescription>
            Crop your logo and adjust the size to fit your site. For best results across all devices, use a logo with these characteristics:
            <ul className="mt-2 list-disc pl-5 text-sm space-y-1">
              <li>Rectangular shape (wider than tall)</li>
              <li>Recommended dimensions: 260px × 64px</li>
              <li>Transparent background (PNG format)</li>
              <li>Clear visibility at small sizes</li>
            </ul>
          </DialogDescription>
        </DialogHeader>

        <Tabs defaultValue="crop">
          <TabsList className="mb-4">
            <TabsTrigger value="crop">Crop Logo</TabsTrigger>
            <TabsTrigger value="preview">Preview</TabsTrigger>
          </TabsList>

          <TabsContent value="crop" className="space-y-4">
            <CropEditor 
              imageUrl={imageUrl}
              crop={crop}
              onCropChange={onCropChange}
              onCropComplete={onCropComplete}
              logoSize={logoSize}
              onSizeChange={onSizeChange}
              imgRef={imgRef}
            />
          </TabsContent>

          <TabsContent value="preview" className="space-y-6">
            <LogoPreview 
              imageUrl={imageUrl}
              logoSize={logoSize}
              getCroppedImg={getCroppedImg}
              completedCrop={completedCrop}
            />
          </TabsContent>
        </Tabs>

        <DialogFooter>
          <Button variant="outline" onClick={onClose} disabled={isProcessing}>Cancel</Button>
          <Button onClick={handleSave} disabled={isProcessing}>
            {isProcessing ? 'Processing...' : 'Save Changes'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LogoCropDialog;
