
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';

export const useGalleryVisibility = (galleries: any[], setGalleries: React.Dispatch<React.SetStateAction<any[]>>) => {
  const handleTogglePublic = async (id: string, currentStatus: boolean) => {
    try {
      const { error } = await supabase
        .from('galleries')
        .update({ is_public: !currentStatus })
        .eq('id', id);

      if (error) {
        throw error;
      }

      setGalleries(
        galleries.map(gallery => 
          gallery.id === id 
            ? { ...gallery, is_public: !currentStatus } 
            : gallery
        )
      );

      toast.success(`Gallery is now ${!currentStatus ? 'public' : 'private'}`);
    } catch (error) {
      console.error('Error updating gallery visibility:', error);
      toast.error('Failed to update gallery visibility');
    }
  };

  return {
    handleTogglePublic
  };
};
