
import React from 'react';
import { Phone, Mail, MapPin, Clock } from 'lucide-react';
import { useSettings } from '@/hooks/useSettings';
import { LocationLinks } from '@/components/header/navigationData';

const ContactInfo = () => {
  const { settings } = useSettings();

  return (
    <div>
      <h2 className="text-3xl md:text-4xl font-bold mb-6">Contact Us</h2>
      <p className="text-lg text-gray-600 mb-8">
        Have questions or ready to start your project? Reach out to us through the form or contact information below.
      </p>
      
      <div className="space-y-6 mb-8">
        <div className="flex items-start">
          <Phone className="text-brick mt-1 mr-4" />
          <div>
            <h3 className="font-semibold text-lg mb-1">Phone</h3>
            <p className="text-gray-700">{settings.phone_number}</p>
            <p className="text-gray-500 text-sm">Mon-Fri: 9am-5pm, Sat: 10am-2pm</p>
          </div>
        </div>
        
        <div className="flex items-start">
          <Mail className="text-brick mt-1 mr-4" />
          <div>
            <h3 className="font-semibold text-lg mb-1">Email</h3>
            <p className="text-gray-700">{settings.email}</p>
            <p className="text-gray-500 text-sm">We respond within 24 hours</p>
          </div>
        </div>
        
        <div className="flex items-start">
          <MapPin className="text-brick mt-1 mr-4" />
          <div>
            <h3 className="font-semibold text-lg mb-1">Locations</h3>
            <div className="space-y-2">
              {LocationLinks.map((location, index) => (
                <div key={index}>
                  <p className="font-medium">{location.name}</p>
                  <p className="text-gray-700">{location.address}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        <div className="flex items-start">
          <Clock className="text-brick mt-1 mr-4" />
          <div>
            <h3 className="font-semibold text-lg mb-1">Business Hours</h3>
            <div className="grid grid-cols-2 gap-x-4 text-gray-700">
              <p>Monday - Friday:</p>
              <p>9:00 AM - 5:00 PM</p>
              <p>Saturday:</p>
              <p>10:00 AM - 2:00 PM</p>
              <p>Sunday:</p>
              <p>Closed</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
