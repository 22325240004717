
import React from 'react';
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

interface FranchiseFieldsProps {
  franchiseRegion: string | null | undefined;
  jobNumber: number | null | undefined;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (name: string, value: string) => void;
}

const FranchiseFields = ({
  franchiseRegion,
  jobNumber,
  handleChange,
  handleSelectChange
}: FranchiseFieldsProps) => {
  // Franchise regions for dropdown - using the correct enum values from the database
  const franchiseRegions = [
    { value: "HQ", label: "HQ" },
    { value: "north_york", label: "North York" }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="space-y-2">
        <Label htmlFor="franchise_region">Franchise Region</Label>
        <Select 
          value={franchiseRegion || "none"} 
          onValueChange={(value) => {
            // If "none" is selected, pass null to handler
            handleSelectChange("franchise_region", value === "none" ? null : value);
          }}
        >
          <SelectTrigger id="franchise_region">
            <SelectValue placeholder="Select franchise region" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="none">None</SelectItem>
            {franchiseRegions.map((region) => (
              <SelectItem key={region.value} value={region.value}>
                {region.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="space-y-2">
        <Label htmlFor="job_number">Job Number</Label>
        <Input
          id="job_number"
          name="job_number"
          type="number"
          value={jobNumber || ""}
          onChange={handleChange}
          placeholder="Enter job number"
        />
      </div>
    </div>
  );
};

export default FranchiseFields;
