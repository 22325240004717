
import React from 'react';

interface PostHeaderProps {
  title: string;
  authorName?: string;
  publishedAt?: string;
}

const PostHeader: React.FC<PostHeaderProps> = ({ title, authorName, publishedAt }) => {
  return (
    <>
      <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6 text-gray-900">{title}</h1>
      
      <div className="flex items-center text-gray-600 mb-10 border-b border-gray-200 pb-6">
        {authorName && (
          <span className="flex items-center mr-6">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 text-brick" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
            {authorName}
          </span>
        )}
        
        {publishedAt && (
          <span className="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1 text-brick" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            {new Date(publishedAt).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </span>
        )}
      </div>
    </>
  );
};

export default PostHeader;
