
import React from 'react';
import CategoriesSidebar from './CategoriesSidebar';
import ContactPromo from './ContactPromo';

interface BlogSidebarProps {
  categories: Array<{
    id: string;
    name: string;
    slug: string;
    post_count?: number;
  }>;
  onCategorySelect?: (categorySlug: string) => void;
  selectedCategory?: string | null;
}

const BlogSidebar: React.FC<BlogSidebarProps> = ({ 
  categories, 
  onCategorySelect,
  selectedCategory
}) => {
  return (
    <div className="lg:col-span-1 space-y-8">
      <CategoriesSidebar 
        categories={categories} 
        onCategorySelect={onCategorySelect}
        selectedCategory={selectedCategory}
      />
      <ContactPromo />
    </div>
  );
};

export default BlogSidebar;
