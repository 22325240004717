
import { useState } from 'react';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { GalleryPhoto } from './types';

export const useFetchGalleryPhotos = () => {
  const [loading, setLoading] = useState(true);
  
  const fetchPhotos = async (galleryId: string): Promise<GalleryPhoto[]> => {
    try {
      setLoading(true);
      
      const { data, error } = await supabase
        .from('gallery_photos')
        .select('*')
        .eq('gallery_id', galleryId)
        .order('sort_order', { ascending: true });

      if (error) {
        throw error;
      }

      return data || [];
    } catch (error) {
      console.error('Error fetching photos:', error);
      toast.error('Failed to load gallery photos');
      return [];
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchPhotos,
    loading
  };
};
