import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { supabase } from '@/lib/supabase';
import { ChevronRight } from 'lucide-react';
import Header from '@/components/header';
import Footer from '@/components/Footer';
import GalleryHeader from '@/components/gallery/GalleryHeader';
import ImageGrid from '@/components/gallery/ImageGrid';
import LazyImage from '@/components/ui/lazy-image';
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
const GalleryPage = () => {
  const [galleries, setGalleries] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchGalleries();
  }, []);
  const fetchGalleries = async () => {
    try {
      setLoading(true);
      const {
        data,
        error
      } = await supabase.from('galleries').select(`
          *,
          gallery_photos(image_url),
          services(title)
        `).eq('is_public', true).order('created_on', {
        ascending: false
      });
      if (error) throw error;
      const galleriesWithPhotos = data?.map(gallery => {
        const coverImage = gallery.cover_image || gallery.gallery_photos?.[0]?.image_url;
        return {
          ...gallery,
          cover_image: coverImage
        };
      }) || [];

      // Log to debug
      console.log("Galleries with cover images:", galleriesWithPhotos.map(g => ({
        id: g.id,
        title: g.title,
        cover_image: g.cover_image,
        photos_count: g.gallery_photos?.length || 0
      })));
      setGalleries(galleriesWithPhotos);
    } catch (error) {
      console.error('Error fetching galleries:', error);
    } finally {
      setLoading(false);
    }
  };
  return <>
      <Helmet>
        <title>Gallery | Fix My Brick</title>
        <meta name="description" content="Browse our project galleries to see examples of our work." />
      </Helmet>

      <Header />

      <main className="container mx-auto px-4 py-12">
        <section className="mb-12">
          <GalleryHeader title="Gallery" description="Browse through our collection of project galleries to see examples of our work and craftsmanship." />

          {loading ? <ImageGrid loading={true} images={[]} /> : galleries.length === 0 ? <div className="py-20 text-center">
              <p className="text-lg text-gray-600">No galleries available yet. Please check back soon!</p>
            </div> : <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {galleries.map(gallery => <Card key={gallery.id} className="overflow-hidden h-[350px] hover:shadow-lg transition-shadow duration-200 flex flex-col">
                  <div className="relative h-[180px] overflow-hidden bg-gray-200">
                    {gallery.cover_image ? <div className="w-full h-full">
                        <img src={gallery.cover_image} alt={gallery.title} className="w-full h-full object-cover" onError={e => {
                  console.error(`Gallery cover image failed to load: ${gallery.cover_image}`);
                  (e.target as HTMLImageElement).src = '/placeholder.svg';
                }} style={{
                  display: 'block'
                }} crossOrigin={gallery.cover_image.includes('imagedelivery.net') ? 'anonymous' : undefined} />
                      </div> : <div className="w-full h-full flex items-center justify-center text-gray-400">
                        No image
                      </div>}
                    {gallery.services?.title && <Badge className="absolute top-2 right-2 z-10" variant="secondary">{gallery.services.title}</Badge>}
                  </div>
                  <CardContent className="p-4 flex-grow bg-white border-t">
                    <h2 className="text-xl font-semibold mb-2 text-gray-900">{gallery.title}</h2>
                    {gallery.description && <p className="text-gray-600 line-clamp-2 text-sm">{gallery.description}</p>}
                  </CardContent>
                  <CardFooter className="px-4 pb-4 pt-0 bg-white mt-auto">
                    <Link to={`/gallery/${gallery.slug}`} className="w-full">
                      <Button variant="outline" className="w-full justify-between border-[#D00B0E] text-[#D00B0E] hover:bg-[#D00B0E] hover:text-white">
                        View Gallery
                        <ChevronRight className="h-4 w-4" />
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>)}
            </div>}
        </section>
      </main>

      <Footer />
    </>;
};
export default GalleryPage;