
import { useState } from 'react';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';
import { GalleryPhoto, ExternalImage } from './types';

export const usePhotoUpload = () => {
  const [uploading, setUploading] = useState(false);

  const uploadPhoto = async (
    galleryId: string,
    file: File,
    caption: string = ''
  ): Promise<GalleryPhoto | null> => {
    try {
      setUploading(true);
      
      // Generate a unique file name
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random().toString(36).substring(2, 15)}.${fileExt}`;
      const filePath = `${galleryId}/${fileName}`;

      // Upload to storage
      const { error: uploadError } = await supabase.storage
        .from('gallery_images')
        .upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }

      // Get public URL
      const { data: urlData } = supabase.storage
        .from('gallery_images')
        .getPublicUrl(filePath);

      const publicUrl = urlData.publicUrl;

      // Store in database - Removing review_status field as it doesn't exist in the table
      const { data, error: dbError } = await supabase
        .from('gallery_photos')
        .insert({
          gallery_id: galleryId,
          image_url: publicUrl,
          caption: caption || null,
          sort_order: Date.now() // Simple sort order based on timestamp
        })
        .select();

      if (dbError) {
        throw dbError;
      }

      toast.success('Photo uploaded successfully');
      return data?.[0] || null;
    } catch (error) {
      console.error('Error uploading photo:', error);
      toast.error('Failed to upload photo');
      return null;
    } finally {
      setUploading(false);
    }
  };

  return {
    uploadPhoto,
    uploading
  };
};
