
import React from 'react';
import { FileText, ExternalLink } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import LazyImage from '@/components/ui/lazy-image';
import { formatDate } from '@/lib/utils';
import { useNavigate } from 'react-router-dom';

interface WSIBCertificatePreviewProps {
  certificate: {
    id: string;
    title: string;
    description: string | null;
    file_url: string | null;
    expiry_date: string | null;
  } | null;
  isLoading?: boolean;
}

const WSIBCertificatePreview: React.FC<WSIBCertificatePreviewProps> = ({
  certificate,
  isLoading = false
}) => {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <Card>
        <CardHeader className="pb-3">
          <CardTitle className="text-lg">Certificate Preview</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex justify-center items-center h-40 bg-gray-100 animate-pulse rounded-md">
            <FileText className="h-10 w-10 text-gray-300" />
          </div>
        </CardContent>
      </Card>
    );
  }

  if (!certificate || !certificate.file_url) {
    return (
      <Card>
        <CardHeader className="pb-3">
          <CardTitle className="text-lg">Certificate Preview</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col justify-center items-center h-40 border border-dashed border-gray-300 rounded-md p-4">
            <FileText className="h-10 w-10 text-gray-400 mb-2" />
            <p className="text-center text-gray-500">No certificate uploaded yet.</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  const isPDF = certificate.file_url.toLowerCase().endsWith('.pdf');

  return (
    <Card>
      <CardHeader className="pb-3">
        <CardTitle className="text-lg flex items-center justify-between">
          <span>Certificate Preview</span>
          {certificate.file_url && (
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="ghost" size="sm">
                  <ExternalLink className="h-4 w-4 mr-2" />
                  View Full
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-80 p-0" align="end">
                <div className="p-4 pt-0">
                  <p className="font-medium text-sm mt-4">
                    {certificate.title}
                    {certificate.expiry_date && (
                      <span className="block text-sm text-gray-500 mt-1">
                        Valid until: {formatDate(certificate.expiry_date)}
                      </span>
                    )}
                  </p>
                  <div className="mt-2 mb-2 flex gap-2">
                    <Button
                      size="sm"
                      onClick={() => navigate('/wsib')}
                      className="w-full"
                    >
                      View Public Page
                    </Button>
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() => {
                        const a = document.createElement('a');
                        a.href = certificate.file_url!;
                        a.download = '';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                      }}
                      className="w-full"
                    >
                      Download
                    </Button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          )}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div 
          className="border rounded-md overflow-hidden cursor-pointer"
          onClick={() => navigate('/wsib')}
        >
          {isPDF ? (
            <div className="aspect-[3/4] bg-gray-50 flex flex-col items-center justify-center p-8 text-center">
              <FileText className="h-16 w-16 text-D00B0E mb-4" />
              <p className="text-gray-600 mb-2">PDF Certificate</p>
              <Button 
                variant="outline" 
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(certificate.file_url!, '_blank');
                }}
                className="mt-2"
              >
                <FileText className="mr-2 h-4 w-4" />
                View PDF
              </Button>
            </div>
          ) : (
            <LazyImage 
              src={certificate.file_url} 
              alt="WSIB Certificate" 
              className="w-full aspect-[3/4] object-contain" 
            />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default WSIBCertificatePreview;
