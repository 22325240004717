
import React from 'react';
import { SiteSettings } from '@/types/settings';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { InfoIcon } from 'lucide-react';

interface AnalyticsTabProps {
  settings: SiteSettings;
  saving: boolean;
  handleChange: (field: keyof SiteSettings, value: string) => void;
}

const AnalyticsTab: React.FC<AnalyticsTabProps> = ({ 
  settings, 
  saving, 
  handleChange 
}) => {
  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Google Analytics</CardTitle>
          <CardDescription>
            Configure Google Analytics tracking for your website
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <Alert className="bg-amber-50 border-amber-200">
            <InfoIcon className="h-4 w-4 text-amber-500" />
            <AlertDescription className="text-amber-700">
              After saving, Google Analytics tracking will be active on all pages of your website.
            </AlertDescription>
          </Alert>
          
          <div className="space-y-2">
            <Label htmlFor="google_analytics_id">Google Analytics Measurement ID</Label>
            <Input
              id="google_analytics_id"
              name="google_analytics_id"
              value={settings.google_analytics_id || ''}
              onChange={(e) => handleChange('google_analytics_id', e.target.value)}
              placeholder="G-XXXXXXXXXX"
              disabled={saving}
              className="w-full max-w-md"
            />
            <p className="text-sm text-gray-500">
              Enter your Google Analytics Measurement ID (starts with G-). Leave empty to disable tracking.
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default AnalyticsTab;
