
import React from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { Slider } from '@/components/ui/slider';
import { useState, useEffect } from 'react';

interface CropEditorProps {
  imageUrl: string;
  crop: Crop;
  onCropChange: (crop: Crop) => void;
  onCropComplete: (crop: Crop) => void;
  logoSize: number;
  onSizeChange: (value: number[]) => void;
  imgRef: React.RefObject<HTMLImageElement>;
}

export const CropEditor: React.FC<CropEditorProps> = ({
  imageUrl,
  crop,
  onCropChange,
  onCropComplete,
  logoSize,
  onSizeChange,
  imgRef
}) => {
  const [imgSrc, setImgSrc] = useState<string>(imageUrl);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // When imageUrl changes, load the image properly
  useEffect(() => {
    // Reset loading state
    setIsLoading(true);
    
    // Create a new in-memory image to handle CORS issues
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => {
      setIsLoading(false);
      
      // Create a canvas to convert the image
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        try {
          // Try to convert to data URL to make it origin-clean
          const dataUrl = canvas.toDataURL('image/png');
          setImgSrc(dataUrl);
        } catch (e) {
          console.error('Could not create data URL, using original image', e);
          setImgSrc(imageUrl);
        }
      }
    };
    
    img.onerror = () => {
      console.error('Error loading image for cropping');
      setIsLoading(false);
      // Fall back to the original URL if loading fails
      setImgSrc(imageUrl);
    };
    
    img.src = imageUrl;
  }, [imageUrl]);

  return (
    <div className="flex flex-col space-y-4">
      <div className="max-h-[400px] overflow-auto flex justify-center bg-gray-100 rounded-md p-4">
        {isLoading ? (
          <div className="flex items-center justify-center h-32">
            <p className="text-gray-500">Loading image...</p>
          </div>
        ) : (
          <ReactCrop
            crop={crop}
            onChange={onCropChange}
            onComplete={onCropComplete}
            aspect={undefined}
            keepSelection
          >
            <img 
              ref={imgRef}
              src={imgSrc}
              alt="Logo to crop"
              className="max-w-full"
              crossOrigin="anonymous"
            />
          </ReactCrop>
        )}
      </div>

      <div className="space-y-2">
        <div className="flex justify-between">
          <span className="text-sm font-medium">Logo Size</span>
          <span className="text-sm text-gray-500">{logoSize}rem</span>
        </div>
        <Slider 
          value={[logoSize]} 
          onValueChange={onSizeChange} 
          min={4} 
          max={36} 
          step={1}
        />
      </div>
    </div>
  );
};
